import React from 'react'
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";

const Resolution = ({ detail }) => {
    const styles = StyleSheet.create({
        container: {
            width: "70%",
            borderRadius: "8px",
            border: 1,
            borderColor: "#F0F1F8",
            display: "flex",
            justifyContent: "space-around",
            paddingVertical: "5px",
            paddingHorizontal: "8px",
        },
        title: {
            fontWeight: "semibold",
        },
        value: {
            fontSize: "11px",
            fontWeight: "bold",
            color: "#1767A5",
        }
    })

  return (
    <View style={styles.container}>
        <Text style={styles.title}>
            Resolution
        </Text>
        <Text style={styles.value}>
            {/* {detail.resolutions?.existing.concat(detail.resolutions?.new)?.join(", ")} */}
            {detail.resolutions?.existing.concat(detail.resolutions?.new)?.sort((a, b) => {
              if (a === "4K") return -1;   // First, sort "4K"
              if (b === "4K") return 1;
              if (a === "HD") return -1;    // Second, sort "HD"
              if (b === "HD") return 1;
              if (a === "SD") return -1;    // Third, sort "SD"
              if (b === "SD") return 1;
              return 0;                    // Default case: maintain existing order
            })?.join(", ")}
        </Text>
    </View>
  )
}

export default Resolution