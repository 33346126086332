import React, { memo, useRef, useState } from "react";
import QuadrantPicker from "../../components/QuadrantPicker/QuadrantPicker";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import useOnClickOutside from "../../hooks/useClickOutside";

const ControlSelects = ({
  chartType,
  setChartType,
  quarterEnd,
  quarterStart,
  setQuarterEnd,
  setQuarterStart,
  showChartTypeDropdown = false,
  customQuarterRange = null,
  defaultValue,
  page = "DEFAULT",
  showQuarterSelection = true,
  data,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const barTypeRef = useRef(null);
  useOnClickOutside(barTypeRef, () => setOpenDropdown(false));

  return (
    <div className="flex gap-x-2 text-xs">
      {showChartTypeDropdown && (
        <div ref={barTypeRef} className="relative">
          <button
            onClick={() => setOpenDropdown((prev) => !prev)}
            className="z-50 flex items-center rounded-md gap-x-1 px-4 py-1.5  text-sm bg-pot-bg-blue text-pot-blue"
          >
            {chartType === "line" ? "Line Chart" : "Bar Chart"}
            <HipchatChevronDownIcon />
          </button>
          {openDropdown && (
            <div className="absolute w-32 h-fit z-50 rounded-md bg-white border shadow-md right-0 ">
              <button
                onClick={() => {
                  setChartType("line");
                  setOpenDropdown(false);
                }}
                isChecked={chartType === "line"}
                className="py-3 px-4"
              >
                Line chart
              </button>
              <button
                onClick={() => {
                  setChartType("bar");
                  setOpenDropdown(false);
                }}
                label=""
                isChecked={chartType === "bar"}
                className="pb-3 px-4"
              >
                Bar chart
              </button>
            </div>
          )}
        </div>
      )}
      {showQuarterSelection && (
        <QuadrantPicker
          quarterEnd={quarterEnd}
          quarterStart={quarterStart}
          setQuarterEnd={setQuarterEnd}
          setQuarterStart={setQuarterStart}
          customQuarterRange={customQuarterRange}
          defaultValue={defaultValue}
          page={page}
          data={data}
        />
      )}
    </div>
  );
};

export default memo(ControlSelects);
