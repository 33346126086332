import React from "react";
import { motion } from "framer-motion";
// import ClipLoader from "react-spinners/ClipLoader";
import { DotPulse } from "@uiball/loaders";

const AuthSubmit = ({
  text,
  onClick,
  notValid,
  newPage,
  loading,
  fontSize = "text-lg",
  marginTop,
}) => {
  return (
    <section
      className={`w-full flex justify-center ${
        newPage ? "mt-[3vh]" : marginTop ? marginTop : "mt-16"
      }`}
    >
      <motion.button
        onClick={(e) => !notValid && onClick(e)}
        transition={{ duration: 1 }}
        className={` ${
          notValid && "opacity-40"
        } bg-gradient-to-r from-pot-dark-blue via-pot-blue-2 to-pot-dark-blue bg-[length:300%] px-8 py-2 rounded-lg flex items-center gap-x-3 text-white font-medium ${fontSize}`}
      >
        {loading && <DotPulse color="white" size={20} />}
        {text}
      </motion.button>
    </section>
  );
};

export default AuthSubmit;
