import React, { memo, useCallback, useEffect, useState } from "react";
import { ModalTransition } from "@atlaskit/modal-dialog";
import FilterIcon from "@atlaskit/icon/glyph/filter";
import ColumnVisibilityModal from "./ColumnVisibilityModal";
// import DataFilterModal from "./DataFilterModal";
import { AnimatePresence, motion } from "framer-motion";
import SearchIcon from "@atlaskit/icon/glyph/search";
import EditFilledIcon from "@atlaskit/icon/glyph/edit-filled";
import RefreshIcon from "@atlaskit/icon/glyph/refresh";
import { useRecoilState, useRecoilValue } from "recoil";
import { AccountUserAtom, FilterValuesAtom, SubscriptionTypeAtom } from "../../../atom";
import { Link, useNavigate } from "react-router-dom";
import Filter from "../../../assets/Filter";
import { debounce } from "lodash";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import Generate from "./Generate";
import { getFromLocalStorage } from "../../../utils/localStorage";

const TableControlTopBar = ({
  search,
  setSearch,
  setAllColumnsVisibility,
  setAllColumnOrder,
  allColumnOrder,
  allColumnsVisibility,
  columnsVisibleTemp,
  setColumnsVisibleTemp,
  columnsOrderTemp,
  setColumnsOrderTemp,
}) => {
  const [columnFilterModalIsOpen, setColumnFilterModalIsOpen] = useState(false);
  const [dataFilterModalIsOpen, setDataFilterModalIsOpen] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useRecoilState(FilterValuesAtom);
  const [type, setType] = useRecoilState(SubscriptionTypeAtom)
  const account = useRecoilValue(AccountUserAtom)
  const navigate = useNavigate();

  const openModal = useCallback(() => setColumnFilterModalIsOpen(true), []);
  const closeModal = useCallback(() => setColumnFilterModalIsOpen(false), []);
  // const openDataFilterModal = () => setDataFilterModalIsOpen(true);
  // const closeDataFilterModal = () => setDataFilterModalIsOpen(false);
  const redirectToSearch = () => navigate("/search");
  const userType = getFromLocalStorage("user_type")

  const handleOptimisedChange = useCallback(
    debounce((text) => {
      setSearch(text);
    }, 300),
    []
  );

  useEffect(() => {
    handleOptimisedChange(searchValue);
  }, [searchValue]);

  return (
    <section className="w-full pb-5 px-5 flex justify-between items-center">
      <div className="flex items-center text-sm gap-x-2 2xl:gap-x-3">
        {/* <button onClick={() => setType("Subscribers")} className={`border rounded-md px-2 py-1  ${type ===  "Subscribers" ? "bg-pot-blue text-white border-pot-blue" : "text-pot-t-grey"}`}>
          Subscription
        </button>
        <button onClick={() => setType("Viewers")} className={`border rounded-md px-2 py-1 ${type ===  "Viewers" ? "bg-pot-blue text-white border-pot-blue" : "text-pot-t-grey"}`}>
          Ad-Based
        </button>
        <button onClick={() => setType("Transactional Customers")} className={`border rounded-md px-2 py-1 ${type ===  "Transaction Customer" ? "bg-pot-blue text-white border-pot-blue" : "text-pot-t-grey"}`}>
          Transactional
        </button> */}
      </div>
      <div className="flex items-center text-sm gap-x-2 2xl:gap-x-3">
        <div className="rounded-md flex items-center bg-[#66BCFF]/10 py-1 px-2">
          <SearchIcon size="small" primaryColor="#1767A5" />
          <input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            // onBlur={() => {
            //   if (!searchValue) setSearchClicked(false);
            // }}
            placeholder="Search"
            className="py-1.5 px-2 w-52 2xl:w-60 bg-transparent text-tiny outline-none text-pot-blue placeholder:text-pot-blue"
          />
          {searchValue ? (
            <button className="mt-1" onClick={() => setSearchValue("")}>
              <CrossIcon size="small" primaryColor="#1767A5" />
            </button>
          ) : (
            <div className="w-4"></div>
          )}
        </div>
        {/* ) : (
          <motion.button
            // onClick={openModal}
            // whileHover={{ scale: 1.1 }}
            onClick={() => setSearchClicked(true)}
            className=" flex items-center rounded bg-[#66BCFF]/10 hover:bg-[#66BCFF]/20 transition ease-in-out px-3 py-2"
          >
            <SearchIcon size="small" primaryColor="#00838F" />
            <p className="text-pot-dark-blue ml-1">search</p>
          </motion.button>
        )} */}
        <Link to="/search">
          <motion.button
            // onClick={redirectToSearch}
            // onClick={openModal}
            // whileHover={{ scale: 1.1 }}
            className=" flex items-center text-pot-dark-blue bg-[#66BCFF]/10 hover:bg-[#66BCFF]/20 transition ease-in-out px-3 py-2 rounded"
          >
            <div className="stroke-pot-dark-blue">
              <Filter />
            </div>
            <p className="ml-1">Filter</p>
            {filters.length ? (
              <p className="ml-2 w-5 h-5 rounded-full flex justify-center items-center bg-pot-dark-blue text-white text-xs">
                {filters.reduce(
                  (acc, cur) => acc + Object.values(cur)[0]?.length,
                  0
                )}
              </p>
            ) : (
              <></>
            )}
          </motion.button>
        </Link>
        {filters.length ? (
          <motion.button
            // whileHover={{ scale: 1.1 }}
            onClick={() => setFilters([])}
            className="flex items-center bg-white hover:bg-[#66BCFF]/20 transition ease-in-out px-2 py-2 rounded  text-pot-dark-blue"
          >
            <RefreshIcon size="small" primaryColor="#1767A5" />
            <p className="ml-1">Reset</p>
          </motion.button>
        ) : (
          <></>
        )}
        {(account?.user_permissions.includes("c_download_detail_pdf") || userType.toLowerCase().includes("admin")) && (<Generate />)}
        <motion.button
          // whileHover={{ scale: 1.1 }}
          onClick={openModal}
          className="flex items-center bg-pot-bg-blue hover:bg-[#66BCFF0D]/20 transition ease-in-out px-2 py-2 text-sm rounded"
        >
          <EditFilledIcon size="small" primaryColor="#1767A5" />
          <p className="ml-1 text-[#1767A5]">Modify</p>
        </motion.button>
      </div>
      <ModalTransition>
        {columnFilterModalIsOpen && (
          <ColumnVisibilityModal
            closeModal={closeModal}
            setAllColumnsVisibility={setAllColumnsVisibility}
            setAllColumnOrder={setAllColumnOrder}
            allColumnOrder={allColumnOrder}
            allColumnsVisibility={allColumnsVisibility}
            columnsVisibleTemp={columnsVisibleTemp}
            setColumnsVisibleTemp={setColumnsVisibleTemp}
            columnsOrderTemp={columnsOrderTemp}
            setColumnsOrderTemp={setColumnsOrderTemp}
          />
        )}
      </ModalTransition>
      {/* <AnimatePresence>
        {dataFilterModalIsOpen && (
          <DataFilterModal closeModal={closeDataFilterModal} />
        )}
      </AnimatePresence> */}
    </section>
  );
};

export default memo(TableControlTopBar);
