import React, { useRef, useState } from "react";
import InputAreaField from "./components/InputAreaField";
import InputDivideField from "./components/InputDivideField";
import InputField from "./components/InputField";
import PhoneField from "./components/PhoneField";
import { motion } from "framer-motion";
import { MacScrollbar } from "mac-scrollbar";
import { useRecoilValue } from "recoil";
import { AppInfoAtom, CountryCodesAtom } from "../atom";
import { DotPulse } from "@uiball/loaders";
// import ReCAPTCHA from "react-google-recaptcha";
const ReCAPTCHA = React.lazy(() => import("react-google-recaptcha"))

const ContactForm = ({
  formData = {},
  setFormData = () => {},
  error = {},
  setError = () => {},
  onSubmit = () => {},
  loading=false,
  small=false
}) => {
  const country_codes = useRecoilValue(CountryCodesAtom)
  const [disabled, setDisabled] = useState(small ? true : false);
  const height = window.innerHeight

  const onChange = (value) => {
    console.log(value, "value")
    setFormData({ ...formData, g_recaptcha_response: value})
    setDisabled(false)
  }

  return (
    <MacScrollbar>
      <section className="text-xs">
        <InputDivideField
          heading="Name"
          className={small ? "mt-5" :"mt-8"}
          formData={formData}
          setFormData={setFormData}
          value1="first_name"
          value2="last_name"
          error={error}
          setError={setError}
        />
        <PhoneField
          heading="Phone Number"
          placeholder={1234506789}
          formData={formData}
          setFormData={setFormData}
          value="phone_number"
          className={small ? "mt-5" :"mt-8"}
          error={error}
          setError={setError}
          country_codes= {country_codes || ["+1"]}
          fieldWithCode={small}
        />
        <InputField
          heading="Email Address"
          className={small ? "mt-5" :"mt-8"}
          formData={formData}
          setFormData={setFormData}
          value="email_id"
          placeholder="john_doe@example.com"
          error={error}
          setError={setError}
        />
        <InputAreaField
          heading={small ? "Comments" : "Explain in brief"}
          className={small ? "mt-5" :"mt-8"}
          formData={formData}
          setFormData={setFormData}
          value="query"
          placeholder={small ? "Let us know how we can assist you..." : "Write your query"}
          error={error}
          setError={setError}
        />
        {small && (
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_G_RECAPTCHA_SITE_KEY}
            onChange={onChange}
            className="mt-5"
          />
        )}
        <div className={`w-full ${height < 800 ? "mt-4" : "mt-20"} flex justify-center pb-1`}>
          <motion.button
            onClick={() => {
              if(disabled) return
              else onSubmit()
            }}
            whileHover={{ scale: disabled ? 1 : 1.1 }}
            className={`${disabled ? "bg-pot-blue/30 cursor-default" : "bg-pot-blue"} px-5 flex gap-x-2 items-center  py-2 text-white text-sm font semibold rounded-md` }
          >
            {loading &&  (
              <DotPulse size={12} speed={0.8} color="white" />
            )}
            Send
          </motion.button>
        </div>
      </section>
    </MacScrollbar>
  );
};

export default ContactForm;
