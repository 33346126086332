import React from "react";

const ColoredPlus = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 7.25V1.99475C8.74921 1.79657 8.66977 1.6068 8.52914 1.46716C8.38851 1.32751 8.19819 1.24941 8 1.25C7.583 1.25 7.25 1.58375 7.25 1.99475V7.25H1.99475C1.79657 7.2508 1.6068 7.33024 1.46716 7.47087C1.32751 7.6115 1.24941 7.80182 1.25 8C1.25 8.41775 1.58375 8.75 1.99475 8.75H7.25V14.0053C7.25 14.4163 7.586 14.75 8 14.75C8.417 14.75 8.75 14.4163 8.75 14.0053V8.75H14.0053C14.2034 8.74921 14.3932 8.66977 14.5329 8.52914C14.6725 8.38851 14.7506 8.19819 14.75 8C14.75 7.583 14.4163 7.25 14.0053 7.25H8.75Z"
        fill="#00838F"
        stroke="#00838F"
      />
    </svg>
  );
};

export default ColoredPlus;
