import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CustomerApi } from '../../api/customer'

const usePlanTableFetch = () => {
    const { id } = useParams()
    const [data, setData] = useState([])
    const [called, setCalled] = useState(0)

    const fetchPlanTableData = async () => {
        const { data: response } = await CustomerApi.get_plan_history(id)
        if(response.success) {
            setData(response.data)
        }
    }

    const callAgain = () => setCalled(prev => prev+ 1)

    useEffect(() => {
        fetchPlanTableData()
    }, [called])

  return ({ data, callAgain })
}

export default usePlanTableFetch