import React, { useState } from "react";
import ColoredDownArrow from "../assets/ColoredDownArrow";
import { motion } from "framer-motion";
import { MacScrollbar } from "mac-scrollbar";

const Dropdown = ({ options, text, setValue, small=false }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [mouseEntered, setMouseEntered] = useState(false);

  return (
    <div
      tabIndex={1}
      onBlur={() => !mouseEntered && setOpenDropdown(false)}
      className="relative z-[48]"
    >
      <button
        onClick={() => setOpenDropdown((prev) => !prev)}
        className={`text-tiny font-medium ${small ? "w-fit" : "w-[156px]"} whitespace-nowrap bg-pot-bg-blue text-pot-dark-blue flex justify-between gap-3 px-2 py-1.5 rounded items-center `}
      >
        <p className="whitespace-nowrap ">{text}</p>
        <ColoredDownArrow color="#1767A5" />
      </button>
      {openDropdown && (
        <div className="absolute min-w-[18vw] top-8 left-0  w-40 h-60 overflow-y-auto pt-1 pb-1 bg-white rounded shadow">
          <MacScrollbar>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.1 }}
              onMouseEnter={() => setMouseEntered(true)}
              onMouseLeave={() => setMouseEntered(false)}
              className="w-full h-[95%] text-xs overflow-y-auto my-2"
            >
              {options.map((each) => (
                <p
                  onClick={() => {
                    setValue(each);
                    setOpenDropdown(false);
                  }}
                  className="border-l-2 cursor-pointer border-white hover:border-pot-dark-blue hover:bg-pot-grey-4/10 transition ease-in-out px-2 py-1 my-1"
                >
                  {each.label || each}
                </p>
              ))}
            </motion.div>
          </MacScrollbar>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
