import React from 'react'
import { Link } from 'react-router-dom'
import error_image from "../assets/page_not_found.png"
import { motion } from 'framer-motion'

const ErrorBoundary = () => {

  return (
    <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.1 }}
        className="flex flex-col w-screen h-screen justify-center items-center">
        <img src={error_image} className="w-[40vw]" />
        <Link to="/" className="py-2 px-5 mt-20 rounded bg-gradient-to-r from-pot-dark-blue to-pot-blue text-white">
            Back to home page
        </Link>
    </motion.div>
  )
}

export default ErrorBoundary