import React from "react";

const Doc = () => {
  return (
    <svg
      width="34"
      height="42"
      viewBox="0 0 34 42"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6665 16.345V37.3333H5.33317V4.66667H19.3098V10.4767C19.3098 11.4049 19.6786 12.2952 20.335 12.9515C20.9913 13.6079 21.8816 13.9767 22.8098 13.9767H33.3098V12.7587C33.3098 12.0657 33.0018 11.41 32.4722 10.9667L20.6328 1.08267C19.7928 0.382667 18.7358 0 17.6415 0H5.33317C4.09549 0 2.90851 0.491665 2.03334 1.36683C1.15817 2.242 0.666504 3.42899 0.666504 4.66667V37.3333C0.666504 38.571 1.15817 39.758 2.03334 40.6332C2.90851 41.5083 4.09549 42 5.33317 42H28.6665C29.9042 42 31.0912 41.5083 31.9663 40.6332C32.8415 39.758 33.3332 38.571 33.3332 37.3333V16.345H28.6665Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default Doc;
