import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import {
  AllFilterOptionsAtom,
  AppInfoAtom,
  CanadaOptionsAtom,
  CountryCodesAtom,
  QuarterRangeAtom,
  ChurnQuarterRangeAtom,
  NPSQuarterRangeAtom,
} from "../atom";
import { navItems } from "../utils/adminNavItems";
import MainNavigationComponent from "./MainNavComponent";
import { appInfo } from "../api/core";
import { getFromLocalStorage } from "../utils/localStorage";
import { DotPulse } from "@uiball/loaders";
import { OttDataApi } from "../api/ottData";
import { formatToOptions } from "../utils/formatConfig";
import { getFromCache, setToCache } from "../utils/cacheStorage";
import { BASE_URL } from "../api/axios";

const AdminNavigation = () => {
  const setAppInfoData = useSetRecoilState(AppInfoAtom);
  const [loading, setLoading] = useState(true);
  const setQuarterRange = useSetRecoilState(QuarterRangeAtom);
  const setNPSQuarterRange = useSetRecoilState(NPSQuarterRangeAtom);
  const setChurnQuarterRange = useSetRecoilState(ChurnQuarterRangeAtom);
  const setAllFilterOptions = useSetRecoilState(AllFilterOptionsAtom);
  const setCanadaFilterOptions = useSetRecoilState(CanadaOptionsAtom);
  const setCountryCodes = useSetRecoilState(CountryCodesAtom);
  // const setLoadingProgress = useSetRecoilState(LoadingProgressAtom)
  const isAdmin = getFromLocalStorage("user_type")?.toUpperCase() === "ADMIN";

  const fetchAppInfo = async () => {
    // setLoadingProgress(10)
    const { data: response } = await appInfo();
    // setLoadingProgress(15)
    const settableAppInfo = { ...response.data };
    settableAppInfo.country_codes = [...new Set(settableAppInfo.country_codes)];
    await setToCache("app-info", `${BASE_URL}/core/app-info/`, settableAppInfo);
    setAppInfoData(response.data);
    //setLoadingProgress(70)
    // setLoadingProgress(100)
  };

  const fetchAppConfigFromCache = async () => {
    let data, data2, response_real;
    const response = await getFromCache(`${BASE_URL}/ott-profile/config/`);
    if (response) {
      data = response.region_config.US;
      data2 = response.region_config.CANADA;
      response_real = response;
      const allOptions = formatToOptions(data.search_options);
      const allOptions2 = formatToOptions(data2.search_options);
      const country_codes = [...new Set(response_real.country_codes)];
      setAllFilterOptions(allOptions);
      setCanadaFilterOptions(allOptions2);
      setQuarterRange(response_real.subs_quarter_range);
      setNPSQuarterRange(data?.nps_quarter_range);
      setChurnQuarterRange(data?.churn_quarter_range);
      setCountryCodes(country_codes);
      setLoading(false);
    }
  };

  const fetchAppConfig = async () => {
    // setLoadingProgress(10)
    // const { data: response } = await appInfo()
    let data, data2, response_real;
    const { data: res } = await OttDataApi.config();
    data = res.data.region_config.US;
    data2 = res.data.region_config.CANADA;
    response_real = res.data;
    // setLoadingProgress(40)

    // const countryRegionObj = formatToKeyValue(data.field_mapping.country);
    // const deviceType = formatToKeyValue(data.field_mapping.device_support);
    const allOptions = formatToOptions(data.search_options);
    const allOptions2 = formatToOptions(data2.search_options);
    console.log(allOptions2.names, "all can names");
    // const {
    //   visible: allColumns,
    //   keyVal: allColumnHeads,
    //   sortable: sortableObj,
    // } = formatToVisibility(data.column_fields);
    // // const namesKeyList = formatNamesToIdList(allOptions.names)
    // // setLoadingProgress(80)

    // setInitialColumnVisibility(allColumns);
    // setSortableColumnsList(sortableObj);
    // setColumnKeyValue(allColumnHeads);
    // setCountryObject(countryRegionObj);
    // setDeviceSupport(deviceType);
    const country_codes = [...new Set(response_real.country_code)];
    console.log("country_codes", country_codes, response_real);
    setAllFilterOptions(allOptions);
    setCanadaFilterOptions(allOptions2);
    setQuarterRange(response_real.subs_quarter_range);
    setNPSQuarterRange(data?.nps_quarter_range);
    setChurnQuarterRange(data?.churn_quarter_range);
    setCountryCodes(country_codes);
    // setLoadingProgress(100)

    setLoading(false);
  };

  const fetchAppInfoFromCache = async () => {
    const data = await getFromCache(`${BASE_URL}/core/app-info/`);
    if (data) {
      setAppInfoData(data);
    }
  };

  // useEffect(() => {
  //   if(loading) return
  //   if(adminEditValues) {

  //   }
  // }, [adminEditValues])

  useEffect(() => {
    // if(!appInfoData){
    if (isAdmin) {
      fetchAppInfo();
      fetchAppConfig();
      fetchAppConfigFromCache();
      fetchAppInfoFromCache();
    } else {
      const loggedInAlways = localStorage.getItem("loggedInAlways");
      if (!loggedInAlways) {
        localStorage.setItem("noChange", true);
      }
      window.location.href = "/page-not-found";
    }
    // } else {
    // setLoading(false)
    // }
  }, []);

  if (loading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <DotPulse size={40} color="#1767A5" />
      </div>
    );
  }

  return <MainNavigationComponent navItems={navItems} equals />;
};

export default AdminNavigation;
