import React, { useEffect, useState } from "react";
import DatePicker from "./DatePicker";
import TopbarControl from "./TopbarControl";
import moment from "moment";

const DateRangePickerContainer = ({
  keyValue,
  allFilters,
  setAllFilters,
  setOpenDatePicker,
  initialDaysDiff = 0,
  allowMonthsDiff = 0,
  startDate,
  endDate,
}) => {
  const [fromDate, setFromDate] = useState(new Date().getDate());
  const [fromMonth, setFromMonth] = useState(
    (((new Date().getMonth() - 1) % 12) + 12) % 12
  );
  const [fromYear, setFromYear] = useState(new Date().getFullYear());
  const [toDate, setToDate] = useState(new Date().getDate());
  const [toMonth, setToMonth] = useState(new Date().getMonth());
  const [toYear, setToYear] = useState(new Date().getFullYear());
  const [selectedFromDate, setSelectedFromDate] = useState(
    initialDaysDiff ? moment(startDate).toDate() : new Date()
  );
  const [selectedToDate, setSelectedToDate] = useState(
    initialDaysDiff ? moment(endDate).toDate() : new Date()
  );
  const [inputStartDate, setInputStartDate] = useState("");
  const [inputEndDate, setInputEndDate] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    const settableDate = selectedToDate ? selectedToDate : new Date();
    if (initial) {
      setInputEndDate(moment(settableDate).format("MM-DD-YY"));
      setInitial(false);
      return;
    }
    setToMonth(settableDate.getMonth());
    setToYear(settableDate.getFullYear());
    setInputEndDate(moment(settableDate).format("MM-DD-YY"));
    if (selectedToDate < selectedFromDate) {
      setInvalid(true);
      return;
    }
    setInvalid(false);
  }, [selectedToDate]);

  useEffect(() => {
    const settableDate = selectedFromDate ? selectedFromDate : new Date();
    if (initial) {
      setInputStartDate(moment(settableDate).format("MM-DD-YY"));
      setInitial(false);
      return;
    }
    setFromMonth(settableDate.getMonth());
    setFromYear(settableDate.getFullYear());
    setInputStartDate(moment(settableDate).format("MM-DD-YY"));
    if (selectedFromDate > selectedToDate) {
      setInvalid(true);
      return;
    }
    setInvalid(false);
    if (initial) {
      setInitial(false);
    }
  }, [selectedFromDate]);

  return (
    <div>
      <TopbarControl
        keyValue={keyValue}
        allFilters={allFilters}
        setAllFilters={setAllFilters}
        inputStartDate={inputStartDate}
        setInputStartDate={setInputStartDate}
        startDate={selectedFromDate}
        endDate={selectedToDate}
        setInputEndDate={setInputEndDate}
        inputEndDate={inputEndDate}
        setStartDate={setSelectedFromDate}
        setEndDate={setSelectedToDate}
        setPickerOpen={setOpenDatePicker}
        invalid={invalid}
      />
      <div className="flex w-full divide-x pb-2 border-b">
        <DatePicker
          date={{
            value: fromDate,
            month: fromMonth,
            year: fromYear,
            set: setFromDate,
            setMonth: setFromMonth,
            setYear: setFromYear,
          }}
          otherDate={{
            value: toDate,
            month: toMonth,
            year: toYear,
            set: setToDate,
            setMonth: setToMonth,
            setYear: setToYear,
          }}
          selectedDate={selectedFromDate}
          setSelectedDate={setSelectedFromDate}
          selectedOtherDate={selectedToDate}
          from
          allowMonthsDiff={allowMonthsDiff}
        />
        <DatePicker
          otherDate={{
            value: fromDate,
            month: fromMonth,
            year: fromYear,
            set: setFromDate,
            setMonth: setFromMonth,
            setYear: setFromYear,
          }}
          date={{
            value: toDate,
            month: toMonth,
            year: toYear,
            set: setToDate,
            setMonth: setToMonth,
            setYear: setToYear,
          }}
          selectedDate={selectedToDate}
          setSelectedDate={setSelectedToDate}
          selectedOtherDate={selectedFromDate}
          allowMonthsDiff={allowMonthsDiff}
        />
      </div>
    </div>
  );
};

export default DateRangePickerContainer;
