export const tags = [
  {
    label: `{first_name}`,
    value: `{first_name}`,
  },
  {
    label: `{portal_link}`,
    value: `{portal_link}`,
  },
  {
    label: `{pa_info_email}`,
    value: `{pa_info_email}`,
  },
];
