import React, { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Sort from "../assets/Sort";
import Checkbox from "../components/Checkbox";
import { strokes } from "../utils/chartsData";
import { getAllQuarterBetween, padStart, subsLess } from "../utils/helperFunc";

const useTrendsCols = (
  rawData,
  quarterEnd,
  quarterStart,
  selectedOtts,
  setSelectedOtts,
  sortBy,
  setSortBy
) => {
  const [colDef, setColDef] = useState([]);
  const screenSize = window.innerWidth;

  const getAllQuadrants = () => {
    const quadsData = getAllQuarterBetween(quarterStart, quarterEnd);
    //   const quadsData = Object.values(Object.values(service)[0])[0]
    return quadsData;
  };

  // defines the fixed column
  const mainDef = {
    header: () => (
      <div className="w-full flex gap-1 px-2 font-semibold justify-center">
        Name of Service
      </div>
    ),
    accessorKey: "name",
    enablePinning: true,
    size: Math.ceil((screenSize - 170) / 7),
    cell: ({ getValue, row }) => {
      const selectedOtt = selectedOtts?.find(
        (each) => each.id === row.original.id
      );

      const handleCheck = () => {
        if (!!selectedOtt) {
          const filteredOtts = selectedOtts.filter(
            (each) => each.id !== selectedOtt.id
          );
          setSelectedOtts([...filteredOtts]);
        } else {
          if (selectedOtts.length >= 10) {
            toast.error("You can only add upto 10 Profiles");
            return;
          }
          const settableStroke = strokes.find(
            (stroke) => !selectedOtts.some((each) => each.color === stroke)
          );
          setSelectedOtts((prev) => [
            ...prev,
            { ...row.original, color: settableStroke },
          ]);
        }
      };

      return (
        <p className="font-semibold flex justify-start gap-x-3 w-full text-left">
          <Checkbox
            onCheck={handleCheck}
            color={selectedOtt?.color}
            checked={!!selectedOtt}
          />
          {getValue()}
        </p>
      );
    },
  };

  const addableDef = (value) => {
    const realVal = [value[0] + 1, value[1]];
    return {
      header: () => (
        <button
          onClick={() => {
            const settableSortValue =
              sortBy !== `+ ${realVal.join(" ")}`
                ? sortBy?.includes(realVal.join(" "))
                  ? null
                  : `+ ${realVal.join(" ")}`
                : `- ${realVal.join(" ")}`;
            setSortBy(settableSortValue);
          }}
          className="w-full flex gap-1 px-2 font-semibold items-center justify-center"
        >
          Q{realVal.join(" ")}
          {sortBy === `+ ${realVal.join(" ")}` ? (
            <Sort firstFill="#1767A5" secondFill="white" />
          ) : sortBy === `- ${realVal.join(" ")}` ? (
            <Sort firstFill="white" secondFill="#1767A5" />
          ) : (
            <Sort firstFill="#1767A5" secondFill="#1767A5" />
          )}
        </button>
      ),
      accessorKey: `${realVal.join(" ")}`,
      size: Math.ceil((screenSize - 170) / 14),
      cell: ({ getValue }) => (
        <p className="w-full text-center">
          {getValue() ? `${subsLess(padStart(getValue()))} M` : <em>na</em>}
        </p>
      ),
    };
  };

  useEffect(() => {
    const allQuads = getAllQuadrants();
    const quadsDef = allQuads.map((each) => addableDef(each));
    setColDef([mainDef, ...quadsDef]);
  }, [rawData, selectedOtts, sortBy]);

  return colDef;
};

export default useTrendsCols;
