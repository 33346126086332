import React, { useEffect, useState } from "react";
import SelectInput from "../../form/components/SelectInput";
// import DatePickerInput from "../../form/components/DatePickerInput";
import MultiRangeSlider from "../../components/MultiRangeSlider";
import RangeInput from "../../form/components/RangeInput";
import Button from "../../form/components/Button";
// import Toggle from "@atlaskit/toggle";
import RefreshIcon from "@atlaskit/icon/glyph/refresh";
import { AnimatePresence, motion } from "framer-motion";
import DateRangePickerInput from "../../form/components/DateRangePickerInput";
import AndOrDropdown from "../../form/components/AndOrDropdown";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { AllFilterOptionsAtom, SelectedProfileCountryAtom } from "../../atom";
import { Radio } from "@atlaskit/radio";
import Checkbox from "@atlaskit/checkbox";
import InfoIcon from "@atlaskit/icon/glyph/info";
import { MacScrollbar } from "mac-scrollbar";
import useFilteredNames from "../../hooks/useFilteredNames";
//This is a badly coded page

const FilterFields = ({
  allFilters,
  setAllFilters,
  setMainFilters,
  initial,
}) => {
  const selectedCountry = useRecoilValue(SelectedProfileCountryAtom);
  const allFilterOptions = useRecoilValue(AllFilterOptionsAtom);
  // Sorted options for genres
  const sortedGenreOptions = allFilterOptions?.genres
    ?.slice()
    ?.sort((a, b) => a.label.localeCompare(b?.label));
  // const subscriberMax = Math.trunc(allFilterOptions.subscribers.max);
  // const subscriberMin = Math.trunc(allFilterOptions.subscribers.min);
  const subSmall = allFilterOptions.subscribers.find((each) =>
    each.label.toLowerCase().includes("small")
  );
  const subMedium = allFilterOptions.subscribers.find((each) =>
    each.label.toLowerCase().includes("medium")
  );
  const subLarge = allFilterOptions.subscribers.find((each) =>
    each.label.toLowerCase().includes("large")
  );

  const pricingMax = Math.trunc(allFilterOptions.pricing.max);
  const pricingMin = Math.trunc(allFilterOptions.pricing.min);

  const getCustomSub = () => {
    let necessaryObj = allFilters
      ?.find((each) => Object.keys(each)[0] === "subscribers")
      ?.subscribers.find(
        (each) =>
          !(
            each.label.toLowerCase().includes("small") ||
            each.label.toLowerCase().includes("medium") ||
            each.label.toLowerCase().includes("large")
          )
      );
    return necessaryObj;
  };

  const hasCustomSub = getCustomSub()?.label?.split(" - ");

  const handleCheckboxClick = (keyValue, objset) => {
    {
      const necessaryObj = allFilters?.find(
        (each) => Object.keys(each)[0] === keyValue
      );
      if (
        necessaryObj?.[keyValue].some((each) => each.label === objset.label)
      ) {
        const filteredVal = necessaryObj[keyValue].filter(
          (each) => each.label !== objset.label
        );
        if (filteredVal.length) {
          necessaryObj[keyValue] = filteredVal;
          setAllFilters([...allFilters]);
        } else {
          const filteredFilters = allFilters.filter(
            (each) => Object.keys(each)[0] !== keyValue
          );
          setAllFilters(filteredFilters);
        }
      } else {
        if (necessaryObj) {
          necessaryObj?.[keyValue].push(objset);
          setAllFilters([...allFilters]);
        } else {
          setAllFilters([...allFilters, { [keyValue]: [objset] }]);
        }
      }
    }
  };

  const [customSubscriber, setCustomSubscriber] = useState(
    !!hasCustomSub?.length || false
  );
  const pricingOptions = allFilterOptions.pricing;

  // const hasPricing = allFilters
  //   .find((each) => Object.keys(each)[0] === "pricing")
  //   ?.pricing?.[0]?.value.split(" - ")
  //   ?.sort((a, b) => Number(a) < Number(b));
  // const hasSubscribers = allFilters
  //   .find((each) => Object.keys(each)[0] === "subscribers")
  //   ?.subscribers?.[0]?.value.split(" - ")
  //   ?.sort((a, b) => Number(a) < Number(b));
  const prevDist = allFilters.find(
    (each) => Object.keys(each)[0] === "distribution_types"
  )?.distribution_types?.[0];
  const prevOrgCont = allFilters.find(
    (each) => Object.keys(each)[0] === "10"
  )?.["10"]?.[0]?.label;
  const prevDownFetr = allFilters.find(
    (each) => Object.keys(each)[0] === "20"
  )?.["20"]?.[0]?.label;
  const prevFreeTrial = allFilters.find(
    (each) => Object.keys(each)[0] === "30"
  )?.["30"]?.[0]?.label;
  const prevLivContent = allFilters.find(
    (each) => Object.keys(each)[0] === "40"
  )?.["40"]?.[0].label;

  const [pricingVal, setPricingVal] = useState(
    // hasPricing?.length
    //   ? { min: hasPricing[0], max: hasPricing[1] }
    //   :
    { max: pricingMax, min: pricingMin }
  );
  const [subscribersVal, setSubscribersVal] = useState(
    hasCustomSub?.length
      ? { min: hasCustomSub[0], max: hasCustomSub[1] }
      : //   ? { min: hasSubscribers[0], max: hasSubscribers[1] }
        { min: 0, max: 100 }
  );

  const [selectedSubscriberSize, setSelectedSubSize] = useState("large");
  const [selectedDist, setSelectedDist] = useState(
    prevDist ? { label: prevDist.label, value: prevDist.value } : null
  );
  const [originalContent, setOriginalContent] = useState(prevOrgCont || null);
  const [downloadFeature, setDownloadFeature] = useState(prevDownFetr || null);
  const [liveContent, setLiveContent] = useState(prevLivContent || null);
  const [freeTrial, setFreeTrial] = useState(prevFreeTrial || null);
  const [showSubToolTip, setShowSubToolTip] = useState(false);
  const [showDisTypToolTip, setShowDisTypToolTip] = useState(false);
  const [showDownToolTip, setShowDownToolTip] = useState(false);
  const [backToData, setBackToData] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (initial) {
      return;
    }
    if (pricingVal.min > pricingMin || pricingVal.max < pricingMax) {
      const filteredFilter = allFilters.filter(
        (each) => Object.keys(each)[0] !== "pricing"
      );
      const val = `${pricingVal.min} - ${pricingVal.max}`;
      setAllFilters([
        { pricing: [{ label: val, value: val }] },
        ...filteredFilter,
      ]);
    } else {
      const filteredFilter = allFilters.filter(
        (each) => Object.keys(each)[0] !== "pricing"
      );
      if (allFilters.length > filteredFilter.length) {
        setAllFilters(filteredFilter);
      }
    }
  }, [pricingVal]);

  useEffect(() => {
    if (initial) {
      return;
    }
    if (customSubscriber) {
      const val = `${subscribersVal.min} - ${subscribersVal.max}`;
      const filterArray = allFilters?.find(
        (each) => Object.keys(each)[0] === "subscribers"
      )?.subscribers;
      if (filterArray) {
        let obj = getCustomSub();
        if (obj) {
          obj.label = val;
          obj.value = val;
          setAllFilters([...allFilters]);
        } else {
          filterArray.push({ label: val, value: val });
          setAllFilters([...allFilters]);
        }
      } else {
        setAllFilters([
          ...allFilters,
          { subscribers: [{ label: val, value: val }] },
        ]);
      }
    } else {
      let obj = getCustomSub();
      if (obj) {
        const filteredArray = allFilters
          ?.find((each) => Object.keys(each)[0] === "subscribers")
          .subscribers.filter((each) => each.label !== obj.label);
        if (filteredArray.length) {
          allFilters.find(
            (each) => Object.keys(each)[0] === "subscribers"
          ).subscribers = filteredArray;
          setAllFilters([...allFilters]);
        } else {
          const filteredArray = allFilters?.filter(
            (each) => Object.keys(each)[0] !== "subscribers"
          );
          setAllFilters(filteredArray);
        }
      }
    }
  }, [subscribersVal]);

  useEffect(() => {
    if (initial) {
      return;
    }
    let obj = getCustomSub();
    if (obj && customSubscriber) {
      const [min, max] = obj.label.split(" - ");
      setSubscribersVal({ min, max });
    } else {
      setSubscribersVal({ min: 0, max: 100 });
    }
  }, [customSubscriber]);

  useEffect(() => {
    if (initial) {
      return;
    }
    if (originalContent) {
      const filteredFilter = allFilters.filter(
        (each) => Object.keys(each)[0] !== "10"
      );
      setAllFilters([
        { 10: [{ label: originalContent, value: originalContent }] },
        ...filteredFilter,
      ]);
    } else {
      const filteredFilter = allFilters.filter(
        (each) => Object.keys(each)[0] !== "10"
      );
      setAllFilters(filteredFilter);
      setOriginalContent(false);
    }
  }, [originalContent]);

  useEffect(() => {
    if (initial) {
      return;
    }
    if (downloadFeature) {
      const filteredFilter = allFilters.filter(
        (each) => Object.keys(each)[0] !== "20"
      );
      setAllFilters([
        { 20: [{ label: downloadFeature, value: downloadFeature }] },
        ...filteredFilter,
      ]);
    } else {
      const filteredFilter = allFilters.filter(
        (each) => Object.keys(each)[0] !== "20"
      );
      setAllFilters(filteredFilter);
      setDownloadFeature(false);
    }
  }, [downloadFeature]);

  useEffect(() => {
    if (initial) {
      return;
    }
    if (freeTrial) {
      const filteredFilter = allFilters.filter(
        (each) => Object.keys(each)[0] !== "30"
      );
      setAllFilters([
        { 30: [{ label: freeTrial, value: freeTrial }] },
        ...filteredFilter,
      ]);
    } else {
      const filteredFilter = allFilters.filter(
        (each) => Object.keys(each)[0] !== "30"
      );
      setAllFilters(filteredFilter);
      setFreeTrial(false);
    }
  }, [freeTrial]);

  useEffect(() => {
    if (initial) {
      return;
    }
    if (liveContent) {
      const filteredFilter = allFilters.filter(
        (each) => Object.keys(each)[0] !== "40"
      );
      setAllFilters([
        { 40: [{ label: liveContent, value: liveContent }] },
        ...filteredFilter,
      ]);
    } else {
      const filteredFilter = allFilters.filter(
        (each) => Object.keys(each)[0] !== "40"
      );
      setAllFilters(filteredFilter);
      setLiveContent(false);
    }
  }, [liveContent]);

  useEffect(() => {
    if (initial) {
      return;
    }
    if (selectedDist) {
      const filteredFilter = allFilters.filter(
        (each) => Object.keys(each)[0] !== "distribution_types"
      );
      setAllFilters([
        {
          distribution_types: [
            { label: selectedDist.label, value: selectedDist.value },
          ],
        },
        ...filteredFilter,
      ]);
    } else {
      const filteredFilter = allFilters.filter(
        (each) => Object.keys(each)[0] !== "distribution_types"
      );
      setAllFilters(filteredFilter);
      setSelectedDist("");
    }
  }, [selectedDist]);

  useEffect(() => {
    if (initial) {
      return;
    }
    const hasOriginalContent = allFilters.find(
      (each) => Object.keys(each)[0] === "10"
    );
    const hasDownloadFeature = allFilters.find(
      (each) => Object.keys(each)[0] === "20"
    );
    const hasFreeTrial = allFilters.find(
      (each) => Object.keys(each)[0] === "30"
    );
    const hasLiveContent = allFilters.find(
      (each) => Object.keys(each)[0] === "40"
    );
    const hasDist = allFilters.find(
      (each) => Object.keys(each)[0] === "distribution_types"
    );
    const hasPricing = allFilters.find(
      (each) => Object.keys(each)[0] === "pricing"
    );
    const hasSubscribers = allFilters.find(
      (each) => Object.keys(each)[0] === "subscribers"
    );
    const hasCustomSub = getCustomSub();
    if (!hasOriginalContent) {
      setOriginalContent(false);
    }
    if (!hasDownloadFeature) {
      setDownloadFeature(false);
    }
    if (!hasLiveContent) {
      setLiveContent(false);
    }
    if (!hasFreeTrial) {
      setFreeTrial(false);
    }
    if (!hasDist) {
      setSelectedDist(null);
    }
    if (!hasPricing) {
      setPricingVal({ max: pricingMax, min: pricingMin });
    }
    if (!hasSubscribers) {
      setSubscribersVal({ max: 100, min: 0 });
    }
    if (!hasCustomSub) {
      setCustomSubscriber(false);
    }
  }, [allFilters]);

  useEffect(() => {
    if (backToData) {
      navigate("/search/results");
    }
  }, [backToData]);

  const { filteredNames, searchVal, setSearchVal } = useFilteredNames();

  return (
    <>
      <MacScrollbar>
        {/* <section className="max-h-[92vh] overflow-y-auto"> */}
        <section className="h-[80vh] overflow-y-auto mt-[2vh]">
          <section className="flex flex-col">
            <section className="px-8 grid grid-cols-1 md:grid-cols-3 gap-x-5 gap-y-6 text-sm">
              <SelectInput
                heading="Name of Service"
                keyValue="names"
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                z="z-[94]"
                options={filteredNames}
                placeholder="Select Service"
                customSearch={setSearchVal}
                defaultSearch={false}
                long
              />
              <SelectInput
                heading="Country/Region"
                keyValue="countries"
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                z="z-[94]"
                options={allFilterOptions.countries}
                placeholder="Select Country"
                groupSelect
                long
                showInfo="Geographies where service is active"
              />
              <SelectInput
                heading="Platforms"
                keyValue="platform_devices"
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                z="z-[94]"
                options={[
                  {
                    label: "distribution platforms".toUpperCase(),
                    options: allFilterOptions.distribution_platforms,
                  },
                  ...allFilterOptions.device_supports,
                ]}
                placeholder="Select Platforms"
                columnVise
                groupSelect
                showInfo="Distribution on top connected devices, operating systems, [and/or] aggregation platforms"
              />
              {/* <DatePickerInput
            heading="Launch Date"
            keyValue="launch_date"
            allFilters={allFilters}
            setAllFilters={setAllFilters}
          /> */}
              <DateRangePickerInput
                heading="Launch Date"
                keyValue="launch_date"
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                placeholder="Select Launch Date"
              />
              {/* <DateRangePickerInput
              heading="Closed Date"
              keyValue="closed_date"
              allFilters={allFilters}
              setAllFilters={setAllFilters}
              placeholder="Select Closed Date"
            /> */}
              <SelectInput
                heading="Language"
                keyValue="languages"
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                z="z-[93]"
                options={allFilterOptions.languages}
                placeholder="Select Language"
              />
              <SelectInput
                heading="Type of Content"
                keyValue="types_of_content"
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                z="z-[92]"
                options={allFilterOptions.types_of_content}
                placeholder="Select Type of Content"
                // showInfo={`Change to \"Content format\" and show only TVs, Movies, per prior guidance`}
              />
              <SelectInput
                heading="Target Viewer"
                keyValue="target_markets"
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                z="z-[92]"
                options={allFilterOptions.target_markets}
                placeholder="Select Target Viewer"
                showInfo="Audience Segments Service Targets"
              />
              <SelectInput
                heading="Genre of Content"
                keyValue="genres"
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                z="z-[92]"
                options={sortedGenreOptions || []}
                placeholder="Select Genre of Content"
              />
              {/* <SelectInput
            heading="Business Model"
            keyValue="business_model"
            allFilters={allFilters}
            setAllFilters={setAllFilters}
            z="z-[91]"
          /> */}
              <AndOrDropdown
                heading="Business Model"
                keyValue="business_models"
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                z="z-[91]"
                options={allFilterOptions.business_models}
                placeholder="Select Business Model"
                initial={initial}
              />
              <SelectInput
                heading="Resolution"
                keyValue="resolutions"
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                z="z-[91]"
                options={allFilterOptions.resolutions}
                placeholder="Select Resolution"
              />
            </section>
            <section className="w-full grid grid-cols-1 xl:grid-cols-3 gap-x-5 px-8 mt-6 mb-5 text-sm">
              <section className="">
                <div>
                  <div className="flex items-center relative gap-x-1">
                    <p className="font-medium mb-2 mt-1">Subscribers</p>
                    <div className="z-50 w-12 mt-0.5 ml-2 relative">
                      <div
                        onMouseEnter={() => setShowSubToolTip(true)}
                        onMouseLeave={() => setShowSubToolTip(false)}
                      >
                        <InfoIcon size="small" primaryColor="grey" />
                      </div>
                      <AnimatePresence>
                        {showSubToolTip && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="z-50 absolute -top-0 w-fit rounded text-xs text-white left-5"
                          >
                            <p className="w-[12vw] bg-pot-dark-blue px-2 py-1 rounded">
                              Estimated number of subscribers in{" "}
                              {selectedCountry === "CANADA"
                                ? selectedCountry
                                : "the " + selectedCountry}
                              ; for subscription services only; estimates are
                              not available for every service.
                            </p>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                  {/* <section className="pb-5 text-sm">
                  <Button
                    text="Small"
                    onClick={() => setSelectedSubSize("small")}
                    primary={selectedSubscriberSize === "small"}
                    simple
                  />
                  <Button
                    text="Medium"
                    onClick={() => setSelectedSubSize("medium")}
                    primary={selectedSubscriberSize === "medium"}
                    simple
                  />
                  <Button
                    text="Large"
                    onClick={() => setSelectedSubSize("large")}
                    primary={selectedSubscriberSize === "large"}
                    simple
                  />
                </section> */}
                  <div className="flex flex-col w-fit gap-2 mb-4">
                    <Checkbox
                      isChecked={
                        !!allFilters
                          ?.find(
                            (each) => Object.keys(each)[0] === "subscribers"
                          )
                          ?.subscribers?.some(
                            (each) => each.label === subSmall.label
                          )
                      }
                      onChange={() =>
                        handleCheckboxClick("subscribers", subSmall)
                      }
                      label={subSmall.label}
                    />
                    <Checkbox
                      isChecked={
                        !!allFilters
                          ?.find(
                            (each) => Object.keys(each)[0] === "subscribers"
                          )
                          ?.subscribers?.some(
                            (each) => each.label === subMedium.label
                          )
                      }
                      onChange={() =>
                        handleCheckboxClick("subscribers", subMedium)
                      }
                      label={subMedium.label}
                    />
                    <Checkbox
                      isChecked={
                        !!allFilters
                          ?.find(
                            (each) => Object.keys(each)[0] === "subscribers"
                          )
                          ?.subscribers?.some(
                            (each) => each.label === subLarge.label
                          )
                      }
                      onChange={() =>
                        handleCheckboxClick("subscribers", subLarge)
                      }
                      label={subLarge.label}
                    />
                    <Checkbox
                      isChecked={customSubscriber}
                      onChange={() => setCustomSubscriber((prev) => !prev)}
                      label="Custom"
                    />
                  </div>
                  <AnimatePresence>
                    {customSubscriber && (
                      <MultiRangeSlider
                        max={100}
                        min={0}
                        value={subscribersVal}
                        setValue={setSubscribersVal}
                        selected={selectedSubscriberSize}
                      />
                    )}
                  </AnimatePresence>
                </div>
                <AnimatePresence>
                  {customSubscriber && (
                    <RangeInput
                      value={subscribersVal}
                      setValue={setSubscribersVal}
                    />
                  )}
                </AnimatePresence>
              </section>
              <section className="col-span-2 pr-[25vh] 2xl:col-span-1 2xl:pr-0">
                <div>
                  <p className="font-medium mb-1">Pricing</p>
                  <p className=" font-normal mb-4 text-xs text-pot-grey-3">
                    Average monthly price for base plan
                  </p>
                  {/* <MultiRangeSlider
                  max={pricingMax}
                  min={pricingMin}
                  value={pricingVal}
                  setValue={setPricingVal}
                  price
                /> */}
                  <div className="grid grid-cols-2 gap-y-2 2xl:gap-2">
                    {pricingOptions.map((option) => (
                      <Checkbox
                        isChecked={
                          !!allFilters
                            ?.find((each) => Object.keys(each)[0] === "pricing")
                            ?.pricing?.some(
                              (each) => each.label === option.label
                            )
                        }
                        onChange={() => handleCheckboxClick("pricing", option)}
                        label={option.label}
                      />
                    ))}
                  </div>
                </div>
                {/* <RangeInput value={pricingVal} setValue={setPricingVal} price /> */}
              </section>
            </section>
            {/* <section className="px-10 py-8 text-sm">
          <p className="font-medium mb-2">Distribution type</p>
          <Button
            text="Standalone"
            onClick={() => setSelectedDist("standalone")}
            primary={selectedDist === "standalone"}
          />
          <Button
            text="Distributed"
            onClick={() => setSelectedDist("distributed")}
            primary={selectedDist === "distributed"}
          />
        </section> */}

            <section className="grid px-6 grid-cols-1 md:grid-cols-5 2xl:grid-cols-6 gap-x-5 mb-3 mt-8 text-sm">
              <div className="w-fit my-2">
                <div className="flex ">
                  <p className="font-medium mb-3">Distribution Type</p>
                  <div className="z-50 w-2 mt-0.5 ml-2 relative">
                    <div
                      onMouseEnter={() => setShowDisTypToolTip(true)}
                      onMouseLeave={() => setShowDisTypToolTip(false)}
                    >
                      <InfoIcon size="small" primaryColor="grey" />
                    </div>
                    <AnimatePresence>
                      {showDisTypToolTip && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="z-50 absolute -top-0 w-fit rounded text-xs text-white left-5"
                        >
                          <p className="w-[12vw] bg-pot-dark-blue px-2 py-1 rounded">
                            Standalone services have a direct-to-customer app or
                            web presence; distributed [services] reach viewers
                            through a third-party platform or service
                          </p>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
                {allFilterOptions.distribution_types.map((each) => (
                  <>
                    <Radio
                      name="dis_type"
                      isChecked={selectedDist?.label === each.label}
                      onMouseDown={() =>
                        selectedDist?.value === each.value
                          ? setSelectedDist(null)
                          : setSelectedDist(each)
                      }
                      label={
                        <div
                          onClick={() =>
                            selectedDist?.value === each.value
                              ? setSelectedDist(null)
                              : setSelectedDist(each)
                          }
                        >
                          {each.label}
                        </div>
                      }
                    />
                    <div className="h-3"></div>
                  </>
                ))}
                {/*             
            
            <Radio
              name="dis_type"
              isChecked={selectedDist === "Distributed"}
              onMouseDown={() =>
                selectedDist === "Distributed"
                  ? setSelectedDist("")
                  : setSelectedDist("Distributed")
              }
              label="Distributed"
            /> */}
              </div>
              <div className="w-fit my-2">
                <p className="mb-3">Original Content</p>
                <Radio
                  name="org_cont"
                  label={
                    <div
                      onClick={() =>
                        originalContent === "Original Content"
                          ? setOriginalContent("")
                          : setOriginalContent("Original Content")
                      }
                    >
                      Yes
                    </div>
                  }
                  isChecked={originalContent === "Original Content"}
                  onMouseDown={() =>
                    originalContent === "Original Content"
                      ? setOriginalContent("")
                      : setOriginalContent("Original Content")
                  }
                />
                <div className="h-3"></div>
                <Radio
                  name="org_cont"
                  label={
                    <div
                      onClick={() =>
                        originalContent === "No Original Content"
                          ? setOriginalContent("")
                          : setOriginalContent("No Original Content")
                      }
                    >
                      No
                    </div>
                  }
                  isChecked={originalContent === "No Original Content"}
                  onMouseDown={() =>
                    originalContent === "No Original Content"
                      ? setOriginalContent("")
                      : setOriginalContent("No Original Content")
                  }
                />
              </div>
              <div className="w-fit my-2">
                <p className="mb-3">Live Content</p>
                <Radio
                  name="liv_cnt"
                  label={
                    <div
                      onClick={() =>
                        liveContent === "Live Content"
                          ? setLiveContent("")
                          : setLiveContent("Live Content")
                      }
                    >
                      Yes
                    </div>
                  }
                  isChecked={liveContent === "Live Content"}
                  onMouseDown={() =>
                    liveContent === "Live Content"
                      ? setLiveContent("")
                      : setLiveContent("Live Content")
                  }
                />
                <div className="h-3"></div>
                <Radio
                  name="liv_cnt"
                  label={
                    <div
                      onClick={() => {
                        liveContent === "No Live Content"
                          ? setLiveContent("")
                          : setLiveContent("No Live Content");
                      }}
                    >
                      No
                    </div>
                  }
                  isChecked={liveContent === "No Live Content"}
                  onMouseDown={() =>
                    liveContent === "No Live Content"
                      ? setLiveContent("")
                      : setLiveContent("No Live Content")
                  }
                />
              </div>

              <div className="w-fit my-2">
                <div className="flex">
                  <p className="mb-3">Download Feature</p>
                  <div className="z-50 w-2 mt-0.5 ml-2 relative">
                    <div
                      onMouseEnter={() => setShowDownToolTip(true)}
                      onMouseLeave={() => setShowDownToolTip(false)}
                    >
                      <InfoIcon size="small" primaryColor="grey" />
                    </div>
                    <AnimatePresence>
                      {showDownToolTip && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="z-50 absolute -top-0 w-fit rounded text-xs text-white left-5"
                        >
                          <p className="w-[12vw] bg-pot-dark-blue px-2 py-1 rounded">
                            Ability to download titles for offline viewing
                          </p>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
                <Radio
                  name="dwn_ftr"
                  label={
                    <div
                      onClick={() =>
                        downloadFeature === "Download Feature"
                          ? setDownloadFeature("")
                          : setDownloadFeature("Download Feature")
                      }
                    >
                      Yes
                    </div>
                  }
                  isChecked={downloadFeature === "Download Feature"}
                  onMouseDown={() =>
                    downloadFeature === "Download Feature"
                      ? setDownloadFeature("")
                      : setDownloadFeature("Download Feature")
                  }
                />
                <div className="h-3"></div>
                <Radio
                  name="dwn_ftr"
                  label={
                    <div
                      onClick={() =>
                        downloadFeature === "No Download Feature"
                          ? setDownloadFeature("")
                          : setDownloadFeature("No Download Feature")
                      }
                    >
                      No
                    </div>
                  }
                  isChecked={downloadFeature === "No Download Feature"}
                  onMouseDown={() =>
                    downloadFeature === "No Download Feature"
                      ? setDownloadFeature("")
                      : setDownloadFeature("No Download Feature")
                  }
                />
              </div>
              <div className="w-fit my-2">
                <p className="mb-3">Free Trial</p>
                <Radio
                  name="free_trial"
                  label={
                    <div
                      onClick={() =>
                        freeTrial === "Free Trial"
                          ? setFreeTrial("")
                          : setFreeTrial("Free Trial")
                      }
                    >
                      Yes
                    </div>
                  }
                  isChecked={freeTrial === "Free Trial"}
                  onMouseDown={() =>
                    freeTrial === "Free Trial"
                      ? setFreeTrial("")
                      : setFreeTrial("Free Trial")
                  }
                />
                <div className="h-3"></div>
                <Radio
                  name="free_trial"
                  label={
                    <div
                      onClick={() =>
                        freeTrial === "No Free Trial"
                          ? setFreeTrial("")
                          : setFreeTrial("No Free Trial")
                      }
                    >
                      No
                    </div>
                  }
                  isChecked={freeTrial === "No Free Trial"}
                  onMouseDown={() =>
                    freeTrial === "No Free Trial"
                      ? setFreeTrial("")
                      : setFreeTrial("No Free Trial")
                  }
                />
              </div>
            </section>
          </section>
        </section>
      </MacScrollbar>
      <div className="border-t flex justify-between items-center h-[10vh] px-5 z-[94] sticky bottom-0 bg-white mt-[1vh]">
        <section>
          <Button
            text="Apply"
            onClick={() => {
              setMainFilters(allFilters);
              setBackToData(true);
            }}
            primary
          />
          <Button
            text="Cancel"
            onClick={() => {
              setBackToData(true);
            }}
          />
        </section>
        <motion.button
          whileHover={{ scale: 1.1 }}
          onClick={() => {
            setAllFilters([]);
          }}
          className="ml-10 flex items-center text-pot-dark-blue float-right"
        >
          <RefreshIcon size="small" />
          <p className="ml-1">Reset</p>
        </motion.button>
      </div>
    </>
  );
};

export default FilterFields;
