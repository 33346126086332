import { PDFViewer } from "@react-pdf/renderer";
import { DotPulse } from "@uiball/loaders";
import React, { useEffect, useState } from "react";
import { LibraryApi } from "../../../api/library";
import PdfViewerModal from "./PdfViewerModal";
import { btoa } from "../../../utils/helperFunc";
import { getFromLocalStorage } from "../../../utils/localStorage";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./PdfViewer.css";

// import "react-pdf/dist/esm/Page/TextLayer.css";
// import { pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = ({ article, handleCancel, setPdfFile, pdfFile }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  //   const fetchPdfFile = async () => {
  //     const res = await LibraryApi.download(article.file_url, true);
  //     console.log(res, "hello")
  //     if (res.status === 404) {
  //       setPdfFile("No data");
  //       return;
  //     }
  //     // const url = URL.createObjectURL(res.data);
  //       const base64 = btoa(
  //         new Uint8Array(res.data).reduce(
  //           (data, byte) => data + String.fromCharCode(byte),
  //           ""
  //         )
  //       );
  //     setPdfFile(base64);
  //   };

  const fetchPdfFile = async () => {
    const request = new Request(article.file_url, {
      method: "GET",
      headers: {
        Authorization: `Token ${getFromLocalStorage("token")}`,
      },
    });
    const res = await fetch(request);
    const resBlob = await res.blob();
    // const file = window.URL.createObjectURL(resBlob)
    setPdfFile(resBlob);
  };

  useEffect(() => {
    if (pdfFile) return;
    fetchPdfFile();
  }, []);

  return (
    <PdfViewerModal article={article} handleCancel={handleCancel}>
      {pdfFile ? (
        <div>
          <Document
            className="pdf-doc"
            file={pdfFile}
            onContextMenu={(e) => e.preventDefault()}
            onLoadError={(e) => console.log(e)}
            options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {numPages > 0 &&
              Array.from(new Array(numPages), (el, index) => {
                // const translate = `-translate-y-[${80 * index}vh]`
                return (
                  <Page
                    className="pb-6"
                    renderMode={index === 0 ? "canvas" : "svg"} // If first page then renderMode will be canvas else svg
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                );
              })}
          </Document>
          {/* <p>
          Page {pageNumber} of {numPages}
        </p> */}
        </div>
      ) : (
        // <embed onContextMenu={(e) => e.preventDefault()} src={`${pdfFile}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`} className="w-[60vw] h-full" />
        <div className="h-full min-h-[70vh] main-bg flex items-center">
          <DotPulse color="white" />
        </div>
      )}
    </PdfViewerModal>
  );
};

export default PdfViewer;
