import React, { useEffect, useState } from "react";
import Control from "./Control";
import TypeButtons from "./TypeButtons";
import ViewersTable from "./Table";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  FilterValuesAtom,
  LoadingProgressAtom,
  QuarterRangeAtom,
  SelectedProfileCountryAtom,
  TableLoadingAtom,
  AllNamesAtom
} from "../../../atom";
import { OttDataApi } from "../../../api/ottData";
import { formatToParams } from "../../../utils/formatConfig";
import { toast } from "react-toastify";

const ViewersTrend = () => {
  const [type, setType] = useState("PAID_SUBS");
  const [fetchedDetails, setFetchedDetails] = useState([]);

  // for setting quarter
  const quarterRange = useRecoilValue(QuarterRangeAtom);
  const endQuarter = Number(quarterRange.max.split(" ")[0]) - 1;
  const endQuarterYear = Number(quarterRange.max.split(" ")[1]);
  const modableVal = endQuarter - 11;
  const startQuarter =
    modableVal % 4 < 0 ? 4 + (modableVal % 4) : modableVal % 4;
  const diff =
    modableVal % 4 !== 0
      ? Math.trunc(Math.abs(modableVal) / 4 + 1)
      : Math.trunc(Math.abs(modableVal) / 4);
  const startQuarterYear = endQuarterYear - diff;
  // const startQuarter = Number(quarterRange.min.split(" ")[0]) - 1
  // const startQuarterYear = Number(quarterRange.min.split(" ")[1])
  const [quarterStart, setQuarterStart] = useState([
    startQuarter,
    startQuarterYear,
  ]);
  const [quarterEnd, setQuarterEnd] = useState([endQuarter, endQuarterYear]);

  const [sortableKey, setSortableKey] = useState(
    `- ${[endQuarter + 1, endQuarterYear].join(" ")}`
  );
  const [searchVal, setSearchVal] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [length, setLength] = useState(10);
  const setLoading = useSetRecoilState(TableLoadingAtom);
  const setLoadingProgress = useSetRecoilState(LoadingProgressAtom);
  // const mainFilters = useRecoilValue(FilterValuesAtom);
  const [mainFilters, setMainFilters] = useRecoilState(FilterValuesAtom);
  const [showDownloadLoading, setShowDownloadLoading] = useState(false);
  const filter = useRecoilValue(FilterValuesAtom);
  // const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom)
  const [selectedProfileCountry, setSelectedProfileCountry] = useRecoilState(SelectedProfileCountryAtom);
  const allNames = useRecoilValue(AllNamesAtom)
  const [firstLoad, setFirstLoad] = useState(true);

  const fetchTrend = async () => {
    setLoadingProgress(20);
    // const filterParam = formatToParams(mainFilters); // Original
    const queryParameters = new URLSearchParams(window.location.search);
    var specific_profile = queryParameters.get("profile");
    const specific_region = queryParameters.get("region");
    const specific_type = queryParameters.get("type");

    if (specific_region !== null) {
      if (selectedProfileCountry != specific_region && !firstLoad) {
        // In case the region was changed from the original profile
        for(var key in allNames[specific_region]) {
          if(allNames[specific_region][key] === specific_profile) {
              // Got the name of the profile from its ID
              // Now finding ID in other region
              const newId = allNames[selectedProfileCountry][key]
              if (newId) {
                specific_profile = newId
              } else {
                toast.error(`We do not have ${key} for this Region`)
              }
          }
        }
      } else {
        setSelectedProfileCountry(specific_region);
      }
      if (["PAID_SUBS", "MONTHLY_VIEWERS", "TRANSACTIONAL_CUSTOMERS"].includes(specific_type) && firstLoad) {
        setType(specific_type)
      }
      setFirstLoad(false)
    }
    const filterParam = specific_profile != null ? formatToParams([{"names": [{"value": specific_profile}]}]) : formatToParams(mainFilters);
    
    const settableStartQuarter = [Number(quarterStart[0]) + 1, quarterStart[1]];
    const settableEndQuarter = [Number(quarterEnd[0]) + 1, quarterEnd[1]];
    const payload = {
      viewers_trend: {
        q_range: {
          start: settableStartQuarter.join(" "),
          end: settableEndQuarter.join(" "),
        },
        kind: type,
      },
      filters: filterParam,
    };
    const params = {
      result_type: "viewers_trend",
      search: searchVal,
      length,
      page,
      profile_country: selectedProfileCountry
    };
    if (sortableKey) {
      payload.viewers_trend.sort_by = sortableKey;
    }
    setLoadingProgress(40);
    const { data: response } = await OttDataApi.list(payload, { params });
    setLoadingProgress(80);
    setFetchedDetails(response.data.results);
    setTotalCount(response.data.total_count);
    setTotalPages(response.data.total_pages);
    setLoading(false);
    setLoadingProgress(100);
  };

  const downloadFile = async () => {
    setShowDownloadLoading(true);
    const queryParameters = new URLSearchParams(window.location.search);
    var specific_profile = queryParameters.get("profile");
    const specific_region = queryParameters.get("region");
    if (specific_region !== null) {
      if (selectedProfileCountry != specific_region && !firstLoad) {
        // In case the region was changed from the original profile
        for(var key in allNames[specific_region]) {
          if(allNames[specific_region][key] === specific_profile) {
              // Got the name of the profile from its ID
              // Now finding ID in other region
              const newId = allNames[selectedProfileCountry][key]
              if (newId) {
                specific_profile = newId
              } else {
                toast.error(`We do not have ${key} for this Region`)
              }
          }
        }
      } else {
        setSelectedProfileCountry(specific_region);
      }
      setFirstLoad(false)
    }
    const filterParam = specific_profile != null ? formatToParams([{"names": [{"value": specific_profile}]}]) : formatToParams(mainFilters);
    const settableStartQuarter = [Number(quarterStart[0]) + 1, quarterStart[1]];
    const settableEndQuarter = [Number(quarterEnd[0]) + 1, quarterEnd[1]];
    setLoadingProgress(20);
    const payload = {
      viewers_trend: {
        q_range: {
          start: settableStartQuarter.join(" "),
          end: settableEndQuarter.join(" "),
        },
        kind: type,
      },
      filters: filterParam,
    };
    const params = {
      result_type: "viewers_trend",
      search: searchVal,
      length,
      page,
      profile_country: selectedProfileCountry,
      download: 1,
    };
    if (sortableKey) {
      payload.viewers_trend.sort_by = sortableKey;
    }
    setLoadingProgress(40);
    const response = await OttDataApi.list(payload, {
      params,
      responseType: "blob",
    });
    if (response.status !== 200) {
      const errorString = await response.data?.text();
      const errorObj = JSON.parse(errorString);
      toast.error(errorObj.errors.error_message.join(", "));
      setLoadingProgress(100);
      return;
    }
    setLoadingProgress(80);
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.target = "_blank";
    link.setAttribute("download", "viewers_trend.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    setLoadingProgress(100);
    setShowDownloadLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (quarterEnd && !sortableKey) {
      setSortableKey(`- ${[quarterEnd[0] + 1, quarterEnd[1]].join(" ")}`);
      return;
    }
    setFetchedDetails([]);
    fetchTrend();
  }, [
    type,
    quarterStart,
    quarterEnd,
    sortableKey,
    page,
    length,
    filter,
  ]);

  useEffect(() => {
    if(page === 1) {
      setLoading(true);
      setFetchedDetails([]);
      fetchTrend();
    } else {
      setPage(1)
    }
  }, [searchVal,])

  return (
    <>
      <div className="px-5">
        <TypeButtons
          active={type}
          setActive={setType}
          setSearchVal={setSearchVal}
          quarterEnd={quarterEnd}
          quarterStart={quarterStart}
          setQuarterEnd={setQuarterEnd}
          setQuarterStart={setQuarterStart}
          downloadFile={downloadFile}
          showDownloadLoading={showDownloadLoading}
        />
      </div>
      <ViewersTable
        type={type}
        rawData={fetchedDetails}
        quarterStart={quarterStart}
        quarterEnd={quarterEnd}
        setSortableKey={setSortableKey}
        sortableKey={sortableKey}
        totalCount={totalCount}
        totalPages={totalPages}
        setLength={setLength}
        length={length}
        page={page}
        setPage={setPage}
      />
    </>
  );
};

export default ViewersTrend;
