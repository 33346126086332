import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { AllFilterOptionsAtom, CanadaOptionsAtom } from "../atom";
import DateContainer from "../components/DatePicker/DateContainer";
import DatePickerInputTwo from "../components/DatePicker/Two";
import { formatNamesToObj } from "../utils/formatConfig";
import SelectInput from "./components/SelectInput";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import { useRef } from "react";
import Paste from "../assets/Paste";
import useFilteredNames from "../hooks/useFilteredNames";

const LibraryForm = ({
  setName,
  name,
  setPDate,
  setOtts,
  setCanOtts,
  editData,
  otts,
  canOtts,
  clear,
  errors = {},
  setError,
}) => {
  const [ottList, setOttList] = useState([{ names: [] }]);
  const [canadaOttList, setCanadaOttList] = useState([{ names: [] }]);
  const [ottObj, setOttObj] = useState({});
  const [canOttObj, setCanOttObj] = useState({});
  const allFilterOptions = useRecoilValue(AllFilterOptionsAtom);
  const canadaFilterOptions = useRecoilValue(CanadaOptionsAtom);
  const onOttsPaste = async (setter, type) => {
    const text = await window.navigator.clipboard.readText();
    const ottSettableList = [];
    const formattedText = text.replaceAll("\r\n", "\n");
    formattedText.split("\n").forEach((each) => {
      let originalItem = findOriginalKey(
        type === "US" ? ottObj : canOttObj, // Use canOttObj for Canada profiles
        each
      );
      if (originalItem) {
        ottSettableList.push({
          label: originalItem,
          value: type === "US" ? ottObj[originalItem] : canOttObj[originalItem], // Use canOttObj for Canada profiles
        });
      }
    });
    setter([{ names: ottSettableList }]);
  };

  useEffect(() => {
    if (!ottList[0].names.length) return;
    setOtts([...ottList[0].names]);
    if (errors.profile_tags) {
      delete errors.profile_tags;
      setError({ ...errors });
    }
  }, [ottList]);

  useEffect(() => {
    if (!canadaOttList[0].names.length) return;
    setCanOtts([...canadaOttList[0].names]);
    if (errors.profile_tags) {
      delete errors.profile_tags;
      setError({ ...errors });
    }
  }, [canadaOttList]);

  useEffect(() => {
    if (canOtts.length) return;
    setCanadaOttList([{ names: [] }]);
  }, [canOtts]);

  useEffect(() => {
    if (otts.length) return;
    setOttList([{ names: [] }]);
  }, [otts]);

  useEffect(() => {
    if (editData) {
      setOttList([
        {
          names: [
            ...editData.profile_tags
              .filter((each) => each.profile_country === "US")
              .map((each) => ({
                label: each.name,
                value: each.id,
              })),
          ],
        },
      ]);
      setCanadaOttList([
        {
          names: [
            ...editData.profile_tags
              .filter((each) => each.profile_country === "CANADA")
              .map((each) => ({
                label: each.name,
                value: each.id,
              })),
          ],
        },
      ]);
    } else {
      setOttList([{ names: [] }]);
      setCanadaOttList([{ names: [] }]);
    }
  }, [editData]);

  useEffect(() => {
    if (allFilterOptions.names) {
      const names = formatNamesToObj(allFilterOptions.names);
      setOttObj(names);
      const canNames = formatNamesToObj(canadaFilterOptions.names);
      setCanOttObj(canNames);
    }
  }, [allFilterOptions]);

  const findOriginalKey = (data, lowercaseKey) => {
    // Convert the lowercaseKey to lowercase
    const lowercaseInput = lowercaseKey.toLowerCase();

    // Iterate through the object keys and find the matching original key
    for (const key in data) {
      if (key.toLowerCase() === lowercaseInput) {
        return key;
      }
    }

    // Return a message or handle the case when the key is not found
    return "";
  };

  // Paste button component
  const PasteButton = ({ type }) => {
    return (
      <button
        onClick={async () => {
          const text = await window.navigator.clipboard.readText();
          const ottSettableList = [];
          const formattedText = text.replaceAll("\r\n", "\n");
          formattedText.split("\n").forEach((each) => {
            let currentObj = type === "US" ? ottObj : canOttObj;
            let originalItem = findOriginalKey(currentObj, each);
            if (originalItem) {
              ottSettableList.push({
                label: originalItem,
                value: currentObj[originalItem],
              });
            }
          });
          if (type === "US") {
            setOttList([{ names: ottSettableList }]);
          } else if (type === "CANADA") {
            setCanadaOttList([{ names: ottSettableList }]);
          }
        }}
        className="ml-2 w-fit px-4 rounded-md h-4"
      >
        <Paste size={"4"} />
      </button>
    );
  };

  return (
    <>
      <div className="w-full flex">
        <div className="w-full flex gap-x-4 text-tiny">
          <div className="w-full flex flex-col gap-y-3">
            <label class>
              <span className="font-semibold">Name</span>
              <input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (errors.name) {
                    delete errors.name;
                    setError({ ...errors });
                  }
                }}
                placeholder="Name of the file"
                className={`w-full mt-1 placeholder:text-pot-t-grey text-xs box-border outline-none focus:bg-white focus:border-pot-light-blue-2 bg-[#EBECF0]/60 hover:bg-[#DFE1E6]/60 border-2 border-[#EBECF0]/60 transition ease-in-out duration-200 flex justify-between p-2 rounded-sm`}
              />
              {errors.name && (
                <p className="text-xs text-pot-red">{errors.name.join(", ")}</p>
              )}
            </label>
            <div className="z-50">
              <SelectInput
                heading="United States Profiles mentioned"
                keyValue="names"
                allFilters={ottList}
                setAllFilters={setOttList}
                options={allFilterOptions.names}
                placeholder="Type something"
                long
                bold
                dontCleanState
                z={"z-50"}
                onPaste={() => onOttsPaste(setOttList, "US")}
                validateInputForPasteClear
                rightButton={<PasteButton type="US" />}
              />
              {errors.profile_tags && (
                <p className="text-xs text-pot-red">
                  {errors.profile_tags.join(", ")}
                </p>
              )}
            </div>
            <div className="flex flex-wrap gap-y-2 gap-x-3 mt-2">
              {ottList[0]?.names?.map((ott, index) => (
                <div
                  className="w-fit bg-pot-bg-blue px-2 py-0.5 text-xs flex items-center gap-x-2"
                  key={index}
                >
                  {ott.label}
                  <button
                    className="flex items-center"
                    onClick={() => {
                      setOttList([
                        {
                          names:
                            ottList[0]?.names?.filter((each) => each !== ott) ||
                            [],
                        },
                      ]);
                      setOtts((prev) => {
                        return prev.filter((each) => each.value !== ott.value);
                      });
                    }}
                  >
                    <EditorCloseIcon size="small" />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full self-stretch flex flex-col gap-y-3">
            <div>
              <DatePickerInputTwo
                placeholder="Select publication date"
                clear={clear}
                editData={editData}
                setPickedDate={setPDate}
              />
              {errors.publication_date && (
                <p className="text-xs text-pot-red">
                  {errors.publication_date.join(", ")}
                </p>
              )}
            </div>
            <div className="z-[45]">
              <SelectInput
                heading="Canada Profiles mentioned"
                keyValue="names"
                allFilters={canadaOttList}
                setAllFilters={setCanadaOttList}
                options={canadaFilterOptions.names}
                placeholder="Type something"
                long
                bold
                dontCleanState
                z={"z-50"}
                onPaste={() => onOttsPaste(setCanadaOttList, "CANADA")}
                validateInputForPasteClear
                rightButton={<PasteButton type="CANADA" />}
              />
            </div>
            <div className="flex flex-wrap gap-y-2 gap-x-3 mt-2">
              {canadaOttList[0]?.names?.map((ott) => (
                <div
                  className="w-fit bg-pot-bg-blue px-2 py-0.5 text-xs flex items-center gap-x-2"
                  key={ott}
                >
                  {ott.label}
                  <button
                    className="flex items-center"
                    onClick={() => {
                      setCanadaOttList([
                        {
                          names:
                            canadaOttList[0]?.names?.filter(
                              (each) => each !== ott
                            ) || [],
                        },
                      ]);
                      setCanOtts((prev) => {
                        return prev.filter((each) => each.value !== ott.value);
                      });
                    }}
                  >
                    <EditorCloseIcon size="small" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(LibraryForm);
