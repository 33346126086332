import { useState, useEffect } from "react";

const useViewersData = (rawData, type) => {
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    const settableData = [];
    rawData.forEach((each) => {
      // const header = Object.keys(each)[0]
      // const subs = each[header].subscribers
      // const qoq = each[header].qoq
      // const yoy = each[header].yoy
      const header = each.name;
      const subs = each.subs;
      const qoq = each.qoq_growth;
      const yoy = each.yoy_growth;
      settableData.push({ name: header, type: "serviceName" });
      // settableData.push({ name: "subscribers", type: "subs", ...subs})
      settableData.push({
        name:
          type === "PAID_SUBS"
            ? "Subscribers"
            : type === "MONTHLY_VIEWERS"
            ? "Viewers"
            : "Transactional Customers",
        type: "subs",
        ...subs,
      });
      settableData.push({ name: "QoQ Growth", type: "qoq", ...qoq });
      settableData.push({ name: "YoY Growth", type: "yoy", ...yoy });
    });
    setFormattedData(settableData);
  }, [rawData]);

  return formattedData;
};

export default useViewersData;
