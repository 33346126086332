import React, { useState } from "react";
import Edit from "../../assets/Edit";
import { DotPulse } from "@uiball/loaders";
import { DataImportApi } from "../../api/dataImport";
import { toast } from "react-toastify";

export const GreenLoadingButton = () => {
  return (
    <button className="bg-pot-dark-blue/10 w-[184px] py-[12px] flex justify-center rounded-md">
      <DotPulse size={20} color="#1767A5" speed={0.8} />
    </button>
  );
};

const Download = () => {
  const [loading, setLoading] = useState(false);

  const downloadData = async (type) => {
    try {
      setLoading(true);
      const response = await DataImportApi.subDownloadSample();
      if (response.status !== 200) {
        const errorString = await response.data.text()
        const errorObj = JSON.parse(errorString)
        toast.error(errorObj.errors.error_message.join(", "));
        setLoading("");
        return;
      }
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.target = "_blank";
      link.setAttribute("download", `pre_filled.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLoading(false);
    } catch (e) {
      toast.error(JSON.stringify(e));
      setLoading(false);
    }
  };

  return (
    <div className="w-full px-10 min-h-[8vh] flex justify-end items-center gap-3 ">
      {loading ? (
        <GreenLoadingButton />
      ) : (
        <button
          onClick={() => downloadData("pre-filled")}
          className="bg-pot-bg-blue/90 h-fit text-pot-blue flex text-sm items-center gap-2 px-4 py-1 rounded-md"
        >
          <Edit fill="#1767A5" />
          Download Prefilled
        </button>
      )}
    </div>
  );
};

export default Download;
