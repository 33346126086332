import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import ColumnView from "./screensCustomer/ColumnView";
import DetailView from "./screensCustomer/DetailView";
import SearchView from "./screensCustomer/SearchView";
import CustomerNavigation from "./navigationOutlet/Customer";
import AdminNavigation from "./navigationOutlet/Admin";
import AdminList from "./screensAdmin/AdminList";
import { Route, Routes, Navigate } from "react-router-dom";
import ForgotPassword from "./screensAuth/ForgotPassword";
import { useEffect, useState } from "react";
import { registerIntercepts } from "./api/axios";
import AdminDetails from "./screensAdmin/AdminList/Details";
import OrganisationList from "./screensAdmin/OrganisationList";
import OrganizationDetails from "./screensAdmin/OrganisationList/Details";
import ChangePassword from "./screensCustomer/ChangePassword";
import UploadData from "./screensAdmin/UploadData";
import Dashboard from "./screensCustomer/Dashboard";
import Contact from "./screensCustomer/Contact";
import MainLogin from "./screensAuth/MainLogin";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyUpload from "./screensAdmin/UploadData/Verify";
import UploadVerifySuccess from "./screensAdmin/UploadData/VerifySuccess";
import DetailsView from "./screensCustomer/DetailsView";
import ErrorBoundary from "./components/ErrorBoundary";
import ViewersTrend from "./screensCustomer/ViewerTrend";
import { GlobalScrollbar } from "mac-scrollbar";
import "mac-scrollbar/dist/mac-scrollbar.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Partytown } from "@builder.io/partytown/react";
import AdminDashboard from "./screensAdmin/AdminDashboard";
import NewPassword from "./screensAuth/NewPassword";
import useLoggedInControl from "./hooks/useLoggedInControl";
import LibraryData from "./screensAdmin/LibraryData";
// import { appInfo } from "./api/core";
// import { useSetRecoilState } from "recoil";
// import { AppInfoAtom } from "./atom";
import Articles from "./screensCustomer/Articles";

// PLAN_VERSION: comment the below 2 lines < Line 42 - 43 > - DONE
// import CustomerManage from "./screensAdmin/CustomerManage";
// import CustomerDetails from "./screensAdmin/CustomerManage/Details";
import UploadSubscribers from "./screensAdmin/UploadSubscribers";
import ServiceComparison from "./screensCustomer/CompareView/ServiceComparison";
import PlatFormComparison from "./screensCustomer/CompareView/PlatformComparison";
import withClearCache from "./ClearCache";

// PLAN_VERSION: uncomment the below 2 lines < Line 47 - 48 > - DONE
import CustomerList from "./screensAdmin/CustomerList";
import CustomerDetails from "./screensAdmin/CustomerList/Details";
import { DotPulse } from "@uiball/loaders";
import Feedback from "./screensCustomer/Feedback/Feedback";
import FAQ from "./screensCustomer/FAQ/FAQ";
import Joyride from "react-joyride";
import CustomWalkthroughStyling from "./utils/customWalkthroughStyling";
import { walkthroughSteps } from "./utils/walkthroughSteps";
import { AccountUserAtom } from "./atom";
import { useRecoilValue } from "recoil";
import { WalkthroughApi } from "./api/walkthrough";
import "./styles/CustomWalkthrough.css";
import NPS from "./screensCustomer/NPSandChurn/NPS/NPS";
import SendEmail from "./screensAdmin/EmailPages/SendEmail";
import EmailTemplate from "./screensAdmin/EmailPages/EmailTemplate";
import TemplateForm from "./screensAdmin/EmailPages/TemplateForm";
import NonStandardCharts from "./screensAdmin/NPSandChurn/NonStandardChart/NonStandardCharts";
import NPSUploadData from "./screensAdmin/NPSandChurn/UploadData/NPSUploadData";
import Churn from "./screensCustomer/NPSandChurn/Churn/Churn";

// import { Helmet } from "react-helmet";

const queryClient = new QueryClient();

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return (
    <BrowserRouter>
      <ClearCacheComponent />
    </BrowserRouter>
  );
}

function MainApp() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  // const setAppInfoData = useSetRecoilState(AppInfoAtom)
  useLoggedInControl();

  // if (!isLoggedIn) {
  //   return(<BrowserRouter>
  //       <AnimatedRoutes isLoggedIn={isLoggedIn} />
  //   </BrowserRouter>);
  // }

  // const removeServiceWorkers = async () => {
  //   navigator.serviceWorker.getRegistrations().then((registrations) => {
  //     registrations.forEach((registration, id) => {
  //       console.log("registration", id, registration)
  //     });
  //   });
  //   caches.keys().then((keyList) => console.log(keyList, "KeyList"))
  // }

  // useEffect(() => {
  //   removeServiceWorkers()
  // }, [])

  // const fetchAppInfo = async () => {
  //   const { data: response } = await appInfo();
  //   const settableAppInfo = { ...response.data };
  //   settableAppInfo.country_codes = [...new Set(settableAppInfo.country_codes)];
  //   setAppInfoData(settableAppInfo);
  // };

  // useEffect(() => {
  //   fetchAppInfo();
  // }, []);

  useEffect(() => {
    registerIntercepts(setLoading, setServerErrorMessage);
    setLoading(false);
    document.title = "Streaming Video Tracker";
  }, []);

  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/partytown";
    // s.type = "text/javascript";
    s.innerHTML = process.env.REACT_APP_TRACKING_CODE;
    // console.log(s, process.env.REACT_APP_TRACKING_CODE, "Hello")
    document.body.appendChild(s);
  }, []);

  useEffect(() => {
    if (!process.env.REACT_APP_GA_CODE) {
      console.log("DEVELOPMENT BRANCH");
      return;
    }
    console.log("DEVELOPMENT BRANCH", process.env.REACT_APP_GA_CODE);
    const s = document.createElement("script");
    const sc = document.createElement("script");
    sc.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_CODE}`;
    sc.type = "text/partytown";
    sc.async = true;
    s.type = "text/partytown";
    // s.type = "text/javascript";
    s.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', '${process.env.REACT_APP_GA_CODE}');`;
    console.log(s, sc, "Hello");
    document.body.appendChild(sc);
    document.body.appendChild(s);
  }, []);

  useEffect(() => {
    if (serverErrorMessage) {
      toast.error(serverErrorMessage);
      setServerErrorMessage("");
    }
  }, [serverErrorMessage]);

  // JOYRIDE STATES
  const [startWalkThrough, setStartWalkThrough] = useState(false);
  const [target, setTarget] = useState();
  const user = useRecoilValue(AccountUserAtom);
  const [{ run, steps }, setState] = useState({
    run: true,
    steps: walkthroughSteps, // walkthrough steps
  });
  const customStyling = CustomWalkthroughStyling(location.pathname, target);

  const handleCallback = async (data) => {
    let target = data.step.target;
    setTarget(target);
    if (data.type === "step:close" || data.type === "tour:end") {
      setStartWalkThrough(false);
    }
    let redirectto =
      target === ".step_2"
        ? "/search"
        : target === ".step_3"
        ? "/viewers-trend"
        : target === ".step_4"
        ? "/library"
        : "/dashboard";
    if (data.status === "running") {
      navigate(redirectto);
    }
    if (data.status === "skipped" || data.status === "finished") {
      let status = data.status === "skipped" ? "SKIPPED" : "COMPLETED";
      const { data: response } = await WalkthroughApi.update(status);
    }
  };

  // If walkthrough_autostart is true, the walkthrough will start
  useEffect(() => {
    if (user?.walkthrough_autostart === true) {
      setStartWalkThrough(true);
    }
  }, [user]);

  if (loading) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center pb-[14vh]">
        <DotPulse size={40} color="#1767A5" />
      </div>
    );
  }

  return (
    <div className="font-popins">
      <ToastContainer
        position="top-center"
        theme="colored"
        newestOnTop={false}
        closeOnClick
        pauseOnHover
        rtl={false}
      />
      <Joyride
        run={run && startWalkThrough}
        steps={steps}
        continuous
        hideCloseButton={true}
        disableScrollParentFix={true}
        disableScrolling={true}
        disableOverlayClose={true}
        showSkipButton={true}
        showProgress
        styles={customStyling}
        callback={(data) => handleCallback(data)}
      />
      {/* <Helmet>
        <script dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_TRACKING_CODE }} type="text/javascript" />
      </Helmet> */}
      <GlobalScrollbar />
      <Partytown
        debug={true}
        resolveUrl={(url, location, type) => {
          if (type === "script") {
            var proxyUrl = new URL(
              `https://www.ottvideotracker.com/proxy/www.clarity.ms${url.pathname.replace(
                "proxy/www.clarity.ms",
                ""
              )}`
            );
            // console.log("Hello", proxyUrl, url.pathname);
            return proxyUrl;
          }
          return url;
        }}
      />
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/admin" element={<AdminNavigation />}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="email-template" element={<EmailTemplate />} />
            <Route path="send-email" element={<SendEmail />} />
            <Route path="email-template/create" element={<TemplateForm />} />
            <Route path="email-template/edit/:id" element={<TemplateForm />} />
            <Route path="user/admin" element={<AdminList />} />
            <Route path="user/admin/create" element={<AdminDetails />} />
            <Route path="user/admin/edit/:id" element={<AdminDetails />} />
            <Route path="nps-churn/upload-data" element={<NPSUploadData />} />
            <Route
              path="nps-churn/nps-standard-charts"
              element={<NonStandardCharts />}
            />

            {/* PLAN_VERSION: uncomment the below block < Line 177 > - DONE */}
            <Route path="user/customer" element={<CustomerList />} />

            {/* PLAN_VERSION: comment the below lines < Line 180 > - DONE */}
            {/* <Route path="user/customer" element={<CustomerManage />} /> */}

            <Route path="user/customer/create" element={<CustomerDetails />} />
            <Route
              path="user/customer/edit/:id"
              element={<CustomerDetails />}
            />
            <Route path="user/organization" element={<OrganisationList />} />
            <Route
              path="user/organization/create"
              element={<OrganizationDetails />}
            />
            <Route
              path="user/organization/edit/:id"
              element={<OrganizationDetails />}
            />
            <Route
              path="data/upload/verifying/:id"
              element={<VerifyUpload />}
            />
            <Route
              path="data/upload/result/:id"
              element={<UploadVerifySuccess />}
            />
            <Route
              path="data/upload/result/error"
              element={<UploadVerifySuccess />}
            />
            <Route
              path="nps-churn/upload-data/result"
              element={<UploadVerifySuccess type="nps" />}
            />
            <Route path="data/upload" element={<UploadData />} />
            <Route
              path="data-subscribers/upload"
              element={<UploadSubscribers />}
            />
            <Route path="data/library" element={<LibraryData />} />
            <Route path="" element={<Navigate to="/admin/dashboard" />} />
            <Route path="*" element={<Navigate to="/page-not-found" />} />
          </Route>
          <Route path="/new-password/:token" element={<NewPassword />} />
          <Route path="/login" element={<MainLogin />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/* <Route path="/change-password" element={<ChangePassword />} /> */}
          <Route path="/page-not-found" element={<ErrorBoundary />} />
          <Route path="profile-feedback" element={<Feedback />} />
          <Route
            element={
              <CustomerNavigation
                target={target}
                startWalkThrough={startWalkThrough}
                setStartWalkThrough={setStartWalkThrough}
              />
            }
          >
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="search/:type" element={<ColumnView />} />
            <Route path="profile/:id" element={<DetailView />} />
            <Route path="service-comparison" element={<ServiceComparison />} />
            <Route
              path="platform-comparison"
              element={<PlatFormComparison />}
            />

            <Route path="profile" element={<DetailsView />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="contact" element={<Contact />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="search" element={<SearchView />} />
            <Route path="viewers-trend" element={<ViewersTrend />} />
            <Route path="nps" element={<NPS />} />
            <Route path="churn" element={<Churn />} />
            <Route path="library" element={<Articles />} />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/*" element={<Navigate to="/page-not-found" />} />
          </Route>
        </Routes>
      </QueryClientProvider>
    </div>
  );
}

export default App;

// (function (c, l, a, r, i, t, y) {
//   c[a] =
//     c[a] ||
//     function () {
//       (c[a].q = c[a].q || []).push(arguments);
//     };
//   t = l.createElement(r);
//   t.async = 1;
//   t.src = "https://www.clarity.ms/tag/" + i;
//   y = l.getElementsByTagName(r)[0];
//   y.parentNode.insertBefore(t, y);
// })(window, document, "clarity", "script", "e3drcbzo5o");

// (function (i, s, o, g, r, a, m) {
//   i["GoogleAnalyticsObject"] = r;
//   (i[r] =
//     i[r] ||
//     function () {
//       (i[r].q = i[r].q || []).push(arguments);
//     }),
//     (i[r].l = 1 * new Date());
//   (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
//   a.async = 1;
//   a.src = g;
//   m.parentNode.insertBefore(a, m);
// })(
//   window,
//   document,
//   "script",
//   "https://www.google-analytics.com/analytics.js",
//   "ga"
// );
