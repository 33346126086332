import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";
import { faq } from "../../api/core";
import { titles } from "../../utils/Titles";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ReactMarkdown from "react-markdown";
import DetailsAccordion from "../../components/DetailsAccordion";

const FAQ = () => {
  const navigate = useNavigate();
  const setTitle = useSetRecoilState(titleAtom);
  // const [faqs, setFaqs] = useState([]);

  const fetchFAQ = async () => {
    const { data: response } = await faq();
    // setFaqs(response.data)
    return response.data;
  };

  const { data: faqs } = useQuery({
    queryKey: ["FAQ"],
    queryFn: fetchFAQ,
    cacheTime: 1000 * 60 * 60 * 24,
  });

  useEffect(() => {
    setTitle("FAQ");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
    fetchFAQ();
  }, []);

  const middle = faqs?.length / 2;

  return (
    <section className="m-10" id="faq">
      <div className="flex flex-col gap-2">
        <h1 className="font-semibold text-xl">Frequently Asked Questions </h1>
        <p className="text-sm text-pot-text-grey">
          Quick answers to questions you may have. Can’t find what you’re
          looking for?{" "}
          <span
            className="text-pot-blue cursor-pointer"
            onClick={() => navigate("/contact")}
          >
            Contact Us
          </span>
        </p>
      </div>
      {/* <div className="my-10 grid grid-cols-2 gap-x-10 gap-y-6 grid-auto-rows">
        {faqs?.map((faq) => {
          return (
            <div className="w-full">
              <DetailsAccordion title={faq?.question} children={faq?.answer} />
            </div>
          );
        })}
      </div> */}
      <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-6 md:gap-y-0">
        <div className="flex flex-col gap-y-6">
          {faqs?.slice(0, middle).map((faq) => {
            return (
              <div className="w-full">
                <DetailsAccordion title={faq?.question}>
                  <ReactMarkdown
                    children={faq?.answer}
                    className="prose !text-sm"
                  />
                </DetailsAccordion>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col gap-y-6">
          {faqs?.slice(middle, faqs?.length).map((faq) => {
            return (
              <div className="w-full">
                <DetailsAccordion title={faq?.question}>
                  <ReactMarkdown
                    children={faq?.answer}
                    className="prose !text-sm"
                  />
                </DetailsAccordion>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
