import React, { useState } from "react";
import { motion } from "framer-motion";

const RangeInput = ({ value, setValue, price = false }) => {
  const [focus, setFocus] = useState(false);
  const handleMinChange = (e) =>
    setValue((prev) => ({
      ...prev,
      min:
        parseInt(prev.max > e.target.value ? e.target.value : prev.max - 1) ||
        0,
    }));
  const handleMaxChange = (e) =>
    setValue((prev) => ({
      ...prev,
      max:
        (parseInt(e.target.value) &&
          parseInt(
            prev.min < e.target.value
              ? e.target.value <= 100
                ? e.target.value
                : 100
              : prev.min + 1
          )) ||
        "",
    }));

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="w-full ml-10 mt-12"
    >
      <div
        className={`w-40 rounded-lg px-2 py-1 flex justify-center text-pot-dark-blue bg-pot-grey-2 ${
          focus ? "outline outline-pot-blue" : ""
        }`}
      >
        {price && "$"}
        <input
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={value.min}
          onChange={handleMinChange}
          className="w-6 text-right bg-pot-grey-2 mr-2 outline-none"
        />
        -{price && " $"}
        <input
          onFocus={(e) => {
            e.target.value = "";
            setFocus(true);
          }}
          onBlur={() => setFocus(false)}
          value={value.max || ""}
          onChange={handleMaxChange}
          className="w-6 bg-pot-grey-2 ml-2 outline-none"
        />
        {!price && "M"}
      </div>
    </motion.div>
  );
};

export default RangeInput;
