import { DotPulse } from "@uiball/loaders";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { ClipLoader } from "react-spinners";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getChart } from "../../api/dashboard";
import { AllFilterOptionsAtom, SelectedProfileCountryAtom, ShowAlphabetsAtom } from "../../atom";

const TopOTT = () => {
  const topOtts = [
    "Great Courses Signature Collection",
    "Netflix",
    "Cruchyroll",
    "Disney Hotstar",
    "Amazon Prime",
    "Amazon Prime",
    "24filx",
    "Cruchyroll",
    "Disney Hotstar",
    "Amazon Prime",
  ];
  const [data, setData] = useState(null)
  const [topNames, setTopNames] = useState([]);
  const [loading, setLoading] = useState(false)
  const [topIds, setTopIds] = useState(null);
  const [hasCalled, setHasCalled] = useState(false)
  const allOptions = useRecoilValue(AllFilterOptionsAtom);
  const setShowAlphabets = useSetRecoilState(ShowAlphabetsAtom);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom)
  const navigate = useNavigate();

  const formatTopVals = (dataVal = null) => {
    console.log("Hoog rr data", data, dataVal)
    let data2
    if(dataVal) {
      data2 = dataVal;
    } else {
      data2 = data
    }
    const quats = Object.keys(data2)
      .map((each) => each.split(" ").reverse().join(" "))
      .sort()
      .reverse()
      .map((each) => each.split(" ").reverse().join(" "));
    setHasCalled(true)
    const usVal = data2[quats[0]]?.[selectedProfileCountry].map((each) => each.ott_name);
    const settableData = usVal.reduce((acc, cur) => {
      const letterList = allOptions.tempNames.map((each) => each.label);
      const namesList = allOptions.tempNames.map((each) => each.options);
      let letterIndex = letterList.indexOf(cur[0].toUpperCase());
      letterIndex = letterIndex > 0 ? letterIndex : 0;
      const value = namesList[letterIndex].find((each) => {
        return each.label === cur;
      }).value;
      acc[cur] = value;
      return acc;
    }, {});
    setTopNames(Object.keys(settableData));
    setTopIds(Object.values(settableData));
  }

  const fetchTopValues = async () => {
    const params = { id: "TOP_SERVICES_BY_USER_REACH_TABLE" };
    const { data: response } = await getChart({ params });
    setData(response.data);
    setHasCalled(true)
    formatTopVals(response.data)
  };

  useEffect(() => {
    if (allOptions && !hasCalled) {
      fetchTopValues();
    } else if(hasCalled) {
      formatTopVals()
    }
  }, [allOptions]);

  return (
    <div className="flex items-center">
      <div className="w-[10vw] flex justify-center items-center px-2 mx-2">
        <p className=" font-semibold">Top OTT Platforms</p>
      </div>
      <div className="w-full  grid grid-cols-5 gap-3 pr-8">
        {topNames?.map((each, id) => (
          <>
            {topIds || !loading ? (
              <Link className="w-full h-full" to={`/profile/${topIds[id]}`}>
                <button
                  onClick={() => {
                    if (!topIds) return;
                    setShowAlphabets(true);
                  }}
                  className="w-full h-full bg-pot-bg-blue/80 text-sm flex justify-center items-center rounded-md hover:bg-pot-bg-blue-2 transition ease-in-out"
                >
                  <div className="text-pot-dark-blue font-semibold flex justify-center items-center text-center py-3 px-2">
                    {each}
                  </div>
                </button>
              </Link>
            ) : (
              <button
                onClick={() => {
                  if (!topIds) return;
                  setShowAlphabets(true);
                }}
                className="w-full h-full bg-pot-bg-blue/80 text-sm flex justify-center items-center rounded-md hover:bg-pot-bg-blue-2 transition ease-in-out"
              >
                <div className="text-pot-dark-blue font-semibold flex justify-center items-center text-center pt-5 pb-[18.5px] px-2">
                  <DotPulse size={20} speed={0.6} color="#1767A5" />
                </div>
              </button>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default TopOTT;
