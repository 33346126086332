import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AccountUserAtom, titleAtom } from "../../atom";
import Download from "./Download";
import Dropzone from "./Dropzone";
import Table from "./Table";
import { MacScrollbar } from "mac-scrollbar";
import { titles } from "../../utils/Titles";

const UploadData = () => {
  const setTitle = useSetRecoilState(titleAtom);
  const [hasDropzone, setHasDropzone] = useState(false);
  const [hasDownload, setHasDownload] = useState(false);
  const [hasTable, setHasTable] = useState(false);
  const account = useRecoilValue(AccountUserAtom);

  useEffect(() => {
    setTitle("Upload Data");
    document.title = `Streaming Video Tracker | ${titles[window.location.pathname]}`
  }, []);

  useEffect(() => {
    setHasDropzone(account?.user_permissions.includes("a_create_upload"));
    setHasDownload(account?.user_permissions.includes("a_download_upload"));
    setHasTable(account?.user_permissions.includes("a_view_upload"));
  }, [account]);

  return (
    <MacScrollbar>
      <section
        className={`w-full flex flex-col ${
          !hasDropzone
            ? "2xl:h-[90vh] 2xl:justify-evenly 2xl:overflow-y-auto"
            : "justify-between"
        }`}
      >
        {hasDownload && <Download />}
        {hasDropzone && (
          <Dropzone hasTable={hasTable} hasDownload={hasDownload} />
        )}
        <Table
          height={
            hasDropzone ? "h-[63vh]" : hasDownload ? "h-[75vh]" : "h-[85vh]"
          }
          hasDropzone={hasDropzone}
        />
      </section>
    </MacScrollbar>
  );
};

export default UploadData;
