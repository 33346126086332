import React from "react";
import shortcutnew from "../../assets/shortcutnew.png";
import { useParams } from "react-router-dom";
import { formatChurnValue, formatNpsValue } from "../../utils/helperFunc";

const NPSChurn = ({ detail }) => {
  const { id } = useParams();
  const valueStyle = `text-xl font-bold text-pot-blue`;
  const handleClick = (type) => {
    const url = `/${type}?profile=${id}`;
    window.open(url, "_blank");
  };
  return (
    <section className="grid grid-cols-2 gap-4 text-sm">
      {detail?.nps && (
        <div
          className="group w-full flex flex-col justify-evenly border-2 border-8DB1CD52/32 rounded-2xl shadow-md py-4 px-6 radial-gradient custom-shadow relative cursor-pointer col-span-2"
          onClick={() => handleClick("nps")}
        >
          <img
            src={shortcutnew}
            className="absolute top-2 right-2 z-10"
            alt="subscriber-icon"
          />
          <span className="absolute -top-10 right-0 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
            Click to see NPS by service
          </span>

          <div className="flex items-center justify-between h-1/2 pr-2">
            <span className="font-semibold">NPS</span>
            <span className={valueStyle}>
              {detail?.nps ? formatNpsValue(detail?.nps) : "-"}
            </span>
          </div>
        </div>
      )}
      {detail?.churn && (
        <div
          className="group w-full flex flex-col justify-evenly border-2 border-8DB1CD52/32 rounded-2xl shadow-md py-4 px-6 radial-gradient custom-shadow relative cursor-pointer col-span-2"
          onClick={() => handleClick("churn")}
        >
          <img
            src={shortcutnew}
            className="absolute top-2 right-2 z-10"
            alt="subscriber-icon"
          />
          <span className="absolute -top-10 right-0 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
            Click to see Churn by service
          </span>
          <div className="flex items-center justify-between h-1/2 pr-2">
            <span className="font-semibold">Churn</span>
            <span className={valueStyle}>
              {detail?.churn ? formatChurnValue(detail?.churn) : "-"}
            </span>
          </div>
        </div>
      )}
    </section>
  );
};

export default NPSChurn;
