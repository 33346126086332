const PAGES_MAX = 6;

export const startOfCount = (length, page) => (page - 1) * length + 1;

export const endOfCount = (length, page, totalCount) =>
  page * length > totalCount
    ? totalCount
    : page * length;

export const paginationLength = (totalPages, offset) =>{
  return(totalPages - ((offset*PAGES_MAX) - 1) > PAGES_MAX ? PAGES_MAX : totalPages - (offset ? (offset * PAGES_MAX) - 1 : 0))}
