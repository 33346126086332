import React, { useEffect, useState } from "react";
import { titles } from "../../../utils/Titles";
import Button from "../../../form/components/Button";
import { DotPulse } from "@uiball/loaders";
import { AnimatePresence, motion } from "framer-motion";
import { getFromLocalStorage } from "../../../utils/localStorage";
import { BASE_URL } from "../../../api/axios";
import axios from "axios";
import LibraryDropzone from "../../LibraryData/LibraryDropzone";
import NonStandardChartsForm from "../../../form/NonStandardCharts";
import NonStandardChartsTable from "./NonStandardChartsTable";
import { CustomCharts } from "../../../api/npschurn";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../../atom";

const NonStandardCharts = () => {
  const setTitle = useSetRecoilState(titleAtom);
  const [mainFile, setMainFile] = useState(null);
  const [formData, setFormData] = useState({});
  const [editData, setEditData] = useState(null);
  const [clear, setClear] = useState(false);
  const [called, callAgain] = useState(0);
  const [errors, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle("Non-Standard Charts");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
    setError({});
  }, []);

  useEffect(() => {
    if (mainFile && mainFile?.file) {
      clearError();
    }
    if (editData) {
      setError({});
    }
  }, [mainFile, editData]);

  useEffect(() => {
    if (clear) {
      const id = setTimeout(() => setClear(false), 1000);
      return () => clearTimeout(id);
    }
  }, [clear]);

  const validate = () => {
    const errorObj = {};
    if (!formData?.name) {
      errorObj.name = ["This field is required."];
    }
    if (!formData?.y_axis_label) {
      errorObj.y_axis_label = ["This field is required."];
    }
    if (!formData?.footer_text) {
      errorObj.footer_text = ["This field is required."];
    }
    if (!editData && !mainFile.file) {
      errorObj.file = ["This field is required."];
    }
    if (!formData?.value_type) {
      errorObj.value_type = ["This field is required."];
    }
    if (!formData?.page) {
      errorObj.page = ["This field is required."];
    }
    if (!formData?.position) {
      errorObj.position = ["This field is required."];
    }
    setError(errorObj);
    return !Object.keys(errorObj).length;
  };

  const handleProceed = async () => {
    if (loading) return;
    const valid = validate();
    if (!valid) return;
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("data", mainFile.file);
    formDataToSend.append("name", formData?.name);
    formDataToSend.append("y_axis_label", formData?.y_axis_label);
    formDataToSend.append("footer_text", formData?.footer_text);
    formDataToSend.append("page", formData?.page);
    formDataToSend.append("position", formData?.position);
    formDataToSend.append("value_type", formData?.value_type);
    try {
      const { data: response } = await axios.post(
        `/nps-churn/admin/chart/`,
        formDataToSend,
        {
          baseURL: BASE_URL,
          headers: { Authorization: `Token ${getFromLocalStorage("token")}` },
        }
      );
      setLoading(false);
      if (response.success) {
        setMainFile(null);
        setFormData({});
        setClear(true);
        callAgain((prev) => !prev);
      } else {
        setError(response.errors.error_message[0]);
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.errors.error_message[0]);
    }
  };

  const handleEdit = async () => {
    if (loading) return;
    const valid = validate();
    if (!valid) return;
    setLoading(true);
    // if (mainFile.file) {
    //   return;
    // }

    const formDataToSend = new FormData();
    mainFile.file && formDataToSend.append("data", mainFile.file);
    formDataToSend.append("name", formData?.name);
    formDataToSend.append("y_axis_label", formData?.y_axis_label);
    formDataToSend.append("footer_text", formData?.footer_text);
    formDataToSend.append("page", formData?.page);
    formDataToSend.append("position", formData?.position);
    formDataToSend.append("value_type", formData?.value_type);
    try {
      const { data: response } = await CustomCharts.update(
        editData.id,
        formDataToSend
      );
      setLoading(false);
      if (response.success) {
        setMainFile(null);
        setFormData({});
        setEditData(null);
        setClear(true);
        callAgain((prev) => !prev);
      } else {
        if (typeof response.errors.error_message[0] === "string") {
          setError({ non_field_error: response.errors.error_message[0] });
        } else {
          setError(response.errors.error_message[0]);
        }
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.errors.error_message[0]);
    }
  };

  const clearError = () => {
    if (errors.file) {
      delete errors.file;
      setError({ ...errors });
    }
  };

  return (
    <section className="w-full 2xl:flex 2xl:flex-col 2xl:h-[90vh] 2xl:justify-evenly 2xl:overflow-y-auto">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{ opacity: 1, scale: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className="mx-5 my-3 border-2 border-dashed divide-y-2 divide-dashed rounded-xl"
        >
          <div className="flex divide-x-2 divide-dashed">
            <div className="w-[70%] px-5">
              <LibraryDropzone
                error={errors?.file}
                clearError={clearError}
                editData={editData}
                clear={clear}
                setMainFile={setMainFile}
                title="Upload an excel file here"
                description="The first sheet of excel must contain a table representing a chart"
                errors={errors?.errors_found}
                setError={setError}
                page="NonStandardChart"
              />
            </div>
            <div className="w-full p-5">
              <NonStandardChartsForm
                errors={errors}
                setError={setError}
                clear={clear}
                editData={editData}
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </div>
          <div className="py-2 flex flex-col gap-2 items-center">
            {(errors?.data || errors?.non_field_error) && (
              <p className="text-xs text-pot-red ">
                {errors?.data
                  ? errors?.data.join(", ")
                  : errors?.non_field_error}
              </p>
            )}
            <Button
              text={
                <div className="flex gap-x-2 items-center">
                  {loading && <DotPulse size={20} speed={0.8} color="white" />}
                  {editData?.id ? "Update Chart" : "Create Chart"}
                </div>
              }
              onClick={() => (editData?.id ? handleEdit() : handleProceed())}
              primary
              className="w-fit !m-0"
            />
          </div>
        </motion.div>
      </AnimatePresence>
      <NonStandardChartsTable
        called={called}
        callAgain={callAgain}
        setEditData={setEditData}
      />
    </section>
  );
};

export default NonStandardCharts;
