import React, { useEffect, useState } from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import TableLayoutTwo from "../../components/TableLayout/Two";
import Edit from "../../assets/Edit";
import Toggle from "@atlaskit/toggle";
import TableControl from "../../components/TableLayout/TableControl";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  AccountUserAtom,
  LoadingProgressAtom,
  MainAppSideBarExpandAtom,
  TableLoadingAtom,
} from "../../atom";
import { OrganizationApi } from "../../api/organization";
import { MacScrollbar } from "mac-scrollbar";
import Sort from "../../assets/Sort";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

const OrganisationTable = ({ search }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const setLoading = useSetRecoilState(LoadingProgressAtom);
  const navigate = useNavigate();
  const [colDef, setColDef] = useState([]);
  const account = useRecoilValue(AccountUserAtom);
  const setTableLoading = useSetRecoilState(TableLoadingAtom);
  const [sortableKey, setSortableKey] = useState("");
  const [initial, setInitial] = useState(true);
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);
  const [toggleChanged, setToggleChanged] = useState(0);

  const toggleActive = async (row) => {
    const payload = { is_active: !row.original.active_customer_count };
    const { data: response } = await OrganizationApi.toggle(
      row.original.id,
      payload
    );
    if (!response.success) {
      Object.values(response.errors).forEach((each) => {
        console.log(each);
        toast.error(each.join(", "));
      });
      return;
    }
    setToggleChanged((prev) => prev + 1);
  };

  const initialColDef = [
    {
      header: () => (
        <div
          onClick={() =>
            sortableKey === "name"
              ? setSortableKey(`-name`)
              : sortableKey === `-name`
              ? setSortableKey("")
              : setSortableKey("name")
          }
          className="w-full flex justify-center cursor-pointer gap-x-3"
        >
          Organization
          <motion.button whileHover={{ scale: 1.2 }} className="min-w-fit mr-2">
            {sortableKey === "name" ? (
              <Sort firstFill="#1767A5" secondFill="#929292" />
            ) : sortableKey === `-name` ? (
              <Sort firstFill="#929292" secondFill="#1767A5" />
            ) : (
              <Sort firstFill="#1767A5" secondFill="#1767A5" />
            )}
          </motion.button>
        </div>
      ),
      accessorKey: "name",
      cell: ({ getValue }) => (
        <div className="w-full flex justify-center">
          <p className="w-48 truncate">{getValue()}</p>
        </div>
      ),
    },
    {
      header: () => (
        <div
          onClick={() =>
            sortableKey === "customer_count"
              ? setSortableKey(`-customer_count`)
              : sortableKey === `-customer_count`
              ? setSortableKey("")
              : setSortableKey("customer_count")
          }
          className="w-full flex justify-center cursor-pointer gap-x-3"
        >
          Customers
          <motion.button whileHover={{ scale: 1.2 }} className="min-w-fit mr-2">
            {sortableKey === "customer_count" ? (
              <Sort firstFill="#1767A5" secondFill="#929292" />
            ) : sortableKey === `-customer_count` ? (
              <Sort firstFill="#929292" secondFill="#1767A5" />
            ) : (
              <Sort firstFill="#1767A5" secondFill="#1767A5" />
            )}
          </motion.button>
        </div>
      ),
      accessorKey: "customer_count",
      cell: ({ getValue }) => (getValue() ? getValue() : "-"),
    },
    {
      header: () => (
        <div className="w-full flex justify-center cursor-pointer gap-x-3">
          Enabled customers
        </div>
      ),
      accessorKey: "active_customer_count",
      cell: ({ getValue }) => (getValue() ? getValue() : "-"),
    },
    {
      header: () => (
        <div className="w-full flex justify-center cursor-pointer gap-x-3">
          Plan Name
        </div>
      ),
      accessorKey: "assigned_plans",
      cell: ({ getValue }) => {
        const assignedPlans = getValue();
        const allTimeAccessText = "All Time Access";
        const allTimeAccessPresent = assignedPlans.includes(allTimeAccessText);
        let result = allTimeAccessPresent ? [allTimeAccessText] : [];
        // Filter out duplicates and store unique values in a Set
        const uniquePlans = new Set(
          assignedPlans.filter((plan) => plan !== allTimeAccessText)
        );
        // Convert the Set back to an array
        result = [...result, ...Array.from(uniquePlans)]?.join(", ");
        return result ? result : "-";
      },
    },
  ];

  const actionCol = {
    header: "Action",
    cell: ({ row }) => (
      <div className="flex justify-center">
        <Tooltip anchorSelect={`#edit${row.original.id}`} className="z-40">
          Edit organization profile
        </Tooltip>
        <button
          id={`edit${row.original.id}`}
          onClick={() =>
            navigate(`/admin/user/organization/edit/${row.original.id}`)
          }
          className="fill-pot-grey-6 ml-3 mr-4"
        >
          <Edit fill={"#1767A5"} />
        </button>
        <Tooltip anchorSelect={`#toggle${row.original.id}`} className="z-40">
          Click to {row.original.active_customer_count ? "disable" : "enable"}{" "}
          organization
        </Tooltip>
        <div id={`toggle${row.original.id}`}>
          <Toggle
            size="regular"
            isChecked={row.original.active_customer_count}
            onChange={() => toggleActive(row)}
          />
        </div>
      </div>
    ),
  };

  useEffect(() => {
    if (account?.user_permissions.includes("a_modify_organization")) {
      setColDef([...initialColDef, actionCol]);
    } else {
      setColDef([...initialColDef]);
    }
  }, [account, sortableKey]);

  // const data = Array.from({ length: 10 }).map(() => ({
  //   organisation: "lorem ipsum dolor",
  //   email: "lorem ipsum dolor",
  //   phone: "+91 9313284328",
  //   customers: 0,
  // }));

  const fetch = async () => {
    setTableLoading(true);
    setLoading(40);
    const params = { page, length: 100 };
    if (search) {
      params.search = search;
    }
    if (sortableKey) {
      console.log(sortableKey);
      params.sort_by = sortableKey.replace("phone_no", "phone");
    }
    const { data: response } = await OrganizationApi.list({ params });
    setLoading(70);
    if (response.success) {
      setTotalPages(response.data.total_pages);
      setTotalCount(response.data.total_count);
      setData(response.data.results);
    }
    setLoading(100);
    setTableLoading(false);
  };

  useEffect(() => {
    if (account?.user_permissions.includes("a_view_organization")) {
      fetch();
    }
  }, [page, account, sortableKey, toggleChanged]);

  useEffect(() => {
    if (initial) {
      setInitial(false);
      return;
    }
    if (page === 1) {
      if (account?.user_permissions.includes("a_view_organization")) {
        fetch();
      }
    } else {
      setPage(1);
    }
  }, [search]);

  const table = useReactTable({
    data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
  });

  if (account && !account?.user_permissions.includes("a_view_organization")) {
    return (
      <div className="w-full h-[75vh] flex justify-center pb-[30vh] items-center font-bold text-pot-dark-green">
        You are not allowed to view the organizations list
      </div>
    );
  }

  return (
    <MacScrollbar>
      <div className="w-full relative h-[75vh] overflow-y-auto">
        <TableLayoutTwo small={true} table={table} showNoDataText />
        <motion.div
          animate={sidebarExpand ? { paddingLeft: 180 } : { paddingLeft: 56 }}
          className="fixed left-0 bottom-0 w-full"
          transition={{ duration: 0.4 }}
        >
          <TableControl
            page={page}
            setPage={setPage}
            length={100}
            totalCount={totalCount}
            totalPages={totalPages}
          />
        </motion.div>
      </div>
    </MacScrollbar>
  );
};

export default OrganisationTable;
