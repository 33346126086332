import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { LeftArrow, RightArrow } from "../../assets/Arrows";
import { getAllQuarterBetween } from "../../utils/helperFunc";

const BarQuarterSelect = ({
  selectedBarQuarter,
  setSelectedBarQuarter,
  quarterEnd,
  quarterStart,
}) => {
  const wholeRange = getAllQuarterBetween(quarterStart, quarterEnd).map(
    (each) => [each[0] + 1, each[1]].join(" ")
  );
  const [showableRange, setShowableRange] = useState(
    wholeRange.length > 6 ? wholeRange.slice(-6) : wholeRange
  );

  useEffect(() => {
    setShowableRange(wholeRange.length > 6 ? wholeRange.slice(-6) : wholeRange);
  }, [quarterEnd, quarterStart]);

  return (
    <div className="flex gap-x-3">
      <button
        onClick={() => {
          if (wholeRange.indexOf(showableRange[0]) <= 0) return;
          if (wholeRange.indexOf(showableRange[0]) - 6 > 0) {
            setShowableRange(
              wholeRange.slice(
                wholeRange.indexOf(showableRange[0]) - 6,
                wholeRange.indexOf(showableRange[0])
              )
            );
          } else {
            setShowableRange(
              wholeRange.slice(0, wholeRange.indexOf(showableRange[0]))
            );
          }
        }}
        className={`px-2 py-1 ${
          wholeRange.indexOf(showableRange[0]) > 0
            ? "hover:bg-pot-grey"
            : "stroke-pot-t-grey opacity-20 cursor-default"
        }`}
      >
        <LeftArrow />
      </button>
      <div className="w-[30vw] flex justify-center gap-x-2">
          {showableRange.map((each, id) => (
            <motion.button
              key={each + id}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              // exit={{
              //   opacity: 0,
              //   y: -10,
              //   transition: { delay: 0, duration: 0.1 },
              // }}
              transition={{ delay: 0.1 }}
              onClick={() => setSelectedBarQuarter(each)}
              className={`border px-3 py-1 rounded-md ${selectedBarQuarter === each ? "bg-pot-blue text-white" : "text-pot-blue"}`}
            >
              Q{each}
            </motion.button>
          ))}
      </div>
      <button
        onClick={() => {
          if (
            wholeRange.indexOf(showableRange.slice(-1)[0]) >=
            wholeRange.length - 1
          )
            return;
          if (
            wholeRange.indexOf(showableRange.slice(-1)[0]) + 7 >
            wholeRange.length - 1
          ) {
            setShowableRange(
              wholeRange.slice(
                wholeRange.indexOf(showableRange.slice(-1)[0]) + 1,
                wholeRange.indexOf(showableRange.slice(-1)[0]) + 7
              )
            );
          } else {
            setShowableRange(
              wholeRange.slice(
                wholeRange.indexOf(showableRange.slice(-1)[0]) + 1,
                wholeRange.length
              )
            );
          }
        }}
        className={`px-2 py-1 ${
          wholeRange.indexOf(showableRange.slice(-1)[0]) < wholeRange.length - 1
            ? "hover:bg-pot-grey "
            : "stroke-pot-t-grey opacity-20 cursor-default"
        }`}
      >
        <RightArrow />
      </button>
    </div>
  );
};

export default BarQuarterSelect;
