import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ContactEntryAtom, titleAtom } from "../atom";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { LeftArrow } from "../assets/Arrows";
import ContactForm from "../form/Contact";
// import Heart from "../assets/Heart";
import EmojiCustomIcon from "@atlaskit/icon/glyph/emoji/custom";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "../form/components/Button";
import { MacScrollbar } from "mac-scrollbar";
import { contact, faq } from "../api/core";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import FaqIcon from "../assets/FaqIcon";
import { titles } from "../utils/Titles";
import shortcutnew from "../assets/shortcutnew.png";

const Contact = () => {
  const setTitle = useSetRecoilState(titleAtom);
  const [expandFAQ, setExpandFAQ] = useState(null);
  const [showSendMessage, setShowSendMessage] = useState(false);
  const [formData, setFormData] = useState({ phone_number: { code: "+1" } });
  const [contactEntry, setContactEntry] = useRecoilState(ContactEntryAtom);
  const [initial, setInitial] = useState(true);
  const [error, setError] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  // const [faqs, setFaqs] = useState([])
  const navigate = useNavigate();

  const back = () => {
    // if (window.history.state && window.history.state.idx > 0) {
    if (
      window.navigation?.currentEntry?.index > 1 ||
      (window.history.state && window.history.state.idx > 0) ||
      window.history.length > 2
    ) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  const handleContactSubmit = async () => {
    if (submitLoading) {
      return;
    }
    setSubmitLoading(true);
    const payload = {
      ...formData,
      phone_number: Object.values(formData.phone_number).join(""),
    };
    const { data: response } = await contact(payload);
    if (!response.success) {
      let errorObj = {};
      response.errors.error_message.forEach((each) => {
        if (typeof each === "string") {
          toast.error(each);
          return;
        }
        errorObj = { ...errorObj, ...each };
      });
      setError(errorObj);
      setSubmitLoading(false);
      return;
    }
    setContactEntry(null);
    setFormData({ phone_number: { code: "+1" } });
    setShowSendMessage(true);
    setSubmitLoading(false);
  };

  const fetchFAQ = async () => {
    const { data: response } = await faq();
    // setFaqs(response.data)
    return response.data;
  };

  const { data: faqs } = useQuery({
    queryKey: ["FAQ"],
    queryFn: fetchFAQ,
    cacheTime: 1000 * 60 * 60 * 24,
  });

  useEffect(() => {
    setTitle("Contact");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
    // fetchFAQ()
    if (contactEntry) {
      setFormData({
        ...contactEntry,
        phone_number: { ...contactEntry.phone_number },
      });
    }
  }, []);

  useEffect(() => {
    if (initial) {
      setInitial(false);
      return;
    }
    if (
      JSON.stringify(formData) ===
      JSON.stringify({ phone_number: { code: "+1" } })
    ) {
      setContactEntry(null);
      return;
    }
    setContactEntry({
      ...formData,
      phone_number: { ...formData.phone_number },
    });
  }, [formData]);

  return (
    <section className="flex flex-col lg:flex-row divide-x">
      <MacScrollbar>
        <section className="w-full h-fit lg:h-[94vh] text-xs pt-12 px-10 xl:px-16">
          <div className="flex items-center">
            <motion.button
              onClick={back}
              whileHover={{ scale: 1.5 }}
              className="pr-4"
            >
              <LeftArrow />
            </motion.button>
            <p className=" text-sm font-semibold">Contact Us</p>
          </div>
          <div className="px-4 mt-3 ">
            <p className="text-pot-text-grey">
              You agree to receive correspondence from Parks Associates by
              completing this form.
              <br />
              <a
                href="https://www.parksassociates.com/privacy-policy" // original privacy policy url
                target="_blank"
                rel="noreferrer"
                className="text-pot-blue"
              >
                View our privacy policies.
              </a>
            </p>
            <ContactForm
              formData={formData}
              setFormData={setFormData}
              onSubmit={handleContactSubmit}
              error={error}
              setError={setError}
              loading={submitLoading}
            />
          </div>
        </section>
      </MacScrollbar>
      <MacScrollbar>
        <section className="w-full h-fit lg:h-[94vh] pt-10 lg:pt-[76px] px-14 lg:px-10 xl:px-16">
          <p className="text-pot-text-grey justify-center text-sm">
            If you have any feedback or suggestions on profiles, please fill
            the:
            <br />
            <a
              href="/profile-feedback"
              target="_blank"
              rel="noreferrer"
              className="text-pot-blue"
            >
              Profile Feedback Form
              <img
                src={shortcutnew}
                className="inline-block h-4 ml-2"
                alt="subscriber-icon"
              />
            </a>
          </p>
        </section>
      </MacScrollbar>
      <ModalTransition>
        {showSendMessage && (
          <Modal onClose={() => setShowSendMessage(false)}>
            <div className="w-full flex flex-col justify-center items-center text-center gap-y-10 p-10">
              <p>
                <b>Thank you!</b>
                <br /> We got the request. We will be in touch within 2 business
                days. If you have immediate questions please call{" "}
                <a href="tel:+1-972-490-1113">+1 972-490-1113</a>
              </p>
              <div className="ml-5">
                <Button
                  text={"Close"}
                  onClick={() => setShowSendMessage(false)}
                  primary
                  large
                />
              </div>
            </div>
          </Modal>
        )}
      </ModalTransition>
    </section>
  );
};

export default Contact;
