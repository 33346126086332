export const data1 = [
  { year: 2013, Existing: 110, Launched: 100, Closed: -25 },
  { year: 2014, Existing: 282, Launched: 150, Closed: -25 },
  { year: 2015, Existing: 130, Launched: 93, Closed: -25 },
  { year: 2016, Existing: 299, Launched: 112, Closed: -25 },
  { year: 2017, Existing: 100, Launched: 200, Closed: -25 },
  { year: 2018, Existing: 197, Launched: 121, Closed: -25 },
  { year: 2019, Existing: 200, Launched: 45, Closed: 0 },
  { year: 2020, Existing: 382, Launched: 101, Closed: -25 },
  { year: 2021, Existing: 356, Launched: 90, Closed: -25 },
  { year: 2022, Existing: 310, Launched: 80, Closed: -25 },
];

export const data2 = [
  { year: 2013, Existing: 120, Launched: 100, Closed: -25 },
  { year: 2015, Existing: 230, Launched: 103, Closed: -25 },
  { year: 2017, Existing: 260, Launched: 150, Closed: -25 },
  { year: 2019, Existing: 100, Launched: 100, Closed: 0 },
  { year: 2021, Existing: 336, Launched: 90, Closed: -25 },
  { year: 2022, Existing: 410, Launched: 80, Closed: -25 },
  { year: 2014, Existing: 482, Launched: 150, Closed: -25 },
  { year: 2016, Existing: 199, Launched: 152, Closed: -25 },
  { year: 2018, Existing: 297, Launched: 121, Closed: -25 },
  { year: 2020, Existing: 382, Launched: 191, Closed: -25 },
];

export const businessModelComparisonData1 = [
  {
    year: 2000,
    Existing: 110,
    Launched: 100,
    Closed: -25,
    business_model: "SVOD",
  },
  {
    year: 2002,
    Existing: 130,
    Launched: 93,
    Closed: -25,
    business_model: "AVOD",
  },
  {
    year: 2004,
    Existing: 100,
    Launched: 200,
    Closed: -25,
    business_model: "FAST",
  },
  {
    year: 2006,
    Existing: 200,
    Launched: 45,
    Closed: 0,
    business_model: "vMPVD",
  },
  {
    year: 2008,
    Existing: 356,
    Launched: 90,
    Closed: -25,
    business_model: "TVOD",
  },
];

export const businessModelComparisonData2 = [
  {
    year: 2000,
    Existing: 60,
    Launched: 100,
    Closed: -25,
    business_model: "SVOD",
  },
  {
    year: 2002,
    Existing: 120,
    Launched: 93,
    Closed: -25,
    business_model: "AVOD",
  },
  {
    year: 2004,
    Existing: 200,
    Launched: 200,
    Closed: -25,
    business_model: "FAST",
  },
  {
    year: 2006,
    Existing: 90,
    Launched: 45,
    Closed: 0,
    business_model: "vMPVD",
  },
  {
    year: 2008,
    Existing: 356,
    Launched: 90,
    Closed: -25,
    business_model: "TVOD",
  },
];

export const reachData1 = [
  { time: "Mar19", x: 20, y: 22 },
  { time: "Jun19", x: 24, y: 43 },
  { time: "Sep19", x: 15, y: 24 },
  { time: "Dec19", x: 34, y: 43 },
  { time: "Mar20", x: 44, y: 33 },
  { time: "Jun20", x: 43, y: 23 },
  { time: "Sep20", x: 35, y: 1 },
  { time: "Dec20", x: 5, y: 20 },
  { time: "Mar21", x: 13, y: 40 },
  { time: "Jun21", x: 16, y: 33 },
  { time: "Sep21", x: 50, y: 14 },
  { time: "Dec21", x: 33, y: 12 },
];

export const reachData2 = [
  { time: "Mar19", x: 10, y: 32 },
  { time: "Jun19", x: 14, y: 33 },
  { time: "Sep19", x: 15, y: 34 },
  { time: "Dec19", x: 44, y: 13 },
  { time: "Mar20", x: 44, y: 23 },
  { time: "Jun20", x: 33, y: 43 },
  { time: "Sep20", x: 45, y: 19 },
  { time: "Dec20", x: 50, y: 40 },
  { time: "Mar21", x: 43, y: 10 },
  { time: "Jun21", x: 26, y: 43 },
  { time: "Sep21", x: 5, y: 34 },
  { time: "Dec21", x: 43, y: 22 },
];

export const names = ["Netflix", "flix22", "Disney+", "Hulu"];

export const strokes = [
  "#1B75BB",
  "#E15A78",
  "#41B4D9",
  "#ECA11B",
  "#F37EC0",
  "#83C135",
  "#2AA170",
  "#8E8CFF",
  "#BC69F3",
  "#F2C428",
  // ADDED MORE COLORS IF THERE ARE MORE OTTS
  "#FF7F50",
  "#6495ED",
  "#FFD700",
  "#4B0082",
  "#32CD32",
  "#FF4500",
  "#9370DB",
  "#00FFFF",
  "#8A2BE2",
  "#FF69B4",
];
