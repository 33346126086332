import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import parks_associates from "../../assets/parks-associates-Teallogo.png";

const Footer = ({ landscape = false }) => {
  const footer_styles = StyleSheet.create({
    container: {
      width: "100vw",
      position: "absolute",
      bottom: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: "7px",
      alignItems: "center",
      borderTop: 0.5,
      borderColor: "#1767A5",
      paddingVertical: "14px",
      fontFamily: "Poppins",
      marginTop: "10px",
    },
    img: {
      height: "28px",
      objectFit: "contain",
      marginRight: "10px"
    },
    copyright_text: {
      fontSize: "8px",
      marginVertical: "4px",
    },
    rights_text: {
      maxWidth: landscape ? "50vw" : "70vw",
      fontSize: "6px",
    },
  });

  return (
    <View fixed style={footer_styles.container}>
      <View>
        <Text style={footer_styles.copyright_text}>
          Published by Parks Associates | © Parks Associates | Addison,
          Texas 75001
        </Text>
        <Text style={footer_styles.rights_text}>
          All rights reserved. No part of this report may be reproduced in any
          form or by any means, without permission in writing from the
          publisher. Printed in the United States of America.
        </Text>
      </View>
      <Image src={parks_associates} style={footer_styles.img} />
    </View>
  );
};

export default Footer;
