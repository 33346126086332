import React from "react";

const PlayIcon = () => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6515 8.48939C20.5842 9.60522 20.5842 12.3948 18.6515 13.5106L4.84851 21.4798C2.91584 22.5956 0.500001 21.2008 0.500001 18.9692L0.500002 3.03085C0.500002 0.799188 2.91584 -0.595596 4.84851 0.520233L18.6515 8.48939Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default PlayIcon;
