import React from "react";

const ColoredDownArrow = ({ color, width }) => {
  return (
    <svg
      width={width || "10"}
      height={width || "7"}
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.364964 0.866176C0.131455 1.10229 0.000488281 1.42097 0.000488281 1.75305C0.000488281 2.08513 0.131455 2.40381 0.364964 2.63993L4.03871 6.34618C4.31121 6.61492 4.66371 6.74867 5.01246 6.74867C5.36121 6.74867 5.70746 6.61492 5.97371 6.34618L9.63621 2.65243C9.86942 2.41615 10.0002 2.09753 10.0002 1.76555C10.0002 1.43357 9.86942 1.11495 9.63621 0.878677C9.52142 0.762196 9.38462 0.669699 9.23376 0.606564C9.08291 0.543429 8.921 0.510916 8.75746 0.510916C8.59393 0.510916 8.43202 0.543429 8.28116 0.606564C8.1303 0.669699 7.9935 0.762196 7.87871 0.878677L5.00621 3.77493L2.12246 0.866176C2.00742 0.75015 1.87053 0.658055 1.71971 0.595206C1.56889 0.532358 1.40711 0.5 1.24371 0.5C1.08032 0.5 0.918542 0.532358 0.767718 0.595206C0.616894 0.658055 0.48001 0.75015 0.364964 0.866176V0.866176Z"
        fill={color || "#00838F"}
      />
    </svg>
  );
};

export default ColoredDownArrow;
