import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { checkDateIsGreater, checkDateIsLess, checkIfSelectedDate } from "../../utils/DateHelpers";
import moment from "moment";

const DateView = ({
  getDaysArray,
  date,
  otherDate,
  selectedDate,
  setSelectedDate,
  selectedOtherDate,
  from,
  allowMonthsDiff
}) => {
  const [dateArray, setDateArray] = useState(null);

  useEffect(() => {
    setDateArray(getDaysArray());
  }, [date.month]);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.6 }}
      className={`grid grid-cols-7 gap-y-3 text-center mt-[2vh] text-xs px-4 pb-2`}
    >
      {dateArray?.[0]?.map((each) => (
        <p className="opacity-25">{each}</p>
      ))}
      {dateArray?.[1]?.map((each) => (
        <button className={`p-1 flex justify-center items-center ${
            checkIfSelectedDate(each, date.month, date.year, selectedOtherDate) &&
            `bg-pot-blue text-white ${
              !from ? "rounded-l-md" : "rounded-r-md"
            }`
          } ${
            checkIfSelectedDate(each, date.month, date.year, selectedDate) &&
            `bg-pot-blue text-white ${
              !from ? "rounded-r-md" : "rounded-l-md"
            }`
          }
           ${
            !from
              ? checkDateIsLess(
                  selectedOtherDate,
                  new Date(date.year, date.month, each)
                ) &&
                checkDateIsGreater(
                  selectedDate,
                  new Date(date.year, date.month, each)
                ) &&
                "bg-pot-grey text-cyan"
              : checkDateIsLess(
                  selectedDate,
                  new Date(date.year, date.month, each)
                ) &&
                checkDateIsGreater(
                  selectedOtherDate,
                  new Date(date.year, date.month, each)
                ) &&
                "bg-pot-grey text-cyan"
          }
          `}
          onClick={() => {
              if(allowMonthsDiff) {
                var months;
                var d2, d1;
                const settable = new Date(date.year, date.month, each)
                if(moment(settable).format("YYYY-MM-DD") < moment(otherDate).format("YYYY-MM-DD")){
                  d1 = settable;
                  d2 = new Date(otherDate.year, otherDate.month, otherDate.value);
                  // console.log(d1, d2)
                } else {
                  d1 =new Date(otherDate.year, otherDate.month, otherDate.value);
                  d2 = new Date(settable);
                  // console.log(d1, d2)
                }
                // console.log(d1, d2, "out")
                months = (d2.getFullYear() - d1.getFullYear()) * 12;
                months -= d1.getMonth();
                months += d2.getMonth();
                months = months <= 0 ? 0 : months
                if(months > allowMonthsDiff ){
                  return;
                }
              }
              setSelectedDate(new Date(date.year, date.month, each))
              date.set(each)
          }}
          >{each}
          </button>
      ))}
      {dateArray?.[2]?.map((each) => (
        <p className="opacity-25">{each}</p>
      ))}
    </motion.div>
  );
};

export default DateView;
