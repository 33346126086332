import React from "react";
import Checkbox from "../../components/Checkbox";
import { names } from "../../utils/chartsData";
import { strokes } from "../../utils/chartsData";

const Legends = ({ selectedOtts, setSelectedOtts }) => {
  const getColor = (index) => `accent-[${strokes[index]}]`;

  const onCheck = (id) => {
    const filteredOtts = selectedOtts.filter((each) => each.id !== id);
    setSelectedOtts(filteredOtts);
  };

  return (
    <div className="w-full grid grid-cols-5 px-10">
      {selectedOtts?.map((each, index) => {
        const accent = getColor(index);
        return (
          <div className={`flex gap-2 py-3 justify-start ${accent}`}>
            <Checkbox
              onCheck={() => onCheck(each.id)}
              color={each.color}
              checked={true}
            />
            {each.name}
          </div>
        );
      })}
    </div>
  );
};

export default Legends;
