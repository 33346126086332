import React, { useCallback, useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";

const MultiRangeSlider = ({ max, min, value, setValue, price, size }) => {
  const [minVal, setMinVal] = useState(value.min);
  const [maxVal, setMaxVal] = useState(value.max);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  useEffect(() => {
    if (value) {
      setMaxVal(value.max);
      setMinVal(value.min);
    }
  }, [value]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex justify-start items-center relative"
    >
      <input
        type="range"
        min={min}
        max={max}
        ref={minValRef}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(+event.target.value, maxVal - 1);
          setMinVal(value);
          setValue((prev) => ({ ...prev, min: value }));
          event.target.value = value.toString();
        }}
        className={`pointer-events-none absolute h-0 w-[250px] outline-none ${
          minVal > max - 100 ? "z-[90]" : "z-[88]"
        }`}
      />
      <input
        type="range"
        min={min}
        max={max}
        ref={maxValRef}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(+event.target.value, minVal + 1);
          setMaxVal(value);
          setValue((prev) => ({ ...prev, max: value }));
          event.target.value = value.toString();
        }}
        className="pointer-events-none absolute h-0 w-[250px] outline-none z-[89]"
      />

      <div className="relative w-[250px]">
        <div className="absolute top-[1px] rounded h-[4px] bg-pot-blue-2/40 w-full z-[86]" />
        <div
          ref={range}
          className="absolute rounded h-[8px] bg-pot-dark-blue z-[87]"
        />
        <div className="absolute text-[12px] mt-[20px] left-[6px]">
          {price ? (min ? `$ ${min}` : "Free") : min ? `${min} M` : min}
        </div>
        <div className="absolute text-[12px] mt-[20px] right-[-4px] ">
          {price ? `$ ${max}` : `${max} M`}
        </div>
      </div>
    </motion.div>
  );
};

export default MultiRangeSlider;
