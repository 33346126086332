import React, { useEffect, useState } from "react";
import { getChart } from "../api/dashboard";

const usePreviewNPSCharts = (initial, params) => {
  const [charts, setCharts] = useState({});
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(0);

  const formatData = (data) => {
    let newData = Object.keys(data).map((item) => {
      let newObj = {};
      data[item]?.map((each) => {
        return (newObj[each.x_axis] = each.value);
      });
      return {
        name: item,
        ...newObj,
      };
    });
    return newData;
  };

  const formatBarData = (data) => {
    const OTTs = Object.keys(data)
      .map((ott, index) => {
        if (index !== 0) return null;
        return data[ott].map((item) => item.x_axis);
      })
      .filter(Boolean);
    const newData = OTTs[0].map((item, index) => {
      let newObj = {};
      Object.keys(data).forEach((ott) => {
        newObj[ott] = data[ott][index].value;
      });
      return {
        name: item,
        ...newObj,
      };
    });
    return newData;
  };

  const sortDataByQuarter = (data) => {
    const sortedKeys = Object.keys(data).sort((a, b) => {
      const [aQuarter, aYear] = a.split(" ");
      const [bQuarter, bYear] = b.split(" ");

      if (aYear !== bYear) {
        return aYear.localeCompare(bYear);
      } else {
        const quarters = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 };
        return quarters[aQuarter] - quarters[bQuarter];
      }
    });

    const sortedData = {};
    sortedKeys.forEach((key) => {
      sortedData[key] = data[key];
    });

    return sortedData;
  };

  const getSingleChartsData = async () => {
    setLoading(true);
    const { data: response } = await getChart({ params });
    const barData = formatBarData(sortDataByQuarter(response.data));
    const lineData = formatData(sortDataByQuarter(response.data));
    const newData = {
      data: lineData,
      bar: barData,
    };
    setCharts(newData);
    setLoading(false);
  };

  useEffect(() => {
    if (initial && params?.id) getSingleChartsData();
  }, [toggle]);

  const callAgain = () => {
    setToggle((prev) => prev + 1);
  };

  return { charts, loading, callAgain };
};

export default usePreviewNPSCharts;
