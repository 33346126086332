import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Legend,
  ReferenceLine,
  Tooltip,
  LabelList,
} from "recharts";
import { DotPulse } from "@uiball/loaders";
import { useRecoilValue } from "recoil";
import { ResizingAtom, SelectedProfileCountryAtom } from "../../atom";
import EditorPanelIcon from "@atlaskit/icon/glyph/editor/panel";
import { useExportChart } from "../../hooks/useExportChart";
// import { businessModelComparisonData1, businessModelComparisonData2 } from "../../utils/chartsData";

const BusinessModelComparisonChart = ({
  data,
  label,
  isRoundVal = true,
  isPercentVal = false,
}) => {
  const [market, setMarket] = useState("US");
  const [showTooltip, setShowtooltip] = useState(false);
  const [sortedData, setSortedData] = useState({});
  const containerResizing = useRecoilValue(ResizingAtom);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);
  const { ExportButton, headerRef, titleStyles } = useExportChart();

  useEffect(() => {
    setMarket(selectedProfileCountry);
  }, [selectedProfileCountry]);

  useEffect(() => {
    if (data) {
      // const sortedTempData = [...data].sort((a, b) => b.count - a.count);
      const US = data["US"].sort((a, b) => b.count - a.count);
      const CANADA = data["CANADA"].sort((a, b) => b.count - a.count);
      setSortedData({ US: US, CANADA: CANADA });
    }
  }, [data]);

  return (
    <section className="w-full xl:w-[60%] relative">
      <ExportButton
        id="businessModelComparisonChart"
        title="Business Model Comparison Chart"
        variant="footer"
      />
      <div ref={headerRef} className={`${titleStyles} flex items-center pr-4`}>
        <p className="text-sm text-pot-dark-blue font-semibold p-4">
          Business Model Comparison
        </p>
        <div
          onMouseEnter={() => setShowtooltip(true)}
          onMouseLeave={() => setShowtooltip(false)}
          className=" px-2  relative"
        >
          <EditorPanelIcon primaryColor="rgb(23,103,165)" />
          {showTooltip && (
            <div className="px-2 py-0.5 text-xs w-[30vw] rounded-sm font-thin -left-[15vw] z-[99] absolute bg-pot-dark-blue text-white">
              <div className="flex gap-x-2">
                <p className="w-fit">a.</p>
                <p className="w-full">
                  vMVPD – Online Pay-TV providers that aggregate multiple
                  channels of television programming and distribute them to
                  consumers as a packaged monthly service delivered exclusively
                  over the internet. They offer OTT subscriptions for bundles of
                  live, linear channels and may also offer a collection of
                  on-demand content.
                  {/* Help */}
                </p>
              </div>
              <div className="flex gap-x-2 py-1">
                <p className="w-fit">b.</p>
                <p className="w-full">
                  FAST (Free Ad-Supported Streaming TV) Platform - Video
                  services that aggregate or bundle linear channels of content
                  provided free to the viewer with non-skippable embedded
                  advertisements.
                </p>
              </div>
              <div className="flex gap-x-2 py-1">
                <p className="w-fit">c.</p>
                <p className="w-full">
                  FAST (Free Ad-Supported Streaming TV) Channel - Services that
                  provide content to FAST Platforms for inclusion in a
                  live/linear bundled channel on that platform.
                </p>
              </div>
              <div className="flex gap-x-2 py-1">
                <p className="w-fit">d.</p>
                <p className="w-full">
                  AVOD - A business model for video services where a consumer
                  has access to a library of on-demand video content that
                  includes embedded advertising.
                </p>
              </div>
              <div className="flex gap-x-2 py-1">
                <p className="w-fit">e.</p>
                <p className="w-full">
                  TVOD - An OTT video service that allows users to rent or
                  purchase digital videos, movies, or TV shows on an individual
                  basis.
                </p>
              </div>
              <div className="flex gap-x-2 py-1">
                <p className="w-fit">f.</p>
                <p className="w-full">
                  SVOD – A business model for video services wherein a consumer
                  pays a regular (monthly or annual) fee to gain access to a
                  library of on-demand video content for a specified period.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        id="chart-businessModelComparisonChart"
        className="h-full w-full relative shadow-md opacity-90 hover:opacity-100 border rounded-lg"
      >
        <div
          style={{ height: headerRef?.current?.clientHeight + 5 }}
          className="text-pot-dark-blue flex items-center font-semibold text-sm px-5"
        >
          <p className="pb-1">Business Model Comparison</p>
        </div>

        {!data || containerResizing ? (
          <div className="w-full h-96 flex justify-center items-center">
            <DotPulse size={40} color="#1767A5" />
          </div>
        ) : (
          <div className="w-full h-full relative text-xs">
            <ResponsiveContainer width={"97%"} height={400}>
              <BarChart
                margin={{
                  left: 80,
                  top: 40,
                  bottom: 20,
                  right: 40,
                }}
                barGap={2}
                barCategoryGap={8}
                stackOffset="sign"
                data={sortedData[market]}
                layout="vertical"
              >
                <Bar shape={CustomizedBar} dataKey={"count"} fill="#1B75BB">
                  <LabelList
                    valueAccessor={(each) =>
                      `${isRoundVal && Math.round(each.value)}${
                        isPercentVal ? "%" : ""
                      }`
                    }
                    position="right"
                  />
                </Bar>
                <XAxis
                  padding={{ left: 20, right: 20 }}
                  type="number"
                  label={{
                    value: label,
                    position: "insideBottom",
                    offset: -10,
                  }}
                />
                {/* <YAxis tickFormatter={(value) => value.split(" ").join("_")} tickMargin={8} interval={0} padding={{ top: 20, bottom: 20 }} type="category" dataKey="x_axis" /> */}
                <YAxis
                  tick={CustomizedTick}
                  label={{
                    value: "Business Model",
                    angle: -90,
                    position: "insideTopRight",
                    offset: 110,
                  }}
                  interval={0}
                  padding={{ top: 20, bottom: 20 }}
                  type="category"
                  dataKey="x_axis"
                />
              </BarChart>
            </ResponsiveContainer>
            <div className="absolute -top-1 left-16 flex gap-5">
              <button
                onClick={() => setMarket("US")}
                className={`text-sm px-2 py-1 border rounded ${
                  market === "US" && "bg-[#1B75BB] text-white border-[#1B75BB]"
                }`}
              >
                US
              </button>
              <button
                onClick={() => setMarket("CANADA")}
                className={`text-sm px-2 py-1 border rounded ${
                  market === "CANADA" &&
                  "bg-[#1B75BB] text-white border-[#1B75BB]"
                }`}
              >
                Canada
              </button>
            </div>
          </div>
        )}
        <div className="w-full absolute bottom-2 flex justify-end text-xs px-2 text-pot-t-grey">
          © Parks Associates
        </div>
      </div>
    </section>
  );
};
const CustomizedTick = (props) => {
  const words = `${props.payload.value}`.split(" ");
  return (
    <g>
      <rect
        x={props.x - 80}
        y={props.y - 8}
        width={50}
        height={28}
        fill="transparent"
      />
      {words.map((word, i) => (
        <text
          key={i}
          x={props.x - 44}
          y={props.y + 2 + i * 12}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={12}
          className="leading-3"
        >
          {word}
        </text>
      ))}
    </g>
  );
};

const CustomizedBar = (props) => {
  return (
    <g>
      <rect
        x={props.x}
        y={props.y + props.height / 2 - 5.5}
        width={props.width}
        height={11}
        fill={props.fill}
        rx={5.5}
        ry={5.5}
      />
    </g>
  );
};
export default BusinessModelComparisonChart;
