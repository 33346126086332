import React, { useEffect, useState } from "react";
import park_associates from "../assets/parks-associates-Teallogo.png";
import { motion } from "framer-motion";
import ott_tracker from "../assets/ott_tracker.png";
import LoginForm from "../form/Login";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "../api/Auth";
import { setToLocalStorage } from "../utils/localStorage";
import { toast } from "react-toastify";
import { MacScrollbar } from "mac-scrollbar";
import { useRecoilValue } from "recoil";
import { AppInfoAtom } from "../atom";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import ContactForm from "../form/Contact";
import { contact, unAuthContact } from "../api/core";
import ThumbsUp from "../assets/thumbsup.svg";
import Button from "../form/components/Button";
import PlayIcon from "../assets/PlayIcon";
import "../styles/Login.css";

const MainLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [error, setError] = useState("");
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openContactForm, setOpenContactForm] = useState(false);
  const [formData, setFormData] = useState({ phone_number: "" });
  const [contactError, setContactError] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openYTModal, setOpenYTModal] = useState(false);
  const [contactFormSubmitted, setContactFormSubmitted] = useState(false);
  const appInfo = useRecoilValue(AppInfoAtom);
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (!username) {
      setError((prev) => ({ ...prev, email: "Username cannot be blank" }));
    }
    if (!password) {
      setError((prev) => ({ ...prev, password: "Password cannot be blank" }));
    }
    if (!acceptPrivacy && username && password) {
      setError((prev) => ({
        ...prev,
        non_field_errors: "You need to accept privacy policy to login",
      }));
    }
    if (!password || !username || !acceptPrivacy) return;
    setLoading(true);
    const payload = { email: username, password: password };
    const { data: response } = await AuthApi.login(payload);
    if (!response.success) {
      if (Array.isArray(response.errors.error_message)) {
        toast.error(response.errors.error_message.join(", "));
        setLoading(false);
        return;
      }
      setError(response.errors);
      setLoading(false);
      return;
    }
    localStorage.clear();
    const { token, user_type } = response.data.user;
    if (keepLoggedIn) {
      localStorage.setItem("loggedInAlways", true);
    } else {
      localStorage.setItem("tabsOpen", 1);
    }
    setToLocalStorage(token, user_type);
    if (user_type === "ADMIN") {
      navigate("/admin");
      return;
    }
    navigate("/");
  };

  const handleContactSubmit = async () => {
    if (submitLoading) {
      return;
    }
    setSubmitLoading(true);
    const payload = {
      ...formData,
      // phone_number: Object.values(formData.phone_number)[1],
    };
    console.log(payload);
    const { data: response } = await unAuthContact(payload);
    if (!response.success) {
      let errorObj = {};
      response.errors.error_message.forEach((each) => {
        if (typeof each === "string") {
          toast.error(each);
          return;
        }
        errorObj = { ...errorObj, ...each };
      });
      setContactError(errorObj);
      setSubmitLoading(false);
      return;
    }
    setContactFormSubmitted(true);
    setFormData({ phone_number: "" });
    // setOpenContactForm(false);
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (!openContactForm) {
      setContactFormSubmitted(false);
    }
  }, [openContactForm]);

  return (
    <>
      <div className="w-full flex">
        <MacScrollbar>
          <section className="w-full h-screen overflow-y-auto overflow-x-hidden bg-pot-bg-1 pt-[3vh] text-sm text-pot-text-grey">
            <motion.section
              initial={{ scale: 0.98, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="w-full h-full flex flex-col"
            >
              <section className="h-fit px-[3vw]">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center max-md:gap-4">
                  <img
                    src={ott_tracker}
                    alt="park associates"
                    className="h-[6vh] object-contain"
                  />
                  <Button
                    text="Request Demo"
                    primary
                    onClick={() => setOpenContactForm(true)}
                  />
                </div>
                <p className="mt-[3vh]">
                  The <b>Streaming Video Tracker</b> is an exhaustive analysis
                  of market trends and profiles for 400+ over-the-top video
                  services in the US and Canada. It estimates subscribers,
                  viewers and transactional users for dozens of OTT services,
                  including those that do not publicly release customer figures.
                </p>
                <p className="mt-[2vh]">
                  This research helps subscribers understand the vast landscape
                  of OTT video service providers, with insights on business
                  models, new services, global expansion, and growth of
                  individual services. Monthly market updates providing analysis
                  on the most important announcements in the OTT market
                  complements the data provided in this portal.
                </p>
              </section>
              {/* PLAN_VERSION: comment the below line < Line 146 > -  DONE */}
              {/* <section name="section_for_no_plan" className="flex flex-col h-full justify-between mt-12 2xl:justify-between gap-y-4 2xl:mt-0"> */}
              {/* PLAN_VERSION: uncomment the below line < Line 148 > - DONE */}
              <section
                name="section_for_plan"
                className="flex flex-col h-full justify-between mt-8 2xl:justify-between gap-y-4 2xl:mt-0"
              >
                <LoginForm
                  setUsername={setUsername}
                  setPassword={setPassword}
                  keepLoggedIn={keepLoggedIn}
                  setKeepLoggedIn={setKeepLoggedIn}
                  error={error}
                  setError={setError}
                  onSubmit={onSubmit}
                  acceptPrivacy={acceptPrivacy}
                  setAcceptPrivacy={setAcceptPrivacy}
                  loading={loading}
                  newPage
                />
                {/* PLAN_VERSION: uncomment the below block < Line 163 - 173 > - DONE */}
                <div className="w-full bg-pot-blue/10 flex relative justify-end gap-x-10 items-center px-[2vw] py-2 text-xs">
                  <p className="w-full text-center absolute top-[25%] left-2 text-sm whitespace-pre-wrap font-semibold">
                    Please contact us for a Free Demo !!!
                  </p>
                  <button
                    onClick={() => setOpenContactForm(true)}
                    className="underline isolate whitespace-nowrap text-pot-blue text-sm"
                  >
                    Contact Us
                  </button>
                </div>
              </section>
            </motion.section>
          </section>
        </MacScrollbar>
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7 }}
          className="max-sm:hidden w-full bg-login-bg relative h-screen bg-cover bg-right-top"
        >
          <div className="absolute top-0 left-0 h-full w-full bg-[#16517E]/20"></div>
          <div className="absolute z-10 top-0 w-full px-[2vw] h-16 flex justify-end mt-10">
            <img src={park_associates} alt="park associates" />
          </div>
          <button
            onClick={() => setOpenYTModal(true)}
            className="absolute z-10 top-0 left-0 h-full w-full flex justify-center items-center"
          >
            <div className="w-fit h-fit relative ml-[3vw] mb-[4vh]">
              <div className="play-btn w-fit h-fit px-3 py-3 bg-white rounded-full">
                <div className="translate-x-[2px]">
                  <PlayIcon />
                </div>
              </div>
            </div>
          </button>
        </motion.section>
      </div>
      <ModalTransition>
        {openContactForm && (
          <Modal onClose={() => setOpenContactForm(false)}>
            {contactFormSubmitted ? (
              <div className="w-full flex flex-col justify-center p-10">
                <img src={ThumbsUp} className="max-h-[20vh] pb-8" />
                <p className="pb-10">
                  Thank you for contacting us! We have received your message and
                  will get back to you as soon as possible.
                </p>
                <div className="w-full flex justify-center items-center">
                  <Button
                    text={"Okay"}
                    primary
                    fixed
                    onClick={() => {
                      setOpenContactForm(false);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="px-10 py-5 max-h-[95vh] overflow-y-auto bg-pot-bg-1">
                <p className=" text-base font-semibold">Contact Us</p>

                <p className="text-pot-text-grey text-xs mt-2">
                  You agree to receive correspondence from Parks Associates by
                  completing this form.
                  <br />
                  <a
                    href="https://www.parksassociates.com/privacy-policy" // original privacy policy url
                    target="_blank"
                    rel="noreferrer"
                    className="text-pot-blue"
                  >
                    View our privacy policies.
                  </a>
                </p>
                <ContactForm
                  small
                  error={contactError}
                  formData={formData}
                  loading={submitLoading}
                  onSubmit={handleContactSubmit}
                  setError={setContactError}
                  setFormData={setFormData}
                />
              </div>
            )}
          </Modal>
        )}
      </ModalTransition>
      <ModalTransition>
        {openYTModal && (
          <Modal onClose={() => setOpenYTModal(false)} width="x-large">
            <iframe
              src="https://www.youtube.com/embed/oNu_ZWwvMW0?autoplay=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              allowfullscreen
              className="w-full aspect-video"
            />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default MainLogin;
