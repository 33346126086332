import { DotPulse } from "@uiball/loaders";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { ClipLoader } from "react-spinners";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import BigRetry from "../../assets/BigRetry";
import BigTick from "../../assets/BigTick";
import {
  DescriptionAtom,
  TableInfoAtom,
  titleAtom,
  UploadVerifyIdAtom,
  VerificationResultAtom,
} from "../../atom";
import "../../styles/Table.css";
import { DataImportApi } from "../../api/dataImport";
import { toast } from "react-toastify";
import { toTitleCase } from "../../utils/helperFunc";

const UploadVerifySuccess = ({ type = "upload_data" }) => {
  const navigate = useNavigate();
  const setTitle = useSetRecoilState(titleAtom);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadResult, setUploadResult] = useRecoilState(
    VerificationResultAtom
  );
  const [uploadId, setUploadId] = useRecoilState(UploadVerifyIdAtom);
  const [description, setDescription] = useRecoilState(DescriptionAtom);
  const [tableInfo, setTableInfo] = useRecoilState(TableInfoAtom);
  const forNPSChurnPage = type === "nps";

  const helper = (info) => {
    let result = [];
    info?.forEach((element, i) => {
      let uniqueHeader = "";
      let noOfRecords = {};
      let ignoredComments = {};
      let comments = {};
      Object?.keys(element)?.forEach((e) => {
        if (e === "sheet") {
          uniqueHeader = element[e];
        } else {
          const temp = {
            key: [toTitleCase(e?.replace(/_/g, " "))],
            value: element[e],
          };
          if (e === "no_of_records") {
            noOfRecords = temp;
          } else if (e === "ignored_columns") {
            ignoredComments = temp;
          } else {
            comments = temp;
          }
        }
      });
      let uniqueBody = [noOfRecords, ignoredComments, comments];
      const object = {
        head: uniqueHeader,
        body: uniqueBody,
      };
      result.push(object);
    });
    return result;
  };

  const formattedData = helper(tableInfo);

  useEffect(() => {
    if (uploadResult) {
      setTitle("Upload Data");
      Array.isArray(uploadResult)
        ? setResult(uploadResult[0])
        : setResult(uploadResult);
      setUploadResult(null);
    } else {
      navigate("/admin");
    }
  }, []);

  const downloadData = async (id) => {
    try {
      setLoading(true);
      const newId = id.replace(".xlsx", "");
      const response = await DataImportApi.download(newId, { kind: "errors" });
      if (response.status !== 200) {
        const errorString = await response.data.text();
        const errorObj = JSON.parse(errorString);
        toast.error(errorObj.errors.error_message.join(", "));
        setLoading(false);
        return;
      }
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.target = "_blank";
      link.setAttribute("download", "Import Errors.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLoading(false);
    } catch (e) {
      toast.error(JSON.stringify(e));
      setLoading(false);
    }
  };

  if (result === "SUCCESS") {
    return (
      <div className="w-full h-[90vh] flex flex-col gap-2 justify-center items-center pr-10">
        <BigTick size="100" />
        <p className="font-semibold mt-5">Upload Successful</p>
        <p className="text-sm text-pot-text-grey-2 w-[40vw] text-center">
          The file is uploaded successfully and the updated data is live on the
          customer side
        </p>
        {description && description?.length > 0 && (
          <div className="my-10 w-[70%] border-2">
            <div className="sticky top-0 bg-pot-background-grey-2">
              <p className="flex items-center h-10 border-b-2">
                <span className="pl-10 py-3">Problems Found</span>
              </p>
            </div>
            <div className="max-h-[30vh] overflow-y-auto custom-scrollbar">
              <table className="w-full">
                <tbody className="divide-y">
                  {description?.map((row, index) => (
                    <tr className="h-10" key={index}>
                      <td className="pl-10 py-3">{row}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div className="flex flex-col items-center gap-4">
          {!forNPSChurnPage && description && description?.length > 1 && (
            <button
              onClick={() => downloadData(uploadId)}
              className="bg-pot-dark-blue rounded-md px-4 py-2 text-white  mt-5"
            >
              {loading ? (
                <DotPulse size={20} speed={0.8} color="#fff" />
              ) : (
                "Download Errors"
              )}
            </button>
          )}
          <div className="grid grid-cols-2 w-[64vw] gap-10">
            {forNPSChurnPage &&
              tableInfo &&
              formattedData?.map((table, index) => {
                return (
                  <div className="border-[1px]" key={index}>
                    <div className="bg-[#fafafa] w-full px-6 py-2 font-bold">
                      {table?.head || "-"}
                    </div>
                    <div className="h-[250px] divide-y overflow-y-scroll custom-scrollbar text-sm">
                      {table?.body?.map((item, idx) => {
                        if (Object?.keys(item)?.length === 0) return null;
                        const tableKey =
                          item?.key[0] === "No Of Records"
                            ? "No. of Records saved"
                            : item?.key[0] === "Ignored Columns"
                            ? "Unknown Columns"
                            : item?.key[0] === "Columns"
                            ? "Detected Columns"
                            : item?.key;
                        return (
                          <div className="px-6 py-2 grid grid-cols-2" key={idx}>
                            <div>{tableKey || "-"}</div>
                            {Array?.isArray(item?.value) ? (
                              <div className="flex flex-col w-full">
                                {item?.value?.length > 0 ? (
                                  item?.value?.map((value, currIdx) => {
                                    return (
                                      <li className="" key={currIdx}>
                                        {value || "-"}
                                      </li>
                                    );
                                  })
                                ) : (
                                  <div className="">{"-"}</div>
                                )}
                              </div>
                            ) : (
                              <div>{item?.value || "-"}</div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
          <button
            onClick={() => {
              window.history.back();
              setDescription("");
              setUploadId("");
            }}
            className="bg-pot-dark-blue rounded-md px-4 py-2 text-white w-fit mt-5"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-[90vh] flex flex-col gap-2 justify-center items-center pr-10">
      {result ? (
        <>
          <BigRetry />
          <p className="font-semibold mt-5">Data Upload failed</p>
          <p className="text-sm text-pot-text-grey-2 w-[40vw] text-center">
            No changes were saved. Go through the below mentioned list of errors
            and re-upload the corrected file
          </p>
          <div className="flex flex-col items-center gap-2 w-[80%] mt-6">
            {description &&
              description?.map((error, index) => (
                <p
                  className="text-sm text-pot-text-grey-2  text-center"
                  key={index}
                >
                  {error}
                </p>
              ))}

            {forNPSChurnPage && tableInfo && tableInfo?.length > 0 && (
              <div className="h-fit max-h-[200px] overflow-y-scroll custom-scrollbar max-w-[50vw]">
                {tableInfo.map((item, index) => {
                  return (
                    <div className="flex border-[1px] bg-[#fafafa]">
                      <div className="border-r-[1px] px-4 py-2 w-[44px] mr-4 flex justify-center items-center">
                        {index + 1}
                      </div>
                      <div className="py-2">{item}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <button
            onClick={() => {
              window.history.back();
              setDescription("");
            }}
            className="bg-pot-dark-blue rounded-md px-4 py-2 text-white mt-5"
          >
            Back
          </button>
        </>
      ) : (
        <DotPulse speed={0.8} size={20} />
      )}
    </div>
  );
};

export default UploadVerifySuccess;
