import React from 'react'
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import Header from '../../components/Pdf/Header';
import world from "../../assets/pdf_png/world.png"
import List from '../../components/Pdf/List';

const Countries = ({ countries }) => {
    const styles = StyleSheet.create({
        container: {
            marginTop: "10px",
            border: 1,
            borderRadius: "8px",
            borderColor: "#F0F1F8",
            width: "100%",
          },
          contents_container: {
                paddingVertical: "5px",
                paddingHorizontal: "8px",
          },
          content: {
              display: "flex",
              flexDirection: "row",
              marginVertical: "2px",
          },
    })
  return (
    <View wrap={false} style={styles.container}>
        <Header image={world} title="Countries" />
        <View style={styles.contents_container}>
            {Object.keys(countries).map((item) =>(
                <View wrap={false} style={styles.content}>
                    <List text={item} bold/>
                    <Text>{countries[item].join(", ")}</Text>
                </View>
            ))}
        </View>
    </View>
  )
}

export default Countries