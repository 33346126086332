import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomerApi } from "../../api/customer";
import TableLayoutThree from "../../components/TableLayout/Three";
import usePlanTableFetch from "../../hooks/fetch/usePlanTableFetch";
import useCustomerHistoryColdef from "../../hooks/useCustomerHistoryColdef";
import { motion } from "framer-motion";
import { DotPulse } from "@uiball/loaders";

const HistoryTable = ({ signalToCall, removeSignalToCall }) => {
  const [openDeleteModal, setDeleteId] = useState(null);
  const [openExpireModal, setExpireId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { data, callAgain } = usePlanTableFetch();
  const colDef = useCustomerHistoryColdef({ setDeleteId, setExpireId });
  const { id } = useParams();

  // const data = Array.from({ length: 10 }).map((_, id) => ({
  //     plans: "3 day trial",
  //     start_date: "30-01-2022",
  //     expiry_date: "30-01-2022",
  //     permissions: "1",
  //     actions: id === 2 ? "expiry" : "delete"
  // }))

  const deleteHistory = async (plan_id) => {
    setLoading(true);
    await CustomerApi.delete_plan_history(id, plan_id);
    setLoading(false);
    setDeleteId(null);
    callAgain();
  };

  const expiryHistory = async (plan_id) => {
    setLoading(true);
    await CustomerApi.expire_plan_history(id, plan_id);
    setLoading(false);
    setExpireId(null);
    callAgain();
  };

  const table = useReactTable({
    data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (signalToCall) {
      callAgain();
      removeSignalToCall();
    }
  }, [signalToCall]);

  return (
    <>
      <div className="pb-8">
        <p className="pb-2 text-sm font-semibold px-4">History</p>
        <div className="max-h-[50vh] overflow-y-auto">
          <TableLayoutThree table={table} small />
        </div>
      </div>
      <ModalTransition>
        {openExpireModal && (
          <Modal onClose={() => setExpireId(null)}>
            <div className="w-full flex flex-col items-center p-5">
              <p>Are you sure you want to instantly expire this plan?</p>
              <div className="flex justify-center mt-5 gap-x-4">
                <motion.button
                  onClick={() => setExpireId(null)}
                  whileHover={{ scale: 1.1 }}
                  className="bg-pot-grey-4/20 px-4 py-2 flex gap-x-2 items-center text-pot-text-grey-2 text-sm font semibold rounded-md"
                >
                  Cancel
                </motion.button>
                <motion.button
                  onClick={() => !loading && expiryHistory(openExpireModal)}
                  whileHover={{ scale: 1.1 }}
                  className="bg-pot-blue px-4 py-2 flex gap-x-2 items-center text-white text-sm font semibold rounded-md"
                >
                  {loading && <DotPulse size={12} speed={0.8} color="white" />}
                  Expire
                </motion.button>
              </div>
            </div>
          </Modal>
        )}
        {openDeleteModal && (
          <Modal onClose={() => setDeleteId(null)}>
            <div className="w-full flex flex-col items-center p-5">
              <p>Are you sure you want to delete this plan?</p>
              <div className="flex justify-center mt-5 gap-x-4">
                <motion.button
                  onClick={() => setDeleteId(null)}
                  whileHover={{ scale: 1.1 }}
                  className="bg-pot-grey-4/20 px-4 py-2 flex gap-x-2 items-center text-pot-text-grey-2 text-sm font semibold rounded-md"
                >
                  Cancel
                </motion.button>
                <motion.button
                  onClick={() => !loading && deleteHistory(openDeleteModal)}
                  whileHover={{ scale: 1.1 }}
                  className="bg-pot-blue px-4 py-2 flex gap-x-2 items-center text-white text-sm font semibold rounded-md"
                >
                  {loading && <DotPulse size={12} speed={0.8} color="white" />}
                  Delete
                </motion.button>
              </div>
            </div>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default HistoryTable;
