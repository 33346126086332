import { authenticatedAxiosInstance as axios } from "./axios";

const list = (params) => axios.get(`/notification/email-template/`, params);
const create = (payload) =>
  axios.post(`/notification/email-template/`, payload);
const getOne = (id) => axios.get(`/notification/email-template/${id}/`);
const edit = (id, payload) =>
  axios.patch(`/notification/email-template/${id}/`, payload);
const remove = (id) => axios.delete(`/notification/email-template/${id}/`);
const history = (params) => axios.get(`/notification/email/history/`, params);
const details = (id) => axios.get(`/notification/email/history/${id}/`);
const send = (payload) => axios.post(`/notification/email/bulk-send/`, payload);

export const EmailAPI = {
  list,
  create,
  getOne,
  edit,
  remove,
  history,
  details,
  send,
};
