import { authenticatedAxiosInstance as axios } from "./axios";

// ADMIN SIDE Upload Data
const upload = (payload) => axios.post(`/nps-churn/admin/upload/`, payload);
const history = (params) =>
  axios.get(`/nps-churn/admin/upload/history/`, params);
const downloadPrefilled = () =>
  axios.get(`nps-churn/admin/download-prefilled/`, {
    responseType: "blob",
  });

export const NPSChurnImport = { upload, history, downloadPrefilled };

// ADMIN SIDE Non Standard Charts
const list = (params) => axios.get(`/nps-churn/admin/chart/`, params);
const update = (id, payload) =>
  axios.patch(`/nps-churn/admin/chart/${id}/`, payload);
const destroy = (id) => axios.delete(`/nps-churn/admin/chart/${id}/`);
const downloadChart = (id) =>
  axios.get(`nps-churn/admin/chart/${id}/download-prefilled/`, {
    responseType: "blob",
  });

export const CustomCharts = { list, update, destroy, downloadChart };

// CUSTOMER SIDE NPS
const npsList = (payload, params) =>
  axios.post(`/nps-churn/nps/`, payload, params);
const npsIndustryBenchmark = () =>
  axios.get(`/nps-churn/chart/?page-filter=NPS`);

export const NPSImport = { npsList, npsIndustryBenchmark };

// CUSTOMER SIDE Churn

const churnList = (payload, params) =>
  axios.post(`/nps-churn/churn/`, payload, params);
const churnIndustryBenchmark = () =>
  axios.get(`/nps-churn/chart/?page-filter=CHURN`);

export const CHURNImport = { churnList, churnIndustryBenchmark };
