import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import AuthHeader from "./components/AuthHeader";
import AuthInputField from "./components/AuthInput";
import AuthSubmit from "./components/AuthSubmit";

const ChangePasswordForm = ({ onSubmit= () => {}, error, setError }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordError, setPasswordError] = useState(false);
  const [showConfirmPasswordError, setConfirmPasswordError] = useState(false);
  // const navigate = useNavigate();
  const description = ``;

  const handlePasswordValidation = () =>
    password.length < 8 ? setPasswordError(true) : setPasswordError(false);

  const handleConfirmPasswordValidation = () =>
    password !== confirmPassword
      ? setConfirmPasswordError(true)
      : setConfirmPasswordError(false);

  return (
    <section>
      <AuthHeader heading={"Set New Password"} description={description} />
      <main>
        <AuthInputField
          onChange={(e) => {
            setPassword(e.target.value)
            delete error.new_password
            setError({...error})
          }}
          heading="Password"
          placeholder="⦁⦁⦁⦁⦁⦁⦁⦁"
          type="password"
          onBlur={handlePasswordValidation}
          errorMessage={error.new_password || ""}
        />
        {showPasswordError && (
          <p className="mt-1 text-xs ml-1">Must be atleast 8 characters</p>
        )}
        <AuthInputField
          onChange={(e) => {
            setConfirmPassword(e.target.value)
            delete error.confirm_password
            setError({...error})
          }}
          heading="Confirm Password"
          placeholder="⦁⦁⦁⦁⦁⦁⦁⦁"
          type="password"
          onBlur={handleConfirmPasswordValidation}
          errorMessage={error.confirm_password ||  ""}
        />
        {showConfirmPasswordError && (
          <p className="mt-1 text-xs ml-1">Both passwords must match</p>
        )}
      </main>
      <AuthSubmit
        text="Set Password"
        onClick={() => onSubmit(password, confirmPassword)}
        notValid={showConfirmPasswordError || showPasswordError}
      />
    </section>
  );
};

export default ChangePasswordForm;
