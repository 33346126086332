import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import location from "../../assets/pdf_png/location_blue_big.png";

const Header = ({ cols, widths, keys, region }) => {
  const styles = StyleSheet.create({
    header_container: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      marginTop: "20px",
      marginBottom: "10px",
      justifyContent: "space-between",
    },
    heading: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#1767A5",
    },
    table_head: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#F4F4F4",
    },
    region: {
      display: "flex",
      flexDirection: "row",
      columnGap: "1px",
      alignItems: "flex-end",
      paddingRight: "5px",
      paddingBottom: "5px"
    },
    region_text: {
      fontSize: "7px",
      color: "#1767A5",
      fontWeight: "medium",
    },
    region_img: {
      width: "10px",
      height: "10px",
      paddingBottom: "2px",
      paddingLeft: "2px"
    },
  });

  return (
    <View fixed>
      <View style={styles.header_container}>
        <Text style={styles.heading}>Services Comparison</Text>
        <View style={styles.region}>
          <Image style={styles.region_img} src={location} />
          <Text style={styles.region_text}>{region.toUpperCase()}</Text>
        </View>
      </View>
      <View style={styles.table_head}>
        {keys.map((key, id) => (
          <View
            style={{
              width: widths[id],
              padding: "5px 10px",
              textAlign: id ? "center" : "left",
              color: id ? "#1767A5" : "black",
              fontSize: id ? "12px" : "8px",
              fontWeight: id ? "semibold" : "normal",
            }}
          >
            <Text>{cols[key]}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default Header;
