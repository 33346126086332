import React, { useState, useEffect } from "react";
import packageJson from "../package.json";
import moment from "moment";

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    console.log("This is not the lates build");
    return true;
  } else {
    console.log("This is the correct app");
    return false;
  }
};

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      if (process.env.NODE_ENV === "development") {
        console.log("This is development server")
        setIsLatestBuildDate(true);
      } else {
        console.log(process.env.NODE_ENV, "build")
        const api =
          process.env.REACT_APP_BASIC_ENV === "development"
            ? "/meta_dev.json"
            : "/meta.json";
        fetch(api)
          .then((response) => response.json())
          .then((meta) => {
            const key =
              process.env.REACT_APP_BASIC_ENV === "development"
                ? "devBuildDate"
                : "buildDate";
            const latestVersionDate = meta[key];
            const currentVersionDate = packageJson[key];

            const shouldForceRefresh = buildDateGreaterThan(
              latestVersionDate,
              currentVersionDate
            );
            if (shouldForceRefresh) {
              setIsLatestBuildDate(false);
              refreshCacheAndReload();
            } else {
                setIsLatestBuildDate(true)
            } 
          })
          .catch((e) => {
            console.log(e, "This is an error");
            setIsLatestBuildDate(true);
          });
      }
    }, [window.location.pathname]);

    /**
     * unregisters all service workers
     * removes all caches
     * and then hard reloads the app.
     */
    const refreshCacheAndReload = () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistrations().then((regs) => {
          regs.map((r) => r.unregister());
        });
      }
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload(true);
    };

    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
