import React from "react";

const TickMark = () => {
  return (
    <svg
      width="9"
      height="7"
      viewBox="0 0 9 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71929 2.30529C1.62704 2.20978 1.5167 2.1336 1.39469 2.08119C1.27269 2.02878 1.14147 2.00119 1.00869 2.00004C0.87591 1.99888 0.744231 2.02419 0.621334 2.07447C0.498438 2.12475 0.386786 2.199 0.292893 2.29289C0.199001 2.38679 0.124748 2.49844 0.0744668 2.62133C0.0241859 2.74423 -0.00111606 2.87591 3.77564e-05 3.00869C0.00119157 3.14147 0.0287774 3.27269 0.0811864 3.39469C0.133595 3.5167 0.209778 3.62704 0.305288 3.71929L2.30529 5.71929C2.49282 5.90676 2.74712 6.01207 3.01229 6.01207C3.27745 6.01207 3.53176 5.90676 3.71929 5.71929L7.71929 1.71929C7.8148 1.62704 7.89098 1.5167 7.94339 1.39469C7.9958 1.27269 8.02339 1.14147 8.02454 1.00869C8.02569 0.87591 8.00039 0.744231 7.95011 0.621335C7.89983 0.498438 7.82558 0.386787 7.73168 0.292894C7.63779 0.199001 7.52614 0.124748 7.40324 0.0744673C7.28035 0.0241864 7.14867 -0.00111606 7.01589 3.77557e-05C6.88311 0.00119157 6.75189 0.0287779 6.62988 0.0811869C6.50788 0.133596 6.39754 0.209779 6.30529 0.305289L3.01229 3.59829L1.71929 2.30529Z"
        fill="#FAFBFC"
      />
    </svg>
  );
};

export default TickMark;
