import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { CustomerApi } from "../api/customer";
import expIcon from "../assets/expiry.svg";
import Trash from "../assets/Trash";

const useCustomerHistoryColdef = ({ setDeleteId, setExpireId }) => {
  const [showExpire, setShowExpire] = useState("");
  const [showMainTooltip, setShowMainTooltip] = useState("");
  
  const colDef = [
    {
      header: "Plans",
      accessorKey: "name",
      size: 200,
      cell: ({ getValue, row }) => (
        <div className="relative ">
          <div
            className={`${
              row.original.is_active && "font-semibold"
            } cursor-default flex flex-wrap gap-x-1`}
            onMouseEnter={() => setShowMainTooltip(row.original.id)}
            onMouseLeave={() => setShowMainTooltip("")}
          >
            <p className="whitespace-nowrap">{getValue()}</p>
            <p className="whitespace-nowrap">{`${
              row.original.duration > 0
                ? " (" + row.original.duration + (row.original.duration === 1 ? " day)" : " days)")
                : ""
            }`}</p>
          </div>
          {row.original.id === showMainTooltip && (
            <div className="absolute z-[98] text-xs rounded px-1 py-0.5 bg-[#028D9B] -top-9 text-white left-2">
              <p className="whitespace-nowrap">
                Applied Date:{" "}
                {moment(row.original.applied_at || "").format("MM-DD-YYYY")}
              </p>
              <p className="whitespace-nowrap">
                Acitvated By: {row.original.created_by}
              </p>
            </div>
          )}
        </div>
      ),
    },
    {
      header: "Start Date",
      size: 100,
      accessorKey: "start_at",
      cell: ({ getValue, row }) => (
        <p
          className={`${
            row.original.is_active && "font-semibold"
          } whitespace-nowrap`}
        >
          {getValue() ? moment(getValue()).format("MM-DD-YYYY") : "-"}
        </p>
      ),
    },
    {
      header: "Expiry Date",
      size: 100,
      accessorKey: "expire_at",
      cell: ({ getValue, row }) => (
        <p
          className={`${
            row.original.is_active && "font-semibold"
          } whitespace-nowrap`}
        >
          {getValue() ? moment(getValue()).format("MM-DD-YYYY") : "-"}
        </p>
      ),
    },
    {
      header: "Permissions",
      accessorKey: "permissions",
      size: 20,
      cell: ({ getValue, row }) => (
        <div
          className={`${row.original.is_active && "font-semibold"} text-center`}
        >
          {getValue().length}
        </div>
      ),
    },
    {
      header: () => <p className="w-full text-right">Actions</p>,
      accessorKey: "actions",
      size: 20,
      cell: ({ row }) => {
        return (
          <>
            {row.original.is_expireable && (
              <div className="relative w-full h-fit">
                <button
                  onClick={() => setExpireId(row.original.id)}
                  onMouseEnter={() => setShowExpire(row.original.id)}
                  onMouseLeave={() => setShowExpire("")}
                  className="w-full flex justify-center"
                >
                  <img src={expIcon} className="w-fit h-fit" />
                </button>
                {showExpire === row.original.id && (
                  <div className="absolute z-[98] -top-7 right-0 w-fit h-fit px-2 py-1 bg-pot-bg-blue text-pot-blue">
                    Expire
                  </div>
                )}
              </div>
            )}
            {row.original.is_deleteable && (
              <button
                onClick={() => setDeleteId(row.original.id)}
                className="w-full flex justify-center"
              >
                <Trash />
              </button>
            )}
          </>
        );
      },
    },
  ];

  return colDef;
};

export default useCustomerHistoryColdef;
