import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthApi } from "../api/Auth";
import AuthHeader from "./components/AuthHeader";
import AuthInputField from "./components/AuthInput";
import AuthSubmit from "./components/AuthSubmit";

const ChangePasswordAuthedForm = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPasswordError, setShowCurrentPasswordError] = useState("");
  const [showPasswordError, setPasswordError] = useState(false);
  const [showConfirmPasswordError, setConfirmPasswordError] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const description = `Your new password must be different from previously used password`;

  const handlePasswordValidation = (password, setPasswordError) =>
    password.length < 8 ? setPasswordError(true) : setPasswordError(false);

  const handleConfirmPasswordValidation = () =>
    password !== confirmPassword
      ? setConfirmPasswordError(true)
      : setConfirmPasswordError(false);

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    const payload = {
      current_password: currentPassword,
      new_password: password,
      confirm_password: confirmPassword,
    };
    const { data: response } = await AuthApi.changePassword(payload);
    setLoading(false);
    if (response.success) {
      // localStorage.removeItem("token")
      // localStorage.removeItem("user_type")
      // localStorage.removeItem("loggedInAlways")
      // localStorage.removeItem("noChange")
      navigate("/");
    } else {
      let errs = {};
      // toast.error(response.errors.error_message.join(", "))
      response.errors.error_message.forEach((each) => {
        if (typeof each !== "object") {
          toast.error(each);
        } else {
          errs = { ...errs, ...each };
        }
      });
      if (Object.keys(errs).length) {
        setError(errs);
      }
    }
  };

  return (
    <section>
      <AuthHeader heading={"Change Password"} description={description} />
      <main>
        <AuthInputField
          onChange={(e) => {
            setCurrentPassword(e.target.value);
            delete error.current_password;
            setError({ ...error });
          }}
          heading="Current Password"
          placeholder="⦁⦁⦁⦁⦁⦁⦁⦁"
          type="password"
          onBlur={() =>
            handlePasswordValidation(
              currentPassword,
              setShowCurrentPasswordError
            )
          }
          errorMessage={error.current_password || ""}
        />
        {showCurrentPasswordError && (
          <p className="text-xs text-red-400 h-0 ml-1">
            Must be atleast 8 characters
          </p>
        )}
        <AuthInputField
          onChange={(e) => {
            setPassword(e.target.value);
            delete error.new_password;
            setError({ ...error });
          }}
          heading="New Password"
          placeholder="⦁⦁⦁⦁⦁⦁⦁⦁"
          type="password"
          onBlur={() => handlePasswordValidation(password, setPasswordError)}
          errorMessage={error.new_password || ""}
        />
        {showPasswordError && (
          <p className="text-xs text-red-400 h-0 ml-1">
            Must be atleast 8 characters
          </p>
        )}
        <AuthInputField
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            delete error.confirm_password;
            setError({ ...error });
          }}
          heading="Confirm Password"
          placeholder="⦁⦁⦁⦁⦁⦁⦁⦁"
          type="password"
          onBlur={handleConfirmPasswordValidation}
          errorMessage={error.confirm_password || ""}
        />
        {showConfirmPasswordError && (
          <p className="text-xs text-red-400 h-0 ml-1">
            New password and confirm password must match
          </p>
        )}
      </main>
      <AuthSubmit
        text="Change Password"
        onClick={handleSubmit}
        notValid={
          showConfirmPasswordError ||
          showPasswordError ||
          showCurrentPasswordError
        }
        loading={loading}
      />
    </section>
  );
};

export default ChangePasswordAuthedForm;
