import React, { useEffect, useState } from "react";
import RoundedClose from "../../assets/RoundedClose";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  FilterValuesAtom,
  InitialColumnVisibilityAtom,
  SelectedProfileCountryAtom,
  titleAtom,
} from "../../atom";
import { toNormalText } from "../../utils/helperFunc";
import FilterFields from "./FilterFields";
import { OttDataApi } from "../../api/ottData";
import { formatToParams } from "../../utils/formatConfig";
import { motion } from "framer-motion";
import { MacScrollbar } from 'mac-scrollbar';
import { titles } from "../../utils/Titles";

const SearchView = () => {
  const [mainFilters, setMainFilters] = useRecoilState(FilterValuesAtom);
  const [initial, setInitial] = useState(true);
  const initialVisibilityObject = useRecoilValue(InitialColumnVisibilityAtom);
  // const [initialTotalCount, setInitialTotalCount] = useState(null);
  const settableFilters = mainFilters.map((each1) => {
    const key = Object.keys(each1);
    return {
      [key[0]]: [...Object.values(each1)[0].map((each2) => ({ ...each2 }))],
    };
  });

  
  const [filteredCount, setFilteredCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [allFilters, setAllFilters] = useState(settableFilters || []);
  const setTitle = useSetRecoilState(titleAtom);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom)

  const fetchTotalCount = async () => {
    // if(!initial && !allFilters.length) {
    //   setTotalCount(initialTotalCount)
    //   setFilteredCount(initialTotalCount)
    //   return
    // }
    const filterParam = formatToParams(allFilters);
    const fieldsParam = Object.keys(initialVisibilityObject).concat("name");
    const payload = { fields: fieldsParam, filters: filterParam };
    const params = { count_only: 1 };
    params.profile_country = selectedProfileCountry
    const { data: response } = await OttDataApi.list(payload, { params });

    setFilteredCount(response.data.filtered_count);
    setTotalCount(response.data.total_count);
    // setInitialTotalCount(response.data.total_count);
    if (initial) {
      setInitial(false);
    }
  };

  useEffect(() => {
    setTitle("Search");
    document.title = `Streaming Video Tracker | ${titles[window.location.pathname]}`
  }, []);

  useEffect(() => {
    fetchTotalCount();
  }, [allFilters, selectedProfileCountry]);

  return (
    <section className="w-full flex">
        <section className="w-full h-[93vh] overflow-y-auto">
          {/* <header className="p-8 flex justify-between">
          <div className="flex">
            <SearchIcon primaryColor="#3A61BA" size="medium" />
            <p className="ml-3 font-semibold text-pot-dark-blue">Search</p>
          </div>
          <button
            onClick={() => setAllFilters([])}
            className="flex items-center"
          >
            <RetryIcon primaryColor="#3A61BA" size="small" />
            <p className="ml-2 text-pot-dark-blue">reset</p>
          </button>
        </header> */}
          <FilterFields
            allFilters={allFilters}
            setAllFilters={setAllFilters}
            setMainFilters={setMainFilters}
            initial={initial}
          />
        </section>
      <section className="w-[40%] 2xl:w-[28%] border-l">
        <header className="px-5 h-[10vh] flex flex-col justify-center border-b">
          <p className="text-pot-dark-blue font-bold">Active Filters</p>
          {/* <p className="text-pot-grey-3 text-sm">Lorem ipsum dolor sit ametf</p> */}
        </header>
        <section className="h-[1vh] bg-white "></section>
          <section className="h-[72vh] relative overflow-y-auto px-2">
            {allFilters.map((each) => (
              <section className="p-3 transition ease-in-out">
                <p className=" font-semibold mb-3 text-sm">
                  {String(parseInt(Object.keys(each)[0])) !==
                  Object.keys(each)[0]
                    ? toNormalText(Object.keys(each)[0]).toUpperCase()
                    : ""}
                </p>
                {Object.values(each)[0].map((value) => (
                  <div className="flex justify-between items-center">
                    <p
                      className={`text-sm text-gray-600 ${
                        String(parseInt(Object.keys(each)[0])) ===
                          Object.keys(each)[0] && "font-semibold"
                      }`}
                    >
                      {value.label}
                    </p>
                    <button
                      onClick={() => {
                        each[Object.keys(each)[0]] = Object.values(
                          each
                        )[0].filter((val) => val.value !== value.value);
                        if (!each[Object.keys(each)[0]].length) {
                          const dollor = allFilters.filter((yo) => yo !== each);
                          setAllFilters([...dollor]);
                        } else {
                          setAllFilters([...allFilters]);
                        }
                      }}
                    >
                      <RoundedClose />
                    </button>
                  </div>
                ))}
              </section>
            ))}
          </section>
        <section className="w-full h-[10vh] text-sm bg-white border-t relative bottom-0 flex justify-center items-center pr-10">
          <div>
            <p>Results Will Show </p>
            <div className="text-pot-dark-blue text-2xl mt-2 font-bold flex gap-x-2">
              <motion.div
                key={filteredCount}
                animate={{
                  scale: [1, 1.3, 1],
                }}
                // whileHover={{
                //   scale: [1, 1.3, 1],
                // }}
              >
                {filteredCount}
              </motion.div>{" "}
              of
              <motion.span
              // key={filterCount}
              // animate={{
              //   scale: [1, 1.2, 1]
              // }}
              >
                {totalCount}
              </motion.span>{" "}
              Profiles
            </div>
          </div>
        </section>
      </section>
    </section>
  );
};

export default SearchView;
