// import TextField from "@atlaskit/textfield";
import Select, { CheckboxSelect } from "@atlaskit/select";
import { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import Close from "../assets/Close";
import Plus from "../assets/Plus";
import { AllFilterOptionsAtom, FilterValuesAtom } from "../atom";
import CheckIcon from "@atlaskit/icon/glyph/check";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import company1 from "../assets/company1.png";
import RoundedFilledClose from "../assets/RoundedFilledClose";
import { formatNamesToObj } from "../utils/formatConfig";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { padStart } from "../utils/helperFunc";
import SelectInput from "../form/components/SelectInput";
import { ValueContainer } from "../form/components/SelectInput";

const useCompareViewPlatformColDef = (
  comparedHeaders,
  totalCols,
  setComparedHeaders,
  setComparedValues,
  setTotalCols
) => {
  const initialFirstColDef = {
    header: () => (
      <p className="text-center text-sm font-semibold">Platforms</p>
    ),
    accessorKey: "keyName",
    enablePinning: true,
    size: 10,
    cell: ({ getValue }) => <p className="text-center text-sm">{getValue()}</p>,
  };

  const [colDef, setColDef] = useState([]);
  const allFilterOptions = useRecoilValue(AllFilterOptionsAtom);
  const [allNamesObj, setAllNamesObj] = useState(null);
  const keyNames = ["value1", "value2", "value3", "value4", "value5"];

  useEffect(() => {
    if (allFilterOptions.names) {
      console.log(allFilterOptions, "allFilterOptions")
      const names = formatNamesToObj(allFilterOptions.names);
      setAllNamesObj({ ...names });
    }
  }, [allFilterOptions]);

  const getEachColDef = (value, index, namesNumber) => ({
    header: ({ header }) => {
      console.log(header, "header", totalCols);
      return comparedHeaders[value] ? (
        <div
          className={`w-full flex justify-center font-popins text-lg font-semibold `}
        >
          <div className="flex items-center ">
            <div className="flex items-center gap-x-3">
              {/* <div className="w-10 h-10 flex-shrink-0 rounded-full bg-white flex justify-center items-center object-contain"> */}
              {/* <img src={company1} className="object-contain h-8 w-8" /> */}
              {/* </div> */}
              {allNamesObj ? (
                <div className="w-full h-full relative">
                  <p className="cursor-pointer text-pot-dark-blue mr-3">
                    {comparedHeaders[value].label}
                  </p>
                </div>
              ) : (
                <p className="text-pot-dark-blue mr-3">
                  {!!allNamesObj ? "true" : JSON.stringify(allNamesObj)}
                  {comparedHeaders[value].label}
                </p>
              )}
            </div>
            <button
              onClick={() => {
                let settableComparedHeaders;
                if (Object.keys(comparedHeaders).length > 2) {
                  settableComparedHeaders = Object.keys(comparedHeaders).reduce(
                    (acc, cur, id) => {
                      if (cur === value) {
                        return acc;
                      }
                      const keyIndex = keyNames.indexOf(cur);
                      if (keyIndex > keyNames.indexOf(value)) {
                        acc[keyNames[keyIndex - 1]] = comparedHeaders[cur];
                      } else {
                        acc[cur] = comparedHeaders[cur];
                      }
                      return acc;
                    },
                    {}
                  );
                  setTotalCols((prev) => prev - 1);
                } else {
                  settableComparedHeaders = {
                    ...comparedHeaders,
                    [value]: null,
                  };
                }
                const vals = keyNames.filter((val) => val >= value);
                const obj = {};
                vals.forEach((each) => (obj[each] = null));
                setComparedValues((prev) => ({ ...prev, ...obj }));
                setComparedHeaders(settableComparedHeaders);
              }}
            >
              <RoundedFilledClose />
            </button>
          </div>
        </div>
      ) : (
        <div
          className={`w-full flex justify-center items-center font-popins text-sm font-normal`}
        >
          <div
            className={
              5 - namesNumber > 2
                ? "w-1/2 mr-2"
                : 5 - namesNumber > 1
                ? "w-3/4 mr-2"
                : "w-full mr-2"
            }
          >
            {/* <SelectInput
              // heading="Platforms"
              keyValue="platform_devices"
              allFilters={allFilters}
              setAllFilters={setAllFilters}
              z="z-[94]"
              options={[
                {
                  label: "distribution platforms".toUpperCase(),
                  options: allFilterOptions.distribution_platforms,
                },
                ...allFilterOptions.device_supports,
              ]}
              placeholder="Select Platforms"
              columnVise
              closeMenuOnSelect={true}
              // showInfo="Distribution on top connected devices, operating systems, [and/or] aggregation platforms"
            /> */}

            <CheckboxSelect
              value={Object.values(comparedHeaders).map(each => (each ? { type: each.type, value: each.value, label: each.label } : null))}
              onChange={(e) => {
                const condition = Object.values(comparedHeaders).some((each) => {
                  console.log(each, e.slice(-1)[0], "eee")
                  return(each?.value === e.slice(-1)?.[0]?.value)
                })
                if(condition) return;
                setComparedHeaders((prev) => ({
                  ...prev,
                  [keyNames[index]]: { ...e.slice(-1)[0], keyName: value },
                }));
              }}
              // options={[
              //   {
              //     label: "distribution platforms".toUpperCase(),
              //     options: allFilterOptions.distribution_platforms,
              //   },
              //   ...allFilterOptions.device_supports,
              // ]}
              options={[
                {
                  label: "distribution platforms".toUpperCase(),
                  options: allFilterOptions.filtered_platforms,
                },
                ...allFilterOptions.filtered_devices,
              ]}
              placeholder="Choose Something"
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: "white",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "#1767A5",
                }),
                option: (base) => ({
                  display: "flex",
                  justifyContent: "left",
                  textAlign: "left",
                  cursor: "pointer",
                }),
                menu: (base, state) => ({
                  ...base,
                  width: "fit-content",
                  height: "fit-content",
                  padding: "20px 2px",
                  // height: "70vh",
                  // overflowY: "scroll",
                  right:
                    header.index > totalCols / 2
                      ? totalCols - header.index
                        ? `-${(totalCols - header.index) * 10}vw`
                        : "0vw"
                      : "auto",
                  left:
                    header.index > totalCols / 2
                      ? "auto"
                      : `-${(header.index - 0.5) * 10}vw`,
                }),
                menuList: (base, state) => {
                  let val = undefined
                  if(state.children.length < 3) {
                    val = 1
                  } else {
                    if(Array.isArray(state.children)){
                      if(!state.children?.some((each) => each.props?.options?.length > 5) ) {
                        val = 1
                      }
                    } else { val = 1}
                  }

                  console.log(val, "Val $$$", state.children)
                  return({
                  // height: "65vh",
                  // overflowY: "auto",
                  columnCount: val || 3,
                  columnGap: 2,
                  columnRule: "2px solid #F3F3F3",
                  "@media only screen and (min-width: 1550px)": {
                    columnCount: val || 4,
                    columnGap: 2,
                    columnRule: "2px solid #F3F3F3",
                  },
                })},
              }}
              components={{
                MultiValueContainer: () => <></>,
                ClearIndicator: () => <></>,
                ValueContainer,
              }}
            />
          </div>
          {Object.keys(comparedHeaders).length > 2 ? (
            <button
              onClick={() => {
                if (Object.keys(comparedHeaders).length > 2) {
                  const settableCompareHead = Object.keys(
                    comparedHeaders
                  ).reduce(
                    (acc, cur) =>
                      cur === value
                        ? acc
                        : keyNames.indexOf(cur) > keyNames.indexOf(value)
                        ? {
                            ...acc,
                            [keyNames[keyNames.indexOf(cur) - 1]]:
                              comparedHeaders[cur],
                          }
                        : { ...acc, [cur]: comparedHeaders[cur] },
                    {}
                  );
                  setComparedHeaders({ ...settableCompareHead });
                  setTotalCols((prev) => prev - 1);
                }
              }}
            >
              <RoundedFilledClose />
            </button>
          ) : (
            <></>
          )}
        </div>
      );
    },

    accessorKey: keyNames[index],

    cell: ({ getValue, row }) => getValue()?.name ? (
      <Link
        to={`/profile/${getValue().id}`}
        className="w-fit"
      >
      <p className="text-sm hover:underline text-pot-text-grey-2">
        {getValue().name}
      </p>
      </Link>
    ) : (
      <p
        className={`w-full ${
          Number.isInteger(getValue())
            ? "text-pot-blue font-medium text-base"
            : "text-sm text-center"
        } `}
      >
        {getValue()}
      </p>
    ),
  });

  useEffect(() => {
    const colDefLength =
      Object.keys(comparedHeaders).length > 2
        ? Object.keys(comparedHeaders).length
        : 2;
    const colDefTemp = [{ ...initialFirstColDef }];
    Array.from({ length: colDefLength }).forEach((_, id) => {
      const eachColDef = getEachColDef(
        Object.keys(comparedHeaders)[id],
        id,
        Object.keys(comparedHeaders).length
      );
      colDefTemp.push(eachColDef);
    });
    if (colDefLength < 5) {
      colDefTemp.push({
        header: () => (
          <button
            onClick={() => {
              let newKeyName;
              setComparedHeaders((prev) => {
                newKeyName = keyNames[Object.keys(prev).length];
                return { ...prev, [newKeyName]: null };
              });
              setComparedValues((prev) => ({ ...prev, [newKeyName]: null }));
              setTotalCols((prev) => prev + 1);
            }}
          >
            <Plus />
          </button>
        ),
        accessorKey: "nothing",
        size: 2,
      });
    }
    setColDef(colDefTemp);
  }, [comparedHeaders, allNamesObj]);

  return colDef;
};

export default useCompareViewPlatformColDef;
