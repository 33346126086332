import React, { useState } from "react";
import AuthInputField from "./components/AuthInput";
import AuthHeader from "./components/AuthHeader";
import AuthSubmit from "./components/AuthSubmit";
import Danger from "../assets/Danger";
import { validateEmail } from "../utils/validations";

const ForgotPasswordForm = ({ email, setEmail, onSubmit, error, setError, loading }) => {
  const description = ``;
  return (
    <section>
      <AuthHeader heading={"Forgot Password"} description={description} />
      <main>
        <AuthInputField
          onFocus={() => {
            if(error) {
              setError("")
              setEmail("")
            }
          }}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          onBlur={() => {
            if(email && !validateEmail(email)){
              setError("Email is not valid")
            }
          }}
          heading="Email ID"
          placeholder="Enter Your Registered Email ID"
        />
      </main>
      {error && (
        <div className="flex mt-5 items-center text-xs">
          <Danger />
          <p className="ml-3">{error}</p>
        </div>
      )}
      <AuthSubmit
        notValid={error}
        text="Submit"
        onClick={onSubmit}
        loading={loading}
      />
    </section>
  );
};

export default ForgotPasswordForm;
