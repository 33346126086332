import moment from 'moment';
import React from 'react'
import { checkDateIsLess } from '../../utils/DateHelpers';

const TopBarControl = ({ setInputDate, inputDate, date, setDate, setOpen, setValue=() => {}}) => {
  return (
    <div className="w-full flex justify-between items-center mt-4 text-sm pb-4 px-2 border-b mb-4">
        <input
          value={inputDate}
        //   onChange={(e) => {
        //     const val = e.target.value;
        //     const regex = /^[0-9]{0,2}\-[0-9]{0,2}\-[0-9]{0,2}$/;
        //     const match = val.match(regex)?.join("");
        //     if (!match) return;
        //     setInputDate(match);
        //   }}
        //   onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
        //   onBlur={() => {
        //     const settableDate = new Date(
        //       moment(inputDate, "MM-DD-YYYY").toDate()
        //     );
        //     const newDate = new Date();
        //     const check = checkDateIsLess(newDate, settableDate);
        //     if (!settableDate || check) {
        //       setInputDate(moment(date).format("MM-DD-YY"));
        //       return;
        //     }
        //     setDate(settableDate);
        //   }}
          className="w-28 text-center bg-pot-background-grey-2 p-2 cursor-default text-xs mr-2 rounded-md focus:bg-white"
        />
        {/* <button
          onClick={() => {
            setValue(date)
            setOpen(false)
          }}
          className={`p-2 bg-pot-blue text-white rounded-md`}
        >
          Set Date
        </button> */}
    </div>
  )
}

export default TopBarControl