import moment from "moment";
import React from "react";
import DetailIcon from "../../assets/Details";
import { fullSentance } from "../../utils/helperFunc";

const Details = ({ detail }) => {
  return (
    <section className="w-full rounded-2xl shadow-md border border-pot-grey-4/10 pb-2">
      <header className="flex justify-between items-center border-b px-4 py-4 mb-2">
        
        <div className="flex">
          <DetailIcon />
          <p className="text-pot-dark-blue font-semibold ml-3">Overview</p>
        </div>
        <div className="text-xs text-pot-dark-blue flex items-center">
          {detail.site_urls?.existing.length ? <p className="mr-3">ⓘ</p> : null}
          <div className="max-w-[192px] truncate">
            {detail.site_urls?.existing?.map((url) => (
              <a href={url} target="_blank" rel="noreferrer" className="">
                {url}
              </a>
            ))}
            {detail.site_urls?.existing?.length === 0 &&
              detail.site_urls?.new?.map((url) => (
                <a href={url} target="_blank" rel="noreferrer" className="">
                  {url}
                </a>
              ))}
          </div>
        </div>
      </header>
      <section classname="w-full">
        {/* <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%]  flex-grow-0 flex-shrink-0  font-semibold  ">Name:</p>
          <p className="w-[80%] text-pot-grey-3 flex-grow-0 flex-shrink-0  ">{detail.name?.existing || "-"}</p>
        </div> */}
        {detail.description?.existing && (<div className="w-full  flex text-xs px-5 py-2">
          <p className="w-[12%]  font-semibold flex-grow-0 flex-shrink-0  ">
            Description:
          </p>
          <p className="w-[80%] text-pot-grey-3 flex-grow-0 flex-shrink-0  ">
            {detail.description?.existing || "-"}
          </p>
        </div>)}
        <div className="w-full  flex text-xs px-5 py-2">
          <p className="w-[12%]  font-semibold flex-grow-0 flex-shrink-0  ">
            Ownership:
          </p>
          <p className="w-[80%] text-pot-grey-3 flex-grow-0 flex-shrink-0  ">
            {detail.ownership?.existing || "-"}
          </p>
        </div>
        <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%]  flex-grow-0 flex-shrink-0  font-semibold  ">
            Launch Date:
          </p>
          <p className="w-[80%] text-pot-grey-3 flex-grow-0 flex-shrink-0  ">
            {detail.launch_date?.existing
              ? moment(detail?.launch_date?.existing, "YYYY-MM-DD").format("MM-DD-YYYY")
              : "-"}
          </p>
        </div>
        {detail.is_phased_out && (<div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%]  flex-grow-0 flex-shrink-0  font-semibold  ">
            Closed Date:
          </p>
          <p className="w-[80%] text-pot-grey-3 flex-grow-0 flex-shrink-0  ">
            {detail.closed_date?.existing
              ? moment(detail?.closed_date?.existing, "YYYY-MM-DD").format("MM-DD-YYYY")
              : "-"}
          </p>
        </div>)}
        {/* <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%] font-semibold flex-grow-0 flex-shrink-0  ">
            License Agreement:
          </p>
          <p className="w-[80%] text-pot-grey-3 flex-grow-0 flex-shrink-0 ">
            {detail.licensing_agreement?.existing || "-"}
          </p>
        </div> */}
        {/* <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%] text-pot-grey-3">Launch Date:</p>
          <p className="w-[80%] text-pot-grey-3">
            {moment(detail.launch_date?.existing).format("MMMM YYYY")}
          </p>
        </div> */}
        <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%]  font-semibold flex-grow-0 flex-shrink-0  ">
            Language:
          </p>
          <p className="w-[80%] text-pot-grey-3 flex-grow-0 flex-shrink-0 ">
            {detail.languages?.existing?.length || detail.languages?.new?.length
              ? fullSentance(detail.languages)
              : "-"}
          </p>
        </div>
        <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%] font-semibold flex-grow-0 flex-shrink-0  ">
            Type of Content:
          </p>
          <p className="w-[80%] text-pot-grey-3 flex-grow-0 flex-shrink-0 ">
            {detail.type_of_content?.existing?.length ||
            detail.type_of_content?.new?.length
              ? fullSentance(detail.type_of_content)
              : "-"}
          </p>
        </div>
        <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%]  font-semibold flex-grow-0 flex-shrink-0 ">
            Genre of Content:
          </p>
          <p className="w-[80%] text-pot-grey-3 flex-grow-0 flex-shrink-0 ">
            {detail.genres?.existing?.length || detail.genres?.new?.length
              ? fullSentance(detail.genres)
              : "-"}
          </p>
        </div>
        <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%] font-semibold  flex-grow-0 flex-shrink-0 ">
            Content Details:
          </p>
          <p className="w-[80%] text-pot-grey-3 flex-grow-0 flex-shrink-0 ">
            {detail.content_details?.existing || "-"}
          </p>
        </div>
        {/* <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%] text-pot-grey-3">Original Content:</p>
          <p className="w-[80%] text-pot-grey-3">
            {JSON.stringify(detail.original_content?.existing || "-")}
          </p>
        </div> */}
        <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%] font-semibold  flex-grow-0 flex-shrink-0  ">
            Target Viewer:
          </p>
          <p className="w-[80%] text-pot-grey-3 flex-grow-0 flex-shrink-0 ">
            {/* {detail.target_market?.existing || "-"} */}
            {detail.target_market?.existing?.length ||
            detail.target_market?.new?.length
              ? fullSentance(detail.target_market)
              : "-"}
          </p>
        </div>
        <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%] font-semibold flex-grow-0 flex-shrink-0  ">
            Business Model:
          </p>
          <p className="w-[80%] text-pot-grey-3 flex-grow-0 flex-shrink-0 ">
            {detail.business_model?.existing?.length ||
            detail.business_model?.new?.length
              ? fullSentance(detail.business_model)
              : "-"}
          </p>
        </div>
        <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%] font-semibold flex-grow-0 flex-shrink-0  ">
            No of Streams:
          </p>
          <p className="w-[80%] whitespace-pre-wrap text-pot-grey-3 flex-grow-0 flex-shrink-0 ">
            {detail.no_of_streams?.existing || "-"}
          </p>
        </div>
        <div className="w-full flex text-xs px-5 py-2">
          <p className="w-[12%] font-semibold flex-grow-0 flex-shrink-0  ">
            Size of Library:
          </p>
          <p className=" w-[80%] whitespace-pre-wrap text-pot-grey-3 flex-grow-0 flex-shrink-0 ">
            {detail.size_of_library?.existing || "-"}
          </p>
        </div>
      </section>
    </section>
  );
};

export default Details;
