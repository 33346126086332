import React from "react";

const Lock = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M11.0833 6.41675H2.91667C2.27233 6.41675 1.75 6.93908 1.75 7.58341V11.6667C1.75 12.3111 2.27233 12.8334 2.91667 12.8334H11.0833C11.7277 12.8334 12.25 12.3111 12.25 11.6667V7.58341C12.25 6.93908 11.7277 6.41675 11.0833 6.41675Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.08337 6.41675V4.08341C4.08337 3.30987 4.39066 2.568 4.93765 2.02102C5.48463 1.47404 6.22649 1.16675 7.00004 1.16675C7.77359 1.16675 8.51545 1.47404 9.06244 2.02102C9.60942 2.568 9.91671 3.30987 9.91671 4.08341V6.41675"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Lock;
