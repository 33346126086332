import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="mb-5">
        <p className="font-semibold mb-2">
          Return, Refund, and Cancellation Policies
        </p>
        <p className="text-pot-text-grey">
          Parks Associates does not provide returns, refunds, or cancellation of
          orders for its research or event products once they have been
          delivered to the buyer’s reading room. If you are unsatisfied with any
          of the content delivered, please contact Parks Associates at
          972-490-1113 or <span className="text-pot-blue">sales@parksassociates.com</span> to discuss.
        </p>
      </div>
      <div className="mb-5">
        <p className="font-semibold mb-2">Deliverables and Delivery Policy</p>
        <p className="text-pot-text-grey">
          Upon confirmation of your purchase, an electronic copy of the
          purchased content, typically a PDF or PowerPoint file, will appear in
          your online Reading Room. You can view and download this file at your
          discretion. Past Webcasts include a PDF of the presentation with a
          link to the archived audio and login instructions.
        </p>
      </div>
      <div>
        <p className="font-semibold mb-2">Parks Associates' Privacy Policy</p>
        <p className="text-pot-text-grey">
          Our Privacy Policy is designed to assist you in understanding how we
          collect and use the personal information you provide to us and to
          assist you in making informed decisions when using our site and our
          products and services.
        </p>
      </div>
      <div className="mt-5">
        <p className="font-semibold">What Information Do We Collect?</p>
        <p className="text-pot-text-grey">
          When you visit our Web site you may provide us with two types of
          information: personal information you knowingly choose to disclose
          that is collected on an individual basis and Web site use information
          collected on an aggregate basis as you and others browse our Web site.
        </p>
        <p className="font-semibold py-4">
          1. Personal Information You Choose to Provide
        </p>
        <p className="pb-4">
          <span className="text-black">
            <em>Registration Information.</em>
          </span>
          <br />
          You will provide us information about yourself, your firm or company,
          and your practices when you register for certain services, download
          Parks Associates latest white papers, or register for email
          newsletters and alerts.
        </p>
        <p className="pb-4">
          <span className="text-black">
            <em>Email Information.</em>
          </span>
          <br />
          If you choose to correspond with us through email and check off the
          box affirming interest in receiving email, we may retain the content
          of your email messages together with your email address and our
          responses. This practice follows GDPR laws.
        </p>
        <p className="font-semibold py-4">2. Web Site Use Information</p>
        <p className="pb-4">
          <span className="text-black">
            <em>How Do We Use the Information That You Provide to Us?</em>
          </span>
          <br />
          Broadly speaking, we use personal information for purposes of
          administering and expanding our business activities, providing
          customer service and making available other products and services to
          our customers and prospective customers. Occasionally, we may also use
          the information we collect to notify you about important changes to
          our Web site, new services and special offers we think you will find
          valuable. You may notify us at any time if you do not wish to receive
          these offers by emailing us at{" "}
          <span className="text-pot-blue">info@parksassociates.com</span>
        </p>
        <p className="pb-4">
          <span className="text-black">
            <em>Sharing Information with Third Parties</em>
          </span>
          <br />
          We may enter into alliances, partnerships or other business
          arrangements with third parties who may be given access to personal
          information including your name, address, telephone number and email
          for the purpose of providing you information regarding products and
          services that we think will be of interest to you. In connection with
          alliances, partnerships or arrangements, we may also provide certain
          information to third parties if we have determined that the
          information will be used in a responsible manner by a responsible
          third party. We also use third parties to facilitate our business,
          including, but not limited to, sending email and processing credit
          card payments. In connection with these offerings and business
          operations, our partners and other third parties may have access to
          your personal information for use in connection with business
          activities. As we develop our business, we may buy or sell assets or
          business offerings. Customer, email, and visitor information is
          generally one of the transferred business assets in these types of
          transactions. We may also transfer such information in the course of
          corporate divestitures, mergers, or any dissolution.
        </p>
        <p className="pb-4">
          <span className="text-black">
            <em>Notice of New Services and Changes</em>
          </span>
          <br />
          Occasionally, we may also use the information we collect to notify you
          about important changes to our Web site, new services and special
          offers we think you will find valuable. As our customer, you will be
          given the opportunity to notify us of your desire not to receive these
          offers by sending us an email request at <span className="text-pot-blue">info@parksassociates.com.</span>
        </p>
        <p className="pb-4">
          <span className="text-black">
            <em>Request to Delete Your Contact Details</em>
          </span>
          <br />
          Parks Associates will delete any contact details within our database
          by request. As our customer, you can notify us of your desire to have
          data deleted by emailing us at <span className="text-pot-blue">info@parksassociates.com</span> or calling our
          headquarters at +1-972-490-1113.
        </p>
      </div>
      <div className="mt-5">
        <p className="font-semibold pb-5">
          How Do We Protect Your Information?
        </p>
        <p className="pb-4">
          <span className="text-black">
            <em>How Do We Secure Information Transmissions?</em>
          </span>
          <br />
          Email is not recognized as a secure medium of communication. For this
          reason, we request that you do not send private information to us by
          email. Some of the information you may enter on our Web site may be
          transmitted securely via Secure Sockets Layer SSL, 128 bit encryption
          services. Pages utilizing this technology will have URLs that start
          with HTTPS instead of HTTP. Please contact <span className="text-pot-blue">info@parksassociates.com</span> if
          you have any questions or concerns.
        </p>
        <p className="pb-4">
          <span className="text-black">
            <em>How Can You Access and Correct Your Information?</em>
          </span>
          <br />
          You may request access to all your personally identifiable information
          that we collect online and maintain in our database by emailing
          <span className="text-pot-blue">info@parksassociates.com</span>
        </p>
        <p className="pb-4">
          <span className="text-black">
            <em>Certain Disclosures</em>
          </span>
          <br />
          We may disclose your personal information if required to do so by law
          or subpoena or if we believe that such action is necessary to (a)
          conform to the law or comply with legal process served on us or
          affiliated parties; (b) protect and defend our rights and property,
          our site, the users of our site, and/or our affiliated parties; (c)
          act under circumstances to protect the safety of users of our site,
          us, or third parties.
        </p>
        <p className="pb-4">
          <span className="text-black">
            <em>What About Other Web Sites Linked to Our Web Site?</em>
          </span>
          <br />
          We are not responsible for the practices employed by Web sites linked
          to or from our Web site nor the information or content contained
          therein. Often links to other Web sites are provided solely as
          pointers to information on topics that may be useful to the users of
          our Web site.
          <br />
          Please remember that when you use a link to go from our Web site to
          another Web site, our Privacy Policy is no longer in effect. Your
          browsing and interaction on any other Web site, including Web sites
          which have a link on our Web site, is subject to that Web site's own
          rules and policies. Please read over those rules and policies before
          proceeding.
        </p>
        <p className="pb-4">
          <span className="text-black">
            <em>Your Consent</em>
          </span>
          <br />
          By using our Web site you consent to our collection and use of your
          personal information as described in this Privacy Policy. If we change
          our privacy policies and procedures, we will post those changes on our
          Web site to keep you aware of what information we collect, how we use
          it and under what circumstances we may disclose it.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
