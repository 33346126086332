import React from "react";
import { toNormalText } from "../../utils/helperFunc";
import { validatePhone } from "../../utils/validations";

const PhoneField = ({
  placeholder,
  formData,
  setFormData,
  value,
  className,
  heading,
  error,
  setError,
  errVal,
  country_codes = ["+91"],
  fieldWithCode = false,
}) => {
  return (
    <div className={className}>
      <p className="font-semibold">{heading}</p>
      <div className="flex gap-6 mb-2">
        {!fieldWithCode && (
          <select
            value={formData[value]?.code}
            onChange={(e) => {
              formData[value].code = e.target.value;
              setFormData({ ...formData });
            }}
            className="border outline-none rounded px-3 py-2 mt-3 border-pot-blue-2/20 shadow shadow-pot-blue-2/10 focus:shadow-lg focus:shadow-pot-blue-2/10"
          >
            {country_codes?.map((each) => (
              <option>{each}</option>
            ))}
          </select>
        )}

        <input
          value={
            fieldWithCode
              ? formData?.[value] || ""
              : formData?.[value]?.number || ""
          }
          onFocus={() => {
            if (error[value]) {
              delete error[value];
              setError({ ...error });
            }
          }}
          onChange={(e) => {
            const val = validatePhone(e.target.value, fieldWithCode);
            if (!val && e.target.value) return;
            if (fieldWithCode) {
              formData[value] = val || "";
            } else {
              formData[value].number = val || "";
            }
            setFormData({ ...formData });
          }}
          placeholder={placeholder || toNormalText(value)}
          className="w-full border outline-none rounded px-3 py-2 mt-3 border-pot-blue-2/20 shadow shadow-pot-blue-2/10 focus:shadow-lg focus:shadow-pot-blue-2/10"
        />
      </div>
      <p className="text-red-600 text-xs">{error[value]}</p>
    </div>
  );
};

export default PhoneField;
