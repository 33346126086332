import React from "react";

const Prices = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9642 5.96083L12.765 0.803328C12.1225 0.193328 11.25 -0.0875049 10.3725 0.0249951L4.85083 0.754162C4.16666 0.844995 3.685 1.4725 3.775 2.1575C3.865 2.8425 4.4975 3.32583 5.17833 3.23333L10.7 2.50416C10.825 2.4875 10.9508 2.5275 11.0233 2.5975L16.1908 7.72416C16.8217 8.36833 16.8208 9.41583 16.1967 10.0517L15.77 10.4783C15.6758 10.3575 15.5733 10.2408 15.4642 10.1292L10.2658 4.97166C9.62333 4.36166 8.75 4.08 7.87333 4.1925L2.35166 4.92166C1.7675 4.99916 1.31666 5.47416 1.26916 6.06166L0.84333 11.3917C0.771663 12.2742 1.09916 13.13 1.72166 13.7208L6.79333 18.75C7.5825 19.5558 8.63833 20 9.76583 20.0008H9.77C10.8967 20.0008 11.9508 19.5592 12.74 18.7558L15.4717 15.9783C16.2442 15.1917 16.6392 14.1633 16.6558 13.1275L17.9717 11.8117C19.5517 10.2042 19.5533 7.585 17.9633 5.96166L17.9642 5.96083ZM13.6892 14.225L10.9583 17.0025C10.6425 17.3242 10.2192 17.5008 9.76833 17.5008C9.31666 17.5008 8.89416 17.3225 8.56666 16.9875L3.46333 11.9275C3.3725 11.8408 3.325 11.7192 3.33583 11.5925L3.68083 7.26833L8.20083 6.67166C8.32416 6.65166 8.45166 6.695 8.52416 6.765L13.6917 11.8917C14.3225 12.5358 14.3217 13.5833 13.69 14.2267L13.6892 14.225ZM7.5 9.58333C7.47166 11.2267 5.0275 11.2258 5 9.58333C5.02833 7.94 7.4725 7.94083 7.5 9.58333Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default Prices;
