import React from 'react'
import Checkbox from '../../components/Checkbox'
import { names } from '../../utils/chartsData'
import { strokes } from '../../utils/chartsData'

const Legends = ({ selectedOtts, setSelectedOtts }) => {
    const getColor = (index) => (`accent-[${strokes[index]}]`)

  const onCheck = (id) => {
    const filteredOtts = selectedOtts.filter(each => each.id !== id)
    setSelectedOtts(filteredOtts)
  }

  return (
    <div className="w-full grid grid-cols-5 gap-x-4 mt-3 mr-10 ml-10">
        {selectedOtts?.map((each, index) => {
        const accent = getColor(index)
        return(
            <div className={`w-fit flex gap-2 py-3 justify-center ${accent}`}>
                <Checkbox onCheck={() => onCheck(each.id)} color={each.color} checked={true} />
                {each.name}
            </div>
        )})}
    </div>
  )
}

export default Legends