import React, { memo } from "react";
import Checkbox from "@atlaskit/checkbox";
import { toNormalText } from "../../../utils/helperFunc";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
// import Modal from "../../../components/Modal";
import Modal from "@atlaskit/modal-dialog";
import EditFilledIcon from "@atlaskit/icon/glyph/edit-filled";
import Button from "../../../form/components/Button";
import Lock from "../../../assets/Lock";
// import { initialVisibilityObject } from "../../../utils/visibility";
import DragHandle from "../../../assets/DragHandle";
import RoundedClose from "../../../assets/RoundedClose";
import { useRecoilValue } from "recoil";
import { ColumnKeyValueAtom, InitialColumnVisibilityAtom } from "../../../atom";
import { MacScrollbar } from "mac-scrollbar";

const ColumnVisibilityModal = ({
  closeModal = () => {},
  setAllColumnsVisibility,
  setAllColumnOrder,
  allColumnOrder,
  allColumnsVisibility,
  columnsVisibleTemp,
  setColumnsVisibleTemp,
  columnsOrderTemp,
  setColumnsOrderTemp,
}) => {
  const initialVisibilityObject = useRecoilValue(InitialColumnVisibilityAtom);
  const columnKeyValue = useRecoilValue(ColumnKeyValueAtom);
  const allColumns = Object.keys(initialVisibilityObject);

  const handleVisibility = (column) => {
    columnsVisibleTemp[column] = !columnsVisibleTemp[column];
    setColumnsVisibleTemp({ ...columnsVisibleTemp });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    const order = reorder(
      columnsOrderTemp,
      result.source.index,
      result.destination.index
    );

    setColumnsOrderTemp(order);
  };

  return (
    <Modal onClose={closeModal} width="x-large">
      <div className="w-full h-[85vh] bg-white flex divide-x">
        <div className="w-full flex flex-col">
          <div className="font-medium h-fit p-4 text-pot-dark-blue flex items-center">
            <div className="mt-1 mr-2">
              <EditFilledIcon size="small" primaryColor="#00838F" />
            </div>
            <p>Modify fields</p>
          </div>
          <div className="h-fit">
            <MacScrollbar>
              <div className="w-full grid grid-cols-3 gap-y-2 max-h-96 overflow-y-auto p-4">
                {/* <div className="w-full "> */}
                {allColumns.map((column) => (
                  <div className="flex items-center">
                    <Checkbox
                      size="medium"
                      isChecked={columnsVisibleTemp[column] ? true : false}
                      onChange={() => handleVisibility(column)}
                      className="checked:bg-pot-blue-2"
                    />
                    <p className="text-sm">{columnKeyValue[column]}</p>
                  </div>
                ))}
                {/* </div> */}
              </div>
            </MacScrollbar>
          </div>
          <div className="h-full"></div>
          <div className="p-4 h-fit border-t flex items-center mt-4">
            <Button
              text="Apply"
              onClick={() => {
                setAllColumnOrder([...columnsOrderTemp]);
                setAllColumnsVisibility({ ...columnsVisibleTemp });
                closeModal();
              }}
              primary
            />
            <Button
              text="Cancel"
              onClick={() => {
                setColumnsOrderTemp([...allColumnOrder]);
                setColumnsVisibleTemp({ ...allColumnsVisibility });
                closeModal();
              }}
            />
          </div>
        </div>
        <div className="w-[50%] bg-white">
          <div className="p-4 border-b">
            <p className="text-pot-blue font-semibold">Your Columns</p>
            <p className="text-sm text-pot-grey-4">Drag and drop to reorder</p>
          </div>
          <div className="w-full p-4 flex justify-start items-center border-b stroke-pot-grey-3">
            <Lock />
            <p className="ml-2 cursor-default">Name</p>
          </div>
          <MacScrollbar>
            <div className="h-[60vh] overflow-y-auto">
              <DragDropContext
                className="w-[90%] z-[91] "
                onDragEnd={onDragEnd}
              >
                <Droppable droppableId="droppable" direction="vertical">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="w-full mr-4 my-2"
                      {...provided.droppableProps}
                    >
                      {columnsOrderTemp.map((each, index) => (
                        <Draggable
                          className="top-auto left-auto"
                          key={each}
                          draggableId={each}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className="w-full flex justify-between items-center"
                            >
                              <div
                                {...provided.dragHandleProps}
                                className={`top-auto left-auto p-1 my-1 flex items-center ${
                                  snapshot.isDragging
                                    ? "bg-slate-200"
                                    : "bg-white"
                                }`}
                              >
                                <DragHandle />
                                <p className="truncate ml-2 text-sm">
                                  {columnKeyValue[each]}
                                </p>
                              </div>
                              <div
                                onClick={() => {
                                  setColumnsVisibleTemp({
                                    ...columnsVisibleTemp,
                                    [each]: false,
                                  });
                                }}
                                className="mr-4 cursor-pointer"
                              >
                                <RoundedClose />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </MacScrollbar>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ColumnVisibilityModal);
