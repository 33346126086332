import React, { useRef, useState } from "react";
import {
  Bar,
  BarChart,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useTopActiveUsersChart from "../../hooks/useTopActiveUsersChart";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import useOnClickOutside from "../../hooks/useClickOutside";
import { DotPulse } from "@uiball/loaders";
import moment from "moment";
import { useExportChart } from "../../hooks/useExportChart";

const ActiveUsersChart = ({ fetch, setters = [] }) => {
  const [day, setDay] = useState(true);
  const [openDayDropdown, setOpenDayDropdown] = useState(false);
  const dayRef = useRef();
  const { data, loading, setLoading } = useTopActiveUsersChart({
    fetch,
    day,
    setters,
  });
  useOnClickOutside(dayRef, () => setOpenDayDropdown(false));
  const { ExportButton, headerRef, titleStyles } = useExportChart();

  return (
    <div className="relative">
      <div
        ref={headerRef}
        className={`${titleStyles} flex h-[13%] items-center justify-between px-5`}
      >
        <p className="text-sm text-pot-dark-blue font-semibold">
          No. Of Active Users
        </p>
        <div className="flex items-center gap-x-2">
          <ExportButton
            id="active-users"
            title="No. Of Active Users"
            className="h-8"
          />
          <div ref={dayRef} className="relative text-xs">
            <button
              onClick={() => setOpenDayDropdown(true)}
              className="flex items-center rounded-md gap-x-1 text-pot-blue bg-pot-bg-blue pl-3 pr-1 py-1"
            >
              {day ? "Day" : "Week"}
              <ChevronDownIcon primaryColor="#1767A5" />
            </button>
            {openDayDropdown && (
              <div className="bg-white absolute right-0 z-50 w-28 rounded-md border shadow-md">
                <button
                  onClick={() => {
                    setDay(true);
                    setLoading(true);
                    setOpenDayDropdown(false);
                  }}
                  className="w-full text-left px-3 py-2 mt-2 hover:bg-pot-bg-blue mb-1"
                >
                  Day
                </button>
                <button
                  onClick={() => {
                    setDay(false);
                    setLoading(true);
                    setOpenDayDropdown(false);
                  }}
                  className="w-full text-left px-3 py-2 hover:bg-pot-bg-blue mb-2"
                >
                  Week
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        id="chart-active-users"
        className="w-full h-[40vh] border shadow-md rounded-md text-xs"
      >
        <div
          style={{ height: headerRef?.current?.clientHeight }}
          className="flex h-[13%] items-center justify-between px-5"
        >
          <p className="text-sm text-pot-dark-blue font-semibold">
            No. Of Active Users
          </p>
        </div>
        {loading ? (
          <div className="w- h-60 flex justify-center items-center">
            <DotPulse color="#1767A5" size={28} speed={0.8} />
          </div>
        ) : (
          <ResponsiveContainer width="97%" height="87%">
            <BarChart
              margin={{ bottom: 50, left: 30, top: 10, right: 20 }}
              barGap={5}
              barCategoryGap={20}
              stackOffset="sign"
              data={data}
            >
              <XAxis
                // label={{
                //   value: "Day",
                //   position: "insideBottom",
                //   offset: 10,
                // }}
                name="date"
                axisLine={false}
                tickLine={false}
                tickMargin={30}
                dataKey="x_axis"
                interval={0}
                tick={day ? <CustomizedTick /> : <CustomizedRangeTick />}
                // textAnchor="center"
                // tick={false}
                // padding={{ bottom: 10 }}
                angle={day ? 90 : 0}
                tickFormatter={(value) =>
                  moment(value, "YYYY-MM-DD").format("MM-DD-YYYY")
                }
              />
              {/* <Legend
              verticalAlign="top"
              height={60}
              align={"right"}
              formatter={(value) => (
                <span className="text-pot-text-grey">
                  {value.slice(0, 1).toUpperCase() + value.slice(1)}
                </span>
              )}
              iconType="circle"
              iconSize={10}
            /> */}
              <Tooltip
                cursor={{ fill: "#fff" }}
                formatter={(value, name) => [value, "No of Active Users"]}
                labelFormatter={(value) =>
                  moment(value, "YYYY-MM-DD").format("MM-DD-YYYY")
                }
              />
              <YAxis
                label={{
                  value: "No. of Active Users",
                  angle: -90,
                  position: "insideBottomLeft",
                  offset: 20,
                }}
                tickCount={7}
              />
              <ReferenceLine y={0} stroke="#000000" />
              <ReferenceLine x={0} stroke="#000000" />
              <Bar
                maxBarSize={20}
                dataKey="value"
                stackId="a"
                fill="#1B75BB"
                // shape={<CustomizedBar />}
              >
                {/* <LabelList dataKey="x_axis" position="top" /> */}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

const CustomizedTick = (props) => {
  return (
    <g transform={`translate(${props.x},${props.y - 20})`}>
      <text
        transform="rotate(90)"
        x={-10}
        y={5}
        style={{
          fontSize: "12px",
          fill: "#666666",
          textAlign: "left",
        }}
      >
        {moment(props.payload.value, "YYYY-MM-DD").format("MM-DD-YYYY")}
      </text>
    </g>
  );
};

const CustomizedRangeTick = (props) => {
  const [from, to] = props.payload.value.split(" to ");
  return (
    <g transform={`translate(${props.x},${props.y - 18})`}>
      <text
        textAnchor="middle"
        y={0}
        style={{
          fontSize: "12px",
          fill: "#666666",
        }}
      >
        {moment(from, "YYYY-MM-DD").format("MM-DD-YYYY")}
      </text>
      <text
        textAnchor="middle"
        y={15}
        style={{
          fontSize: "12px",
          fill: "#666666",
        }}
      >
        to
      </text>
      <text
        textAnchor="middle"
        y={30}
        style={{
          fontSize: "12px",
          fill: "#666666",
        }}
      >
        {moment(to, "YYYY-MM-DD").format("MM-DD-YYYY")}
      </text>
    </g>
  );
};

// const CustomizedBar = (props) => {
//   const color = `bg-[${props.fill}]`;
//   console.log(Math.abs(props.width) > 10,  props)

//   return (
//     <g>
//       <foreignObject
//         height={props.height}
//         width={Math.abs(props.width)}
//         x={props.x}
//         y={props.y}
//       >
//         <div className={`${color} z-50 w-full h-full `}>
//           { Math.abs(props.width) > 11 && props.height > 50 && (
//             <div style={{ writingMode: "vertical-lr"}} className="h-full w-full flex items-center justify-center text-white text-[9px]">
//               {/* <p className="text-[10px] bg-red-300 rotate-90"> */}
//                 {moment(props.payload.x_axis, "YYYY-MM-DD").format("MM-DD-YYYY")}
//                 {/* Hello how do you do */}
//                 {/* </p> */}
//             </div>
//           )}
//         </div>
//       </foreignObject>
//     </g>
//   );
// };

export default ActiveUsersChart;
