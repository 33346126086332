import React from "react";
import { useRecoilValue } from "recoil";
import Danger from "../../assets/Danger";
import { QuarterRangeAtom } from "../../atom";
import DatePicker from "../../components/DatePicker";
import QuarterDropdown from "../../components/QuaterDropdown";
import { getAllQuarterBetween } from "../../utils/helperFunc";
import { motion } from "framer-motion";

const UploadControls = ({ control = {}, valueHandles = {}, showToolTip=false }) => {
  const quarterRange = useRecoilValue(QuarterRangeAtom);
  const endQuarter = 3;
  const startQuarter = 0;
  const endYear = new Date().getFullYear() + 5;
  const startYear = 2000;
  const range = getAllQuarterBetween(
    [startQuarter, startYear],
    [endQuarter, endYear]
  );

  console.log(endQuarter, endYear, "yrr")

  console.log(range, [startQuarter, startYear], [endQuarter, endYear]);
  console.log(showToolTip, "ooo oooo")

  return (
    <div className="absolute top-4 left-4 mx-10">
      <div className="flex gap-x-3">
        <QuarterDropdown
          open={control.quarterOpen}
          setOpen={control.setQuarterOpen}
          quarterList={range.reverse()}
          value={valueHandles.quarter}
          setValue={valueHandles.setQuarter}
        />
        <DatePicker
          open={control.pickerOpen}
          setOpen={control.setPickerOpen}
          value={valueHandles.date}
          setValue={valueHandles.setDate}
        />
      </div>
      {showToolTip && (<motion.div initial={{ opacity: 0}} animate={{ opacity: 1}} className="border rounded-md px-2 text-xs flex gap-x-2 items-center mt-3">
        <Danger />
        {!valueHandles.quarter && "Quarter "}
        {!valueHandles.quarter && !valueHandles.date && "and "}
        {!valueHandles.date && "Data Updated On "}
        are Required
      </motion.div>)}
    </div>
  );
};

export default UploadControls;
