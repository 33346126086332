import React from "react";

const Walkthrough = () => {
  return (
    <svg
      version="1.0"
      width="14"
      height="14"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="rgb(23 103 165)"
        stroke="none"
      >
        <path
          d="M1010 5113 c-212 -21 -279 -43 -358 -117 -63 -59 -102 -136 -115
-231 l-11 -75 -106 0 c-66 0 -126 -6 -157 -15 -112 -33 -208 -130 -243 -245
-20 -64 -20 -90 -18 -1360 l3 -1295 33 -67 c53 -109 153 -186 271 -208 46 -9
1611 -16 1611 -7 0 22 24 191 36 252 17 86 77 282 101 328 l14 28 -123 58
c-173 83 -318 129 -523 167 -67 13 -149 18 -318 18 -214 1 -229 2 -243 20 -12
17 -14 196 -14 1197 0 1106 1 1178 18 1197 14 17 36 23 114 32 410 44 786 -64
1130 -323 l78 -59 2 -1029 3 -1030 65 92 c35 50 106 135 157 189 l93 98 1 843
0 844 27 21 c75 59 233 160 320 203 189 95 389 147 605 157 150 8 333 -9 359
-33 17 -15 18 -53 18 -789 l0 -773 33 -5 c118 -16 245 -37 265 -42 l22 -6 0
611 0 611 93 0 c59 0 97 -4 105 -12 9 -9 12 -166 12 -643 l0 -631 58 -23 c31
-13 103 -48 160 -78 l102 -54 -2 743 -3 743 -32 66 c-39 79 -102 140 -181 177
-51 24 -71 27 -183 30 l-126 4 -5 63 c-7 82 -46 167 -106 228 -91 93 -184 121
-442 133 -396 18 -779 -91 -1114 -318 -59 -40 -118 -79 -130 -87 -21 -13 -29
-9 -118 54 -274 195 -577 312 -897 344 -97 10 -262 12 -341 4z m-480 -1743 c0
-701 3 -1015 11 -1052 25 -117 99 -212 207 -265 57 -28 57 -28 242 -25 360 5
592 -42 835 -167 l90 -46 -774 -3 c-681 -2 -777 0 -798 13 l-23 15 0 1250 c0
1122 2 1250 16 1264 12 12 37 16 105 16 l89 0 0 -1000z"
        />
        <path
          d="M3510 2756 c-607 -108 -1065 -580 -1151 -1185 -15 -106 -7 -361 15
-461 58 -265 179 -492 367 -685 201 -207 431 -334 717 -397 145 -32 407 -32
551 0 285 64 502 182 702 381 199 200 317 417 381 702 32 144 32 406 0 551
-43 198 -124 381 -239 539 -71 99 -244 268 -341 332 -148 99 -321 173 -497
212 -97 22 -405 29 -505 11z m278 -418 c66 -16 135 -87 151 -154 24 -98 -20
-193 -110 -238 -88 -43 -176 -30 -244 39 -69 68 -82 156 -39 244 28 56 79 98
136 111 47 11 54 11 106 -2z m55 -771 c30 -18 56 -44 74 -75 l28 -47 0 -430 0
-430 -25 -45 c-38 -67 -96 -103 -174 -108 -71 -5 -120 14 -168 65 -57 60 -58
66 -58 510 0 259 4 422 11 447 13 47 85 121 131 135 55 17 130 8 181 -22z"
        />
      </g>
    </svg>
  );
};

export default Walkthrough;
