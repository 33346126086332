import MediaServicesGridIcon from "@atlaskit/icon/glyph/media-services/grid";
import TableIcon from "@atlaskit/icon/glyph/table";
import BitbucketCompareIcon from "@atlaskit/icon/glyph/bitbucket/compare";
import SearchIcon from "@atlaskit/icon/glyph/search";
import DetailViewIcon from "@atlaskit/icon/glyph/detail-view";
import RoadmapIcon from "@atlaskit/icon/glyph/roadmap";
import OpenBook from "../assets/OpenBook";
import FaqIcon from "../assets/FaqIcon";
import ContactIcon from "../assets/ContactIcon";
import ChartIcon from "../assets/ChartIcon";
import FavouriteChartIcon from "../assets/FavouriteChartIcon";
import NPSChurnIcon from "../assets/NPSChurnIcon";

export const navItems = [
  {
    icon: <MediaServicesGridIcon primaryColor="white" />,
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    icon: <SearchIcon primaryColor="white" />,
    title: "Search",
    to: "/search",
  },
  {
    icon: <TableIcon primaryColor="white" />,
    title: "Search Results",
    to: "/search/results",
    cc: "/search/viewers-trend",
  },
  {
    icon: <BitbucketCompareIcon primaryColor="white" />,
    title: "Compare",
    to: "/service-comparison",
    cc: "/platform-comparison",
    subItems: [
      {
        title: "Service Comparisons",
        to: "/service-comparison",
      },
      {
        title: "Platform Comparisons",
        to: "/platform-comparison",
      },
    ],
  },
  {
    icon: <DetailViewIcon primaryColor="white" />,
    title: "Profiles",
    to: "/profile",
  },
  {
    icon: <RoadmapIcon primaryColor="white" secondaryColor="#1767A5" />,
    title: "Viewers Trend",
    to: "/viewers-trend",
  },
  {
    icon: <NPSChurnIcon />,
    title: "NPS & Churn",
    to: "/nps",
    cc: "/churn",
    subItems: [
      {
        icon: <ChartIcon />,
        title: "NPS",
        to: "/nps",
      },
      {
        icon: <FavouriteChartIcon />,
        title: "Churn",
        to: "/churn",
      },
    ],
  },

  {
    icon: <OpenBook />,
    title: "Library",
    to: "/library",
  },
  {
    icon: <FaqIcon />,
    title: "FAQ",
    to: "/faq",
  },
  {
    icon: <ContactIcon />,
    title: "Contact & Support",
    to: "/contact",
  },
];


