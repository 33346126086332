import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Phone from "../../assets/Phone";
import tv from "../../assets/TV.svg";
import web from "../../assets/web.svg"
import pc from "../../assets/pc.svg"
import mobile from "../../assets/mobile.svg"
import media_player from "../../assets/media_player.svg"
import gaming from "../../assets/gaming.svg"
import others from "../../assets/pdf_png/others.png"
import { DeviceSupportAtom } from "../../atom";
import { formatToAllValObj, formatToViewable } from "../../utils/formatConfig";

const Devices = ({ detail, setPdfShowableDevices }) => {
  const deviceOrder = [
    "TV",
    "PC",
    "Media Players",
    "Web",
    "Mobile",
    "Gaming Devices",
    "undefined"
  ]
  const devicesObject = useRecoilValue(DeviceSupportAtom);
  const [showableDevices, setShowableDevices] = useState({});
  const [sortedDevices, setSortedDevices] = useState([])

  const getDeviceImage = (deviceType) => {
    if(deviceType.toLowerCase() === "tv") return tv
    else if(deviceType.toLowerCase() === "web") return web
    else if(deviceType.toLowerCase() === "mobile") return mobile
    else if(deviceType.toLowerCase() === "media players") return media_player
    else if(deviceType.toLowerCase().includes("gaming")) return gaming
    else if(deviceType.toLowerCase() === "pc") return pc
    else return others
  }

  useEffect(() => {
    if (detail.device_support) {
      const arr = detail.device_support.existing.concat(
        detail.device_support.new || []
      );
      arr.sort();
      const settable = formatToViewable(devicesObject, arr);
      if(settable.undefined) {
        const a = [...settable.undefined]
        delete settable.undefined
        settable.undefined = a
      }
      const devList = deviceOrder.filter((each) => Object.keys(settable).includes(each))
      setSortedDevices(devList)
      setShowableDevices(settable);
      setPdfShowableDevices(settable);
    }
  }, [detail]);

  return (
    <div className="w-full rounded-2xl shadow-lg border border-pot-grey-4/10 mb-10">
      <header className="border-b flex px-4 py-2 ">
        <Phone />
        <p className="text-pot-dark-blue font-semibold ml-3">Device Support</p>
      </header>
      <section className="flex flex-wrap gap-20 mx-4 my-2 text-xs py-3">
        {sortedDevices?.map((deviceType) => (
          <div className="flex flex-col">
            <div className="flex items-center">
              {deviceType !== "undefined" && <img src={getDeviceImage(deviceType)} className="w-5 h-5 object-contain" />}
              <p className="font-semibold ml-2">{deviceType === "undefined" ? "Others" : deviceType}</p>
            </div>
            <div className="flex gap-20 mt-3">
              {Array.from({
                length: Math.ceil(showableDevices[deviceType].length / 7),
              }).map((_, id) => (
                <ul key={id} className="px-4">
                  {Array.from({
                    length:
                      showableDevices[deviceType].length - id * 7 < 7
                        ? showableDevices[deviceType].length - id * 7
                        : 7,
                  }).map((_, idx) => (
                    <li key={idx} className="mb-1 list-disc text-pot-grey-3">
                      {showableDevices[deviceType][idx + (id * 7)]}
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Devices;
