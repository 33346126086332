import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import Header from "../../components/Pdf/Header";
import List from "../../components/Pdf/List";
import phone from "../../assets/pdf_png/phone.png";

const DistributionPlatform = ({ detail }) => {
  const plat = detail.distribution_platforms.existing.concat(detail.distribution_platforms.new || [])

  const styles = StyleSheet.create({
    container: {
      marginTop: "10px",
      border: 1,
      borderRadius: "8px",
      borderColor: "#F0F1F8",
      width: "100%",
    },
    contents_container: {
      paddingVertical: "5px",
      paddingHorizontal: "8px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    ul: {
      paddingRight: "12px",
      paddingBottom: "12px",
    },
  });

  return (
    <View wrap={false} style={styles.container}>
      <Header image={phone} title="Distribution Platform" />
      <View style={styles.contents_container}>
        {Array.from({
          length: Math.ceil(plat.length / 7),
        }).map((_, id) => (
          <View style={styles.ul}>
            {Array.from({
              length: plat.length - id * 7 < 7 ? plat.length - id * 7 : 7,
            }).map((_, idx) => (
              <List text={plat[idx + id * 7]} mb />
            ))}
          </View>
        ))}
      </View>
    </View>
  );
};

export default DistributionPlatform;
