import React, { useState } from "react";
import PDFGenerator from "../PDFGenerator.js";
import { motion } from "framer-motion";
import FilledRoundedWhiteClose from "../../assets/FilledRoundedWhiteClose.js";
import { AccountUserAtom, AppInfoAtom, ProfileCountryAtom, SelectedProfileCountryAtom } from "../../atom.js";
import { useRecoilValue } from "recoil";
import { getFromLocalStorage } from "../../utils/localStorage.js";

const Header = ({
  hasDelayed,
  detail,
  pdfShowableDevices = false,
  pdfShowableCountries = false,
}) => {
  const account = useRecoilValue(AccountUserAtom);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const profileCountries = useRecoilValue(ProfileCountryAtom)
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom)
  const userType = getFromLocalStorage("user_type")


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`w-full h-full mt-10 mb-5 py-5 px-10 flex justify-between ${
        detail.is_phased_out ? "bg-pot-bg-grey-3" : "bg-pot-dark-blue"
      } rounded-2xl`}
    >
      <div className="">
        <p className="text-white font-bold text-2xl py-0.5">
          {detail.name?.existing}
        </p>
        {detail.is_phased_out && (
          <p className="flex gap-x-2 text-white items-center text-sm">
            <FilledRoundedWhiteClose />
            This service has been ended
          </p>
        )}
      </div>
      {detail &&
      pdfShowableCountries &&
      (account?.user_permissions.includes("c_download_detail_pdf") || userType.toLowerCase().includes("admin")) &&
      pdfShowableDevices &&
      hasDelayed &&
      !detail.is_phased_out ? (
        downloadClicked ? (
          <PDFGenerator
            name={
              detail.name?.existing
                ? `${detail.name?.existing}_details`
                : "details"
            }
            pdfShowableCountries={pdfShowableCountries}
            pdfShowableDevices={pdfShowableDevices}
            detail={detail}
            region={profileCountries[selectedProfileCountry]}
          />
        ) : (
          <button
            onClick={() => setDownloadClicked(true)}
            className="w-48 flex justify-center items-center py-1.5 rounded-lg bg-white text-pot-dark-blue font-bold"
          >
            Download Profile
          </button>
        )
      ) : (
        <></>
      )}
    </motion.div>
  );
};

export default Header;
