import React, { useRef, useState } from "react";
import { Checkbox } from "@atlaskit/checkbox";
import { DotPulse } from "@uiball/loaders";
import useOnClickOutside from "../../hooks/useClickOutside";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import ColoredDownArrow from "../../assets/ColoredDownArrow";
import { motion } from "framer-motion";

const CheckboxSelectField = ({
  options = [],
  placeholder = "",
  setPage,
  count,
  search,
  setSearch,
  selected,
  setSelected,
  type,
}) => {
  const dropdownRef = useRef();
  const inputRef = useRef();
  const [expand, setExpand] = useState(false);
  const [enteredOptionsDropdown, setEnteredOptionsDropdown] = useState(false);
  useOnClickOutside(dropdownRef, () => {
    setExpand(false);
    setSearch("");
  });
  const selectedValues = selected.map((option) => option.value);

  const optionSelect = (e, option) => {
    inputRef.current.blur();
    e.stopPropagation();
    if (!selectedValues.includes(option.value)) {
      if (selected) {
        setSelected([...selected, option]);
      } else {
        setSelected([option]);
      }
    } else {
      setSelected((prev) => prev.filter((item) => item.value !== option.value));
    }
  };

  const onScroll = (e) => {
    if (options?.length === count) {
      return;
    }
    if (e.target) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (scrollTop + clientHeight >= scrollHeight) {
        setPage((prev) => prev + 1);
      }
    }
  };

  return (
    <div className="w-full relative z-[51]" ref={dropdownRef}>
      <div className="flex">
        <input
          ref={inputRef}
          onFocus={() => setExpand(true)}
          onBlur={() => !enteredOptionsDropdown && setExpand(false)}
          value={search || ""}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeholder}
          className={`w-full border-2 outline-none indent-2 rounded-[4px] py-2 cursor-pointer`}
        />
        <motion.button
          onClick={() => setExpand((prev) => !prev)}
          animate={expand ? { rotate: 180 } : { rotate: 0 }}
          onMouseDown={() => !expand && inputRef.current.focus()}
          className="self-stretch absolute right-3 top-3"
        >
          <ColoredDownArrow color={"#555"} />
        </motion.button>
      </div>
      <div className="flex flex-wrap gap-y-2 gap-x-3 mt-2 p-2 min-h-[96px] max-h-48 overflow-y-auto rounded-md bg-pot-grey border-2 border-pot-grey-6">
        {selected.length > 0 ? (
          selected?.map((item, index) => (
            <div
              className="w-fit h-fit bg-[#cccccc] px-2 py-1.5 rounded-sm text-xs flex items-center gap-x-2"
              key={index}
            >
              {item.label}
              <button
                className="flex items-center"
                onClick={() => {
                  const filteredOptions = selected.filter(
                    (each) => each !== item
                  );
                  setSelected(filteredOptions);
                }}
              >
                <EditorCloseIcon size="small" />
              </button>
            </div>
          ))
        ) : (
          <p className="py-1.5">No {type} is selected</p>
        )}
      </div>
      {expand && (
        <div
          onScroll={onScroll}
          onClick={() => {
            inputRef.current.focus();
          }}
          onMouseEnter={() => setEnteredOptionsDropdown(true)}
          onMouseLeave={() => setEnteredOptionsDropdown(false)}
          className="absolute top-10 w-full max-h-40 overflow-y-auto bg-white rounded-lg border border-pot-blue-2/20 shadow-lg shadow-pot-blue-2/10 py-2"
        >
          {options.map((option) => {
            return (
              <div
                onClick={(e) => optionSelect(e, option)}
                className="flex w-full p-1 text-left text-pot-grey-3 border-l-2 hover:bg-pot-blue/10 hover:text-pot-blue hover:border-l-pot-blue"
              >
                <Checkbox isChecked={selectedValues.includes(option.value)} />
                <button onClick={(e) => {}} className="">
                  {option.label}
                </button>
              </div>
            );
          })}
          {options?.length === 0 && <div className="p-2">No {type}s found</div>}
          {options?.length !== count && !search && (
            <div className="flex justify-center items-center py-2">
              <DotPulse color="#00838F" size={20} speed={0.6} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CheckboxSelectField;
