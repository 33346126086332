import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { titleAtom } from '../../atom'
import { titles } from '../../utils/Titles'
import OttList from './OttList'
import TopOTT from './TopOTT'

const DetailsView = () => {
  const setTitle = useSetRecoilState(titleAtom)

  useEffect(() => {
    setTitle("Profiles")
    document.title = `Streaming Video Tracker | ${titles[window.location.pathname]}`
  }, [])

  return (
    <div className="mt-10">
        <TopOTT />
        <OttList />
    </div>
  )
}

export default DetailsView