import React from "react";
import Prices from "../../assets/Prices";

const PricesPromotions = ({detail}) => {
  return (
    <div className="w-full rounded-2xl shadow-lg border border-pot-grey-4/10 my-10">
      <header className="border-b flex px-4 py-2">
        <Prices />
        <p className="text-pot-dark-blue font-semibold ml-3">
          Pricing
        </p>
      </header>
      <section className="text-xs px-4 py-4 space-y-4 whitespace-pre-wrap text-pot-grey-3">
        {/* {String(detail.pricing_and_promotions?.existing).split(`   `).map((each) => (
          <p>{each.trim()}</p>
        ))} */}
        {String(detail.pricing_and_promotions?.existing)}
        {/* <div className="flex">
          <p className="font-semibold w-60">Free with ads:</p>
          <p>14 days free premium trial</p>
        </div>
        <p className="font-semibold mt-4 mb-2">Free Membership Tiers:</p>
        <div className="flex pb-2">
          <li className="w-60 px-3">Free ($0/month):</li>
          <p>Ad-supported, new episodes one week after premiere</p>
        </div>
        <div className="flex pb-2">
          <li className="w-60 px-3">Fan ($7.99/month):</li>
          <p>
            Ad-free, full access to library, new episodes one hour after Japan,
            streams on 1 device at a time
          </p>
        </div>
        <div className="flex pb-2">
          <li className="w-60 px-3">Mega Fan ($9.99/month):</li>
          <p>
            Ad-free, full access to library, new episodes one hour after Japan,
            streams on 4 device at a time, offline viewing, merchandise
          </p>
        </div>
        <div className="flex pb-2">
          <li className="w-60 px-3">Ultimate Fan ($14.99/month):</li>
          <p>
            Ad-free, full access to library, new episodes at premiere, offline
            viewing, six concurrent streams, merchandise
          </p>
        </div> */}
      </section>
    </div>
  );
};

export default PricesPromotions;
