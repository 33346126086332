import React from "react";
// import Filter from "../assets/Filter";
// import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
// import { Checkbox } from "@atlaskit/checkbox";
// import useOnClickOutside from "../hooks/useClickOutside";
// import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";

const AdminActiveFilter = ({ val = "", set = () => {} }) => {
  //   const [openDropdown, setOpenDropdown] = useState(false)
  const filterVals = ["Active", "Disabled", "Enabled"];
  //   const filterRef = useRef()
  //   useOnClickOutside(filterRef, () => setOpenDropdown(false))

  //   const operateOnVal = ( each ) => {
  //     if(val.includes(each)){
  //         val.splice(val.indexOf(each), 1)
  //     } else {
  //         val.push(each)
  //     }
  //     set([...val])
  //   }

  return (
    <div className="flex gap-x-4 self-stretch">
      <button
          onClick={() =>set("")}
          className={`px-3 h-full flex items-center text-sm rounded-md ${
            !val
              ? "bg-pot-blue border border-pot-blue text-white"
              : "border text-pot-t-grey"
          }`}
        >
          All
        </button>
      {filterVals.map((each) => (
        <button
          onClick={() => set(each.toLowerCase())}
          className={`px-3 h-full flex items-center text-sm rounded-md ${
            val === each.toLowerCase()
              ? "bg-pot-blue border border-pot-blue text-white"
              : "border text-pot-text-grey-2"
          }`}
        >
          {each}
        </button>
      ))}
    </div>
  );

  //   return (
  //     <div className="flex gap-x-4 z-50 h-full">
  //         <div ref={filterRef} className="relative h-full">
  //             <button onClick={() => setOpenDropdown(prev => !prev)} className="px-3 h-full rounded-md stroke-pot-blue bg-pot-bg-blue text-sm  flex gap-x-2 items-center text-pot-blue">
  //                 <Filter />
  //                 Filter
  //                 <HipchatChevronDownIcon size='small' primaryColor='#1767A5' />
  //             </button>
  //             {openDropdown && (
  //                 <div className="absolute w-48 rounded-md z-50 border shadow-md left-0 mt-2 text-sm bg-white px-3 py-2 flex flex-col gap-y-3">
  //                     {filterVals.map((each) => (
  //                         <Checkbox
  //                             label={each}
  //                             isChecked={val.includes(each)}
  //                             onChange={() => operateOnVal(each)}
  //                         />
  //                     ))}
  //                 </div>
  //             )}
  //         </div>
  //         {val.map(each => (
  //             <div className="border cursor-default h-full pl-3 text-pot-blue text-sm rounded-md flex items-center gap-x-2">
  //                 {each}
  //                 <button onClick={() => operateOnVal(each)} className="flex items-center h-full">
  //                     <EditorCloseIcon primaryColor='#1767A5' />
  //                 </button>
  //             </div>
  //         ))}
  //     </div>
  //   )
};

export default AdminActiveFilter;
