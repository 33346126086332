import React, { useEffect, useState } from "react";
import { useReactTable, getCoreRowModel } from "@tanstack/react-table";
import useColumnsDef from "../../../hooks/useColumnsDef";
import { tableData } from "../../../utils/data";
// import { initialVisibilityObject } from "../../../utils/visibility";
import TableLayoutOne from "../../../components/TableLayout/One";
// import { dummylist } from "../../../api/Dummy";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  ExcelSearchResultsDownloadAtom,
  FilterValuesAtom,
  InitialColumnVisibilityAtom,
  LoadingProgressAtom,
  PdfColsAtom,
  PdfDataAtom,
  SelectedProfileCountryAtom,
  StartColumnGenerate,
  SubscriptionTypeAtom,
  TableLoadingAtom,
} from "../../../atom";
import { OttDataApi } from "../../../api/ottData";
import TableControlTopBar from "../TableControlTopBar";
import TableControl from "../../../components/TableLayout/TableControl";
import { formatToParams } from "../../../utils/formatConfig";
import NoData from "../../../assets/no_data.png";
import Button from "../../../form/components/Button";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { toast } from "react-toastify";

const Table = ({ viewType }) => {
  const [initialVisibilityObject, setInitialColumnVisibility] = useRecoilState(
    InitialColumnVisibilityAtom
  );
  const [columnResizeMode] = useState("onChange");
  const [platformData, setPlatformData] = useState([]);
  const [sortableKey, setSortableKey] = useState("");
  const cols = useColumnsDef(
    initialVisibilityObject,
    sortableKey,
    setSortableKey
  );
  const [allColumnsVisibility, setAllColumnsVisibility] = useState({
    ...initialVisibilityObject,
  });
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [length, setLength] = useState(10);
  const mainFilters = useRecoilValue(FilterValuesAtom);
  // const allColumnsList = (visibleObj) => Object.keys(visibleObj).filter(each => visibleObj[each]).concat(Object.keys(visibleObj).filter(each => !visibleObj[each]))
  const visibleColumns = (visibleObj) =>
    Object.keys(visibleObj).filter((each) => visibleObj[each]);
  const [allColumnOrder, setAllColumnOrder] = useState(
    visibleColumns(initialVisibilityObject)
  );
  const [columnsVisibleTemp, setColumnsVisibleTemp] = useState({
    ...initialVisibilityObject,
  });
  const [columnsOrderTemp, setColumnsOrderTemp] = useState(
    visibleColumns(initialVisibilityObject)
  );
  const columnVisibility = { name: true, ...initialVisibilityObject };
  const [search, setSearch] = useState("");
  const setLoadingProgress = useSetRecoilState(LoadingProgressAtom);
  const [startGenerate, setStartGenerate] = useRecoilState(StartColumnGenerate);
  const setPdfCols = useSetRecoilState(PdfColsAtom);
  const setPdfData = useSetRecoilState(PdfDataAtom);
  const subsType = useRecoilValue(SubscriptionTypeAtom);
  const [excelDownload, setExcelDownload] = useRecoilState(ExcelSearchResultsDownloadAtom)
  const setLoading = useSetRecoilState(TableLoadingAtom)
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom)
  const navigate = useNavigate();

  const table = useReactTable({
    data: platformData,
    columns: cols,
    columnResizeMode,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
    enablePinning: true,
    initialState: {
      columnPinning: { left: ["name"] },
      columnVisibility,
      columnOrder: allColumnOrder,
    },
  });

  const fetchPlatforms = async () => {
    setLoading(true)
    const filterParam = formatToParams(mainFilters);
    const fieldsParam = Object.keys(initialVisibilityObject).concat("name");
    const kind =
      subsType === "Subscribers"
        ? "PAID_SUBS"
        : subsType === "Viewers"
        ? "MONTHLY_VIEWERS"
        : "TRANSACTIONAL_CUSTOMERS";  
    const payload = { fields: fieldsParam, filters: filterParam };
    const params = {
      page: Math.ceil(totalCount / length) < page ? 1 : page,
      length,
    };
    if (Math.ceil(totalCount / length) < page) {
      setPage(1);
    }
    if (search) {
      params.search = search;
    }
    if (sortableKey) {
      params.sort_by = sortableKey;
    }
    if(selectedProfileCountry) {
      params.profile_country = selectedProfileCountry;
    }
    setLoadingProgress(10);
    const { data: response } = await OttDataApi.list(payload, { params });
    if (response.errors) {
      if (Array.isArray(response.errors.error_message)) {
        toast.error(response.errors.error_message.join(", "));
      }
      setLoadingProgress(100);
      return;
    }
    setLoadingProgress(50);
    // const settableData = response.data.map((data) => ({...data, ...tableData[0]}))
    setLoadingProgress(70);
    setPlatformData(response.data.results);
    setTotalCount(response.data.total_count);
    setTotalPages(response.data.total_pages);
    setLoadingProgress(100);
    setLoading(false)
  };

  const fetchAllPlatforms = async (download=false) => {
    const filterParam = formatToParams(mainFilters);
    const fieldsParam = Object.keys(initialVisibilityObject).concat("name");
    const payload = { fields: fieldsParam, filters: filterParam };
    const params = { page: 1, length: totalCount, profile_country: selectedProfileCountry };
    if (search) {
      params.search = search;
    }
    if(download){
      params.download = 1
    }
    if (sortableKey) {
      params.sort_by = sortableKey;
    }
    const config = { params }
    if(download){
      config.responseType = "blob"
    }
    setLoadingProgress(40);
    let response = await OttDataApi.list(payload, config);
    if(!download) {
      response = response.data
    }
    setLoadingProgress(70);
    if (response.errors) {
      if (Array.isArray(response.errors.error_message)) {
        toast.error(response.errors.error_message.join(", "));
      }
      setLoadingProgress(100);
      setExcelDownload(false)
      return;
    }
    if(!download) {
      setPdfData(response.data.results);
    } else {
      console.log(response, response.data)
      setExcelDownload(false)
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.target = "_blank";
      link.setAttribute("download", "search_results.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
    // setStartGenerate(false)
    setLoadingProgress(100);
  };

  const clearPdfData = () => {
    setPdfCols(null);
    setPdfData(null);
  };

  useEffect(() => {
    table.setColumnVisibility(allColumnsVisibility);
    clearPdfData();
  }, [allColumnsVisibility]);

  useEffect(() => {
    table.setColumnOrder(allColumnOrder);
    clearPdfData();
  }, [allColumnOrder]);

  useEffect(() => {
    fetchPlatforms();
  }, [page, mainFilters, sortableKey, length, subsType]);

  useEffect(() => {
    if(page === 1) {
      fetchPlatforms();
    } else {
      setPage(1);
    }
  }, [search])

  useEffect(() => {
    clearPdfData();
  }, [mainFilters, search, subsType]);

  useEffect(() => {
    const visibles = visibleColumns(columnsVisibleTemp);
    if (columnsOrderTemp.length > visibles.length) {
      const filteredOrder = columnsOrderTemp.filter(
        (each) => columnsVisibleTemp[each]
      );
      setColumnsOrderTemp(filteredOrder);
    } else {
      visibles.forEach((each) => {
        if (columnsOrderTemp.includes(each)) return;
        columnsOrderTemp.push(each);
      });
      setColumnsOrderTemp([...columnsOrderTemp]);
    }
  }, [columnsVisibleTemp]);

  useEffect(() => {
    if (startGenerate) {
      setPdfCols(allColumnOrder);
      fetchAllPlatforms();
    }
  }, [startGenerate]);

  useEffect(() => {
    if(excelDownload){
      fetchAllPlatforms(true)
    }
  }, [excelDownload])

  return (
    <>
      <TableControlTopBar
        search={search}
        setSearch={setSearch}
        setAllColumnsVisibility={setAllColumnsVisibility}
        setAllColumnOrder={setAllColumnOrder}
        allColumnOrder={allColumnOrder}
        allColumnsVisibility={allColumnsVisibility}
        columnsVisibleTemp={columnsVisibleTemp}
        setColumnsVisibleTemp={setColumnsVisibleTemp}
        columnsOrderTemp={columnsOrderTemp}
        setColumnsOrderTemp={setColumnsOrderTemp}
      />
      <div className="w-full relative h-[75vh] overflow-hidden text-sm">
        <div className="flex relative w-full">
          <div className="w-full h-[68vh] overflow-auto">
            <TableLayoutOne table={table} />
            <AnimatePresence>
              {totalCount === 0 && (
                <motion.section
                  initial={{ opacity: 0, scale: 0.7 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0 }}
                  className="w-full flex flex-col justify-center items-center gap-y-6"
                >
                  <img src={NoData} alt="NO DATA" className="w-[40vw]" />
                  <Button
                    text="Modify filters"
                    primary
                    onClick={() => {
                      navigate("/search");
                    }}
                  />
                </motion.section>
              )}
            </AnimatePresence>
          </div>
          <div className="sticky pl-1 pr-2 bg-white right-0 top-0 w-fit h-0 z-40 ">
            <div className="flex justify-center items-center h-[60vh] bg-white text-xs font-semibold">
              <p style={{ writingMode: "vertical-rl" }}>© Parks Associates</p>
            </div>
          </div>
        </div>
        {totalCount !== 0 && (
          <TableControl
            page={page}
            setLength={setLength}
            length={length}
            setPage={setPage}
            totalCount={totalCount}
            totalPages={totalPages}
            big
          />
        )}
      </div>
    </>
  );
};

export default Table;
