import React from "react";

const UploadPlus = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      className="mb-4"
    >
      <rect width="34" height="34" rx="5" fill="#1767A5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.75 16.25V10.9948C17.7492 10.7966 17.6698 10.6068 17.5291 10.4672C17.3885 10.3275 17.1982 10.2494 17 10.25C16.583 10.25 16.25 10.5838 16.25 10.9948V16.25H10.9948C10.7966 16.2508 10.6068 16.3302 10.4672 16.4709C10.3275 16.6115 10.2494 16.8018 10.25 17C10.25 17.4178 10.5838 17.75 10.9948 17.75H16.25V23.0053C16.25 23.4163 16.586 23.75 17 23.75C17.417 23.75 17.75 23.4163 17.75 23.0053V17.75H23.0053C23.2034 17.7492 23.3932 17.6698 23.5329 17.5291C23.6725 17.3885 23.7506 17.1982 23.75 17C23.75 16.583 23.4163 16.25 23.0053 16.25H17.75Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};

export default UploadPlus;
