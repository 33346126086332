import React from "react";

function ShortCut() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="white" fill-opacity="0.01" />
      <path
        d="M12.67 12.6667C12.668 12.6667 12.6667 12.668 12.6667 12.668L12.67 12.6667ZM3.33333 12.6707C3.33333 12.668 3.332 12.6667 3.33 12.6667H3.33333V12.6707ZM12.6667 12.6667V8.66668H14V12.668C14.0002 12.8428 13.9659 13.0159 13.8992 13.1775C13.8324 13.339 13.7345 13.4859 13.611 13.6096C13.4875 13.7333 13.3408 13.8314 13.1793 13.8984C13.0179 13.9654 12.8448 13.9999 12.67 14H3.33C2.97743 13.9998 2.63934 13.8597 2.38998 13.6105C2.14061 13.3613 2.00035 13.0232 2 12.6707V3.32935C2 2.59535 2.59733 2.00001 3.33133 2.00001H7.33333V3.33335H3.33333V12.6667H12.6667ZM3.33333 12.6707C3.33333 12.668 3.332 12.6667 3.33 12.6667H3.33333V12.6707ZM7.33333 3.33335H3.33333V12.6667H12.6667V8.66668H14V12.668C14.0002 12.8428 13.9659 13.0159 13.8992 13.1775C13.8324 13.339 13.7345 13.4859 13.611 13.6096C13.4875 13.7333 13.3408 13.8314 13.1793 13.8984C13.0179 13.9654 12.8448 13.9999 12.67 14H3.33C2.97743 13.9998 2.63934 13.8597 2.38998 13.6105C2.14061 13.3613 2.00035 13.0232 2 12.6707V3.32935C2 2.59535 2.59733 2.00001 3.33133 2.00001H7.33333V3.33335ZM12.6667 3.33335V5.33335C12.6667 5.51016 12.7369 5.67973 12.8619 5.80475C12.987 5.92977 13.1565 6.00001 13.3333 6.00001C13.5101 6.00001 13.6797 5.92977 13.8047 5.80475C13.9298 5.67973 14 5.51016 14 5.33335V2.66668C14 2.48987 13.9298 2.3203 13.8047 2.19528C13.6797 2.07025 13.5101 2.00001 13.3333 2.00001H10.6667C10.4899 2.00001 10.3203 2.07025 10.1953 2.19528C10.0702 2.3203 10 2.48987 10 2.66668C10 2.84349 10.0702 3.01306 10.1953 3.13808C10.3203 3.26311 10.4899 3.33335 10.6667 3.33335H12.6667Z"
        fill="#1767A5"
      />
      <path
        d="M8.47133 8.47135L13.8047 3.13801C13.8683 3.07652 13.9191 3.00295 13.9541 2.92162C13.989 2.84028 14.0074 2.7528 14.0082 2.66428C14.0089 2.57576 13.9921 2.48797 13.9585 2.40604C13.925 2.32411 13.8755 2.24968 13.8129 2.18708C13.7503 2.12449 13.6759 2.07499 13.594 2.04147C13.512 2.00795 13.4243 1.99108 13.3357 1.99185C13.2472 1.99262 13.1597 2.01101 13.0784 2.04595C12.9971 2.08089 12.9235 2.13167 12.862 2.19535L7.52867 7.52868C7.40723 7.65441 7.34003 7.82282 7.34155 7.99761C7.34307 8.17241 7.41318 8.33962 7.53679 8.46323C7.66039 8.58683 7.8276 8.65694 8.0024 8.65846C8.1772 8.65998 8.3456 8.59278 8.47133 8.47135Z"
        fill="#1767A5"
      />
    </svg>
  );
}

export default ShortCut;
