import React from "react";
import { checkDateIsLess } from "../../utils/DateHelpers";
import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import moment from "moment";

const TopbarControl = ({
  keyValue,
  allFilters,
  setAllFilters,
  inputStartDate,
  setInputStartDate,
  startDate,
  endDate,
  setInputEndDate,
  inputEndDate,
  setStartDate,
  setEndDate,
  setPickerOpen,
  setOtherSelectedDate,
  invalid,
}) => {
  return (
    <div className="w-full flex justify-between items-center mt-4 text-sm pb-4 px-2 border-b mb-4">
      <div className="flex">
        <input
          value={inputStartDate}
          onChange={(e) => {
            const val = e.target.value;
            const regex = /^[0-9]{0,2}\-[0-9]{0,2}\-[0-9]{0,2}$/;
            const match = val.match(regex)?.join("");
            if (!match) return;
            setInputStartDate(match);
          }}
          onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
          onBlur={() => {
            const settableDate = new Date(
              moment(inputStartDate, "MM-DD-YYYY").toDate()
            );
            const newDate = new Date();
            const check = checkDateIsLess(newDate, settableDate);
            if (!settableDate || check) {
              setInputStartDate(moment(startDate).format("MM-DD-YY"));
              return;
            }
            setStartDate(settableDate);
          }}
          className="w-36 bg-background p-2 text-xs mr-2 border focus:bg-white"
        />
        <div className="cursor-pointer mt-1 h-fit stroke-pot-blue">
          <ArrowRightIcon />
        </div>
        <input
          value={inputEndDate}
          onChange={(e) => {
            const val = e.target.value;
            const regex = /^[0-9]{0,2}\-[0-9]{0,2}\-[0-9]{0,2}$/;
            const match = val.match(regex)?.join("");
            if (!match) return;
            setInputEndDate(match);
          }}
          onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
          onBlur={() => {
            const settableDate = new Date(
              moment(inputEndDate, "MM-DD-YYYY").toDate()
            );
            const newDate = new Date();
            const check = checkDateIsLess(newDate, settableDate);
            if (!settableDate || check) {
              setInputEndDate(moment(endDate).format("MM-DD-YY"));
              return;
            }
            setEndDate(settableDate);
          }}
          className="w-36 bg-background p-2 text-xs mr-2 border focus:bg-white"
        />
      </div>
      <div>
        <button
          onClick={() => setPickerOpen(false)}
          className="mr-3 p-2 bg-pot-grey rounded-md"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            if (invalid) return;
            const filteredFilters = allFilters.filter(
              (each) => Object.keys(each)[0] !== keyValue
            );
            const val = `${moment(startDate).format("MM-DD-YYYY")} - ${moment(
              endDate
            ).format("MM-DD-YYYY")}`;
            setAllFilters([
              { [keyValue]: [{ label: val, value: val }] },
              ...filteredFilters,
            ]);
            setStartDate(startDate);
            setEndDate(endDate);
            setPickerOpen(false);
          }}
          className={`p-2 bg-pot-blue text-white rounded-md ${
            invalid && "opacity-10"
          } `}
        >
          Set Date
        </button>
      </div>
    </div>
  );
};

export default TopbarControl;
