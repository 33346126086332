import { useCallback, useState, useRef } from "react";
import html2canvas from "html2canvas";
import { DotPulse } from "@uiball/loaders";
import DownloadIcon from "../assets/DownloadIcon";

export const useExportChart = () => {
  const headerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const titleStyles =
    "absolute z-10 bg-white top-[1px] left-[1px] right-[1px] !w-[calc(100%-2px)] rounded-t-lg";

  const handleDownload = useCallback(async (id, title) => {
    const element = document.getElementById(`chart-${id}`);

    if (!element) return;

    setIsLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 0));

    try {
      await document.fonts.ready;

      element.style.fontFamily = "'Poppins', sans-serif";

      element.classList.add("print-mode");
      const canvas = await html2canvas(element, {
        useCORS: true,
        letterRendering: true,
        allowTaint: true,
        scale: 2,
      });
      element.classList.remove("print-mode");

      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/jpeg", 1);
      link.download = `${title}.jpeg`;
      link.click();
    } catch (error) {
      console.error("Export failed:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const ExportButton = ({ id, title, variant = "default", className }) => {
    const variantStyles = {
      default: "",
      header: "absolute top-4 right-4",
      footer: "absolute bottom-2 left-2",
    };
    return (
      <button
        className={`px-2 !py-0 w-20 h-7 cursor-pointer text-xs md:text-sm text-pot-grey-3 border-2 rounded-[4px] flex justify-center items-center z-20 ${variantStyles[variant]} ${className}`}
        onClick={() => handleDownload(id, title)}
        disabled={isLoading}
      >
        {isLoading ? (
          <DotPulse size={40} color="#1767A5" />
        ) : (
          <div className="flex items-center gap-1">
            <DownloadIcon />
            Export{" "}
          </div>
        )}
      </button>
    );
  };

  return { handleDownload, isLoading, ExportButton, headerRef, titleStyles };
};
