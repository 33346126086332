import React from 'react'
import ChangePasswordAuthedForm from '../../form/ChangePasswordAuthed'

const ChangePassword = () => {
  return (
    <section className="w-full h-[90vh] flex justify-center items-center">
        <div className="bg-pot-background-grey/30 w-[40vw] px-10 py-20 mb-10 rounded-md">
            <ChangePasswordAuthedForm />
        </div>
    </section>
  )
}

export default ChangePassword