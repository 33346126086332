import React, { useCallback, useEffect, useState } from "react";
import Table from "./Table";
import SearchIcon from "@atlaskit/icon/glyph/search";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { useNavigate } from "react-router-dom";
import Customer from "../../assets/Customer";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AccountUserAtom, titleAtom } from "../../atom";
import { MacScrollbar } from "mac-scrollbar";
import { debounce } from "lodash";
import { titles } from "../../utils/Titles";
import AdminActiveFilter from "../../components/AdminActiveFilter";
import DownloadIcon from '@atlaskit/icon/glyph/download'
import { DotPulse } from "@uiball/loaders";

const CustomerList = () => {
  const navigate = useNavigate();
  const setTitle = useSetRecoilState(titleAtom);
  const account = useRecoilValue(AccountUserAtom);
  const [searchValue, setSearchValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [haveCreatePermission, setCreatePermission] = useState(false);
  const [haveViewPermission, setViewPermission] = useState(false)
  const [startDownload, setStartDownload] = useState(false)
  const [activeFilter, setActiveFilter] = useState("");
  const optimisedSearch = useCallback(
    debounce((val) => setSearchValue(val), 600),
    []
  );

  useEffect(() => {
    setCreatePermission(
      account?.user_permissions.includes("a_create_customer")
    );
    setViewPermission(account?.user_permissions.includes("a_view_customer"))
  }, [account]);

  useEffect(() => {
    setTitle("Customer List");
    document.title = `Streaming Video Tracker | ${titles[window.location.pathname]}`;
  }, []);

  useEffect(() => {
    optimisedSearch(inputValue);
  }, [inputValue]);

  return (
    <MacScrollbar>
      <section className="w-full 2xl:flex 2xl:flex-col 2xl:h-[90vh] 2xl:justify-evenly 2xl:overflow-y-auto">
        <div className="w-full px-5 flex justify-between py-2">
          <AdminActiveFilter val={activeFilter} set={setActiveFilter} />
          <div className="flex gap-x-3">
            <div className="border border-pot-bg-blue px-2 py-1 flex justify-center text-xs rounded text-pot-blue bg-pot-bg-blue/90">
              <div className="translate-y-[0.1rem]">
                <SearchIcon size="small" primaryColor="#1767A5" />
              </div>
              <input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Search"
                className="outline-none px-2 w-56 bg-transparent placeholder:text-pot-blue"
              />
              <button
                onClick={() => setInputValue("")}
                className="translate-y-0.5"
              >
                <CrossIcon size="small" primaryColor="#1767A5" />
              </button>
            </div>
            {haveViewPermission && (
              <button
              onClick={() => !startDownload && setStartDownload(true)}
              className="bg-pot-bg-blue/90 text-pot-blue flex justify-center text-sm items-center gap-2 px-4 py-1 rounded-md w-36"
            >
              {startDownload ? (
                <DotPulse size={20} color="#1767A5" />
              ) : (<>
              <DownloadIcon primaryColor="#1767A5" size="small" />
              <p>Download</p>
              </>)}
            </button>
            )}
            {haveCreatePermission && (
              <button
                onClick={() => navigate("/admin/user/customer/create")}
                className="bg-pot-bg-blue/90 text-pot-blue flex text-sm items-center gap-2 px-4 py-1 rounded-md"
              >
                <Customer />
                <p>Create A Customer</p>
              </button>
            )}
          </div>
        </div>
        <Table search={searchValue} filter={activeFilter} startDownload={startDownload} setStartDownload={setStartDownload} />
      </section>
    </MacScrollbar>
  );
};

export default CustomerList;
