import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Doc from "../../assets/Doc";
import RoundedFilledClose from "../../assets/RoundedFilledClose";
import UploadPlus from "../../assets/UploadPlus";
import { motion } from "framer-motion";

const LibraryDropzone = ({
  setMainFile = () => {},
  clear,
  editData,
  error,
  clearError,
  title = "Upload a PDF",
  description = "Please upload your PDF file for publishing into the libary, making sure the file is within the size limit of 20MB",
  errors,
  setError,
  page = "Library",
}) => {
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);

  const onDrop = useCallback(async (acceptedFiles) => {
    clearError();
    setFileName(acceptedFiles[0].name);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".pdf",
    maxFiles: 1,
    multiple: false,
  });

  useEffect(() => {
    if (file && file.name) {
      setMainFile({ file });
    } else {
      setMainFile({ file: null });
    }
    // }
  }, [file]);

  useEffect(() => {
    if (clear) {
      setFile(null);
      setFileName("");
    }
  }, [clear]);

  useEffect(() => {
    if (editData) {
      setFile({});
      setFileName(editData.name);
    } else {
      setFile(null);
      setFileName("");
    }
  }, [editData]);

  return (
    <>
      {fileName ? (
        <div className="h-[30vh] flex flex-col justify-center items-center">
          <div className="w-[30vw] flex justify-between gap-3">
            <Doc />
            <div className="w-full flex flex-col justify-between pb-2">
              <div className="w-full flex justify-between pl-3 text-sm">
                <p className="w-60 truncate">{fileName}</p>
                {
                  <button
                    onClick={() => {
                      setFileName("");
                      // setAcceptedFile(null);
                      setError({});
                      setFile(null);
                    }}
                  >
                    <RoundedFilledClose />
                  </button>
                }
              </div>
              <div className="w-full h-[3px] relative z-50 rounded-full mx-3 bg-pot-bg-grey-2">
                <motion.div
                  className="h-[3px] bg-pot-dark-blue rounded-full"
                  initial={{ width: 0 }}
                  animate={{ width: `${100}%` }}
                ></motion.div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          // onClick={open}
          {...getRootProps({})}
          className={`${"h-[30vh]"} text-sm cursor-pointer mx-10 rounded-lg flex relative justify-center items-center`}
        >
          <input {...getInputProps()} />
          <div className="flex flex-col justify-center items-center">
            {/* <input {...getInputProps()}/> */}
            <div className="cursor-pointer">
              <UploadPlus />
            </div>
            {isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p className="font-semibold">{title}</p>
            )}
            <p className="mt-4 text-center text-xs text-pot-grey-4">
              {description}
            </p>
          </div>
        </div>
      )}
      {errors && (
        <div className="flex flex-col gap-2 ml-10 mr-5 max-h-[60px] overflow-y-scroll custom-scrollbar">
          {errors &&
            errors?.map((error) => {
              return <p className="text-xs text-pot-red">{error}</p>;
            })}
        </div>
      )}
      {error ? (
        <div className="text-xs text-center text-pot-red">{error}</div>
      ) : (
        <div className="min-h-[1.25rem] w-full"></div>
      )}
    </>
  );
};

export default React.memo(LibraryDropzone);
