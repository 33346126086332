export const setToCache = async (cacheName, url, response) => {
  const data = new Response(JSON.stringify(response));
  const cache = await caches.open(cacheName);
  cache.put(url, data);

  // caches.match(`${BASE_URL}/ott-profile/config/`).then((response) => {

  //   console.log(response, 'Cached data')
  // })
};

export const getFromCache = async (url) => {
    const response = await caches.match(url)
    if(response) {
        const res = await response.json()
        console.log("Called from cache here", res)
        return res
    }
    return null
}
