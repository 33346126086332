import React from "react";
import { motion } from "framer-motion";

const YearView = ({ yearOffset, date, selectedYear, setYearView }) => {
  const currentYear = new Date().getFullYear();
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.6 }}
      animate={{ opacity: 1, scale: 1 }}
      className="grid grid-cols-4 gap-5 pl-5 mt-8"
    >
      {Array.from({ length: 16 }).map((_, id) => {
          const thisYear = currentYear + (yearOffset - 1) * 16 + id + 1
          return(
        <button
          className={selectedYear === thisYear ? "px-4 py-2 bg-pot-dark-blue rounded flex items-center justify-center text-white" : ""}
          onClick={() => {
            date.setYear(thisYear)
            setYearView(false)
          }}
        >
          {thisYear}
        </button>
      )})}
    </motion.div>
  );
};

export default YearView;
