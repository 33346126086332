// import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";

const useTopActiveUsersCols = ({
  fetch = async (id) => {
    console.log(id, "NOT WORKING");
  },
  setters=[]
}) => {
   const [data, setData] = useState([])
   const [loading, setLoading] = useState(true)
  const colDef = [
    {
      header: () => <p className="text-left font-medium">Rank</p>,
      accessorKey: "rank",
      size: 5,
    },
    {
      header: () => <p className="text-left font-medium">Name</p>,
      accessorKey: "name",
      size: 100,
    },
    {
      header: () => <p className="text-left font-medium">Email Id</p>,
      accessorKey: "email",
      size: 200,
    },
    {
      header: () => <p className="text-left font-medium">No of Logins</p>,
      accessorKey: "count",
      size: 100,
    },
  ];

//   const { data } = useQuery({
//     queryKey: ["TopActiveUsers"],
//     queryFn: () => fetch("TOP_10_ACTIVE_USERS"),
//     cacheTime: 1000 * 60 * 60 * 24,
//   });

  const fetchActiveUsers = async () => {
    const data = await fetch("TOP_10_ACTIVE_USERS")
    setData(data)
    setLoading(false)
  }
  
  useEffect(() => {
    fetchActiveUsers();
  }, [setters])

  // const data = Array.from({ length: 10 }).map((_, id) => ({
  //     rank: id + 1,
  //     name: "James Harley",
  //     email: "jamesharleyv3835@gmail.com",
  //     logins: 2933,
  // }))

  const settable = useMemo(() => ({ colDef, data, loading }), [colDef, data, loading])

  return settable;
};

export default useTopActiveUsersCols;
