import React, { useState } from "react";
import ForgotPasswordForm from "../form/ForgotPassword";
import MainAuthComponent from "./MainAuthComponent";
import forgot_password from "../assets/forget_password.png";
import { AuthApi } from "../api/Auth";
// import { validateEmail } from "../utils/validations";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async () => {
    if(loading) return
    setLoading(true)
    if (!email) {
      setError("Please enter Email")
      return;
    }
    const { data: response } = await AuthApi.resetPassword({ email });
    setLoading(false)
    if(response.success){
      navigate("/login");
    }
  };

  return (
    <MainAuthComponent side_image={forgot_password} width="w-[70%] mb-5">
      <ForgotPasswordForm
        email={email}
        setEmail={setEmail}
        onSubmit={onSubmit}
        error={error}
        setError={setError}
        loading={loading}
      />
    </MainAuthComponent>
  );
};

export default ForgotPassword;
