import React, { useEffect, useRef, useState } from "react";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import DateRangePicker from "../../components/DateRangePicker";
import useOnClickOutside from "../../hooks/useClickOutside";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";

const Header = ({
  setters = [],
  setStartDate,
  setEndDate,
  startDate,
  endDate,
}) => {
  const [openPicker, setOpenPicker] = useState(false);
  const pickerRef = useRef();
  useOnClickOutside(pickerRef, () => setOpenPicker(false));

  const setDates = ([{ value }]) => {
    const [start, end] = value[0].value
      .split(" - ")
      .map((each) => moment(each, "MM-DD-YYYY").format("YYYY-MM-DD"));
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="w-full flex mt-8 justify-between text-sm">
      <p className="text-lg font-semibold">User Analytical information</p>
      <div ref={pickerRef} className="relative">
        <button
          onClick={() => setOpenPicker((prev) => !prev)}
          className="flex gap-x-1 rounded-md items-center text-pot-blue bg-pot-bg-blue pr-2 pl-4"
        >
          {moment(setters.start, "YYYY-MM-DD").format("MM-DD-YYYY")}
          {" - "}
          {moment(setters.end, "YYYY-MM-DD").format("MM-DD-YYYY")}
          <ChevronDownIcon primaryColor="#1767A5" />
        </button>
        <AnimatePresence>
          {openPicker && (
            <motion.div
              initial={{ opacity: 0, marginTop: "-1rem" }}
              exit={{ opacity: 0, scale: 0.98, transition: { duration: 0.1 } }}
              animate={{ opacity: 1, marginTop: "0.5rem" }}
              className="z-50 absolute mt-2 right-0 bg-white shadow-lg border rounded-lg "
            >
              <DateRangePicker
                setOpenDatePicker={setOpenPicker}
                allFilters={[]}
                setAllFilters={setDates}
                keyValue="value"
                initialDaysDiff={30}
                allowMonthsDiff={6}
                startDate={startDate}
                endDate={endDate}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Header;
