import React, { useState } from "react";
import Checkbox from "@atlaskit/checkbox";
import AuthInputField from "./components/AuthInput";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import AuthHeader from "./components/AuthHeader";
import AuthSubmit from "./components/AuthSubmit";
import Danger from "../assets/Danger";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "./components/Button";
import PrivacyPolicy from "../components/PrivacyPolicy";
import { MacScrollbar } from "mac-scrollbar";

const LoginForm = ({
  setUsername,
  setPassword,
  keepLoggedIn,
  setKeepLoggedIn,
  onSubmit,
  error,
  setError,
  acceptPrivacy,
  setAcceptPrivacy = () => {},
  loading = false,
  newPage = false,
}) => {
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
  const navigate = useNavigate();
  const description = ``;

  return (
    <section className="w-full px-[3vw]">
      <AuthHeader
        heading="Login Screen"
        description={description}
        newPage={newPage}
      />
      <main className="w-full">
        <AuthInputField
          onChange={(e) => {
            setError(null);
            setUsername(e.target.value);
          }}
          heading="Email"
          placeholder="Type your email"
          newPage={newPage}
          errorMessage={error?.email}
          autoComplete="on"
        />
        <AuthInputField
          onChange={(e) => {
            setError(null);
            setPassword(e.target.value);
          }}
          heading="Password"
          placeholder="Type your password"
          type="password"
          newPage={newPage}
          errorMessage={error?.password}
          autoComplete="on"
        />
        <button
          onClick={() => {
            navigate("/forgot-password");
          }}
          className="underline text-xs mt-5"
        >
          Forgot Password?
        </button>
        <div className="flex items-center mt-6">
          <Checkbox
            isChecked={keepLoggedIn}
            size="medium"
            onChange={() => setKeepLoggedIn((prev) => !prev)}
          />
          <p
            onClick={() => setKeepLoggedIn((prev) => !prev)}
            className={`${
              newPage && "font-semibold"
            } text-xs 2xl:text-sm font-medium cursor-default`}
          >
            Keep me logged in
          </p>
        </div>
      </main>
      {error && !newPage && (
        <div className="flex mt-5 items-center text-xs">
          <Danger />
          <p className="ml-3">{error?.non_field_errors}</p>
        </div>
      )}
      {newPage && (
        <Checkbox
          isChecked={acceptPrivacy}
          onChange={() => {
            setError("");
            setAcceptPrivacy(false);
            if (!acceptPrivacy) {
              setOpenPrivacyModal(true);
            }
          }}
          label={
            <p className="text-xs 2xl:text-sm text-pot-dark-blue underline">
              Accept our privacy policy and terms of condition
            </p>
          }
        />
      )}
      {newPage && (
        <div className="h-[4vh]">
          {error?.non_field_errors && (
            <motion.div className="flex min-h-[4vh] items-center text-xs">
              <Danger />
              <p className="ml-3">{error?.non_field_errors}</p>
            </motion.div>
          )}
        </div>
      )}
      <AuthSubmit
        text="Login"
        onClick={onSubmit}
        newPage={newPage}
        loading={loading}
      />
      <ModalTransition>
        {openPrivacyModal && (
          <Modal onClose={() => setOpenPrivacyModal(false)} width="x-large">
            <div className="pt-10 pb-4 h-fit bg-white">
              <MacScrollbar>
                <div className="max-h-[65vh] overflow-y-auto text-sm px-10 text-pot-text-grey">
                  <PrivacyPolicy />
                </div>
              </MacScrollbar>
            </div>
            <div className="py-8 flex justify-center bg-white">
              <Button
                text="Accept"
                primary
                onClick={() => {
                  setAcceptPrivacy(true);
                  setOpenPrivacyModal(false);
                }}
              />
            </div>
          </Modal>
        )}
      </ModalTransition>
    </section>
  );
};

export default LoginForm;
