import React from "react";

const Customer = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.126 7.00001H2C0.887 7.00001 0 7.89501 0 9.00001V12.73C0 14.04 1.874 14.783 4 14.96V11.996C4.00159 11.2012 4.3183 10.4395 4.88068 9.87786C5.44306 9.31623 6.2052 9.00054 7 9.00001H7.354C6.7539 8.47112 6.32615 7.77447 6.126 7.00001ZM13.874 7.00001H18C19.105 7.00001 20 7.88501 20 9.00001V12.73C20 14.04 18.126 14.783 16 14.96V11.996C15.9989 11.2011 15.6824 10.439 15.1199 9.87727C14.5574 9.31553 13.795 9.00001 13 9.00001H12.646C13.2461 8.47112 13.6738 7.77447 13.874 7.00001ZM7.967 2.55401C7.87498 1.94306 7.59657 1.37542 7.16981 0.928652C6.74304 0.481881 6.18875 0.177771 5.58265 0.0578696C4.97656 -0.0620313 4.34823 0.00812587 3.7835 0.258756C3.21878 0.509385 2.74521 0.928263 2.4275 1.45816C2.10979 1.98805 1.96343 2.60312 2.00843 3.21932C2.05342 3.83552 2.28758 4.4228 2.67889 4.90092C3.07021 5.37904 3.59961 5.72468 4.19475 5.89062C4.78989 6.05655 5.42175 6.03469 6.004 5.82801C6.03235 5.16191 6.22679 4.51346 6.56962 3.94166C6.91246 3.36986 7.39282 2.89284 7.967 2.55401ZM13.997 5.82801C14.5792 6.0346 15.2109 6.05642 15.806 5.89051C16.401 5.72459 16.9304 5.37903 17.3217 4.90101C17.713 4.423 17.9471 3.83584 17.9922 3.21975C18.0373 2.60365 17.8911 1.98865 17.5736 1.45875C17.2561 0.92885 16.7828 0.50989 16.2182 0.259095C15.6537 0.00829902 15.0255 -0.0621036 14.4194 0.0575034C13.8133 0.17711 13.259 0.480895 12.832 0.927363C12.4051 1.37383 12.1264 1.94121 12.034 2.55201C12.6088 2.89068 13.0895 3.36793 13.4324 3.94018C13.7753 4.51243 13.9694 5.16146 13.997 5.82801Z"
        fill="#1767A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12C5 10.895 5.887 10 7 10H13C14.105 10 15 10.885 15 12V15.73C15 18.757 5 18.757 5 15.73V12Z"
        fill="#1767A5"
      />
      <path
        d="M10 9.00001C11.6569 9.00001 13 7.65686 13 6.00001C13 4.34316 11.6569 3.00001 10 3.00001C8.34315 3.00001 7 4.34316 7 6.00001C7 7.65686 8.34315 9.00001 10 9.00001Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default Customer;
