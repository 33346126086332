import React, { useEffect, useState } from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import TableLayoutTwo from "../../components/TableLayout/Two";
import Message from "../../assets/Message";
import Edit from "../../assets/Edit";
import Toggle from "@atlaskit/toggle";
import TableControl from "../../components/TableLayout/TableControl";
import { CustomerApi } from "../../api/customer";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  AccountUserAtom,
  LoadingProgressAtom,
  MainAppSideBarExpandAtom,
  TableLoadingAtom,
} from "../../atom";
import { useNavigate } from "react-router-dom";
import { AdminApi } from "../../api/admin";
import { MacScrollbar } from "mac-scrollbar";
import { toast } from "react-toastify";
import moment from "moment";
import Sort from "../../assets/Sort";
import { motion } from "framer-motion";
import { DotPulse } from "@uiball/loaders";
import CheckIcon from "@atlaskit/icon/glyph/check";
import ResetPass from "../../assets/ResetPass";
import { dateDifference } from "../../utils/DateHelpers";
import { Tooltip } from "react-tooltip";

const CustomerTable = ({ search, filter, startDownload, setStartDownload }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const setLoading = useSetRecoilState(LoadingProgressAtom);
  const [colDef, setColDef] = useState([]);
  // const [actionCol, setActionCol] = useState({});
  const account = useRecoilValue(AccountUserAtom);
  // const [errors, setErrors] = useState(null);
  const [initial, setInitial] = useState(true);
  const setTableLoading = useSetRecoilState(TableLoadingAtom);
  const [sortableKey, setSortableKey] = useState("");
  const [mailLoading, setMailLoading] = useState(0);
  const [mailId, setMailId] = useState(null);
  const [resetLoading, setResetLoading] = useState(0);
  const [resetId, setResetId] = useState(null);
  const [toggledSomewhere, setToggledSomewhere] = useState(0);
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);

  const sendEmailToggle = async (id) => {
    setMailId(id);
    setMailLoading((prev) => (prev + 1) % 3);
    const { data: response } = await AdminApi.sendWelcomeMail(id);
    if (response.success) {
      setMailLoading((prev) => (prev + 1) % 3);
    } else {
      setMailLoading(0);
    }
  };

  const downloadCustomers = async () => {
    const params = { page, download_excel: 1 };
    if (search) {
      params.search = search;
    }
    if (filter) {
      params.filter = filter;
    }
    if (sortableKey) {
      params.sort_by = sortableKey.replace("phone_no", "phone");
    }
    try {
      const response = await CustomerApi.download(params);
      if (response.status !== 200) {
        const errorString = await response.data.text();
        const errorObj = JSON.parse(errorString);
        toast.error(errorObj.errors.error_message.join(", "));
        return;
      }
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.target = "_blank";
      link.setAttribute("download", `Customer records.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      toast.error(JSON.stringify(e));
    }
    setStartDownload(false);
  };

  const sendReset = async (id) => {
    setResetId(id);
    setResetLoading((prev) => (prev + 1) % 3);
    const { data: response } = await AdminApi.sendReset(id);
    if (response.success) {
      setResetLoading((prev) => (prev + 1) % 3);
    } else {
      setResetLoading(0);
    }
    // fetchAdmins();
  };

  const initialColDef = [
    {
      header: () => (
        <div
          onClick={() =>
            sortableKey === "first_name"
              ? setSortableKey(`-first_name`)
              : sortableKey === `-first_name`
              ? setSortableKey("")
              : setSortableKey("first_name")
          }
          className="w-full flex justify-center cursor-pointer gap-x-3"
        >
          Name
          <motion.button whileHover={{ scale: 1.2 }} className="min-w-fit mr-2">
            {sortableKey === "first_name" ? (
              <Sort firstFill="#1767A5" secondFill="#929292" />
            ) : sortableKey === `-first_name` ? (
              <Sort firstFill="#929292" secondFill="#1767A5" />
            ) : (
              <Sort firstFill="#1767A5" secondFill="#1767A5" />
            )}
          </motion.button>
        </div>
      ),
      id: "name",
      cell: ({ row }) => (
        <div className="w-full flex justify-center">
          <p className="w-48 truncate">
            {row.original.first_name} {row.original.last_name}
          </p>
        </div>
      ),
    },
    {
      header: () => (
        <div
          onClick={() =>
            sortableKey === "organization"
              ? setSortableKey(`-organization`)
              : sortableKey === `-organization`
              ? setSortableKey("")
              : setSortableKey("organization")
          }
          className="w-full flex justify-center cursor-pointer gap-x-3"
        >
          Organization
          <motion.button whileHover={{ scale: 1.2 }} className="min-w-fit mr-2">
            {sortableKey === "organization" ? (
              <Sort firstFill="#1767A5" secondFill="#929292" />
            ) : sortableKey === `-organization` ? (
              <Sort firstFill="#929292" secondFill="#1767A5" />
            ) : (
              <Sort firstFill="#1767A5" secondFill="#1767A5" />
            )}
          </motion.button>
        </div>
      ),
      accessorKey: "organization",
      cell: ({ getValue }) => (getValue() ? getValue() : "-"),
    },
    {
      header: () => (
        <div
          onClick={() =>
            sortableKey === "email"
              ? setSortableKey(`-email`)
              : sortableKey === `-email`
              ? setSortableKey("")
              : setSortableKey("email")
          }
          className="w-full flex justify-center cursor-pointer gap-x-3"
        >
          Email ID
          <motion.button whileHover={{ scale: 1.2 }} className="min-w-fit mr-2">
            {sortableKey === "email" ? (
              <Sort firstFill="#1767A5" secondFill="#929292" />
            ) : sortableKey === `-email` ? (
              <Sort firstFill="#929292" secondFill="#1767A5" />
            ) : (
              <Sort firstFill="#1767A5" secondFill="#1767A5" />
            )}
          </motion.button>
        </div>
      ),
      accessorKey: "email",
      cell: ({ getValue }) => (
        <div className="w-full flex justify-center">
          <p className="w-48 truncate">{getValue()}</p>
        </div>
      ),
    },
    {
      header: () => (
        <div
          onClick={() =>
            sortableKey === "phone_no"
              ? setSortableKey(`-phone_no`)
              : sortableKey === `-phone_no`
              ? setSortableKey("")
              : setSortableKey("phone_no")
          }
          className="w-full flex justify-center cursor-pointer gap-x-3"
        >
          Phone No.
          <motion.button whileHover={{ scale: 1.2 }} className="min-w-fit mr-2">
            {sortableKey === "phone_no" ? (
              <Sort firstFill="#1767A5" secondFill="#929292" />
            ) : sortableKey === `-phone_no` ? (
              <Sort firstFill="#929292" secondFill="#1767A5" />
            ) : (
              <Sort firstFill="#1767A5" secondFill="#1767A5" />
            )}
          </motion.button>
        </div>
      ),
      accessorKey: "phone_no",
      cell: ({ getValue }) =>
        getValue() ? getValue()?.[0] + getValue()?.[1] : "-",
    },
    {
      header: "Plan Name",
      accessorKey: "plan_name",
      cell: ({ row }) => row.original.plan?.name || "-",
    },
    {
      header: "Time Left",
      accessorKey: "time_left",
      cell: ({ row }) => {
        if (row.original.plan?.expiry_date) {
          return (
            dateDifference(row.original.plan.expiry_date) +
            (dateDifference(row.original.plan.expiry_date) === 1
              ? "day"
              : " days")
          );
        } else {
          return "-";
        }
      },
    },
    {
      header: () => (
        <div
          onClick={() =>
            sortableKey === "last_login"
              ? setSortableKey(`-last_login`)
              : sortableKey === `-last_login`
              ? setSortableKey("")
              : setSortableKey("last_login")
          }
          className="w-full flex justify-center cursor-pointer gap-x-3"
        >
          Last User Login
          <motion.button whileHover={{ scale: 1.2 }} className="min-w-fit mr-2">
            {sortableKey === "last_login" ? (
              <Sort firstFill="#1767A5" secondFill="#929292" />
            ) : sortableKey === `-last_login` ? (
              <Sort firstFill="#929292" secondFill="#1767A5" />
            ) : (
              <Sort firstFill="#1767A5" secondFill="#1767A5" />
            )}
          </motion.button>
        </div>
      ),
      accessorKey: "last_login",
      cell: ({ getValue }) =>
        getValue() ? (
          moment(getValue()).format("MM-DD-YYYY")
        ) : (
          <i>Never Logged In</i>
        ),
    },
  ];

  const initialActionCol = {
    header: "Action",
    cell: ({ row }) => (
      <div className="flex justify-center">
        <Tooltip anchorSelect={`#edit${row.original.id}`} className="z-40">
          Edit customer account
        </Tooltip>
        <Tooltip anchorSelect={`#email${row.original.id}`} className="z-40">
          {row.original.can_send_welcome_mail && "Re-send welcome email"}
        </Tooltip>
        <Tooltip anchorSelect={`#lock${row.original.id}`} className="z-40">
          Reset login attempts
        </Tooltip>
        <Tooltip anchorSelect={`#toggle${row.original.id}`} className="z-40">
          Click to {row.original.is_active ? "disable" : "enable"} account
        </Tooltip>
        <div className="mr-5 mt-1">
          {account?.user_permissions.includes("a_create_admin") &&
            (resetLoading === 1 && resetId === row.original.id ? (
              <div className="h-full pt-2.5 pb-2 mr-0.5 flex items-center">
                <DotPulse size={20} color="#1767A5" speed={0.8} />
              </div>
            ) : resetLoading === 2 && resetId === row.original.id ? (
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                className="h-full flex items-center"
              >
                <CheckIcon primaryColor="#00838F" />
              </motion.div>
            ) : (
              <button
                id={`lock${row.original.id}`}
                onClick={() => sendReset(row.original.id)}
                className="px-0.5"
              >
                <ResetPass />
              </button>
            ))}
        </div>
        {account?.user_permissions.includes("a_create_customer") &&
          (mailLoading === 1 && mailId === row.original.id ? (
            <div className="h-full mt-3 flex items-center mr-2.5 ml-0.5">
              <DotPulse size={20} color="#1767A5" />
            </div>
          ) : mailLoading === 2 && mailId === row.original.id ? (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="h-full mr-2 pt-1 flex items-center"
            >
              <CheckIcon primaryColor="#00838F" />
            </motion.div>
          ) : (
            <motion.button
              whileTap={{
                scale: row.original.can_send_welcome_mail
                  ? [1, 1.5, 0.6, 1]
                  : 1,
              }}
              onClick={() => {
                if (row.original.can_send_welcome_mail) {
                  sendEmailToggle(row.original.id);
                }
              }}
              className={`${
                row.original.can_send_welcome_mail
                  ? "fill-pot-blue"
                  : "fill-pot-grey-6 cursor-not-allowed"
              } mr-3`}
              id={`email${row.original.id}`}
            >
              <Message
                fill={
                  row.original.can_send_welcome_mail ? "#1767A5" : "#DFE1E6"
                }
              />
            </motion.button>
          ))}
        {account?.user_permissions.includes("a_modify_customer") && (
          <button
            onClick={() =>
              navigate(`/admin/user/customer/edit/${row.original.id}`)
            }
            className="fill-pot-grey-6 ml-3 mr-4"
            id={`edit${row.original.id}`}
          >
            <Edit fill={"#1767A5"} />
          </button>
        )}
        {account?.user_permissions.includes("a_modify_customer") && (
          <div id={`toggle${row.original.id}`}>
            <Toggle
              isChecked={row.original.is_active}
              size="regular"
              onChange={() => toggleActive(row)}
            />
          </div>
        )}
      </div>
    ),
  };

  useEffect(() => {
    if (
      !account?.user_permissions.includes("a_modify_customer") &&
      !account?.user_permissions.includes("a_create_customer")
    ) {
      setColDef([...initialColDef]);
    } else {
      setColDef([...initialColDef, { ...initialActionCol }]);
    }
  }, [account, sortableKey, mailLoading, resetLoading]);

  useEffect(() => {
    let id;
    if (mailLoading === 2) {
      id = setTimeout(() => {
        setMailId(null);
        setMailLoading(0);
      }, 800);
    }
    return () => clearTimeout(id);
  }, [mailLoading]);

  useEffect(() => {
    let id;
    if (resetLoading === 2) {
      id = setTimeout(() => {
        setResetLoading(0);
        setResetId(null);
      }, 800);
    }
    return () => clearTimeout(id);
  }, [resetLoading]);

  useEffect(() => {
    if (startDownload) {
      downloadCustomers();
    }
  }, [startDownload]);

  // const data = Array.from({ length: 20 }).map(() => ({
  //   name: "Kevin Dukon",
  //   organisation: "lorem ipsum dolor",
  //   email: "lorem ipsum dolor",
  //   phone: "+91 9313284328",
  //   last_user_activity: "22/06/2022 | 09:30 am",
  // }));

  const toggleActive = async (row) => {
    const payload = { is_active: !row.original.is_active };
    const { data: response } = await CustomerApi.update(
      row.original.id,
      payload
    );
    if (!response.success) {
      Object.values(response.errors).forEach((each) => {
        console.log(each);
        toast.error(each.join(", "));
      });
      return;
    }
    setToggledSomewhere((prev) => prev + 1);
  };

  const fetchCustomers = async () => {
    setTableLoading(true);
    setLoading(40);
    const params = { page, length: 100 };
    if (search) {
      params.search = search;
    }
    if (filter) {
      params.filter = filter;
    }
    if (sortableKey) {
      params.sort_by = sortableKey.replace("phone_no", "phone");
    }
    const { data: response, status } = await CustomerApi.list({ params });
    setLoading(70);
    if (response.success) {
      setTotalPages(response.data.total_pages);
      setTotalCount(response.data.total_count);
      setData(response.data.results);
    }
    setLoading(100);
    setTableLoading(false);
  };

  useEffect(() => {
    if (account?.user_permissions.includes("a_view_customer")) {
      fetchCustomers();
    }
  }, [page, account, sortableKey, toggledSomewhere]);

  useEffect(() => {
    if (initial) {
      setInitial(false);
      return;
    }
    if (page === 1) {
      if (account?.user_permissions.includes("a_view_customer")) {
        fetchCustomers();
      }
    } else {
      setPage(1);
    }
  }, [search, filter]);

  const table = useReactTable({
    data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
  });

  if (account && !account?.user_permissions.includes("a_view_customer")) {
    return (
      <div className="w-full h-[75vh] flex justify-center pb-[30vh] items-center font-bold text-pot-dark-green">
        You are not allowed to view the customers list
      </div>
    );
  }

  return (
    <MacScrollbar>
      <div className="w-full relative h-[75vh] overflow-y-auto">
        <TableLayoutTwo small={true} table={table} showNoDataText />
        <motion.div
          animate={sidebarExpand ? { paddingLeft: 180 } : { paddingLeft: 56 }}
          className="fixed left-0 bottom-0 w-full"
          transition={{ duration: 0.4 }}
        >
          <TableControl
            page={page}
            length={100}
            setPage={setPage}
            totalCount={totalCount}
            totalPages={totalPages}
          />
        </motion.div>
      </div>
    </MacScrollbar>
  );
};

export default CustomerTable;
