import React, { useCallback, useEffect, useRef, useState } from "react";
import ColoredDownArrow from "../../assets/ColoredDownArrow";
import { toNormalText } from "../../utils/helperFunc";
import { motion } from "framer-motion";
import ClipLoader from "react-spinners/ClipLoader";
import { debounce } from "lodash";
import { MacScrollbar } from 'mac-scrollbar';
import { DotPulse } from "@uiball/loaders";

const SelectField = ({
  heading,
  placeholder,
  value,
  className,
  options,
  count,
  search,
  prevSearch,
  setPage,
  setSearch,
  selected,
  setSelected,
  required=false,
  invisibleCaret=false,
}) => {
  const [expand, setExpand] = useState(false);
  const [enteredOptionsDropdown, setEnteredOptionsDropdown] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();

  // const setInputChange = (e) => {
  //   if (selected) setSelected(null);
  //   setInputValue(e.target.value);
  // };

  const optionSelect = (e, option) => {
    inputRef.current.blur();
    e.stopPropagation();
    setInputValue("");
    setSelected(option);
    setExpand(false);
  };

  const onScroll = (e) => {
    if (options?.length === count || search !== prevSearch) {
      return;
    }
    if (e.target) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      console.log("Yes has reached here fetc", scrollTop, clientHeight, scrollHeight)
      if (scrollTop + clientHeight >= scrollHeight) {
        setPage((prev) => prev + 1);
      }
    }
  };

  const optimisedSearchSet = useCallback(
    debounce((val) => setSearch(val), 400),
    []
  );

  useEffect(() => {
    optimisedSearchSet(inputValue);
  }, [inputValue]);

  // useEffect(() => {
  //   setInputValue()
  // }, [selected])

  return (
    <div className={className + " relative h-12"}>
      {/* <div className="font-semibold flex w-full">{heading}{required && (<p className="ml-1 text-xxs text-red-400">✷</p>)}</div> */}
      {/* <div className="font-semibold text-xs flex bg-red-200">{required && (<p className="ml-1 text-xxs text-red-400">{heading}✷</p>)}</div> */}
      <div className="w-full flex justify-between items-center border outline-none rounded px-3 mt-3 border-pot-blue-2/20 shadow shadow-pot-blue-2/10 focus:shadow-lg focus:shadow-pot-blue-2/10">
        <input
          ref={inputRef}
          onFocus={() => setExpand(true)}
          onBlur={() => !enteredOptionsDropdown && setExpand(false)}
          value={selected?.label || ""}
          onChange={() => {}}
          placeholder={placeholder || toNormalText(value)}
          className={`w-full outline-none py-2 ${invisibleCaret && "caret-transparent"}`}
        />
        <motion.button
          onClick={() => setExpand(prev => !prev)}
          animate={expand ? { rotate: 180 } : { rotate: 0 }}
          onMouseDown={() => !expand && inputRef.current.focus()}
          className="self-stretch"
        >
          <ColoredDownArrow />
        </motion.button>
      </div>
      {expand && (
        // <MacScrollbar>
          <div
            onScroll={onScroll}
            onClick={() => {
              inputRef.current.focus();
            }}
            onMouseEnter={() => setEnteredOptionsDropdown(true)}
            onMouseLeave={() => setEnteredOptionsDropdown(false)}
            className="relative top-3 w-full max-h-40 overflow-y-auto bg-white rounded-lg border border-pot-blue-2/20 shadow-lg shadow-pot-blue-2/10 p-2"
          >
            {options.map((option) => (
              <button
                onClick={(e) => optionSelect(e, option)}
                className="w-full px-3 py-1 text-left text-pot-grey-3 hover:bg-pot-blue/10 hover:text-pot-blue  "
              >
                {option.label}
              </button>
            ))}
            {options?.length !== count && prevSearch === search && (
              <div className="flex justify-center items-center py-2">
                <DotPulse color="#00838F" size={20} speed={0.6} />
              </div>
            )}
          </div>
        // </MacScrollbar>
      )}
    </div>
  );
};

export default SelectField;
