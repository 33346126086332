import React from "react";

const Note = ({ size = "16" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 13.5899L9.705 12.2949L9 13L11 15L15 11L14.2949 10.295L11 13.5899Z"
        fill="#1B75BB"
      />
      <path d="M8 8.5H4.5V9.5H8V8.5Z" fill="#1B75BB" />
      <path d="M10.5 6H4.5V7H10.5V6Z" fill="#1B75BB" />
      <path d="M10.5 3.5H4.5V4.5H10.5V3.5Z" fill="#1B75BB" />
      <path
        d="M8 15H3C2.4485 15 2 14.5514 2 14V2C2 1.4486 2.4485 1 3 1H12C12.5515 1 13 1.4486 13 2V9.5H12V2H3V14H8V15Z"
        fill="#1B75BB"
      />
      <path
        d="M11 13.5899L9.705 12.2949L9 13L11 15L15 11L14.2949 10.295L11 13.5899Z"
        stroke="#1B75BB"
        strokeWidth="0.5"
      />
      <path d="M8 8.5H4.5V9.5H8V8.5Z" stroke="#1B75BB" strokeWidth="0.5" />
      <path d="M10.5 6H4.5V7H10.5V6Z" stroke="#1B75BB" strokeWidth="0.5" />
      <path
        d="M10.5 3.5H4.5V4.5H10.5V3.5Z"
        stroke="#1B75BB"
        strokeWidth="0.5"
      />
      <path
        d="M8 15H3C2.4485 15 2 14.5514 2 14V2C2 1.4486 2.4485 1 3 1H12C12.5515 1 13 1.4486 13 2V9.5H12V2H3V14H8V15Z"
        stroke="#1B75BB"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default Note;
