import React from "react";
import park_associates from "../assets/parks-associates-Teallogo.png";
import { motion } from "framer-motion";

const MainAuthComponent = ({ children, side_image, width }) => {
  return (
    <div className="h-screen w-screen flex">
      <div className="h-screen w-full bg-white p-[1vw] z-10">
        <div className="w-full h-full flex flex-col justify-between items-center">
          <div className="w-full h-fit">
            <img
              src={park_associates}
              alt="Park Associates"
              className="w-[8vw]"
            />
          </div>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            src={side_image}
            alt=""
            className={width}
          />
        </div>
      </div>
      <div className="h-screen w-[65%] bg-pot-background-grey/30 flex justify-center items-center px-[7vw]">
        <motion.div
          initial={{ x: "200%" }}
          animate={{ x: "0" }}
          transition={{ duration: "0.4", delay: "0.2" }}
          exit={{ x: "-200%", transition: { duration: "0.3", delay: "0" } }}
          className="w-full"
        >
          {children}
        </motion.div>
      </div>
    </div>
  );
};

export default MainAuthComponent;
