import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { StyledLeftArrow, StyledRightArrow } from "../../assets/Arrows";
import { AppInfoAtom } from "../../atom";

function ArticleTopBar({ selectedYear, setSelectedYear }) {
  const appInfo = useRecoilValue(AppInfoAtom);
  const range = {
    min: appInfo?.library?.year_range?.start,
    max: appInfo?.library?.year_range?.end,
  };
  const [minShowableYear, setMinShowableYear] = useState(range.min);
  const [maxShowableYear, setMaxShowableYear] = useState(range.max);

  useEffect(() => {
    if (appInfo) {
      const range = {
        min: appInfo?.library?.year_range?.start,
        max: appInfo?.library?.year_range?.end,
      };
      setMaxShowableYear(range.max);
      setMinShowableYear(range.min);
    }
  }, [appInfo]);

  return (
    <div className="w-full flex gap-x-5 justify-between border-b	text-center py-2 px-4 text-sm bg-[#FAFAFA]">
      <p>Publication Year</p>
      <div className="flex space-x-[0.5vw] 2xl:space-x-4 items-center">
        <button
          className={`${
            selectedYear === 0 && "rounded-md bg-pot-blue text-white "
          } px-2 py-1`}
          onClick={() => setSelectedYear(0)}
        >
          All
        </button>
        {Array.from({ length: maxShowableYear - minShowableYear + 1 }).map(
          (_, id) => (
            <button
              key={minShowableYear + id}
              className={`${
                selectedYear === minShowableYear + id &&
                "rounded-md bg-pot-blue text-white "
              } px-2 py-1`}
              onClick={() => setSelectedYear(minShowableYear + id)}
            >
              {minShowableYear + id}
            </button>
          )
        )}
      </div>
      {/* 
       just commented this because I already had a used a similar component in the detail view page.
       I just copy pasted it

      <div className=" flex space-x-1 items-center ">
        <button className="hover:scale-105">
          <ChevronLeftCircleIcon
            primaryColor="white"
            secondaryColor="#1767A5"
          />
        </button>
        <button className="hover:scale-105">
          <ChevronRightCircleIcon
            primaryColor="#1767A5"
            secondaryColor="white"
          />
        </button>
      </div> */}
      <div className="w-fit flex gap-x-5 pr-3">
        <button
          onClick={() => {
            if (!(minShowableYear > range.min)) return;
            setMaxShowableYear(minShowableYear);
            setMinShowableYear(
              minShowableYear - 14 > range.min
                ? minShowableYear - 14
                : range.min
            );
          }}
          className={`w-7 h-7 rounded-full border ${
            minShowableYear > range.min
              ? "bg-white hover:bg-pot-dark-blue hover:stroke-white transition ease-in-out"
              : "bg-pot-bg-grey-2 opacity-10 cursor-not-allowed"
          } shadow-md flex justify-center items-center pr-0.5 stroke-pot-dark-blue `}
        >
          <StyledLeftArrow color="inherit" />
        </button>
        <button
          onClick={() => {
            if (!(maxShowableYear < range.max)) return;
            setMinShowableYear(maxShowableYear);
            setMaxShowableYear(
              maxShowableYear + 14 < range.max
                ? maxShowableYear + 14
                : range.max
            );
          }}
          className={`w-7 h-7 rounded-full border ${
            maxShowableYear < range.max
              ? "bg-white hover:bg-pot-dark-blue hover:stroke-white transition ease-in-out"
              : "bg-pot-bg-grey-2 opacity-10 cursor-not-allowed"
          } shadow-md flex justify-center items-center pl-0.5 stroke-pot-dark-blue`}
        >
          <StyledRightArrow color="inherit" />
        </button>
      </div>
    </div>
  );
}

export default ArticleTopBar;
