import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import Header from "../../components/Pdf/Header";
import service from "../../assets/pdf_png/service.png";

const Services = ({ detail }) => {
  const styles = StyleSheet.create({
    container: {
      width: "100%",
      borderRadius: "8px",
      border: 1,
      borderColor: "#F0F1F8",
    },
    text: {
      paddingVertical: "5px",
      paddingHorizontal: "8px",
    },
  });
  return (
    <View style={styles.container}>
      <Header title="Service & Features" image={service} />
      <Text style={styles.text}>
        {detail.service_features?.existing}
      </Text>
      <Text style={styles.text}>
        {detail.unique_aspects?.existing}
      </Text>
    </View>
  );
};

export default Services;
