import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import TableControl from "../../../components/TableLayout/TableControl";
import TableLayoutTwo from "../../../components/TableLayout/Two";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  AccountUserAtom,
  DescriptionAtom,
  TableInfoAtom,
  TableLoadingAtom,
  UploadVerifyIdAtom,
  VerificationResultAtom,
} from "../../../atom";
import { useNavigate } from "react-router-dom";
import Note from "../../../assets/Note";
import { NPSChurnImport } from "../../../api/npschurn";

const Table = ({ height, hasDropzone, callAgain, setCallAgain }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [length, setLength] = useState(10);
  const account = useRecoilValue(AccountUserAtom);
  const [uploadVerifyId, setUploadVerifyId] =
    useRecoilState(UploadVerifyIdAtom);
  const [result, setVerificationResult] = useRecoilState(
    VerificationResultAtom
  );
  const [tableInfo, setTableInfo] = useRecoilState(TableInfoAtom);
  const setTableLoading = useSetRecoilState(TableLoadingAtom);

  const initialColDef = [
    {
      header: "Date",
      accessorKey: "created_at",
      cell: ({ getValue }) =>
        getValue() && moment(getValue()).format("MM-DD-YYYY"),
    },
    {
      header: "Time",
      id: "time",
      cell: ({ row }) =>
        row.original.created_at &&
        moment(row.original.created_at).format("hh:mm A"),
    },
    { header: "User name", accessorKey: "uploaded_by.name" },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ getValue, row }) => {
        const styles =
          getValue().toUpperCase() === "FAILED"
            ? "bg-[#ff5938]"
            : "bg-pot-light-blue-2";
        return (
          <div className="w-full flex justify-center">
            <button
              onClick={() => setUploadVerifyId(row.original.id)}
              className="flex items-center gap-x-2 w-[80px]"
            >
              <div
                className={`!w-[6px] !h-[6px] !min-w-[6px] !min-h-[6px] rounded-full ${styles}`}
              ></div>
              {getValue()}
            </button>
          </div>
        );
      },
    },
    {
      header: "Actions",
      cell: ({ row }) => {
        return (
          <button
            onClick={() => {
              setVerificationResult(
                row.original.status === "FAILED" ? "ERROR" : "SUCCESS"
              );
              if (row.original.status === "FAILED") {
                setTableInfo(row.original.errors_found);
              } else {
                setTableInfo(row.original.info);
              }
              navigate(`/admin/nps-churn/upload-data/result`);
            }}
            className="flex justify-center w-full gap-x-2"
          >
            <Note />
            <span className="underline">Result</span>
          </button>
        );
      },
    },
  ];

  useEffect(() => {
    setVerificationResult(
      data.map((row) => (row.status === "FAILED" ? "ERROR" : "SUCCESS"))
    );
  }, [data, setVerificationResult]);

  const fetchHistory = async () => {
    setTableLoading(true);
    const params = { page, length };
    const { data: response } = await NPSChurnImport.history({ params });
    setData(response.data.results);
    setTotalCount(response.data.total_count);
    setTotalPages(response.data.total_pages);
    setTableLoading(false);
  };

  useEffect(() => {
    fetchHistory();
  }, [page, length, account, callAgain]);

  const table = useReactTable({
    data,
    columns: initialColDef,
    getCoreRowModel: getCoreRowModel(),
  });

  if (account && !account?.user_permissions.includes("a_view_upload")) {
    return (
      <div
        className={`w-full ${
          hasDropzone ? "h-[30vh]" : "h-[75vh] pb-[10vh]"
        } flex justify-center items-center font-bold text-pot-dark-green`}
      >
        You are not allowed to {!hasDropzone && "upload or"} view the upload
        history
      </div>
    );
  }

  return (
    <>
      <div
        className={`w-full flex flex-col justify-between relative ${height} overflow-y-auto mt-5`}
      >
        <TableLayoutTwo table={table} />
        <TableControl
          page={page}
          setPage={setPage}
          length={length}
          setLength={setLength}
          totalCount={totalCount}
          totalPages={totalPages}
        />
      </div>
    </>
  );
};

export default React.memo(Table);
