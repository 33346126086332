import React, { useEffect, useState } from "react";
import { DownArrow, LeftArrow, LeftWardArrow } from "../../assets/Arrows";
import { motion } from "framer-motion";
import Heart from "../../assets/Heart";
import Toggle from "@atlaskit/toggle";
import CustomerForm from "../../form/Customer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  AccountUserAtom,
  AppInfoAtom,
  LoadingProgressAtom,
  titleAtom,
} from "../../atom";
import { CustomerApi } from "../../api/customer";
// import { MacScrollbar } from "mac-scrollbar";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import { TelemetryApi } from "../../api/telemetry";
import Access from "./Access";
import TrialPlan from "./TrialPlan";
import HistoryTable from "./HistoryTable";
// import async from "react-select/async";
import { toast } from "react-toastify";

const CustomerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [error, setError] = useState({});
  const [noPermissionFor, setNoPermissionFor] = useState(null);
  const [formData, setFormData] = useState({ phone: { code: "+1" } });
  const isEdit = location.pathname.includes("edit");
  const setTitle = useSetRecoilState(titleAtom);
  const [initial, setInitial] = useState(false);
  const [access, setAccess] = useState(isEdit ? false : true);
  const setLoading = useSetRecoilState(LoadingProgressAtom);
  const account = useRecoilValue(AccountUserAtom);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [planId, setPlanId] = useState("");
  const [planName, setPlanName] = useState("");
  const [planDuration, setPlanDuration] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [callHistoryLogAgain, setCallHistoryLogAgain] = useState(false);
  const [planError, setPlanError] = useState({});

  const back = () => {
    // if (window.history.state && window.history.state.idx > 0) {
    if (
      window.navigation?.currentEntry?.index > 1 ||
      (window.history.state && window.history.state.idx > 0) ||
      window.history.length > 2
    ) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  const getPayload = () => {
    let formattedFormData = {
      ...formData,
      // phone: formData.phone.code + formData.phone.number,
      organization: selectedOrg?.value,
      user_permissions: permissions,
    };
    if (formData.phone.number) {
      formattedFormData = {
        ...formattedFormData,
        phone: formData.phone.code + formData.phone.number,
      };
    } else {
      delete formattedFormData.phone;
    }
    return formattedFormData;
  };
  // ({
  //   ...formData,
  //   phone: formData.phone.code + formData.phone.number,
  //   organization: selectedOrg?.value,
  //   user_permissions: permissions,
  // });

  const removePlanErrorValue = (keyVal) => {
    delete planError[keyVal];
    setPlanError({ ...planError });
  };

  const submitPlan = async (customerId = id) => {
    const payload = { permissions };
    if (planId) {
      payload.plan_id = planId;
    } else {
      payload.custom_plan = {
        name: planName,
        duration: planDuration,
      };
    }
    const { data: response } = await CustomerApi.set_plan(customerId, payload);
    if (!response.success) {
      response.errors.error_message.forEach((each) => {
        if (typeof each === "string") {
          toast.error(each);
        } else {
          if (Object.keys(each)[0] === "custom_plan") {
            setPlanError({
              name: each.custom_plan.name.join(""),
              duration: each.custom_plan.name.join(""),
            });
          } else {
            toast.error(JSON.stringify(each));
          }
        }
      });
      if (isEdit) {
        navigate(`/admin/user/customer/edit/${id}`);
      } else {
        return false;
      }
    } else {
      setPlanName("");
      setPlanDuration("");
      setPlanId("");
      setPermissions([]);
      return true;
    }
  };

  const create = async () => {
    if (submitLoading) {
      return;
    }
    if (Object.keys(formData) === 1) {
      return;
    }
    if ((!planDuration || !planName) && !planId) {
      toast.error("Must select plan");
      return;
    }
    setSubmitLoading(true);
    const payload = getPayload();
    const { data: response } = await CustomerApi.create(payload);
    setSubmitLoading(false);
    if (!response.successs && response.errors) {
      let errs = {};
      response.errors.error_message.forEach((each) => {
        if (typeof each === "string") {
          errs.error_message = each;
        } else {
          errs = { ...errs, ...each };
        }
      });
      setError(errs);
      // setError(response.errors);
    } else {
      await submitPlan(response.data.id);
      back();
    }
  };

  const edit = async () => {
    if (submitLoading) {
      return;
    }
    setSubmitLoading(true);
    const payload = getPayload();
    const id = formData.id;
    delete payload.id;
    const { data: response } = await CustomerApi.update(id, payload);
    setSubmitLoading(false);
    if (response.success) {
      back();
    } else {
      if (response.errors) {
        let errs = {};
        response.errors.error_message.forEach((each) => {
          if (typeof each === "string") {
            errs.error_message = each;
          } else {
            errs = { ...errs, ...each };
          }
        });
        setError(errs);
      } else {
        back();
      }
    }
  };

  // const doPageTelemetry = async (isEdit) => {
  //   const payload = {
  //     url: window.location.href,
  //     title: isEdit ? "Edit Customer" : "Create Customer",
  //   };
  //   const { data: response } = await TelemetryApi.pageView(payload);
  //   if (!response.success) {
  //     console.error(response.errors);
  //   }
  // };

  const fetchCustomer = async () => {
    setLoading(30);
    const { data: response } = await CustomerApi.show(id);
    setLoading(50);
    const data = response.data;
    const settableFormData = {
      ...data,
      phone: data.phone_no
        ? { code: data.phone_no?.[0], number: data.phone_no?.[1] }
        : { code: "+1" },
    };
    const settableOrg = {
      label: data.organization.name,
      value: data.organization.id,
    };
    setLoading(70);
    // setPermissions(response.data.user_permissions);
    setFormData(settableFormData);
    setAccess(settableFormData.is_active);
    setSelectedOrg(settableOrg);
    setLoading(100);
  };

  useEffect(() => {
    if (isEdit && account?.user_permissions.includes("a_modify_customer")) {
      fetchCustomer();
    }
    return () => {};
  }, [account]);

  useEffect(() => {
    if (account) {
      if (isEdit && !account?.user_permissions.includes("a_modify_customer")) {
        setNoPermissionFor("edit");
      } else if (
        !isEdit &&
        !account?.user_permissions.includes("a_create_customer")
      ) {
        setNoPermissionFor("create");
      }
    }
  }, [account]);

  useEffect(() => {
    setTitle("Customer List");
    document.title = isEdit
      ? "Streaming Video Tracker | Edit Customer"
      : "Streaming Video Tracker | Create Customer";
    // doPageTelemetry();
  }, []);

  if (noPermissionFor) {
    return (
      <div className="flex flex-col w-full h-[63vh] items-center justify-center">
        <p className="text-pot-dark-green font-semibold mb-10">
          You are not allowed to {noPermissionFor} a customer
        </p>
        <Link
          to="/admin/user/customer"
          className="bg-pot-dark-blue text-white px-4 py-1 text-sm font-semibold rounded"
        >
          back
        </Link>
      </div>
    );
  }

  return (
    <section className="flex divide-x">
      {/* <MacScrollbar> */}
      <section className="w-full h-[94vh] overflow-y-auto text-xs pt-12 px-16">
        <div className="flex items-center">
          <motion.button
            onClick={back}
            whileHover={{ scale: 1.5 }}
            className="pr-4"
          >
            <LeftArrow />
          </motion.button>
          <p className=" text-sm font-semibold">Customer Details</p>
        </div>
        <div className="px-4 mt-3">
          {/* <p className="text-pot-text-grey">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
              aliquam, purus sit amet luctus venenatis, lectus aliquam, purus
              sit
            </p> */}
          <CustomerForm
            formData={formData}
            setFormData={setFormData}
            selectedOrg={selectedOrg}
            setSelectedOrg={setSelectedOrg}
            isEdit={isEdit}
            onSubmit={() => (isEdit ? edit() : create())}
            error={error}
            setError={setError}
            loading={submitLoading}
          />
        </div>
      </section>
      {/* </MacScrollbar>
      <MacScrollbar> */}
      <section className="w-full h-[94vh] overflow-y-auto text-xs bg-white">
        <Access access={access} setAccess={setAccess} isEdit={isEdit}>
          {/* {isEdit && (
            <p className="text-pot-dark-blue">
              Trial was expired on 31-01-2023.
            </p>
          )} */}
          <TrialPlan
            error={planError}
            setError={setPlanError}
            removeFromError={removePlanErrorValue}
            isEdit={isEdit}
            permissions={permissions}
            setPermissions={setPermissions}
            setPlanId={setPlanId}
            name={planName}
            setName={setPlanName}
            duration={planDuration}
            setDuration={setPlanDuration}
            submit={submitPlan}
            callHistory={() => setCallHistoryLogAgain(true)}
          />
          {isEdit && (
            <HistoryTable
              signalToCall={callHistoryLogAgain}
              removeSignalToCall={() => setCallHistoryLogAgain(false)}
            />
          )}
        </Access>
        {/* <p className=" text-sm font-semibold mb-3">Permissions</p>
          {appInfo.permissions.CUSTOMER.map((permission) => (
            <>
              <div className="flex justify-between items-center mt-6">
                <div className="flex gap-2">
                  <PeopleGroupIcon size="small" primaryColor="#1767A5" />
                  {permission.title}
                </div>
                <div className="select-none">
                <Toggle
                  isChecked={permission.permissions.some((a) =>
                    permissions.includes(a.codename)
                  )}
                  onChange={() => {
                    if (
                      permission.permissions.some((a) =>
                        permissions.includes(a.codename)
                      )
                    ) {
                      const filteredPermissions = permissions.filter(
                        (each) =>
                          !permission.permissions
                            .map((del) => del.codename)
                            .includes(each)
                      );
                      setPermissions(filteredPermissions);
                    } else {
                      permission.permissions.forEach((each) => {
                        if (!permissions.includes(each.codename)) {
                          permissions.push(each.codename);
                        }
                      });
                      setPermissions([...permissions]);
                    }
                  }}
                />
                </div>
              </div>
              <div className="pl-6 pr-3">
                {permission.permissions.map((each) => (
                  <div className="flex justify-between mt-2">
                    <p className="">{each.name}</p>
                    <input
                      type="checkbox"
                      className="accent-pot-blue"
                      checked={permissions.includes(each.codename)}
                      onChange={() => {
                        if (!permissions.includes(each.codename)) {
                          setPermissions([...permissions, each.codename]);
                        } else {
                          const filteredPermissions = permissions.filter(
                            (per) => per !== each.codename
                          );
                          setPermissions(filteredPermissions);
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          ))} */}
      </section>
      {/* </MacScrollbar> */}
    </section>
  );
};

export default CustomerDetails;
