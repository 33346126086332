import React, { useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ColoredDownArrow from "../../assets/ColoredDownArrow";
import { Radio } from "@atlaskit/radio";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import { AppInfoAtom } from "../../atom";
import { useRecoilValue } from "recoil";
import Toggle from "@atlaskit/toggle";
import usePlanFetch from "../../hooks/fetch/usePlanFetch";
import { DotPulse } from "@uiball/loaders";

const TrialPlan = ({
  isEdit,
  setPlanId,
  setPermissions,
  permissions,
  name,
  setName,
  duration,
  setDuration,
  submit,
  callHistory,
  error,
  setError,
  removeFromError
}) => {
  const [openTrialPlan, setOpenTrialPlan] = useState(isEdit ? false : true);
  //   const [selectedPlans, setSelectedPlans] = useState([])
  const [selectedPlan, setSelectedPlan] = useState({});
  const appInfo = useRecoilValue(AppInfoAtom);
  const plans = usePlanFetch({ setPermissions });
  const [isCustom, setIsCustom] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false)

  // const plans = [
  //   "3 day trial",
  //   "7 day trial",
  //   "1 month access",
  //   "Custom access",
  //   "All  time access",
  // ];

  //   const addToSelected = (plan) => {
  //     if(selectedPlans.includes(plan)){
  //         const filteredPlans = selectedPlans.filter((each) => each !== plan)
  //         setSelectedPlans(filteredPlans)
  //     } else {
  //         setSelectedPlans(prev => [...prev, plan])
  //     }
  //   }

  const addAsSelected = (plan, asCustom=false) => {
    if(asCustom) {
      setPlanId("")
      setSelectedPlan({})
      setIsCustom(prev => !prev)
      if(permissions.length) {
        setPermissions([])
      }
      return
    }
    if (selectedPlan.id === plan.id) {
      // planNameRef.current.value = ""
      // planDurationRef.current.value = ""
      setName("")
      setIsCustom(false)
      setDuration("")
      setSelectedPlan({});
      setPlanId("");
      if(permissions.length) {
        setPermissions([])
      }
    } else {
      // planNameRef.current.value = plan.name
      // planDurationRef.current.value = plan.duration
      setPlanId(plan.id);
      setIsCustom(false)
      setName("")
      setDuration("")
      setSelectedPlan(plan);
      setPermissions(plan.default_permissions);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true)
    const isSuccess = await submit()
    if(isSuccess) {
      setIsCustom(false)
      callHistory()
    }
    setSubmitLoading(false)
  }

  return (
    <div
      className={`relative rounded-lg shadow-[0_0_20px_2px_rgba(58,97,186,0.09)] mt-6 bg-white mb-6`}
    >
      <button
        onClick={() => setOpenTrialPlan((prev) => !prev)}
        className={` w-full flex px-4 justify-between items-center py-2 ${
          openTrialPlan ? "border-b rounded-t-lg " : "rounded-lg "
        } bg-white`}
      >
        <p className="text-sm font-semibold">Select New Plan</p>
        <motion.div
          animate={{ rotate: openTrialPlan ? 180 : 0 }}
          className="px-3"
        >
          <ColoredDownArrow color="#383838" />
        </motion.div>
      </button>
      {/* <AnimatePresence> */}
      {openTrialPlan && (
        <motion.div
          initial={{ y: "-20px", bottom: "-0px", zIndex: -4 }}
          animate={
            openTrialPlan
              ? { y: 0, bottom: "-0px", zIndex: 1 }
              : { y: "-40px", zIndex: -4 }
          }
          exit={{
            y: "-20px",
            zIndex: 1,
            transition: { duration: 0.04, ease: "linear" },
          }}
          className="relative rounded-md w-full h-fit px-4 pb-4 pt-5"
        >
          <div className="grid grid-cols-3 place-items-center pb-3">
            {plans.map((plan) => (
              <button
                onClick={() => addAsSelected(plan)}
                className="w-full flex gap-x-2 items-center"
              >
                <Radio isChecked={selectedPlan.id === plan.id} />
                <p>{plan.name}</p>
              </button>
            ))}
            <button
                onClick={() => addAsSelected({}, true)}
                className="w-full flex gap-x-2 items-center"
              >
                <Radio isChecked={isCustom} />
                <p>Custom access</p>
              </button>
          </div>
          {isCustom && (<div className="flex gap-x-5 pt-2 text-xs px-2">
            <label className="flex flex-col w-full">
              <p className="font-semibold pb-2">Plan name</p>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="4 Month Access"
                className="border rounded-sm outline-none px-3 py-1 border-[#F3F3F3] bg-pot-background-grey-2"
              />
              <p className="text-red-400 text-xs">{error.name}</p>
            </label>
            <label className="flex flex-col w-full">
              <p className="font-semibold pb-2">No. of days</p>
              <input
                value={duration}
                onChange={(e) => setDuration(String(parseInt(e.target.value) || ""))}
                placeholder="120"
                className="border rounded-sm outline-none px-3 py-1 border-[#F3F3F3] bg-pot-background-grey-2"
              />
              <p className="text-red-400 text-xs">{error.duration}</p>
            </label>
          </div>)}
          <div className="pt-8 pb-5 px-2">
            <p className="font-semibold">Permissions</p>
            {appInfo?.permissions?.CUSTOMER?.map((permission) => (
              <>
                <div className="flex justify-between items-center mt-2">
                  <div className="flex gap-2">
                    <PeopleGroupIcon size="small" primaryColor="#1767A5" />
                    {permission.title}
                  </div>
                  <div className="select-none">
                    <Toggle
                      isChecked={permission.permissions.some((a) =>
                        permissions.includes(a.codename)
                      )}
                      onChange={() => {
                        if (
                          permission.permissions.some((a) =>
                            permissions.includes(a.codename)
                          )
                        ) {
                          const filteredPermissions = permissions.filter(
                            (each) =>
                              !permission.permissions
                                .map((del) => del.codename)
                                .includes(each)
                          );
                          setPermissions(filteredPermissions);
                        } else {
                          permission.permissions.forEach((each) => {
                            if (!permissions.includes(each.codename)) {
                              permissions.push(each.codename);
                            }
                          });
                          setPermissions([...permissions]);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="pl-6 pr-3">
                  {permission.permissions.map((each) => (
                    <div className="flex justify-between mt-2">
                      <p className="">{each.name}</p>
                      <input
                        type="checkbox"
                        className="accent-pot-blue"
                        checked={permissions.includes(each.codename)}
                        onChange={() => {
                          if (!permissions.includes(each.codename)) {
                            setPermissions([...permissions, each.codename]);
                          } else {
                            const filteredPermissions = permissions.filter(
                              (per) => per !== each.codename
                            );
                            setPermissions(filteredPermissions);
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            ))}
          </div>
          {isEdit && (
            <div className="pb-5 flex justify-center">
              <button
                onClick={handleSubmit}
                className="px-3 py-2 bg-pot-blue flex gap-x-2 items-center text-white rounded text-sm mt-10"
              >
                {submitLoading && (<DotPulse size={12} speed={0.8} color="white" />)}
                Apply
              </button>
            </div>
          )}
        </motion.div>
      )}
      {/* </AnimatePresence> */}
    </div>
  );
};

export default TrialPlan;
