import React from "react";

const Location = () => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0.5C4.4087 0.5 2.88258 1.13214 1.75736 2.25736C0.632141 3.38258 0 4.9087 0 6.5C0 10.55 5.2875 15.125 5.5125 15.32C5.64835 15.4362 5.82123 15.5 6 15.5C6.17876 15.5 6.35165 15.4362 6.4875 15.32C6.75 15.125 12 10.55 12 6.5C12 4.9087 11.3679 3.38258 10.2426 2.25736C9.11742 1.13214 7.5913 0.5 6 0.5V0.5ZM6 13.7375C4.4025 12.2375 1.5 9.005 1.5 6.5C1.5 5.30653 1.97411 4.16193 2.81802 3.31802C3.66193 2.47411 4.80652 2 6 2C7.19347 2 8.33806 2.47411 9.18198 3.31802C10.0259 4.16193 10.5 5.30653 10.5 6.5C10.5 9.005 7.5975 12.245 6 13.7375ZM6 3.5C5.40665 3.5 4.82664 3.67595 4.33329 4.00559C3.83994 4.33524 3.45542 4.80377 3.22836 5.35195C3.0013 5.90013 2.94189 6.50333 3.05764 7.08527C3.1734 7.66721 3.45912 8.20176 3.87868 8.62132C4.29824 9.04088 4.83278 9.3266 5.41473 9.44236C5.99667 9.55811 6.59987 9.4987 7.14805 9.27164C7.69623 9.04458 8.16476 8.66006 8.49441 8.16671C8.82405 7.67336 9 7.09334 9 6.5C9 5.70435 8.68393 4.94129 8.12132 4.37868C7.55871 3.81607 6.79565 3.5 6 3.5ZM6 8C5.70333 8 5.41332 7.91203 5.16664 7.7472C4.91997 7.58238 4.72771 7.34811 4.61418 7.07403C4.50065 6.79994 4.47094 6.49834 4.52882 6.20737C4.5867 5.91639 4.72956 5.64912 4.93934 5.43934C5.14912 5.22956 5.41639 5.0867 5.70736 5.02882C5.99833 4.97094 6.29993 5.00065 6.57402 5.11418C6.84811 5.22771 7.08238 5.41997 7.2472 5.66665C7.41202 5.91332 7.5 6.20333 7.5 6.5C7.5 6.89783 7.34196 7.27936 7.06066 7.56066C6.77935 7.84197 6.39782 8 6 8Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default Location;
