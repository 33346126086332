import React from "react";

const ChartIcon = ({ size = "22" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.83301 20.167H20.1663"
        stroke="white"
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.9375 3.66634V20.1663H13.0625V3.66634C13.0625 2.65801 12.65 1.83301 11.4125 1.83301H10.5875C9.35 1.83301 8.9375 2.65801 8.9375 3.66634Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 9.16634V20.1663H6.41667V9.16634C6.41667 8.15801 6.05 7.33301 4.95 7.33301H4.21667C3.11667 7.33301 2.75 8.15801 2.75 9.16634Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.583 13.7503V20.167H19.2497V13.7503C19.2497 12.742 18.883 11.917 17.783 11.917H17.0497C15.9497 11.917 15.583 12.742 15.583 13.7503Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChartIcon;
