import React from 'react'
import BlueTick from '../../assets/BlueTick'
import RedCross from '../../assets/RedCross'

const Presence = ({ detail }) => {
    const freeTrialValue =
    detail.free_trial?.new[0] || detail.free_trial?.existing[0];
  return (
    <div className="w-full rounded-2xl shadow-md border flex flex-col justify-center border-pot-grey-4/10 px-5 text-xs">
        <div className="flex items-center mb-1">
            <p className="w-[70%]">Downloadable Content</p>
            { detail.download_feature?.existing ? <BlueTick /> : <RedCross /> }
        </div>
        <div className="flex items-center mb-1">
            <p className="w-[70%]">Original Content</p>
            { detail.original_content?.existing ? <BlueTick /> : <RedCross /> }
        </div>
        <div className="flex items-center mb-1">
            <p className="w-[70%]">Live Content</p>
            { detail.live_content?.existing ? <BlueTick /> : <RedCross /> }
        </div>
        <div className="flex items-center">
            <p className="w-[70%]">FreeTrial</p>
            {typeof freeTrialValue === 'string' ? (
                freeTrialValue.toLowerCase() === 'yes' ? (
                    <BlueTick />
                ) : (
                    <RedCross />
                )
                ) : freeTrialValue ? (
                <BlueTick />
                ) : (
                <RedCross />
                )}
        </div>
    </div>
  )
}

export default Presence