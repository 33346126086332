import axios from "axios";
import { getFromLocalStorage } from "../utils/localStorage";
import { toast } from "react-toastify";
// import { toast } from "react-toastify"

export const BASE_URL = process.env.REACT_APP_DOMAIN;
export const BASE_URLV2 = process.env.REACT_APP_DOMAINV2;

export const normalAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const authenticatedAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const authenticatedAxiosInstanceV2 = axios.create({
  baseURL: BASE_URLV2,
  headers: {
    "Content-Type": "application/json",
  },
});

const handleErrorResponse = (error, setServerErrorMessage) => {
  if (error.response?.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("user_type");
    localStorage.removeItem("loggedInAlways");
    window.location.href = "/login";
  } else if (!error.response.status) {
    return { data: "", status: 304 };
  } else {
    if (![400, 401, 422, 403, 404, 426, 304].includes(error.response?.status)) {
      setServerErrorMessage(
        "Unknown error: please try again later and contact tech support"
      );
    }
  }
  return error.response;
};

export const registerIntercepts = (
  setLoading = () => {},
  setServerErrorMessage
) => {
  authenticatedAxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => handleErrorResponse(error, setServerErrorMessage)
  );
  authenticatedAxiosInstance.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Token ${getFromLocalStorage("token")}`;
      return config;
    },
    (error) => Promise.reject(error)
  );

  authenticatedAxiosInstanceV2.interceptors.response.use(
    (response) => response,
    (error) => handleErrorResponse(error, setServerErrorMessage)
  );
  authenticatedAxiosInstanceV2.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Token ${getFromLocalStorage("token")}`;
      return config;
    },
    (error) => Promise.reject(error)
  );
  normalAxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => error.response
  );
  setLoading(false);
};
