import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  AllFilterOptionsAtom,
  ScrollToLetterAtom,
  ShowAlphabetsAtom,
} from "../../atom";
import { motion } from "framer-motion";
import { StyledLeftArrow, StyledRightArrow } from "../../assets/Arrows";
import { useNavigate } from "react-router-dom";

const NavigateList = ({ id, detail, type }) => {
  const [headsList, setHeadsList] = useState([]);
  const [prevIndex, setPrevIndex] = useState(null);
  const [nextIndex, setNextIndex] = useState(null);
  const allOptions = useRecoilValue(AllFilterOptionsAtom);
  const setScrollToLetter = useSetRecoilState(ScrollToLetterAtom);
  const setShowAlphabets = useSetRecoilState(ShowAlphabetsAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (allOptions && detail.name) {
      const key =
        type === "all"
          ? "tempNames"
          : type === "active"
          ? "names"
          : type === "inActive"
          ? "inActiveNames"
          : "tempNames";
      const letterList = allOptions[key].map((each) => each.label);
      setHeadsList(["All", ...letterList]);
      const namesList = allOptions[key].map((each) => each.options);
      let letterIndex = letterList.indexOf(
        detail.name.existing[0].toUpperCase()
      );
      if (letterIndex > 0) {
        const index = namesList[letterIndex].findIndex(
          (each) => each.value === id
        );
        if (index === namesList[letterIndex].length - 1) {
          if (letterIndex < letterList.length - 1) {
            if(!namesList[letterIndex + 1][0]?.value) return
            setNextIndex(namesList[letterIndex + 1][0].value);
          }
          if(!namesList[letterIndex][index - 1]?.value) return
          setPrevIndex(namesList[letterIndex][index - 1].value);
        } else if (index === 0) {
          if (letterIndex !== 0) {
            const prevNamesListLength = namesList[letterIndex - 1].length;
            if(!namesList[letterIndex - 1][prevNamesListLength - 1]?.value) return
            setPrevIndex(
              namesList[letterIndex - 1][prevNamesListLength - 1].value
            );
          }
          if(!namesList[letterIndex][1]?.value) return
          setNextIndex(namesList[letterIndex][1].value);
        } else {
          if(!namesList[letterIndex][index + 1]?.value) return
          if(!namesList[letterIndex][index - 1]?.value) return
          setNextIndex(namesList[letterIndex][index + 1].value);
          setPrevIndex(namesList[letterIndex][index - 1].value);
        }
      } else {
        letterIndex = 0;
        const index = namesList[letterIndex].findIndex(
          (each) => each.value === id
        );
        if (index === namesList[letterIndex].length - 1) {
          if (!namesList[letterIndex + 1][0]?.value) return;
          setNextIndex(namesList[letterIndex + 1][0].value);
        } else {
          if (!namesList[letterIndex][index + 1]?.value) return;
          setNextIndex(namesList[letterIndex][index + 1].value);
        }
        if (index !== 0 && namesList[letterIndex].length - 1 !== 0) {
          if (!namesList[letterIndex][index - 1]?.value) return;
          setPrevIndex(namesList[letterIndex][index - 1].value);
        }
      }
    }
  }, [detail, allOptions]);

  return (
    <div className="h-[7vh] bg-pot-background-grey-2 w-full flex items-center text-sm">
      <p className="w-fit min-w-[8vw] pl-4">OTT Names</p>
      <div className="w-full flex justify-center gap-x-2">
        {headsList.map((each, idx) => (
          <motion.button
            whileHover={{ scale: 1.1 }}
            onClick={() => {
              setScrollToLetter(idx);
              navigate("/profile");
            }}
            className={`px-2 py-1 rounded-md`}
          >
            {each}
          </motion.button>
        ))}
      </div>
      <div className="w-fit flex gap-x-5 pr-6">
        <button
          onClick={() => {
            if (prevIndex) {
              setShowAlphabets(type);
              navigate(`/profile/${prevIndex}`);
            }
          }}
          className={`w-7 h-7 rounded-full border ${
            prevIndex
              ? "bg-white hover:bg-pot-dark-blue hover:stroke-white transition ease-in-out"
              : "bg-pot-bg-grey-2 opacity-10 cursor-not-allowed"
          } shadow-md flex justify-center items-center pr-0.5 stroke-pot-dark-blue `}
        >
          <StyledLeftArrow color="inherit" />
        </button>
        <button
          onClick={() => {
            if (nextIndex) {
              setShowAlphabets(type);
              navigate(`/profile/${nextIndex}`);
            }
          }}
          className={`w-7 h-7 rounded-full border ${
            nextIndex
              ? "bg-white hover:bg-pot-dark-blue hover:stroke-white transition ease-in-out"
              : "bg-pot-bg-grey-2 opacity-10 cursor-not-allowed"
          } shadow-md flex justify-center items-center pl-0.5 stroke-pot-dark-blue`}
        >
          <StyledRightArrow color="inherit" />
        </button>
      </div>
    </div>
  );
};

export default NavigateList;
