import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  LabelList,
} from "recharts";
import Dropdown from "../../components/Dropdown";
import { motion } from "framer-motion";
import { DotPulse } from "@uiball/loaders";
import { useRecoilValue } from "recoil";
import { ResizingAtom, SelectedProfileCountryAtom } from "../../atom";
import { useExportChart } from "../../hooks/useExportChart";

const TopsChart = ({
  text,
  data,
  label,
  mainData,
  devices = false,
  distribution = false,
  isPercentVal = false,
  isRoundVal = false,
  formatDecimal = false,
}) => {
  const uniqueIdentifier =
    text?.split(" ").join("_").toLowerCase() +
    label?.split(" ").join("_").toLowerCase();
  const [quaters, setQuaters] = useState(null);
  const [market, setMarket] = useState("US");
  const [quaterData, setQuaterData] = useState(null);
  const [quater, setQuater] = useState(null);
  const [quaterValue, setQuaterValue] = useState(null);
  const containerResizing = useRecoilValue(ResizingAtom);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);
  const { ExportButton, headerRef, titleStyles } = useExportChart();

  useEffect(() => {
    setMarket(selectedProfileCountry);
  }, [selectedProfileCountry]);

  // console.log(data, quater, text, quaterData, quaterValue)
  console.log(quaterData?.[market], text, market, quaterData, data);

  useEffect(() => {
    if (data) {
      const quats = Object.keys(data)
        .map((each) => each.split(" ").reverse().join(" "))
        .sort()
        .reverse()
        .map((each) => each.split(" ").reverse().join(" "));
      setQuaters(quats);
      setQuater(quats[0]);
      setQuaterData(data[quater] || Object.values(data)[0]);
    }
  }, [data]);

  useEffect(() => {
    if (!data) return;
    if (quater) {
      setQuaterData(data[quater]);
      setQuaterValue(quater);
    }
  }, [quater]);

  useEffect(() => {
    if (!mainData) return;
    setQuaterData(mainData);
  }, [mainData]);

  return (
    <section className="w-full relative">
      <ExportButton id={uniqueIdentifier} title={text} variant="footer" />
      <div
        ref={headerRef}
        className={`${titleStyles} w-full flex justify-between px-5 py-3 items-center`}
      >
        <p className="text-pot-dark-blue font-semibold text-sm">{text}</p>
        {quater && (
          <>
            <Dropdown
              options={quaters}
              text={quaterValue}
              setValue={setQuater}
            />
          </>
        )}
      </div>
      <div
        id={`chart-${uniqueIdentifier}`}
        className="w-full h-full border shadow rounded-lg opacity-90 hover:opacity-100 transition ease-in-out duration-500"
      >
        <div
          style={{ height: headerRef.current?.clientHeight + 5 }}
          className="w-full flex justify-between px-5 py-3 items-center"
        >
          <p className="text-pot-dark-blue font-semibold text-sm">{text}</p>
        </div>
        {!quaterData || containerResizing ? (
          <div className="w-full h-96 flex justify-center items-center">
            <DotPulse size={40} color="#1767A5" />
          </div>
        ) : (
          <div className="w-full h-fit relative text-xs pb-5">
            {quaterData[market]?.length ? (
              <ResponsiveContainer
                width={"97%"}
                // height={quater ? 400 : 350}
                height={400}
              >
                <BarChart
                  margin={{
                    left: 130,
                    // top: quater ? 40 : 0,
                    top: 40,
                    bottom: 20,
                    right: 40,
                  }}
                  barGap={2}
                  barCategoryGap={8}
                  stackOffset="sign"
                  data={quaterData[market]}
                  layout="vertical"
                >
                  {devices}
                  <Bar shape={CustomizedBar} dataKey={"value"} fill="#1B75BB">
                    <LabelList
                      valueAccessor={(each) =>
                        `${
                          isRoundVal
                            ? Math.round(each.value)
                            : formatDecimal
                            ? each.value.toFixed(1)
                            : each.value.toFixed(2)
                        }${isPercentVal ? "%" : ""}`
                      }
                      position="right"
                    />
                  </Bar>
                  <XAxis
                    padding={{ left: 20, right: 20 }}
                    type="number"
                    label={{
                      value: label,
                      position: "insideBottom",
                      offset: -10,
                    }}
                  />
                  {/* <YAxis tickFormatter={(value) => value.split(" ").join("_")} tickMargin={8} interval={0} padding={{ top: 20, bottom: 20 }} type="category" dataKey="x_axis" /> */}
                  <YAxis
                    tick={CustomizedTick}
                    label={{
                      value: distribution
                        ? "Distribution Platform"
                        : devices
                        ? "Device Types"
                        : "OTT Services",
                      angle: -90,
                      position: distribution ? "insideTopRight" : "insideLeft",
                      offset: distribution ? 140 : -90,
                    }}
                    interval={0}
                    padding={{ top: 20, bottom: 20 }}
                    type="category"
                    dataKey="x_axis"
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="w-full h-96 flex justify-center items-center text-base font-semibold text-pot-text-grey-2"
              >
                No Chart Data
              </motion.div>
            )}
            {(quater || devices || distribution) && (
              <div className="absolute -top-1 left-8 flex gap-3">
                <button
                  onClick={() => setMarket("US")}
                  className={`text-sm px-2 py-1 border rounded ${
                    market === "US" &&
                    "bg-[#1B75BB] text-white border-[#1B75BB]"
                  }`}
                >
                  US
                </button>
                <button
                  onClick={() => setMarket("CANADA")}
                  className={`text-sm px-2 py-1 border rounded ${
                    market === "CANADA" &&
                    "bg-[#1B75BB] text-white border-[#1B75BB]"
                  }`}
                >
                  Canada
                </button>
              </div>
            )}
          </div>
        )}
        <div className="w-full pb-2 py-1 flex justify-end text-xs px-2 text-pot-t-grey">
          © Parks Associates
        </div>
      </div>
    </section>
  );
};

const CustomizedTick = (props) => {
  return (
    <g transform={`translate(0,${props.y})`}>
      <text
        x={180}
        // y={11}
        textAnchor="end"
        dominantBaseline="middle"
        style={{
          fontSize: "12px",
          fill: "#666666",
        }}
      >
        {props.payload.value}
      </text>
    </g>
  );
};

const CustomizedBar = (props) => {
  return (
    <g>
      <rect
        x={props.x}
        y={props.y + props.height / 2 - 5.5}
        width={props.width}
        height={11}
        fill={props.fill}
        rx={5.5}
        ry={5.5}
      />
    </g>
  );
};

export default TopsChart;
