import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { OttDataApi } from "../../../api/ottData";
import {
  AccountUserAtom,
  AllFilterOptionsAtom,
  CompareViewHeadersAtom,
  InitialColumnVisibilityAtom,
  LoadingProgressAtom,
  PlatformCompareViewHeaderAtom,
  SelectedProfileCountryAtom,
  titleAtom,
} from "../../../atom";
import TableLayoutOne from "../../../components/TableLayout/One";
import { formatNamesToId } from "../../../utils/formatConfig";
import { toast } from "react-toastify";
import RetryIcon from "@atlaskit/icon/glyph/retry";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import { formatObjectWithNoNullValue } from "../../../utils/helperFunc";
import ComparePdf from "../../ComparePdf";
import { TelemetryApi } from "../../../api/telemetry";
import { titles } from "../../../utils/Titles";
// import CrossIcon from "@atlaskit/icon/glyph/cross";
import SelectInput from "../../../form/components/SelectInput";
import AndOrDropdown from "../../../form/components/AndOrDropdown";
import Dropdown from "../../../components/Dropdown";
import useCompareViewPlatformColDef from "../../../hooks/useCompareViewPlatformColumns";
import useCompareViewPlatformData from "../../../hooks/useCompareViewPlatformData";
import Close from "../../../assets/Close";
import { getFromLocalStorage } from "../../../utils/localStorage";
const PlatFormComparison = () => {
  const setTitle = useSetRecoilState(titleAtom);
  const [comparedHeaders, setComparedHeaders] = useRecoilState(
    PlatformCompareViewHeaderAtom
  );
  const filterOptions = useRecoilValue(AllFilterOptionsAtom);
  const [comparedValues, setComparedValues] = useState(null);
  const [totalCols, setTotalCols] = useState(2);
  const allColumnsObj = useRecoilValue(InitialColumnVisibilityAtom);
  const colDef = useCompareViewPlatformColDef(
    comparedHeaders,
    totalCols,
    setComparedHeaders,
    setComparedValues,
    setTotalCols
  );
  const data = useCompareViewPlatformData(comparedHeaders, comparedValues);
  const [keys, setKeys] = useState(null);
  const [pdfCols, setPdfCols] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const setLoading = useSetRecoilState(LoadingProgressAtom);
  const [prevTeleValue, setPrevTeleValue] = useState([]);
  const [items, setItems] = useState([]);
  const [businessModel, setBusinessModel] = useState([]);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom)
  const account = useRecoilValue(AccountUserAtom);
  const table = useReactTable({
    data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
    enablePinning: true,
    initialState: { columnPinning: { left: ["Platforms"] } },
  });
  const Genrefilterdata = filterOptions.genres;
  const BusinessfilterData = filterOptions.business_models

  const fetchData = async () => {
    const allHeaders = Object.values(comparedHeaders).filter((each) => each);
    if (!allHeaders.length) {
      setComparedValues({ value1: undefined, value2: undefined });
      return;
    }
    const platforms = Object.values(comparedHeaders).filter(each => !!each).map((each) => {
      return {
        id: each.value,
        kind: each.type === 2 ? "device_supports" : "distirbution_platforms",
      };
    });
    const filters = {
      business_models: businessModel.map((each) => each.value),
      genres: items.map(item => item.value)
    }
    setLoading(30);
    // const { settable: idArray, ids } = formatNamesToId(
    //   filterOptions.names,
    //   comparedHeaders
    //   );
    //   const columns = Object.keys(allColumnsObj);
    // const payload = { fields: columns, filters: { names: idArray } };
    const payload = { platforms, filters };
    const params = { profile_country: selectedProfileCountry }
    setLoading(40);
    const { data: response } = await OttDataApi.platform(payload, { params });

    if (!response.success) {
      if (Array.isArray(response.errors.error_message)) {
        toast.error(response.errors.error_message.join(", "));
      }
      setLoading(100);
      return;
    }
    const results = response.data.platforms;
    setLoading(60);

    const settable = {};
    results.forEach((each) => {
      const sortedProfiles = [...each.profiles] 
      sortedProfiles.sort((a, b) => a.name.localeCompare(b.name))
      settable[each.id] = sortedProfiles
    })

    setComparedValues(settable)

    // const settable = Object.keys(ids).reduce((acc, cur) => {
    //   const id = ids[cur];
    //   const obj = results.find((result) => result.id === id);
    //   return { ...acc, [cur]: obj };
    // }, {});
    // setComparedValues(settable);
    setLoading(100);
  };

  const clearData = () => {
    setPdfCols(null);
    setPdfData(null);
    setKeys(null);
  };

  const compareTelemetry = async (items) => {
    const hasItemsNotInPrevTeleVal = items.filter(
      (each) => !prevTeleValue.includes(each)
    ).length;
    // const hasPrevTeleValNotInItems = prevTeleValue.filter(each => !items.includes(each)).length
    if (!hasItemsNotInPrevTeleVal) {
      return;
    }
    const payload = { items };
    setPrevTeleValue(items);
    const { data: response } = await TelemetryApi.compare(payload);
    if (!response.success) {
      console.error(response.errors.error_message.join(", "));
    }
  };

  // const doTelemetry = (comparedHeaders) => {
  //   const { settable: idArray, ids } = formatNamesToId(
  //     filterOptions.names,
  //     comparedHeaders
  //   );
  //   if (idArray.length >= 2) {
  //     if (initial) {
  //       setPrevTeleValue(idArray);
  //       setInitial(false);
  //       return;
  //     }
  //     compareTelemetry(idArray);
  //   } else {
  //     setInitial(false);
  //   }
  // };

  const downloadHandle = async () => {
    const allHeaders = Object.values(comparedHeaders).filter((each) => each);
    if (!allHeaders.length) {
      return
    }
    setLoading(40)
    const platforms = Object.values(comparedHeaders).filter(each => !!each).map((each) => {
      return {
        id: each.value,
        kind: each.type === 2 ? "device_supports" : "distirbution_platforms",
      };
    });
    const filters = {
      business_models: businessModel.map((each) => each.value),
      genres: items.map(item => item.value)
    }

    try{
     const params = { 
      profile_country: selectedProfileCountry
     }
    const response = await OttDataApi.downloadPlatform({ platforms, filters }, params)
    if (response.status !== 200) {
      const errorString = await response.data.text()
      const errorObj = JSON.parse(errorString)
      toast.error(errorObj.errors.error_message.join(", "));
      setLoading("");
      return;
    }
    setLoading(60)
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.target = "_blank";
    link.setAttribute("download", `platform_compare.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (e) {
    toast.error(JSON.stringify(e));
  }
  setLoading(100)
  }

  const handleBusinessDropdownChange = (selectedValue) => {
    if(businessModel.some((model) => model.value === selectedValue.value)) {
      setBusinessModel(businessModel.filter(model => model.value !== selectedValue.value))
      return
    }
    setBusinessModel([...businessModel, selectedValue]);
  };

  const handleDeleteGenres = (itemId) => {
    setItems(items.filter((item) => item.value !== itemId));
  };
  const handleDeleteBusiness = (id) => {
    setBusinessModel(businessModel.filter((el) => el.value !== id));
  };

  const handleGenresDropdownChange = (selectedValue) => {
    if(items.some((item) => item.value === selectedValue.value)) {
      setItems(items.filter(item => item.value !== selectedValue.value))
      return
    }
    setItems([...items, selectedValue]);
  };

  useEffect(() => {
    if (comparedHeaders) {
      fetchData();
      clearData();
      // doTelemetry(comparedHeaders);
    }
  }, [comparedHeaders, businessModel, items]);

  useEffect(() => {
    setComparedHeaders({
      value1: null,
      value2: null
  })
  }, [selectedProfileCountry])

  useEffect(() => {
    setTitle("Platform Comparison");
    document.title = `Streaming Video Tracker | ${titles[window.location.pathname]}`;
  }, []);

  return (
    <div className="max-w-full overflow-auto">
      <div className="py-4 px-10 w-full flex justify-between items-center border-b-2 ">
        <div className="flex justify-start gap-3 relative z-[90]">
          <Dropdown
            options={Genrefilterdata}
            text={"Genre of Content"}
            setValue={handleGenresDropdownChange}
          />
          <div className="text-tiny font-medium flex gap-3 flex-wrap rounded items-center">
            {items.map((item) => (
              <div
                className="flex justify-between gap-5 px-2 items-center bg-white text-black py-1 rounded-md border-2 border-gray-300"
                key={item.value}
              >
                <p>{item.label}</p>
                <button onClick={() => handleDeleteGenres(item.value)}>
                  <Close color={"black"} />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="py-4 px-6 w-full flex justify-between items-center">
        <div className="py-0 px-4 w-full flex justify-between items-center">
          <div className="flex justify-start gap-3 ">
            <Dropdown
              options={BusinessfilterData}
              text={"Business Model"}
              setValue={handleBusinessDropdownChange}
            />
            <div className="text-tiny font-medium flex gap-3 flex-wrap  rounded items-center">
              {businessModel.map((el, i) => {
                return (
                  <div
                    className="flex justify-between  gap-5  px-2 items-center  bg-white text-black py-1 rounded-md border-2 border-gray-300"
                    key={i}
                  >
                    <p>{el.label}</p>
                    <button onClick={() => handleDeleteBusiness(el.value)}>
                      <Close color={"black"} />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-6">
          <button
            onClick={() => {
              setComparedHeaders({ value1: null, value2: null });
            }}
            className="ml-6 flex items-center gap-x-2 text-sm text-pot-dark-blue bg-[#66BCFF]/10 hover:bg-[#66BCFF]/20 transition ease-in-out px-3 py-1 rounded"
          >
            <RetryIcon size="small" primaryColor="#1767A5" />
            Reset
          </button>
          {!account?.user_permissions?.includes("c_download_detail_pdf") || getFromLocalStorage("user_type").toLowerCase().includes("admin") ? (
              <button
                onClick={downloadHandle}
                className={`${!Object.values(comparedHeaders).filter(val => val).length ? "cursor-not-allowed" : "hover:bg-[#66BCFF0D]/20" } w-40 flex justify-center items-center gap-x-2 bg-pot-bg-blue  transition ease-in-out px-2 py-1 text-sm mr-9 rounded text-pot-dark-blue`}
              >
                <DownloadIcon primaryColor="#1767A5" size="small" />
                Download
              </button>
            ): null}
        </div>
      </div>

      <div className={`${window.innerWidth < 1550 ? "h-[124vh]" : "h-[92vh]"} overflow-y-auto w-full`}>
        <TableLayoutOne table={table} alternateColor />
      </div>
    </div>
  );
};

export default PlatFormComparison;
