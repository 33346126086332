import React, { useEffect, useState } from 'react'
import { AccountApi } from '../../api/account'

const usePlanFetch = () => {
  const [plans, setPlans] = useState([])

  const fetchPlans = async () => {
    const { data: response } = await AccountApi.available_plans()
    if(response.success) {
        const plans = response.data.plans
        const allTime = plans.find(plan => (String(plan.duration) === String(null)) || String(plan.duration) === String(undefined) )
        const filteredPlans = plans.filter(plan => !((String(plan.duration) === String(null)) || String(plan.duration) === String(undefined) ))
        const sorted = filteredPlans.sort((a,b) => a.duration - b.duration)
        setPlans([...sorted, allTime])
    }
  }

  useEffect(() => {
    fetchPlans()
  }, [])

  return (plans)
}

export default usePlanFetch