import React, { useEffect, useState } from "react";
import { LeftArrow } from "../../assets/Arrows";
import { motion } from "framer-motion";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AccountUserAtom, LoadingProgressAtom, titleAtom } from "../../atom";
import { MacScrollbar } from "mac-scrollbar";
import EmailTemplateForm from "../../form/EmailTemplateForm";
import { EmailAPI } from "../../api/email";
import { toast } from "react-toastify";

const TemplateForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [error, setError] = useState({});
  const [noPermissionFor, setNoPermissionFor] = useState(null);
  const setTitle = useSetRecoilState(titleAtom);
  const setLoading = useSetRecoilState(LoadingProgressAtom);
  const [formData, setFormData] = useState();
  const isEdit = location.pathname.includes("edit");
  const account = useRecoilValue(AccountUserAtom);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isCustomizable, setIsCustomizable] = useState(false);

  const back = () => {
    // if (window.history.state && window.history.state.idx > 0) {
    if (
      window.navigation?.currentEntry?.index > 1 ||
      (window.history.state && window.history.state.idx > 0) ||
      window.history.length > 2
    ) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  const create = async () => {
    if (submitLoading) {
      return;
    }
    setSubmitLoading(true);
    const payload = formData;
    const { data: response } = await EmailAPI.create(payload);
    setSubmitLoading(false);
    if (!response.successs && response.errors) {
      let errs = {};
      response.errors.error_message.forEach((each) => {
        if (typeof each === "string") {
          errs.error_message = each;
        } else {
          errs = { ...errs, ...each };
        }
      });
      setError(errs);
    } else {
      toast.success("Template created successfully!");
      setTimeout(() => {
        back();
      }, 500);
    }
    setSubmitLoading(false);
  };

  const edit = async () => {
    if (submitLoading) {
      return;
    }
    setSubmitLoading(true);
    const payload = formData;
    delete payload.id;
    const { data: response } = await EmailAPI.edit(id, payload);
    setSubmitLoading(false);
    if (response.success) {
      back();
    } else {
      if (response.errors) {
        let errs = {};
        response.errors.error_message.forEach((each) => {
          if (typeof each === "string") {
            errs.error_message = each;
          } else {
            errs = { ...errs, ...each };
          }
        });
        setError(errs);
        // setError(response.errors);
      } else {
        back();
      }
    }
  };

  const getDetails = async () => {
    if (isEdit) {
      const { data: response } = await EmailAPI.getOne(id);
      const data = response.data;
      delete data.id;
      setIsCustomizable(data.is_customizable);
      setFormData(data);
    }
  };

  useEffect(() => {
    setTitle(isEdit ? "Edit Email Template" : "Create Email Template");
    document.title = isEdit
      ? "Streaming Video Tracker | Edit Organization"
      : "Streaming Video Tracker | Create Organization";

    getDetails();
  }, []);

  useEffect(() => {
    // if (account) {
    //   if (
    //     isEdit &&
    //     !account.user_permissions.includes("a_modify_organization")
    //   ) {
    //     setNoPermissionFor("edit");
    //   } else if (
    //     !isEdit &&
    //     !account.user_permissions.includes("a_create_organization")
    //   ) {
    //     setNoPermissionFor("create");
    //   }
    // }
  }, [account]);

  if (noPermissionFor) {
    return (
      <div className="flex flex-col w-full h-[63vh] items-center justify-center">
        <p className="text-pot-dark-green font-semibold mb-10">
          You are not allowed to {noPermissionFor} an organization
        </p>
        <Link
          to="/admin/user/organization"
          className="bg-pot-dark-blue text-white px-4 py-1 text-sm font-semibold rounded"
        >
          back
        </Link>
      </div>
    );
  }

  return (
    <MacScrollbar>
      <section className="w-full lg:min-w-[750px] lg:w-[50vw] h-[94vh] overflow-y-auto text-xs pt-12 px-16">
        <div className="flex items-center">
          <motion.button
            onClick={back}
            whileHover={{ scale: 1.5 }}
            className="pr-4"
          >
            <LeftArrow />
          </motion.button>
          <p className=" text-sm font-semibold">Email Template</p>
        </div>
        <div className="px-4 mt-3">
          {/* <p className="text-pot-text-grey">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus aliquam, purus sit
          </p> */}
          <EmailTemplateForm
            isEdit={isEdit}
            formData={formData}
            setFormData={setFormData}
            onSubmit={isEdit ? edit : create}
            error={error}
            setError={setError}
            loading={submitLoading}
            isCustomizable={isCustomizable}
            setIsCustomizable={setIsCustomizable}
          />
        </div>
      </section>
    </MacScrollbar>
  );
};

export default TemplateForm;
