import React from "react";
import location from "../../assets/pdf_png/location_white.png"
import {
    Text,
    View,
    StyleSheet,
    Image
  } from "@react-pdf/renderer";

const NameSection = ({ detail, region }) => {
  const namesection_style = StyleSheet.create({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      border: "1px",
      borderColor: "#1767A5",
      backgroundColor: "#1767A5",
      borderRadius: "8px",
      paddingHorizontal: "15px",
      paddingVertical: "3px",
    },
    header: {
      width: "100%",
      color: "white",
      fontSize: "14px",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
      paddingVertical: "5px",
    },
    dates_container: {
      width: "28%",
      border: "1px",
      borderColor: "#F0F1F8",
      borderRadius: "8px",
      paddingHorizontal: "15px",
      paddingVertical: "5px",
      display: "flex",
      justifyContent: "space-between",
    },
    date_line: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    date_key: {
      width: "100%",
    },
    date_value: {
      width: "100%",
    },
    region_container: {
      height: "100%",
      display: "flex",
      justifyContent: "flex-end"
    }, 
    region: {
      display: "flex",
      flexDirection: "row",
      columnGap: "5px",
      alignItems: "center"
    },
    region_text: {
      fontSize: "7px",
      color: "white",
      fontWeight: "medium"
    },
    region_img: {
      width: "6px",
      height: "7px",
      marginBottom: "2px",
  }
  });

  return (
    <View style={namesection_style.container}>
      <Text style={namesection_style.header}>
        {detail.name?.existing || "-"}
      </Text>
      {/* <View style={namesection_style.dates_container}>
        <View style={namesection_style.date_line}>
          <Text style={namesection_style.date_key}>Launch Date:</Text>
          <Text style={namesection_style.date_value}>{"01-01-2021"}</Text>
        </View>
        <View style={namesection_style.date_line}>
          <Text style={namesection_style.date_key}>Closed Date:</Text>
          <Text style={namesection_style.date_value}>{"01-01-2021"}</Text>
        </View>
      </View> */}
      <View style={namesection_style.region_container}>
        <View style={namesection_style.region}>
          <Image style={namesection_style.region_img} src={location} />
          <Text style={namesection_style.region_text}>
            {region.toUpperCase()}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default NameSection;
