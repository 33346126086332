import React from "react";
import { useRecoilValue } from "recoil";
import { DescriptionAtom } from "../../atom";
import { AnimatePresence, motion } from "framer-motion";
import BigTick from "../../assets/BigTick";

const Success = ({ goBack }) => {
  const description = useRecoilValue(DescriptionAtom);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.3 }}
        className="z-[90] mt-10 w-full flex items-center justify-center"
      >
        <div className="flex flex-col items-center justify-center gap-y-4 pr-10">
          <BigTick />
          <p className="font-semibold mt-5">Upload Success</p>
          <p className="text-sm text-pot-text-grey-2 w-[40vw] text-center">
            The subscriber data has been successfully uploaded.
          </p>
          {description && description?.length > 0 && (
            <div className="max-h-[30vh] overflow-hidden border-2 w-full">
              <div className="flex py-3 font-semibold border-b">
                <div className="w-[15%] max-w-[15%] pl-6">Sr. No.</div>
                <div className="w-[30%] max-w-[30%] pl-6">Name</div>
                <div className="w-[30%] max-w-[30%] pl-6">Quarter</div>
                <div className="w-[40%] max-w-[40%] pl-6">Problem</div>
              </div>
              <div className="flex flex-col divide-y text-sm h-fit max-h-[200px] overflow-y-scroll custom-scrollbar">
                {description?.map((row, index) => {
                  return (
                    <div className="flex">
                      <div className={`w-[15%] max-w-[15%] py-3 pl-6`}>
                        {index + 1}
                      </div>
                      <div className={`w-[30%] max-w-[30%] py-3 pl-6`}>
                        {row?.name || "-"}
                      </div>
                      <div className={`w-[30%] max-w-[30%] py-3 pl-6`}>
                        {row?.qy || "-"}
                      </div>
                      <div className={`w-[40%] max-w-[40%] py-3 pl-6`}>
                        {row?.kind || "-"}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <button
            onClick={goBack}
            className="bg-pot-dark-blue rounded-md px-4 py-2 text-white"
          >
            Go Back
          </button>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Success;
