import React from "react";

const Admin = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M13 9C14.6569 9 16 7.65685 16 6C16 4.34315 14.6569 3 13 3C11.3431 3 10 4.34315 10 6C10 7.65685 11.3431 9 13 9Z"
        fill="#1767A5"
      />
      <path
        d="M5 6C6.65685 6 8 4.65685 8 3C8 1.34315 6.65685 0 5 0C3.34315 0 2 1.34315 2 3C2 4.65685 3.34315 6 5 6Z"
        fill="#1767A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12C8 10.895 8.887 10 10 10H16C17.105 10 18 10.885 18 12V15.73C18 18.757 8 18.757 8 15.73V12Z"
        fill="#1767A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 9C10.0004 8.73725 9.94893 8.477 9.84856 8.23417C9.74819 7.99134 9.60089 7.7707 9.41509 7.58491C9.2293 7.39911 9.00866 7.25181 8.76583 7.15144C8.523 7.05107 8.26275 6.99961 8 7H2C0.887 7 0 7.897 0 9.003V12.829C0 14.75 4.054 15.347 7 14.813V12.006C6.99934 11.6118 7.07635 11.2214 7.22662 10.857C7.37689 10.4925 7.59748 10.1613 7.87579 9.88215C8.1541 9.60301 8.48468 9.38143 8.84864 9.23006C9.2126 9.0787 9.60282 9.00052 9.997 9H10Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default Admin;
