import React from "react";
import ott_tracker from "../../assets/ott_tracker.png";
import parks_associates from "../../assets/parks-associates-Teallogo.png";
import OTTImage from "../../assets/PdfFront.png";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";

const FrontPage = () => {
  const styles = StyleSheet.create({
    frontpage_container: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      fontFamily: "Poppins",
    },
    frontpage_header: {
      width: "100vw",
      height: "80px",
      backgroundColor: "#EDF6F7",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: "10px",
    },
    frontpage_header_img: {
      height: "40px",
      objectFit: "contain",
    },
    fontpage_text_container: {
      width: "80vw",
      textAlign: "center",
      fontSize: "8px",
      padding: "10px",
    },
    frontpage_text: {
      marginTop: "15px",
      fontWeight: "semibold",
    },
    frontpage_img_container: {
      width: "100vw",
      height: "100%",
      overflow: "hidden",
    },
    frontpage_img: {
      width: "100vw",
      height: "100%",
      objectFit: "fill",
      position: "absolute",
      bottom: 30,
    },
    frontpage_fullwidth_text_container: {
      width: "100vw",
      height: "100%",
      position: "absolute",
      top: 0,
      display: "flex",
      alignItems: "center",
    },
    frontpage_disclaimer_container: {
      width: "100vw",
      position: "absolute",
      bottom: 0,
      backgroundColor: "#EDF6F7",
      paddingHorizontal: "8px",
      paddingVertical: "5px",
    },
    frontpage_disclaimer_bold: {
      fontSize: "7px",
      fontWeight: "bold",
    },
    frontpage_disclaimer_normal: {
      fontSize: "7px",
      marginTop: "2px",
      marginBottom: "5px",
    },
    frontpage_notes_bold: {
      fontSize: "6px",
      fontWeight: "semibold",
      marginBottom: "4px",
    },
    frontpage_notes_normal: {
      fontSize: "6px",
    },
  });

  return (
    <View wrap={false} style={styles.frontpage_container}>
      <View style={styles.frontpage_header}>
        <Image src={ott_tracker} style={styles.frontpage_header_img} />
        <Image src={parks_associates} style={styles.frontpage_header_img} />
      </View>
      <View style={styles.frontpage_img_container}>
        <Image src={OTTImage} style={styles.frontpage_img} />
        <View style={styles.frontpage_fullwidth_text_container}>
          <View style={styles.fontpage_text_container}>
            <Text style={styles.frontpage_text}>
              The Streaming Video Tracker is an exhaustive analysis of market
              trends and profiles for 400+ over-the-top video services in the US
              and Canada. It estimates subscribers, viewers and transactional
              users for dozens of OTT services, including those that do not
              publicly release customer figures.
            </Text>
            <Text style={styles.frontpage_text}>
              This research helps subscribers understand the vast landscape of
              OTT video service providers, with insights on business models, new
              services, global expansion, and growth of individual services.
              Monthly market updates providing analysis on the most important
              announcements in the OTT market complements the data provided in
              this portal.
            </Text>
          </View>
          <View style={styles.frontpage_disclaimer_container}>
            <Text style={styles.frontpage_disclaimer_bold}>Disclaimer</Text>
            <Text style={styles.frontpage_disclaimer_normal}>
              Parks Associates has made every reasonable effort to ensure that
              all information in this document is correct. We assume no
              responsibility for any inadvertent errors.
            </Text>
            <Text style={styles.frontpage_notes_bold}>Methodology notes:</Text>
            <View style={styles.frontpage_notes_normal}>
              <Text>
                The Streaming Video Tracker provides estimates for the number
                of users and subscribers for OTT video services in North
                America, along with various other relevant business information.
                Parks Associates has created a sizing model for each service
                that is assessed and adjusted each quarter with input from the
                following types of data:
              </Text>
              <Text>
                1) Reported figures – Provides a definitive measure at a point
                in time that can be used to frame future estimates.
              </Text>
              <Text>
                2) Survey data – This data (from a sample of 5,000-10,000 US
                Household respondents) shows the percentage of broadband
                households that subscribe, view, or purchase content from
                various OTT services. Parks Associates has survey questions to
                tease out the uptake and use of various business models. This
                data allows us to compare use and spending across services.
              </Text>
              <Text>
                3) Online and mobile data – This data includes website traffic,
                mobile app downloads, unique visitors, bounce rate, time spent
                on the site, traffic ranking relative to other sites, and other
                factors. Parks Associates has been using various sources since
                2014 for this traffic data and continuously assesses new traffic
                data sources that emerge.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default FrontPage;
