import moment from "moment";
import React from "react";
import {
  calculateDatePadding,
  getArrayOfdays,
} from "../utils/DateHelpers";

const useCalenderControl = ({
  selectedMonth,
  selectedYear,
  setSelectedMonth,
  setSelectedYear,
}) => {
  const setToPrevMonth = (e) => {
    e.stopPropagation()
    const prevMonth = (((selectedMonth - 1) % 12) + 12) % 12;
    setSelectedMonth(prevMonth);
    if (!selectedMonth) setSelectedYear(selectedYear - 1);
  };

  const setToNextMonth = (e) => {
    e.stopPropagation()
    const nextMonth = (selectedMonth + 1) % 12;
    setSelectedMonth(nextMonth);
    if (selectedMonth === 11) setSelectedYear(selectedYear + 1);
  };

  const setToPrevYear = (e) => {
    e.stopPropagation()
    setSelectedYear(selectedYear - 1);
  };

  const setToNextYear = (e) => {
    e.stopPropagation()
    setSelectedYear(selectedYear + 1);
  };

  const getDate = (day) => {
    const date = new Date()
    date.setDate(day)
    date.setMonth(selectedMonth)
    date.setFullYear(selectedYear)
    return moment(date).format("YYYY-MM-DD")
  }

  const getDaysArray = () => {
    const selectedDate = new Date();
    selectedDate.setDate(1)
    selectedDate.setMonth(selectedMonth);
    selectedDate.setFullYear(selectedYear);
    const prevMonth = (((selectedMonth - 1) % 12) + 12) % 12;
    const prevMonthYear = prevMonth === 11 ? selectedYear - 1 : selectedYear;
    const padding = calculateDatePadding(selectedDate);
    const monthDays = getArrayOfdays(selectedMonth + 1, selectedYear);
    const prevMonthDays = getArrayOfdays(prevMonth + 1, prevMonthYear);
    const showableDates = padding
      ? prevMonthDays.slice(-padding).concat(monthDays)
      : monthDays;
    const nextMonthDays = Array.from({
      length: showableDates.length % 7 ? 7 - (showableDates.length % 7) : 0,
    }).map((_, id) => id + 1);
    return [
      padding ? prevMonthDays.slice(-padding) : [],
      monthDays,
      nextMonthDays,
    ];
  };

  const getDaysOfYearArray = () => {
    const yearDays = Array.from({ length: 12 }).map((_, id) => {
      const month = id + 1;
      const selectedDate = new Date();
      selectedDate.setDate(1)
      selectedDate.setMonth(id);
      selectedDate.setFullYear(selectedYear);
      const prevMonth = id === 1 ? 12 : id;
      const prevMonthYear = id === 1 ? selectedYear - 1 : selectedYear;
      let padding = calculateDatePadding(selectedDate);
      const monthDays = getArrayOfdays(month, selectedYear);
      const prevMonthDays = getArrayOfdays(prevMonth, prevMonthYear);
      const showableDates = padding
        ? prevMonthDays.slice(-padding).concat(monthDays)
        : monthDays;
      const nextMonthDays = Array.from({
        length: showableDates.length % 7 ? 7 - (showableDates.length % 7) : 0,
      }).map((_, id) => id + 1);
      return [
        padding ? prevMonthDays.slice(-padding) : [],
        monthDays,
        nextMonthDays,
      ];
    });
    return yearDays
  };

  const checkIfToday = (selectedDate) => {
    const today = new Date()
    const sameDate = selectedDate.getDate() === today.getDate()
    const sameMonth = selectedDate.getMonth() === today.getMonth()
    const sameYear = selectedDate.getYear() === today.getYear()
    return sameDate && sameMonth && sameYear
  }

  return {
    setToNextMonth,
    setToPrevMonth,
    setToNextYear,
    setToPrevYear,
    getDaysArray,
    getDaysOfYearArray,
    getDate,
    checkIfToday
  };
};

export default useCalenderControl;
