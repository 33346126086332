import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import World from "../../assets/World";
import { CountryObectAtom } from "../../atom";
import { formatToViewable } from "../../utils/formatConfig";

const Countries = ({ detail, setPdfShowableCountries }) => {
  const countriesObject = useRecoilValue(CountryObectAtom);
  const [showableCountries, setShowableCoutries] = useState({});

  useEffect(() => {
    if (detail.countries) {
      const arr = detail.countries.existing.concat(detail.countries.new || []);
      console.log(countriesObject, arr, "countries")
      const settable = formatToViewable(countriesObject, arr);
      setShowableCoutries(settable);
      setPdfShowableCountries(settable)
    }
  }, [detail, countriesObject]);

  return (
    <div className="w-full my-10 rounded-2xl shadow-md border border-pot-grey-4/10">
      <header className="border-b flex px-4 py-2 ">
        <World />
        <p className="text-pot-dark-blue font-semibold ml-3">Countries</p>
      </header>
      <section className="w-full py-5 px-4 text-xs space-y-4 whitespace-pre-wrap">
        <ul className="px-4">
          {Object.keys(showableCountries).map((item) => (
            <li key={item} className="mb-1 list-disc">
              <div className="flex">
                <p className="w-[12vw] font-semibold">{item}</p>
                <p className="text-pot-grey-3">{showableCountries[item].join(", ")}</p>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Countries;
