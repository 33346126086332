import React, { useState } from "react";
import { LeftArrow, RightArrow } from "../../assets/Arrows";
import { getAllQuarterBetween } from "../../utils/helperFunc";

const QuadrantRangePicker = ({
  setOpenDropdown,
  setSelectedQuater,
  setOpenRangeDropdown,
  selectedQuarterEnd,
  setSelectedQuarterEnd,
  selectedQuarterStart,
  setSelectedQuarterStart,
  setQuarterStart,
  setQuarterEnd,
  customQuarterRange,
}) => {
  const quarterRange = customQuarterRange;
  const quarterMin = quarterRange.min.split(" ").map((each, index) => {
    if (index === 0 && each.startsWith("Q")) {
      return parseInt(each.slice(1));
    }
    return parseInt(each);
  });
  const quarterMax = quarterRange.max.split(" ").map((each, index) => {
    if (index === 0 && each.startsWith("Q")) {
      return parseInt(each.slice(1));
    }
    return parseInt(each);
  });
  const allQuarter = getAllQuarterBetween(
    [Number(quarterMin[0]), quarterMin[1]],
    [Number(quarterMax[0]) - 1, quarterMax[1]],
    true
  );
  const [yearListStart, setYearListStart] = useState(quarterMax[1] - 3);
  const [yearListEnd, setYearListEnd] = useState(quarterMax[1]);
  const [clickType, setClickType] = useState("start");

  return (
    <div className="absolute w-fit h-fit z-50 text-xs rounded-md bg-white border shadow-md py-2 right-0 ">
      <div className="p-2 flex w-full justify-between gap-x-6 border-b">
        <div className="flex gap-x-2">
          <input
            value={
              selectedQuarterStart
                ? `Q${[
                    Number(selectedQuarterStart[0]) + 1,
                    selectedQuarterStart[1],
                  ]?.join(" ")}`
                : ""
            }
            className="w-20 outline-none border focus:bg-pot-bg-blue px-2"
          />
          {/* <LeftWardArrow /> */}
          <input
            value={
              selectedQuarterEnd
                ? `Q${[
                    Number(selectedQuarterEnd[0]) + 1,
                    selectedQuarterEnd[1],
                  ]?.join(" ")}`
                : ""
            }
            className="w-20 outline-none border focus:bg-pot-bg-blue px-2"
          />
        </div>
        <div className="flex gap-x-2">
          <button
            onClick={() => {
              setSelectedQuater(4);
              setOpenDropdown(false);
              setOpenRangeDropdown(false);
              setSelectedQuarterEnd(null);
              setSelectedQuarterStart(null);
            }}
            className="w-16 py-1 rounded text-pot-t-grey bg-pot-grey"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setOpenDropdown(false);
              setQuarterEnd(selectedQuarterEnd.map((each) => parseInt(each)));
              setQuarterStart(
                selectedQuarterStart.map((each) => parseInt(each))
              );
              setOpenRangeDropdown(false);
            }}
            className="w-16 py-1 rounded text-white bg-pot-blue"
          >
            Set Date
          </button>
        </div>
      </div>
      <div className="flex w-full justify-between mt-3 px-2">
        <button
          onClick={() => {
            if (yearListStart <= quarterMin[1]) return;
            setYearListEnd(yearListStart - 1);
            setYearListStart(
              yearListStart - quarterMin[1] > 4
                ? yearListStart - 4
                : quarterMin[1]
            );
          }}
          className="px-2 py-1 hover:bg-pot-grey"
        >
          <LeftArrow />
        </button>
        <p>
          {yearListStart} - {yearListEnd}
        </p>
        <button
          onClick={() => {
            if (yearListEnd >= quarterMax[1]) return;
            setYearListEnd(
              quarterMin[1] - yearListEnd < 4 ? yearListEnd + 4 : quarterMax[1]
            );
            setYearListStart(yearListEnd + 1);
          }}
          className="px-2 py-1 hover:bg-pot-grey"
        >
          <RightArrow />
        </button>
      </div>
      <div className="grid grid-cols-4 my-3 mx-4">
        {Array.from({ length: yearListEnd - yearListStart + 1 }).map(
          (_, yearOffset) =>
            Array.from({ length: 4 }).map((_, quad) => {
              const val = [quad, yearListStart + yearOffset].join(" ");
              const indexOfEndQ = allQuarter[0].indexOf(
                selectedQuarterEnd?.join(" ")
              );
              const indexOfStartQ = allQuarter[0].indexOf(
                selectedQuarterStart?.join(" ")
              );
              const indexOfVal = allQuarter[0].indexOf(val);

              return (
                <button
                  onClick={() => {
                    if (allQuarter[1].includes(val)) return;
                    if (clickType === "end" && indexOfStartQ > indexOfVal) {
                      setSelectedQuarterStart(val.split(" "));
                    } else if (
                      clickType === "start" &&
                      indexOfEndQ >= 0 &&
                      indexOfEndQ < indexOfVal
                    ) {
                      setSelectedQuarterEnd(val.split(" "));
                    } else if (clickType === "start") {
                      setSelectedQuarterStart(val.split(" "));
                      setClickType("end");
                    } else if (clickType === "end") {
                      setSelectedQuarterEnd(val.split(" "));
                      setClickType("start");
                    }
                  }}
                  className={`flex w-full justify-center py-2 ${
                    allQuarter[1].includes(val) && "text-pot-grey-3"
                  } ${
                    selectedQuarterEnd?.join(" ") === val &&
                    "bg-pot-blue text-white rounded-r-md"
                  } ${
                    selectedQuarterStart?.join(" ") === val &&
                    "bg-pot-blue text-white rounded-l-md"
                  } ${
                    indexOfStartQ < indexOfVal &&
                    indexOfVal < indexOfEndQ &&
                    "bg-pot-grey-2"
                  }`}
                >
                  Q{quad + 1} {yearListStart + yearOffset}
                </button>
              );
            })
        )}
      </div>
    </div>
  );
};

export default QuadrantRangePicker;
