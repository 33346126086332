import { authenticatedAxiosInstance as axios } from "./axios";

const show = (id) => axios.get(`/ott-profile/${id}/`)
const config = (params) => axios.get(`/ott-profile/config/`, params)
const list = (payload, params) => axios.post(`/ott-profile/search/`, payload, params)
const download = (payload, params) => axios.post(`/ott-profile/search/`, payload, {...params, responseType: "blob"})
const subData = (payload, params) => axios.post(`/ott-profile/sub/`, payload, params)
const platform = (payload, params = {}) => axios.post(`/ott-profile/compare/platform/`, payload, params)
const downloadPlatform = (payload, params = {} ) => axios.post(`/ott-profile/compare/platform/`, payload, { params: { download: 1, ...params }, responseType: "blob"},)

export const OttDataApi = {
    show,
    config,
    list,
    subData,
    download,
    platform,
    downloadPlatform
}