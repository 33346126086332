import { MacScrollbar } from "mac-scrollbar";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AccountUserAtom, titleAtom } from "../../atom";
import { debounce } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { titles } from "../../utils/Titles";
import SearchIcon from "@atlaskit/icon/glyph/search";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import EmailIcon from "@atlaskit/icon/glyph/email";
import Table from "./Table";

const EmailTemplate = () => {
  const navigate = useNavigate();
  const setTitle = useSetRecoilState(titleAtom);
  const account = useRecoilValue(AccountUserAtom);
  const [searchValue, setSearchValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [createPermission, setCreatePermission] = useState(true);
  const [editPermission, setEditPermission] = useState(true);
  const [deletePermission, setDeletePermission] = useState(true);
  const [viewPermission, setViewPermission] = useState(true);

  useEffect(() => {
    if (account) {
      if (!account?.user_permissions.includes("a_create_email_template")) {
        setCreatePermission(false);
      }
      if (!account?.user_permissions.includes("a_modify_email_template")) {
        setEditPermission(false);
      }
      if (!account?.user_permissions.includes("a_delete_email_template")) {
        setDeletePermission(false);
      }
      if (!account?.user_permissions.includes("a_view_email_template")) {
        setViewPermission(false);
      }
    }
  }, [account]);

  const optimisedSearch = useCallback(
    debounce((val) => setSearchValue(val), 600),
    []
  );

  useEffect(() => {
    setTitle("Email Template");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
  }, []);

  useEffect(() => {
    optimisedSearch(inputValue);
  }, [inputValue]);

  return (
    <MacScrollbar>
      <section className="w-full 2xl:flex 2xl:flex-col 2xl:h-[90vh] 2xl:justify-evenly 2xl:overflow-y-auto flex flex-col justify-start">
        <div className="w-full px-5 flex justify-end gap-x-4 py-2">
          <div className="border border-pot-bg-blue px-2 py-1 flex justify-center text-xs rounded text-pot-blue bg-pot-bg-blue/90">
            <div className="translate-y-[0.1rem]">
              <SearchIcon size="small" primaryColor="#1767A5" />
            </div>
            <input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Search"
              className="outline-none px-2 w-56 bg-transparent placeholder:text-pot-blue"
            />
            <button
              onClick={() => setInputValue("")}
              className="translate-y-0.5"
            >
              <CrossIcon size="small" primaryColor="#1767A5" />
            </button>
          </div>
          {/* TRUE JUST FOR TESTING PURPOSE NEED TO DELETE THE UNNECESSARY LINES */}
          {createPermission && (
            <button
              onClick={() => navigate("/admin/email-template/create")}
              className="bg-pot-bg-blue/90 text-pot-blue flex text-sm items-center gap-2 px-4 py-1 rounded-md"
            >
              <EmailIcon />
              <p>Create A Template</p>
            </button>
          )}
        </div>
        {!viewPermission ? (
          <div className="flex flex-col w-full h-full items-center justify-center">
            <p className="text-pot-dark-green font-semibold mb-10">
              You are not allowed to view this page
            </p>
          </div>
        ) : (
          <Table
            search={searchValue}
            editPermission={editPermission}
            deletePermission={deletePermission}
          />
        )}
      </section>
    </MacScrollbar>
  );
};

export default EmailTemplate;
