import React, { useState } from "react";
import useCalenderControl from "../../hooks/useCalenderControl";
import DayHeader from "../DateRangePicker/DayHeader";
import MonthView from "../DateRangePicker/MonthView";
import PickerControl from "../DateRangePicker/PickerControl";
import YearView from "../DateRangePicker/YearView";
import DateView from "./DateView";

const DateContainer = ({ date, selectedDate, setSelectedDate }) => {
  const [monthView, setMonthView] = useState(false);
  const [yearView, setYearView] = useState(false);
  const [yearOffset, setYearOffset] = useState(0);
  const {
    setToNextMonth,
    setToPrevMonth,
    getDaysArray,
    setToNextYear,
    setToPrevYear,
  } = useCalenderControl({
    selectedMonth: date.month,
    setSelectedMonth: date.setMonth,
    selectedYear: date.year,
    setSelectedYear: date.setYear,
  });
  return (
    <div className=" text-sm">
      <PickerControl
        date={date}
        setToNextYear={setToNextYear}
        setToPrevYear={setToPrevYear}
        setToNextMonth={setToNextMonth}
        setToPrevMonth={setToPrevMonth}
        monthView={monthView}
        setMonthView={setMonthView}
        yearView={yearView}
        setYearView={setYearView}
        yearOffset={yearOffset}
        setYearOffset={setYearOffset}
      />
      {yearView ? (
        <YearView
          yearOffset={yearOffset}
          date={date}
          selectedYear={selectedDate.getFullYear()}
          setYearView={setYearView}
        />
      ) : monthView ? (
        <MonthView
          month={selectedDate.getMonth()}
          setMonth={date.setMonth}
          setMonthView={setMonthView}
          sameYear={selectedDate.getFullYear() === date.year}
        />
      ) : (
        <>
          <DayHeader bold />
          <DateView 
            getDaysArray={getDaysArray}
            date={date}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </>
      )}
    </div>
  );
};

export default DateContainer;
