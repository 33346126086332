import React, { useEffect } from "react";

const Button = ({
  text,
  onClick = () => {},
  className = "",
  primary = false,
  large = false,
  disabled = false,
  simple = false,
  fixed = false,
  ternery = false,
}) => {
  return (
    <button
      onClick={!disabled && onClick}
      className={`${
        !primary
          ? ternery
            ? "bg-pot-grey-4"
            : "bg-pot-grey-4/80 text-pot-grey-3"
          : "bg-gradient-to-r from-pot-dark-blue via-pot-blue-2 to-pot-dark-blue bg-[length:300%]"
      } ${fixed && "w-40"}  text-white ${
        disabled && " cursor-default opacity-40"
      } ${
        large
          ? "font-semibold text-base px-5 py-2 rounded-lg"
          : simple
          ? "px-3 py-1 text-sm rounded"
          : "px-4 py-2 font-medium rounded-lg"
      } mr-5 ${className}`}
    >
      {text}
    </button>
  );
};

export default Button;
