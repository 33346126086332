// COMMENTED OUT the options which are not needed right now

export const toolbarOptions = {
  options: [
    "inline",
    // "blockType", // THIS IS TO BE IMPLEMETED LATER (H1- H6 IMPLEMENTATION IN THE EDITOR WITH FONT STYLES)
    // "fontSize",
    "list",
    // "textAlign",
    // "colorPicker",
    "link",
    "emoji",
    "history",
  ],
  inline: {
    options: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      // "superscript",
      // "subscript",
    ],
  },
  // fontSize: {
  //   options: [8, 9, 10, 11, 12, 14, 18, 24, 30],
  // },
  // fontFamily: {
  //   options: [],
  //   className: undefined,
  //   component: undefined,
  //   dropdownClassName: "hidden",
  // },
};
