import React from "react";

const FavouriteChartIcon = (size = "22") => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1663 11.9163V8.24967C20.1663 3.66634 18.333 1.83301 13.7497 1.83301H8.24967C3.66634 1.83301 1.83301 3.66634 1.83301 8.24967V13.7497C1.83301 18.333 3.66634 20.1663 8.24967 20.1663H11.9163"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.71875 13.2828L8.90042 10.4503C9.21209 10.0469 9.78959 9.97361 10.1929 10.2853L11.8704 11.6053C12.2738 11.9169 12.8512 11.8436 13.1629 11.4495L15.2804 8.71777"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8565 14.5013L18.1131 15.0238C18.2415 15.2805 18.5623 15.5188 18.8464 15.5738L19.1948 15.6288C20.2398 15.803 20.4873 16.573 19.7356 17.3338L19.4148 17.6546C19.2039 17.8746 19.0848 18.2963 19.149 18.5897L19.1948 18.7822C19.479 20.0472 18.8098 20.533 17.7098 19.873L17.4714 19.7355C17.1873 19.5705 16.729 19.5705 16.4448 19.7355L16.2064 19.873C15.0973 20.5421 14.4281 20.0472 14.7214 18.7822L14.7673 18.5897C14.8314 18.2963 14.7123 17.8746 14.5015 17.6546L14.1806 17.3338C13.429 16.573 13.6764 15.803 14.7214 15.6288L15.0698 15.5738C15.3448 15.528 15.6748 15.2805 15.8031 15.0238L16.0598 14.5013C16.5548 13.5022 17.3615 13.5022 17.8565 14.5013Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FavouriteChartIcon;
