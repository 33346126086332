import React, { useEffect, useState } from "react";
import InputField from "../../form/components/InputField";
import InputAreaField from "../../form/components/InputAreaField";
import ReCAPTCHA from "react-google-recaptcha";
import { feedback } from "../../api/core";
import { toast } from "react-toastify";
import Button from "../../form/components/Button";
import { getFromLocalStorage } from "../../utils/localStorage";

function FeedbackForm({
  formData = {},
  setFormData = () => {}
}) {
  const [error, setError] = useState({});
  const [isCheckboxChecked, setisCheckboxChecked] = useState(false);
  const [isRecaptchaChecked, setisRecaptchaChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  let user_id = getFromLocalStorage("user_id");
  let first_name = getFromLocalStorage("first_name");
  let last_name = getFromLocalStorage("last_name");
  let name;  
  if (first_name && last_name) {
    name = first_name + " " + last_name;
  } else if (first_name) {
    name = first_name;
  } else if (last_name) {
    name = last_name;
  } else {
    name = null;
  }
  let email = getFromLocalStorage("email");
  let organization_name = getFromLocalStorage("organization_name");

  const suggestChanges = {
    frame1: [
      {
        description: {
          heading: "Description",
          value: "description",
          placeholder: "Description",
        },
      },
      {
        ownership: {
          heading: "Ownership",
          value: "ownership",
          placeholder: "Eg. Netflix, Inc.",
        },
      },
      {
        launch_date: {
          heading: "Launch Date",
          value: "launch_date",
          placeholder: "Eg. 01-01-2000",
        },
      },
      {
        languages: {
          heading: "Languages",
          value: "languages",
          placeholder: "Eg. English, Spanish",
        },
      },
      {
        type_of_content: {
          heading: "Type of content",
          value: "type_of_content",
          placeholder: "Eg. Movies, TV shows",
        },
      },
      {
        genre_of_content: {
          heading: "Genre of content",
          value: "genre_of_content",
          placeholder: "Eg. Action, Thriller, Horror",
        },
      },
      {
        content_details: {
          heading: "Content Details",
          value: "content_details",
          placeholder: "Content Details",
        },
      },
      {
        target_viewers: {
          heading: "Target Viewers",
          value: "target_viewers",
          placeholder: "Eg. General",
        },
      },
      {
        business_model: {
          heading: "Business Model",
          value: "business_model",
          placeholder: "Eg. Subscription, Advertisement",
        },
      },
      {
        no_of_streams: {
          heading: "No. of Streams",
          value: "no_of_streams",
          placeholder: "No. of Streams",
        },
      },
      {
        size_of_library: {
          heading: "Size of Library",
          value: "size_of_library",
          placeholder: "Eg. 5000+ titles",
        },
      },
      {
        resolution: {
          heading: "Resolution",
          value: "resolution",
          placeholder: "Eg. SD, HD, 4K",
        },
      },
    ],
    frame2: [
      {
        has_downloadable_content: {
          heading: "Has Downloadable Content",
          value: "has_downloadable_content",
          placeholder: "Has Downloadable Content?",
        },
      },
      {
        has_original_content: {
          heading: "Has Original Content",
          value: "has_original_content",
          placeholder: "Has Original Content?",
        },
      },
      {
        has_live_content: {
          heading: "Has Live Content",
          value: "has_live_content",
          placeholder: "Has Live Content?",
        },
      },
      {
        has_free_trial: {
          heading: "Has Free Trial",
          value: "has_free_trial",
          placeholder: "Has Free Trial?",
        },
      },
      {
        distribution_type: {
          heading: "Distribution Type",
          value: "distribution_type",
          placeholder: "Eg. Standalone, Distributed",
        },
      },
      {
        subscribers_count: {
          heading: "Subscriber Count",
          value: "subscribers_count",
          placeholder: "Eg. 66.65M",
        },
      },
      {
        monthly_viewers_count: {
          heading: "Monthly Viewer Count",
          value: "monthly_viewers_count",
          placeholder: "Eg. 1.4M",
        },
      },
      {
        transactional_viewers_count: {
          heading: "Transactional Viewers Count",
          value: "transactional_viewers_count",
          placeholder: "Eg. 0.8M",
        },
      },
    ],
    frame3: [
      {
        countries: {
          heading: "Countries",
          value: "countries",
          placeholder: "Eg. US, Canada",
        },
      },
      {
        distribution_platforms: {
          heading: "Distribution Platforms",
          value: "distribution_platforms",
          placeholder: "Eg. Amazon channels, Apple TV+",
        },
      },
      {
        device_support: {
          heading: "Device Support",
          value: "device_support",
          placeholder: "Eg. Browser, PC",
        },
      },
    ],
    frame4: [
      {
        pricing: {
          heading: "Pricing",
          value: "pricing",
          placeholder: "Eg. 50k-500k",
        },
      },
      {
        service_features: {
          heading: "Service Features",
          value: "service_features",
          placeholder: "Service Features",
        },
      },
      {
        link_to_site: {
          heading: "Link to site",
          value: "link_to_site",
          placeholder: "Link to site",
        },
      },
    ],
  };

  const formChange = (formDataVar) => {
    // Function that structure as per API request body
    let structuredData = {
      user: formDataVar.user_id,
      name: formDataVar.name?.trim(),
      email: formDataVar.email?.trim(),
      company: formDataVar.company?.trim(),
      comment: formDataVar.comment?.trim(),
      other_content:{
        interested_in_buying: formDataVar.interested_in_buying?.trim(),
        company_details: formDataVar.company_details?.trim(),
        other_contacts: formDataVar.other_contacts?.trim(),
      },
      main_content:{
        profile_name: formDataVar.profile_name?.trim(),
        description: formDataVar.description?.trim(),
        ownership: formDataVar.ownership?.trim(),
        launch_date: formDataVar.launch_date?.trim(),
        languages: formDataVar.languages?.trim(),
        type_of_content: formDataVar.type_of_content?.trim(),
        genre_of_content: formDataVar.genre_of_content?.trim(),
        content_details: formDataVar.content_details?.trim(),
        target_viewers: formDataVar.target_viewers?.trim(),
        business_model: formDataVar.business_model?.trim(),
        no_of_streams: formDataVar.no_of_streams?.trim(),
        size_of_library: formDataVar.size_of_library?.trim(),
        resolution: formDataVar.resolution?.trim(),
        has_downloadable_content: formDataVar.has_downloadable_content?.trim(),
        has_original_content: formDataVar.has_original_content?.trim(),
        has_live_content: formDataVar.has_live_content?.trim(),
        has_free_trial: formDataVar.has_free_trial?.trim(),
        distribution_type: formDataVar.distribution_type?.trim(),
        subscribers_count: formDataVar.subscribers_count?.trim(),
        monthly_viewers_count: formDataVar.monthly_viewers_count?.trim(),
        transactional_viewers_count: formDataVar.transactional_viewers_count?.trim(),
        countries: formDataVar.countries?.trim(),
        distribution_platforms: formDataVar.distribution_platforms?.trim(),
        device_support: formDataVar.device_support?.trim(),
        pricing: formDataVar.pricing?.trim(),
        service_features: formDataVar.service_features?.trim(),
        link_to_site: formDataVar.link_to_site?.trim(),
      },
      g_recaptcha_response: formDataVar.g_recaptcha_response,
    };
    return structuredData;
  };


  const handleSubmit = async () => {
    setLoading(true)
    if (isCheckboxChecked === false) return;
    if (isRecaptchaChecked === false) return;
    
    const payload = formChange(formData);
    const { data: response } = await feedback(payload);

    if (!response.success) {
      let errorObj = {};
      setLoading(false)
      response.errors.error_message.forEach((each) => {
        errorObj = { ...errorObj, ...each };
        if (each.name || each.email || each.company) {
          toast.error("Please fill all required information");
          return;
        }
        if (each.non_field_errors) {
          toast.error(each.non_field_errors[0]);
          return;
        }
        if (each.g_recaptcha_response) {
          toast.error("Please complete reCAPTCHA");
          return;
        }
      });
      setError(errorObj);
    } else {
      toast.success("Your feedback is received!");
      initialData()
    }    
    setLoading(false)
    return;
  };

  const initialData = () => {
    setFormData((prevFormData) => ({
      // ...prevFormData,
      user: user_id,
      name: name,
      email: email,
      company: organization_name,
    }));
  }

  // Do this on first load
  useEffect(() => {
    initialData()
  }, []);

  return (
    <div className="flex flex-col items-center w-full ">
      <div className="my-[1rem] w-[90%] p-[2rem] shadow-md border opacity-90 hover:opacity-100 transition ease-in-out duration-500 rounded-lg">
        <h2 className="text-3xl">Personal Details</h2>
        <div className="md:flex flex-wrap md:flex-no-wrap justify-between">
          <InputField
            heading={"Name"}
            className={"mt-5 min-w-[250px] md:w-[25vw] text-sm"}
            formData={formData}
            setFormData={setFormData}
            value="name"
            placeholder={"Enter your name"}
            error={error}
            setError={setError}
            required
          />
          <InputField
            heading={"Company Name"}
            className={"mt-5 min-w-[250px] md:w-[25vw] text-sm"}
            formData={formData}
            setFormData={setFormData}
            value="company"
            placeholder={"Enter your company name"}
            error={error}
            setError={setError}
            required
          />
          <InputField
            heading={"E-mail address"}
            className={"mt-5 min-w-[250px] md:w-[25vw] text-sm"}
            formData={formData}
            setFormData={setFormData}
            value="email"
            placeholder={"eg. johndoe@example.com"}
            error={error}
            setError={setError}
            required
          />
        </div>
        <div className="md:flex flex-wrap md:flex-no-wrap xl:flex-wrap justify-between">
          <InputAreaField
            heading={"Are you interested in buying this product"}
            className={"mt-5 min-w-[250px] md:w-[25vw] text-sm"}
            formData={formData}
            setFormData={setFormData}
            value="interested_in_buying"
            placeholder={"Are you interested in buying this product"}
            error={error}
            setError={setError}
          />
          <InputAreaField
            heading={"Tell us more about your company"}
            className={"mt-5 min-w-[250px] md:w-[25vw] text-sm"}
            formData={formData}
            setFormData={setFormData}
            value="company_details"
            placeholder={"Tell us more about your company"}
            error={error}
            setError={setError}
          />
          <InputAreaField
            heading={"Other Contacts from your company"}
            className={"mt-5 min-w-[250px] md:w-[25vw] text-sm"}
            formData={formData}
            setFormData={setFormData}
            value="other_contacts"
            placeholder={
              "Details of concerned person from your company to be contacted for this product"
            }
            error={error}
            setError={setError}
          />
        </div>
      </div>
      <div className="my-[1rem] w-[90%]  p-[2rem] shadow-md border opacity-90 hover:opacity-100 transition ease-in-out duration-500 rounded-lg">
        <h2 className="text-3xl">Suggest Changes</h2>
        <div className="md:flex flex-wrap md:flex-no-wrap xl:flex-wrap justify-between">
          <InputField
            heading={"Profile Name"}
            className={"mt-5 min-w-[250px] md:w-[25vw] xl:w-[20vw] text-sm pr-2"}
            formData={formData}
            setFormData={setFormData}
            value="profile_name"
            placeholder={"Eg. Netflix"}
            error={error}
            setError={setError}
          />
        </div>
        <div className="md:flex flex-wrap md:flex-no-wrap xl:flex-wrap justify-between">
          {suggestChanges.frame1.map((field, index) => (
            <InputAreaField
              heading={Object.values(field)[0].heading}
              className={"mt-5 min-w-[250px] md:w-[25vw] xl:w-[20vw] text-sm pr-2"}
              formData={formData}
              setFormData={setFormData}
              value={Object.values(field)[0].value}
              placeholder={Object.values(field)[0].placeholder}
              error={error}
              setError={setError}
            />
          ))}
        </div>
        <div className="md:flex flex-wrap md:flex-no-wrap xl:flex-wrap justify-between mt-7">
          {suggestChanges.frame2.map((field, index) => (
            <InputAreaField
              heading={Object.values(field)[0].heading}
              className={"mt-5 min-w-[250px] md:w-[25vw] xl:w-[20vw] text-sm pr-2"} // + (index === suggestChanges.frame2.length - 1 ? " flex-grow" : "")
              formData={formData}
              setFormData={setFormData}
              value={Object.values(field)[0].value}
              placeholder={Object.values(field)[0].placeholder}
              error={error}
              setError={setError}
            />
          ))}
          <div className="mt-5 min-w-[250px] md:w-[25vw] xl:w-[20vw] text-sm pr-2"></div>
        </div>
        <div className="md:flex flex-wrap md:flex-no-wrap xl:flex-wrap justify-between mt-7">
          {suggestChanges.frame3.map((field) => (
            <InputAreaField
              heading={Object.values(field)[0].heading}
              className={"mt-5 min-w-[250px] md:w-[25vw] xl:w-[20vw] text-sm pr-2"}
              formData={formData}
              setFormData={setFormData}
              value={Object.values(field)[0].value}
              placeholder={Object.values(field)[0].placeholder}
              error={error}
              setError={setError}
            />
          ))}
          <div className="mt-5 min-w-[250px] md:w-[25vw] xl:w-[20vw] text-sm pr-2"></div>
        </div>
        <div className="md:flex flex-wrap md:flex-no-wrap xl:flex-wrap justify-between mt-7">
          {suggestChanges.frame4.map((field) => (
            <InputAreaField
              heading={Object.values(field)[0].heading}
              className={"mt-5 min-w-[250px] md:w-[25vw] xl:w-[20vw] text-sm pr-2"}
              formData={formData}
              setFormData={setFormData}
              value={Object.values(field)[0].value}
              placeholder={Object.values(field)[0].placeholder}
              error={error}
              setError={setError}
            />
          ))}
          <div className="mt-5 min-w-[250px] md:w-[25vw] xl:w-[20vw] text-sm pr-2"></div>
        </div>
        <div className="md:flex flex-wrap md:flex-no-wrap justify-between">
          <InputAreaField
            heading={"Additional Feedback"}
            className={"mt-5 md:w-full text-sm"}
            formData={formData}
            setFormData={setFormData}
            value="comment"
            parent="main_content"
            placeholder={"Do you have any additional feedback?"}
            error={error}
            setError={setError}
          />
        </div>
      </div>
      <div className="w-[90%] opacity-90 hover:opacity-100 transition ease-in-out duration-500 rounded-lg">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_G_RECAPTCHA_SITE_KEY}
          onChange={(value) => {
            setFormData({ ...formData, g_recaptcha_response: value });
            if (value) {
              setisRecaptchaChecked(true)
            } else {
              setisRecaptchaChecked(false)
            }
          }}
          className="mt-1"
        />
      </div>
      <div className="mt-2 w-[90%] opacity-90 hover:opacity-100 transition ease-in-out duration-500 rounded-lg cursor-pointer"
        onClick={(value) => {
          setisCheckboxChecked(!isCheckboxChecked);
        }}
      >
        <input type="checkbox" checked={isCheckboxChecked} required />{" "}
        <span className="ml-2 ">
          I am authorized to give feedback on behalf of my company
        </span>
      </div>
      <div className="my-1 w-[90%] opacity-90 hover:opacity-100 transition ease-in-out duration-500 rounded-lg">
        <Button
          text="Submit"
          disabled={!isCheckboxChecked || !isRecaptchaChecked || loading}
          onClick={handleSubmit}
          className={`my-[1rem] ${
            isCheckboxChecked ? "cursor-pointer" : "cursor-not-allowed bg-slate-500"
          }`}
          primary={isCheckboxChecked || !isRecaptchaChecked}
        />
      </div>
    </div>
  );
}

export default FeedbackForm;
