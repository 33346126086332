import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../../atom";
import { titles } from "../../../utils/Titles";
import { MacScrollbar } from "mac-scrollbar";
import Table from "./Table";
import Dropzone from "./Dropzone";
import Download from "./Download";

const NPSUploadData = () => {
  const setTitle = useSetRecoilState(titleAtom);
  const [callAgain, setCallAgain] = useState(0);

  useEffect(() => {
    setTitle("Upload NPS & Churn Data");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
  }, []);

  return (
    <section
      className={`w-full flex flex-col 2xl:h-[90vh] 2xl:justify-evenly 2xl:overflow-y-auto
        }`}
    >
      <Download />
      <Dropzone
        hasTable={true}
        hasDownload={true}
        callAgain={callAgain}
        setCallAgain={setCallAgain}
      />
      <Table
        height={"h-[63vh]"}
        hasDropzone={true}
        callAgain={callAgain}
        setCallAgain={setCallAgain}
      />
    </section>
  );
};

export default NPSUploadData;
