import React, { useState } from "react";
import { toast } from "react-toastify";
import { DataImportApi } from "../../../api/dataImport";
import { DotPulse } from "@uiball/loaders";
import DownloadIcon from "../../../assets/DownloadIcon";
import { NPSChurnImport } from "../../../api/npschurn";
import { formatDateToMMDDYY } from "../../../utils/DateHelpers";

export const GreenLoadingButton = () => {
  return (
    <button className="bg-pot-dark-green/10 w-[184px] py-[12px] flex justify-center rounded-md">
      <DotPulse size={20} color="#0097A7" speed={0.8} />
    </button>
  );
};

const Download = () => {
  const [loading, setLoading] = useState("");

  const downloadData = async (type) => {
    try {
      setLoading(type);
      const response = await NPSChurnImport[type]();
      if (response.status !== 200) {
        const errorString = await response.data.text();
        const errorObj = JSON.parse(errorString);
        toast.error(errorObj.errors.error_message.join(", "));
        setLoading("");
        return;
      }
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.target = "_blank";
      const date = new Date();
      const formattedDate = formatDateToMMDDYY(date, "-");
      const formattedName = `NPS & Churn Prefilled (${formattedDate})`;
      link.setAttribute("download", `${formattedName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLoading("");
    } catch (e) {
      console.log("error :", e);
      toast.error(JSON.stringify(e));
      setLoading("");
    }
  };
  return (
    <div className="w-full px-10 min-h-[8vh] flex justify-end items-center gap-3">
      {loading === "downloadPrefilled" ? (
        <GreenLoadingButton />
      ) : (
        <button
          onClick={() => downloadData("downloadPrefilled")}
          className="bg-pot-bg-blue/90 h-fit text-pot-blue flex text-sm items-center gap-2 px-4 py-1 rounded-md"
        >
          <DownloadIcon />
          Download Prefilled
        </button>
      )}
    </div>
  );
};

export default Download;
