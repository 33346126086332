import React, { memo, useEffect, useRef } from "react";
import { useReactTable, getCoreRowModel } from "@tanstack/react-table";
import TableLayoutOne from "../../components/TableLayout/One";
import useTrendsCols from "../../hooks/useTrendsCols";
// import { ClipLoader } from "react-spinners";
import { DotPulse } from "@uiball/loaders";

const TrendTable = ({
  quarterStart,
  quarterEnd,
  subsData,
  page,
  setPage,
  totalNames,
  selectedOtts,
  setSelectedOtts,
  sortBy,
  setSortBy,
  lastRecord,
  isLoading
}) => {
  // const quadData = Array.from({length: 20}).reduce((acc, _, index) => {
  //     let key = `Q${index+1}`
  //     acc[key] = index % 2 === 0 ? 20 : index % 3 === 0 ? "na" : -20
  //     return acc
  //   }, {})
  // const data = Array.from({length: 30}).map(() => ({name: "Netflix", ...quadData}))
  const tableRef = useRef();
  const colDef = useTrendsCols(
    subsData,
    quarterEnd,
    quarterStart,
    selectedOtts,
    setSelectedOtts,
    sortBy,
    setSortBy
  );

  const table = useReactTable({
    data: subsData,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
    enablePinning: true,
    initialState: { columnPinning: { left: ["name"] } },
  });

  const scrollHandle = (e) => {
    if (isLoading) return; // Prevent scrolling while loading

    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (
      scrollTop + clientHeight >= scrollHeight &&
      (page + 1) * 20 < totalNames &&
      !lastRecord
    ) {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (!subsData.length) return;
    // if(tableRef.current) {
    //   tableRef.current.scrollLeft += 5000

    // }
    const id = setTimeout(() => {
      if (tableRef.current) {
        tableRef.current.scrollLeft += tableRef.current.scrollWidth;
        console.log(
          "Inside the scroll",
          tableRef.current.scrollLeft,
          tableRef.current.scrollWidth
        );
      }
    }, 100);
    return () => clearTimeout(id);
  }, [subsData]);

  return (
    <div className="flex">
      <div
        ref={tableRef}
        onScroll={scrollHandle}
        className="w-full h-[50vh] overflow-auto text-sm"
      >
        <TableLayoutOne table={table} />
        {!lastRecord && (
          <div className="w-full flex justify-center py-3">
            <DotPulse size={20} color="#1767A5" speed={0.8} />
          </div>
        )}
      </div>
      <div className="flex justify-center items-center h-[50vh] pl-1 bg-white border-l text-xs font-semibold">
        <p style={{ writingMode: "vertical-rl" }}>© Parks Associates</p>
      </div>
    </div>
  );
};

export default memo(TrendTable);
