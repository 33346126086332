export const RightArrow = () => (
  <svg width="5" height="8" viewBox="0 0 5 8" fill="none">
    <path
      d="M0.75 0.75L4.25 4L0.75 7.25"
      stroke="#19181A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LeftArrow = () => (
  <svg width="5" height="8" viewBox="0 0 5 8" fill="none">
    <path
      d="M4.25 0.75L0.75 4L4.25 7.25"
      stroke="#19181A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LeftWardArrow = () => {
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.73755 5.01245L12.2375 5.01245M7.76245 9.26245L12.2625 5.01245L7.76245 0.762451"
      stroke="#19181A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>;
};

export const DownArrow = () => {
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292068 0.292941C0.105262 0.481833 0.000488281 0.736777 0.000488281 1.00244C0.000488281 1.2681 0.105262 1.52305 0.292068 1.71194L3.23107 4.67694C3.44907 4.89194 3.73107 4.99894 4.01007 4.99894C4.28907 4.99894 4.56607 4.89194 4.77907 4.67694L7.70907 1.72194C7.89563 1.53292 8.00024 1.27803 8.00024 1.01244C8.00024 0.746856 7.89563 0.49196 7.70907 0.302941C7.61724 0.209757 7.5078 0.135759 7.38711 0.0852509C7.26642 0.0347429 7.1369 0.0087328 7.00607 0.0087328C6.87524 0.0087328 6.74571 0.0347429 6.62503 0.0852509C6.50434 0.135759 6.3949 0.209757 6.30307 0.302941L4.00507 2.61994L1.69807 0.292941C1.60603 0.20012 1.49652 0.126444 1.37586 0.0761652C1.25521 0.0258864 1.12578 0 0.995069 0C0.864353 0 0.734931 0.0258864 0.614272 0.0761652C0.493613 0.126444 0.384105 0.20012 0.292068 0.292941Z"
      fill="#434343"
    />
  </svg>;
};

export const StyledDownArrow = () => {
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.364964 0.866176C0.131455 1.10229 0.000488281 1.42097 0.000488281 1.75305C0.000488281 2.08513 0.131455 2.40381 0.364964 2.63993L4.03871 6.34618C4.31121 6.61492 4.66371 6.74867 5.01246 6.74867C5.36121 6.74867 5.70746 6.61492 5.97371 6.34618L9.63621 2.65243C9.86942 2.41615 10.0002 2.09753 10.0002 1.76555C10.0002 1.43357 9.86942 1.11495 9.63621 0.878677C9.52142 0.762196 9.38462 0.669699 9.23376 0.606564C9.08291 0.543429 8.921 0.510916 8.75746 0.510916C8.59393 0.510916 8.43202 0.543429 8.28116 0.606564C8.1303 0.669699 7.9935 0.762196 7.87871 0.878677L5.00621 3.77493L2.12246 0.866176C2.00742 0.75015 1.87053 0.658055 1.71971 0.595206C1.56889 0.532358 1.40711 0.5 1.24371 0.5C1.08032 0.5 0.918542 0.532358 0.767718 0.595206C0.616894 0.658055 0.48001 0.75015 0.364964 0.866176Z"
      fill="#00838F"
    />
  </svg>;
};

export const StyledLeftArrow = ({ color }) => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 2L1.5 7L6.5 12"
        stroke={color}
        strokeWidth="3"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const StyledRightArrow = ({ color }) => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 12L6.5 7L1.5 2"
      stroke={color}
      strokeWidth="3"
      strokeLinejoin="round"
      strokeLinejoin="round"
    />
  </svg>
);
