import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { DotPulse } from "@uiball/loaders";
import React from "react";
import { useRecoilValue } from "recoil";
import { ResizingAtom } from "../../atom";
import TableLayoutOne from "../../components/TableLayout/One";
import useTopActiveUsersCols from "../../hooks/useTopActiveUsersCols";
import { useExportChart } from "../../hooks/useExportChart";

const ActiveUsersTable = ({ fetch, setters = [] }) => {
  const {
    data = [],
    colDef,
    loading,
  } = useTopActiveUsersCols({ fetch, setters });
  const resizingLoading = useRecoilValue(ResizingAtom);
  const { ExportButton, headerRef, titleStyles } = useExportChart();

  const table = useReactTable({
    data: data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <div className="relative">
      <div
        ref={headerRef}
        className={`${titleStyles} flex justify-between items-center pr-4`}
      >
        <p className="text-sm text-pot-dark-blue font-semibold px-4 pt-4 pb-3">
          Top 10 Active Users
        </p>
        <ExportButton id="top-active-users" title="Top 10 Active Users" />
      </div>
      <div
        id="chart-top-active-users"
        className="w-full self-stretch border rounded-md shadow-md text-xs"
      >
        <p
          style={{ height: headerRef.current?.clientHeight }}
          className="text-sm text-pot-dark-blue font-semibold px-4 pt-4 pb-3"
        >
          Top 10 Active Users
        </p>
        <p className="text-xs font-regular px-4 pt-0 pb-[26px]">
          Top 10 active users ranked by login frequency within the specified
          date range
        </p>
        <div className="w-full mb-1">
          <TableLayoutOne table={table} moreBorder small />
        </div>
        {!resizingLoading &&
          (loading ? (
            <div className="w-full  flex justify-center items-center">
              <DotPulse color="#1767A5" speed={0.8} size={28} />
            </div>
          ) : (
            !data.length && (
              <div className="w-full min-h-[40vh] flex justify-center items-center font-semibold text-lg text-pot-grey-4">
                No Data
              </div>
            )
          ))}
      </div>
    </div>
  );
};

export default ActiveUsersTable;
