import React, { useEffect, useState } from 'react'

const useMouseLocation = (isOverEndRegion= false) => {
    const [mouseLocation, setMouseLocation] = useState(null)

    const handleMouseMove = (e) => {
        setMouseLocation(e.clientX)
    }

    const checkIfAtLastPart = (val) => {
        const totalWidth = window.innerWidth;
        const widthDiff = totalWidth - val
        const widthDiffPercent = ((widthDiff/totalWidth) * 100)

        console.log(widthDiffPercent, "percent")

        return(widthDiffPercent <= 40)
    }

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove)
        return(() => window.removeEventListener("mousemove", handleMouseMove))
    }, [])

  return (isOverEndRegion ? checkIfAtLastPart(mouseLocation) : mouseLocation )
}

export default useMouseLocation