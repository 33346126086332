import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ColumnKeyValueAtom } from "../atom";

const useCompareViewData = (comparedHeaders, comparedValues) => {
  const initialCompareViewData = useRecoilValue(ColumnKeyValueAtom);

  // const initialCompareViewData = [
  //     {
  //         features: "Launch Date"
  //     },
  //     {
  //         features: "Business Model"
  //     },
  //     {
  //         features: "Free Trial"
  //     },
  //     {
  //         features: "Pricing"
  //     },
  //     {
  //         features: "Distribution Type"
  //     },
  //     {
  //         features: "Subscribers"
  //     },
  //     {
  //         features: "Countries"
  //     },
  //     {
  //         features: "Platforms"
  //     },
  //     {
  //         features: "Languages"
  //     },
  //     {
  //         features: "Orginal Content"
  //     },
  //     {
  //         features: "Genre"
  //     },
  //     {
  //         features: "Target Market"
  //     },
  //     {
  //         features: "Subscriber Growth"
  //     },
  // ]

  const [compareViewData, setCompareViewData] = useState(
    Object.keys(initialCompareViewData).map((each) => ({
      mainVal: each,
      features:
        each === "pricing"
          ? "Pricing (base plan/month)"
          : initialCompareViewData[each],
    }))
  );

  // useEffect(() => {
  //     if(!comparedHeaders) return
  //     const settableCompareData = compareViewData.map((each) => ({...each, ...comparedHeaders}))
  //     setCompareViewData(settableCompareData)
  // }, [comparedHeaders])
  useEffect(() => {
    if (!comparedValues) return;
    const settableCompareData = compareViewData.map((each) => {
      const label = each.mainVal;
      const settable = Object.keys(comparedValues).reduce((acc, cur) => {
        return { ...acc, [cur]: comparedValues[cur]?.[label] };
      }, {});
      return { ...each, ...settable };
    });
    setCompareViewData(settableCompareData);
  }, [comparedValues]);
  // console.log(compareViewData, 'compareViewData');
  return compareViewData;
};

export default useCompareViewData;
