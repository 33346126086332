import React from "react";

const OpenEye = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.83301 10.0007C1.83301 10.0007 5.16634 3.33398 10.9997 3.33398C16.833 3.33398 20.1663 10.0007 20.1663 10.0007C20.1663 10.0007 16.833 16.6673 10.9997 16.6673C5.16634 16.6673 1.83301 10.0007 1.83301 10.0007Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 12.5C12.3807 12.5 13.5 11.3807 13.5 10C13.5 8.61929 12.3807 7.5 11 7.5C9.61929 7.5 8.5 8.61929 8.5 10C8.5 11.3807 9.61929 12.5 11 12.5Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OpenEye;
