import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";

const DistributionType = ({ detail }) => {
  const styles = StyleSheet.create({
    container: {
      width: "100%",
      marginLeft: "10px",
      borderRadius: "8px",
      border: 1,
      borderColor: "#F0F1F8",
      display: "flex",
      justifyContent: "space-around",
      paddingVertical: "5px",
      paddingHorizontal: "8px",
    },
    title: {
      fontWeight: "semibold",
    },
    value: {
      fontSize: "11px",
      fontWeight: "bold",
      color: "#1767A5",
    },
  });

  return (
    <View wrap={false} style={styles.container}>
      <Text style={styles.title}>Distribution Type</Text>
      <Text style={styles.value}>
        {detail.distribution_types?.existing
          ?.concat(detail.distribution_types?.new)
          ?.join("\n")}
      </Text>
    </View>
  );
};

export default DistributionType;
