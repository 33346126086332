import React, { useCallback, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SearchIcon from "@atlaskit/icon/glyph/search";
import UserAvatarCircleIcon from "@atlaskit/icon/glyph/user-avatar-circle";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import Lock from "../assets/Lock";
import Logout from "../assets/Logout";
import SwitcherIcon from "@atlaskit/icon/glyph/switcher";
import { useLocation, useNavigate } from "react-router-dom";
import { MacScrollbar } from "mac-scrollbar";
import {
  AccountUserAtom,
  AllFilterOptionsAtom,
  AppInfoAtom,
  LoadingProgressAtom,
  ProfileCountryAtom,
  SelectedProfileCountryAtom,
  titleAtom,
} from "../atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { delay } from "../utils/helperFunc";
import { AccountApi } from "../api/account";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { formatNamesToObj } from "../utils/formatConfig";
import { debounce } from "lodash";
import Danger from "../assets/Danger";
import { getFromLocalStorage, setToLocalStorage } from "../utils/localStorage";
import useOnClickOutside from "../hooks/useClickOutside";
import useSearchTelemetry from "../hooks/useSearchTelemetry";
import { dateDifference } from "../utils/DateHelpers";
import moment from "moment";
import Location from "../assets/Location";
import { DownArrow, StyledDownArrow, StyledLeftArrow } from "../assets/Arrows";
import Walkthrough from "../assets/Walkthrough";

const Topbar = ({
  initial,
  showSearch,
  target,
  startWalkThrough,
  setStartWalkThrough,
}) => {
  const allOptions = useRecoilValue(AllFilterOptionsAtom);
  const [openProfileLocation, setOpenProfileLocation] = useState(false);
  const [openAuthActions, setOpenAuthActions] = useState(false);
  const title = useRecoilValue(titleAtom);
  const profileCountries = useRecoilValue(ProfileCountryAtom);
  const [selectedProfileCountry, setSelectedProfileCountry] = useRecoilState(
    SelectedProfileCountryAtom
  );
  const [inputValue, setInputValue] = useState("");
  const [progress, setProgress] = useRecoilState(LoadingProgressAtom);
  const [appInfoData, setAppInfoData] = useRecoilState(AppInfoAtom);
  const [user, setUser] = useRecoilState(AccountUserAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const [allNamesObj, setAllNamesObj] = useState(null);
  const [filteredValues, setFilteredValues] = useState(null);
  const [count, setCount] = useState(0);
  const [placeholderVal, setPlaceholderVal] = useState("");
  const isAdmin = getFromLocalStorage("user_type")?.toUpperCase() === "ADMIN";
  const isCustomer = getFromLocalStorage("user_type")
    ?.toUpperCase()
    .includes("CUSTOMER");
  const profileRef = useRef(null);
  const profileCountryRef = useRef(null);
  useOnClickOutside(profileCountryRef, () => setOpenProfileLocation(false));
  useOnClickOutside(profileRef, () => setOpenAuthActions(false));
  const setSearchTelemetryId = useSearchTelemetry();
  const [plan, setPlan] = useState({});
  const notNPSandChurnPages =
    location.pathname !== "/nps" && location.pathname !== "/churn";

  const checkProgress = async (progress) => {
    if (progress === 100) {
      await delay(300);
      setProgress(0);
    }
  };

  const fetchAccountInfo = async () => {
    const { data: response } = await AccountApi.info();
    setUser(response.data.user);

    // PLAN_VERSION: Uncomment the below code < Line 62 - 68 > - DONE
    if (isCustomer) {
      if (!response.data.plan) {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
    setPlan(response.data.plan || {});

    // Adding required user information into localstorage
    setToLocalStorage(
      null,
      null,
      null,
      response.data.user.id ?? "",
      response.data.user.first_name ?? "",
      response.data.user.last_name ?? "",
      response.data.user.email ?? "",
      response.data.user.organization?.name ?? ""
    );
  };

  const optimisedFilters = useCallback(
    debounce(() => {
      if (inputValue) {
        const val1 = Object.keys(allNamesObj)?.filter((each) =>
          each.toLowerCase().startsWith(inputValue.toLowerCase())
        );
        const val2 = Object.keys(allNamesObj)?.filter(
          (each) =>
            (each.toLowerCase().includes(inputValue.toLowerCase()) &&
              !each.toLowerCase().startsWith(inputValue.toLowerCase())) ||
            (each.toLowerCase() == "prime video" &&
              inputValue.toLowerCase().startsWith("ama")) //Note: This is hardcoded as per client's request. They wanted amazon to show prime video in suggestion
        );
        setFilteredValues([...val1, ...val2]);
      } else {
        setFilteredValues(null);
      }
    }, 300),
    [inputValue, allNamesObj]
  );

  useEffect(() => {
    checkProgress(progress);
  }, [progress]);

  useEffect(() => {
    fetchAccountInfo();
  }, []);

  useEffect(() => {
    if (allOptions.names) {
      const names = formatNamesToObj(allOptions.names);
      setAllNamesObj(names);
    }
  }, [allOptions]);

  useEffect(() => {
    let interval;
    if (allNamesObj) {
      interval = setInterval(() => {
        setCount((prev) => {
          return (prev + 1) % 6;
        });
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [allNamesObj]);

  useEffect(() => {
    optimisedFilters();
  }, [inputValue, allNamesObj]);

  useEffect(() => {
    if (count) {
      const allNames = Object.keys(allNamesObj);
      setPlaceholderVal(allNames[Math.floor(Math.random() * allNames.length)]);
    } else {
      setPlaceholderVal("");
    }
  }, [count]);

  return (
    <motion.div
      initial={{ y: "-100%" }}
      animate={{ y: 0 }}
      transition={{ duration: 0.4 }}
      className="h-12 sticky top-0 z-[97] bg-pot-background-grey-2"
    >
      <section className="h-12 bg-pot-background-grey-2 border-b flex justify-between items-center px-5">
        <div className="w-full max-w-[28vw] flex items-center justify-between pr-3">
          <p className="font-bold ">{title}</p>
          {/* DROPDOWN */}
          {!window.location.pathname.includes("admin") &&
            location.pathname !== "/contact" &&
            location.pathname !== "/faq" &&
            notNPSandChurnPages && (
              <div
                ref={profileCountryRef}
                className={`${
                  openProfileLocation ||
                  (startWalkThrough && target === ".step_0")
                    ? "mt-[81px]"
                    : "mt-0"
                } max-h-max relative flex flex-col step_0`}
              >
                <button
                  onClick={() => setOpenProfileLocation((prev) => !prev)}
                  className={`min-w-[144px] flex items-center gap-x-2 py-1 px-2 shadow-[0_0_2px_2px_#1767A580] active:shadow-[0_0_2px_1px_#1767A580] transition ease-in-out rounded-md text-sm text-pot-blue font-medium`}
                >
                  <div className="mb-0.5">
                    <Location />
                  </div>
                  <div className="flex justify-between w-full">
                    {profileCountries?.[selectedProfileCountry]}
                    <motion.div
                      initial={{ rotate: -90, scale: 0.7 }}
                      animate={{
                        rotate:
                          openProfileLocation ||
                          (startWalkThrough && target === ".step_0")
                            ? 90
                            : -90,
                        scale: 0.7,
                      }}
                    >
                      <StyledLeftArrow color="#1767A5" />
                    </motion.div>
                  </div>
                </button>
                <div
                  className={`
                ${
                  openProfileLocation ||
                  (startWalkThrough && target === ".step_0")
                    ? " mt-2"
                    : "hidden"
                } w-fit min-w-full shadow-lg bg-white rounded-md flex flex-col divide-y`}
                >
                  {Object.keys(profileCountries).map((country) => (
                    <button
                      onClick={() => {
                        setSelectedProfileCountry(country);
                        setOpenProfileLocation(false);
                      }}
                      className="text-left whitespace-nowrap px-4 py-2 w-full text-black text-sm"
                    >
                      {profileCountries[country]}
                    </button>
                  ))}
                </div>
              </div>
            )}
        </div>
        {location.pathname !== "/contact" &&
          location.pathname !== "/faq" &&
          location.pathname !== "/search" &&
          showSearch && (
            <div className="min-w-[28vw] z-[97] relative border bg-white rounded flex items-center px-2 py-1">
              {!inputValue && (
                <AnimatePresence>
                  <motion.div
                    key={placeholderVal}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="pointer-events-none absolute ml-6 text-xs text-pot-grey-4 h-full flex items-center"
                  >
                    {placeholderVal
                      ? "Try Searching ' " + placeholderVal + " '"
                      : "Search Any Service"}
                  </motion.div>
                </AnimatePresence>
              )}
              <SearchIcon size="small" />
              <input
                autocomplete="off"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && filteredValues.length === 1) {
                    setInputValue("");
                    navigate(`/profile/${allNamesObj[filteredValues[0]]}`);
                  } else if (e.key === "Enter" && !filteredValues.length) {
                    setInputValue("");
                  }
                }}
                // placeholder="Search"
                className="outline-none ml-2  text-sm w-full"
              />
              {inputValue && (
                <button onClick={() => setInputValue("")}>
                  <CrossIcon size="small" />
                </button>
              )}
              {inputValue && allNamesObj && (
                <div className="absolute top-10 left-0 py-1 shadow-md border w-full z-[97] bg-white rounded-md text-xs">
                  <MacScrollbar>
                    <div className="max-h-[30vh] overflow-y-auto">
                      {filteredValues && !filteredValues.length ? (
                        <div className="px-4 py-2 flex justify-between items-center">
                          <div className="flex gap-x-4 items-center">
                            <Danger />
                            <p>
                              No profiles found for
                              <span className="italic">'{inputValue}'</span>
                            </p>
                          </div>
                          <button onClick={() => setInputValue("")}>
                            <CrossIcon size="small" />
                          </button>
                        </div>
                      ) : (
                        filteredValues?.map((each) => (
                          <button
                            onClick={() => {
                              setInputValue("");
                              setSearchTelemetryId(allNamesObj[each]);
                              navigate(`/profile/${allNamesObj[each]}`);
                            }}
                            className="w-full text-left px-3 py-2 border-l-2 border-white transition ease-out hover:bg-pot-grey-4/20 hover:border-l-2 hover:border-pot-dark-blue"
                          >
                            {each}
                          </button>
                        ))
                      )}
                    </div>
                  </MacScrollbar>
                </div>
              )}
            </div>
          )}
        <div ref={profileRef} className="relative h-10 min-w-[15rem] w-full">
          <div
            className={`flex ${
              window.location.pathname.includes("/admin")
                ? "justify-end"
                : "justify-end"
            } gap-x-2 items-center divide-x`}
          >
            {!window.location.pathname.includes("/admin") && plan.name && (
              <p className="text-pot-blue text-xs whitespace-nowrap font-semibold mt-0.5">
                {plan.name}
                {/* Hello */}
              </p>
            )}
            {/* <div className="text-pot-text-grey text-xs whitespace-nowrap mt-0.5 text-center pl-2">
              <p>Last Data Updated</p>
              <p>
                {appInfoData?.last_update_at
                  ? moment(appInfoData?.last_update_at).format("MM-DD-YYYY")
                  : "-"}
              </p>
            </div> */}
            <div
              onClick={() => setOpenAuthActions((prev) => !prev)}
              className="h-full flex items-center cursor-pointer pl-2"
            >
              <UserAvatarCircleIcon size="large" primaryColor="#1767A5" />
              <div className="ml-2 leading-3 flex flex-col gap-y=2">
                <div className="flex items-center">
                  <p className="font-medium text-sm mr-1 whitespace-nowrap">
                    {(user?.first_name || "") + " " + (user?.last_name || "")}
                  </p>
                  <motion.div
                    animate={openAuthActions ? { rotate: 180 } : { rotate: 0 }}
                    className="mt-[1px]"
                  >
                    <HipchatChevronDownIcon size="small" />
                  </motion.div>
                </div>
                <p className="text-xs text-pot-grey-3 whitespace-nowrap">
                  {user?.organization?.name || ""}
                </p>
              </div>
            </div>
          </div>
          {openAuthActions && (
            <div className="absolute top-12 bg-white shadow-lg border border-pot-grey-4/10 rounded-lg right-0 w-60 h-fit z-[60] p-2">
              {isAdmin && (
                <button
                  onClick={() => {
                    if (window.location.pathname.includes("/admin")) {
                      const loggedInAlways =
                        localStorage.getItem("loggedInAlways");
                      if (!loggedInAlways) {
                        localStorage.setItem("noChange", true);
                      }
                      window.location.href = "/";
                    } else {
                      const loggedInAlways =
                        localStorage.getItem("loggedInAlways");
                      if (!loggedInAlways) {
                        localStorage.setItem("noChange", true);
                      }
                      window.location.href = "/admin";
                    }
                    setOpenAuthActions(false);
                  }}
                  className="text-tiny flex items-center mt-2 w-full pb-2 pl-1 border-b"
                >
                  <SwitcherIcon size="small" primaryColor="#1767A5" />
                  <p className="text-tiny ml-4">
                    Switch to{" "}
                    {window.location.pathname.includes("/admin")
                      ? "Customer"
                      : "Admin"}
                  </p>
                </button>
              )}
              <button
                onClick={() => {
                  navigate("/change-password");
                  setOpenAuthActions(false);
                }}
                className="flex items-center stroke-pot-blue ml-1 mt-1"
              >
                <Lock />
                <p className="text-tiny ml-4 mt-1 mb-2">Change Password</p>
              </button>
              {!location.pathname.startsWith("/admin") && (
                <button
                  onClick={() => {
                    location.pathname !== "/dashboard" &&
                      navigate("/dashboard");
                    setStartWalkThrough(true);
                    setOpenAuthActions(false);
                  }}
                  className="flex items-center stroke-pot-blue ml-1 mt-1"
                >
                  <Walkthrough />
                  <p className="text-tiny ml-4 mt-1 mb-2">Tracker Guide</p>
                </button>
              )}
              <button
                onClick={() => {
                  localStorage.removeItem("token");
                  localStorage.removeItem("user_type");
                  localStorage.removeItem("tabsOpen");
                  localStorage.removeItem("noChange");
                  localStorage.removeItem("loggedInAlways");
                  localStorage.removeItem("first_name");
                  localStorage.removeItem("last_name");
                  localStorage.removeItem("email");
                  localStorage.removeItem("organization_name");
                  window.location.href = "/login";
                  setOpenAuthActions(false);
                }}
                className="flex items-center mt-1"
              >
                <Logout />
                <p className="text-tiny ml-4">Logout</p>
              </button>
              {!window.location.pathname.includes("/admin") &&
                (plan.name || plan.expiry_date) && (
                  <div className="border-t pt-2 mt-2.5 text-xxs text-pot-grey-4 pl-4 cursor-default">
                    {"Total " +
                      (plan.expiry_date
                        ? `- ${plan.total_remaining_duration} ${
                            Number(plan.total_remaining_duration) === 1
                              ? "day"
                              : "days"
                          } left`
                        : "")}
                  </div>
                )}
            </div>
          )}
        </div>
      </section>
      <div className="w-full h-[3px] relative z-50">
        {progress && !initial ? (
          <motion.div
            className="h-[3px] bg-pot-dark-blue rounded-r"
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            tranistion={{ delay: initial ? 0.4 : 0 }}
          ></motion.div>
        ) : (
          <></>
        )}
      </div>
    </motion.div>
  );
};

export default Topbar;
