import React from "react";

const AuthHeader = ({ heading, description, newPage }) => {
  return (
    <header className={`${newPage ? "mb-[3vh] mt-[1vh]" : "mb-16"}`}>
      <p className="w-full font-bold text-lg 2xl:text-xl">{heading}</p>
      {!newPage && <p className="w-full min-h-4 text-xs 2xl:text-sm text-gray-500 mt-1">
        {description}
      </p>}
    </header>
  );
};

export default AuthHeader;
