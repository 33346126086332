import React, { memo, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";
// import { ClipLoader } from "react-spinners";
import { DotPulse } from "@uiball/loaders";
import { useRecoilValue } from "recoil";
import { ResizingAtom } from "../../atom";

const TrendBarChart = ({
  selectedBarQuarter,
  chartLoading,
  selectedOtts,
  barData,
  type,
  page = "viewerstrend",
}) => {
  // const data = names.map((name) => ({
  //   name,
  //   value: Math.ceil(Math.random() * 60),
  // }));
  const containerResizing = useRecoilValue(ResizingAtom);

  if (chartLoading || containerResizing) {
    return (
      <div className="w-full flex justify-center h-[350px] items-center">
        <DotPulse size={40} color="#1767A5" speed={0.8} />
      </div>
    );
  }

  // FOR YAxis Label depending upon the type and page
  const yAXisLabel =
    page === "nps"
      ? "NPS"
      : page === "churn"
      ? "Churn"
      : type === "PAID_SUBS"
      ? "Subscribers (M)"
      : type === "MONTHLY_VIEWERS"
      ? "Viewers (M)"
      : "Transactional Customers (M)";

  // FOR NPS AND CHURN PAGES
  const otts = selectedOtts?.map((ott) => ott?.name);
  const barColors = {};
  selectedOtts?.forEach((ott) => {
    barColors[ott?.name] = ott?.color;
  });

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        data={page === "viewerstrend" ? selectedOtts : barData}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 40,
        }}
        barCategoryGap={10} // Gap between groups of bars
        barGap={0} // Gap between bars in a group
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          label={{
            value: "Time",
            position: "insideBottom",
            offset: -20,
          }}
          dataKey="name"
          tick={CustomizedTick}
        />
        <YAxis
          label={{
            value: yAXisLabel,
            angle: -90,
            offset: 50,
            position: "insideRight",
          }}
        />
        <Tooltip
          formatter={(value, name) => {
            if (page === "viewerstrend")
              return [`${value.toFixed(1)} M`, `Q${name}`];
            if (page === "nps") return [`${Math.round(value)}`, `${name}`];
            return [`${Math.round(value)}%`, `${name}`];
          }}
          labelFormatter={(value) =>
            page === "viewerstrend" ? `${value}` : `Q${value}`
          }
          cursor={{ fill: "fff", fillOpacity: "0" }}
        />
        {page === "viewerstrend" && (
          <Bar
            type="monotone"
            dataKey={selectedBarQuarter}
            // fill={strokes[0]}
            barSize={20}
          >
            {selectedOtts.map((each, index) => (
              <Cell key={`cell-${index}`} fill={each.color} />
            ))}
          </Bar>
        )}
        {page !== "viewerstrend" && (
          <>
            {otts?.map((ott) => {
              return (
                <Bar
                  key={ott?.color}
                  type="monotone"
                  dataKey={ott}
                  // barSize={20}
                >
                  {barData?.map((each, index) => (
                    <Cell key={`cell-${index}`} fill={barColors[ott]} />
                  ))}
                </Bar>
              );
            })}
          </>
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};
const CustomizedTick = ({ x, y, payload }) => {
  const quarterNumber = parseInt(payload.value.split(" ")[0]);
  const quartersToHide = [2, 4];
  if (quartersToHide.includes(quarterNumber)) {
    return null;
  }
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={10}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(0)"
      >
        Q{payload.value}
      </text>
    </g>
  );
};
export default memo(TrendBarChart);
