import React from "react";

const Message = ({ fill }) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        d="M2.41667 2.58341V13.4167H17.5833V2.58341H2.41667ZM17.5833 0.416748C18.775 0.416748 19.75 1.39175 19.75 2.58341V13.4167C19.75 14.6084 18.775 15.5834 17.5833 15.5834H2.41667C1.225 15.5834 0.25 14.6084 0.25 13.4167V2.58341C0.25 1.39175 1.225 0.416748 2.41667 0.416748H17.5833Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.95617 2.04175H0.384333C0.54575 2.51841 0.816583 2.96691 1.19683 3.34716L7.569 9.71825C7.8826 10.0321 8.25498 10.2811 8.66485 10.451C9.07473 10.6209 9.51406 10.7083 9.95775 10.7083C10.4014 10.7083 10.8408 10.6209 11.2506 10.451C11.6605 10.2811 12.0329 10.0321 12.3465 9.71825L18.7187 3.34716C19.0978 2.96691 19.3687 2.51841 19.5301 2.04175H16.9593L10.8147 8.18641C10.7021 8.29906 10.5684 8.38842 10.4213 8.44939C10.2742 8.51036 10.1165 8.54174 9.95721 8.54174C9.79795 8.54174 9.64025 8.51036 9.49313 8.44939C9.346 8.38842 9.21233 8.29906 9.09975 8.18641L2.95617 2.04175Z"
        fill={fill}
      />
    </svg>
  );
};

export default Message;
