import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DataImportApi } from "../../api/dataImport";
import TableControl from "../../components/TableLayout/TableControl";
import TableLayoutTwo from "../../components/TableLayout/Two";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  AccountUserAtom,
  TableLoadingAtom,
  UploadVerifyIdAtom,
} from "../../atom";
import { useNavigate } from "react-router-dom";
import { MacScrollbar } from "mac-scrollbar";
import EditorPanelIcon from "@atlaskit/icon/glyph/editor/panel";
import { EmailAPI } from "../../api/email";
import { ModalTransition } from "@atlaskit/modal-dialog";
import EmailDetailsModal from "./EmailDetailsModal";

const Table = ({ height, render }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [length, setLength] = useState(10);
  const [colDef, setColDef] = useState([]);
  const account = useRecoilValue(AccountUserAtom);
  const setTableLoading = useSetRecoilState(TableLoadingAtom);
  const [openEmailDetails, setOpenEmailDetails] = useState();
  const [emailDetails, setEmailDetails] = useState();

  const initialColDef = [
    {
      header: "Date",
      width: "150px",
      accessorKey: "created_at",
      cell: ({ getValue }) =>
        getValue() && moment(getValue()).format("MM-DD-YYYY"),
    },
    {
      header: "Email Template",
      width: "150px",
      accessorKey: "email_template_name",
      cell: ({ getValue }) => getValue() || "-",
    },
    {
      header: () => (
        <div className="w-full flex justify-center items-center">
          <div className="w-56">Subject</div>
        </div>
      ),
      width: "150px",
      accessorKey: "subject",
      cell: ({ getValue }) => (
        <div className="w-full flex justify-center items-center">
          <div className="truncate">{getValue()}</div>
        </div>
      ),
    },
    {
      header: "Sent To",
      width: "150px",
      cell: ({ getValue, row }) => {
        return (
          <div className="flex justify-between">
            <div className="opacity-0 w-10"></div>
            <span>
              {row?.original?.count_sent_emails +
                " of " +
                row?.original?.count_total_emails}
            </span>
            <span
              className="cursor-pointer w-10"
              onClick={() => {
                setOpenEmailDetails(row?.original?.id);
                fetchEmailDetails(row?.original?.id);
              }}
            >
              <EditorPanelIcon primaryColor="rgb(100,100,100)" />
            </span>
          </div>
        );
      },
    },
    {
      header: "Delivery Status",
      width: "150px",
      accessorKey: "status",
      cell: ({ getValue }) => getValue().replace(/_/g, " "),
    },
  ];

  useEffect(() => {
    setColDef([...initialColDef]);
  }, [account]);

  const closePopup = () => {
    setOpenEmailDetails();
    setEmailDetails();
  };

  const fetchHistory = async () => {
    setTableLoading(true);
    const params = { page, length };
    const { data: response } = await EmailAPI.history({ params });
    if (page === 1) {
      const foundObj =
        response.data.results.find((each) => each.status === "SUCCESS") || {};
    }
    setData(response.data.results);
    setTotalCount(response.data.total_count);
    setTotalPages(response.data.total_pages);
    setTableLoading(false);
  };

  const fetchEmailDetails = async (id) => {
    const { data: response } = await EmailAPI.details(id);
    if (response.success) {
      setEmailDetails(response.data);
    }
  };

  useEffect(() => {
    // if (account?.user_permissions.includes("a_view_upload")) {
    fetchHistory();
    // }
  }, [page, length, account, render]);

  const table = useReactTable({
    data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
  });

  //   if (account && !account?.user_permissions.includes("a_view_upload")) {
  //     return (
  //       <div
  //         className={`w-full ${
  //           hasDropzone ? "h-[30vh]" : "h-[75vh] pb-[10vh]"
  //         } flex justify-center items-center font-bold text-pot-dark-green`}
  //       >
  //         You are not allowed to {!hasDropzone && "upload or"} view the upload
  //         history
  //       </div>
  //     );
  //   }

  return (
    <>
      <MacScrollbar>
        <div
          className={`w-full relative ${height} overflow-y-auto mt-5 flex flex-col justify-between`}
        >
          <TableLayoutTwo table={table} />
          <TableControl
            page={page}
            setPage={setPage}
            length={length}
            setLength={setLength}
            totalCount={totalCount}
            totalPages={totalPages}
          />
        </div>
        <ModalTransition>
          {openEmailDetails && (
            <EmailDetailsModal handleCancel={closePopup} data={emailDetails} />
          )}
        </ModalTransition>
      </MacScrollbar>
    </>
  );
};

export default Table;
