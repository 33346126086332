// import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'


const useTopFilterCols = ({ fetch = async () => {}, setters=[] }) => {
    const [ data, setData ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [selectedRegion, setSelectedRegion] = useState("US")

    const colDef = [
        {
            header: () => <p className="text-left font-medium">Rank</p>,
            accessorKey: "rank",
            size: 5,
        },
        {
            header: () => <p className="text-left font-medium">Filter</p>,
            accessorKey: "field_name",
            size: 100,
        },
        {
            header: () => <p className="text-left font-medium">Option</p>,
            accessorKey: "field_option",
            size: 100,
        },
        {
            header: () => <p className="text-left font-medium">Usage</p>,
            accessorKey: "count",
            size: 100,
        },
    ]

    // const { data } = useQuery({
    //     queryKey: ["TopFilters"],
    //     queryFn: () => fetch("TOP_10_SEARCH_FILTERS"),
    //     cacheTime: (1000 * 60 * 60 * 24)
    //   })

      const fetchSearchFilters = async () => {
        setData([])
        setLoading(true)
        const data = await fetch("TOP_10_SEARCH_FILTERS", selectedRegion)
        setData(data)
        setLoading(false)
      }
      
      useEffect(() => {
        fetchSearchFilters();
      }, [setters, selectedRegion])

    // console.log(topFilters, "TOP Filters")

    // const data = Array.from({ length: 10 }).map((_, id) => ({
    //     rank: id + 1,
    //     filter: "country",
    //     option: "Mexico",
    //     usage: 2933,
    // }))

    const settable = useMemo(() => ({ colDef, data, loading, selectedRegion, setSelectedRegion }), [colDef, data, loading, selectedRegion])

    return(settable)
}

export default useTopFilterCols