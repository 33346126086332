import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import Doc from "../../assets/Doc";
import RoundedFilledClose from "../../assets/RoundedFilledClose";
import UploadPlus from "../../assets/UploadPlus";
import { motion } from "framer-motion";
import axios from "axios";
import { BASE_URL } from "../../api/axios";
import { getFromLocalStorage } from "../../utils/localStorage";
import { toast } from "react-toastify";
import {
  DescriptionAtom,
  UploadVerifyIdAtom,
  VerificationResultAtom,
} from "../../atom";
import { useRecoilState, useSetRecoilState } from "recoil";

const Dropzone = ({
  hasTable,
  hasDownload,
  setUploadVerifyId,
  uploadVerifyId,
}) => {
  const [showUploadRegion, setShowUploadRegion] = useState(false);
  const [acceptedFileName, setAcceptedFileName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [canceled, setCanceled] = useState(false);
  const setUploadId = useSetRecoilState(UploadVerifyIdAtom);
  const [showToolTip, setShowToolTip] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState(null);
  const sourceRef = useRef(null);
  const [description, setDescription] = useRecoilState(DescriptionAtom);
  const [result, setVerificationResult] = useRecoilState(
    VerificationResultAtom
  );

  const uploadFile = async (accepted) => {
    console.log(accepted, "accepted");
    setShowToolTip(false);
    setAcceptedFileName(accepted.name);
    setUploadProgress(10);
    setShowUploadRegion(true);
    sourceRef.current = axios.CancelToken.source();
    const formData = new FormData();
    formData.append("file", accepted, accepted.name);
    setUploadProgress(30);
    try {
      const { data: response } = await axios.post(
        `/data-import/subs/`,
        formData,
        {
          baseURL: BASE_URL,
          cancelToken: sourceRef.current.token,
          headers: { Authorization: `Token ${getFromLocalStorage("token")}` },
        }
      );
      setUploadProgress(100);
      if (response.success) {
        setVerificationResult(response?.data?.status);
        setUploadVerifyId(response.data);
        setDescription(response.data?.errors_list);
      } else {
        toast.error("File Upload Error ! ");
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        setCanceled(false);
      } else {
        setUploadProgress(100);
        toast.error(
          "There was an unexpected error or the excel file is open in your device. Close the file and try again, or inform the developer team"
        );
        toast.error(
          e.response.data.errors.error_message || "File Upload Error ! "
        );
      }
      setShowUploadRegion(false);
      setAcceptedFile(null);
    }
  };

  // const cancelUploaded = async () => {
  //   const { data: response } = await DataImportApi.stop()
  //   if(!response.success) {
  //     Object.values(response.data).forEach(each => {
  //       toast.error(each.join(", "))
  //     })
  //   }
  // }

  const onDrop = useCallback(async (acceptedFiles) => {
    setShowToolTip(true);
    setAcceptedFile(acceptedFiles[0]);
    // setShowUploadRegion(true)
    await uploadFile(acceptedFiles[0]);
  }, []);

  console.log(acceptedFile);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    // clickable:'#dropzonePreview',
    noClick: true,
    onDrop,
    maxFiles: 1,
    multiple: false,
  });

  useEffect(() => {
    if (canceled) {
      setShowUploadRegion(false);
      setCanceled(false);
      return;
      // console.log(sourceRef.current);
      // sourceRef.current.cancel("canceled");
      // cancelUploaded()
    }
  }, [canceled]);

  useEffect(() => {
    if (uploadVerifyId) {
      setAcceptedFileName(null);
      setUploadProgress(0);
      setShowUploadRegion(false);
    }
  }, [uploadVerifyId]);

  return (
    <div className="relative w-full">
      {/* <UploadControls
        control={{ pickerOpen, setPickerOpen, quarterOpen, setQuarterOpen }}
        valueHandles={{ quarter, setQuarter, date, setDate }}
        showToolTip={showToolTip}
      /> */}
      {showUploadRegion ? (
        <div
          className={`${hasTable ? "h-[20vh]" : "h-[50vh]"} ${
            !hasDownload && "mt-10"
          } mx-10 border-4 border-slate-100 border-dashed rounded-lg flex flex-col gap-2 justify-center items-center`}
        >
          <div className="w-[30vw] flex justify-between gap-3">
            <Doc />
            <div className="w-full flex flex-col justify-between pb-2">
              <div className="w-full flex justify-between px-3 text-sm">
                <p className="w-60 truncate">{acceptedFileName}</p>
                <button onClick={() => setCanceled(true)}>
                  <RoundedFilledClose />
                </button>
              </div>
              <div className="w-full h-[3px] relative z-50 rounded-full mx-3 bg-pot-bg-grey-2">
                <motion.div
                  className="h-[3px] bg-pot-dark-blue rounded-full"
                  initial={{ width: 0 }}
                  animate={{ width: `${uploadProgress}%` }}
                ></motion.div>
              </div>
            </div>
          </div>
          {/* {showProceed ? (
            <button
              onClick={() => {
                //  navigate(`/admin/data/upload/verifying/${uploadVerifyId}`)
                setUploadId(uploadVerifyId);
              }}
              className={`bg-pot-dark-blue text-white px-4 py-2 font-semibold text-sm rounded-md `}
            >
              Proceed
            </button>
          ) : (
            <div className="h-12 w-12"></div>
          )} */}
        </div>
      ) : (
        <div
          {...getRootProps({})}
          className={`${hasTable ? "h-[20vh]" : "h-[50vh]"} ${
            !hasDownload && "mt-10"
          } cursor-pointer mx-10 border-4  ${
            isDragActive ? "border-pot-blue/400" : "border-slate-100 "
          }border-dashed cursor-default rounded-lg flex relative justify-center items-center`}
        >
          {/* <input {...getInputProps()} /> */}
          <div className="flex flex-col justify-center items-center">
            {/* <input {...getInputProps()}/> */}
            <div onClick={open} className="cursor-pointer">
              <UploadPlus />
            </div>
            {isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>Upload An Excel Sheets Here</p>
            )}
            <p className="w-[40vw] mt-4 text-center text-xs text-pot-grey-4">
              Ensure the format is the same as the example file and upload
              only.xls files.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropzone;
