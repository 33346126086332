import { authenticatedAxiosInstance as axios } from "./axios";

const create = (payload) => axios.post(`/user-manage/organization/`, payload);
const list = (params) => axios.get(`/user-manage/organization/`, params);
const show = (id) => axios.get(`/user-manage/organization/${id}/`);
const update = (id, payload) =>
  axios.patch(`/user-manage/organization/${id}/`, payload);
const toggle = (id, payload) =>
  axios.post(`/user-manage/organization/${id}/bulk-action/`, payload);

export const OrganizationApi = {
  create,
  list,
  show,
  update,
  toggle,
};
