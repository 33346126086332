import React, { useEffect, useMemo, useState } from "react";
// import { useQuery } from "@tanstack/react-query";

const useTopProfilesViewedCols = ({
  fetch = async (id) => {
    console.log(id, "NOT WORKING");
  },
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState("US")

  const colDef = [
    {
      header: () => <p className="text-left font-medium">Rank</p>,
      accessorKey: "rank",
      size: 5,
    },
    {
      header: () => <p className="text-left font-medium">OTT Name</p>,
      accessorKey: "name",
      size: 100,
    },
    // {
    //     header: () => <p className="text-left font-medium">Usage</p>,
    //     accessorKey: "option",
    //     size: 100,
    // },
    {
      header: () => <p className="text-left font-medium">Usage</p>,
      accessorKey: "count",
      size: 80,
    },
  ];

  // const { data } = useQuery({
  //     queryKey: ["TopProfileViewed"],
  //     queryFn: () => fetch("TOP_10_PROFILE_VIEWED"),
  //     cacheTime: (1000 * 60 * 60 * 24),
  //     initialData: []
  //   })

  const fetchProfileViewed = async () => {
    setData([])
    setLoading(true)
    const data = await fetch("TOP_10_PROFILE_VIEWED", selectedRegion);
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchProfileViewed();
  }, [selectedRegion]);

  // const data = Array.from({ length: 10 }).map((_, id) => ({
  //     rank: id + 1,
  //     ott: "Crunchyroll",
  //     // option: "Mexico",
  //     usage: 2933,
  // }))

  const settable = useMemo(() => ({ colDef, data, loading, selectedRegion, setSelectedRegion }), [colDef, data, loading, selectedRegion])

  return settable;
};
export default useTopProfilesViewedCols;
