import React from "react";

const BigRevert = () => {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.8531 1.42453C40.916 1.86499 41.8818 2.51056 42.6952 3.32437L69.6802 30.305C70.4946 31.1185 71.1408 32.0845 71.5816 33.1479C72.0225 34.2112 72.2494 35.3511 72.2494 36.5022C72.2494 37.6533 72.0225 38.7931 71.5816 39.8565C71.1408 40.9199 70.4946 41.8859 69.6802 42.6994L42.6952 69.68C41.8818 70.4938 40.916 71.1394 39.8531 71.5798C38.7901 72.0203 37.6508 72.247 36.5002 72.247C35.3496 72.247 34.2102 72.0203 33.1473 71.5798C32.0843 71.1394 31.1186 70.4938 30.3052 69.68L3.32017 42.6994C2.5057 41.8859 1.85957 40.9199 1.41873 39.8565C0.977886 38.7931 0.750977 37.6533 0.750977 36.5022C0.750977 35.3511 0.977886 34.2112 1.41873 33.1479C1.85957 32.0845 2.5057 31.1185 3.32017 30.305L30.3052 3.32437C31.1186 2.51056 32.0843 1.86499 33.1473 1.42453C34.2102 0.984073 35.3496 0.757368 36.5002 0.757368C37.6508 0.757368 38.7901 0.984073 39.8531 1.42453ZM33.4066 43.9686C34.227 44.789 35.3398 45.25 36.5002 45.25C37.6605 45.25 38.7733 44.789 39.5938 43.9686C40.4142 43.1481 40.8752 42.0353 40.8752 40.875V19C40.8752 17.8397 40.4142 16.7269 39.5938 15.9064C38.7733 15.0859 37.6605 14.625 36.5002 14.625C35.3398 14.625 34.227 15.0859 33.4066 15.9064C32.5861 16.7269 32.1252 17.8397 32.1252 19V40.875C32.1252 42.0353 32.5861 43.1481 33.4066 43.9686ZM33.4066 57.0936C34.227 57.914 35.3398 58.375 36.5002 58.375C37.6605 58.375 38.7733 57.914 39.5938 57.0936C40.4142 56.2731 40.8752 55.1603 40.8752 54C40.8752 52.8396 40.4142 51.7269 39.5938 50.9064C38.7733 50.0859 37.6605 49.625 36.5002 49.625C35.3398 49.625 34.227 50.0859 33.4066 50.9064C32.5861 51.7269 32.1252 52.8396 32.1252 54C32.1252 55.1603 32.5861 56.2731 33.4066 57.0936Z"
        fill="#C44B4B"
      />
    </svg>
  );
};

export default BigRevert;
