import React from "react";

const FilledRoundedWhiteClose = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8ZM11.7496 10.7701C11.7496 10.5103 11.6463 10.2612 11.4625 10.0775L9.385 8L11.4625 5.9225C11.5534 5.83156 11.6256 5.7236 11.6748 5.60478C11.724 5.48596 11.7493 5.35861 11.7493 5.23C11.7493 5.10139 11.724 4.97404 11.6748 4.85523C11.6256 4.73641 11.5534 4.62844 11.4625 4.5375C11.3716 4.44656 11.2636 4.37443 11.1448 4.32521C11.026 4.27599 10.8986 4.25066 10.77 4.25066C10.6414 4.25066 10.514 4.27599 10.3952 4.32521C10.2764 4.37443 10.1684 4.44656 10.0775 4.5375L8 6.615L5.92167 4.5375C5.73583 4.3648 5.49025 4.2709 5.2366 4.27554C4.98294 4.28019 4.74097 4.38302 4.56158 4.56241C4.38218 4.7418 4.27935 4.98377 4.27471 5.23743C4.27006 5.49109 4.36397 5.73666 4.53667 5.9225L6.61417 8L4.53667 10.0775C4.35291 10.2613 4.24968 10.5105 4.24968 10.7704C4.24968 11.0303 4.35291 11.2796 4.53667 11.4633C4.62743 11.5546 4.73533 11.627 4.85418 11.6764C4.97302 11.7259 5.10046 11.7513 5.22917 11.7513C5.35788 11.7513 5.48532 11.7259 5.60416 11.6764C5.72301 11.627 5.83091 11.5546 5.92167 11.4633L8 9.385L10.0775 11.4633C10.2613 11.647 10.5105 11.7501 10.7703 11.7501C11.0301 11.75 11.2793 11.6467 11.4629 11.4629C11.6466 11.2791 11.7497 11.0299 11.7496 10.7701Z"
        fill="white"
      />
    </svg>
  );
};

export default FilledRoundedWhiteClose;
