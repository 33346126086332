import React, { useState } from 'react'
import ChangePasswordForm from '../form/ChangePassword'
import MainAuthComponent from './MainAuthComponent'
import forgot_password from "../assets/forget_password.png"
import { useNavigate, useParams } from 'react-router-dom'
import { AuthApi } from '../api/Auth'
import { toast } from 'react-toastify'

const NewPassword = () => {
  const { token } = useParams()
  const [error, setError] = useState({})
  const navigate = useNavigate()

  const onSubmit = async (password, confirmPassword) => {
    const payload = {
      new_password: password,
      confirm_password: confirmPassword,
    }
    const { data: response} = await AuthApi.setPassword(payload, token)
    if( response.success ) {
      toast.success("Password set successfully")
      localStorage.removeItem("token")
      localStorage.removeItem("user_type")
      localStorage.removeItem("noChange")
      localStorage.removeItem("loggedInAlways")
      navigate("/login")
    } else {
      let errs = {}
      // toast.error(response.errors.error_message.join(", "))
      response.errors.error_message.forEach((each) => {
        if(typeof(each) !== "object") {
          toast.error(each)
        } else {
          errs = {...errs, ...each}
        }
      })
      if(Object.keys(errs).length){
        setError(errs)
      }
    }
  }

  return (
    <MainAuthComponent side_image={forgot_password} width="w-[70%] mb-5">
        <ChangePasswordForm onSubmit={onSubmit} error={error} setError={setError} />
    </MainAuthComponent>
  )
}

export default NewPassword