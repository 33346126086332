import { DotPulse } from "@uiball/loaders";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { LibraryApi } from "../../api/library";
import { NameFilterFromProfileAtom, ResizingAtom } from "../../atom";
import ArticleCard from "./ArticleCard";

function ArticleList({ nameSearch="", searchOTTVal = "", called="", callAgain=() => {}, page, setPageLoading, setTotalPages, selectedYear, setPage }) {
  const [articles, setArticles] = useState(null)
  const [highlightingArticle, setHighlightingArticle] = useState("")
  const [loading, setLoading] = useState(false)
  const resizeLoading = useRecoilValue(ResizingAtom)
  const profile = useRecoilValue(NameFilterFromProfileAtom)

  const fetchArticles = async () => {
    if(page === 1) {
      setLoading(true)
    }
    const params = { page, is_published: 1 }
    if(searchOTTVal) {
      params.profile_id = searchOTTVal;
    } else if(nameSearch) {
      params.search = nameSearch
    }

    if(selectedYear) {
      params.publication_year = selectedYear
    }

    const { data: response } = await LibraryApi.list({ params })
    setPageLoading(false)
    if(response.success) {
      const settableResult = response.data.results
      if(page === 1) {
        setArticles(settableResult)
      } else {
        setArticles(prev => [...prev, ...settableResult])
      }
      setTotalPages(response.data.total_pages)
    }
    setLoading(false)
    callAgain(false)
  }

  useEffect(() => {
    if(profile) return
    fetchArticles()
  }, [ page ])

  useEffect(() => {
    if(page === 1) {
      fetchArticles()
    } else {
      setPage(1)
    }
  }, [selectedYear, called])

  useEffect(() => {
    if(highlightingArticle) {
     const id = setTimeout(() => setHighlightingArticle(""), 8000)
      return(() => clearTimeout(id))
    }
  }, [highlightingArticle])

  useEffect(() => {
    setHighlightingArticle("")
  }, [searchOTTVal, selectedYear])

  if(!articles || loading) {
    return(
      <div className="w-full flex h-[60vh] justify-center items-center">
        <DotPulse color="#1767A5" />
      </div>
    )
  }

  if(!articles.length) {
    return(<div className="w-full min-h-[60vh] text-pot-t-grey flex justify-center items-center">
      No files for selected filter
    </div>)
  }

  return (
    <>
    <div className={resizeLoading ? "hidden" : "mx-auto"}>
      <div className="grid grid-cols-1 gap-x-[2.5vw] gap-y-10 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 px-8 py-6">
        {articles?.map((article, i) => {
          return <ArticleCard article={article} key={article.id} highlightingArticle={highlightingArticle} setHighlightingArticle={setHighlightingArticle} />;
        })}
      </div>
    </div>
    <div className={resizeLoading ? "w-full flex h-[60vh] justify-center items-center" : "hidden"}>
        <DotPulse color="#1767A5" />
      </div>
    </>
  );
}

export default ArticleList;
