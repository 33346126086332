import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { strokes } from "../../utils/chartsData";

const NPSChurnLineChart = ({ filteredData, xAxisLabels, yAxisLabel, page }) => {
  return (
    <ResponsiveContainer width="100%" height="100%" className={"text-xs"}>
      <LineChart
        data={filteredData}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          label={{
            value: "Time",
            position: "insideBottom",
            offset: -30,
          }}
          dy={14}
          angle={filteredData.length > 8 ? -10 : 0}
          interval={0}
          fontSize={12}
          dataKey="name"
        />
        <YAxis
          fontSize={12}
          // label={{
          //   value: yAxisLabel,
          //   angle: -90,
          //   offset: 55,
          //   // position: "insideRight",
          //   fontSize: 12,
          //   lineHeight: 16,
          //   dx: -20,
          // }}
        />
        <Tooltip
          formatter={(value, name) => {
            if (page === "CHURN") return [`${value}%`, `${name}`];
            if (page === "NPS") return [`${value}`, `${name}`];
            return [`${value}`, `${name}`];
          }}
          wrapperStyle={{ fontSize: "12px" }}
        />
        {xAxisLabels &&
          xAxisLabels.map((each, index) => (
            <Line
              key={each}
              type="monotone"
              dataKey={each}
              stroke={strokes[index]}
              strokeWidth={1.5}
            />
          ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default NPSChurnLineChart;
