import React from "react";

const ContactIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M575 4946 c-37 -16 -70 -52 -84 -89 -7 -19 -11 -209 -11 -566 l0
-537 -30 -29 c-48 -46 -128 -168 -175 -268 -203 -427 -133 -1062 177 -1622
327 -589 928 -1147 1545 -1433 305 -142 565 -212 861 -232 374 -25 670 63 933
275 86 70 139 136 183 229 56 119 75 237 57 356 -20 125 -57 183 -166 257 -78
52 -181 87 -513 172 -169 44 -365 96 -437 115 -71 20 -145 36 -163 36 -83 0
-149 -54 -252 -205 -90 -133 -98 -140 -147 -125 -118 39 -266 137 -445 295
l-112 100 1451 5 c1447 5 1452 5 1479 26 15 11 37 33 48 48 21 27 21 28 21
1561 0 1533 0 1534 -21 1562 -11 15 -45 40 -75 55 l-54 27 -2020 0 c-1662 0
-2025 -2 -2050 -13z m3585 -320 c-8 -7 -337 -267 -730 -576 -393 -310 -732
-577 -752 -593 l-37 -30 -68 54 c-283 221 -1441 1135 -1453 1146 -12 11 232
13 1520 13 1302 0 1532 -2 1520 -14z m-2488 -844 c475 -373 875 -687 891 -696
44 -29 120 -24 171 11 22 16 423 329 889 696 467 367 850 667 853 667 2 0 4
-553 4 -1230 l0 -1230 -1479 0 -1479 0 -33 41 c-53 67 -189 272 -189 285 0 17
102 108 195 173 109 76 162 131 170 175 6 27 -23 157 -124 568 -137 551 -161
626 -222 688 -85 85 -168 89 -491 24 l-28 -6 0 256 c0 141 2 256 5 256 2 0
393 -305 867 -678z m-460 -542 c59 -234 110 -436 112 -450 4 -22 -5 -32 -71
-80 -248 -179 -337 -352 -259 -502 47 -91 210 -327 322 -468 139 -175 418
-453 557 -557 117 -87 260 -166 375 -207 139 -49 263 -28 363 62 45 40 150
170 178 219 7 14 51 5 347 -71 386 -99 518 -139 554 -166 21 -16 24 -26 24
-72 -2 -113 -51 -201 -157 -282 -565 -432 -1691 -59 -2465 818 -225 255 -372
484 -477 745 -172 429 -179 871 -18 1161 45 81 142 178 208 210 58 28 226 70
270 67 l30 -2 107 -425z"
        />
      </g>
    </svg>
  );
};

export default ContactIcon;
