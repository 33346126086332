import React from "react";
import TickMark from "../assets/TickMark";

const Checkbox = ({ color, checked = false, onCheck = () => {} }) => {
  const bgcolor = `bg-[${color}]`;
  return (
    <div onClick={onCheck} className="relative">
      <div className="w-4 h-4 rounded border"></div>
      {checked && (
        <div
          style={{ backgroundColor: color }}
          className={`absolute top-0 left-0 w-4 h-4 rounded flex justify-center items-center`}
        >
          <TickMark />
        </div>
      )}
    </div>
  );
};

export default Checkbox;
