import React from "react";
import { motion } from "framer-motion";

const BigRetry = () => {
  return (
    <motion.svg
      width="193"
      height="181"
      viewBox="0 0 193 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial={{ rotate: -20 }}
      animate={{ rotate: 0 }}
      transition={{ duration: 0.6 }}

    >
      <path
        d="M22.75 66.9167H56.1056C59.1003 66.9167 61.9724 68.1063 64.09 70.2239C66.2076 72.3415 67.3973 75.2136 67.3973 78.2083C67.3973 81.2031 66.2076 84.0751 64.09 86.1927C61.9724 88.3103 59.1003 89.5 56.1056 89.5H12.5762C9.28397 89.497 6.12759 88.1871 3.80067 85.858C1.47375 83.529 0.166686 80.3714 0.166687 77.0792V33.0417C0.166687 30.0469 1.35634 27.1749 3.47394 25.0573C5.59154 22.9397 8.46362 21.75 11.4584 21.75C14.4531 21.75 17.3252 22.9397 19.4428 25.0573C21.5604 27.1749 22.75 30.0469 22.75 33.0417V66.9167Z"
        fill="#1767A5"
      />
      <path
        d="M38.4454 67.9329H14.8459C20.0787 47.663 32.1893 29.8399 49.1075 17.51C66.0257 5.18019 86.7011 -0.890861 107.6 0.334628C128.498 1.56012 148.322 10.0061 163.683 24.2288C179.045 38.4515 188.989 57.5679 191.817 78.3105C194.645 99.0531 190.181 120.134 179.187 137.95C168.194 155.765 151.354 169.21 131.547 175.985C111.739 182.76 90.1928 182.445 70.5914 175.094C50.9901 167.743 34.5504 153.812 24.0824 135.683H43.5267V124.165C51.2067 137.581 63.2437 147.967 77.6398 153.6C92.0358 159.232 107.925 159.772 122.67 155.129C137.415 150.487 150.129 140.942 158.702 128.078C167.275 115.214 171.19 99.8053 169.799 84.4094C168.408 69.0135 161.794 54.5564 151.054 43.4376C140.314 32.3189 126.095 25.2073 110.756 23.2833C95.418 21.3594 79.8828 24.7387 66.7296 32.8603C53.5764 40.982 43.5961 53.3576 38.4454 67.9329Z"
        fill="#1767A5"
      />
      <path
        d="M34.0417 141.555C40.2779 141.555 45.3334 136.499 45.3334 130.263C45.3334 124.027 40.2779 118.971 34.0417 118.971C27.8055 118.971 22.75 124.027 22.75 130.263C22.75 136.499 27.8055 141.555 34.0417 141.555Z"
        fill="#1767A5"
      />
    </motion.svg>
  );
};

export default BigRetry;
