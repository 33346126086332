import React from "react";
import { toNormalText } from "../../utils/helperFunc";

const InputField = ({
  className,
  heading,
  formData,
  setFormData,
  value,
  placeholder,
  error,
  setError,
  disabled = false,
  required = false,
}) => {
  const disabledStyles = `cursor-not-allowed`;
  return (
    <div className={className}>
      <div className="font-semibold text-xs flex">
        {heading}
        {required && <p className="ml-1 text-xxs text-red-400">✷</p>}
      </div>
      <input
        onFocus={() => {
          if (error[value]) {
            delete error[value];
            setError({ ...error });
          }
        }}
        value={formData?.[value] || ""}
        onChange={(e) => {
          formData[value] = e.target.value;
          setFormData({ ...formData });
        }}
        disabled={disabled}
        placeholder={placeholder || toNormalText(value)}
        className={`w-full border outline-none rounded px-3 py-2 mt-3 border-pot-blue-2/20 shadow shadow-pot-blue-2/10 focus:shadow-lg focus:shadow-pot-blue-2/10 ${
          disabled && disabledStyles
        }`}
      />
      <p className="text-red-600">{error[value]}</p>
    </div>
  );
};

export default InputField;
