import React from "react";
import Services from "../../assets/Services";

const ServiceFeature = ({ detail }) => {
  return (
    <div className={`${/*detail.unique_aspects?.existing ? "w-[68%]" : */"w-full"} rounded-2xl shadow-md border border-pot-grey-4/10 pb-2`}>
      <header className="border-b flex px-4 py-2 ">
        <Services />
        <p className="text-pot-dark-blue font-semibold ml-3">
          Service Features
        </p>
      </header>
      <section className="w-full py-4 px-4 text-xs space-y-4 whitespace-pre-wrap text-pot-grey-3">
        {/* {String(detail.service_features).split(`\\n`).map((each) => ( */}
          <p>{detail.service_features?.existing}</p>
        {/* ))} */}
      </section>
      <section className="w-full px-4 text-xs space-y-4 whitespace-pre-wrap text-pot-grey-3">
        {/* {String(detail.service_features).split(`\\n`).map((each) => ( */}
          <p>{detail.unique_aspects?.existing}</p>
        {/* ))} */}
      </section>
    </div>
  );
};

export default ServiceFeature;
