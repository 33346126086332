import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import Check from "../../assets/pdf_png/tick.png";
import Cross from "../../assets/pdf_png/x.png";
import moment from "moment";

const Row = ({ keys, data, widths }) => {
  const typeArrays = [
    "business_model",
    "distribution_types",
    "genres",
    "target_market",
  ];

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        color: "#7B7B7B",
      }}
      wrap={false}
    >
      {keys?.map((key, id) => {
        if (key === "features") {
          return (
            <Text
              style={{
                width: widths[id],
                textAlign: "left",
                color: "black",
                padding: "8px 12px",
                borderBottom: "1px solid #F4F4F4",
                borderLeft: "1px solid #F4F4F4",
                borderRight:
                  id === keys.length - 1 ? "1px solid #F4F4F4" : "0px",
              }}
            >
              {data[key]}
            </Text>
          );
        } else if (typeArrays.includes(data.mainVal)) {
          return (
            <View
              style={{
                width: widths[id],
                display: "flex",
                textAlign: "center",
                paddingVertical: "4px",
                borderBottom: "1px solid #F4F4F4",
                borderLeft: "1px solid #F4F4F4",
                borderRight:
                  id === keys.length - 1 ? "1px solid #F4F4F4" : "0px",
              }}
            >
              {data?.[key]?.map((text) => (
                <Text style={{ paddingVertical: "4px" }}>{text}</Text>
              ))}
            </View>
          );
        } else if (data.mainVal.includes("pricing")) {
          const validValue = data[key] !== undefined && data[key] !== null;
          return (
            <View
              style={{
                width: widths[id],
                display: "flex",
                alignItems: "center",
                color: "black",
                paddingVertical: "8px",
                borderBottom: "1px solid #F4F4F4",
                borderLeft: "1px solid #F4F4F4",
                borderRight:
                  id === keys.length - 1 ? "1px solid #F4F4F4" : "0px",
              }}
            >
              {typeof data[key] !== "string" && validValue ? (
                <Text>${data[key]}</Text>
              ) : (
                <Text style={{ textAlign: "center" }}>{data[key]}</Text>
              )}
            </View>
          );
        } else if (data.mainVal.includes("subscribers")) {
          return (
            <View
              style={{
                width: widths[id],
                display: "flex",
                alignItems: "center",
                color: "black",
                paddingVertical: "8px",
                borderBottom: "1px solid #F4F4F4",
                borderLeft: "1px solid #F4F4F4",
                borderRight:
                  id === keys.length - 1 ? "1px solid #F4F4F4" : "0px",
              }}
            >
              {typeof data[key] !== "string" && data[key] ? (
                <Text>{data[key] <= 0.009 ? "< 0.01" : data?.[key]?.toFixed(2)}M</Text>
              ) : (
                <Text>{data[key]}</Text>
              )}
            </View>
          );
        } else if (data.mainVal.includes("is")) {
          return (
            <View
              style={{
                width: widths[id],
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #F4F4F4",
                borderLeft: "1px solid #F4F4F4",
                borderRight:
                  id === keys.length - 1 ? "1px solid #F4F4F4" : "0px",
              }}
            >
              {data[key] ? (
              <Image
                src={Check}
                style={{
                  width: "6px",
                    objectFit: "contain",
                    paddingTop: "8px",
                  }}
                />
              ) : (
                <Image
                  src={Cross}
                  style={{
                    width: "7px",
                  objectFit: "contain",
                  paddingTop: "8px",
                }}
              />
)}
            </View>
          );
        }
        return (
          <Text
            style={{
              width: widths[id],
              textAlign: "center",
              paddingVertical: "8px",
              borderBottom: "1px solid #F4F4F4",
              borderLeft: "1px solid #F4F4F4",
              borderRight: id === keys.length - 1 ? "1px solid #F4F4F4" : "0px",
            }}
          >
            {data.mainVal.includes("date") && data[key] ? moment(data[key], "YYYY-MM-DD").format("MM-DD-YYYY") : data[key] }
          </Text>
        );
      })}
    </View>
  );
};

export default Row;
