import PeopleIcon from "@atlaskit/icon/glyph/people";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import OfficeBuildingIcon from "@atlaskit/icon/glyph/office-building";
import MediaServicesGridIcon from "@atlaskit/icon/glyph/media-services/grid";
import UploadIcon from "@atlaskit/icon/glyph/upload";
import EmailIcon from "@atlaskit/icon/glyph/email";
import NPSChurnIcon from '../assets/NPSChurnIcon';

export const navItems = [
  {
    icon: <MediaServicesGridIcon primaryColor="white" />,
    title: "Admin Dashboard",
    to: "/admin/dashboard",
  },
  {
    icon: <UploadIcon primaryColor="white" />,
    title: "Upload Data",
    to: "/admin/data/upload",
  },
  {
    icon: <UploadIcon primaryColor="white" />,
    title: "Upload Subscribers",
    to: "/admin/data-subscribers/upload",
  },
  {
    icon: <UploadIcon primaryColor="white" />,
    title: "Library",
    to: "/admin/data/library",
    // needPermission: "a_view_library_item",
  },
  {
    icon: <EmailIcon primaryColor="white" />,
    title: "Emails",
    to: "/admin/send-email",
    cc: "/admin/email-template",
    subItems: [
      {
        title: "Send Email",
        to: "/admin/send-email",
      },
      {
        title: "Email Template",
        to: "/admin/email-template",
      },
    ],
  },
  {
    icon: <NPSChurnIcon />,
    title: "NPS & Churn",
    to: "/admin/nps-churn/upload-data",
    cc: "/admin/nps-churn/nps-standard-charts",
    subItems: [
      {
        title: "Upload Data",
        to: "/admin/nps-churn/upload-data",
      },
      {
        title: "Non-Standard Charts",
        to: "/admin/nps-churn/nps-standard-charts",
      },
    ],
  },
  {
    heading: "ADMIN MANAGEMENT",
  },
  {
    icon: <PeopleIcon primaryColor="white" />,
    title: "Admin",
    to: "/admin/user/admin",
  },
  {
    icon: <PeopleGroupIcon primaryColor="white" />,
    title: "Customer",
    to: "/admin/user/customer",
  },
  {
    icon: <OfficeBuildingIcon primaryColor="white" secondaryColor="#00838F" />,
    title: "Organization",
    to: "/admin/user/organization",
  },
];
