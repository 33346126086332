import React, { useEffect, useRef } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFpage from "./PDFpage";
// import { ClipLoader } from "react-spinners";
import { DotPulse } from "@uiball/loaders";
import { useSetRecoilState } from "recoil";
import { DownloadTelemetrySetterAtom } from "../../atom";

export const PDFDownloadButton = ({ title }) => {
  const buttonRef = useRef(null);
  const setDownloadTelemetryStart = useSetRecoilState(DownloadTelemetrySetterAtom)

  useEffect(() => {
    buttonRef.current.click();
    setDownloadTelemetryStart(title)
  }, []);

  return (
    <button
      ref={buttonRef}
      onClick={() => setDownloadTelemetryStart(title)}
      className="w-48 flex justify-center items-center py-1.5 rounded-lg bg-white text-pot-dark-blue font-bold"
    >
      Download Profile
    </button>
  );
};

const PDFGenerator = ({
  name,
  detail,
  pdfShowableDevices,
  pdfShowableCountries,
  region,
}) => {

  console.log(detail.pricing_and_promotions?.existing, "Detail")

  return (
    <PDFDownloadLink
      document={
        <PDFpage
          pdfShowableCountries={pdfShowableCountries}
          pdfShowableDevices={pdfShowableDevices}
          detail={detail}
          region={region}
        />
      }
      fileName={name}
    >
      {({ loading, error, url }) =>
        loading || error || !url ? (
          <button className="w-48 bg-white flex justify-center gap-x-3 py-2 font-semibold rounded-lg text-pot-blue text-sm items-center">
            <DotPulse size={20} color="#1767A5" speed={0.8} />
            Generating
          </button>
        ) : (
          <PDFDownloadButton title="PROFILE" />
        )
      }
    </PDFDownloadLink>
  );
};

export default PDFGenerator;
