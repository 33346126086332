import React, { useEffect, useRef } from "react";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import { motion } from "framer-motion";
import useOnClickOutside from "../../hooks/useClickOutside";
import CheckCircleOutlineIcon from "@atlaskit/icon/glyph/check-circle-outline";

const QuarterDropdown = ({ quarterList, open, setOpen, value, setValue }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));
  const nowRef = useRef()

  useEffect(() => {
    let id
    if(open){
      id = setTimeout(() => {
        console.log("Yesss")
        nowRef.current.scrollIntoView({ behavior:"smooth" })
      }, 150)
    }
    return(() => clearTimeout(id))
  }, [open])

  return (
    <div ref={ref} className="relative text-sm z-[70]">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setOpen((prev) => !prev);
        }}
        className="pl-4 pr-3 bg-pot-bg-blue flex items-center gap-x-2 text-sm text-pot-blue py-1 rounded-md"
      >
        Quarter
        {value ? (
          <motion.div initial={{scale: 0}} animate={{scale: 1}} className="h-fit -mb-1 py-[3px] ml-2">
            <CheckCircleOutlineIcon primaryColor="#1767A5" size="small" />
          </motion.div>
        ) : (
          <HipchatChevronDownIcon primaryColor="#1767A5" />
        )}
      </button>
      {open && (
        <motion.div
          onClick={(e) => e.stopPropagation()}
          initial={{ height: 0 }}
          animate={{ height: "28vh" }}
          className="absolute top-10 shadow-md py-2 rounded-md bg-white"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="max-h-[25vh] w-40 overflow-y-auto"
          >
            {quarterList.map((quarter) => {
              const showableQuarterVal = [quarter[0] + 1, quarter[1]].join(" ");
              const isCurrentQuarter = Math.floor((new Date().getMonth() + 3) / 3) === quarter[0]+1
              const isCurrentYear = new Date().getFullYear() === quarter[1]
              return (
                <button
                  ref={ (isCurrentQuarter && isCurrentYear) ? nowRef : null }
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue(showableQuarterVal);
                    setOpen((prev) => !prev);
                  }}
                  className="py-2 px-4 hover:bg-pot-bg-blue/60 text-left w-full"
                >
                  Q{showableQuarterVal}
                </button>
              );
            })}
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default QuarterDropdown;
