import React, { useEffect, useState } from "react";
import TrendTable from "../Table";
import { useRecoilValue } from "recoil";
import { AllFilterOptionsAtom, ChurnQuarterRangeAtom } from "../../../atom";
import SearchBox from "../SearchBox";
import { strokes } from "../../../utils/chartsData";
import SingleChart from "../SingleChart";
import { CHURNImport } from "../../../api/npschurn";

const getFormattedBarData = (response) => {
  let quarters =
    response.data.results[0]?.churn &&
    Object.keys(response.data.results[0]?.churn);
  let formattedBarData = quarters?.map((each, index) => {
    let ottValues = {};
    response.data.results?.forEach((ott) => {
      Object.entries(ott?.churn)?.forEach(([key, value]) => {
        if (key === quarters[index]) ottValues[ott.name] = value;
      });
    });
    return {
      name: each,
      ...ottValues,
    };
  });
  return formattedBarData;
};

const ChurnbyService = () => {
  const [chartType, setChartType] = useState("line");
  const allFilter = useRecoilValue(AllFilterOptionsAtom);
  const initialNamesList = allFilter.namesArr;
  const [namesList, setNamesList] = useState(initialNamesList);
  const [page, setPage] = useState(0);
  const quarterRange = useRecoilValue(ChurnQuarterRangeAtom);
  const endQuarter = Number(quarterRange.max.split(" ")[0]) - 1;
  const endQuarterYear = Number(quarterRange.max.split(" ")[1]);
  // const modableVal = (endQuarter - 11) * 2;
  // const startQuarter =
  //   modableVal % 4 < 0 ? 4 + (modableVal % 4) : modableVal % 4;
  // const diff =
  //   modableVal % 4 !== 0
  //     ? Math.trunc(Math.abs(modableVal) / 4 + 1)
  //     : Math.trunc(Math.abs(modableVal) / 4);
  // const startQuarterYear = endQuarterYear - diff;
  const startQuarter = Number(quarterRange.min.split(" ")[0]) - 1;
  const startQuarterYear = Number(quarterRange.min.split(" ")[1]);
  const [quarterStart, setQuarterStart] = useState([
    startQuarter,
    startQuarterYear,
  ]);
  const [quarterEnd, setQuarterEnd] = useState([endQuarter, endQuarterYear]);
  const [subsData, setSubsData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [initial, setInitial] = useState(true);
  const [selectedOtts, setSelectedOtts] = useState(null);
  const [chartLoading, setChartLoading] = useState(true);
  const [selectedBarQuarter, setSelectedBarQuarter] = useState(
    [endQuarter + 1, endQuarterYear].join(" ")
  );
  const [sortBy, setSortBy] = useState(`+ ${selectedBarQuarter}`);
  const [clearOut, setClearOut] = useState(false);
  const [selectedOttChangedInFetch, setSelectedOttChangedInFetch] =
    useState(false);
  const [lastRecord, setLastRecord] = useState(false);

  useEffect(() => {
    fetchSubsData(false, true);
  }, []);

  useEffect(() => {
    if (initial) return;
    fetchSubsData();
  }, [page]);

  useEffect(() => {
    if (initial) return;
    setChartLoading(true);
    setSelectedBarQuarter([endQuarter + 1, endQuarterYear].join(" "));
    setPage(0);
    setLastRecord(false);
    fetchSubsData(true, false, true);
  }, [quarterStart, quarterEnd]);

  useEffect(() => {
    if (initial) return;
    if (clearOut) {
      setClearOut(false);
      return;
    }
    if (chartLoading) {
      fetchSubsData(true, true, true);
    } else {
      fetchSubsData(true);
    }
    if (searchValue?.length > 0) {
      setLastRecord(true);
    }
  }, [namesList, sortBy, searchValue]);

  useEffect(() => {
    if (initial) return;
    if (searchValue) return;
    if (selectedOttChangedInFetch) {
      setSelectedOttChangedInFetch(false);
      return;
    }
    // COMMENTING FOR NOW
    // let formattedSubsData = subsData.filter(
    //   (each) => !selectedOtts.find((ott) => ott.id === each.id)
    // );
    // formattedSubsData = [...selectedOtts, ...formattedSubsData];
    // setSubsData(formattedSubsData);
  }, [selectedOtts]);

  // Fetch subs data
  const fetchSubsData = async (
    reset = false,
    calledInitial = false,
    callForSelected = false
  ) => {
    setLastRecord(false);
    const queryParameters = new URLSearchParams(window.location.search);
    var specific_profile = queryParameters.get("profile");
    const startQ = [quarterStart[0] + 1, quarterStart[1]];
    const endQ = [quarterEnd[0] + 1, quarterEnd[1]];
    let params = {
      length: 100,
      page: reset ? 1 : page + 1,
      search: searchValue,
    };
    let payload = {
      q_range: {
        start: startQ.join(" "),
        end: endQ.join(" "),
      },
      // sort_by: `+ 3 2021`
    };
    let settableSelOtts = selectedOtts ? [...selectedOtts] : null;
    if (sortBy) {
      if (!searchValue) {
        setNamesList(initialNamesList);
        payload.sort_by = sortBy;
      }
    }
    if (specific_profile) {
      payload = {
        ...payload,
        filters: { names: [specific_profile] },
      };
    }
    const { data: response } = await CHURNImport.churnList(payload, { params });
    if (response.errors?.error_message[0].includes("Invalid page")) {
      setLastRecord(true);
      return;
    }
    if (subsData?.length === response.data.total_count || specific_profile) {
      setLastRecord(true);
    }
    let formattedSubsData = response.data.results.map((each) => {
      // Filter out null values from each.nps object
      let filteredNps = Object.fromEntries(
        Object.entries(each?.churn || {}).filter(([_, value]) => value !== null)
      );
      if (specific_profile) {
        // Return merged object without null values in each.nps
        return {
          ...each,
          ...filteredNps,
          churn: filteredNps,
        };
      }
      return {
        ...each,
        ...each.churn,
      };
    });
    let formattedBarData = getFormattedBarData(response);
    if (callForSelected) {
      params = { length: selectedOtts?.length };
      payload = {
        ...payload,
        filters: { names: selectedOtts.map((each) => each.id) },
      };
      delete payload.sort_by;
      const { data: response2 } = await CHURNImport.churnList(payload, {
        params,
      });
      settableSelOtts = response2.data.results.map((each, id) => {
        // Filter out null values from each.nps object
        let filteredNps = Object.fromEntries(
          Object.entries(each?.churn || {}).filter(
            ([_, value]) => value !== null
          )
        );
        if (specific_profile) {
          // Return merged object without null values in each.nps
          return {
            ...each,
            ...filteredNps,
            churn: filteredNps,
            color: strokes[id],
          };
        }
        return { ...each, ...each?.churn, color: strokes[id] };
      });
      let formattedBarDataResponse = getFormattedBarData(response);
      setSelectedOttChangedInFetch(true);
      setSelectedOtts(settableSelOtts);
      setBarData(formattedBarDataResponse);
      setChartLoading(false);
    }
    if (initial) {
      setInitial(false);
    }
    if (calledInitial) {
      // Initial selected OTTs
      const countsOfOTTs = formattedSubsData.map((ott) => {
        return {
          ...ott,
          count: Object.values(ott.churn).filter((val) => val !== null)?.length,
        };
      });
      countsOfOTTs.sort((a, b) => b.count - a.count);
      const values = countsOfOTTs.slice(0, 10).map((each, id) => ({
        ...each,
        color: strokes[id],
      }));
      setSelectedOtts(values);
      const barValues = formattedBarData.slice(0, 10);
      setBarData(barValues);
    }
    if (!sortBy && !searchValue && !calledInitial) {
      formattedSubsData = formattedSubsData.filter(
        (each) => !settableSelOtts.find((ott) => ott.id === each.id)
      );
      if (page === 0 || reset) {
        formattedSubsData = [...settableSelOtts, ...formattedSubsData];
      }
    }
    setSubsData((prev) =>
      reset ? formattedSubsData : [...prev, ...formattedSubsData]
    );
    if (chartLoading) {
      setChartLoading(false);
    }
  };

  return (
    <div className="m-5 text-xs">
      <SingleChart
        title="Churn by service"
        chartType={chartType}
        setChartType={setChartType}
        quarterEnd={quarterEnd}
        quarterStart={quarterStart}
        setQuarterEnd={setQuarterEnd}
        setQuarterStart={setQuarterStart}
        selectedOtts={selectedOtts}
        setSelectedOtts={setSelectedOtts}
        chartLoading={chartLoading}
        selectedBarQuarter={selectedBarQuarter}
        barData={barData}
        page="churn"
        showChartTypeDropdown
      />
      <SearchBox setSearchValue={setSearchValue} clearOut={clearOut} />
      <TrendTable
        subsData={subsData}
        quarterStart={quarterStart}
        quarterEnd={quarterEnd}
        page={page}
        setPage={setPage}
        totalNames={namesList?.length}
        selectedOtts={selectedOtts}
        setSelectedOtts={setSelectedOtts}
        setSortBy={setSortBy}
        sortBy={sortBy}
        lastRecord={lastRecord}
        chartLoading={chartLoading}
        usedFor="churn"
      />
    </div>
  );
};

export default ChurnbyService;
