import React, { useEffect, useState } from "react";
import { DownArrow, LeftArrow } from "../../assets/Arrows";
import { motion } from "framer-motion";
import Checkbox from "@atlaskit/checkbox";

const AndOrDropdown = ({
  heading,
  keyValue,
  allFilters,
  setAllFilters,
  z,
  options,
  placeholder,
  initial,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [mouseOn, setMouseOn] = useState(false);
  const [andOr, setAndOr] = useState("and");
  const [andOrChanged, setAndOrChanged] = useState(false);
  const [models, setModels] = useState([]);
  const [modelsChanged, setModelsChanged] = useState(false);

  useEffect(() => {
    if (initial) {
      return;
    }
    const filteredFilter = allFilters.filter(
      (each) => Object.keys(each)[0] !== keyValue
      );
      if(!andOrChanged && !modelsChanged){
        return
      }
    if (!andOr || !models.length) {
      setAndOrChanged(false);
      setModelsChanged(false);
      if(allFilters.some((each) => Object.keys(each)[0] === keyValue)){
        setAllFilters(filteredFilter);
      }
      return;
    }
    const labelVal = `${andOr.toUpperCase()} - ${models.join(", ")}`;
    const upperAndOr = andOr.toUpperCase();
    const realVal = {
      [upperAndOr]: [
        ...models.map(
          (val) => options.find((each) => each.label === val).value
        ),
      ],
    };
    setAllFilters([
      { [keyValue]: [{ label: labelVal, value: realVal }] },
      ...filteredFilter,
    ]);
    setAndOrChanged(false);
    setModelsChanged(false);
    // else {
    //   const filteredFilter = allFilters.filter(
    //     (each) => Object.keys(each)[0] !== keyValue
    //   );
    //   if(filteredFilter.length !== allFilters.length) {
    //     setAllFilters(filteredFilter)
    //   }
    // }
  }, [modelsChanged, andOrChanged]);

  useEffect(() => {
    const hasModel = allFilters.find(
      (each) => Object.keys(each)[0] === keyValue
    );
    if (!hasModel) {
      setAndOr("and");
      setModels([]);
    } else if (hasModel && (!andOr || !models.length)) {
      const newAndOr = Object.keys(hasModel[keyValue][0].value)[0];
      const newModelsString = Object.values(hasModel[keyValue][0].value);
      const newModels = newModelsString[0].map(
        (each) => options.find((val) => val.value === each).label
      );
      setModels(newModels);
      setAndOr(newAndOr.toLowerCase());
    }
  }, [allFilters]);

  return (
    <div className={`w-full h-14 ${z}`}>
      <p className="font-medium pb-2">{heading}</p>
      <div
        className={`w-full h-14 ${z}`}
        onBlur={() => {
          if (!mouseOn) {
            setOpenDropdown(false);
          }
        }}
        tabIndex="0"
      >
        <div
          onClick={() => {
            setOpenDropdown((prev) => !prev);
          }}
          className={`w-full h-10 ${
            openDropdown
              ? "outline outline-2 outline-pot-light-blue-2 bg-white"
              : "bg-[#EBECF0]/60 hover:bg-[#DFE1E6]/60 transition ease-in-out"
          } cursor-pointer py-1.5 px-2.5 transition ease-in-out flex items-center justify-between rounded-sm`}
        >
          <div className="w-full h-full flex items-center">
            <p className="mt-1 text-slate-500 text-tiny bg-inherit">
              {placeholder}
            </p>
          </div>
          <motion.div initial={{ rotate: -90 }} className="mr-2">
            <LeftArrow />
          </motion.div>
        </div>
        {openDropdown && (
          <div
            onMouseEnter={() => setMouseOn(true)}
            onMouseLeave={() => setMouseOn(false)}
            className="relative w-full bg-white top-2 shadow-lg rounded-md left-0"
          >
            <div className="w-full flex">
              <button
                onClick={() => {
                  setAndOr("and");
                  setAndOrChanged(true);
                }}
                className={`w-full ${
                  andOr === "and"
                    ? "bg-pot-blue  text-white"
                    : "bg-pot-background-grey-2  text-pot-grey-5"
                } py-3 text-sm rounded-tl`}
              >
                AND
              </button>
              <button
                onClick={() => {
                  setAndOr("or");
                  setAndOrChanged(true);
                }}
                className={`w-full ${
                  andOr === "or"
                    ? "bg-pot-blue  text-white"
                    : "bg-pot-background-grey-2  text-pot-grey-5"
                } py-3 text-sm rounded-tr`}
              >
                OR
              </button>
            </div>
            <div className="w-full flex flex-col py-2 ">
              <div>
                {options?.map((each) => (
                  <div className=" w-full pl-2 flex py-1 text-sm hover:bg-pot-grey-4/10 border-l-2 border-white hover:border-pot-dark-blue">
                    <Checkbox
                      label={each.label}
                      isChecked={models.includes(each.label)}
                      onChange={() => {
                        setModelsChanged(true);
                        if (models.includes(each.label)) {
                          const filteredModels = models.filter(
                            (val) => val !== each.label
                          );
                          setModels(filteredModels);
                        } else {
                          setModels([...models, each.label]);
                        }
                      }}
                    />
                    {/* <p className="text-sm ml-2">{each.label}</p> */}
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="grid grid-cols-2 px-4 py-4">
            <div className="flex justify-center py-2">
              <Checkbox
                isChecked={models.includes("TVOD")}
                onChange={() => {
                  setModelsChanged(true)
                  if (models.includes("TVOD")) {
                    const filteredModels = models.filter(
                      (each) => each !== "TVOD"
                    );
                    setModels(filteredModels);
                  } else {
                    setModels([...models, "TVOD"]);
                  }
                }}
              />
              <p className="text-sm ml-2">TVOD</p>
            </div>
            <div className="flex justify-center py-2">
              <Checkbox
                isChecked={models.includes("SVOD")}
                onChange={(val) => {
                  setModelsChanged(true)
                  if (models.includes("SVOD")) {
                    const filteredModels = models.filter(
                      (each) => each !== "SVOD"
                    );
                    setModels(filteredModels);
                  } else {
                    setModels([...models, "SVOD"]);
                  }
                }}
              />
              <p className="text-sm ml-2">SVOD</p>
            </div>
            <div className="flex justify-center py-2">
              <Checkbox
                isChecked={models.includes("AVOD")}
                onChange={(val) => {
                  setModelsChanged(true)
                  if (models.includes("AVOD")) {
                    const filteredModels = models.filter(
                      (each) => each !== "AVOD"
                    );
                    setModels(filteredModels);
                  } else {
                    setModels([...models, "AVOD"]);
                  }
                }}
              />
              <p className="text-sm ml-2">AVOD</p>
            </div>
            <div className="flex justify-center py-2 pr-1.5">
              <Checkbox
                isChecked={models.includes("FAST")}
                onChange={(val) => {
                  setModelsChanged(true)
                  if (models.includes("FAST")) {
                    const filteredModels = models.filter(
                      (each) => each !== "FAST"
                    );
                    setModels(filteredModels);
                  } else {
                    setModels([...models, "FAST"]);
                  }
                }}
              />
              <p className="text-sm ml-2">FAST</p>
            </div>
          </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default AndOrDropdown;
