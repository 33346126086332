import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DataImportApi } from "../../api/dataImport";
import TableControl from "../../components/TableLayout/TableControl";
import TableLayoutTwo from "../../components/TableLayout/Two";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import RetryIcon from "@atlaskit/icon/glyph/retry";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  AccountUserAtom,
  DescriptionAtom,
  TableLoadingAtom,
  UploadVerifyIdAtom,
  VerificationResultAtom,
} from "../../atom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import BigRevert from "../../assets/BigRevert";
// import { ClipLoader } from "react-spinners";
import { MacScrollbar } from "mac-scrollbar";
import { DotPulse } from "@uiball/loaders";
import Note from "../../assets/Note";

// created_at
// :
// "2022-12-09T07:41:04.484518Z"
// filename
// :
// "aa1db893-c878-47a7-af19-41aa2dd0bb28.xlsx"
// id
// :
// "cee84ce1-5477-4d98-acda-f69c36ac68f9"
// status
// :
// "SUCCESS"
// uploaded_by
// :
// "System Admin"

const dummyData = [
  {
    created_at: "2022-12-09T07:41:04.484518Z",
    filename: "aa1db893-c878-47a7-af19-41aa2dd0bb28.xlsx",
    id: "cee84ce1-5477-4d98-acda-f69c36ac68f9",
    status: "ERROR",
    uploaded_by: "System Admin",
  },
  {
    created_at: "2022-12-09T07:41:04.484518Z",
    filename: "aa1db893-c878-47a7-af19-41aa2dd0bb28.xlsx",
    id: "cee84ce1-5477-4d98-acda-f69c36ac68f9",
    status: "SUCCESS",
    uploaded_by: "System Admin",
  },
];

const Table = ({ height, hasDropzone }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [length, setLength] = useState(10);
  const [revertId, setRevertId] = useState(null);
  const [loading, setLoading] = useState("");
  const [colDef, setColDef] = useState([]);
  const [callTableAfterUpload, setCallTableAfterUpload] = useState(false);
  const account = useRecoilValue(AccountUserAtom);
  const setTableLoading = useSetRecoilState(TableLoadingAtom);
  const [uploadVerifyId, setUploadVerifyId] =
    useRecoilState(UploadVerifyIdAtom);
  const [description, setDescription] = useRecoilState(DescriptionAtom);
  const [result, setVerificationResult] = useRecoilState(
    VerificationResultAtom
  );
  const navigate = useNavigate();

  const hasDownload = account?.user_permissions.includes("a_download_upload");

  const downloadData = async (id) => {
    try {
      setLoading(id);
      const newId = id.replace(".xlsx", "");
      const response = await DataImportApi.subHistoryDownload(newId);
      if (response.status !== 200) {
        const errorString = await response.data.text();
        const errorObj = JSON.parse(errorString);
        toast.error(errorObj.errors.error_message.join(", "));
        setLoading("");
        return;
      }
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.target = "_blank";
      link.setAttribute("download", "file.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLoading("");
    } catch (e) {
      toast.error(JSON.stringify(e));
      setLoading("");
    }
  };

  const initialColDef = [
    {
      header: "Date",
      accessorKey: "created_at",
      cell: ({ getValue }) =>
        getValue() && moment(getValue()).format("MM-DD-YYYY"),
    },
    {
      header: "Time",
      id: "time",
      cell: ({ row }) =>
        row.original.created_at &&
        moment(row.original.created_at).format("hh: mm A"),
    },
    { header: "User name", accessorKey: "uploaded_by" },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ getValue, row }) =>
        getValue() === "SUCCESS" || getValue() === "ERROR" ? (
          <p>{getValue()}</p>
        ) : (
          <div className="w-full flex justify-center">
            <div
              // onClick={() => setUploadVerifyId(row.original.id)}
              className="flex items-center gap-x-2"
            >
              <div className="w-1 h-1 rounded-full bg-pot-dark-green"></div>
              {getValue()}
            </div>
          </div>
        ),
    },
    {
      header: "Actions",
      width: "20%",
      cell: ({ row }) => {
        return (
          <div className="flex gap-4">
            <button
              onClick={() => {
                if (row.original.status === "FAILED") {
                  setDescription(row.original.errors_found);
                } else {
                  setDescription(row.original?.errors_list);
                }
                setVerificationResult(row.original.status);
              }}
              className="flex justify-center w-full gap-x-2"
            >
              <Note />
              <span className="underline">Result</span>
            </button>
            {hasDownload &&
              (loading === row.original.filename ? (
                <div className="flex justify-center w-full gap-x-2">
                  <DotPulse size={20} speed={0.8} />
                </div>
              ) : (
                <button
                  onClick={() => downloadData(row.original.filename)}
                  className="flex justify-center w-full gap-x-2"
                >
                  <DownloadIcon size="small" primaryColor="#1767A5" />
                  Download
                </button>
              ))}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setVerificationResult(
      data.map((row) => (row.status === "FAILED" ? "FAILED" : "SUCCESS"))
    );
  }, [data, setVerificationResult, setDescription]);

  useEffect(() => {
    setColDef([...initialColDef]);
  }, [account, loading]);

  const fetchHistory = async () => {
    setTableLoading(true);
    const params = { page, length };
    const { data: response } = await DataImportApi.subhistory({ params });
    // response.data.results = dummyData;
    if (page === 1) {
      const foundObj =
        // response.data.results.find((each) => each.status === "SUCCESS") || {};
        response.data.results.find((each) => each.status === "SUCCESS") || {};
      foundObj.has_revert = true;
    }
    console.log(response.data);
    setData(response.data.results);
    setTotalCount(response.data.total_count);
    setTotalPages(response.data.total_pages);
    setTableLoading(false);
    setCallTableAfterUpload(false);
  };

  const revertHistory = async () => {
    const { data: response } = await DataImportApi.revert(revertId);
    setRevertId(null);
    if (response.success) {
      await fetchHistory();
      return;
    }
    toast.error(response.errors.error_message);
  };

  useEffect(() => {
    if (account?.user_permissions.includes("a_view_upload")) {
      fetchHistory();
    }
  }, [page, length, account, callTableAfterUpload]);

  useEffect(() => {
    if (uploadVerifyId) {
      setCallTableAfterUpload(true);
    }
  }, [uploadVerifyId]);

  const table = useReactTable({
    data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
  });

  if (account && !account?.user_permissions.includes("a_view_upload")) {
    return (
      <div
        className={`w-full ${
          hasDropzone ? "h-[30vh]" : "h-[75vh] pb-[10vh]"
        } flex justify-center items-center font-bold text-pot-dark-green`}
      >
        You are not allowed to {!hasDropzone && "upload or"} view the
        subscribers upload history
      </div>
    );
  }

  return (
    <>
      <MacScrollbar>
        <div className={`w-full relative ${height} overflow-y-auto mt-5`}>
          <TableLayoutTwo table={table} />
          <TableControl
            page={page}
            setPage={setPage}
            length={length}
            setLength={setLength}
            totalCount={totalCount}
            totalPages={totalPages}
          />
        </div>
      </MacScrollbar>
      <ModalTransition>
        {revertId && (
          <Modal onClose={() => setRevertId(null)} width="x-large">
            <div className="bg-white px-10 py-10 flex flex-col gap-4 justify-center items-center">
              <BigRevert />
              <p className="font-semibold text-sm">Revert</p>
              <p className="text-sm text-pot-text-grey-2 w-[70%] text-center">
                Take note! When you choose to roll back, this data will be
                restored to the selected version.
              </p>
              <div className="flex gap-10 pt-10">
                <button
                  onClick={revertHistory}
                  className="bg-pot-background-grey-2 w-40 flex justify-center py-2 text-pot-t-grey rounded-md"
                >
                  Yes, I understand
                </button>
                <button
                  onClick={() => setRevertId(null)}
                  className="bg-pot-dark-blue w-40 flex justify-center py-2 text-white rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default Table;
