import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import React from "react";
import ott_tracker from "../../assets/ott_tracker.png";
import parks_associates from "../../assets/parks-associates-Teallogo.png";
import login from "../../assets/Login1.png";

const FrontPage = () => {
  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "100%",
      fontSize: "8px",
      fontFamily: "Poppins",
    },
    section: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
    heading_image_container: {
      marginHorizontal: "28px",
      marginTop: "12px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    disclaimer_image_container: {
      marginBottom: "22px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    image: {
      height: "100%",
      objectFit: "cover",
      backgroundColor: "red",
    },
    heading_image: {
      height: "40px",
      objectFit: "contain",
    },
    text_container: {
      marginTop: "18px",
      fontSize: "8px",
      paddingHorizontal: "28px",
    },
    ott_text: {
      marginTop: "2.5px",
      fontWeight: "semibold",
      lineHeight: "1.8px",
    },
    frontpage_disclaimer_container: {
      width: "100%",
      position: "absolute",
      bottom: "8px",
      paddingHorizontal: "28px",
      paddingVertical: "5px",
    },
    frontpage_disclaimer_bold: {
      fontSize: "8px",
      fontWeight: "bold",
    },
    frontpage_disclaimer_normal: {
      fontSize: "8px",
      marginTop: "2px",
      marginBottom: "5px",
      color: "#383838",
      lineHeight: "1.6px",
    },
    frontpage_notes_bold: {
      fontSize: "7px",
      fontWeight: "semibold",
      marginBottom: "4px",
    },
    frontpage_notes_normal: {
      fontSize: "7px",
      color: "#383838",
      lineHeight: "1.6px",
    },
  });

  return (
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={[styles.section, { backgroundColor: "#EDF6F7" }]}>
        <View style={styles.heading_image_container}>
          <Image src={ott_tracker} style={styles.heading_image} />
        </View>
        <View style={styles.text_container}>
          <Text style={styles.ott_text}>
            The Streaming Video Tracker is an exhaustive analysis of market
            trends and profiles for 400+ over-the-top video services in the US
            and Canada. It estimates subscribers, viewers and transactional
            users for dozens of OTT services, including those that do not
            publicly release customer figures.
          </Text>
          <Text style={styles.ott_text}>
            This research helps subscribers understand the vast landscape of OTT
            video service providers, with insights on business models, new
            services, global expansion, and growth of individual services.
            Monthly market updates providing analysis on the most important
            announcements in the OTT market complements the data provided in
            this portal.
          </Text>
        </View>
        <View style={styles.frontpage_disclaimer_container}>
          <View style={styles.disclaimer_image_container}>
            <Image src={parks_associates} style={styles.heading_image} />
          </View>
          <Text style={styles.frontpage_disclaimer_bold}>Disclaimer</Text>
          <Text style={styles.frontpage_disclaimer_normal}>
            Parks Associates has made every reasonable effort to ensure that all
            information in this document is correct. We assume no responsibility
            for any inadvertent errors.
          </Text>
          <Text style={styles.frontpage_notes_bold}>Methodology notes:</Text>
          <View style={styles.frontpage_notes_normal}>
            <Text style={{ paddingTop: "1.5px" }}>
              The Streaming Video Tracker provides estimates for the number of
              users and subscribers for OTT video services in North America,
              along with various other relevant business information. Parks
              Associates has created a sizing model for each service that is
              assessed and adjusted each quarter with input from the following
              types of data:
            </Text>
            <Text style={{ paddingTop: "1.5px" }}>
              1) Reported figures – Provides a definitive measure at a point in
              time that can be used to frame future estimates.
            </Text>
            <Text style={{ paddingTop: "1.5px" }}>
              2) Survey data – This data (from a sample of 5,000-10,000 US
              Household respondents) shows the percentage of broadband
              households that subscribe, view, or purchase content from various
              OTT services. Parks Associates has survey questions to tease out
              the uptake and use of various business models. This data allows us
              to compare use and spending across services.
            </Text>
            <Text style={{ paddingTop: "1.5px" }}>
              3) Online and mobile data – This data includes website traffic,
              mobile app downloads, unique visitors, bounce rate, time spent on
              the site, traffic ranking relative to other sites, and other
              factors. Parks Associates has been using various sources since
              2014 for this traffic data and continuously assesses new traffic
              data sources that emerge.
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.section}>
        <Image src={login} style={styles.image} />
      </View>
    </Page>
  );
};

export default FrontPage;
