import React from "react";

const Banner = ({ heading, para }) => {
  return (
    <div className="m-5 flex flex-col p-4 bg-[#e9f6ff] rounded-md gap-4">
      <h3 className="text-pot-dark-blue font-semibold">What is {heading}?</h3>
      <div>
        {para?.split("\n").map((line) => {
          return <p className="text-xs">{line}</p>;
        })}
      </div>
    </div>
  );
};

export default Banner;
