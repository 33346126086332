import React from "react";

const Sort = ({ firstFill, secondFill }) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5154 1.86549L8.93748 0.305489C8.53165 -0.100761 7.87082 -0.100761 7.46707 0.302989L5.88665 1.86549C5.64123 2.10799 5.63915 2.50382 5.88165 2.74924C6.12415 2.99507 6.5204 2.99674 6.76582 2.75424L7.57623 1.95299V9.37507C7.57623 9.72007 7.85582 10.0001 8.20123 10.0001C8.54665 10.0001 8.82623 9.72007 8.82623 9.37507V1.95257L9.63665 2.75382C9.75832 2.87424 9.91748 2.93466 10.0762 2.93466C10.2375 2.93466 10.3983 2.87299 10.5208 2.74924C10.7633 2.50382 10.7608 2.10799 10.5154 1.86549Z"
        fill={firstFill}
      />
      <path
        d="M4.81923 7.26875C5.06173 7.51417 5.05965 7.91 4.81423 8.1525L3.23381 9.715C3.03215 9.91625 2.76673 10.0175 2.5009 10.0175C2.23506 10.0175 1.96673 9.91625 1.7634 9.71292L0.185478 8.15292C-0.0599383 7.91042 -0.0620216 7.51458 0.180478 7.26917C0.422978 7.02333 0.819229 7.02167 1.06465 7.26417L1.87506 8.06542V0.625C1.87506 0.28 2.15465 0 2.50006 0C2.84548 0 3.12506 0.28 3.12506 0.625V8.06542L3.93548 7.26417C4.18131 7.02167 4.57631 7.02292 4.81923 7.26875Z"
        fill={secondFill}
      />
    </svg>
  );
};

export default Sort;
