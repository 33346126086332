import React, { useEffect, useState } from "react";
// import { useQuery } from '@tanstack/react-query'

const UsersNumberTrack = ({ fetch = async () => {}, setters=[] }) => {
  // const { data } = useQuery({
  //   queryKey: ["userAndOrg"],
  //   queryFn: () => fetch("COUNT_OF_USERS_AND_ORGANIZATIONS"),
  //   cacheTime: (1000 * 60 * 60 * 24),
  //   initialData: {}
  // })

  const [ data, setData ] = useState([])
  // const [ loading, setLoading ] = useState(true)
  
  const fetchUserAndOrg = async () => {
    const data = await fetch("COUNT_OF_USERS_AND_ORGANIZATIONS")
    setData(data)
    // setLoading(false)
  }
  
  useEffect(() => {
    fetchUserAndOrg();
  }, [setters])

  // console.log(data)

  return (
    <div className="w-full h-full flex gap-x-8 text-xs">
      <div className="w-full h-full shadow-md border rounded-md px-5 py-3">
        <p>No. Of Active Users</p>
        <p className="text-pot-blue text-2xl mt-2 font-semibold">{data?.active_users || 0}</p>
      </div>
      <div className="w-full h-full shadow-md border rounded-md px-5 py-3">
        <p>No. Of Customers</p>
        <p className="text-pot-blue text-2xl mt-2 font-semibold">{data?.customer_count || 0}</p>
      </div>
      <div className="w-full h-full shadow-md border rounded-md px-5 py-3">
        <p>No. Of Disabled Users</p>
        <p className="text-pot-blue text-2xl mt-2 font-semibold">{data?.disabled_users || 0}</p>
      </div>
      <div className="w-full h-full shadow-md border rounded-md px-5 py-3">
        <p>No. Of Organization</p>
        <p className="text-pot-blue text-2xl mt-2 font-semibold">{data?.organizations || 0}</p>
      </div>
    </div>
  );
};

export default UsersNumberTrack;
