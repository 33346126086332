import React, { useCallback, useEffect, useState } from "react";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import EditorSearchIcon from "@atlaskit/icon/glyph/editor/search";
import QuadrantPicker from "../../../components/QuadrantPicker/QuadrantPicker";
import { debounce } from "lodash";
import { useRecoilState, useRecoilValue } from "recoil";
import { AccountUserAtom, FilterValuesAtom } from "../../../atom";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Filter from "../../../assets/Filter";
import RefreshIcon from "@atlaskit/icon/glyph/refresh";
import { DotPulse } from "@uiball/loaders";
import SearchIcon from "@atlaskit/icon/glyph/search";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { getFromLocalStorage } from "../../../utils/localStorage";

const TypeButtons = ({
  active,
  setActive,
  setSearchVal,
  quarterEnd,
  quarterStart,
  setQuarterEnd,
  setQuarterStart,
  downloadFile = () => {},
  showDownloadLoading,
}) => {
  const [inputVal, setInputVal] = useState("");
  const [filters, setFilters] = useRecoilState(FilterValuesAtom);
  const account = useRecoilValue(AccountUserAtom)
  const userType = getFromLocalStorage("user_type");

  const optimizedSearch = useCallback(
    debounce((val) => setSearchVal(val), 400),
    []
  );

  useEffect(() => {
    optimizedSearch(inputVal);
  }, [inputVal]);

  return (
    <div className="w-full flex justify-between">
      <div className="flex gap-x-2 py-1 mb-4 text-sm">
        <button
          onClick={() => setActive("PAID_SUBS")}
          className={`border rounded-md px-2 py-1 ${
            active === "PAID_SUBS"
              ? "bg-pot-blue text-white border-pot-blue"
              : "text-pot-t-grey"
          }`}
        >
          Subscription
        </button>
        <button
          onClick={() => setActive("MONTHLY_VIEWERS")}
          className={`border rounded-md px-2 py-1 ${
            active === "MONTHLY_VIEWERS"
              ? "bg-pot-blue text-white border-pot-blue"
              : "text-pot-t-grey"
          }`}
        >
          Ad-Based
        </button>
        <button
          onClick={() => setActive("TRANSACTIONAL_CUSTOMERS")}
          className={`border rounded-md px-2 py-1 ${
            active === "TRANSACTIONAL_CUSTOMERS"
              ? "bg-pot-blue text-white border-pot-blue"
              : "text-pot-t-grey"
          }`}
        >
          Transactional
        </button>
      </div>
      <div className="flex gap-x-2">
        {/* <div className="w-60 flex items-center h-fit py-1 rounded-md px-2 bg-pot-bg-blue border-pot-bg-blue">
          <EditorSearchIcon primaryColor="#1767A5" />
          <input
            value={inputVal}
            placeholder={"Search"}
            onChange={(e) => setInputVal(e.target.value)}
            className="outline-none text-tiny px-2 py-1.5 text-pot-blue placeholder:text-pot-blue bg-transparent"
          />
        </div> */}
        {/* <div className="rounded-md flex items-center bg-[#66BCFF]/10 py-1 px-2"> */}
        <div className="w-60 flex items-center h-fit py-1 rounded-md px-2 bg-pot-bg-blue border-pot-bg-blue">
          <SearchIcon size="small" primaryColor="#1767A5" />
          <input
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
            placeholder="Search"
            // className="py-1.5 px-2 w-52 2xl:w-60 bg-transparent text-tiny outline-none text-pot-blue placeholder:text-pot-blue"
            className="w-full outline-none text-tiny px-2 py-1.5 text-pot-blue placeholder:text-pot-blue bg-transparent"
          />
          {inputVal ? (
            <button className="mt-1" onClick={() => setInputVal("")}>
              <CrossIcon size="small" primaryColor="#1767A5" />
            </button>
          ) : (
            <div className="w-4"></div>
          )}
        </div>
        <Link to="/search">
          <motion.button
            // onClick={redirectToSearch}
            // onClick={openModal}
            // whileHover={{ scale: 1.1 }}
            className=" flex items-center text-sm text-pot-dark-blue bg-[#66BCFF]/10 hover:bg-[#66BCFF]/20 transition ease-in-out px-3 py-2 rounded"
          >
            <div className="stroke-pot-dark-blue">
              <Filter />
            </div>
            <p className="ml-1">Filter</p>
            {filters.length ? (
              <p className="ml-2 w-5 h-5 rounded-full flex justify-center items-center bg-pot-dark-blue text-white text-xs">
                {filters.reduce(
                  (acc, cur) => acc + Object.values(cur)[0]?.length,
                  0
                )}
              </p>
            ) : (
              <></>
            )}
          </motion.button>
        </Link>
        {filters.length ? (
          <motion.button
            // whileHover={{ scale: 1.1 }}
            onClick={() => setFilters([])}
            className="flex h-fit text-tiny items-center bg-pot-bg-blue/10 hover:bg-[#66BCFF]/20 transition ease-in-out px-2 py-2.5 rounded  text-pot-dark-blue"
          >
            <RefreshIcon size="small" primaryColor="#1767A5" />
            <p className="ml-1">Reset</p>
          </motion.button>
        ) : (
          <></>
        )}
        {(account?.user_permissions.includes("c_download_detail_pdf") || userType.toLowerCase().includes("admin") ) && (<button
          onClick={downloadFile}
          className={`w-40 ${showDownloadLoading && "cursor-wait"} h-fit py-2 flex justify-center gap-x-3 items-center rounded text-sm hover:bg-[#66BCFF0D]/20 transition ease-in-out bg-pot-bg-blue text-pot-dark-blue`}
        >
          {showDownloadLoading ? (
            <>
              <DotPulse speed={0.8} size={20} color="#1767A5" />
              Generating
            </>
          ) : (
            <>
              <DownloadIcon primaryColor="#1767A5" size="small" />
              Download
            </>
          )}
        </button>)}
        <QuadrantPicker
          quarterEnd={quarterEnd}
          quarterStart={quarterStart}
          setQuarterEnd={setQuarterEnd}
          setQuarterStart={setQuarterStart}
        />
      </div>
    </div>
  );
};

export default TypeButtons;
