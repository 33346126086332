import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { PdfColsAtom, PdfDataAtom, titleAtom } from "../../atom";
import { titles } from "../../utils/Titles";
import Table from "./Table";
import ViewersTrend from "./ViewersTrend";

const ColumnView = () => {
  const setTitle = useSetRecoilState(titleAtom);
  const navigate = useNavigate();
  const { type } = useParams();
  const [active, setActive] = useState(type === "results" ? 1 : 2);
  const setPdfCols = useSetRecoilState(PdfColsAtom);
  const setPdfData = useSetRecoilState(PdfDataAtom);

  useEffect(() => {
    setTitle("Search Results");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
  }, []);

  useEffect(() => {
    setPdfCols(null);
    setPdfData(null);
  }, [active]);

  return (
    <>
      <div className="z-50 sticky top-12">
        <div className="bg-white/90 flex mb-5">
          <button
            onClick={() => navigate("/search/results")}
            className={`min-w-max border-b-[3px] ${
              active === 1 ? "border-[#8BB3D2]" : "text-pot-grey-3"
            } py-5 px-10 font-semibold`}
          >
            Search Results
          </button>
          <button
            onClick={() => navigate("/search/viewers-trend")}
            className={`min-w-max border-b-[3px] ${
              active === 2 ? "border-[#8BB3D2]" : "text-pot-grey-3"
            } py-5 px-10 font-semibold`}
          >
            Viewers Trend
          </button>
          <div className="w-full border-b-[3px]"></div>
        </div>
      </div>
      {active === 1 ? <Table viewType={active} /> : <ViewersTrend />}
    </>
  );
};

export default ColumnView;
