export const validatePhone = (val, includeCode = false) => {
  let realValue
  if(includeCode) {
    realValue = val
    .match(/^\(?[+]?\(?\d{0,2}?\)?[-.\s]?\(?\d{0,3}?\)?[-.\s]?\d{0,4}[-.\s]?\d{0,4}[-.\s]?\d{0,9}$/)
    ?.join("");
  } else {
    realValue = val
    .match(/^\(?\d{0,3}?\)?[-.\s]?\d{0,4}[-.\s]?\d{0,4}[-.\s]?\d{0,9}$/)
    ?.join("");
  }
  if (!realValue) {
    if (val === "") return;
  }
  const stringLength = realValue.replace(/[^0-9]/g, "").length;
  const condition = includeCode ? (stringLength > 12 ) : (stringLength > 10)
  if (condition) {
    return;
  }
  return realValue;
};


export const validateEmail = (val) => {
    const re =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const realValue = val.match(re)?.join("")
    if(!realValue) {
        return false;
    }
    return true;
}
