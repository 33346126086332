import React from "react";

const Filter = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M12.8332 1.75H1.1665L5.83317 7.26833V11.0833L8.1665 12.25V7.26833L12.8332 1.75Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Filter;
