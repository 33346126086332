import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { TelemetryApi } from '../api/telemetry'
import { DownloadTelemetrySetterAtom, SelectedProfileCountryAtom } from '../atom'

const useDownloadTelemetry = () => {
    const [downloadTelemetryStart ,setDownloadTelemetryStart] = useRecoilState(DownloadTelemetrySetterAtom)
    const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom)

    const doTelemetry = async () => {
        const payload = { kind: downloadTelemetryStart, file_type: "PDF"}
        const params = { profile_country: selectedProfileCountry }
        await TelemetryApi.download(payload, { params })
        setDownloadTelemetryStart(null)
    }

    useEffect(() => {
        if(downloadTelemetryStart) {
            doTelemetry()
        }
    }, [downloadTelemetryStart])
}

export default useDownloadTelemetry