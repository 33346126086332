export const formatToKeyValue = (obj) => {
  let returnableObj = {};
  Object.keys(obj).forEach((key) =>
    obj[key].forEach((val) => (returnableObj[val.toLowerCase()] = key))
  );
  return returnableObj;
};

export const formatToViewable = (obj, arr) => {
  let returnableObj = {};
  arr.forEach((item) => {
    const val = obj[item.toLowerCase()];
    if (returnableObj[val]) {
      returnableObj[val].push(item);
    } else {
      returnableObj[val] = [item];
    }
  });
  return returnableObj;
};

export const formatToAllValObj = (obj, arr) => {
  let retObj = {};
  arr.forEach((item) => {
    retObj[item] = obj[item];
  });
  return retObj;
};

const formatInActiveNamesToOptions = (values) => {
  const newObj = {};
  values.forEach((eachVal) => {
    const [value, label, active] = eachVal;
    if (active) return;
    if (/[a-zA-Z]/.test(label[0])) {
      if (newObj[label[0].toUpperCase()]) {
        newObj[label[0].toUpperCase()].push({ label, value, active });
      } else {
        newObj[label[0].toUpperCase()] = [{ label, value, active }];
      }
    } else {
      if (newObj["#"]) {
        newObj["#"].push({ label, value, active });
      } else {
        newObj["#"] = [{ label, value, active }];
      }
    }
  });
  return Object.keys(newObj)
    .sort()
    .map((letter) => {
      newObj[letter].sort((a, b) => a.label.localeCompare(b.label));
      return { label: letter, options: newObj[letter] };
    });
};

const formatTempNamesToOptions = (values) => {
  const newObj = {};
  values.forEach((eachVal) => {
    const [value, label, active] = eachVal;
    if (/[a-zA-Z]/.test(label[0])) {
      if (newObj[label[0].toUpperCase()]) {
        newObj[label[0].toUpperCase()].push({ label, value, active });
      } else {
        newObj[label[0].toUpperCase()] = [{ label, value, active }];
      }
    } else {
      if (newObj["#"]) {
        newObj["#"].push({ label, value, active });
      } else {
        newObj["#"] = [{ label, value, active }];
      }
    }
  });
  return Object.keys(newObj)
    .sort()
    .map((letter) => {
      newObj[letter].sort((a, b) => a.label.localeCompare(b.label));
      return { label: letter, options: newObj[letter] };
    });
};

const formatNamesToOptions = (values) => {
  const newObj = {};
  values.forEach((eachVal) => {
    const [value, label, active] = eachVal;
    if (!active) return;
    if (/[a-zA-Z]/.test(label[0])) {
      if (newObj[label[0].toUpperCase()]) {
        newObj[label[0].toUpperCase()].push({ label, value, active });
      } else {
        newObj[label[0].toUpperCase()] = [{ label, value, active }];
      }
    } else {
      if (newObj["#"]) {
        newObj["#"].push({ label, value, active });
      } else {
        newObj["#"] = [{ label, value, active }];
      }
    }
  });
  return Object.keys(newObj)
    .sort()
    .map((letter) => {
      newObj[letter].sort((a, b) => a.label.localeCompare(b.label));
      return { label: letter, options: newObj[letter] };
    });
};

const formatXToOptions = (values, addType) => {
  const newObj = {};
  values.forEach((eachVal) => {
    const [value, label] = eachVal;
    const [region, item] = label;
    if (newObj[region.trim().toUpperCase()]) {
      const settable = { label: item.trim(), value };
      if (addType) {
        settable.type = 2;
      }
      newObj[region.trim().toUpperCase()].push(settable);
    } else {
      const settable = { label: item.trim(), value };
      if (addType) {
        settable.type = 2;
      }
      newObj[region.trim().toUpperCase()] = [settable];
    }
  });
  return Object.keys(newObj).map((region) => ({
    label: region,
    options: newObj[region],
  }));
};

// export const formatNamesToIdList = (obj) => {
//   let list = [];
//   list = Object.values(obj).reduce(
//     (acc, cur) => acc.concat(...cur.options.map((each) => each.value)),
//     []
//   );
// };

const formatToNamesList = (arr) => {
  const returnableArr = [];
  arr.forEach((each) => returnableArr.push(...each.options));
  return returnableArr;
};

export const formatToOptions = (obj) => {
  const returnableObj = {};
  let val;
  Object.keys(obj).forEach((key) => {
    if (key === "names") {
      val = formatNamesToOptions(obj[key]);
      const anotherVal = formatTempNamesToOptions(obj[key]);
      returnableObj.tempNames = anotherVal;
      const inActiveVal = formatInActiveNamesToOptions(obj[key]);
      returnableObj.inActiveNames = inActiveVal;
      returnableObj.namesArr = formatToNamesList(val);
    } else if (key === "countries") {
      val = formatXToOptions(obj[key], false);
    } else if (key === "device_supports") {
      const filteredVal = formatXToOptions(
        obj[key].filter((each) => !!each[2]),
        true
      );
      returnableObj["filtered_devices"] = filteredVal;
      val = formatXToOptions(obj[key], true);
    } else if (key === "target_markets") {
      val = formatXToOptions(obj[key], false);
    } else if (key === "distribution_platforms") {
      const filteredVal = obj[key]
        .filter((each) => !!each[2])
        .map((val) => ({ label: val[1], value: val[0], type: 1 }));
      returnableObj["filtered_platforms"] = filteredVal;
      val = obj[key].map((val) => ({ label: val[1], value: val[0], type: 1 }));
    } else {
      val = Array.isArray(obj[key])
        ? obj[key].map((val) => ({ label: val[1], value: val[0] }))
        : obj[key];
    }
    returnableObj[key] = val;
  });
  return returnableObj;
};

export const formatToVisibility = (arr) => {
  const keyVal = {};
  const visible = {};
  const sortable = {};
  arr.forEach((item) => {
    if (item[0] === "name") return;
    if (["is_free_trial", "target_market", "nps", "churn"].includes(item[0])) {
      visible[item[0]] = false;
    } else {
      visible[item[0]] = true;
    }
    keyVal[item[0]] = item[1];
    sortable[item[0]] = item[2];
  });
  return { keyVal, visible, sortable };
};

export const formatToParams = (arr) => {
  const notInNormal = [
    "business_models",
    "10",
    "20",
    "30",
    "40",
    "distribution_types",
    "launch_date",
    "closed_date",
    "pricing",
    "subscribers",
    "names",
    "platform_devices",
  ];
  let normalObj = {};
  let notNormalObj = {};
  arr.forEach((obj) => {
    if (notInNormal.includes(Object.keys(obj)[0])) {
      let settableVal;
      let similarSettableVal;
      if (Object.keys(obj)[0] === "business_models") {
        settableVal = { business_models: Object.values(obj)[0][0].value };
      } else if (Object.keys(obj)[0] === "distribution_types") {
        settableVal = { distribution_types: Object.values(obj)[0][0].value };
      } else if (Object.keys(obj)[0] === "10") {
        settableVal = {
          original_content:
            String(Object.values(obj)[0][0].value).slice(0, 2) !== "No",
        };
      } else if (Object.keys(obj)[0] === "20") {
        settableVal = {
          download_feature:
            String(Object.values(obj)[0][0].value).slice(0, 2) !== "No",
        };
      } else if (Object.keys(obj)[0] === "30") {
        settableVal = {
          free_trial:
            String(Object.values(obj)[0][0].value).slice(0, 2) !== "No",
        };
      } else if (Object.keys(obj)[0] === "40") {
        settableVal = {
          live_content:
            String(Object.values(obj)[0][0].value).slice(0, 2) !== "No",
        };
      } else if (Object.keys(obj)[0] === "launch_date") {
        const date = Object.values(obj)[0][0].value.split(" - ");
        const start = date[0].split("-");
        const end = date[1].split("-");
        settableVal = {
          launch_date: {
            date_from: [start[2], start[0], start[1]].join("-"),
            date_to: [end[2], end[0], end[1]].join("-"),
          },
        };
      } else if (Object.keys(obj)[0] === "closed_date") {
        const date = Object.values(obj)[0][0].value.split(" - ");
        const start = date[0].split("-");
        const end = date[1].split("-");
        settableVal = {
          closed_date: {
            date_from: [start[2], start[0], start[1]].join("-"),
            date_to: [end[2], end[0], end[1]].join("-"),
          },
        };
      } else if (Object.keys(obj)[0] === "pricing") {
        const settable = Object.values(obj)[0].map((each) => each.value);
        settableVal = { pricing: settable };
      } else if (Object.keys(obj)[0] === "subscribers") {
        const settable = Object.values(obj)[0].map((each) => each.value);
        settableVal = { subscribers: settable };
      } else if (Object.keys(obj)[0] === "names") {
        const values = Object.values(obj)[0].map((each) => each.value);
        settableVal = { names: values };
      } else if (Object.keys(obj)[0] === "platform_devices") {
        const disPlatVal = Object.values(obj)[0]
          .filter((each) => each.type === 1)
          .map((each) => each.value);
        const devSuppVal = Object.values(obj)[0]
          .filter((each) => each.type === 2)
          .map((each) => each.value);
        if (disPlatVal.length) {
          settableVal = { distribution_platforms: { OR: disPlatVal } };
        }
        if (devSuppVal.length) {
          similarSettableVal = { device_supports: { OR: devSuppVal } };
        }
      } else {
        settableVal = obj;
      }
      notNormalObj = { ...notNormalObj, ...settableVal, ...similarSettableVal };
    } else {
      const key = Object.keys(obj)[0];
      const values = Object.values(obj)[0].map((each) => each.value);
      const settableVal = { [key]: { OR: values } };
      normalObj = { ...normalObj, ...settableVal };
    }
  });

  return { ...normalObj, ...notNormalObj };
};

export const formatNamesToObj = (names) =>
  names
    .map((each) => each.options)
    .reduce((acc, cur) => {
      let newObj = {};
      cur.forEach((each) => {
        newObj[each.label] = each.value;
      });
      return { ...acc, ...newObj };
    }, {});

export const formatNamesToId = (names, obj) => {
  let settable;
  const realNames = formatNamesToObj(names);
  settable = Object.values(obj)
    .filter((each) => each)
    .map((each) => realNames[each]);
  const ids = Object.keys(obj).reduce(
    (acc, cur) => ({ ...acc, [cur]: realNames[obj[cur]] }),
    {}
  );
  return { settable, ids };
};

export const formatToObj = (names) => {
  return names.reduce((acc, cur) => {
    acc[cur[1]] = cur[0];
    return acc;
  }, {});
};
