import React, { useRef, useState } from "react";
import CalendarIcon from "@atlaskit/icon/glyph/calendar";
import DateRangePicker from "../../../components/DateRangePicker";

const DateRangePickerInput = ({
  heading,
  keyValue,
  allFilters,
  setAllFilters,
  placeholder,
}) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [mouseOnPicker, setMouseOnPicker] = useState(false);
  const inputRef = useRef(null);

  // useEffect(() => {
  //   if(mouseOnPicker) inputRef.current.focus()
  // }, [mouseOnPicker])

  return (
    <div
      className={`box-border ${
        openDatePicker ? "z-[93]" : "cursor-pointer"
      } relative h-10`}
    >
      <p className="mb-2">{heading}</p>
      <div
        onClick={() => setOpenDatePicker(true)}
        className={`w-full box-border ${
          openDatePicker
            ? "bg-white border-2 border-pot-light-blue-2"
            : "bg-[#EBECF0]/60 hover:bg-[#DFE1E6]/60 transition ease-in-out border-2 border-[#EBECF0]/60"
        } transition ease-in-out duration-200 flex justify-between p-1.5 rounded-sm`}
      >
        {openDatePicker ? (
          <input
            ref={inputRef}
            autoFocus
            value={""}
            placeholder={placeholder || ""}
            className="w-full outline-none bg-inherit text-tiny cursor-pointer caret-transparent"
            //   onFocus={(e) => {
            //     setOpenDatePicker(true);
            //   }}
            onBlur={(e) => {
              e.stopPropagation();
              if (!mouseOnPicker) {
                setOpenDatePicker(false);
              }
            }}
          />
        ) : (
          <div className="w-full h-full flex items-center">
            <p className="mt-1 text-slate-500 text-tiny bg-inherit">{placeholder}</p>
          </div>
        )}
        <CalendarIcon 
          // primaryColor="#1767A5"
          size="medium" />
      </div>
      {openDatePicker && (
        <div
          // onFocus={() => {
          //   setOpenDatePicker(true);
          // }}
          // onBlur={(e) => {
          //   e.stopPropagation();
          //   setOpenDatePicker(false);
          // }}
          onMouseOver={() => {
            setMouseOnPicker(true);
            inputRef.current.focus();
          }}
          onMouseOut={() => {
            setMouseOnPicker(false);
          }}
          className="w-fit mt-2 bg-white border rounded-sm shadow-lg"
        >
          <DateRangePicker
            keyValue={keyValue}
            setAllFilters={setAllFilters}
            allFilters={allFilters}
            setOpenDatePicker={setOpenDatePicker}
          />
        </div>
      )}
      {/* <Textfield 
        elemAfterInput={<div className="mx-2"><CalendarIcon size="medium" /></div>}
        className="border"
    /> */}
    </div>
  );
};

export default DateRangePickerInput;
