import React, { useEffect, useRef, useState } from "react";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import QuadrantRangePicker from "./QuadrantRangePicker";
import { QuarterRangeAtom } from "../../atom";
import { useRecoilValue } from "recoil";
import useOnClickOutside from "../../hooks/useClickOutside";
import { getLastQuarters } from "../../utils/helperFunc";

const QuadrantPicker = ({
  quarterEnd,
  quarterStart,
  setQuarterStart,
  setQuarterEnd,
  page = "DEFAULT",
  customQuarterRange,
  defaultValue = 12,
  data,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openRangeDropdown, setOpenRangeDropdown] = useState(false);
  const [selectedQuater, setSelectedQuater] = useState(defaultValue);
  const [initial, setInitial] = useState(true);
  const [selectedQuarterStart, setSelectedQuarterStart] = useState(null);
  const [selectedQuarterEnd, setSelectedQuarterEnd] = useState(null);
  const quarterRange = useRecoilValue(QuarterRangeAtom);
  const quarterRef = useRef(null);
  const [innerText, setInnerText] = useState(
    defaultValue === 12 ? "12 Quarters" : "Quarters"
  );

  useOnClickOutside(quarterRef, () => {
    setOpenDropdown(false);
    setOpenRangeDropdown(false);
  });

  useEffect(() => {
    if (initial) {
      setInitial(false);
      return;
    }
    if (openDropdown) return;
    if (selectedQuater === "custom") return;

    if (page === "IndustryBenchmark") {
      const { startQuarter, startQuarterYear, endQuarter, endQuarterYear } =
        getLastQuarters(data, selectedQuater);
      setQuarterEnd([endQuarter, endQuarterYear]);
      setQuarterStart([startQuarter, startQuarterYear]);
    } else {
      const endQuarter = Number(quarterRange.max.split(" ")[0]) - 1;
      const endQuarterYear = Number(quarterRange.max.split(" ")[1]);
      const modableVal =
        page === "DEFAULT"
          ? endQuarter - (selectedQuater - 1)
          : (endQuarter - selectedQuater) * 2;
      const startQuarter =
        modableVal % 4 < 0 ? 4 + (modableVal % 4) : modableVal % 4;
      const diff =
        modableVal % 4 !== 0
          ? Math.trunc(Math.abs(modableVal) / 4 + 1)
          : Math.trunc(Math.abs(modableVal) / 4);
      console.log(modableVal, "modval");
      const startQuarterYear = endQuarterYear - diff;
      if (
        quarterStart[0] === startQuarter &&
        quarterStart[1] === startQuarterYear
      )
        return;
      setQuarterEnd([endQuarter, endQuarterYear]);
      setQuarterStart([startQuarter, startQuarterYear]);
    }
  }, [openDropdown]);

  return (
    <div ref={quarterRef} className="relative text-sm">
      <button
        onClick={() => setOpenDropdown((prev) => !prev)}
        className="z-50 flex items-center bg-pot-bg-blue rounded gap-x-1 px-4 py-1.5 text-sm text-pot-blue"
      >
        {innerText}
        <HipchatChevronDownIcon />
      </button>
      {
        openDropdown && !openRangeDropdown && (
          <div className="absolute mt-1 w-60 h-fit z-50 text-sm rounded-md bg-white border shadow-md py-2 right-0 ">
            {/* <Checkbox label="Last 4 Quarters" isChecked={selectedQuater === 4} /> */}
            <button
              onClick={() => {
                setSelectedQuater(4);
                setOpenDropdown(false);
                setInnerText("4 Quarters");
              }}
              className="w-full text-left px-3 py-1"
            >
              Last 4 Quarters
            </button>
            {/* <Checkbox
              onChange={() => {
                setSelectedQuater(8);
                setOpenDropdown(false);
              }}
              label="Last 8 Quarters"
              isChecked={selectedQuater === 8}
            /> */}
            <button
              onClick={() => {
                setSelectedQuater(8);
                setOpenDropdown(false);
                setInnerText("8 Quarters");
              }}
              className="w-full text-left px-3 py-1"
            >
              Last 8 Quarters
            </button>
            {/* <Checkbox
              onChange={() => {
                setSelectedQuater(12);
                setOpenDropdown(false);
              }}
              label="Last 12 Quarters"
              isChecked={selectedQuater === 12}
            /> */}
            <button
              onClick={() => {
                setSelectedQuater(12);
                setOpenDropdown(false);
                setInnerText("12 Quarters");
              }}
              className="w-full text-left px-3 py-1"
            >
              Last 12 Quarters
            </button>
            {/* <Checkbox
              onChange={() => {
                setSelectedQuater("custom");
                setOpenDropdown(false);
                setOpenRangeDropdown(true);
              }}
              label="Custom"
              isChecked={selectedQuater === "custom"}
            /> */}
            <button
              onClick={() => {
                setSelectedQuater("custom");
                setOpenDropdown(false);
                setOpenRangeDropdown(true);
                setInnerText("Quarters");
              }}
              className="text-left px-3 py-1"
            >
              Custom
            </button>
          </div>
        )
        // ) : (
        //   <QuadrantRangePicker setOpenDropdown={setOpenDropdown} setSelectedQuater={setSelectedQuater} />
        // ))
      }
      {openRangeDropdown && (
        <QuadrantRangePicker
          setOpenDropdown={setOpenDropdown}
          setOpenRangeDropdown={setOpenRangeDropdown}
          setSelectedQuater={setSelectedQuater}
          selectedQuarterStart={selectedQuarterStart}
          setSelectedQuarterStart={setSelectedQuarterStart}
          selectedQuarterEnd={selectedQuarterEnd}
          setSelectedQuarterEnd={setSelectedQuarterEnd}
          setQuarterStart={setQuarterStart}
          setQuarterEnd={setQuarterEnd}
          customQuarterRange={
            customQuarterRange ? customQuarterRange : quarterRange
          }
        />
      )}
    </div>
  );
};

export default QuadrantPicker;
