import React from 'react'
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import check from "../../assets/pdf_png/check.png"
import cross from "../../assets/pdf_png/cross.png"

const Presence = ({ detail }) => {
    const styles = StyleSheet.create({
        container: {
            width: "100%",
            marginLeft: "10px",
            borderRadius: "8px",
            border: 1,
            borderColor: "#F0F1F8",
            display: "flex",
            justifyContent: "space-around",
            paddingVertical: "5px",
            paddingHorizontal: "8px",
        },
        value_container: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginVertical: "1px"
        },
        title: {
            fontWeight: "semibold",
            width: "100%"
        },
        img: {
            width: "7px",
            height: "7px",
        }
    })

  return (
    <View style={styles.container}>
        <View style={styles.value_container}>
            <Text style={styles.title}>Download Content</Text>
            <Image src={detail.download_feature?.existing ? check : cross} style={styles.img}  />
        </View>
        <View style={styles.value_container}>
            <Text style={styles.title}>Original Content</Text>
            <Image src={detail.original_content?.existing ? check : cross} style={styles.img} />
        </View>
        <View style={styles.value_container}>
            <Text style={styles.title}>Live Content</Text>
            <Image src={detail.live_content?.existing ? check : cross} style={styles.img} />
        </View>
        <View style={styles.value_container}>
            <Text style={styles.title}>Free Trial</Text>
            <Image src={typeof(detail.free_trial?.existing[0]) === "string" ? detail.free_trial?.existing[0].toLowerCase() === "yes" ? check : cross : detail.free_trial?.existing ? check : cross} style={styles.img} />
        </View>
    </View>
  )
}

export default Presence