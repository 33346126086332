import React, { useState } from "react";
import { createPortal } from "react-dom";
import ArrowLeftIcon from "@atlaskit/icon/glyph/arrow-left";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import { DotPulse } from "@uiball/loaders";
import { LibraryApi } from "../../../api/library";
import { toast } from "react-toastify";

const PdfViewerModal = ({ children, handleCancel = () => {}, article }) => {
  const [loading, setLoading] = useState(false);

  const downloadData = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { id, file_url: url, name } = article;
    try {
      setLoading(id);
      const response = await LibraryApi.download(url);
      if (response.status !== 200) {
        const errorString = await response.data.text();
        const errorObj = JSON.parse(errorString);
        toast.error(errorObj.errors.error_message.join(", "));
        setLoading("");
        return;
      }
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.target = "_blank";
      const settableName = name.endsWith(".pdf") ? name : name + ".pdf";
      link.setAttribute("download", settableName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLoading("");
      handleCancel();
    } catch (e) {
      toast.error(JSON.stringify(e));
      setLoading("");
    }
  };

  return createPortal(
    <div
      onClick={handleCancel}
      className="z-[98] absolute top-0 flex flex-col items-center left-0 h-screen w-screen overflow-auto bg-pot-tuxedo/50"
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="w-full z-[99] sticky top-0 h-fit py-1 px-[20vh] flex justify-between items-center bg-[#00020d]/80"
      >
        <button className="pt-1" onClick={handleCancel}>
          <ArrowLeftIcon primaryColor="white" />
        </button>
        <p className="text-white font-semibold">{article.name}</p>
        {loading ? (
          <DotPulse color="white" size={30} />
        ) : (
          <button className="pt-1.5" onClick={downloadData}>
            <DownloadIcon primaryColor="white" />
          </button>
        )}
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="z-[98] w-fit"
      >
        {children}
      </div>
    </div>,
    document.getElementById("pdf-view")
  );
};

export default PdfViewerModal;
