import React from "react";
import InputField from "./components/InputField";
import { motion } from "framer-motion";
import { MacScrollbar } from "mac-scrollbar";
import { DotPulse } from "@uiball/loaders";
import { Checkbox } from "@atlaskit/checkbox";
import { useState } from "react";
import { ContentState, EditorState, Modifier } from "draft-js";
import { useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { toolbarOptions } from "../utils/toolbarOptions";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../styles/EditorStyles.css";
import { convertedToHTML, handlePastedText } from "../utils/helperFunc";
import { tags } from "../utils/tags";
import { convertFromHTML } from "draft-convert";

const EmailTemplateForm = ({
  isEdit,
  formData,
  setFormData,
  onSubmit,
  error,
  setError,
  loading = false,
  isCustomizable,
  setIsCustomizable,
}) => {
  const [initialRender, setInitialRender] = useState(true);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleTagClick = (tagLabel) => {
    const currentContent = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const contentWithEntity = currentContent.createEntity("TAG", "IMMUTABLE", {
      label: tagLabel,
    });
    const entityKey = contentWithEntity.getLastCreatedEntityKey();

    const newContentState = Modifier.replaceText(
      currentContent,
      selectionState,
      tagLabel,
      null,
      entityKey
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );
    setEditorState(
      EditorState.forceSelection(
        newEditorState,
        newContentState.getSelectionAfter()
      )
    );
  };

  useEffect(() => {
    let html = convertedToHTML(editorState.getCurrentContent());
    setFormData((prev) => ({
      ...prev,
      body: html,
    }));
  }, [editorState]);

  useEffect(() => {
    if (isEdit && formData?.body.length && initialRender) {
      const contentState = convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === "a") {
            return createEntity("LINK", "MUTABLE", { url: node.href });
          }
        },
      })(formData?.body);
      if (contentState) {
        const newEditorState = EditorState.createWithContent(contentState);
        const currentContent = newEditorState.getCurrentContent();
        const contentLength = currentContent.getPlainText("").length;
        if (contentLength > 0) {
          setEditorState(newEditorState);
          setInitialRender(false);
        }
      }
    }
  }, [isEdit, formData]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      is_customizable: isCustomizable,
    }));
  }, [isCustomizable]);

  return (
    <MacScrollbar className="h-fit">
      <section className="pb-8">
        <InputField
          heading="Email Template Name"
          className="mt-5"
          formData={formData}
          setFormData={setFormData}
          value="name"
          placeholder="Give a name to this template"
          error={error}
          setError={setError}
          required
        />
        <InputField
          heading="Subject"
          className="mt-5"
          formData={formData}
          setFormData={setFormData}
          value="subject"
          placeholder="Subject of your email"
          error={error}
          setError={setError}
        />
        <div className="mt-5">
          <div className="font-semibold text-xs flex text-left mb-3">Body</div>
          <div className="flex border-2 rounded-md divide-x">
            <Editor
              editorState={editorState}
              handlePastedText={(e) =>
                handlePastedText(e, editorState, setEditorState)
              }
              toolbarClassName="border-0"
              wrapperClassName="wrapperClassName"
              editorClassName="editor no-scrollbar"
              editorStyle={{
                minHeight: "230px",
                height: "auto",
                overflow: "auto",
                textIndent: "10px",
                border: 0,
                borderTop: "1px solid #cfd8df",
              }}
              wrapperStyle={{
                width: "100%",
                overflow: "hidden",
              }}
              placeholder="Type Email Content"
              toolbarStyle={{
                marginBottom: 0,
                border: 0,
                borderRadius: "8px",
                width: "70%",
              }}
              toolbar={toolbarOptions}
              onEditorStateChange={(e) => setEditorState(e)}
            />
            <div className="flex flex-col gap-2 w-[30%] p-2 text-pot-blue font-semibold overflow-hidden">
              {tags.map((tag) => (
                <p
                  className="bg-pot-light-blue-3/25 px-2 py-1 rounded-md w-fit cursor-pointer"
                  onClick={() => handleTagClick(tag.label)}
                >
                  {tag.label}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="my-4 flex items-center w-fit">
          <Checkbox
            isChecked={isCustomizable}
            label={
              <span className="cursor-pointer">
                Allow this template to be customized
              </span>
            }
            onChange={() =>
              setIsCustomizable(isCustomizable === true ? false : true)
            }
            className=""
          />
        </div>
        <div className="w-full mt-4 flex flex-col justify-center items-center">
          <p className="text-red-600 h-4">{error?.non_field_errors}</p>
          <motion.button
            onClick={onSubmit}
            whileHover={{ scale: 1.1 }}
            className="bg-pot-blue px-4 py-2 w-fit flex gap-x-2 items-center text-white text-sm font semibold rounded-md"
          >
            {loading && <DotPulse size={12} speed={0.8} color="white" />}
            {isEdit ? "Save" : "Create"}
          </motion.button>
        </div>
      </section>
    </MacScrollbar>
  );
};

export default EmailTemplateForm;
