import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ColumnKeyValueAtom } from "../atom";

const useCompareViewPlatformData = (comparedHeaders, comparedValues) => {
  // const initialCompareViewData = useRecoilValue(ColumnKeyValueAtom)

  const initialCompareViewData = [
    {
      keyName: "No. of Profiles",
    },
    {
      keyName: "Profiles",
    },
  ];

  const [comparePlatformData, setComparePlatformData] = useState(
    Object.keys(initialCompareViewData).map((each) => ({
      mainVal: initialCompareViewData[each].keyName,
      keyName: initialCompareViewData[each].keyName,
    }))
  );

  // useEffect(() => {
  //     if(!comparedHeaders) return
  //     const settableCompareData = compareViewData.map((each) => ({...each, ...comparedHeaders}))
  //     setCompareViewData(settableCompareData)
  // }, [comparedHeaders])

  useEffect(() => {
    if (!comparedValues) return;

    const countRow = {}
    let highestCount = 0
    Object.keys(comparedHeaders).forEach((each) => {
      if(!comparedHeaders[each]) return
      const id = comparedHeaders[each]?.value || 0
      countRow[each] = comparedValues[id]?.length
      if(countRow[each] > highestCount) {
        highestCount = countRow[each]
      }
    })
    if(!Object.keys(countRow)) return
    countRow.keyName = "No. of Profiles"

    const rows = Array.from({ length: highestCount }).map((_, idx) => {
      let rowVals = {}
      Object.keys(comparedHeaders).forEach((each) => {
        const id = comparedHeaders[each]?.value
        if(!id) return null
        rowVals[each] = comparedValues[id]?.[idx] || ""
        if(!idx) {
          rowVals.keyName = "Profiles"
        }
      })
      return(rowVals)
    })

    console.log(rows)

    setComparePlatformData([
      countRow,
      ...(rows.length ? rows : [{ keyName: "Profiles"}])
    ])

    // const settableCompareData = comparePlatformData.map((each) => {
    //   const label = each.mainVal;
    //   const settable = Object.keys(comparedValues).reduce((acc, cur) => {
    //     return { ...acc, [cur]: comparedValues[cur]?.[label] };
    //   }, {});
    //   return { ...each, ...settable };
    // });
    // console.log(settableCompareData, "settable")
    // setComparePlatformData(settableCompareData);


  }, [comparedValues]);

  return comparePlatformData;
};

export default useCompareViewPlatformData;
