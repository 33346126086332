import React, { memo } from "react";
import { strokes } from "../../utils/chartsData";
import { names } from "../../utils/chartsData";
import {
  BarChart,
  Bar,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { DotPulse } from "@uiball/loaders";
import { useRecoilValue } from "recoil";
import { ResizingAtom } from "../../atom";

const TrendHorizontalBarChart = ({
  selectedBarQuarter,
  chartLoading,
  selectedOtts,
  type,
}) => {
  const containerResizing = useRecoilValue(ResizingAtom);

  if (chartLoading || containerResizing) {
    return (
      <div className="w-full flex justify-center h-[350px] items-center">
        <DotPulse size={40} color="#1767A5" speed={0.8} />
      </div>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        layout="vertical" // Set the layout to vertical for horizontal bars
        data={selectedOtts}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number" // Change the XAxis type to number for horizontal bars
          label={{
            value:
              type === "PAID_SUBS"
                ? "Subscribers (M)"
                : type === "MONTHLY_VIEWERS"
                ? "Viewers (M)"
                : "Transactional Customers (M)",
            position: "insideBottom",
            offset: -20,
          }}
        />
        <YAxis dataKey="name" type="category" />{" "}
        {/* YAxis is now the category axis */}
        <Tooltip
          formatter={(value, name) => [`${value.toFixed(1)} M`, `Q${name}`]}
          cursor={{ fill: "fff", fillOpacity: "0" }}
        />
        <Bar
          type="monotone"
          dataKey={selectedBarQuarter}
          barSize={20}
          // stackId="a" // If you want to stack bars, you can add a stackId
        >
          {selectedOtts.map((each, index) => (
            <Cell key={`cell-${index}`} fill={each.color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default memo(TrendHorizontalBarChart);
