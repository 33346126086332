import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import Doc from "../../../assets/Doc";
import UploadPlus from "../../../assets/UploadPlus";
import { motion } from "framer-motion";
import { BASE_URL } from "../../../api/axios";
import { getFromLocalStorage } from "../../../utils/localStorage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  DescriptionAtom,
  TableInfoAtom,
  UploadVerifyIdAtom,
  VerificationResultAtom,
} from "../../../atom";
import { useRecoilState, useSetRecoilState } from "recoil";
import axios from "axios";

const Dropzone = ({ hasTable, hasDownload, callAgain, setCallAgain }) => {
  const [showUploadRegion, setShowUploadRegion] = useState(false);
  const [acceptedFileName, setAcceptedFileName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showProceed, setShowProceed] = useState(false);
  const [uploadVerifyId, setUploadVerifyId] = useState(null);
  const [canceled, setCanceled] = useState(false);
  const setUploadId = useSetRecoilState(UploadVerifyIdAtom);
  const [quarter, setQuarter] = useState(null);
  const [date, setDate] = useState(null);
  const [result, setVerificationResult] = useRecoilState(
    VerificationResultAtom
  );
  const [description, setDescription] = useRecoilState(DescriptionAtom);
  const [tableInfo, setTableInfo] = useRecoilState(TableInfoAtom);
  const sourceRef = useRef(null);
  const navigate = useNavigate();

  const uploadFile = async (accepted) => {
    setAcceptedFileName(accepted.name);
    setUploadProgress(2);
    setShowUploadRegion(true);
    sourceRef.current = axios.CancelToken.source();
    const formData = new FormData();
    formData.append("data", accepted, accepted.name);
    setUploadProgress(5);
    setDescription(null);

    const incrementUploadProgress = () => {
      if (uploadProgress >= 90) {
        clearInterval(intervalId);
      } else {
        setUploadProgress((prev) => (prev < 90 ? prev + 0.2 : prev));
      }
    };

    const intervalId = setInterval(incrementUploadProgress, 300);
    try {
      const { data: response } = await axios.post(
        `/nps-churn/admin/upload/`,
        formData,
        {
          baseURL: BASE_URL,
          cancelToken: sourceRef.current.token,
          headers: { Authorization: `Token ${getFromLocalStorage("token")}` },
        }
      );
      setUploadProgress(100);
      if (response.success) {
        setCallAgain((prev) => !prev);
        setVerificationResult("SUCCESS");
        setUploadVerifyId(response.data?.filename);
        setDescription(response.data?.errors_list);
        setTableInfo(response.data?.info);
        setShowProceed(true);
        if (response.data.status === "FAILED") {
          setVerificationResult("ERROR");
          setTableInfo(response?.data?.errors_found);
          toast.error("File Upload Error ! ");
        }
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        setCanceled(false);
      } else {
        toast.error(
          e?.response?.data?.errors?.error_message || "File Upload Error ! "
        );
      }
      setShowUploadRegion(false);
      setDate(null);
      setQuarter(null);
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      await uploadFile(acceptedFiles[0]);
    },
    [date, quarter]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    onDrop,
    maxFiles: 1,
    multiple: false,
  });

  useEffect(() => {
    if (uploadVerifyId) {
      setShowProceed(true);
    }
  }, [uploadVerifyId]);

  useEffect(() => {
    if (canceled) {
      if (!date || !quarter) {
        setShowUploadRegion(false);
        setCanceled(false);
        return;
      }
      sourceRef.current.cancel("canceled");
    }
  }, [canceled]);

  return (
    <div className="relative w-full">
      {showUploadRegion ? (
        <div
          className={`${hasTable ? "h-[20vh]" : "h-[50vh]"} ${
            !hasDownload && "mt-10"
          } mx-10 border-4 border-slate-100 border-dashed rounded-lg flex flex-col gap-2 justify-center items-center`}
        >
          <div className="w-[30vw] flex justify-between gap-3">
            <Doc />
            <div className="w-full flex flex-col justify-between pb-2">
              <div className="w-full flex justify-between px-3 text-sm">
                <p className="w-60 truncate">{acceptedFileName}</p>
              </div>
              <div className="w-full h-[3px] relative z-50 rounded-full mx-3 bg-pot-bg-grey-2">
                <motion.div
                  className="h-[3px] bg-pot-dark-blue rounded-full"
                  initial={{ width: 0 }}
                  animate={{ width: `${uploadProgress}%` }}
                ></motion.div>
              </div>
            </div>
          </div>
          {showProceed ? (
            <button
              onClick={() => {
                navigate(`/admin/nps-churn/upload-data/result`);
                setUploadId(uploadVerifyId);
              }}
              className={`bg-pot-dark-blue text-white px-4 py-2 font-semibold text-sm rounded-md `}
            >
              View Result
            </button>
          ) : (
            <div className="h-12 w-12"></div>
          )}
        </div>
      ) : (
        <div
          {...getRootProps({})}
          className={`${hasTable ? "h-[20vh]" : "h-[50vh]"} ${
            !hasDownload && "mt-10"
          } cursor-pointer mx-10 border-4  ${
            isDragActive ? "border-pot-blue/400" : "border-slate-100 "
          }border-dashed cursor-default rounded-lg flex relative justify-center items-center`}
        >
          <div className="flex flex-col justify-center items-center">
            <div onClick={open} className="cursor-pointer">
              <UploadPlus />
            </div>
            {isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>Upload an excel file here</p>
            )}
            <p className="w-[40vw] mt-4 text-center text-xs text-pot-grey-4">
              The excel must contain the data for both NPS and Churn
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropzone;
