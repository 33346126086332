import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";

const List = ({ bold=false, text, mb=false  }) => {
  const styles = StyleSheet.create({
    list: {
      width: "100px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: mb ? "2px" : "0px"
    },
    list_deco: {
      marginRight: "6px",
    },
    list_text: {
      fontWeight: bold ? "semibold" : "normal",
    },
  });

  return (
    <View wrap={false} style={styles.list}>
      <Text style={styles.list_deco}>•</Text>
      <Text style={styles.list_text}>{text}</Text>
    </View>
  );
};

export default List;
