import React from "react";
import { motion } from "framer-motion";

const BigTick = ({ size = "205" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 205 205"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.8 }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.875 12.0876C0.875 5.89979 5.89979 0.875 12.0876 0.875H192.912C199.1 0.875 204.125 5.89979 204.125 12.0876V192.912C204.122 195.885 202.94 198.735 200.838 200.838C198.735 202.94 195.885 204.122 192.912 204.125H12.0876C9.11477 204.122 6.26453 202.94 4.1624 200.838C2.06028 198.735 0.877989 195.885 0.875 192.912V12.0876ZM23.4583 23.4583V181.542H181.542V23.4583H23.4583Z"
        fill="#1767A5"
      />
      <motion.path
        animate={{
          scale: [1, 1.3, 1.4, 1],
          rotate: [0, 10, -10, 0],
        }}
        transition={{ duration: 1, delay: 0.3 }}
        d="M76.6082 94.5168C75.5666 93.4383 74.3206 92.5781 72.943 91.9863C71.5654 91.3945 70.0837 91.083 68.5844 91.07C67.0851 91.057 65.5982 91.3427 64.2105 91.9104C62.8228 92.4782 61.562 93.3166 60.5018 94.3768C59.4416 95.437 58.6032 96.6978 58.0354 98.0855C57.4677 99.4732 57.182 100.96 57.195 102.459C57.208 103.959 57.5195 105.44 58.1113 106.818C58.7031 108.196 59.5633 109.442 60.6418 110.483L83.2251 133.067C85.3426 135.183 88.2142 136.373 91.2083 136.373C94.2025 136.373 97.074 135.183 99.1915 133.067L144.358 87.8999C145.437 86.8582 146.297 85.6123 146.889 84.2346C147.48 82.857 147.792 81.3753 147.805 79.876C147.818 78.3767 147.532 76.8898 146.965 75.5021C146.397 74.1144 145.558 72.8537 144.498 71.7935C143.438 70.7333 142.177 69.8949 140.79 69.3271C139.402 68.7593 137.915 68.4736 136.416 68.4867C134.916 68.4997 133.435 68.8112 132.057 69.403C130.679 69.9948 129.433 70.855 128.392 71.9335L91.2083 109.117L76.6082 94.5168Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default BigTick;
