import { authenticatedAxiosInstance as axios } from "./axios";

const list = (params) => axios.get(`/user-manage/customer/`, params)
const create = (payload) => axios.post(`/user-manage/customer/`, payload)
const show = (id) => axios.get(`/user-manage/customer/${id}/`)
const update = (id, payload) => axios.patch(`/user-manage/customer/${id}/`, payload)
const set_plan = (id, payload) => axios.post(`/user-manage/customer/${id}/plan/apply/`, payload)
const get_plan_history = (id) => axios.get(`/user-manage/customer/${id}/plan/history/`)
const delete_plan_history = (id, plan_id) => axios.delete(`/user-manage/customer/${id}/plan/${plan_id}/delete/`)
const expire_plan_history = (id, plan_id) => axios.post(`/user-manage/customer/${id}/plan/${plan_id}/expire/`)
const download = (params) => axios.get(`/user-manage/customer/`, { params, responseType: "blob" })

export const CustomerApi = {
    list,
    create, 
    update,
    show,
    set_plan,
    get_plan_history,
    delete_plan_history,
    expire_plan_history,
    download,
}