import React from "react";
import { LeftArrow, RightArrow } from "../../assets/Arrows";
import { fullMonth } from "../../utils/DateHelpers";

const PickerControl = ({
  date,
  setToNextMonth,
  setToPrevMonth,
  monthView,
  setMonthView,
  setToNextYear,
  setToPrevYear,
  yearView,
  setYearView,
  yearOffset,
  setYearOffset,
}) => {
  return (
    <div className="w-full flex justify-between pb-2 px-4">
      <button
        className="p-1 hover:bg-pot-grey"
        onClick={(e) =>
          yearView
            ? setYearOffset((prev) => prev - 1)
            : monthView
            ? setToPrevYear(e)
            : setToPrevMonth(e)
        }
      >
        <LeftArrow />
      </button>

      {yearView ? (
        <div>
          <p className="text-pot-dark-blue font-semibold">
            {new Date().getFullYear() +
              16 * (yearOffset - 1) +
              1 +
              " - " +
              (new Date().getFullYear() + 16 * (yearOffset - 1) + 16)}
          </p>
        </div>
      ) : (
        <div className="flex gap-1 text-pot-dark-blue font-semibold">
          <button onClick={() => setMonthView((prev) => !prev)}>
            {!monthView && fullMonth[date.month] + ", "}
          </button>
          <button onClick={() => setYearView(true)}>{date.year}</button>
        </div>
      )}

      <button
        className="p-1 hover:bg-pot-grey"
        onClick={(e) =>
          yearView
            ? setYearOffset((prev) => prev + 1)
            : monthView
            ? setToNextYear(e)
            : setToNextMonth(e)
        }
      >
        <RightArrow />
      </button>
    </div>
  );
};

export default PickerControl;
