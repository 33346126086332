import React from 'react'
import Phone from '../../assets/Phone'

const Platforms = ({ detail }) => {
    const plat = detail.distribution_platforms.existing.concat(detail.distribution_platforms.new || [])
    plat.sort()

  return (
    <div className="w-full rounded-2xl shadow-lg border border-pot-grey-4/10 mb-10">
      <header className="border-b flex px-4 py-2 ">
        <Phone />
        <p className="text-pot-dark-blue font-semibold ml-3">Distribution Platforms</p>
      </header>
      <section className="flex flex-wrap gap-20 mx-4 my-2 text-xs py-3">
      {/* <ul className="px-4">
          {detail.distribution_platforms.existing.concat(detail.distribution_platforms.new).map((item) => (
            <li key={item} className="mb-1 list-disc">
                {item}
            </li>
          ))}
        </ul> */}
        <div className="flex gap-20">
              {Array.from({
                length: Math.ceil(plat.length / 7),
              }).map((_, id) => (
                <ul key={id} className="px-4">
                  {Array.from({
                    length:
                      plat.length - id * 7 < 7
                        ? plat.length - id * 7
                        : 7,
                  }).map((_, idx) => (
                    <li key={idx} className="mb-1 list-disc text-pot-grey-3">
                      {plat[idx + (id*7)]}
                    </li>
                  ))}
                </ul>
              ))}
            </div>
      </section>
    </div>
  )
}

export default Platforms