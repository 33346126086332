import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  PdfColsAtom,
  StartColumnGenerate,
  PdfDataAtom,
  SubscriptionTypeAtom,
  ColumnKeyValueAtom,
  ExcelSearchResultsDownloadAtom,
  SelectedProfileCountryAtom,
  ProfileCountryAtom,
} from "../../../atom";
import { formatWidthsToPercentage } from "../../../utils/helperFunc";
import ColumnPdf from "../../ColumnPdf/ColumnPdf";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import { DotPulse } from "@uiball/loaders";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
// import { usePDF } from "@react-pdf/renderer";
// import PdfPage from "../../ColumnPdf/PdfPage";
import useOnClickOutside from "../../../hooks/useClickOutside";
import MediaServicesSpreadsheetIcon from '@atlaskit/icon/glyph/media-services/spreadsheet'
import MediaServicesPdfIcon from '@atlaskit/icon/glyph/media-services/pdf'

const Generate = () => {
  const [startGenerate, setStartGenerate] = useRecoilState(StartColumnGenerate);
  const [cols, setCols] = useState(null);
  const [data, setData] = useState(null);
  const [widths, setWidths] = useState(null);
  const [pdfCols, setPdfCols] = useRecoilState(PdfColsAtom);
  const [pdfData, setPdfData] = useRecoilState(PdfDataAtom);
  const subsType = useRecoilValue(SubscriptionTypeAtom);
  const [type, setType] = useState(null);
  const columnKeyValue = useRecoilValue(ColumnKeyValueAtom);
  const [autoDownload, setAutoDownload] = useState(false);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom)
  const profileCountries = useRecoilValue(ProfileCountryAtom)
  const [openDownloadDropdown, setOpenDownloadDropdown] = useState(false);
  const [excelDownload, setExcelDownload] = useRecoilState(ExcelSearchResultsDownloadAtom)
  const [initial, setInitial] = useState(true)
  const downloadRef = useRef(null);
  useOnClickOutside(downloadRef, () => setOpenDownloadDropdown(false));
  // const [instance, update] = usePDF({
  //   document: (
  //     <PdfPage
  //       // pdfShowableCountries={pdfShowableCountries}
  //       // pdfShowableDevices={pdfShowableDevices}
  //       // detail={detail}
  //       colHeads={pdfCols?.map((each, id) =>
  //         id ? columnKeyValue[each] : "Name"
  //       )}
  //       subsType={type}
  //       data={pdfData}
  //       cols={pdfCols}
  //       widths={widths}
  //     />
  //   ),
  // });

  useEffect(() => {
    if (pdfCols && pdfData) {
      const settableWidths = formatWidthsToPercentage(["name", ...pdfCols]);
      setWidths(settableWidths);
      setCols(["name", ...pdfCols]);
      setData([...pdfData]);
      setStartGenerate(false);
      setAutoDownload(true);
      setOpenDownloadDropdown(false);
    } else if (!pdfData && !pdfCols) {
      setStartGenerate(false);
      setCols(null);
      setData(null);
      setWidths(null);
    }
  }, [pdfCols, pdfData]);

  // useEffect(() => {
  //   update();
  // }, [widths]);

  useEffect(() => {
    setType(subsType);
    setInitial(false)
  }, [subsType]);

  // useEffect(() => {
  //   if (autoDownload) {
  //     const href = instance.url;
  //     const link = document.createElement("a");
  //     link.href = href;
  //     link.target = "_blank";
  //     link.setAttribute("download", "search_results.pdf");
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     setAutoDownload(false);
  //   }
  // }, [instance.url]);

  // if (cols && data) {
  //   return (
  //     <div className="flex gap-x-1">
  //       <ColumnPdf data={data} cols={cols} widths={widths} subsType={type} />
  //       {/* <button
  //         onClick={() => {
  //           setPdfCols(null);
  //           setPdfData(null);
  //         }}
  //         className="w-20 flex justify-center items-center py-2 bg-[#66BCFF]/10 hover:bg-[#66BCFF0D]/20 transition ease-in-out rounded"
  //       >
  //         <CrossIcon primaryColor="#1767A5" size="small" />
  //       </button> */}
  //     </div>
  //   );
  // }

  if( cols && data ) {
    return(
      <ColumnPdf
        data={pdfData}
        cols={cols}
        pdfCols={pdfCols}
        // setCols={}
        // setData={} 
        region={profileCountries[selectedProfileCountry]}
        widths={widths}
      />
    )
  }

  if (startGenerate || excelDownload || pdfCols) {
    return (
      <div className="w-40 cursor-wait bg-white flex justify-center items-center gap-x-3 bg-[#66BCFF]/10 hover:bg-[#66BCFF0D]/20 transition ease-in-out px-2 py-2 text-sm rounded text-pot-dark-blue ">
        {/* <ClipLoader size={20} color="#1767A5" /> */}
        <DotPulse color="#1767A5" size={20} speed={0.8} />
        <p>Generating</p>
      </div>
    );
  }

  // return (
  //   <button
  //     onClick={() => setStartGenerate(true)}
  //     className="w-40 bg-white flex justify-between items-center bg-[#66BCFF]/10 hover:bg-[#66BCFF0D]/20 transition ease-in-out px-2 py-2 text-sm rounded text-pot-dark-blue "
  //   >
  //     <div className="flex gap-x-2 items-center ml-2">
  //       <DownloadIcon primaryColor="#1767A5" size="small" />
  //       Download
  //     </div>
  //     <HipchatChevronDownIcon primaryColor="#1767A5" />
  //   </button>
  // );


  return (
    <div ref={downloadRef} className="relative z-[70]">
      <button
        onClick={() => setOpenDownloadDropdown((prev) => !prev)}
        className="w-40 flex justify-between items-center bg-pot-bg-blue hover:bg-[#66BCFF0D]/20 transition ease-in-out px-2 py-2 text-sm rounded text-pot-dark-blue "
      >
        <div className="flex gap-x-2 items-center ml-2">
          <DownloadIcon primaryColor="#1767A5" size="small" />
          Download
        </div>
        <HipchatChevronDownIcon size="small" primaryColor="#1767A5" />
      </button>
      {openDownloadDropdown && (
        <div className="absolute mt-1">
          <div onClick={() => setOpenDownloadDropdown(false)} className="bg-white h-fit shadow-md rounded-md py-2 w-40">
            <div onClick={() => setExcelDownload(true)} className="px-4 py-2 flex justify-start items-center gap-x-2 hover:bg-pot-bg-blue/60 cursor-pointer">
              <MediaServicesSpreadsheetIcon primaryColor="#1767A5" size="small" />
              Excel
            </div>
            {/* {cols && data && instance.url ? ( */}
              {/* <a
                href={instance.url}
                download="search_results.pdf"
                className="w-full text-left hover:bg-pot-bg-blue/40 px-4 py-2 cursor-pointer"
              >
                pdf
              </a>
            ) : ( */}
              <button
                onClick={() => setStartGenerate(true)}
                className="w-full flex justify-start gap-x-2 items-center text-left hover:bg-pot-bg-blue/40 px-4 py-2"
              >
                <MediaServicesPdfIcon primaryColor="#1767A5" size="small" />
                PDF
              </button>
            {/* )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Generate;
