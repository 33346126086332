import React from "react";

const BigUpload = () => {
  return (
    <svg
      width="271"
      height="271"
      viewBox="0 0 271 271"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="271" height="271" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.557 105.238L104.9 127.065C102.8 129.18 101.622 132.04 101.622 135.02C101.622 138 102.8 140.86 104.9 142.975C105.931 144.019 107.159 144.847 108.513 145.413C109.867 145.978 111.319 146.27 112.787 146.27C114.254 146.27 115.707 145.978 117.061 145.413C118.414 144.847 119.643 144.019 120.674 142.975L135.24 128.285L150.326 143.494C151.356 144.539 152.584 145.368 153.938 145.935C155.291 146.501 156.743 146.794 158.211 146.795C159.678 146.796 161.131 146.506 162.485 145.941C163.839 145.376 165.068 144.549 166.1 143.506C168.193 141.39 169.367 138.535 169.369 135.559C169.371 132.583 168.201 129.726 166.112 127.607L143.924 105.25C142.79 104.098 141.438 103.184 139.948 102.559C138.458 101.935 136.858 101.613 135.243 101.612C133.627 101.611 132.027 101.931 130.536 102.553C129.045 103.176 127.692 104.089 126.557 105.238Z"
        fill="#1767A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.208 112.996L124.22 225.754C124.22 232.044 129.278 237.136 135.511 237.125C141.756 237.125 146.803 232.044 146.803 225.754L146.792 112.984C146.792 106.718 141.733 101.625 135.5 101.625C129.267 101.625 124.197 106.718 124.208 112.996Z"
        fill="#1767A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.6332 61.8896C86.7328 61.415 83.7985 61.1771 80.8595 61.1783C49.1864 61.1895 22.5833 86.0425 22.5833 118.551C22.5833 151.399 49.5139 180.667 80.1708 180.667H112.702V158.162H80.1708C61.4605 158.162 44.9972 139.384 44.9972 118.551C44.9972 98.4746 61.5847 83.6938 80.0465 83.6938H80.182C84.5745 83.6938 87.9281 84.2697 91.135 85.3876L93.0546 86.099C99.886 88.8993 102.935 83.3212 102.935 83.3212L104.629 80.3063C112.871 65.0965 129.481 56.639 146.588 56.3793C157.936 56.4922 168.856 60.7294 177.31 68.3004C185.764 75.8714 191.175 86.2592 192.534 97.5261L193.054 101.365C193.054 101.365 193.855 107.293 200.562 107.293C200.709 107.293 200.698 107.35 200.822 107.35H203.69C216.518 107.35 226.003 118.179 226.003 131.717C226.003 145.346 214.858 158.162 202.629 158.162H157.869V180.667H202.629C227.019 180.667 248.417 157.575 248.417 131.717C248.417 109.145 233.602 90.3559 212.983 85.715C205 55.1485 178.51 34.3154 146.521 33.875C124.22 34.1121 102.472 44.0488 89.6332 61.8896Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default BigUpload;
