import React, { useCallback, useEffect, useState } from "react";
import EditorSearchIcon from "@atlaskit/icon/glyph/editor/search";
import { debounce } from "lodash";

const SearchBox = ({
  setSearchValue,
  clearOut,
}) => {
  const [inputValue, setInputValue] = useState("");

  const optimisedSearch = useCallback(
    debounce((text) => {
      setSearchValue(text);
    }, 400),
    []
  );

  useEffect(() => {
    optimisedSearch(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (clearOut) {
      setInputValue("");
    }
  }, [clearOut]);

  return (
    <div className="w-full flex justify-end mt-4 pr-3">
      <div className="w-fit flex items-center border border-pot-blue/10 rounded px-2 bg-pot-bg-blue my-3">
        <EditorSearchIcon primaryColor="#1767A5" />
        <input
          value={inputValue}
          placeholder="Search"
          onChange={(e) => setInputValue(e.target.value)}
          className="w-[300px] outline-none text-sm text-pot-blue placeholder:text-pot-blue px-2 py-1 bg-inherit"
        />
      </div>
    </div>
  );
};

export default SearchBox;
