import { authenticatedAxiosInstance as axios } from "./axios";

const create = (payload) => axios.post(`/user-manage/admin/`, payload)
const list = (params) => axios.get(`user-manage/admin/`, params)
const show = (id) => axios.get(`/user-manage/admin/${id}/`)
const update = (id, payload) => axios.patch(`/user-manage/admin/${id}/`, payload)
const sendWelcomeMail = (id) => axios.post(`/user-manage/send-welcome-mail/${id}/`)
const sendReset = (id) => axios.get(`/user-manage/reset-login-attempt-lock/${id}/`)
const download = (params) => axios.get(`user-manage/admin/`, { params, responseType: "blob" })

export const AdminApi =  {
    create,
    list,
    show,
    update, 
    sendWelcomeMail,
    sendReset,
    download
}