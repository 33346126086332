import React from "react";

const Phone = () => {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.08333 0H4.91666C3.70149 0.00132347 2.53647 0.484634 1.67722 1.34389C0.817962 2.20314 0.334652 3.36816 0.333328 4.58333V15.4167C0.334652 16.6318 0.817962 17.7969 1.67722 18.6561C2.53647 19.5154 3.70149 19.9987 4.91666 20H9.08333C10.2985 19.9987 11.4635 19.5154 12.3228 18.6561C13.182 17.7969 13.6653 16.6318 13.6667 15.4167V4.58333C13.6653 3.36816 13.182 2.20314 12.3228 1.34389C11.4635 0.484634 10.2985 0.00132347 9.08333 0V0ZM4.91666 2.5H9.08333C9.63586 2.5 10.1658 2.71949 10.5565 3.11019C10.9472 3.5009 11.1667 4.0308 11.1667 4.58333V15H2.83333V4.58333C2.83333 4.0308 3.05282 3.5009 3.44352 3.11019C3.83422 2.71949 4.36413 2.5 4.91666 2.5ZM7 18.3333C6.77898 18.3333 6.56702 18.2455 6.41074 18.0893C6.25446 17.933 6.16666 17.721 6.16666 17.5C6.16666 17.279 6.25446 17.067 6.41074 16.9107C6.56702 16.7545 6.77898 16.6667 7 16.6667C7.22101 16.6667 7.43297 16.7545 7.58925 16.9107C7.74553 17.067 7.83333 17.279 7.83333 17.5C7.83333 17.721 7.74553 17.933 7.58925 18.0893C7.43297 18.2455 7.22101 18.3333 7 18.3333Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default Phone;
