import { normalAxiosInstance as axios } from "./axios";
import { authenticatedAxiosInstance as authedAxios } from "./axios";

const login = (payload) => axios.post("/accounts/login/", payload)
const resetPassword = (payload) => axios.post("/accounts/reset-password/", payload)
const setPassword = (payload, token) => axios.post(`/accounts/reset-password/confirm/${token}/`, payload)
const changePassword = (payload) => authedAxios.post("/accounts/change-password/", payload)

export const AuthApi = {
    login,
    resetPassword,
    changePassword,
    setPassword,
}