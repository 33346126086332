import { convertToHTML } from "draft-convert";
import { EditorState, Modifier } from "draft-js";

export const toNormalText = (val) => {
  if (!val) return val;
  return val
    .split("_")
    .map((word) => `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`)
    .join(" ");
};

export const fullSentance = (key, type = 1) => {
  const val = key?.existing.concat(...(key?.new || []));
  if (!val) return "";
  let typeVal = " and ";
  if (type === 2) {
    typeVal = " or ";
  }
  if (val.length === 1) {
    return val;
  }
  return val.slice(0, -1).join(", ") + typeVal + val.slice(-1);
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const formatDataForCharts = (data) => {
  let usVal = [];
  let canadaVal = [];
  data.US.forEach((each) => {
    usVal.push(...Object.keys(each));
  });
  usVal = new Set(usVal);
  usVal = Array.from(usVal).filter((each) => each !== "x_axis");
  data.CANADA.forEach((each) => {
    canadaVal.push(...Object.keys(each));
  });
  canadaVal = new Set(canadaVal);
  canadaVal = Array.from(canadaVal).filter((each) => each !== "x_axis");

  return { usVal, canadaVal };
};

export const formatWidthsToPercentage = (arr) => {
  const valArr = arr.filter(col => col !== "nps" && col !== "churn").map((each) => {
    if (each === "name") return 5;
    else if (each === "launch_date") return 3;
    else if (each === "is_free_trial" || each === "is_original_content")
      return 2;
    else return 4;
  });
  const eachValPercent = 100 / valArr.reduce((acc, cur) => acc + cur, 0);
  const percentArr = valArr.filter(col => col !== "nps" && col !== "churn").map((each) => `${each * eachValPercent}%`);
  return percentArr;
};

export const formatObjectWithNoNullValue = (obj) => {
  const settable = Object.keys(obj).reduce(
    (acc, cur) => (obj[cur] ? { ...acc, [cur]: obj[cur] } : acc),
    {}
  );
  return settable;
};

export const formatCompareValToWidthPercent = (arr) => {
  const valArr = arr.map((each) => {
    if (each === "features") return 1;
    else return 2;
  });
  const eachValPercent = 100 / valArr.reduce((acc, cur) => acc + cur, 0);
  const percentArr = valArr.map((each) => `${each * eachValPercent}%`);
  return percentArr;
};

export const getAllQuarterBetween = (
  start = [],
  end = [],
  showOffset = false
) => {
  const startOffset = [];
  const endOffset = [];
  const allQuarters = [];

  for (let i = start[1]; i <= end[1]; i++) {
    if (start[1] === i) {
      for (let k = 0; k < start[0] - 1; k++) {
        startOffset.push([k, i].join(" "));
      }
    } else if (end[1] === i) {
      for (let k = end[0] + 1; k <= 3; k++) {
        endOffset.push([k, i].join(" "));
      }
    }
    let quarterStart = i === start[1] ? start[0] : 0;
    let quarterEnd = i === end[1] ? end[0] : 3;
    for (let j = quarterStart; j <= quarterEnd; j++) {
      if (showOffset) {
        allQuarters.push([j, i].join(" "));
      } else {
        allQuarters.push([j, i]);
      }
    }
  }
  if (showOffset) {
    return [allQuarters, [...startOffset, ...endOffset]];
  }

  return allQuarters;
};

export const padStart = (num) => {
  // if(num.toString().split(".")[0].length < 2)
  //  num= "0"+num;
  return num;
};

export const subsLess = (num) => {
  if (typeof num !== "number") {
    return num;
  }
  return num < 0.01 ? "< 0.01" : num.toFixed(2);
};

const chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

export const btoa = (input = "") => {
  let str = input;
  let output = "";

  for (
    let block = 0, charCode, i = 0, map = chars;
    str.charAt(i | 0) || ((map = "="), i % 1);
    output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
  ) {
    charCode = str.charCodeAt((i += 3 / 4));

    if (charCode > 0xff) {
      throw new Error(
        "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range."
      );
    }

    block = (block << 8) | charCode;
  }

  return output;
};

export const convertedToHTML = (state) => {
  let html = convertToHTML({
    entityToHTML: (entity, originalText) => {
      if (entity.type === "LINK") {
        const target =
          entity.data.targetOption === "_blank" ? ' target="_blank"' : "";
        return `<a href="${entity.data.url}" ${target}>${originalText}</a>`;
      }
      return originalText;
    },
    styleToHTML: (style) => {
      if (style === "STRIKETHROUGH") {
        return <strike />;
      }
    },
  })(state);
  return html;
};

export const handlePastedText = (textToPaste, editorState, setEditorState) => {
  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const plainText = textToPaste.replace(/<[^>]+>/g, "");

  const newContent = Modifier.replaceText(currentContent, selection, plainText);

  const newEditorState = EditorState.push(
    editorState,
    newContent,
    "insert-characters"
  );

  setEditorState(newEditorState);
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getLastQuarters = (data, count) => {
  if (!data || !count) return null;
  const end = data?.length;
  const start = count > data.length ? 0 : end - count;
  const slicedData = data?.slice(start, end);

  const startData = slicedData && slicedData[0]?.name?.split(" ");
  const endData =
    slicedData && slicedData[slicedData.length - 1]?.name?.split(" ");

  const startQuarter = startData && startData[0]?.slice(1) - 1;
  const startQuarterYear = startData && startData[1];
  const endQuarter = endData && endData[0]?.slice(1) - 1;
  const endQuarterYear = endData && endData[1];
  return { startQuarter, startQuarterYear, endQuarter, endQuarterYear };
};

export const formatNpsValue = (value) => {
  const roundedValue = Math.round(value);
  const result =
    roundedValue > 0
      ? "+" + roundedValue
      : roundedValue < 0
      ? "-" + roundedValue
      : value;
  return result;
};

export const formatChurnValue = (value) => {
  const roundedValue = Math.round(value);
  return roundedValue + "%";
};
