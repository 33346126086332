import React from "react";
import {
    Text,
    View,
  } from "@react-pdf/renderer";

const FlexText = ({ title, value, style, whitespace=false }) => {
  return (
    <View style={style.content}>
      <Text style={style.content_title}>{title}</Text>
      <Text style={style.content_value}>{whitespace ? String(value).split("") : value}</Text>
    </View>
  );
};

export default FlexText;
