import React from "react";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfPage from "./PdfPage";
// import { ClipLoader } from "react-spinners";
// import { ColumnKeyValueAtom } from "../../atom";
// import { useRecoilValue } from "recoil";
import { DotPulse } from "@uiball/loaders";
import { formatCompareValToWidthPercent } from "../../utils/helperFunc";
import { DownloadButton } from "../ColumnPdf/ColumnPdf";

const ComparePdf = ({ cols, data, keys, region }) => {
  return (
    <PDFDownloadLink
      document={
        <PdfPage
          cols={cols}
          data={data}
          keys={keys}
          widths={formatCompareValToWidthPercent(keys)}
          region={region}
        />
      }
      fileName={"services_compare.pdf"}
    >
      {({ loading, error, url }) =>
        loading || error || !url ? (
          <button className="w-40 cursor-wait bg-pot-bg-blue flex gap-x-3 justify-center items-center py-2 text-sm text-pot-blue font-medium rounded-sm">
            <DotPulse color="#1767A5" size={20} speed={0.8} />
            Generating
          </button>
        ) : (
          <DownloadButton title="SERVICE_COMPARISON" />
        )
      }
    </PDFDownloadLink>
  );
};

export default ComparePdf;
