import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { checkIfSelectedDate } from "../../utils/DateHelpers";

const DateView = ({ getDaysArray, date, selectedDate, setSelectedDate }) => {
  const [dateArray, setDateArray] = useState(null);

  useEffect(() => {
    setDateArray(getDaysArray());
  }, [date.month]);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.6 }}
      className={`grid grid-cols-7 gap-y-3 text-center mt-[2vh] text-xs px-4 pb-2`}
    >
      {dateArray?.[0]?.map((each) => (
        <p className="opacity-25 mt-1">{each}</p>
      ))}
      {dateArray?.[1]?.map((each) => (
        <button
          className={`p-1 flex justify-center items-center ${
            checkIfSelectedDate(each, date.month, date.year, selectedDate) &&
            `bg-pot-blue text-white rounded-md`
          }`}
          onClick={() => {
            setSelectedDate(new Date(date.year, date.month, each));
            date.set(each);
          }}
        >
          {each}
        </button>
      ))}
      {dateArray?.[2]?.map((each) => (
        <p className="opacity-25 mt-1">{each}</p>
      ))}
    </motion.div>
  );
};

export default DateView;
