import React, { useEffect, useState } from "react";
import Sort from "../assets/Sort";
import { getAllQuarterBetween, padStart } from "../utils/helperFunc";

const useViewersCols = (
  rawData,
  quarterEnd,
  quarterStart,
  sortableKey,
  setSortableKey,
  setPage
) => {
  const [colDef, setColDef] = useState([]);
  const screenSize = window.innerWidth;

  const getAllQuadrants = () => {
    // const service = Object.values(rawData)[0]
    // const quadsData = Object.values(Object.values(service)[0])[0]
    const quadsData = getAllQuarterBetween(quarterStart, quarterEnd);
    return quadsData;
  };

  // defines the fixed column
  const mainDef = {
    header: () => (
      <div className="w-full flex gap-1 px-2 justify-start font-semibold">
        Name of Service
      </div>
    ),
    accessorKey: "name",
    enablePinning: true,
    size: Math.ceil(
      (screenSize - 170) /
        (getAllQuadrants().length > 7
          ? getAllQuadrants().length > 12
            ? 5
            : 6
          : 9)
    ),
    cell: ({ row, getValue }) =>
      row.original.type === "serviceName" ? (
        <p className="font-semibold w-full flex justify-start text-left">
          {getValue()}
        </p>
      ) : (
        <p className="text-pot-grey-3 w-full flex justify-start text-left">
          {getValue() === "subs" ? "subscribers" : getValue()}
        </p>
      ),
  };

  const addableDef = (value) => {
    const realValue = [value[0] + 1, value[1]];
    return {
      header: () => (
        // <button
        //   onClick={() => {
        //     setPage(1);
        //     const settableSortValue =
        //       sortableKey !== `+ ${realValue.join(" ")}`
        //         ? sortableKey?.includes(realValue.join(" "))
        //           ? null
        //           : `+ ${realValue.join(" ")}`
        //         : `- ${realValue.join(" ")}`;
        //     setSortableKey(settableSortValue);
        //   }}
        //   className="w-full flex gap-x-2 px-0 items-center justify-center font-semibold"
        // >
          <button
            onClick={() => {
              setPage(1);
              let settableSortValue;
              if (sortableKey === `+ ${realValue.join(" ")}`) {
                // Switch + to -
                settableSortValue = `- ${realValue.join(" ")}`;

              } else {
                if (sortableKey?.includes(realValue.join(" "))) {
                  // Switch - to + if it is the same column
                  settableSortValue = `+ ${realValue.join(" ")}`;
                } else {
                  // If it is a different column then set it descending by default
                  settableSortValue = `- ${realValue.join(" ")}`;
                }
              }
                
              setSortableKey(settableSortValue);
            }}
            className="w-full flex gap-x-2 px-0 items-center justify-center font-semibold"
          >
          Q{realValue.join(" ")}
          {sortableKey === `+ ${realValue.join(" ")}` ? (
            <Sort firstFill="#1767A5" secondFill="white" />
          ) : sortableKey === `- ${realValue.join(" ")}` ? (
            <Sort firstFill="white" secondFill="#1767A5" />
          ) : (
            <Sort firstFill="#1767A5" secondFill="#1767A5" />
          )}
        </button>
      ),
      accessorKey: realValue.join(" "),
      size: Math.ceil((screenSize - 170) / 15.4),
      cell: ({ row, getValue }) =>
        row.original.type !== "subs" && row.original.type !== "serviceName" ? (
          <div
            className={`flex justify-center ${
              !getValue()
                ? "text-pot-light-blue"
                : getValue() > 0
                ? "text-pot-green-3"
                : getValue() < 0 && "text-pot-red"
            }`}
          >
            {getValue() ? `${getValue().toFixed(1)}%` : <em>na</em>}
          </div>
        ) : row.original.type === "subs" ? (
          <div className="w-full text-center">
            {getValue() ? (
              getValue() <= 0.009 ? (
                `< ${getValue().toFixed(2)} M`
              ) : (
                `${padStart(getValue().toFixed(2))} M`
              )
            ) : (
              <em>na</em>
            )}
          </div>
        ) : (
          getValue() && getValue().toFixed(2)
        ),
    };
  };

  useEffect(() => {
    const allQuads = getAllQuadrants();
    const quadsDef = allQuads.map((each) => addableDef(each));
    setColDef([mainDef, ...quadsDef]);
  }, [rawData, sortableKey]);

  return colDef;
};

export default useViewersCols;
