import React from "react";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { useRecoilValue } from "recoil";
import { TableLoadingAtom } from "../../atom";
import { DotPulse } from "@uiball/loaders";

const TableLayoutTwo = ({ table, small, showNoDataText = false }) => {
  const tableLoading = useRecoilValue(TableLoadingAtom);
  return (
    <>
      <table className="w-full border-collapse rounded-t-xl overflow-auto text-sm ">
        <thead className="sticky top-0 z-[40]">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className={`relative border-x border-b border-pot-grey-2 bg-pot-background-grey-2`}
                  style={{ width: header.column.columnDef.width || "auto" }}
                >
                  <p className={`font-medium h-full p-2 ${small && "text-sm"}`}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </p>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {tableLoading ? (
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                // className="even:bg-white odd:bg-slate-50"
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    {...{
                      key: cell.id,
                      style: {
                        width:
                          cell.column.getSize() ||
                          cell.column.columnDef.width ||
                          "auto",
                      },
                    }}
                    // className="whitespace-pre-wrap"
                    className={`hidden`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                // className="even:bg-white odd:bg-slate-50"
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    // className="whitespace-pre-wrap"
                    className={`p-3 bg-white text-xs text-center ${
                      small ? "py-2 border-x border-t" : "py-4 border-b"
                    } border-pot-grey-2`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {!tableLoading &&
        showNoDataText &&
        table.getRowModel().rows?.length === 0 && (
          <div className={`w-full h-[30vh] flex justify-center items-center`}>
            {/* <ClipLoader color="#1767A5" /> */}
            No data found
          </div>
        )}
      {tableLoading && (
        <div className={`w-full h-[30vh] flex justify-center items-center`}>
          {/* <ClipLoader color="#1767A5" /> */}
          <DotPulse size={40} color="#1767A5" speed={0.8} />
        </div>
      )}
    </>
  );
};

export default TableLayoutTwo;
