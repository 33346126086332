import React from "react";
import { padStart, subsLess } from "../../utils/helperFunc";
import { useRecoilValue } from "recoil";
import { ProfileCountryAtom, SelectedProfileCountryAtom } from "../../atom";
import shortcutnew from "../../assets/shortcutnew.png";
import { useParams } from "react-router-dom";

const Subscribers = ({ detail }) => {
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);
  const profileCountries = useRecoilValue(ProfileCountryAtom);
  const { id } = useParams();

  const handleClick = () => {
    const url = `/search/viewers-trend?profile=${id}&region=${selectedProfileCountry}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <style>
        {`
          .radial-gradient {
            background-image: radial-gradient(circle, #FBFCFD, #F0F5F9);
          }

          .custom-shadow {
            /* Shadow CSS */
            box-shadow: 0 22px 33px -21px rgba(0, 0, 0, 0.3);
          }
        `}
      </style>

      <div
        className="group w-full border-2 border-8DB1CD52/32 rounded-2xl shadow-md flex flex-col justify-evenly p-7 text-xs relative text-opacity-30 bg-8DB1CD52 radial-gradient custom-shadow cursor-pointer"
        style={{ backgroundColor: "#8DB1CD52" }}
        onClick={handleClick}
      >
        <span className="absolute -top-10 left-1/4 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
          Click to see Viewers Trend
        </span>
        {detail.subscribers_count?.existing ? (
          <div
            className={`flex ${
              detail.subscribers_growth?.existing
                ? "justify-between items-center"
                : "flex-col justify-evenly h-full"
            } mb-1`}
          >
            <img
              src={shortcutnew}
              className="absolute top-2 right-2"
              alt="subscriber-icon"
            />
            <p className="w-[55%] font-semibold">
              {selectedProfileCountry === "US"
                ? "US"
                : profileCountries[selectedProfileCountry]}{" "}
              Subscribers
            </p>
            <p
              className={`text-pot-dark-blue ${
                String(detail.subscribers_growth?.existing)
                  ? detail.subscribers_count?.existing === "Not Estimated"
                    ? "text-md text-center"
                    : "text-xl"
                  : "text-2xl"
              } mt-2 font-bold`}
            >
              {String(detail.subscribers_count?.existing.toFixed(2)) === "0.00"
                ? "0"
                : subsLess(detail.subscribers_count?.existing)}
              {typeof detail.subscribers_count?.existing !== "string" && "M"}
            </p>
          </div>
        ) : null}
        {detail.subscribers_growth?.existing ? (
          <div
            className={`flex ${
              detail.subscribers_count?.existing
                ? "justify-between"
                : "flex-col justify-evenly h-full"
            }  items-center justify-between mb-1`}
          >
            <p className="w-[40%] font-semibold">Subscribers Change (QoQ)</p>
            <p
              className={`${
                detail.subscribers_growth?.existing > 0
                  ? "text-pot-dark-green"
                  : "text-[#C34B4B]"
              } ${
                String(detail.subscribers_count?.existing)
                  ? "text-xl"
                  : "text-2xl"
              } mt-2 font-bold`}
            >
              {String(detail.subscribers_growth?.existing.toFixed(2)) === "0.00"
                ? "0"
                : padStart(detail.subscribers_growth?.existing.toFixed(2))}
              {/* {padStart(detail.subscribers_growth?.existing.toFixed(2))} */}
              {typeof detail.subscribers_growth?.existing !== "string" && "M"}
            </p>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Subscribers;
