import React from "react";

const Eye = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M12.9598 12.9601C11.8202 13.8288 10.4326 14.31 8.99984 14.3334C4.33317 14.3334 1.6665 9.0001 1.6665 9.0001C2.49576 7.45469 3.64593 6.1045 5.03984 5.0401M7.59984 3.82676C8.05873 3.71935 8.52855 3.66566 8.99984 3.66676C13.6665 3.66676 16.3332 9.0001 16.3332 9.0001C15.9285 9.75717 15.4459 10.4699 14.8932 11.1268M10.4132 10.4134C10.2301 10.6099 10.0093 10.7675 9.76394 10.8768C9.51861 10.9862 9.25377 11.0449 8.98523 11.0497C8.71669 11.0544 8.44995 11.005 8.20091 10.9044C7.95188 10.8038 7.72565 10.6541 7.53574 10.4642C7.34582 10.2743 7.1961 10.0481 7.09551 9.79902C6.99492 9.54999 6.94552 9.28324 6.95026 9.0147C6.955 8.74616 7.01378 8.48133 7.12309 8.236C7.2324 7.99066 7.39001 7.76986 7.58651 7.58676"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6665 1.66675L16.3332 16.3334"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Eye;
