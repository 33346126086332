import React from "react";

const Services = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9909 3.55667L13.7776 1.3425C12.9117 0.476667 11.7609 0 10.5359 0H5.25008C2.72258 0 0.666748 2.05583 0.666748 4.58333V15.4167C0.666748 17.9442 2.72258 20 5.25008 20H12.7501C15.2767 20 17.3334 17.9442 17.3334 15.4167V6.7975C17.3334 5.57333 16.8567 4.4225 15.9909 3.55667ZM12.7501 17.5H5.25008C4.10175 17.5 3.16675 16.565 3.16675 15.4167V4.58333C3.16675 3.435 4.10175 2.5 5.25008 2.5H9.83342V5.83333C9.83342 6.75417 10.5792 7.5 11.5001 7.5H14.8334V15.4167C14.8334 16.565 13.8992 17.5 12.7501 17.5ZM12.3334 12.0833V14.5833C12.3051 16.2267 9.86092 16.2258 9.83342 14.5833V12.0833C9.86175 10.44 12.3059 10.4408 12.3334 12.0833ZM8.16675 9.58333V14.5833C8.13925 16.2267 5.69425 16.2258 5.66675 14.5833V9.58333C5.69425 7.94 8.13925 7.94083 8.16675 9.58333Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default Services;
