import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { OttDataApi } from "../../../api/ottData";
import {
  AccountUserAtom,
  AllFilterOptionsAtom,
  CompareViewHeadersAtom,
  InitialColumnVisibilityAtom,
  LoadingProgressAtom,
  ProfileCountryAtom,
  SelectedProfileCountryAtom,
  titleAtom,
} from "../../../atom";
import TableLayoutOne from "../../../components/TableLayout/One";
import useCompareViewColDef from "../../../hooks/useCompareViewColumns";
import useCompareViewData from "../../../hooks/useCompareViewData";
import { formatNamesToId } from "../../../utils/formatConfig";
import { toast } from "react-toastify";
import RetryIcon from "@atlaskit/icon/glyph/retry";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import { formatObjectWithNoNullValue } from "../../../utils/helperFunc";
import ComparePdf from "../../ComparePdf";
import { TelemetryApi } from "../../../api/telemetry";
import { titles } from "../../../utils/Titles";
import { getFromLocalStorage } from "../../../utils/localStorage";
// import CrossIcon from "@atlaskit/icon/glyph/cross";

const ServiceComparison = () => {
  const setTitle = useSetRecoilState(titleAtom);
  const [comparedHeaders, setComparedHeaders] = useRecoilState(
    CompareViewHeadersAtom
  );
  const filterOptions = useRecoilValue(AllFilterOptionsAtom);
  const [comparedValues, setComparedValues] = useState(null);
  const allColumnsObj = useRecoilValue(InitialColumnVisibilityAtom);
  const colDef = useCompareViewColDef(
    comparedHeaders,
    setComparedHeaders,
    setComparedValues
  );
  const data = useCompareViewData(comparedHeaders, comparedValues);
  const [keys, setKeys] = useState(null);
  const [pdfCols, setPdfCols] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const setLoading = useSetRecoilState(LoadingProgressAtom);
  const [prevTeleValue, setPrevTeleValue] = useState([]);
  const [initial, setInitial] = useState(true);
  const account = useRecoilValue(AccountUserAtom);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);
  const profileCountries = useRecoilValue(ProfileCountryAtom);
  const userType = getFromLocalStorage("user_type");

  const table = useReactTable({
    data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
    enablePinning: true,
    initialState: { columnPinning: { left: ["features"] } },
  });
  const fetchData = async () => {
    const allHeaders = Object.values(comparedHeaders).filter((each) => each);
    if (!allHeaders.length) {
      setComparedValues({ value1: undefined, value2: undefined });
      return;
    }
    setLoading(30);
    const { settable: idArray, ids } = formatNamesToId(
      filterOptions.names,
      comparedHeaders
    );
    const columns = Object.keys(allColumnsObj);
    const payload = { fields: columns, filters: { names: idArray } };
    const params = { profile_country: selectedProfileCountry };
    setLoading(40);
    const { data: response } = await OttDataApi.list(payload, { params });

    if (response.errors) {
      if (Array.isArray(response.errors.error_message)) {
        toast.error(response.errors.error_message.join(", "));
      }
      setLoading(100);
      return;
    }
    const results = response.data.results;
    setLoading(60);
    const settable = Object.keys(ids).reduce((acc, cur) => {
      const id = ids[cur];
      let obj = results.find((result) => result.id === id);
      return { ...acc, [cur]: obj };
    }, {});
    setComparedValues(settable);
    setLoading(100);
  };

  const clearData = () => {
    setPdfCols(null);
    setPdfData(null);
    setKeys(null);
  };

  const compareTelemetry = async (items) => {
    const hasItemsNotInPrevTeleVal = items.filter(
      (each) => !prevTeleValue.includes(each)
    ).length;
    // const hasPrevTeleValNotInItems = prevTeleValue.filter(each => !items.includes(each)).length
    console.log(!hasItemsNotInPrevTeleVal, "COMPAREEE");
    if (!hasItemsNotInPrevTeleVal) {
      return;
    }
    const payload = { items };
    setPrevTeleValue(items);
    const params = { profile_country: selectedProfileCountry };
    // console.log(items, "compareee")
    const { data: response } = await TelemetryApi.compare(payload, { params });
    if (!response.success) {
      console.error(response.errors.error_message.join(", "));
    }
  };

  const doTelemetry = (comparedHeaders) => {
    const { settable: idArray, ids } = formatNamesToId(
      filterOptions.names,
      comparedHeaders
    );
    console.log(idArray, initial, "comparee");
    if (idArray.length >= 2) {
      if (initial) {
        setPrevTeleValue(idArray);
        setInitial(false);
        return;
      }
      compareTelemetry(idArray);
    } else {
      setInitial(false);
    }
  };

  useEffect(() => {
    if (comparedHeaders) {
      fetchData();
      clearData();
      doTelemetry(comparedHeaders);
    }
  }, [comparedHeaders]);

  useEffect(() => {
    setTitle("Service Comparison");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
  }, []);

  return (
    <div className="max-w-full overflow-x-auto ">
      <div className="py-4 px-4 w-full flex justify-between">
        <button
          onClick={() => {
            setComparedHeaders({ value1: null, value2: null });
          }}
          className="ml-6 flex items-center gap-x-2 text-sm text-pot-dark-blue bg-[#66BCFF]/10 hover:bg-[#66BCFF]/20 transition ease-in-out px-3 py-2 rounded"
        >
          <RetryIcon size="small" primaryColor="#1767A5" />
          Reset
        </button>
        {account?.user_permissions?.includes("c_download_detail_pdf") ||
        userType.toLowerCase().includes("admin") ? (
          pdfCols && pdfData && keys ? (
            <div className="flex mr-9 gap-x-1">
              <ComparePdf
                cols={pdfCols}
                data={pdfData}
                keys={keys}
                region={profileCountries[selectedProfileCountry]}
              />
              {/* <button
              onClick={() => {
                setPdfCols(null);
                setPdfData(null);
                setKeys(null);
              }}
              className="w-20 flex justify-center items-center py-2 bg-[#66BCFF]/10 hover:bg-[#66BCFF0D]/20 transition ease-in-out rounded"
            >
              <CrossIcon primaryColor="#1767A5" size="small" />
            </button> */}
            </div>
          ) : (
            <button
              onClick={() => {
                const settable = formatObjectWithNoNullValue(comparedHeaders);
                if (!Object.keys(settable).length) return;
                setKeys(["features", ...Object.keys(settable)]);
                setPdfCols({ features: "Features", ...settable });
                setPdfData(data);
              }}
              className={`w-40 ${
                !Object.values(comparedHeaders).filter((each) => each).length
                  ? "cursor-not-allowed"
                  : "hover:bg-[#66BCFF0D]/20"
              } flex justify-center items-center gap-x-2 bg-pot-bg-blue transition ease-in-out px-2 py-2 text-sm mr-9 rounded text-pot-dark-blue`}
            >
              <DownloadIcon primaryColor="#1767A5" size="small" />
              Download
            </button>
          )
        ) : null}
      </div>
      <TableLayoutOne table={table} alternateColor />
    </div>
  );
};

export default ServiceComparison;
