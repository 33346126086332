import React, { useEffect, useRef, useState } from "react";
import { LeftArrow } from "../../assets/Arrows";
import { MacScrollbar } from "mac-scrollbar";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Radio } from "@atlaskit/radio";
import Select from "@atlaskit/select";
import InputField from "../../form/components/InputField";
import { OrganizationApi } from "../../api/organization";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AccountUserAtom, titleAtom } from "../../atom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { titles } from "../../utils/Titles";
import { toolbarOptions } from "../../utils/toolbarOptions";
import { EmailAPI } from "../../api/email";
import Table from "./SendEmailTable";
import AuthSubmit from "../../form/components/AuthSubmit";
import { ContentState, EditorState, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../styles/EditorStyles.css";
import { convertedToHTML, handlePastedText } from "../../utils/helperFunc";
import useOnClickOutside from "../../hooks/useClickOutside";
import { tags } from "../../utils/tags";
import InputAreaField from "../../form/components/InputAreaField";
import { CustomerApi } from "../../api/customer";
import CheckboxSelectField from "../../form/components/CheckboxSelectField";
import { validateEmail } from "../../utils/validations";
import htmlToDraft from "html-to-draftjs";
import { convertFromHTML } from "draft-convert";

const SendEmail = () => {
  const navigate = useNavigate();
  const setTitle = useSetRecoilState(titleAtom);
  const account = useRecoilValue(AccountUserAtom);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedType, selectType] = useState("All Customers");
  const [selectedFilter, setSelectedFilter] = useState();
  // Organizations Dropdown
  const [organizationList, setOrganizationList] = useState([]);
  const [organizationSearch, setOrganizationSearch] = useState("");
  const [totalOrganizations, setTotalOrganizations] = useState();
  const [currentOrganizaitonPage, setCurrentOrganizationPage] = useState(1);
  const [totalOrganizationPages, setTotalOrganizationPages] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  // Customers Dropdown
  const [customersList, setCustomersList] = useState([]);
  const [customerSearch, setCustomerSearch] = useState("");
  const [totalCustomers, setTotalCustomers] = useState();
  const [currentCustomerPage, setCurrentCustomerPage] = useState(1);
  const [totalCustomerPages, setTotalCustomerPages] = useState();
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [formData, setFormData] = useState();
  const [errors, setErrors] = useState();
  const [templates, setTemplates] = useState();
  const [isCustomizable, setIsCustomizable] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [initialRender, setInitialRender] = useState(true);
  const [renderTable, setRenderTable] = useState(0);
  const [loading, setLoading] = useState(false);
  const [noPermissionToView, setNoPermissionToView] = useState(null);
  const [noPermissionToViewTable, setNoPermissionToViewTable] = useState(null);
  const [validEmails, setValidEmails] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const checkboxSelectRef = useRef();
  useOnClickOutside(checkboxSelectRef, () => setOpenMenu(false));

  useEffect(() => {
    if (account) {
      if (!account?.user_permissions.includes("a_view_email_history")) {
        setNoPermissionToViewTable(true);
      }
      if (!account?.user_permissions.includes("a_send_email")) {
        setNoPermissionToView(true);
      }
    }
  }, [account]);

  const disabledStyles = "cursor-not-allowed bg-[#fafafa]";

  const fetchOrganizationList = async () => {
    const params = { page: currentOrganizaitonPage };
    if (organizationSearch) {
      delete params.page;
      params.search = organizationSearch;
    }
    const { data: response } = await OrganizationApi.list({ params });
    if (organizationSearch && response.success) {
      const options = response?.data?.results.map((category) => {
        return {
          label: category?.name + " | " + category?.customer_count,
          value: category?.id,
        };
      });
      setOrganizationList(options);
    } else if (response.success && currentOrganizaitonPage === 1) {
      setTotalOrganizationPages(response.data.total_pages);
      setTotalOrganizations(response.data.total_count);
      const options = response?.data?.results.map((category) => {
        return {
          label: category?.name + " | " + category?.customer_count,
          value: category?.id,
        };
      });
      setOrganizationList(options);
    } else if (response.success && currentOrganizaitonPage > 1) {
      const options = response?.data?.results.map((category) => {
        return {
          label: category?.name + " | " + category?.customer_count,
          value: category?.id,
        };
      });
      setOrganizationList([...organizationList, ...options]);
    }
  };

  const fetchCustomers = async () => {
    const params = { page: currentCustomerPage };
    if (customerSearch) {
      delete params.page;
      params.search = customerSearch;
    }
    const { data: response } = await CustomerApi.list({ params });
    if (customerSearch && response.success) {
      const data = response.data.results.map((e) => {
        const fName = e.first_name && e.first_name;
        const lName = e.last_name && e.last_name;
        const name = fName && lName ? fName + " " + lName : fName;
        return {
          label: name,
          value: e.id,
        };
      });
      setCustomersList(data);
    } else if (response.success && currentCustomerPage === 1) {
      setTotalCustomerPages(response.data.total_pages);
      setTotalCustomers(response.data.total_count);
      const data = response.data.results.map((e) => {
        const fName = e.first_name && e.first_name;
        const lName = e.last_name && e.last_name;
        const name = fName && lName ? fName + " " + lName : fName;
        return {
          label: name,
          value: e.id,
        };
      });
      setCustomersList(data);
    } else if (response.success && currentCustomerPage > 1) {
      const data = response.data.results.map((e) => {
        const fName = e.first_name && e.first_name;
        const lName = e.last_name && e.last_name;
        const name = fName && lName ? fName + " " + lName : fName;
        return {
          label: name,
          value: e.id,
        };
      });
      if (selectedCustomers.length > 0) {
        setCustomersList(data);
      } else {
        setCustomersList([...customersList, ...data]);
      }
    }
  };
  const handleTagClick = (tagLabel) => {
    const currentContent = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const contentWithEntity = currentContent.createEntity("TAG", "IMMUTABLE", {
      label: tagLabel,
    });
    const entityKey = contentWithEntity.getLastCreatedEntityKey();

    const newContentState = Modifier.replaceText(
      currentContent,
      selectionState,
      tagLabel,
      null,
      entityKey
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );
    setEditorState(
      EditorState.forceSelection(
        newEditorState,
        newContentState.getSelectionAfter()
      )
    );
  };

  const clear = () => {
    setEditorState(EditorState.createEmpty());
    setFormData();
    setSelectedTemplate("");
    setIsCustomizable(true);
    setSelectedFilter("CUSTOMERS_W_ACTIVE_SUBS");
    setErrors({});
  };

  const fetchTemplates = async () => {
    const params = { length: 50 };
    const { data: response } = await EmailAPI.list({ params });
    if (response.success) {
      // setTotalPages(response.data.total_pages);
      // setTotalCount(response.data.total_count);
      const data = response.data.results.map((e) => ({
        label: e.name,
        value: e.id,
      }));
      setTemplates(data);
    }
  };

  useEffect(() => {
    setTitle("Send Email");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
    fetchTemplates();
  }, []);

  useEffect(() => {
    fetchOrganizationList();
    if (organizationSearch) setCurrentOrganizationPage(1);
  }, [organizationSearch, currentOrganizaitonPage]);

  useEffect(() => {
    fetchCustomers();
    if (customerSearch) setCurrentCustomerPage(1);
  }, [customerSearch, currentCustomerPage]);

  useEffect(() => {
    let html = convertedToHTML(editorState.getCurrentContent());
    setFormData((prev) => ({
      ...prev,
      body: html,
    }));
  }, [editorState]);

  const back = () => {
    if (
      window.navigation?.currentEntry?.index > 1 ||
      (window.history.state && window.history.state.idx > 0) ||
      window.history.length > 2
    ) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  const getDetails = async (id) => {
    if (id === null) {
      clear();
    }
    setErrors();
    setInitialRender(true);
    const { data: response } = await EmailAPI.getOne(id.value);
    const data = response.data;
    delete data.id;
    setIsCustomizable(data.is_customizable);
    setFormData((prev) => ({ ...prev, ...data }));
    setEditorState(EditorState.createEmpty());
    setSelectedTemplate(id);
  };

  useEffect(() => {
    if (selectedTemplate && initialRender) {
      const contentState = convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === "a") {
            return createEntity("LINK", "MUTABLE", { url: node.href });
          }
        },
      })(formData?.body);
      if (contentState) {
        const newEditorState = EditorState.createWithContent(contentState);
        const currentContent = newEditorState.getCurrentContent();
        const contentLength = currentContent.getPlainText("").length;
        if (contentLength > 0) {
          setEditorState(newEditorState);
          setInitialRender(false);
        }
      }
    }
  }, [selectedTemplate, formData]);

  const checkErrors = () => {
    if (!formData?.subject) {
      setErrors((prev) => ({
        ...prev,
        subject: "Subject is missing",
      }));
    }
    if (formData?.body.length <= 7) {
      setErrors((prev) => ({
        ...prev,
        body: "Body is missing",
      }));
    }
  };

  useEffect(() => {
    if (formData?.body.length > 7) delete errors?.body;
  }, [formData]);

  useEffect(() => {
    if (formData?.emails) {
      const allEmails = formData?.emails
        ? formData?.emails
            .toLowerCase()
            .split("\n")
            .filter((item) => item !== "")
        : "";
      const allValidEmails = allEmails.filter((email) => {
        if (validateEmail(email)) {
          return email;
        }
        return null;
      });
      setValidEmails(allValidEmails);
    }
  }, [formData?.emails]);

  const getRecipientClass = () => {
    if (selectedType === "By Email") {
      const commaSeparatedEmails = validEmails.join(",");
      return `ext:${commaSeparatedEmails}`;
    } else if (selectedType === "By Organization") {
      const allOrganizations = selectedOrganization
        .map((org) => org.value)
        .join(",");
      return `org:${allOrganizations}`;
    } else if (selectedType === "By Customer") {
      const allCustomers = selectedCustomers
        .map((cust) => cust.value)
        .join(",");
      return `user:${allCustomers}`;
    } else {
      return "all";
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let recipientClass = getRecipientClass();
    const payload = {
      recipient_class: recipientClass,
      subject: formData?.subject,
      body: formData?.body,
    };
    if (selectedFilter) {
      payload.recipient_filters = [selectedFilter];
    }
    if (selectedTemplate) {
      payload.email_template = selectedTemplate.value;
    }
    const { data: response } = await EmailAPI.send(payload);
    if (response.success) {
      setRenderTable((prev) => prev + 1);
      clear();
    } else {
      checkErrors();
      const error = response.errors.error_message[0];
      setErrors((prev) => ({
        ...prev,
        ...error,
      }));
    }
    setLoading(false);
  };

  if (noPermissionToView) {
    return (
      <div className="flex flex-col w-full h-[90vh] items-center justify-center">
        <p className="text-pot-dark-green font-semibold mb-10">
          You are not allowed to view this page
        </p>
      </div>
    );
  }

  const handleFilterSelect = (type) => {
    if (selectedFilter === type) {
      setSelectedFilter("");
    } else {
      setSelectedFilter(type);
    }
  };

  return (
    <section className="flex flex-col divide-x">
      <MacScrollbar>
        <section className="w-full lg:min-w-[750px] lg:w-[50vw] text-xs pt-12 px-16">
          <div className="flex items-center">
            <motion.button
              onClick={back}
              whileHover={{ scale: 1.5 }}
              className="pr-4"
            >
              <LeftArrow />
            </motion.button>
            <p className=" text-sm font-semibold">Recipient Selection</p>
          </div>
          <div className="flex items-center gap-4 pt-2 mt-4">
            <Radio
              isChecked={selectedType === "All Customers"}
              onClick={() => selectType("All Customers")}
              label={<span className="cursor-pointer">All Customers</span>}
              className="cursor-pointer"
            />
            <Radio
              isChecked={selectedType === "By Organization"}
              onClick={() => selectType("By Organization")}
              label={<span className="cursor-pointer">By Organization</span>}
              className="cursor-pointer"
            />
            <Radio
              isChecked={selectedType === "By Customer"}
              onClick={() => selectType("By Customer")}
              label={<span className="cursor-pointer">By Customer</span>}
              className="cursor-pointer"
            />
            <Radio
              isChecked={selectedType === "By Email"}
              onClick={() => selectType("By Email")}
              label={<span className="cursor-pointer">By Email</span>}
              className="cursor-pointer"
            />
          </div>
          <p className="text-red-600 h-4 mt-1">{errors?.recipient_class}</p>
          {selectedType === "By Organization" && (
            <div ref={checkboxSelectRef}>
              <div className="flex w-full">
                <CheckboxSelectField
                  type={"organization"}
                  placeholder="Select organizations"
                  options={organizationList}
                  classname="z-[51]"
                  setPage={setCurrentOrganizationPage}
                  count={totalOrganizations}
                  search={organizationSearch}
                  setSearch={setOrganizationSearch}
                  selected={selectedOrganization}
                  setSelected={setSelectedOrganization}
                />
              </div>
            </div>
          )}
          {selectedType === "By Customer" && (
            <div className="flex w-full">
              <CheckboxSelectField
                type={"customer"}
                placeholder="Select customers"
                options={customersList}
                classname="z-[51]"
                setPage={setCurrentCustomerPage}
                count={totalCustomers}
                search={customerSearch}
                setSearch={setCustomerSearch}
                selected={selectedCustomers}
                setSelected={setSelectedCustomers}
              />
            </div>
          )}
          {selectedType === "By Email" && (
            <div className="flex flex-col mt-2">
              <InputAreaField
                heading="Email addresses"
                className="w-full"
                formData={formData}
                setFormData={setFormData}
                value="emails"
                placeholder="Add email addresses"
                error={errors || ""}
                setError={setErrors}
                rows={6}
                // required
              />
              <p className="text-pot-blue-2 font-semibold flex gap-1">
                Count:
                <span>{validEmails.length}</span>
              </p>
            </div>
          )}

          <div className="flex flex-col justify-center mt-4 gap-2">
            <p className="text-sm font-semibold">Filters</p>
            <Radio
              isChecked={selectedFilter === "CUSTOMERS_W_ACTIVE_SUBS"}
              onClick={() => handleFilterSelect("CUSTOMERS_W_ACTIVE_SUBS")}
              label={
                <span className="cursor-pointer">
                  All Active Subscribers (Including Trials)
                </span>
              }
            />
            <Radio
              isChecked={selectedFilter === "CUSTOMERS_W_ACTIVE_NONTRIAL_SUBS"}
              onClick={() =>
                handleFilterSelect("CUSTOMERS_W_ACTIVE_NONTRIAL_SUBS")
              }
              label={
                <span className="cursor-pointer">
                  Active Paid Subscribers only
                </span>
              }
            />
          </div>
          <div className="flex flex-col gap-6 mt-10">
            <p className="text-sm font-semibold">Email Content</p>
            <div className="flex flex-col items-center gap-4 w-full mb-6">
              {/* EMAIL TEMPLATE */}
              <div className="flex items-center gap-4 w-full">
                <p className="text-xs font-semibold min-w-fit">
                  Email template
                </p>
                <Select
                  classNamePrefix="react-select"
                  options={templates}
                  value={selectedTemplate}
                  placeholder="Select Email Template"
                  onChange={(e) => getDetails(e)}
                  className="w-full z-50 cursor-pointer"
                  isClearable
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "#fff",
                      border: "1px solid #cfd8df",
                      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                      minHeight: "34px",
                      height: "34px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      ":hover": {
                        backgroundColor: "#fff",
                        borderColor: "#cfd8df",
                      },
                    }),
                    dropdownIndicator: (baseStyles) => ({
                      ...baseStyles,
                      padding: "0",
                      paddingTop: "0",
                      paddingRight: "0",
                      paddingBottom: "0",
                      paddingLeft: "0",
                    }),
                    option: (baseStyles) => ({
                      ...baseStyles,
                      cursor: "pointer",
                    }),
                  }}
                />
              </div>
              {/* SUBJECT */}
              <InputField
                heading="Subject"
                className="w-full"
                formData={formData}
                setFormData={setFormData}
                value="subject"
                placeholder="Write a Subject"
                error={errors || ""}
                setError={setErrors}
                disabled={!isCustomizable}
                // required
              />
              {/* BODY */}
              <div className={`${!isCustomizable && disabledStyles} w-full`}>
                <div className="font-semibold text-xs flex text-left mb-3">
                  Body
                </div>
                <div className="flex border-2 rounded-md divide-x">
                  <Editor
                    editorState={editorState}
                    handlePastedText={(e) =>
                      handlePastedText(e, editorState, setEditorState)
                    }
                    wrapperClassName="wrapperClassName"
                    editorClassName="editor no-scrollbar"
                    editorStyle={{
                      minHeight: "230px",
                      height: "max-content",
                      overflow: "auto",
                      textIndent: "10px",
                      border: 0,
                      borderTop: "1px solid #cfd8df",
                    }}
                    wrapperStyle={{
                      width: "100%",
                      overflow: "hidden",
                    }}
                    placeholder="Type Email Content"
                    toolbarStyle={{
                      marginBottom: 0,
                      border: 0,
                      borderRadius: "8px",
                      width: "70%",
                    }}
                    toolbar={toolbarOptions}
                    onEditorStateChange={(e) => {
                      setEditorState(e);
                    }}
                  />
                  <div
                    className={`flex flex-col gap-2 max-w-[40%] min-w-fit p-4  font-semibold overflow-hidden text-pot-blue`}
                  >
                    {tags.map((tag) => (
                      <p
                        className={`bg-pot-light-blue-3/25 px-2 py-1 rounded-md w-fit cursor-pointer`}
                        onClick={() => handleTagClick(tag.label)}
                      >
                        {tag.label}
                      </p>
                    ))}
                  </div>
                </div>
                <p className="text-red-600 h-0">{errors?.body}</p>
              </div>
              <div className="mb-10 mt-10">
                <p className="text-red-600 h-4 mt-1">
                  {errors?.non_field_errors}
                </p>
                <AuthSubmit
                  text="Send Email"
                  onClick={handleSubmit}
                  loading={loading}
                  fontSize={"text-sm"}
                  marginTop={"mt-1"}
                />
              </div>
            </div>
          </div>
        </section>
        {!noPermissionToViewTable && (
          <section>
            <p className="text-sm font-semibold px-16">History</p>
            <Table height={"h-[63vh]"} render={renderTable} />
          </section>
        )}
      </MacScrollbar>
    </section>
  );
};

export default SendEmail;
