import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { DataImportApi } from "../../api/dataImport";
import BigUpload from "../../assets/BigUpload";
import {
  DescriptionAtom,
  titleAtom,
  UploadVerifyIdAtom,
  VerificationResultAtom,
} from "../../atom";
import { motion } from "framer-motion";
// import { ClipLoader } from "react-spinners";
import { DotPulse } from "@uiball/loaders";

const VerifyUpload = () => {
  const [verifyId, setVerifyId] = useRecoilState(UploadVerifyIdAtom);
  const setTitle = useSetRecoilState(titleAtom);
  const [result, setVerificationResult] = useRecoilState(
    VerificationResultAtom
  );
  const [description, setDescription] = useRecoilState(DescriptionAtom);
  const { id } = useParams();
  const [startVerify, setStartVerify] = useState(false);
  const [progress, setProgress] = useState(40);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const mountedRef = useRef(true);

  useEffect(() => {
    if (id === verifyId) {
      setTitle("Upload Data");
      setVerifyId(null);
      setStartVerify(true);
    }
    // else {
    //   navigate("/admin");
    // }
  }, []);

  const fetchProgress = async (interval) => {
    const { data: response } = await DataImportApi.getProgress(id);
    if (!mountedRef.current) {
      return;
    }
    setProgress(response.data.percentage);
    setDescription(response.data.last_message);
    setStatus(response.data.status);
    if (response.data.percentage === 100) {
      clearInterval(interval);
    }
  };

  useEffect(() => {
    if (!startVerify) return;
    fetchProgress();
    const interval = setInterval(() => fetchProgress(interval), 400);
    return () => {
      mountedRef.current = false;
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [startVerify, progress]);

  useEffect(() => {
    if (status === "SUCCESS" || status === "ERROR") {
      setVerificationResult(status);
    }
  }, [status]);

  useEffect(() => {
    if (result) {
      navigate(`/admin/data/upload/result/${id}`);
    }
  }, [result]);

  return (
    <div className="w-full h-[90vh] flex flex-col gap-2 justify-center items-center pr-10">
      {startVerify ? (
        <>
          <BigUpload />
          <div className="w-[50vw] h-[3px] relative z-50 rounded-full mx-3 bg-pot-bg-grey-2">
            <motion.div
              className="h-[3px] bg-pot-dark-blue rounded-full"
              initial={{ width: 0 }}
              animate={{ width: `${progress}%` }}
            ></motion.div>
          </div>
          <p className="font-semibold mt-5">
            {description || "Verifying Uploaded Data"}
          </p>
          {/* <p className="text-sm text-pot-text-grey-2">{description}</p> */}
          <p className="text-sm text-pot-text-grey-2">
            This could take a while! Please Be Patient
          </p>
        </>
      ) : (
        <DotPulse size={20} speed={0.8} />
      )}
    </div>
  );
};

export default VerifyUpload;
