export const setToLocalStorage = (
  token,
  user_type,
  v,
  user_id,
  first_name,
  last_name,
  email,
  organization_name
) => {
  if (token) {
    localStorage.setItem("token", token);
  }
  if (user_type) {
    localStorage.setItem("user_type", user_type);
  }
  if (v) {
    localStorage.setItem("v", v);
  }
  if (user_id) {
    localStorage.setItem("user_id", user_id);
  }
  if (first_name) {
    localStorage.setItem("first_name", first_name);
  }
  if (last_name) {
    localStorage.setItem("last_name", last_name);
  }
  if (email) {
    localStorage.setItem("email", email);
  }
  if (organization_name) {
    localStorage.setItem("organization_name", organization_name);
  }
};

export const getFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};
