import React from "react";
import { toNormalText } from "../../utils/helperFunc";

const InputDivideField = ({
  heading,
  className,
  formData,
  setFormData,
  value1,
  value2,
  error,
  setError,
  errVal1,
  errVal2,
  required = false,
}) => {
  return (
    <div className={className}>
      <div className="font-semibold text-xs flex">
        {heading}
        {required && <p className="ml-1 text-xxs text-red-400">✷</p>}
      </div>
      {/* ✶✷✴︎* */}
      <div className="flex gap-6 mt-2 justify-between">
        <div className="w-full">
          <input
            value={formData?.[value1] || ""}
            onFocus={() => {
              if (error[value1] || error[value2]) {
                delete error[value1];
                setError({ ...error });
              }
            }}
            onChange={(e) => {
              formData[value1] = e.target.value;
              setFormData({ ...formData });
            }}
            placeholder={toNormalText(value1)}
            className="w-full border outline-none rounded px-3 py-2 border-pot-blue-2/20 shadow shadow-pot-blue-2/10 focus:shadow-lg focus:shadow-pot-blue-2/10"
          />
          {error[value1] && (
            <span className="my-1 text-red-600 text-xs">
              {error[value1] || ""}
            </span>
          )}
        </div>
        <div className="w-full">
          <input
            value={formData?.[value2] || ""}
            onFocus={() => {
              if (error[value1] || error[value2]) {
                delete error[value2];
                setError({ ...error });
              }
            }}
            onChange={(e) => {
              formData[value2] = e.target.value;
              setFormData({ ...formData });
            }}
            placeholder={toNormalText(value2)}
            className="w-full border outline-none rounded px-3 py-2 border-pot-blue-2/20 shadow shadow-pot-blue-2/10 focus:shadow-lg focus:shadow-pot-blue-2/10"
          />
          {error[value2] && (
            <span className="my-1 text-red-600 text-xs">
              {error[value2] || ""}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default InputDivideField;
