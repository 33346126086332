import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { LibraryApi } from "../../api/library";
import { OttDataApi } from "../../api/ottData";
import { TelemetryApi } from "../../api/telemetry";
import ShortCut from "../../assets/ShortCut";
import {
  AllNamesAtom,
  ArticleFromProfileAtom,
  CountryObectAtom,
  LoadingProgressAtom,
  NameFilterFromProfileAtom,
  ProfileCountryAtom,
  SelectedProfileCountryAtom,
  ShowAlphabetsAtom,
  titleAtom,
} from "../../atom";
import ChartSection from "./ChartSection";
import Countries from "./Countries";
import Details from "./Details";
import Devices from "./Devices";
import Distribution from "./Distribution";
import FreeTrial from "./FreeTrial";
import Header from "./Header";
import Issues from "./Issues";
import LibrarySize from "./LibrarySize";
import NameHeader from "./NameHeader";
import NavigateList from "./NavigateList";
import Platforms from "./Platforms";
import Presence from "./Presence";
import PricesPromotions from "./PricesPromotions";
import Pricing from "./Pricing";
import Resolution from "./Resolution";
import ServiceFeature from "./ServiceFeature";
import Subscribers from "./Subscribers";
import UniqueAspect from "./UniqueAspect";
import UserViwers from "./UserViwers";
import TransactionalViewers from "./TransactionalViewers";
import WorldMap from "./WorldMap";
import { toast } from "react-toastify";
import NPSChurn from "./NPSChurn";

const DetailView = () => {
  const [intial, setInitial] = useState(true);
  const [detail, setDetail] = useState({});
  const setTitle = useSetRecoilState(titleAtom);
  const setLoading = useSetRecoilState(LoadingProgressAtom);
  const countriesObject = useRecoilValue(CountryObectAtom);
  const [pdfShowableCountries, setPdfShowableCountries] = useState([]);
  const [pdfShowableDevices, setPdfShowableDevices] = useState([]);
  const [showAlphabets, setShowAlphabets] = useState(false);
  const [fromDetails, setFromDetails] = useRecoilState(ShowAlphabetsAtom);
  const [hasDelayed, setHasDelayed] = useState(false);
  const [articles, setArticles] = useState(null);
  const setArticle = useSetRecoilState(ArticleFromProfileAtom);
  const setProfileId = useSetRecoilState(NameFilterFromProfileAtom);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);
  const allNames = useRecoilValue(AllNamesAtom);
  const profileCountries = useRecoilValue(ProfileCountryAtom);
  const params = useParams();
  const navigate = useNavigate();

  console.log("Params, ", params);

  const doTelemetry = useCallback(
    async (detail, id) => {
      console.log("Is this called ??");
      console.log("param id", id);
      const payload = {
        url: window.location.href,
        profile_viewed: id,
        title: detail.name.existing,
      };
      console.log(payload, "Param xx");
      const params = { profile_country: selectedProfileCountry };
      const { data: response } = await TelemetryApi.pageView(payload, {
        params,
      });
      if (!response.success) {
        // console.log()
        console.error(response.errors.error_message.join(", "));
      }
    },
    [params]
  );

  const fetchDummy = async () => {
    setLoading(40);
    const { data: response } = await OttDataApi.show(params.id);
    setLoading(70);
    if (response.success) {
      document.title = `Streaming Video Tracker | ${response.data.name.existing}`;
      setDetail(response.data);
    } else {
      navigate("/page-not-found");
    }
    setLoading(100);
    doTelemetry(response.data, params.id);
  };

  const fetchArticles = async () => {
    const param = { length: 3, profile_id: params.id, is_published: 1 };
    const { data: response } = await LibraryApi.list({ params: param });
    if (response.success) {
      setArticles(response.data.results);
    }
  };

  useEffect(() => {
    setTitle("Detail View");
    document.title = "Streaming Video Tracker | Detail";
    setTimeout(() => setHasDelayed(true), 1800);
  }, []);

  useEffect(() => {
    if (fromDetails) {
      console.log(fromDetails, "from Details");
      if (fromDetails === "null") {
        setShowAlphabets(null);
        setFromDetails(null);
        return;
      }
      setShowAlphabets(fromDetails);
      setFromDetails(null);
    }
  }, [fromDetails]);

  useEffect(() => {
    fetchDummy();
    fetchArticles();
  }, [window.location.href]);

  // useEffect(() => {
  //   if(intial) {
  //     setInitial(false)
  //     return
  //   }
  //   if(detail.name?.existing) {
  //     const id = allNames[selectedProfileCountry][detail.name.existing]
  //     if(id) {
  //       navigate(`/profile/${id}`)
  //     } else {
  //       toast.error(`We do not have ${detail.name.existing} for ${profileCountries[selectedProfileCountry]} Region`)
  //     }
  //   }
  //   console.log("All names", allNames)
  // }, [selectedProfileCountry])

  // const articlesData = [
  //   {
  //     article: "  Parks Assoc.-- OTT NA Video Tracker - December 2022 Update",
  //     path: "#",
  //   },
  //   {
  //     article: "  Parks Assoc.-- OTT NA Video Tracker - December 2022 Update",
  //     path: "#",
  //   },
  // ];
  const blockKeys = [
    "resolutions",
    "distribution_types",
    "subscribers_count",
    "monthly_viewers_count",
    "transactional_customers_count",
    "nps",
  ];
  const existingKeys = blockKeys.filter(
    (key) =>
      detail.hasOwnProperty(key) &&
      detail[key]?.existing !== null &&
      detail[key] !== null
  );
  const existingKeysLength = existingKeys.length;

  return (
    <>
      {showAlphabets && (
        <NavigateList id={params.id} detail={detail} type={showAlphabets} />
      )}
      <div className="px-[5vw]">
        {/* <NameHeader
        hasDelayed={hasDelayed}
        pdfShowableCountries={pdfShowableCountries}
        pdfShowableDevices={pdfShowableDevices}
        detail={detail}
      /> */}
        {Object.keys(detail).length ? (
          <Header
            hasDelayed={hasDelayed}
            pdfShowableCountries={pdfShowableCountries}
            pdfShowableDevices={pdfShowableDevices}
            detail={detail}
          />
        ) : (
          <></>
        )}

        {articles?.length ? (
          <div className="flex flex-wrap justify-between text-center mb-8">
            <div className="flex flex-wrap  items-start space-x-3">
              <p className="text-pot-blue font-bold flex ">Articles</p>
              <ul className=" text-black">
                <ol className="space-y-1 list-decimal list-inside text-xs mt-1">
                  {articles?.slice(0, 2).map((element, i) => {
                    return (
                      <div
                        onClick={() => {
                          setProfileId({
                            id: params.id,
                            name: detail.name?.existing,
                          });
                          setArticle(element.file_url);
                        }}
                        className="flex space-x-2"
                        key={i}
                      >
                        <Link to={"/library"} className="flex gap-x-2">
                          <li className="underline">{element.name}</li>
                          <ShortCut />
                        </Link>
                      </div>
                    );
                  })}
                </ol>
              </ul>
            </div>
            {articles.length > 2 && (
              <div
                onClick={() =>
                  setProfileId({ id: params.id, name: detail.name?.existing })
                }
              >
                <Link to={"/library"}>
                  <p className="text-xs text-pot-blue underline">View more</p>
                </Link>
              </div>
            )}
          </div>
        ) : null}

        <Details detail={detail} />
        {/* <div className="w-full flex justify-between my-10 gap-5"> */}
        {/* <section className="w-[49%]"> */}
        {/* <ChartSection />
                <section className="w-full h-full grid grid-cols-2 gap-4">
                    <Pricing detail={detail} /> */}
        {/* <LibrarySize detail={detail} /> */}
        {/* <FreeTrial detail={detail} />
                    <Resolution  detail={detail}/>
                    <Presence detail={detail} />
                </section> */}
        {/* </section> */}
        {/* </div> */}
        <div
          className={`w-full grid ${
            existingKeysLength > 3
              ? "grid-cols-3"
              : `grid-cols-${existingKeysLength + 1}`
          } my-10 gap-4`}
        >
          {detail?.resolutions?.existing?.concat(detail.resolutions?.new)
            ?.length ? (
            <Resolution detail={detail} />
          ) : (
            ""
          )}
          {Object.keys(detail).length ? <Presence detail={detail} /> : ""}
          {detail?.distribution_types?.existing?.concat(
            detail.distribution_types?.new || []
          )?.length ? (
            <Distribution detail={detail} />
          ) : (
            ""
          )}
          {detail.subscribers_count?.existing ? (
            <Subscribers detail={detail} />
          ) : (
            ""
          )}
          {detail?.monthly_viewers_count?.existing ? (
            <UserViwers detail={detail} />
          ) : (
            ""
          )}
          {detail?.transactional_customers_count?.existing ? (
            <TransactionalViewers detail={detail} />
          ) : (
            ""
          )}
          {detail?.nps || detail?.churn ? <NPSChurn detail={detail} /> : ""}
        </div>
        {detail?.countries?.existing?.concat(detail?.countries?.new).length ? (
          <Countries
            setPdfShowableCountries={setPdfShowableCountries}
            detail={detail}
          />
        ) : (
          ""
        )}
        {detail?.distribution_platforms?.existing?.concat(
          detail?.distribution_platforms?.new || []
        )?.length ? (
          <Platforms detail={detail} />
        ) : (
          ""
        )}
        {detail?.device_support?.existing?.concat(detail?.device_support?.new)
          ?.length ? (
          <Devices
            setPdfShowableDevices={setPdfShowableDevices}
            detail={detail}
          />
        ) : (
          ""
        )}
        {detail?.pricing_and_promotions?.existing ? (
          <PricesPromotions detail={detail} />
        ) : (
          ""
        )}
        {/* <WorldMap /> */}
        <div className="flex justify-between mb-10">
          {detail?.service_features?.existing ||
          detail?.unique_aspects?.existing ? (
            <ServiceFeature detail={detail} />
          ) : (
            ""
          )}
          {/* {detail?.unique_aspects?.existing ? <UniqueAspect detail={detail} /> : ""} */}
        </div>
        {/* {detail?.issues_considerations?.existing ? <Issues detail={detail} /> : ""} */}
      </div>
    </>
  );
};

export default DetailView;
