import React, { useEffect, useRef } from "react";
import useViewersCols from "../../../hooks/useViewersCols";
import useViewersData from "../../../hooks/useViewersData";
import { useReactTable, getCoreRowModel } from "@tanstack/react-table";
import TableControl from "../../../components/TableLayout/TableControl";
import TableLayoutOne from "../../../components/TableLayout/One";
import { AnimatePresence, motion } from "framer-motion";
import NoData from "../../../assets/no_data.png";
import { useNavigate } from "react-router-dom";
import Button from "../../../form/components/Button";

const ViewersTable = ({
  type,
  rawData,
  quarterStart,
  quarterEnd,
  setSortableKey,
  sortableKey,
  length,
  setLength,
  totalPages,
  totalCount,
  page,
  setPage,
}) => {
  const navigate = useNavigate();
  const colDef = useViewersCols(
    rawData,
    quarterEnd,
    quarterStart,
    sortableKey,
    setSortableKey,
    setPage
  );
  const data = useViewersData(rawData, type);
  const tableRef = useRef(null);
  const containerRef = useRef(null)
  // const [doScroll, setDoScroll] = useState()

  const table = useReactTable({
    data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
    enablePinning: true,
    initialState: { columnPinning: { left: ["name"] } },
  });

  useEffect(() => {
    if(!data.length) return
    if(containerRef.current) {
      containerRef.current.scrollLeft += 5000

    }
    if(tableRef.current) {
      tableRef.current.scrollLeft += tableRef.current.scrollWidth
      console.log("Inside the scroll", tableRef.current.scrollLeft, tableRef.current.scrollWidth)
    }
  }, [data])

  return (
    <div ref={containerRef} className="w-full h-[72vh] overflow-auto text-sm">
      <div className="flex relative w-full">
        <div ref={tableRef} className="w-full h-[65vh] overflow-auto">
          <TableLayoutOne table={table} moreBorder small />
          <AnimatePresence>
            {totalCount === 0 && (
              <motion.section
                initial={{ opacity: 0, scale: 0.7 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0 }}
                className="w-full flex flex-col justify-center items-center gap-y-6"
              >
                <img src={NoData} alt="NO DATA" className="w-[40vw]" />
                <Button
                  text="Modify filters"
                  primary
                  onClick={() => {
                    navigate("/search");
                  }}
                />
              </motion.section>
            )}
          </AnimatePresence>
        </div>
        <div className="flex justify-center pr-2 items-center h-[60vh] bg-white text-xs font-semibold">
          <p style={{ writingMode: "vertical-rl" }}>© Parks Associates</p>
        </div>
      </div>
      <TableControl
        page={page}
        setPage={setPage}
        length={length}
        setLength={setLength}
        totalCount={totalCount}
        totalPages={totalPages}
        big
      />
    </div>
  );
};

export default ViewersTable;
