import React, { useEffect, useState } from "react";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import park_associates from "../assets/ott_tracker_main.png";
import park_associates_small from "../assets/ott_small.png";
import pa_white from "../assets/pa-whitelogo.png";
import { useLocation, useNavigate } from "react-router-dom";
// import RefreshIcon from "@atlaskit/icon/glyph/refresh";
import { AccountUserAtom, AppInfoAtom, ResizingAtom } from "../atom";
import { useRecoilState, useRecoilValue } from "recoil";
import moment from "moment";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import PresenceActiveIcon from "@atlaskit/icon/glyph/presence-active";

const Sidebar = ({
  sidebarExpand,
  setSidebarExpand,
  // initial,
  navItems,
  equals,
  target,
  startWalkThrough,
}) => {
  const [mouseMovedValue, setMouseMovedValue] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [appInfoData, setAppInfoData] = useRecoilState(AppInfoAtom);
  const [openSubItems, setOpenSubItems] = useState({});
  const [containerResizing, setContainerResizing] =
    useRecoilState(ResizingAtom);
  const account = useRecoilValue(AccountUserAtom);

  const toggleSubItems = (index) => {
    setOpenSubItems((prevState) => {
      const updatedState = { ...prevState };

      Object.keys(updatedState).forEach((key) => {
        if (key !== index) {
          updatedState[key] = false;
        }
      });

      updatedState[index] = !prevState[index] || false;

      return updatedState;
    });
  };

  useEffect(() => {
    let timeout;
    if (containerResizing) {
      timeout = setTimeout(() => {
        setContainerResizing(false);
      }, 800);
    }
    return () => clearTimeout(timeout);
  }, [containerResizing]);

  return (
    // <AnimateSharedLayout>
    <motion.div
      initial={{ x: "-200%" }}
      animate={sidebarExpand ? { width: 180, x: 0 } : { width: 56, x: 0 }}
      transition={{ duration: 0.4 }}
      onClick={() => {
        if (!startWalkThrough) setSidebarExpand((prev) => !prev);
        setContainerResizing(true);
      }}
      className={`h-screen w-[48px] cursor-pointer float-left bg-gradient-to-t from-pot-dark-blue to-pot-blue-2 flex flex-col items-center absolute top-0 z-[96]`}
    >
      <motion.button
        onClick={(e) => {
          e.stopPropagation();
          if (!startWalkThrough) setSidebarExpand((prev) => !prev);
          setContainerResizing(true);
        }}
        initial={{ width: 0 }}
        animate={sidebarExpand ? { width: 148 } : { width: 40 }}
        transition={{ duration: 0.4 }}
        className={`bg-white rounded-md mt-4 mb-4`}
      >
        <div
          className={`h-fit min-h-[36px] ${
            sidebarExpand && "p-1"
          } flex items-center relative justify-center z-0`}
        >
          {sidebarExpand ? (
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ height: 40, opacity: 1, objectFit: "contain" }}
              transition={{ duration: 0.4 }}
              src={park_associates}
              className="h-full object-contain"
            />
          ) : (
            <motion.img
              initial={{ opacity: 0 }}
              animate={{
                height: "fit",
                objectFit: "contain",
                backgroundClip: "padding-box",
                opacity: 1,
              }}
              transition={{ duration: 0.4 }}
              className="rounded-md" // bg-pot-blue
              src={park_associates_small}
            />
          )}
          {/* Commenting the BETA text as per the client's request */}
          {/* <div
            className={`absolute bottom-[-7px] ${
              sidebarExpand ? 'right-[-5px]' : 'right-0.5'
            } border border-pot-blue rounded-full px-1 text-pot-blue text-xxs bg-white font-semibold`}
          >
            BETA
          </div> */}
        </div>
      </motion.button>
      <div className="h-[100vh]">
        <motion.div
          layout
          className="max-h-[75vh] overflow-y-scroll no-scrollbar"
        >
          {navItems?.map((navItem) => {
            return (
              <>
                {!!navItem.heading ? (
                  <motion.div
                    layout
                    className={`${
                      sidebarExpand ? " w-[150px]" : "justify-start w-[33px]"
                    } h-10 flex text-xxs text-pot-light-blue-3 font-semibold justify-center items-center`}
                  >
                    {/* {JSON.stringify(navItem.heading)} */}
                    {!containerResizing &&
                      (sidebarExpand ? (
                        <motion.p
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          layout
                        >
                          {navItem.heading}
                        </motion.p>
                      ) : (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          layout
                          className="border-[1px] w-full border-pot-light-blue-3"
                        ></motion.div>
                      ))}
                  </motion.div>
                ) : (
                  <AnimatePresence>
                    <motion.div
                      layout
                      className="mb-2"
                    >
                      <motion.div
                        transition={{ duration: 0.4 }}
                        onClick={(e) => {
                          if (navItem.subItems && !sidebarExpand) {
                            navigate(navItem.to);
                            return;
                          }
                          e.stopPropagation();
                          if (navItem.subItems) {
                            toggleSubItems(navItem.to);
                          } else {
                            setOpenSubItems({});
                          }
                          navigate(navItem.to);
                        }}
                        className={`${
                          navItem.to === "/dashboard"
                            ? `step_1`
                            : navItem.to === "/search"
                            ? "step_2"
                            : navItem.to === "/viewers-trend"
                            ? "step_3"
                            : navItem.to === "/library"
                            ? "step_4"
                            : ""
                        } 
                         flex items-center cursor-pointer rounded-md p-1 ${
                           equals
                             ? location.pathname === navItem.to ||
                               location.pathname === navItem.cc
                               ? navItem.subItems
                                 ? openSubItems[navItem.to]
                                   ? "bg-pot-light-blue/80 rounded-b-none"
                                   : location.pathname === navItem.to ||
                                     location.pathname === navItem.cc
                                   ? "bg-pot-light-blue/80"
                                   : ""
                                 : "bg-pot-light-blue/60"
                               : ""
                             : location.pathname.includes(navItem.to)
                             ? navItem.subItems
                               ? openSubItems[navItem.to]
                                 ? "bg-pot-light-blue/80"
                                 : ""
                               : "bg-pot-light-blue/60"
                             : ""
                         } ${
                          sidebarExpand
                            ? " w-[150px]"
                            : "justify-start w-[32px]"
                        }`}
                      >
                        <motion.div
                          onMouseLeave={() =>
                            !sidebarExpand && setMouseMovedValue(null)
                          }
                          onMouseEnter={() =>
                            !sidebarExpand && setMouseMovedValue(navItem.title)
                          }
                          layout
                          className="mt-1 relative w-[26px] h-[26px]"
                        >
                          <motion.div
                            layout
                            className="flex justify-center items-center h-full"
                          >
                            {navItem.icon}
                          </motion.div>
                          <AnimatePresence>
                            {!sidebarExpand &&
                              mouseMovedValue === navItem.title && (
                                <motion.div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  transition={{ duration: 0.1, delay: 0.4 }}
                                  className="w-40 text-center px-4 py-2 rounded-lg h-fit absolute -top-[5px] left-[32px] whitespace-pre-wrap bg-white shadow-lg z-[90] text-xs"
                                >
                                  {navItem.title}
                                </motion.div>
                              )}
                          </AnimatePresence>
                        </motion.div>
                        {sidebarExpand && (
                          <motion.p
                            layout
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.2, delay: 0.3 }}
                            className={`text-white stroke-white flex justify-between items-center font-semibold text-xs ml-2 ${
                              navItem.subItems && "w-full pl-[5px]"
                            }`}
                          >
                            <p className="w-fit flex justify-center items-center pt-[3px]]">
                              {navItem.title}
                            </p>
                            {navItem.subItems && sidebarExpand && (
                              <motion.div
                                animate={{
                                  rotate: !openSubItems[navItem.to] ? 0 : 180,
                                }}
                                className="w-fit h-fit pt-1"
                              >
                                <HipchatChevronDownIcon
                                  size="small"
                                  primaryColor="white"
                                />
                              </motion.div>
                            )}
                          </motion.p>
                        )}
                      </motion.div>
                      {openSubItems[navItem.to] &&
                        sidebarExpand &&
                        navItem.subItems && (
                          <motion.div
                            layout
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{
                              opacity: 0,
                              scale: 0.6,
                              transition: { delay: 0 },
                            }}
                            transition={{ delay: 0.4 }}
                            className="bg-pot-light-blue/60 relative left-0 py-1 rounded-b-md"
                          >
                            {navItem.subItems?.map((each) => {
                              return (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(each.to);
                                  }}
                                  className=" w-full flex gap-x-1.5 pl-1.5 font-semibold text-white text-[10.5px] py-2"
                                >
                                  {/* <PresenceActiveIcon size="small" primaryColor="white" /> */}
                                  <p>⦁</p>
                                  {each.title}
                                </button>
                              );
                            })}
                          </motion.div>
                        )}
                    </motion.div>
                  </AnimatePresence>
                )}
              </>
            );
          })}
        </motion.div>
      </div>
      <div className="h-full"></div>
      <div className="mb-5">
        <AnimatePresence>
          {sidebarExpand && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.4, delay: 0 }}
              exit={{ scale: 0, transition: { duration: 0.3, delay: 0 } }}
            >
              <div
                onMouseLeave={() => !sidebarExpand && setMouseMovedValue(null)}
                onMouseMove={() =>
                  !sidebarExpand && setMouseMovedValue("lastupdated")
                }
                className={
                  sidebarExpand
                    ? "w-full flex justify-center"
                    : "w-[26px] h-[26px]"
                }
              >
                {/* <RefreshIcon primaryColor="white" /> */}
                {/* <motion.div
                  className="w-40 text-center px-4 py-2 rounded-lg h-fit relative -top-[86px] left-[20px] whitespace-pre-wrap bg-white shadow-lg z-[90] text-xs"
                >
                  <p>last updated</p>
                  <p className="text-pot-blue font-semibold">
                    25th January 2022
                  </p>
                </motion.div> */}
              </div>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.4, delay: 0 }}
                exit={{ scale: 0, transition: { duration: 0.3, delay: 0 } }}
                className="text-xs text-center text-white px-8"
              >
                {/* <p>Last Update</p>
                <p>
                  {appInfoData?.last_update_at
                    ? moment(appInfoData?.last_update_at).format("MM-DD-YYYY")
                    : "-"}
                </p> */}
                <motion.img
                  animate={{
                    // height: "fit",
                    objectFit: "contain",
                    backgroundClip: "padding-box",
                    opacity: 1,
                  }}
                  transition={{ duration: 0.4 }}
                  src={pa_white}
                />
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
    // </AnimateSharedLayout>
  );
};

export default Sidebar;
