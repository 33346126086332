import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Line,
  Legend,
  ReferenceLine,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { AnimatePresence, motion } from "framer-motion";
import { DotPulse } from "@uiball/loaders";
import { useRecoilValue } from "recoil";
import { ResizingAtom, SelectedProfileCountryAtom } from "../../atom";
import { useExportChart } from "../../hooks/useExportChart";

const VideoReachChart = ({ data }) => {
  const [market, setMarket] = useState("US");
  const containerResizing = useRecoilValue(ResizingAtom);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);
  const { ExportButton } = useExportChart();

  useEffect(() => {
    setMarket(selectedProfileCountry);
  }, [selectedProfileCountry]);

  return (
    <section className="w-full relative">
      <ExportButton
        id="videoReachChart"
        title="Video Reach Chart"
        variant="header"
      />
      <div
        id="chart-videoReachChart"
        className="w-full border shadow-md rounded-lg"
      >
        <p className="text-pot-dark-blue font-semibold text-sm px-5 py-5">
          State of the Market: OTT Video Viewers Reach
        </p>
        {!data || containerResizing ? (
          <div className="w-full h-96 flex justify-center items-center">
            <DotPulse size={40} color="#1767A5" />
          </div>
        ) : (
          <div className="w-full h-fit relative text-xs pb-5">
            <ResponsiveContainer width="98%" height={400}>
              <LineChart
                margin={{ right: 100, bottom: 30, left: 20 }}
                data={data[market]}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <Legend
                  verticalAlign="top"
                  layout="vertical"
                  height={60}
                  align={"center"}
                  wrapperStyle={{ position: "absolute", right: "0%" }}
                  formatter={(value) =>
                    value === "AVOD" ? (
                      <span className="text-pot-text-grey">
                        Total monthly viewers of all estimated ad-based OTT
                        services
                      </span>
                    ) : (
                      value === "SVOD" && (
                        <span className="text-pot-text-grey">
                          Total subscribers of all estimated subscription-based
                          OTT Services
                        </span>
                      )
                    )
                  }
                  iconType="circle"
                  iconSize={10}
                />
                <XAxis
                  label={{
                    value: "Time",
                    position: "insideBottom",
                    offset: -10,
                  }}
                  name="x_axis"
                  dataKey="x_axis"
                />
                <YAxis
                  label={{
                    value: "Total Viewers (M)",
                    angle: -90,
                    position: "insideLeft",
                  }}
                  tickCount={7}
                />
                <Tooltip />
                <ReferenceLine
                  stroke="none"
                  y={data[market].slice(-1)[0].SVOD}
                  label={(props) => (
                    <CustomLabel
                      type="SVOD"
                      color="#028D9B"
                      value={data[market].slice(-1)[0].SVOD.toFixed(2)}
                      {...props}
                    />
                  )}
                  ifOverflow="extendDomain"
                >
                  {/* <Label value={data[market].slice(-1)[0].SVOD.toFixed(2)} position="right" fill="red" /> */}
                </ReferenceLine>
                <ReferenceLine
                  stroke="none"
                  y={data[market].slice(-1)[0].AVOD}
                  label={(props) => (
                    <CustomLabel
                      type="AVOD"
                      color="#1767A5"
                      value={data[market].slice(-1)[0].AVOD.toFixed(2)}
                      {...props}
                    />
                  )}
                  ifOverflow="extendDomain"
                >
                  {/* <Label value={data[market].slice(-1)[0].AVOD.toFixed(2)} position="right" fill="blue" /> */}
                </ReferenceLine>
                <Line
                  //   name="Total monthly viewers of all ad-based OTT services"
                  type="monotone"
                  dataKey={`SVOD`}
                  stroke="#02A0B0"
                />
                <Line
                  //   name="Total subscriptions to all subscription-based OTT services"
                  //   nameKey="x"
                  type="monotone"
                  dataKey={`AVOD`}
                  stroke="#1B75BB"
                />
                {/* <ReferenceLine */}
              </LineChart>
            </ResponsiveContainer>
            <div className="absolute -top-1 left-16 flex gap-5">
              <button
                onClick={() => setMarket("US")}
                className={`text-sm px-2 py-1 border rounded ${
                  market === "US" && "bg-[#1B75BB] text-white border-[#1B75BB]"
                }`}
              >
                US
              </button>
              <button
                onClick={() => setMarket("CANADA")}
                className={`text-sm px-2 py-1 border rounded ${
                  market === "CANADA" &&
                  "bg-[#1B75BB] text-white border-[#1B75BB]"
                }`}
              >
                Canada
              </button>
            </div>
          </div>
        )}
        <div className="w-full pb-2 py-1 flex justify-end text-xs px-2 text-pot-t-grey">
          © Parks Associates
        </div>
      </div>
    </section>
  );
};

const CustomLabel = ({ type, color, value, ...props }) => {
  const bgColor = type === "SVOD" ? "#028D9B" : "#1767A5";
  const textWidth = value.length * 8; // Approximate width of each character
  const rectWidth = textWidth + 6; // Add some padding

  return (
    <g>
      <rect
        x={props.viewBox.width + 85}
        y={props.viewBox.y - 8}
        width={rectWidth}
        height="16"
        fill={bgColor}
        rx="4"
        ry="4"
      />
      <text
        x={props.viewBox.width + 90}
        y={props.viewBox.y + 4}
        fill="white"
        fontSize="12"
        fontWeight="bold"
      >
        {value}
      </text>
    </g>
  );
};

export default VideoReachChart;
