import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Edit from "../../assets/Edit";
import { AccountUserAtom, TableLoadingAtom } from "../../atom";
import Toggle from "@atlaskit/toggle";
import Trash from "../../assets/Trash";
import moment from "moment";
import { DotPulse } from "@uiball/loaders";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import TableLayoutTwo from "../../components/TableLayout/Two";
import TableControl from "../../components/TableLayout/TableControl";
import { LibraryApi } from "../../api/library";
import { toast } from "react-toastify";
import EditFilledIcon from "@atlaskit/icon/glyph/edit-filled";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTransition,
} from "@atlaskit/modal-dialog";

const LibraryTable = ({
  height = "h-[47vh]",
  hasDropzone = true,
  setEditData,
  called,
  callAgain,
}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [length, setLength] = useState(10);
  const [revertId, setRevertId] = useState(null);
  const [loading, setLoading] = useState("");
  const [colDef, setColDef] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const account = useRecoilValue(AccountUserAtom);
  const setTableLoading = useSetRecoilState(TableLoadingAtom);

  const remove = async () => {
    setOpenDeletePopup(false);
    const { data: response } = await LibraryApi.destroy(deleteId);
    setDeleteId("");
    callAgain((prev) => !prev);
  };

  const initialColDef = [
    {
      header: "Date",
      accessorKey: "created_at",
      cell: ({ getValue }) =>
        getValue() && moment(getValue()).format("MM-DD-YYYY"),
    },
    //   {
    //     header: "Time",
    //     id: "time",
    //     cell: ({ row }) =>
    //       row.original.created_at &&
    //       moment(row.original.created_at).format("hh:mm A"),
    //   },
    {
      header: "File Name",
      accessorKey: "name",
      cell: ({ getValue }) => <p className="font-semibold">{getValue()}</p>,
    },
    {
      header: "United States Profiles",
      accessorKey: "us_profiles",
      cell: ({ row }) => {
        const tags = row.original.profile_tags?.filter(
          (each) => each.profile_country.toLowerCase() === "us"
        );
        return (
          <div className="h-full items-start flex gap-x-2 gap-y-1 flex-wrap">
            {tags?.slice(0, 3)?.map((each) => (
              <div className="bg-pot-bg-blue rounded-md w-fit px-1.5 py-1">
                {each.name}
              </div>
            ))}
            {tags.length > 3 && (
              <p className="py-1"> + {tags.length - 3} more</p>
            )}
          </div>
        );
      },
    },
    {
      header: "Canada Profiles",
      accessorKey: "canada_profiles",
      cell: ({ row }) => {
        const tags = row.original.profile_tags?.filter(
          (each) => each.profile_country.toLowerCase() === "canada"
        );
        return (
          <div className="h-full items-start flex gap-x-2 gap-y-1 flex-wrap">
            {tags?.slice(0, 3)?.map((each) => (
              <div className="bg-pot-bg-blue rounded-md w-fit px-1.5 py-1">
                {each.name}
              </div>
            ))}
            {tags.length > 3 && (
              <p className="py-1"> + {tags.length - 3} more</p>
            )}
          </div>
        );
      },
    },
    {
      header: "Status",
      accessorKey: "is_published",
      cell: ({ getValue, row }) => (
        <div className="w-full flex justify-center">
          <div
            className={`flex items-center gap-x-2 ${
              getValue() ? "text-black" : "text-pot-t-grey"
            }`}
          >
            <div
              className={`w-1.5 h-1.5 rounded-full ${
                getValue() ? "bg-pot-blue" : "bg-pot-light-blue/30"
              }`}
            ></div>
            {getValue() ? "Published" : "Unpublished"}
          </div>
        </div>
      ),
    },
  ];
  // const data = Array.from({ length: 10 }).map((_, id) => ({
  //   created_at: "2022-09-19T04:08:13.606241Z",
  //   filename: "OTT First quarter report 2023",
  //   profiles_mentioned: ["Disney", "Amazon Prime", "Netfix"],
  //   is_published: id % 2 === 0 ? true : false
  // }));

  const handleToggleChange = async (id, is_published, profile_tags) => {
    const payload = {
      is_published,
      profile_tags: profile_tags.map((each) => each.id),
    };
    const { data: response } = await LibraryApi.update(id, payload);
    if (response.success) {
      callAgain(true);
    }
  };

  const downloadData = async (id, url, name) => {
    try {
      setLoading(id);
      const response = await LibraryApi.download(url);
      if (response.status !== 200) {
        const errorString = await response.data.text();
        const errorObj = JSON.parse(errorString);
        toast.error(errorObj.errors.error_message.join(", "));
        setLoading("");
        return;
      }
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.target = "_blank";
      const settableName = name.endsWith(".pdf") ? name : name + ".pdf";
      link.setAttribute("download", settableName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLoading("");
    } catch (e) {
      toast.error(JSON.stringify(e));
      setLoading("");
    }
  };

  const getActions = (has_edit, has_delete, loading) => ({
    header: "Actions",
    accessorKey: "action",
    cell: ({ row }) => (
      <div className="flex gap-x-2 items-center justify-center">
        {loading === row.original.id ? (
          <DotPulse size={20} speed={0.8} color="#1767A5" />
        ) : (
          <button
            onClick={() =>
              downloadData(
                row.original.id,
                row.original.file_url,
                row.original.name
              )
            }
            className="pt-0.5"
          >
            <DownloadIcon primaryColor="#1767A5" />
          </button>
        )}
        {has_edit && (
          <>
            <button
              onClick={() =>
                setEditData((prev) =>
                  prev?.id === row.original.id ? null : row.original
                )
              }
              className="pt-0.5"
            >
              {/* <Edit fill="#1767A5" /> */}
              <EditFilledIcon primaryColor="#1767A5" />
            </button>
            <Toggle
              isChecked={row.original.is_published}
              onChange={() =>
                handleToggleChange(
                  row.original.id,
                  !row.original.is_published,
                  row.original.profile_tags
                )
              }
            />
          </>
        )}
        {has_delete && (
          <>
            {deleteId === row.original.id ? (
              <DotPulse size={20} speed={0.8} color="#1767A5" />
            ) : (
              <button
                className="min-w-[1.25rem]"
                onClick={() => setDeleteId(row.original.id)}
              >
                <Trash />
              </button>
            )}
          </>
        )}
      </div>
    ),
  });

  useEffect(() => {
    const hasEdit = account?.user_permissions.includes("a_modify_library_item");
    const hasDelete = account?.user_permissions.includes(
      "a_delete_library_item"
    );
    // const has_create = !account?.user_permissions.includes(
    //   "a_create_library_item"
    // );
    // if (hasEdit || hasDelete) {
    setColDef([
      ...initialColDef,
      getActions(hasEdit, hasDelete, loading, deleteId),
    ]);
    // } else {
    //   setColDef([...initialColDef]);
    // }
  }, [account, loading, deleteId]);

  const fetchLibrary = async () => {
    setTableLoading(true);
    const params = { page, length };
    const { data: response } = await LibraryApi.list({ params });
    // if (page === 1) {
    //   const foundObj =
    //     response.data.results.find((each) => each.status === "SUCCESS") || {};
    //   foundObj.has_revert = true;
    // }
    // console.log(response.data)
    setData(response.data.results);
    setTotalCount(response.data.total_count);
    setTotalPages(response.data.total_pages);
    setTableLoading(false);
    callAgain(false);
  };

  //   const revertHistory = async () => {
  //     const { data: response } = await DataImportApi.revert(revertId);
  //     setRevertId(null);
  //     if (response.success) {
  //       await fetchHistory();
  //       return;
  //     }
  //     toast.error(response.errors.error_message);
  //   };

  useEffect(() => {
    if (account?.user_permissions.includes("a_view_library_item")) {
      fetchLibrary();
    }
  }, [page, length, account]);

  useEffect(() => {
    if (called) {
      fetchLibrary();
    }
  }, [called]);

  useEffect(() => {
    if (deleteId) {
      setOpenDeletePopup(true);
    }
  }, [deleteId]);

  const table = useReactTable({
    data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className={`w-full relative ${height} overflow-y-auto mt-5`}>
        <TableLayoutTwo table={table} />
        <TableControl
          page={page}
          setPage={setPage}
          length={length}
          setLength={setLength}
          totalCount={totalCount}
          totalPages={totalPages}
        />
        <ModalTransition>
          {openDeletePopup && (
            <div className="rounded-xl bg-pot-grey-3">
              <Modal onClose={() => setOpenDeletePopup(false)} width={"400px"}>
                <ModalHeader>
                  <h1 className="flex justify-center text-xl w-full font-bold">
                    Delete Item?
                  </h1>
                </ModalHeader>
                <ModalBody>
                  <p className="text-xs">
                    Are you sure you want to delete this library item? This
                    action cannot be undone and the library item will be
                    permanently removed.
                  </p>
                </ModalBody>
                <div className="flex gap-4 p-4">
                  <button
                    onClick={() => {
                      setDeleteId("");
                      setOpenDeletePopup(false);
                    }}
                    className={`rounded-md px-2 py-1 w-full hover:bg-pot-blue hover:text-white transition-all ease-in-out`}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={remove}
                    className={`rounded-md px-2 py-1 text-red-500 hover:bg-red-500 hover:text-white w-full transition-all ease-in-out`}
                  >
                    Delete
                  </button>
                </div>
              </Modal>
            </div>
          )}
        </ModalTransition>
      </div>
    </>
  );
};

export default React.memo(LibraryTable);
