import React, { useEffect, useState } from "react";
import InputDivideField from "./components/InputDivideField";
import InputField from "./components/InputField";
import PhoneField from "./components/PhoneField";
import RadioField from "./components/RadioField";
import { motion } from "framer-motion";
import SelectField from "./components/SelectField";
import ColoredPlus from "../assets/ColoredPlus";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { OrganizationApi } from "../api/organization";
import { MacScrollbar } from "mac-scrollbar";
import { useRecoilValue } from "recoil";
import { AppInfoAtom, CountryCodesAtom } from "../atom";
import { DotPulse } from "@uiball/loaders";
import { toast } from "react-toastify";

const CustomerForm = ({
  onSubmit = () => {},
  formData,
  setFormData,
  selectedOrg,
  setSelectedOrg,
  isEdit,
  error,
  setError,
  loading = false,
}) => {
  const [page, setPage] = useState(1);
  const [orgOptions, setOrgOptions] = useState([]);
  const [totalOrgs, setTotalOrgs] = useState(0);
  const [orgSearch, setOrgSearch] = useState("");
  const [prevOrgSearch, setPrevOrgSearch] = useState("");
  const [orgFormData, setOrgFormData] = useState({ phone: { code: "+91" } });
  const [organizationModalOpen, setOrganizationModalOpen] = useState(false);
  const [orgError, setOrgError] = useState({});
  const country_codes = useRecoilValue(CountryCodesAtom);
  const [orgSubmitLoading, setOrgSubmitLoading] = useState(false);

  const fetchOrganizations = async () => {
    console.log("fetching");
    const params = { page: orgSearch !== prevOrgSearch ? 1 : page };
    if (orgSearch) {
      params.search = orgSearch;
    }
    const { data: response } = await OrganizationApi.list({ params });
    const results = response.data.results.map((result) => ({
      label: result.name,
      value: result.id,
    }));
    setTotalOrgs(response.data.total_count);
    if (orgSearch !== prevOrgSearch) {
      setOrgOptions([...results]);
    } else {
      setOrgOptions((prev) => [...prev, ...results]);
    }
    if (orgSearch !== prevOrgSearch) {
      setPage(1);
      setPrevOrgSearch(orgSearch);
    }
  };

  const getOrgPayload = () => {
    let formattedFormData = {
      ...orgFormData,
      // phone: formData.phone.code + formData.phone.number,
      // organization: selectedOrg?.value,
      // user_permissions: permissions,
    };
    if (orgFormData.phone.number) {
      formattedFormData = {
        ...formattedFormData,
        phone: formData.phone.code + formData.phone.number,
      };
    } else {
      delete formattedFormData.phone;
    }
    return formattedFormData;
  };

  // ({
  //   ...orgFormData,
  //   phone: orgFormData.phone.code + orgFormData.phone.number,
  // });

  const createOrg = async () => {
    setOrgSubmitLoading(true);
    const payload = getOrgPayload();
    const { data: response } = await OrganizationApi.create(payload);
    if (response.success) {
      const data = response.data;
      setSelectedOrg({ label: data.name, value: data.id });
      setOrganizationModalOpen(false);
    } else {
      let errs = {};
      console.log("Heyyy err", response)
      response.errors.error_message.forEach((each) => {
        if (typeof each === "string") {
          errs.error_message = each;
          // toast.error(each)
        } else if(each.length) {
          errs.error_message = each.join(", ")
          // toast.error(each.join(", "))
        } else {
          errs = { ...errs, ...each };
        }
      });
      // setError(errs);
      setOrgError(errs);
    }
    setOrgSubmitLoading(false);
  };

  useEffect(() => {
    fetchOrganizations();
  }, [page, orgSearch]);

  useEffect(() => {
    if (!organizationModalOpen) {
      setOrgFormData({ phone: { code: "+1" } });
      setOrgError({});
    } else if (orgSearch) {
      setOrgFormData((prev) => ({ ...prev, name: orgSearch }));
    }
  }, [organizationModalOpen]);

  return (
    <MacScrollbar>
      <section className=" pb-8">
        <InputDivideField
          heading="Name"
          className="mt-8"
          formData={formData}
          setFormData={setFormData}
          value1="first_name"
          value2="last_name"
          error={error}
          setError={setError}
          required
        />
        <InputField
          heading="Designation/ Role"
          className="mt-8"
          formData={formData}
          setFormData={setFormData}
          value="designation"
          error={error}
          setError={setError}
        />
        <div className="font-semibold mt-8 flex">
          Organization
          <p className="ml-1 text-xxs text-red-400">✷</p>
        </div>
        <p className="text-xs text-pot-grey-3 mt-1">
          Please select an organization or create a new one using the + button
        </p>
        <div className="w-full flex gap-4">
          <SelectField
            formData={formData}
            setFormData={setFormData}
            value="organization"
            className="w-full"
            search={orgSearch}
            prevSearch={prevOrgSearch}
            options={orgOptions}
            count={totalOrgs}
            setPage={setPage}
            setSearch={setOrgSearch}
            selected={selectedOrg}
            setSelected={setSelectedOrg}
            required
            invisibleCaret
          />
          <button
            onClick={() => setOrganizationModalOpen(true)}
            className="border px-3 py-2 rounded-md mt-3 border-pot-blue-2/20 shadow shadow-pot-blue-2/10 hover:shadow-lg hover:shadow-pot-blue-2/10"
          >
            <ColoredPlus />
          </button>
        </div>
        <p className="text-red-600">{error.organization}</p>
        {/* <RadioField
          heading="Gender"
          className="mt-8"
          formData={formData}
          setFormData={setFormData}
          names={["MALE", "FEMALE"]}
          value="gender"
          error={error}
          setError={setError}
        /> */}
        <PhoneField
          heading="Phone Number"
          placeholder={"000-000-0000"}
          formData={formData}
          setFormData={setFormData}
          value="phone"
          className="mt-8"
          error={error}
          setError={setError}
          country_codes={country_codes}
        />
        <InputField
          heading="Email ID"
          className="mt-8"
          formData={formData}
          setFormData={setFormData}
          value="email"
          placeholder="jack@example.com"
          error={error}
          setError={setError}
          required
        />
        <div className="w-full mt-24 flex justify-center">
          <motion.button
            onClick={onSubmit}
            whileHover={{ scale: 1.1 }}
            className="bg-pot-blue px-4 py-2 flex gap-x-2 items-center text-white text-sm font semibold rounded-md"
          >
            {loading && <DotPulse size={12} speed={0.8} color="white" />}
            {isEdit ? "Save" : "Create"}
          </motion.button>
        </div>

        <ModalTransition>
          {organizationModalOpen && (
            <Modal onClose={() => setOrganizationModalOpen(false)}>
              <div className="p-8">
                <header className="font-semibold mb-4">
                  Create an Organization
                </header>
                <div className="text-xs">
                  <InputField
                    heading="Name"
                    className="mt-8"
                    formData={orgFormData}
                    setFormData={setOrgFormData}
                    value="name"
                    placeholder="Name of Organization"
                    error={orgError}
                    setError={setOrgError}
                    required
                  />
                  <PhoneField
                    heading="Phone Number"
                    placeholder={"000-000-0000"}
                    formData={orgFormData}
                    setFormData={setOrgFormData}
                    value="phone"
                    className="mt-8"
                    error={orgError}
                    setError={setOrgError}
                    country_codes={country_codes}
                  />
                  <InputField
                    heading="Email ID"
                    className="mt-8"
                    formData={orgFormData}
                    setFormData={setOrgFormData}
                    value="email"
                    placeholder="jack@example.com"
                    error={orgError}
                    setError={setOrgError}
                  />
                </div>
                <div className="w-full flex justify-center">
                  <button
                    onClick={createOrg}
                    className="px-3 py-2 bg-pot-blue flex gap-x-2 items-center text-white rounded text-sm mt-10"
                  >
                    {orgSubmitLoading && (
                      <DotPulse size={12} speed={0.8} color="white" />
                    )}
                    {orgSubmitLoading ? "Creating" : "Create & Assign"}
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </ModalTransition>
      </section>
    </MacScrollbar>
  );
};

export default CustomerForm;
