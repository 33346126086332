import React, { useState } from "react";
import logo from "../../assets/parks-associates-Teallogo.png";
import FeedbackForm from "./FeedbackForm";
import { titles } from "../../utils/Titles";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";

function Feedback() {
  // const setTitle = useSetRecoilState(titleAtom);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    // setTitle("Contact");
    document.title = `Streaming Video Tracker | ${titles[window.location.pathname]}`;  // Fix this
  }, []);

  return (
    <div className="flex flex-col items-center">
      <div className="logo mt-[50px] w-[200px] h-[100px]">
        <img srcSet={logo} alt="park-associates" />
      </div>
      <h2 className="mt-[1rem] text-1xl md:text-3xl">
        Do you have Feedback or Suggestions?
      </h2>
      <p className="w-[90%] mx-auto mt-[3rem] text-sm md:text-sm">
      This feedback form is meant to provide you with the opportunity to share more about your company&lsquo;s streaming video service. 
      The fields noted below align with the areas we are tracking. Thank you for taking the time to provide us feedback. 
      For any specific questions about the tracker, please contact your account manager, 
      email <a href="mailto:info@parksassociates.com" className="underline text-pot-dark-blue">info@parksassociates.com</a> or 
      call <a href="tel:972-490-1113" className="underline text-pot-dark-blue">972-490-1113</a>.  
      Thank you.</p>

      <FeedbackForm formData={formData} setFormData={setFormData} small />
    </div>
  );
}

export default Feedback;
