import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
// import { useQuery } from '@tanstack/react-query'

const useProfileCombinationCols = ({ fetch, setters={} }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const [selectedRegion, setSelectedRegion] = useState("US")
  const colDef = [
    {
      header: () => <p className="text-left font-medium">Rank</p>,
      accessorKey: "rank",
      size: 5,
    },
    {
      header: () => <p className="text-left font-medium">Comparison Fields</p>,
      accessorKey: "compare",
      size: 500,
      cell: ({ row }) => (
        <p>{row.original.compare.reduce((acc, cur, id) => acc + cur.name + (id < (row.original.compare.length - 1) ? " ; " : ""), "")}</p>
        // <p>Hello {console.log(row.original)}</p>
      )
    },
    {
      header: () => <p className="text-left font-medium">Usage</p>,
      accessorKey: "count",
      size: 100,
    },
    // {
    //     header: () => <p className="text-left font-medium">No of Logins</p>,
    //     accessorKey: "logins",
    //     size: 100,
    // },
  ];

  // const { data } = useQuery({
  //     queryKey: ["TopActiveUsers"],
  //     queryFn: () => fetch("TOP_10_ACTIVE_USERS"),
  //     cacheTime: (1000 * 60 * 60 * 24)
  //   })

//   const fetchChart = useCallback(async () => {
//     setLoading(true);
//     const responseData = await fetch("TOP_10_COMPARISONS");
//     setData(responseData);
//     setLoading(false);
//   }, [setters])

   const fetchChart = async () => {
        setData([])
        setLoading(true);
        const responseData = await fetch("TOP_10_COMPARISONS", selectedRegion);
        setData(responseData);
        setLoading(false);
   }

//   useEffect(() => {
//     fetchChart();
//   }, [setters]);

  useEffect(() => {
    // console.log("Hellooo")
    fetchChart()
  }, [setters.startDate, setters.endDate, selectedRegion])

  const settable = useMemo(() => ({ colDef, data, loading, selectedRegion, setSelectedRegion }), [data, loading, selectedRegion]);

  return settable;
};

export default useProfileCombinationCols;
