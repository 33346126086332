import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Line,
  Legend,
  ReferenceLine,
  Tooltip,
  Label,
  CartesianGrid,
} from "recharts";
import { formatDataForCharts } from "../../utils/helperFunc";
import { DotPulse } from "@uiball/loaders";
import { useRecoilValue } from "recoil";
import { ResizingAtom, SelectedProfileCountryAtom } from "../../atom";
import { strokes } from "../../utils/chartsData";
import { useExportChart } from "../../hooks/useExportChart";

const TrendingChart = ({ data }) => {
  const [market, setMarket] = useState("US");
  const [usShows, setUsShows] = useState(null);
  const containerResizing = useRecoilValue(ResizingAtom);
  const [canadaShows, setCanadaShows] = useState(null);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);
  const { ExportButton } = useExportChart();

  useEffect(() => {
    setMarket(selectedProfileCountry);
  }, [selectedProfileCountry]);
  // const colors = [
  //   "#1B75BB",
  //   "#7DC2CB",
  //   "#2262D7",
  //   "#3E8991",
  //   "#6197F9",
  //   "#2FB8C9",
  //   "#B2EBF2",
  //   "#82C8FF",
  //   "#4F6D85",
  //   "#424242",
  // ];
  const colors = [
    "#1B75BB",
    "#E15A78",
    "#41B4D9",
    "#ECA11B",
    "#F37EC0",
    "#83C135",
    "#2AA170",
    "#8E8CFF",
    "#BC69F3",
    "#F2C428",
  ];

  useEffect(() => {
    if (data) {
      const formattedData = formatDataForCharts(data);
      setCanadaShows(formattedData.canadaVal);
      setUsShows(formattedData.usVal);
    }
  }, [data]);

  return (
    <section className="w-full relative">
      <ExportButton
        id="trending"
        title="Viewer Reach Growth Trend - Indexed Chart (Top OTT Services)"
        variant="footer"
      />
      <div id="chart-trending" className="border shadow-md rounded-lg">
        <p className="text-pot-dark-blue font-semibold text-sm px-5 py-5">
          Viewer Reach Growth Trend - Indexed Chart (Top OTT Services)
        </p>
        {!(data && canadaShows && usShows) || containerResizing ? (
          <div className="w-full h-96 flex justify-center items-center">
            <DotPulse size={40} color="#1767A5" />
          </div>
        ) : (
          <div className="w-full h-fit relative text-xs pb-5">
            <ResponsiveContainer width="100%" height={430}>
              <LineChart
                margin={{ right: 30, bottom: 40, left: 30, top: 60 }}
                data={data[market]}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <Legend
                  align={"center"}
                  wrapperStyle={{
                    position: "absolute",
                    right: "0%",
                    top: "96%",
                  }}
                  //   formatter={(value) =>
                  //     value === "AVOD" ? (
                  //       <span className="text-pot-text-grey">
                  //         Total subscriptions to all subscription-based OTT services
                  //       </span>
                  //     ) : (
                  //       value === "SVOD" && (
                  //         <span className="text-pot-text-grey">
                  //           Total monthly viewers of all ad-based OTT services
                  //         </span>
                  //       )
                  //     )
                  //   }
                  iconType="circle"
                  iconSize={10}
                />
                <XAxis
                  label={{
                    value: "OTT Services",
                    position: "insideBottom",
                    offset: -20,
                  }}
                  name="x_axis"
                  dataKey="x_axis"
                />
                <YAxis
                  label={{
                    value: "% Growth",
                    angle: -90,
                    position: "insideLeft",
                    offset: 0,
                  }}
                  domain={[90, "auto"]}
                  tickCount={7}
                />
                <Tooltip formatter={(value) => `${value} %`} />
                {/* <Line
              //   name="Total subscriptions to all subscription-based OTT services"
              //   nameKey="x"
              type="monotone"
              dataKey={`AVOD`}
              stroke="#1B75BB"
            />
            <Line
              //   name="Total monthly viewers of all ad-based OTT services"
              type="monotone"
              dataKey={`SVOD`}
              stroke="#02A0B0"
            /> */}

                {market === "US"
                  ? usShows.map((each, id) => (
                      <Line
                        type="monotone"
                        dataKey={each}
                        stroke={strokes[id]}
                      />
                    ))
                  : canadaShows.map((each, id) => (
                      <Line
                        type="monotone"
                        dataKey={each}
                        stroke={strokes[id]}
                      />
                    ))}
                {/* <ReferenceLine */}
              </LineChart>
            </ResponsiveContainer>
            <div className="absolute -top-1 left-16 flex gap-5">
              <button
                onClick={() => setMarket("US")}
                className={`text-sm px-2 py-1 border rounded ${
                  market === "US" && "bg-[#1B75BB] text-white border-[#1B75BB]"
                }`}
              >
                US
              </button>
              <button
                onClick={() => setMarket("CANADA")}
                className={`text-sm px-2 py-1 border rounded ${
                  market === "CANADA" &&
                  "bg-[#1B75BB] text-white border-[#1B75BB]"
                }`}
              >
                Canada
              </button>
            </div>
          </div>
        )}
        <div className="w-full py-2 flex justify-end text-xs px-2 text-pot-t-grey">
          © Parks Associates
        </div>
      </div>
    </section>
  );
};

export default TrendingChart;
