import React, { useEffect, useState } from "react";
import ArticleTopBar from "./ArticleTopBar";
import ArticleList from "./ArticleList";
import FilterBar from "../../components/ArticleFilterBar";
import { useSetRecoilState } from "recoil";
import { titleAtom } from "../../atom";
import { DotPulse } from "@uiball/loaders";
import { titles } from "../../utils/Titles";

function Articles() {
  const [nameSearch, setNameSearch] = useState("");
  const [searchOttVal, setSearchOTTVal] = useState("");
  const [called, callAgain] = useState(false);
  const [page, setPage] = useState(1)
  const [newPageLoading, setPageLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [selectedYear, setSelectedYear] = useState(0);
  const setTitle = useSetRecoilState(titleAtom);

  const scrollHandle = (e) => {
    if(totalPages <= page ) return
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if ( scrollTop + clientHeight >= scrollHeight ) {
      setPage((prev) => prev + 1);
      setPageLoading(true)
    }
  };

  useEffect(() => {
    setTitle("Library");
    document.title = `Streaming Video Tracker | ${titles[window.location.pathname]}`
  }, []);

  return (
    <div onScroll={scrollHandle} className="w-full max-h-[94vh] overflow-y-auto ">
      {/* filter section */}
      <div className="sticky top-0 z-40">
        <ArticleTopBar setSelectedYear={setSelectedYear} selectedYear={selectedYear} />
        {/* drop down section */}
        <FilterBar
          callApi={() => callAgain(true)}
          nameSearch={nameSearch}
          setNameSearch={setNameSearch}
          setSearchOTTVal={setSearchOTTVal}
        />
      </div>
      <ArticleList
        page={page}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        setTotalPages={setTotalPages}
        setPageLoading={setPageLoading}
        searchOTTVal={searchOttVal}
        nameSearch={nameSearch}
        called={called}
        callAgain={callAgain}
        setPage={setPage}
      />
      {newPageLoading && (
        <div className="w-full flex justify-center py-5">
          <DotPulse color="#1767A5" />
        </div>
      )}
    </div>
  );
}

export default Articles;
