import React from "react";

const Distribution = ({ detail }) => {
  return (
    <section className="w-full flex flex-col justify-evenly border border-pot-grey-4/10 rounded-2xl shadow-md p-3">
      <div className="leading-tight text-sm">
        <p className="font-semibold w-[50%]">Distribution Type</p>
        <div className="h-4 w-4"></div>
      </div>
      {detail.distribution_types?.existing
        ?.concat(detail.distribution_types?.new)
        ?.map((each) => (
          <p className="text-pot-dark-blue text-xl mt-1 font-bold">{each}</p>
        ))}
    </section>
  );
};

export default Distribution;
