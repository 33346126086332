export const titles = {
  "/dashboard": "Dashboard",
  "/search": "Search",
  "/library": "Library",
  "/search/results": "Search Results",
  "/search/viewers-trend": "Search Viewers Trend",
  "/service-comparison": "Service Comparison",
  "/platform-comparison": "Platform Comparison",
  "/profile": "Profiles",
  "/contact": "Contact",
  "/profile-feedback": "Profile Feedback",
  "/change-password": "Change Password",
  "/viewers-trend": "Viewers Trend",
  "/nps": "NPS",
  "/churn": "Churn",
  "/admin/dashboard": "Admin Dashboard",
  "/admin/data/upload": "Upload Data",
  "/admin/data-subscribers/upload": "Upload Subscribers",
  "/admin/user/admin": "Admin List",
  "/admin/user/customer": "Customer List",
  "/admin/email-template": "Email Template",
  "/admin/user/organization": "Organization List",
  "/admin/nps-churn/upload-data": "Upload NPS & Churn Data",
  "/admin/nps-churn/nps-standard-charts": "Non-Standard Charts",
};
