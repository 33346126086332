import React from "react";
import { toNormalText } from "../../utils/helperFunc";

const InputAreaField = ({
  className,
  heading,
  formData,
  setFormData,
  value,
  placeholder,
  error,
  setError,
  rows = 4,
}) => {
  return (
    <div className={className}>
      <p className="font-semibold text-xs">{heading}</p>
      <textarea
        rows={rows}
        onFocus={() => {
          if (error[value]) {
            delete error[value];
            setError({ ...error });
          }
        }}
        value={formData?.[value] || ""}
        onChange={(e) => {
          formData[value] = e.target.value;
          setFormData({ ...formData });
        }}
        placeholder={placeholder || toNormalText(value)}
        className="w-full border outline-none rounded px-3 py-2 mt-3 border-pot-blue-2/20 shadow shadow-pot-blue-2/10 focus:shadow-lg focus:shadow-pot-blue-2/10"
      />
      <p className="text-red-600">{error[value]}</p>
    </div>
  );
};

export default InputAreaField;
