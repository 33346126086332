import React, { useEffect, useState } from "react";
import Toggle from "@atlaskit/toggle";
import { AnimatePresence, motion } from "framer-motion";
import { CustomerApi } from "../../api/customer";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Access = ({ children, access = true, setAccess = () => {}, isEdit=false }) => {
  const [toggleClickCount, setToggleClickCount] = useState(0)
  const { id } = useParams()

  const callToggleChange = async (accessVal) => {
    const payload = { is_active: accessVal };
    const { data: response } = await CustomerApi.update(
      id,
      payload
    );
    if (!response.success) {
      Object.values(response.errors).forEach((each) => {
        console.log(each);
        toast.error(each.join(", "));
        setAccess(prev => !prev)
      });
      return;
    }
  };

  useEffect(() => {
    if(toggleClickCount) {
      const timeoutId = setTimeout(() => callToggleChange(access), 400)
      return(() => clearTimeout(timeoutId))
    }
  }, [toggleClickCount])

  return (
    <>
      <div className="border-b pb-4 px-10 sticky top-0 z-20 items-center pt-5 justify-between flex bg-white">
        <p className="text-base font-semibold">Access</p>
        {isEdit && (
          <div className="select-none">
            <Toggle
              isChecked={access}
              onChange={() => {
                setToggleClickCount(prev => prev + 1)
                setAccess((prev) => !prev)
              }}
            />
          </div>
        )}
      </div>
      <AnimatePresence>
        {access && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="px-10 pt-2 bg-white"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Access;
