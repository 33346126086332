import React, { useEffect, useRef, useState } from "react";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import { AnimatePresence, motion } from "framer-motion";
import useOnClickOutside from "../hooks/useClickOutside";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AllFilterOptionsAtom,
  AllNamesAtom,
  NameFilterFromProfileAtom,
  ProfileCountryAtom,
  SelectedProfileCountryAtom,
} from "../atom";
import { formatNamesToObj } from "../utils/formatConfig";
import CrossIcon from "@atlaskit/icon/glyph/cross";

const FilterBar = ({
  setSearchOTTVal = () => {},
  setNameSearch = () => {},
  nameSearch = "",
  callApi = () => {},
}) => {
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [allOttNames, setAllOttNames] = useState(null);
  const [normalInputVal, setNormalInputVal] = useState("");
  const [inputVal, setInputValue] = useState("");
  const [optimisedInputVal, setOptimisedInputVal] = useState("");
  const [selectedOttName, setSelectedOttName] = useState("");
  const [simplyOpened, setSimplyOpened] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState("");
  // const [call, setCall] = useState(false)
  const [profile, setProfile] = useRecoilState(NameFilterFromProfileAtom);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);
  const allNames = useRecoilValue(AllNamesAtom);
  const profileCountries = useRecoilValue(ProfileCountryAtom);
  const allOptions = useRecoilValue(AllFilterOptionsAtom);
  useOnClickOutside(containerRef, () => {
    setOpenDropdown(false);
  });

  useEffect(() => {
    if (allOptions.names) {
      // const names = allNames[selectedProfileCountry]
      // setAllOttNames(names);
      setSelectedRegion(selectedProfileCountry);
    }
  }, [allOptions]);

  useEffect(() => {
    const id = setTimeout(() => setOptimisedInputVal(inputVal), 400);
    return () => clearTimeout(id);
  }, [inputVal]);

  useEffect(() => {
    if (openDropdown) {
      if (!selectedOttName) {
        console.log("doii", "called", nameSearch, "hello", selectedOttName);
        setSimplyOpened(true);
      } else {
        setSimplyOpened(false);
      }
      setNormalInputVal("");
      setSelectedOttName("");
    } else {
      if (!allOttNames) return;
      if (Object.keys(allOttNames).includes(selectedOttName)) {
        // console.log("It has reached here,", allOttNames[selectedOttName], selectedOttName)
        setSearchOTTVal(allOttNames[selectedOttName]);
        callApi();
        // setCall(true)
      } else {
        if (!nameSearch && simplyOpened) {
          console.log("doii ", selectedOttName, simplyOpened);
          setSimplyOpened(false);
          return;
        }
        setSelectedOttName("");
        setSearchOTTVal("");
        callApi();
      }
    }
  }, [openDropdown]);

  useEffect(() => {
    if (normalInputVal === "") {
      if (nameSearch === "") {
        return;
      }
      setNameSearch("");
      callApi();
      // setCall(true)
    } else {
      setSelectedOttName("");
      setSearchOTTVal("");
      const id = setTimeout(() => {
        setNameSearch(normalInputVal);
        callApi();
        // setCall(true)
      }, 400);
      return () => clearTimeout(id);
    }
  }, [normalInputVal]);

  useEffect(() => {
    console.log(profile, "proooooo");
    if (profile) {
      setSelectedOttName(profile.name);
      setSearchOTTVal(profile.id);
      callApi();
      setProfile(null);
    }
  }, [profile]);

  console.log(profile, "prooo oooo");

  // useEffect(() => {
  //   if(call) {
  //     callApi()
  //     setCall(false)
  //   }
  // }, [call])

  return (
    <div className="w-full bg-white flex space-x-6 items-center py-2 px-8 text-sm">
      <label for="ott" className="block text-gray-900 font-bold	">
        Search
      </label>
      {/* <div className="w-[20vw] flex justify-between px-2 rounded-md text-xs items-center bg-pot-background-grey-2 border">
        <input
          value={normalInputVal}
          onChange={(e) => {
            setNormalInputVal(e.target.value);
          }}
          className="outline-none cursor-pointer focus:cursor-auto w-full bg-transparent py-0.5"
          placeholder="Search Name"
        />
        <button
          onClick={() => setNormalInputVal("")}
          className={normalInputVal ? "" : "invisible"}
        >
          <EditorCloseIcon size="medium" />
        </button>
      </div> */}
      <div ref={containerRef} className="w-[20vw] relative">
        <div
          onClick={() => {
            if (!openDropdown) {
              inputRef.current.focus();
            }
            setOpenDropdown((prev) => !prev);
          }}
          className="w-full flex justify-between px-2 rounded-md text-xs items-center bg-pot-background-grey-2 border"
        >
          <input
            ref={inputRef}
            value={selectedOttName || inputVal}
            onChange={(e) => {
              if (selectedOttName) return;
              setInputValue(e.target.value);
            }}
            className="outline-none cursor-pointer focus:cursor-auto w-full bg-transparent py-0.5"
            placeholder="Search OTT"
          />
          {selectedOttName ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedOttName("");
                setSearchOTTVal("");
                callApi();
              }}
              className="pb-1 pt-2"
            >
              <CrossIcon size="small" primaryColor="#1767A5" />
            </button>
          ) : (
            <motion.button
              className="pt-1"
              animate={{ rotate: openDropdown ? 180 : 0 }}
            >
              <ChevronDownIcon primaryColor="#1767A5" size="medium" />
            </motion.button>
          )}
        </div>
        <AnimatePresence>
          {openDropdown && (
            <motion.div className="w-full mt-2 rounded-md border bg-white absolute shadow-md z-[45] max-h-[40vh] overflow-y-auto">
              <div className="flex h-fit bg-pot-grey-4/60 mb-2">
                {Object.keys(profileCountries).map((each) => (
                  <div className="relative w-full h-fit">
                    {selectedRegion === each && (
                      <motion.div
                        layoutId="selectedRegion"
                        className="absolute rounded-t-md top-0 left-0 w-full h-full bg-pot-blue"
                      ></motion.div>
                    )}
                    <button
                      onClick={() => setSelectedRegion(each)}
                      className="relative w-full text-white py-3"
                    >
                      {profileCountries[each]}
                    </button>
                  </div>
                ))}
              </div>
              {selectedRegion &&
                Object.keys(allNames[selectedRegion])
                  .filter(
                    (ott) =>
                      (ott.toLowerCase().startsWith("prime video") &&
                        optimisedInputVal.startsWith("ama")) || //Note: This is hardcoded as per client's request. They wanted amazon to show prime video in suggestion
                      ott
                        .toLowerCase()
                        .includes(optimisedInputVal.toLowerCase())
                  )
                  .sort((a, b) => {
                    if (
                      b
                        .toLowerCase()
                        .startsWith(optimisedInputVal.toLowerCase())
                    ) {
                      return 1;
                    } else {
                      return -1;
                    }
                  })
                  .map((ottName) => (
                    <button
                      onClick={() => {
                        setInputValue("");
                        setOpenDropdown(false);
                        setSelectedOttName(ottName);
                      }}
                      className="w-full text-left text-xs px-3 py-2 border-l-2 border-white transition ease-out hover:bg-pot-grey-4/10 hover:border-l-2 hover:border-pot-dark-blue"
                    >
                      {ottName}
                    </button>
                  ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default FilterBar;
