import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import { padStart, subsLess } from "../../utils/helperFunc";

const Subscribers = ({ detail, region }) => {
  const styles = StyleSheet.create({
    container: {
      width: "100%",
      marginLeft: "10px",
      borderRadius: "8px",
      border: 1,
      borderColor: "#F0F1F8",
      display: "flex",
      justifyContent: "space-around",
      paddingVertical: "5px",
      paddingHorizontal: "8px",
    },
    value_container: {
      display: "flex",
      flexDirection:
        !detail.subscribers_count?.existing ||
        !detail.subscribers_growth?.existing
          ? "column"
          : "row",
      alignItems: "center",
      marginVertical: "1px",
    },
    title: {
      fontWeight: "semibold",
      width: "100%",
      marginRight: "3px",
    },
    value_subs: {
      width: "100%",
      fontSize: "11px",
      fontWeight: "bold",
      color: "#1767A5",
    },
    value_sub_g: {
      width: "100%",
      fontSize: "11px",
      fontWeight: "bold",
      color: detail.subscribers_growth?.existing >= 0 ? "#00838F" : "#C34B4B",
    },
  });

  return (
    <View style={styles.container}>
      {detail.subscribers_count?.existing && (
        <View style={styles.value_container}>
          <Text style={styles.title}>{region === "United States" ? "US" : region || "US"} Subscribers</Text>
          <Text style={styles.value_subs}>
          {String(detail.subscribers_count?.existing.toFixed(2)) === "0.00"
                ? "0"
                : subsLess(detail.subscribers_count?.existing)}
              {typeof detail.subscribers_count?.existing !== "string" && "M"}
            {/* {detail.subscribers_count?.existing}M */}
            </Text>
        </View>
      )}
      {detail.subscribers_growth?.existing && (
        <View style={styles.value_container}>
          <Text style={styles.title}>Subscribers Change</Text>
          <Text style={styles.value_sub_g}>
            {String(detail.subscribers_growth?.existing.toFixed(2)) === "0.00"
              ? "0"
              : padStart(detail.subscribers_growth?.existing.toFixed(2))}
            {typeof detail.subscribers_growth?.existing !== "string" && "M"}
            {/* {detail.subscribers_growth?.existing}M */}
            </Text>
        </View>
      )}
    </View>
  );
};

export default Subscribers;
