import React, { useEffect, useState } from "react";
import { LeftArrow } from "../../assets/Arrows";
import { motion } from "framer-motion";
import AdminForm from "../../form/Admin";
// import Heart from "../../assets/Heart";
import Toggle from "@atlaskit/toggle";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  AccountUserAtom,
  // AdminEditValuesAtom,
  AppInfoAtom,
  LoadingProgressAtom,
  SelectedProfileCountryAtom,
  titleAtom,
} from "../../atom";
// import { validateEmail } from "../../utils/validations";
import { AdminApi } from "../../api/admin";
// import { MacScrollbar } from 'mac-scrollbar';
import PeopleIcon from '@atlaskit/icon/glyph/people'
import { TelemetryApi } from "../../api/telemetry";

const AdminDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [permissions, setPermissions] = useState([]);
  const [error, setError] = useState({});
  const [noPermissionFor, setNoPermissionFor] = useState(null);
  const setTitle = useSetRecoilState(titleAtom);
  const setLoading = useSetRecoilState(LoadingProgressAtom);
  const [formData, setFormData] = useState({ phone: { code: "+1" } });
  const appInfo = useRecoilValue(AppInfoAtom);
  const isEdit = location.pathname.includes("edit");
  const account = useRecoilValue(AccountUserAtom);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom)
  const [submitLoading, setSubmitLoading] = useState(false)

  const back = () => {
    console.log(window.history, "state", window.navigator)
    // if (window.history.state && window.history.state.idx > 0) {
    if((window.navigation?.currentEntry?.index > 1) || (window.history.state && window.history.state.idx > 0) || (window.history.length > 2)) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  const getPayload = () => {
    let formattedFormData = {
      ...formData,
      // phone: formData.phone.code + formData.phone.number,
      user_type: "Admin",
      user_permissions: permissions,
    }
    if(formData.phone.number) {
      formattedFormData = { ...formattedFormData, phone: formData.phone.code + formData.phone.number}
    } else {
      delete formattedFormData.phone
    }
    return(formattedFormData)
  };

  const create = async () => {
    if(submitLoading) {
      return;
    }
    setSubmitLoading(true)
    const payload = getPayload();
    const { data: response } = await AdminApi.create(payload);
    setSubmitLoading(false)
    if (!response.successs && response.errors) {
      let errs = {}
        response.errors.error_message.forEach((each) => {
          if(typeof(each) === "string") {
            errs.error_message = each
          } else {
            errs = { ...errs, ...each}
          }
        })
        setError(errs);
      // setError(response.errors);
    } else {
      back();
    }
  };

  const edit = async () => {
    if(submitLoading) {
      return;
    }
    setSubmitLoading(true)
    const payload = getPayload();
    const id = formData.id;
    delete payload.id;
    const { data: response } = await AdminApi.update(id, payload);
    setSubmitLoading(false)
    if (response.success) {
      back();
    } else {
      if (response.errors) {
        let errs = {}
        response.errors.error_message.forEach((each) => {
          if(typeof(each) === "string") {
            errs.error_message = each
          } else {
            errs = { ...errs, ...each}
          }
        })
        setError(errs);
        // setError(response.errors);
      } else {
        back();
      }
    }
  };

  // const doPageTelemetry = async (isEdit) => {
  //   const payload = {
  //     url: window.location.href,
  //     title: isEdit ? "Edit Admin" : "Create Admin"
  //   }
  //   const params = { profile_country: selectedProfileCountry }
  //   const { data: response } = await TelemetryApi.pageView(payload, { params })
  //   if(!response.success) {
  //     console.error(response.errors)
  //   }
  // }

  const fetchAdmin = async () => {
    setLoading(40);
    const { data: response } = await AdminApi.show(id);
    setLoading(50);
    const phone = response.data.phone_no;
    const admin = {
      ...response.data,
      phone: { code: (phone?.[0] || "+1"), number: (phone?.[1] || "") },
    };
    setLoading(80);
    delete admin.phone_no;
    setPermissions(response.data.user_permissions);
    setFormData(admin || {});
    setLoading(100);
  };

  useEffect(() => {
    setTitle("Admin List");
    document.title = isEdit ? "Streaming Video Tracker | Edit Admin" : "Streaming Video Tracker | Create Admin"
    // doPageTelemetry(isEdit)
  }, []);

  useEffect(() => {
    if (id && account?.user_permissions.includes("a_modify_admin")) {
      fetchAdmin();
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      if (isEdit && !account.user_permissions.includes("a_modify_admin")) {
        setNoPermissionFor("edit");
      } else if (
        !isEdit &&
        !account.user_permissions.includes("a_create_admin")
      ) {
        setNoPermissionFor("create");
      }
    }
  }, [account]);

  if (noPermissionFor) {
    return (
      <div className="flex flex-col w-full h-[63vh] items-center justify-center">
        <p className="text-pot-dark-green font-semibold mb-10">
          You are not allowed to {noPermissionFor} an admin
        </p>
        <Link
          to="/admin/user/admin"
          className="bg-pot-dark-blue text-white px-4 py-1 text-sm font-semibold rounded"
        >
          back
        </Link>
      </div>
    );
  }

  return (
    <section className="w-full flex divide-x">
      {/* <MacScrollbar> */}
        <section className="w-full h-[94vh] overflow-y-auto text-xs pt-12 px-16">
          <div className="flex items-center">
            <motion.button
              onClick={back}
              whileHover={{ scale: 1.5 }}
              className="pr-4"
            >
              <LeftArrow />
            </motion.button>
            <p className=" text-sm font-semibold">Admin Details</p>
          </div>
          <div className="px-4 mt-3">
            {/* <p className="text-pot-text-grey">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
              aliquam, purus sit amet luctus venenatis, lectus aliquam, purus
              sit
            </p> */}
            <AdminForm
              isEdit={isEdit}
              formData={formData}
              setFormData={setFormData}
              onSubmit={isEdit ? edit : create}
              error={error}
              setError={setError}
              loading={submitLoading}
            />
          </div>
        </section>
      {/* </MacScrollbar>
      <MacScrollbar> */}
        <section className="w-full h-[94vh] overflow-y-auto pt-12 px-20 text-xs">
          <p className=" text-sm font-semibold mb-3">Permissions</p>
          {/* <p className="text-pot-text-grey">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus r adipiscing elit ut
            aliquam, purus sit amet luctus venenatis, lectus
          </p> */}
          {appInfo?.permissions?.ADMIN.map((permission) => (
            <>
              <div className="flex justify-between items-center mt-6">
                <div className="flex gap-2">
                  <PeopleIcon size="small" primaryColor="#1767A5" />
                  {permission.title}
                </div>
                <div className="select-none">
                <Toggle
                  isChecked={permission.permissions.some((a) =>
                    permissions.includes(a.codename)
                  )}
                  onChange={() => {
                    if (
                      permission.permissions.some((a) =>
                        permissions.includes(a.codename)
                      )
                    ) {
                      const filteredPermissions = permissions.filter(
                        (each) =>
                          !permission.permissions
                            .map((del) => del.codename)
                            .includes(each)
                      );
                      setPermissions(filteredPermissions);
                    } else {
                      permission.permissions.forEach((each) => {
                        if (!permissions.includes(each.codename)) {
                          permissions.push(each.codename);
                        }
                      });
                      setPermissions([...permissions]);
                    }
                  }}
                />
                </div>
              </div>
              <div className="pl-6 pr-3">
                {permission.permissions.map((each) => (
                  <div className="flex justify-between mt-2">
                    <p className="">{each.name}</p>
                    <input
                      type="checkbox"
                      className="accent-pot-blue"
                      checked={permissions.includes(each.codename)}
                      onChange={() => {
                        if (!permissions.includes(each.codename)) {
                          setPermissions([...permissions, each.codename]);
                        } else {
                          const filteredPermissions = permissions.filter(
                            (per) => per !== each.codename
                          );
                          setPermissions(filteredPermissions);
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          ))}
        </section>
      {/* </MacScrollbar> */}
    </section>
  );
};

export default AdminDetails;
