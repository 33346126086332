import React from "react";

const RoundedFilledClose = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00008 17.3332C13.6025 17.3332 17.3334 13.6022 17.3334 8.99984C17.3334 4.39746 13.6025 0.666504 9.00008 0.666504C4.39771 0.666504 0.666748 4.39746 0.666748 8.99984C0.666748 13.6022 4.39771 17.3332 9.00008 17.3332ZM7.82658 9.01266L6.59758 10.249C6.51824 10.3261 6.45504 10.4182 6.41168 10.52C6.36832 10.6218 6.34566 10.7312 6.34502 10.8418C6.34439 10.9525 6.36579 11.0621 6.40798 11.1644C6.45017 11.2667 6.5123 11.3595 6.59076 11.4375C6.66921 11.5156 6.76241 11.5772 6.86492 11.6188C6.96744 11.6604 7.07721 11.6812 7.18784 11.6799C7.29847 11.6786 7.40774 11.6554 7.50928 11.6114C7.61082 11.5675 7.7026 11.5038 7.77925 11.424L9.00237 10.1936L10.2018 11.3982C10.3577 11.5549 10.5695 11.6432 10.7905 11.6438C11.0116 11.6443 11.2238 11.557 11.3805 11.4011C11.5372 11.2452 11.6255 11.0334 11.6261 10.8123C11.6266 10.5913 11.5393 10.3791 11.3834 10.2224L10.1774 9.01158L11.4126 7.76899C11.564 7.6114 11.6474 7.40064 11.645 7.18212C11.6425 6.96359 11.5542 6.75479 11.3993 6.6007C11.2443 6.44661 11.035 6.35956 10.8165 6.3583C10.5979 6.35705 10.3876 6.44169 10.2309 6.59399L9.00129 7.83094L7.75008 6.57485C7.59324 6.42269 7.38291 6.33822 7.16439 6.33964C6.94587 6.34106 6.73665 6.42826 6.5818 6.58245C6.42696 6.73664 6.33887 6.94549 6.33652 7.164C6.33418 7.38251 6.41775 7.5932 6.56925 7.75069L7.82658 9.01266Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default RoundedFilledClose;
