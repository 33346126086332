import React, { useEffect, useState } from "react";
import { LeftArrow } from "../../assets/Arrows";
import { motion } from "framer-motion";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import OrganizationForm from "../../form/Organization";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AccountUserAtom, LoadingProgressAtom, titleAtom } from "../../atom";
import { OrganizationApi } from "../../api/organization";
import { MacScrollbar } from "mac-scrollbar";
import { TelemetryApi } from "../../api/telemetry";

const OrganizationDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [error, setError] = useState({});
  const [noPermissionFor, setNoPermissionFor] = useState(null);
  const setTitle = useSetRecoilState(titleAtom);
  const setLoading = useSetRecoilState(LoadingProgressAtom);
  const [formData, setFormData] = useState({ phone: { code: "+1" } });
  const isEdit = location.pathname.includes("edit");
  const account = useRecoilValue(AccountUserAtom);
  const [submitLoading, setSubmitLoading] = useState(false)

  const back = () => {
    // if (window.history.state && window.history.state.idx > 0) {
    if((window.navigation?.currentEntry?.index > 1) || (window.history.state && window.history.state.idx > 0) || (window.history.length > 2)) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  const getPayload = () => {
    let formattedFormData = {
      ...formData,
      // phone: formData.phone.code + formData.phone.number,
    }
    if(formData.phone.number) {
      formattedFormData = { ...formattedFormData, phone: formData.phone.code + formData.phone.number}
    } else {
      delete formattedFormData.phone
    }
    return(formattedFormData)
  }
  // ({
  //   ...formData,
  //   phone: formData.phone.code + formData.phone.number,
  // });

  const create = async () => {
    if(submitLoading) {
      return;
    }
    setSubmitLoading(true)
    const payload = getPayload();
    const { data: response } = await OrganizationApi.create(payload);
    setSubmitLoading(false)
    if (!response.successs && response.errors) {
      let errs = {}
        response.errors.error_message.forEach((each) => {
          if(typeof(each) === "string") {
            errs.error_message = each
          } else {
            errs = { ...errs, ...each}
          }
        })
        setError(errs);
      // setError(response.errors);
    } else {
      back();
    }
  };

  const edit = async () => {
    if(submitLoading){
      return;
    }
    setSubmitLoading(true)
    const payload = getPayload();
    const id = formData.id;
    delete payload.id;
    const { data: response } = await OrganizationApi.update(id, payload);
    setSubmitLoading(true)
    if (response.success) {
      back();
    } else {
      if (response.errors) {
        let errs = {}
        response.errors.error_message.forEach((each) => {
          if(typeof(each) === "string") {
            errs.error_message = each
          } else {
            errs = { ...errs, ...each}
          }
        })
        setError(errs);
        // setError(response.errors);
      } else {
        back();
      }
    }
  };

  const fetchOrganization = async () => {
    setLoading(40);
    const { data: response } = await OrganizationApi.show(id);
    setLoading(70);
    const phone = response.data.phone_no;
    const organization = {
      ...response.data,
      phone: { code: (phone?.[0] || ""), number: (phone?.[1] || "") },
    };
    setLoading(80);
    delete organization.phone_no;
    setFormData(organization);
    setLoading(100);
  };

  // const doPageTelemetry = async (isEdit) => {
  //   const payload = {
  //     url: window.location.href,
  //     title: isEdit ? "Edit Organization" : "Create Organization"
  //   }
  //   const { data: response } = await TelemetryApi.pageView(payload)
  //   if(!response.success) {
  //     console.error(response.errors)
  //   }
  // }

  useEffect(() => {
    setTitle("Organization List");
    document.title = isEdit ? "Streaming Video Tracker | Edit Organization" : "Streaming Video Tracker | Create Organization"
    // doPageTelemetry()
  }, []);

  useEffect(() => {
    if (id) {
      fetchOrganization();
    }
  }, []);

  useEffect(() => {
    if (account) {
      if (
        isEdit &&
        !account.user_permissions.includes("a_modify_organization")
      ) {
        setNoPermissionFor("edit");
      } else if (
        !isEdit &&
        !account.user_permissions.includes("a_create_organization")
      ) {
        setNoPermissionFor("create");
      }
    }
  }, [account]);

  if (noPermissionFor) {
    return (
      <div className="flex flex-col w-full h-[63vh] items-center justify-center">
        <p className="text-pot-dark-green font-semibold mb-10">
          You are not allowed to {noPermissionFor} an organization
        </p>
        <Link
          to="/admin/user/organization"
          className="bg-pot-dark-blue text-white px-4 py-1 text-sm font-semibold rounded"
        >
          back
        </Link>
      </div>
    );
  }

  return (
    <MacScrollbar>
      <section className="w-[50vw] h-[94vh] overflow-y-auto text-xs pt-12 px-16">
        <div className="flex items-center">
          <motion.button
            onClick={back}
            whileHover={{ scale: 1.5 }}
            className="pr-4"
          >
            <LeftArrow />
          </motion.button>
          <p className=" text-sm font-semibold">Organization List</p>
        </div>
        <div className="px-4 mt-3">
          {/* <p className="text-pot-text-grey">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus aliquam, purus sit
          </p> */}
          <OrganizationForm
            isEdit={isEdit}
            formData={formData}
            setFormData={setFormData}
            onSubmit={isEdit ? edit : create}
            error={error}
            setError={setError}
            loading={submitLoading}
          />
        </div>
      </section>
    </MacScrollbar>
  );
};

export default OrganizationDetails;
