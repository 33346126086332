import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import Doc from "../../assets/Doc";
import RoundedFilledClose from "../../assets/RoundedFilledClose";
import UploadPlus from "../../assets/UploadPlus";
import { motion } from "framer-motion";
import axios from "axios";
import {
  BASE_URL,
  BASE_URLV2,
  authenticatedAxiosInstanceV2 as axiosV2,
} from "../../api/axios";
import { getFromLocalStorage } from "../../utils/localStorage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  DescriptionAtom,
  UploadVerifyIdAtom,
  VerificationResultAtom,
} from "../../atom";
import { useRecoilState, useSetRecoilState } from "recoil";
import UploadControls from "./UploadControls";
import moment from "moment";
import { DataImportApi } from "../../api/dataImport";

const Dropzone = ({ hasTable, hasDownload }) => {
  const [showUploadRegion, setShowUploadRegion] = useState(false);
  const [acceptedFileName, setAcceptedFileName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showProceed, setShowProceed] = useState(false);
  const [uploadVerifyId, setUploadVerifyId] = useState(null);
  const [canceled, setCanceled] = useState(false);
  const setUploadId = useSetRecoilState(UploadVerifyIdAtom);
  const [quarterOpen, setQuarterOpen] = useState(false);
  const [quarter, setQuarter] = useState(null);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [date, setDate] = useState(null);
  const [showToolTip, setShowToolTip] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState(null);
  const [result, setVerificationResult] = useRecoilState(
    VerificationResultAtom
  );
  const [description, setDescription] = useRecoilState(DescriptionAtom);
  const sourceRef = useRef(null);
  const navigate = useNavigate();

  const uploadFile = async (accepted) => {
    setShowToolTip(false);
    setAcceptedFileName(accepted.name);
    setUploadProgress(2);
    setShowUploadRegion(true);
    sourceRef.current = axios.CancelToken.source();
    const formData = new FormData();
    formData.append("data", accepted, accepted.name);
    setUploadProgress(5);

    const incrementUploadProgress = () => {
      if (uploadProgress >= 90) {
        clearInterval(intervalId);
      } else {
        setUploadProgress((prev) => (prev < 90 ? prev + 0.2 : prev));
      }
    };

    const intervalId = setInterval(incrementUploadProgress, 300);
    try {
      const [quater, year] = quarter.split(" ");
      const params = {
        quarter: quater,
        year,
        data_last_updated: moment(date).format("YYYY-MM-DD"),
      };
      const { data: response } = await axiosV2.post(
        `/data-import/upload/`,
        formData,
        {
          baseURL: BASE_URLV2,
          cancelToken: sourceRef.current.token,
          headers: { Authorization: `Token ${getFromLocalStorage("token")}` },
          params,
        }
      );
      setUploadProgress(100);
      if (response.success) {
        setVerificationResult("SUCCESS");
        setUploadVerifyId(response.data?.filename);
        setDescription(response.data.errors_list);
      } else {
        setVerificationResult("ERROR");
        setShowProceed(true);
        setDescription(response.errors.error_message[0]);
        toast.error("File Upload Error ! ");
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        setCanceled(false);
      } else {
        toast.error(
          e.response.data.errors.error_message || "File Upload Error ! "
        );
      }
      setShowUploadRegion(false);
      setDate(null);
      setQuarter(null);
      setAcceptedFile(null);
    }
  };
  // const cancelUploaded = async () => {
  //   const { data: response } = await DataImportApi.stop()
  //   if(!response.success) {
  //     Object.values(response.data).forEach(each => {
  //       toast.error(each.join(", "))
  //     })
  //   }
  // }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!date || !quarter) {
        setShowToolTip(true);
        // setAcceptedFile(acceptedFiles[0])
        // setShowUploadRegion(true)
        return;
      }
      await uploadFile(acceptedFiles[0]);
    },
    [date, quarter]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    // clickable:'#dropzonePreview',
    noClick: true,
    onDrop,
    maxFiles: 1,
    multiple: false,
  });

  useEffect(() => {
    if (uploadVerifyId) {
      setShowProceed(true);
    }
  }, [uploadVerifyId]);

  useEffect(() => {
    if (canceled) {
      if (!date || !quarter) {
        setShowUploadRegion(false);
        setCanceled(false);
        return;
      }
      sourceRef.current.cancel("canceled");
      // cancelUploaded()
    }
  }, [canceled]);

  useEffect(() => {
    if (date && quarter) {
      setShowToolTip(false);
    }
  }, [date, quarter]);

  return (
    <div className="relative w-full">
      <UploadControls
        control={{ pickerOpen, setPickerOpen, quarterOpen, setQuarterOpen }}
        valueHandles={{ quarter, setQuarter, date, setDate }}
        showToolTip={showToolTip}
      />
      {showUploadRegion ? (
        <div
          className={`${hasTable ? "h-[20vh]" : "h-[50vh]"} ${
            !hasDownload && "mt-10"
          } mx-10 border-4 border-slate-100 border-dashed rounded-lg flex flex-col gap-2 justify-center items-center`}
        >
          <div className="w-[30vw] flex justify-between gap-3">
            <Doc />
            <div className="w-full flex flex-col justify-between pb-2">
              <div className="w-full flex justify-between px-3 text-sm">
                <p className="w-60 truncate">{acceptedFileName}</p>
                {/* <button onClick={() => setCanceled(true)}>
                  <RoundedFilledClose />
                </button> */}
              </div>
              <div className="w-full h-[3px] relative z-50 rounded-full mx-3 bg-pot-bg-grey-2">
                <motion.div
                  className="h-[3px] bg-pot-dark-blue rounded-full"
                  initial={{ width: 0 }}
                  animate={{ width: `${uploadProgress}%` }}
                ></motion.div>
              </div>
            </div>
          </div>
          {showProceed ? (
            <button
              onClick={() => {
                //  navigate(`/admin/data/upload/verifying/${uploadVerifyId}`)
                navigate(
                  `/admin/data/upload/result/${
                    result === "SUCCESS" ? uploadVerifyId : "error"
                  }`
                );
                setUploadId(uploadVerifyId);
              }}
              className={`bg-pot-dark-blue text-white px-4 py-2 font-semibold text-sm rounded-md `}
            >
              View Result
            </button>
          ) : (
            <div className="h-12 w-12"></div>
          )}
        </div>
      ) : (
        <div
          {...getRootProps({})}
          className={`${hasTable ? "h-[20vh]" : "h-[50vh]"} ${
            !hasDownload && "mt-10"
          } cursor-pointer mx-10 border-4  ${
            isDragActive ? "border-pot-blue/400" : "border-slate-100 "
          }border-dashed cursor-default rounded-lg flex relative justify-center items-center`}
        >
          {/* <input {...getInputProps()} /> */}
          <div className="flex flex-col justify-center items-center">
            {/* <input {...getInputProps()}/> */}
            <div onClick={open} className="cursor-pointer">
              <UploadPlus />
            </div>
            {isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>Upload An Excel Sheets Here</p>
            )}
            <p className="w-[40vw] mt-4 text-center text-xs text-pot-grey-4">
              Ensure the format is the same as the example file and upload
              only.xls files.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropzone;
