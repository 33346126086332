import React from "react";
import InputDivideField from "./components/InputDivideField";
import InputField from "./components/InputField";
import PhoneField from "./components/PhoneField";
import RadioField from "./components/RadioField";
import { motion } from "framer-motion";
import { MacScrollbar } from "mac-scrollbar";
import { useRecoilValue } from "recoil";
import { AppInfoAtom, CountryCodesAtom } from "../atom";
import { DotPulse } from "@uiball/loaders";

const AdminForm = ({
  isEdit,
  formData,
  setFormData,
  onSubmit,
  error,
  setError,
  loading=false,
}) => {
  const country_codes = useRecoilValue(CountryCodesAtom)

  return (
    <MacScrollbar>
      <section className="pb-8">
        <InputDivideField
          heading="Name"
          className="mt-8"
          formData={formData}
          setFormData={setFormData}
          value1="first_name"
          value2="last_name"
          error={error}
          setError={setError}
          required
        />
        <InputField
          heading="Designation/ Role"
          className="mt-8"
          formData={formData}
          setFormData={setFormData}
          value="designation"
          error={error}
          setError={setError}
        />
        {/* <RadioField
          heading="Gender"
          className="mt-8"
          formData={formData}
          setFormData={setFormData}
          value="gender"
          names={["MALE", "FEMALE"]}
          error={error}
          setError={setError}
        /> */}
        <PhoneField
          heading="Phone Number"
          placeholder={1234506789}
          formData={formData}
          setFormData={setFormData}
          value="phone"
          className="mt-8"
          error={error}
          setError={setError}
          country_codes={country_codes}
        />
        <InputField
          heading="Email ID"
          className="mt-8"
          formData={formData}
          setFormData={setFormData}
          value="email"
          placeholder="jack@example.com"
          error={error}
          setError={setError}
          required
        />
        <div className="w-full mt-8 flex justify-center">
          <motion.button
            onClick={onSubmit}
            whileHover={{ scale: 1.1 }}
            className="bg-pot-blue px-4 py-2 flex gap-x-2 items-center text-white text-sm font semibold rounded-md"
          >
            {loading &&  (
              <DotPulse size={12} speed={0.8} color="white" />
            )}
            {isEdit ? "Save" : "Create"}
          </motion.button>
        </div>
      </section>
    </MacScrollbar>
  );
};

export default AdminForm;
