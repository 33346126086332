import { DotPulse } from "@uiball/loaders";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { BASE_URL } from "../../api/axios";
import { LibraryApi } from "../../api/library";
import { AccountUserAtom, titleAtom } from "../../atom";
import Button from "../../form/components/Button";
import LibraryForm from "../../form/Library";
import { getFromLocalStorage } from "../../utils/localStorage";
import LibraryDropzone from "./LibraryDropzone";
import LibraryTable from "./Table";
import { AnimatePresence, motion } from "framer-motion";

const LibraryData = () => {
  const setTitle = useSetRecoilState(titleAtom);
  const [mainFile, setMainFile] = useState(null);
  const [name, setName] = useState("");
  const [pDate, setPDate] = useState(null);
  const [otts, setOtts] = useState([]);
  const [canOtts, setCanOtts] = useState([]);
  const [editData, setEditData] = useState(null);
  const [clear, setClear] = useState(false);
  const [called, callAgain] = useState(0);
  const [errors, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const account = useRecoilValue(AccountUserAtom);
  const hasView = account?.user_permissions.includes("a_view_library_item");
  const hasCreate = account?.user_permissions.includes("a_create_library_item");
  const hasEdit = account?.user_permissions.includes("a_modify_library_item");

  // const hasCreate = account?.user_permissions.includes("a_create_library_item")
  // const hasEdit = account?.user_permissions.includes("a_modify_library_item")

  const validate = () => {
    const errorObj = {};
    if (!name) {
      errorObj.name = ["This field is required."];
    }
    if (!pDate) {
      errorObj.publication_date = ["This field is required."];
    }
    if (!otts.concat(canOtts).length) {
      errorObj.profile_tags = ["This field is required."];
    }

    setError(errorObj);
    return !Object.keys(errorObj).length;
  };

  const handleProceed = async () => {
    if (loading) return;
    const valid = validate();
    if (!valid) return;
    setLoading(true);
    const formData = new FormData();
    if (mainFile?.file) {
      formData.append("file", mainFile.file);
    }
    formData.append("name", name);
    otts.concat(canOtts).forEach((each) => {
      formData.append("profile_tags", each.value);
    });
    if (pDate) {
      formData.append("publication_date", moment(pDate).format("YYYY-MM-DD"));
    }
    try {
      const { data: response } = await axios.post(`/library/`, formData, {
        baseURL: BASE_URL,
        headers: { Authorization: `Token ${getFromLocalStorage("token")}` },
      });
      setLoading(false);
      if (response.success) {
        setMainFile(null);
        setName("");
        setPDate(null);
        setOtts([]);
        setCanOtts([]);
        setClear(true);
        callAgain((prev) => !prev);
      } else {
        setError(response.errors.error_message[0]);
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.errors.error_message[0]);
    }
  };

  const handleEdit = async () => {
    if (loading) return;
    const valid = validate();
    if (!valid) return;
    setLoading(true);
    const formDataToSend = new FormData();
    mainFile.file && formDataToSend.append("file", mainFile.file);
    formDataToSend.append("name", name);

    otts.concat(canOtts).forEach((each) => {
      formDataToSend.append("profile_tags", each.value);
    });
    formDataToSend.append(
      "publication_date",
      moment(pDate).format("YYYY-MM-DD")
    );

    try {
      const { data: response } = await LibraryApi.update(
        editData.id,
        formDataToSend
      );
      setLoading(false);
      if (response.success) {
        setEditData(null);
        callAgain(true);
      } else {
        setError(response.errors.error_message[0]);
      }
    } catch (e) {
      setLoading(false);
      setError(e.response.data.errors.error_message[0]);
    }
  };

  useEffect(() => {
    document.title = "Streaming Video Tracker | Library";
    setTitle("Library");
  }, []);

  useEffect(() => {
    if (editData) {
      setMainFile({});
      setName(editData.name);
      setPDate(new Date(editData.publication_date));
      setOtts(
        editData.profile_tags
          .filter((each) => each.profile_country === "US")
          .map((each) => ({ label: each.name, value: each.id }))
      );
      setCanOtts(
        editData.profile_tags
          .filter((each) => each.profile_country === "CANADA")
          .map((each) => ({ label: each.name, value: each.id }))
      );
    } else {
      setName("");
      setMainFile(null);
      setPDate(null);
      setOtts([]);
      setCanOtts([]);
    }
  }, [editData]);

  useEffect(() => {
    if (clear) {
      const id = setTimeout(() => setClear(false), 1000);
      return () => clearTimeout(id);
    }
  }, [clear]);

  useEffect(() => {
    if (errors.publication_date) {
      delete errors.publication_date;
      setError({ ...errors });
    }
  }, [pDate]);

  if (!hasView) {
    return (
      <div className="flex flex-col w-full h-[90vh] items-center justify-center">
        <p className="text-pot-dark-green font-semibold mb-10">
          You are not allowed to view this page
        </p>
      </div>
    );
  }

  return (
    <section className="w-full 2xl:flex 2xl:flex-col 2xl:h-[90vh] 2xl:justify-evenly 2xl:overflow-y-auto">
      <AnimatePresence>
        {(hasCreate || (hasEdit && editData)) && (
          <motion.div
            initial={{ opacity: 0, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1, transition: { duration: 0.3 } }}
            exit={{ opacity: 0 }}
            className="mx-5 my-3 border-2 border-dashed divide-y-2 divide-dashed rounded-xl"
          >
            <div className="flex divide-x-2 divide-dashed">
              <div className="w-[70%] px-5">
                <LibraryDropzone
                  error={errors.file}
                  clearError={() => {
                    if (errors.file) {
                      delete errors.file;
                      setError({ ...errors });
                    }
                  }}
                  editData={editData}
                  clear={clear}
                  setMainFile={setMainFile}
                />
              </div>
              <div className="w-full p-5">
                <LibraryForm
                  errors={errors}
                  setError={setError}
                  clear={clear}
                  otts={otts}
                  canOtts={canOtts}
                  setCanOtts={setCanOtts}
                  editData={editData}
                  setName={setName}
                  name={name}
                  setPDate={setPDate}
                  setOtts={setOtts}
                />
              </div>
            </div>
            <div className="py-[2vh] flex justify-center">
              <Button
                text={
                  <div className="flex gap-x-2 items-center">
                    {loading && (
                      <DotPulse size={20} speed={0.8} color="white" />
                    )}
                    Proceed
                  </div>
                }
                onClick={() => (editData?.id ? handleEdit() : handleProceed())}
                primary
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <LibraryTable
        height={hasCreate || (hasEdit && editData) ? undefined : "h-[90vh]"}
        called={called}
        callAgain={callAgain}
        setEditData={setEditData}
      />
    </section>
  );
};

export default LibraryData;
