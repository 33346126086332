import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AccountUserAtom, TableLoadingAtom } from "../../../atom";
import Toggle from "@atlaskit/toggle";
import Trash from "../../../assets/Trash";
import moment from "moment";
import { DotPulse } from "@uiball/loaders";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import TableLayoutTwo from "../../../components/TableLayout/Two";
import TableControl from "../../../components/TableLayout/TableControl";
import { LibraryApi } from "../../../api/library";
import { toast } from "react-toastify";
import EditFilledIcon from "@atlaskit/icon/glyph/edit-filled";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { CustomCharts } from "../../../api/npschurn";
import OpenEye from "../../../assets/OpenEye";
import Preview from "./Preview";
import { formatDateToMMDDYY } from "../../../utils/DateHelpers";

const NonStandardChartsTable = ({
  height = "h-[47vh]",
  setEditData,
  called,
  callAgain,
}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [length, setLength] = useState(10);
  const [loading, setLoading] = useState("");
  const [colDef, setColDef] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [previewData, setPreviewData] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const account = useRecoilValue(AccountUserAtom);
  const setTableLoading = useSetRecoilState(TableLoadingAtom);

  const remove = async () => {
    setOpenDeletePopup(false);
    const { data: response } = await CustomCharts.destroy(deleteId);
    setDeleteId("");
    setEditData(null);
    callAgain((prev) => !prev);
  };

  const initialColDef = [
    {
      header: "Modified Date",
      accessorKey: "modified_at",
      width: "120px",
      cell: ({ getValue }) =>
        getValue() && moment(getValue()).format("MM-DD-YYYY"),
    },
    {
      header: "Page",
      accessorKey: "page",
      width: "120px",
      cell: ({ getValue }) => (
        <p className="font-semibold">{getValue() || "-"}</p>
      ),
    },
    {
      header: "Chart Name",
      accessorKey: "name",
      width: "300px",
      cell: ({ getValue }) => <p className="font-semibold">{getValue()}</p>,
    },
    {
      header: "Footer Text",
      accessorKey: "footer_text",
      cell: ({ getValue }) => <p className="font-semibold">{getValue()}</p>,
    },
    {
      header: "Actions",
      accessorKey: "action",
      width: "200px",
      cell: ({ row }) => (
        <div className="flex gap-x-2 items-center justify-evenly">
          {loading === row.original.id ? (
            <DotPulse size={20} speed={0.8} color="#1767A5" />
          ) : (
            <button
              onClick={() => downloadData(row.original.id, row.original.name)}
              className="pt-0.5"
            >
              <DownloadIcon primaryColor="#1767A5" />
            </button>
          )}
          <>
            <button
              onClick={() =>
                setEditData((prev) =>
                  prev?.id === row.original.id ? null : row.original
                )
              }
              className="pt-0.5"
            >
              <EditFilledIcon primaryColor="#1767A5" />
            </button>
          </>
          <>
            {deleteId === row.original.id ? (
              <DotPulse size={20} speed={0.8} color="#1767A5" />
            ) : (
              <button
                className="min-w-[1.25rem]"
                onClick={() => setDeleteId(row.original.id)}
              >
                <Trash />
              </button>
            )}
          </>
          <div
            className="cursor-pointer"
            onClick={() => setPreviewData(row?.original)}
          >
            <OpenEye />
          </div>
        </div>
      ),
    },
  ];

  const downloadData = async (id, name) => {
    try {
      setLoading(id);
      const response = await CustomCharts.downloadChart(id);
      if (response.status !== 200) {
        const errorString = await response.data.text();
        const errorObj = JSON.parse(errorString);
        toast.error(errorObj.errors.error_message.join(", "));
        setLoading("");
        return;
      }
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.target = "_blank";
      const date = new Date();
      const formattedDate = formatDateToMMDDYY(date, "-");
      const formattedName = `${name} - Prefilled (${formattedDate})`;
      link.setAttribute("download", `${formattedName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLoading("");
    } catch (e) {
      toast.error(JSON.stringify(e));
      setLoading("");
    }
  };

  useEffect(() => {
    setColDef([...initialColDef]);
  }, [account, loading, deleteId]);

  const fetchHistory = async () => {
    setTableLoading(true);
    const params = { page, length };
    const { data: response } = await CustomCharts.list({ params });
    setData(response.data.results);
    setTotalCount(response.data.total_count);
    setTotalPages(response.data.total_pages);
    setTableLoading(false);
    // callAgain(false);
  };

  useEffect(() => {
    fetchHistory();
  }, [page, length, account, called]);

  useEffect(() => {
    if (deleteId) {
      setOpenDeletePopup(true);
    }
  }, [deleteId]);

  useEffect(() => {
    if (previewData) {
      setOpenPreview(true);
    }
  }, [previewData]);

  const table = useReactTable({
    data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div
        className={`w-full relative ${height} overflow-y-auto mt-2 flex flex-col justify-between`}
      >
        <TableLayoutTwo table={table} />
        <TableControl
          page={page}
          setPage={setPage}
          length={length}
          setLength={setLength}
          totalCount={totalCount}
          totalPages={totalPages}
        />
        <Preview
          openPreview={openPreview}
          setOpenPreview={setOpenPreview}
          previewData={previewData}
          setPreviewData={setPreviewData}
        />
        <ModalTransition>
          {openDeletePopup && (
            <div className="rounded-xl bg-pot-grey-3">
              <Modal
                onClose={() => {
                  setDeleteId("");
                  setOpenDeletePopup(false);
                }}
                width={"400px"}
              >
                <ModalHeader>
                  <h1 className="flex justify-center text-xl w-full font-bold">
                    Delete Chart?
                  </h1>
                </ModalHeader>
                <ModalBody>
                  <p className="text-xs">
                    Are you sure you want to delete this chart? This action
                    cannot be undone and the chart will be permanently removed.
                  </p>
                </ModalBody>
                <div className="flex gap-4 p-4">
                  <button
                    onClick={() => {
                      setDeleteId("");
                      setOpenDeletePopup(false);
                    }}
                    className={`rounded-md px-2 py-1 w-full hover:bg-pot-blue hover:text-white transition-all ease-in-out`}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={remove}
                    className={`rounded-md px-2 py-1 text-red-500 hover:bg-red-500 hover:text-white w-full transition-all ease-in-out`}
                  >
                    Delete
                  </button>
                </div>
              </Modal>
            </div>
          )}
        </ModalTransition>
      </div>
    </>
  );
};

export default React.memo(NonStandardChartsTable);
