import React, { useEffect, useRef, useState } from "react";
import { CheckboxSelect } from "@atlaskit/select";
import { components } from "@atlaskit/select";
import Checkbox from "@atlaskit/checkbox";
import InfoIcon from "@atlaskit/icon/glyph/info";
import { AnimatePresence, motion } from "framer-motion";
import useOnClickOutside from "../../hooks/useClickOutside";

export const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.inputValue
          ? props.selectProps.inputValue
          : props.selectProps.placeholder}
      </components.Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== components.Placeholder ? child : null
      )}
    </components.ValueContainer>
  );
};

// const MenuList = ({ children, ...props }) => {
//   if (props.big) {
//     return (
//       <div className="h-[75vh] overflow-y-auto" {...props}>
//         <components.MenuList>
//           {children}
//         </components.MenuList>
//       </div>
//     );
//   }
//   return (
//     <components.MenuList {...props}>
//       {children}
//     </components.MenuList>
//   );
// };

const GroupHeading = (props) => {
  if (props.groupSelect) {
    return (
      <div
        className={`ml-4 text-pot-text-grey-2 font-bold text-xs flex items-center my-1 ${
          props.big && Number(props.id.split("-")[4]) && "border-t pt-5"
        }`}
        {...props}
      >
        <Checkbox
          isChecked={props.data.options.every((option) =>
            props.selectProps.value
              ?.map((each) => each.value)
              .includes(option.value)
          )}
          onChange={(e) => {
            if (e.target.checked) {
              const newValues = props.data.options.filter(
                (each) =>
                  !props.selectProps.value?.some(
                    (val) => val.value === each.value
                  )
              );
              props.selectProps.onChange(
                (props.selectProps.value || []).concat(newValues)
              );
            } else {
              if (props.selectProps.value) {
                const filteredValues = props.selectProps.value.filter(
                  (each) =>
                    !props.data.options?.some((val) => {
                      return val.value === each.value;
                    })
                );
                props.selectProps.onChange(filteredValues);
              }
            }
          }}
          // label={props.data.label}
        />
        <button
          onClick={() => {
            if (
              !props.data.options.every((option) =>
                props.selectProps.value
                  ?.map((each) => each.value)
                  .includes(option.value)
              )
            ) {
              const newValues = props.data.options.filter(
                (each) =>
                  !props.selectProps.value?.some(
                    (val) => val.value === each.value
                  )
              );
              props.selectProps.onChange(
                (props.selectProps.value || []).concat(newValues)
              );
            } else {
              if (props.selectProps.value) {
                const filteredValues = props.selectProps.value.filter(
                  (each) =>
                    !props.data.options?.some((val) => {
                      return val.value === each.value;
                    })
                );
                props.selectProps.onChange(filteredValues);
              }
            }
          }}
        >
          {props.data.label}
        </button>
      </div>
    );
  }
  return (
    <div
      className={`pl-11 text-pot-text-grey-2 font-bold text-xs my-1 ${
        props.big &&
        Number(props.id.split("-")[4]) &&
        Number(props.id.split("-")[4]) !== 1 &&
        "border-t pt-5"
      } ${props.big && Number(props.id.split("-")[4]) === 4 && "mt-40"} `}
      {...props}
    >
      {props.data.label}
    </div>
  );
};

const SelectInput = ({
  heading,
  keyValue,
  allFilters,
  setAllFilters,
  z,
  options,
  placeholder,
  groupSelect = false,
  columnVise = false,
  long = false,
  showInfo = false,
  dontCleanState = false,
  bold = false,
  closeMenuOnSelect = false,
  onPaste = () => {},
  validateInputForPasteClear = false,
  customSearch = () => {},
  rightButton = <></>,
  defaultSearch = true,
}) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const allRef = useRef();
  useOnClickOutside(allRef, () => setOpenMenu(false));

  useEffect(() => {
    customSearch(searchVal);
  }, [searchVal]);

  return (
    <div
      ref={allRef}
      onClick={() => setOpenMenu((prev) => !prev)}
      onPaste={() => {
        setOpenMenu(false);
        onPaste();
      }}
      className={z}
    >
      <div className="w-full relative flex ">
        <p
          // onMouseEnter={() => setShowToolTip(true)}
          // onMouseLeave={() => setShowToolTip(false)}
          className={`${
            bold ? "font-semibold" : "font-medium"
          } w-fit whitespace-nowrap mb-1.5 flex`}
        >
          {heading}
          {rightButton}
        </p>
        {showInfo && (
          <div className="z-50 w-full mt-0.5 ml-2 relative">
            <div
              onMouseEnter={() => setShowToolTip(true)}
              onMouseLeave={() => setShowToolTip(false)}
            >
              <InfoIcon size="small" primaryColor="grey" />
            </div>
            <AnimatePresence>
              {showToolTip && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="z-50 absolute -top-0 w-fit rounded text-xs text-white left-5"
                >
                  <p className="bg-pot-dark-blue px-2 py-1 rounded">
                    {showInfo}
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
      </div>
      <CheckboxSelect
        filterOption={defaultSearch ? undefined : () => true}
        menuIsOpen={openMenu}
        styles={{
          // control: (base, state) => ({
          // ...base,
          // boxShadow: 'none',
          // border: 0,
          // outline: state.isFocused ? '2px solid #0097A7' : 0,
          //})
          menu: (base, state) =>
            columnVise
              ? {
                  ...base,
                  width: "fit-content",
                  height: "fit-content",
                  padding: "20px 2px",
                  // height: "70vh",
                  // overflowY: "scroll",
                  right: 0,
                }
              : long
              ? {
                  ...base,
                  height: "65vh",
                }
              : { ...base },
          menuList: (base, state) =>
            columnVise
              ? {
                  // height: "65vh",
                  // overflowY: "auto",
                  columnCount: 3,
                  columnGap: 2,
                  columnRule: "2px solid #F3F3F3",
                  "@media only screen and (min-width: 1550px)": {
                    columnCount: 4,
                    columnGap: 2,
                    columnRule: "2px solid #F3F3F3",
                  },
                }
              : long
              ? {
                  height: "65vh",
                  overflowY: "auto",
                }
              : { ...base },
          // option: (base, state) => columnVise ? ({
          //   ...base,
          //   // height: "65vh",
          //   // overflowY: "scroll",
          //   color: "red",
          //   columnCount: 3,
          // columnGap: 10,
          // columnRule: "2px solid #F3F3F3"
          // }) : ({...base}),
        }}
        value={
          allFilters.find((filter) => Object.keys(filter)[0] === keyValue)?.[
            keyValue
          ] || null
        }
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti
        inputValue={searchVal}
        onInputChange={(value) => {
          if (validateInputForPasteClear && value.length > searchVal + 1) {
            // if(value.length > 20) {
            //   console.log(value.slice(0,20) + " ...")
            //   setSearchVal(value.slice(0, 20) + " ...")
            // } else {
            //   setSearchVal(value)
            // }
            return;
          }
          setSearchVal(value);
        }}
        placeholder={placeholder || " "}
        onChange={(value) => {
          const filteredValues = allFilters.filter(
            (filter) => Object.keys(filter)[0] !== keyValue
          );
          if (value.length) {
            setAllFilters([{ [keyValue]: value }, ...filteredValues]);
          } else {
            if (dontCleanState) {
              setAllFilters([...filteredValues, { names: [] }]);
            } else {
              setAllFilters(filteredValues);
            }
          }
        }}
        options={options}
        components={{
          MultiValueContainer: () => <></>,
          ValueContainer,
          // MenuList: ({ ...props }) => <MenuList big={columnVise} />,
          ClearIndicator: () => <></>,
          GroupHeading: ({ ...props }) => (
            <GroupHeading
              big={columnVise}
              groupSelect={groupSelect}
              {...props}
            />
          ),
          // Menu: ({ ...props }) => (
          //   <Menu columnVise={columnVise} {...props} />
          // ),
          // MenuList: ({ ...props }) => (
          //   <MenuList columnVise={columnVise} {...props} />
          // ),
        }}
      />
    </div>
  );
};

export default SelectInput;
