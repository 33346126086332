import React from "react";

const FaqIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M290 4924 c-30 -8 -73 -26 -96 -39 -61 -36 -139 -126 -166 -193 l-23
-57 -3 -1369 c-2 -1210 0 -1376 13 -1430 29 -111 107 -205 214 -255 52 -24 67
-26 224 -29 l167 -3 0 -317 c0 -296 1 -318 20 -356 11 -23 32 -50 47 -60 35
-26 122 -33 160 -14 15 8 208 153 428 322 l400 309 6 -189 c6 -204 14 -243 66
-314 35 -47 75 -81 133 -111 l45 -24 849 -3 849 -2 361 -278 c438 -337 414
-320 464 -328 55 -9 123 24 154 74 22 36 23 47 26 284 l3 246 122 4 c105 3
129 6 172 27 66 33 131 100 163 169 l27 57 0 1125 0 1125 -22 48 c-31 66 -105
142 -172 174 l-56 28 -312 3 -312 3 -3 547 -3 547 -33 67 c-41 84 -128 167
-209 200 l-58 23 -1795 2 c-1557 2 -1802 0 -1850 -13z m3605 -329 l25 -24 0
-1329 c0 -1252 -1 -1331 -18 -1351 l-17 -21 -1080 -2 c-904 -3 -1084 -5 -1105
-17 -13 -8 -190 -141 -392 -297 l-368 -283 0 231 c0 214 -2 235 -21 274 -12
26 -36 53 -57 66 -35 22 -49 23 -275 28 -222 5 -240 6 -253 24 -12 17 -14 218
-14 1348 l0 1329 25 24 24 25 1751 0 1751 0 24 -25z m900 -2425 l0 -1055 -185
-5 c-203 -5 -229 -12 -274 -73 -19 -26 -22 -46 -26 -198 l-5 -170 -272 209
c-150 115 -280 214 -290 218 -10 5 -405 11 -878 14 l-860 5 0 215 0 215 975 5
975 5 56 26 c103 49 181 140 213 248 14 48 16 142 16 728 l0 673 278 -2 277
-3 0 -1055z"
        />
        <path
          d="M1984 4215 c-218 -47 -374 -249 -374 -483 0 -120 50 -177 157 -177
101 0 146 49 162 174 11 90 34 130 91 159 81 42 198 12 257 -64 34 -45 44
-143 20 -191 -9 -16 -66 -76 -127 -133 -132 -123 -190 -194 -235 -293 -41 -89
-55 -156 -55 -255 0 -59 5 -85 20 -110 60 -99 213 -97 272 3 9 17 22 68 27
112 13 123 35 158 189 308 200 195 242 272 242 443 0 154 -49 272 -154 370
-133 124 -317 175 -492 137z"
        />
        <path
          d="M1977 2506 c-71 -26 -107 -76 -107 -146 0 -97 64 -160 163 -160 70 0
108 18 142 67 50 74 26 180 -50 224 -43 26 -103 31 -148 15z"
        />
      </g>
      {/* <path
        d="M15.867 16.2603L14.4701 15.1428L14.4598 15.1349C14.1686 14.9019 14.0216 14.7843 13.8576 14.7005C13.7105 14.6254 13.5537 14.5707 13.3918 14.5375C13.2095 14.5 13.0195 14.5 12.6381 14.5H4.6805C3.65574 14.5 3.14277 14.5 2.75098 14.3004C2.40602 14.1246 2.12539 13.8439 1.94963 13.4989C1.75 13.1071 1.75 12.5947 1.75 11.57V4.59719C1.75 3.57243 1.75 3.05929 1.94963 2.6675C2.12539 2.32254 2.40659 2.04228 2.75155 1.86651C3.14373 1.66669 3.65639 1.66669 4.68315 1.66669H15.3165C16.3433 1.66669 16.857 1.66669 17.2492 1.86651C17.5942 2.04228 17.8744 2.32254 18.0502 2.6675C18.25 3.05967 18.25 3.57344 18.25 4.6002V15.1153C18.25 16.0922 18.25 16.5805 18.0497 16.8314C17.8756 17.0496 17.6116 17.1765 17.3324 17.1762C17.0114 17.1758 16.6298 16.8706 15.867 16.2603Z"
        stroke="#1767A5"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.15 9C9.15 8.78667 9.20333 8.58333 9.31 8.39C9.42333 8.19 9.55667 8.01667 9.71 7.87C9.87 7.71667 10.0267 7.57 10.18 7.43C10.34 7.29 10.4733 7.14 10.58 6.98C10.6933 6.82 10.75 6.66 10.75 6.5C10.75 6.11333 10.5033 5.92 10.01 5.92C9.53 5.92 9.17 6.2 8.93 6.76L7.55 5.97C7.77 5.46333 8.10333 5.07 8.55 4.79C9.00333 4.50333 9.51 4.36 10.07 4.36C10.6833 4.36 11.2167 4.54 11.67 4.9C12.1233 5.26 12.35 5.74667 12.35 6.36C12.35 6.63333 12.2933 6.89 12.18 7.13C12.0733 7.37 11.94 7.57333 11.78 7.74C11.6267 7.90667 11.47 8.06 11.31 8.2C11.1567 8.34 11.0233 8.47667 10.91 8.61C10.8033 8.74333 10.75 8.87333 10.75 9H9.15ZM10.62 11.36C10.4333 11.5467 10.21 11.64 9.95 11.64C9.69 11.64 9.46667 11.5467 9.28 11.36C9.09333 11.1733 9 10.95 9 10.69C9 10.43 9.09333 10.2067 9.28 10.02C9.46667 9.83333 9.69 9.74 9.95 9.74C10.21 9.74 10.4333 9.83333 10.62 10.02C10.8067 10.2067 10.9 10.43 10.9 10.69C10.9 10.95 10.8067 11.1733 10.62 11.36Z"
        fill="#1767A5"
      /> */}
    </svg>
  );
};

export default FaqIcon;
