import React from 'react'
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import { subsLess } from "../../utils/helperFunc";

const Viewers = ({ detail }) => {
    const styles = StyleSheet.create({
        container: {
            width: "70%",
            marginLeft: "10px",
            borderRadius: "8px",
            border: 1,
            borderColor: "#F0F1F8",
            display: "flex",
            justifyContent: "space-around",
            paddingVertical: "5px",
            paddingHorizontal: "8px",
        },
        title: {
            fontWeight: "semibold",
        },
        value: {
            fontSize: "11px",
            fontWeight: "bold",
            color: "#1767A5",
        }
    })
  return (
    <View style={styles.container}>
        <Text style={styles.title}>
            {/* Removed this -> , "(M)" */}
            {["Free Monthly","Users/Viewers"].join("\n")}
        </Text>
        <Text style={styles.value}>
            {String(detail.monthly_viewers_count?.existing.toFixed(2)) === "0.00"
                ? "0"
                : subsLess(detail.monthly_viewers_count?.existing)}
              {typeof detail.monthly_viewers_count?.existing !== "string" && "M"}

            {/* {detail.monthly_viewers_count?.existing} */}
        </Text>
    </View>
  )
}

export default Viewers