import React, { useEffect, useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { getChart } from "../../api/dashboard";
import { titleAtom } from "../../atom";
import BusinessModelComparisonChart from "./BusinessModelComparisonChart";
import MarketStateChart from "./MarketStateChart";
import Table from "./Table";
import TopsChart from "./TopsChart";
import TrendingChart from "./TrendingChart";
import VideoReachChart from "./VideoReachChart";
import { titles } from "../../utils/Titles";
import { getFromCache, setToCache } from "../../utils/cacheStorage";

const Dashboard = () => {
  const setTitle = useSetRecoilState(titleAtom);
  const marketViewRef = useRef();
  const marketLeadersRef = useRef();
  const platformPresenceRef = useRef();
  const [stateOfMarket, setStateOfMarket] = useState(null);
  const [businessModelComparison, setBusinessModelComparison] = useState(null);
  const [reach, setReach] = useState(null);
  const [svod, setSvod] = useState(null);
  // const [vMvpd, setVMvpd] = useState(null);
  const [avod, setAvod] = useState(null);
  const [fastPlatforms, setFastPlatforms] = useState(null);
  const [fastChannels, setFastChannels] = useState(null);
  const [tvod, setTvod] = useState(null);
  const [distPlats, setDistPlats] = useState(null);
  const [devicePlats, setDevicePlats] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [trending, setTrending] = useState(null);
  const containerRef = useRef();
  const [active, setActive] = useState(1);
  const keyToSetter = [
    {
      id: "STATE_OF_THE_MARKET",
      setter: setStateOfMarket,
    },
    {
      id: "BUSINESS_MODEL_COMPARISON",
      setter: setBusinessModelComparison,
    },
    {
      id: "VIDEO_VIEWER_REACH",
      setter: setReach,
    },
    {
      id: "TOP_SERVICES_BY_USER_REACH_TABLE",
      setter: setTableData,
    },
    {
      id: "TOP_SERVICES_BY_USER_REACH_TRENDING",
      setter: setTrending,
    },
    {
      id: "TOP_SVOD_SERVICES",
      setter: setSvod,
    },
    {
      id: "TOP_AVOD_SERVICES",
      setter: setAvod,
    },
    {
      id: "TOP_FAST_PLATFORM",
      setter: setFastPlatforms,
    },
    {
      id: "TOP_FAST_CHANNEL",
      setter: setFastChannels,
    },
    {
      id: "TOP_TVOD_SERVICES",
      setter: setTvod,
    },
    {
      id: "TOP_DISTRIBUTION_PLATFORMS",
      setter: setDistPlats,
    },
    {
      id: "TOP_DEVICE_PLATFORMS",
      setter: setDevicePlats,
    },
  ];

  const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const fetchChartValue = async (id, setter) => {
    const params = { id };
    const { data: response } = await getChart({ params });
    const data = response.data;
    if (id === "STATE_OF_THE_MARKET") {
      const usData = data.US.map((each) => ({ ...each, closed: -each.closed }));
      const canadaData = data.CANADA.map((each) => ({
        ...each,
        closed: -each.closed,
      }));
      const x = {
        US: usData.sort((a, b) => a.x_axis - b.x_axis),
        CANADA: canadaData.sort((a, b) => a.x_axis - b.x_axis),
      };
      setter(x);
      await setToCache("Chart", id, x);
    } else if (id === "BUSINESS_MODEL_COMPARISON") {
      const x = {
        US: data.US.sort((a, b) => {
          if (a.count < b.count) {
            return -1;
          }
          if (a.count > b.count) {
            return 1;
          }
          return 0;
        }),
        CANADA: data.CANADA.sort((a, b) => {
          if (a.count < b.count) {
            return -1;
          }
          if (a.count > b.count) {
            return 1;
          }
          return 0;
        }),
      };
      setter(x);
      await setToCache("Chart", id, x);
    } else {
      setter(response.data);
      await setToCache("Chart", id, response.data);
    }
  };

  const fetchChartValueFromCache = async (id, setter) => {
    const data = await getFromCache(id);
    if (data) {
      setter(data);
    }
  };

  const fetchAllFromCache = async () => {
    // await delay(800)
    keyToSetter.map((each) => {
      fetchChartValueFromCache(each.id, each.setter);
    });
  };

  // const fetchChartValueTemp = async ({ queryKey }) => {
  //   const [ id ] = queryKey
  //   const params = { id };
  //   const { data: response } = await getChart({ params });
  //   const data = response.data;
  //   if (id === "STATE_OF_THE_MARKET") {
  //     const usData = data.US.map((each) => ({ ...each, closed: -each.closed }));
  //     const canadaData = data.CANADA.map((each) => ({
  //       ...each,
  //       closed: -each.closed,
  //     }));
  //     return({
  //       US: usData.sort((a, b) => a.x_axis - b.x_axis),
  //       CANADA: canadaData.sort((a, b) => a.x_axis - b.x_axis),
  //     });
  //   } else if(id === "BUSINESS_MODEL_COMPARISON") {
  //     return({
  //       US: data.US.sort((a, b) => {
  //         if ( a.count < b.count ){
  //           return -1;
  //         }
  //         if ( a.count > b.count ){
  //           return 1;
  //         }
  //         return 0;
  //       }),
  //       CANADA: data.CANADA.sort((a, b) => {
  //         if ( a.count < b.count ){
  //           return -1;
  //         }
  //         if ( a.count > b.count ){
  //           return 1;
  //         }
  //         return 0;
  //       }),
  //     });
  //   } else {
  //     // if(id.includes("DISTRI") || id.includes("DEVICE")) {
  //     //   console.log(response.data, id , "checker")
  //     // }
  //     return(response.data);
  //   }
  // };

  // const { data: stateOfMarket } = useQuery({
  //   queryKey: ["STATE_OF_THE_MARKET"],
  //   queryFn: fetchChartValueTemp,
  //   cacheTime: (1000 * 60 * 60 * 24)
  // })

  // const { data: businessModelComparison } = useQuery({
  //   queryKey: ["BUSINESS_MODEL_COMPARISON"],
  //   queryFn: fetchChartValueTemp,
  //   cacheTime: (1000 * 60 * 60 * 24)
  // })

  // const { data: reach } = useQuery({
  //   queryKey: ["VIDEO_VIEWER_REACH"],
  //   queryFn: fetchChartValueTemp,
  //   cacheTime: (1000 * 60 * 60 * 24)
  // })

  // const { data: tableData } = useQuery({
  //   queryKey: ["TOP_SERVICES_BY_USER_REACH_TABLE"],
  //   queryFn: fetchChartValueTemp,
  //   cacheTime: (1000 * 60 * 60 * 24)
  // })

  // const { data: trending } = useQuery({
  //   queryKey: ["TOP_SERVICES_BY_USER_REACH_TRENDING"],
  //   queryFn: fetchChartValueTemp,
  //   cacheTime: (1000 * 60 * 60 * 24)
  // })

  // const { data: svod } = useQuery({
  //   queryKey: ["TOP_SVOD_SERVICES"],
  //   queryFn: fetchChartValueTemp,
  //   cacheTime: (1000 * 60 * 60 * 24)
  // })

  // const { data: avod } = useQuery({
  //   queryKey: ["TOP_AVOD_SERVICES"],
  //   queryFn: fetchChartValueTemp,
  //   cacheTime: (1000 * 60 * 60 * 24)
  // })

  // const { data: fast } = useQuery({
  //   queryKey: ["TOP_FAST_SERVICES"],
  //   queryFn: fetchChartValueTemp,
  //   cacheTime: (1000 * 60 * 60 * 24)
  // })

  // const { data: tvod } = useQuery({
  //   queryKey: ["TOP_TVOD_SERVICES"],
  //   queryFn: fetchChartValueTemp,
  //   cacheTime: (1000 * 60 * 60 * 24)
  // })

  // const { data: distPlats } = useQuery({
  //   queryKey: ["TOP_DISTRIBUTION_PLATFORMS"],
  //   queryFn: fetchChartValueTemp,
  //   cacheTime: (1000 * 60 * 60 * 24)
  // })

  // const { data: devicePlats } = useQuery({
  //   queryKey: ["TOP_DEVICE_PLATFORMS"],
  //   queryFn: fetchChartValueTemp,
  //   cacheTime: (1000 * 60 * 60 * 24)
  // })

  useEffect(() => {
    setTitle("Dashboard");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
  }, []);

  useEffect(() => {
    keyToSetter.map((each) => {
      fetchChartValue(each.id, each.setter);
    });
    // fetchChartValue("STATE_OF_THE_MARKET", setStateOfMarket);
    // fetchChartValue("BUSINESS_MODEL_COMPARISON", setBusinessModelComparison);
    // fetchChartValue("VIDEO_VIEWER_REACH", setReach);
    // fetchChartValue("TOP_SERVICES_BY_USER_REACH_TABLE", setTableData);
    // fetchChartValue("TOP_SERVICES_BY_USER_REACH_TRENDING", setTrending);
    // fetchChartValue("TOP_SVOD_SERVICES", setSvod);
    // // fetchChartValue("TOP_VMVPD_SERVICES", setVMvpd)
    // fetchChartValue("TOP_AVOD_SERVICES", setAvod);
    // fetchChartValue("TOP_FAST_SERVICES", setFast);
    // fetchChartValue("TOP_TVOD_SERVICES", setTvod);
    // fetchChartValue("TOP_DISTRIBUTION_PLATFORMS", setDistPlats);
    // fetchChartValue("TOP_DEVICE_PLATFORMS", setDevicePlats);
  }, []);

  useEffect(() => {
    fetchAllFromCache();
  }, []);

  const handleScrollToSection = (ref, offset) => {
    var elementPosition = ref.current.getBoundingClientRect().top;
    var offsetPosition =
      elementPosition + containerRef.current.scrollTop + Number(offset);
    containerRef.current.scrollTo({ behavior: "smooth", top: offsetPosition });
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (
      scrollTop >
      platformPresenceRef.current.getBoundingClientRect().top +
        containerRef.current.scrollTop -
        120
    ) {
      setActive(3);
    } else if (
      scrollTop >
      marketLeadersRef.current.getBoundingClientRect().top +
        containerRef.current.scrollTop -
        120
    ) {
      setActive(2);
    } else {
      setActive(1);
    }
  };

  return (
    <div
      ref={containerRef}
      onScroll={handleScroll}
      className="h-[94vh] overflow-x-auto"
    >
      <div className="z-50 sticky top-0">
        <div className="bg-white/90 flex mb-10">
          <button
            onClick={() => handleScrollToSection(marketViewRef, -162)}
            className={`min-w-max border-b-[3px] ${
              active === 1 ? "border-[#8BB3D2]" : "text-pot-grey-3"
            } py-5 px-6 -mb-0.5 font-semibold`}
          >
            Streaming Video Services Market Overview
          </button>
          <button
            onClick={() => handleScrollToSection(marketLeadersRef, -92)}
            className={`min-w-max border-b-[3px] ${
              active === 2 ? "border-[#8BB3D2]" : "text-pot-grey-3"
            } py-5 px-6 -mb-0.5 font-semibold`}
          >
            Market Leaders
          </button>
          <button
            onClick={() => handleScrollToSection(platformPresenceRef, -70)}
            className={`min-w-max border-b-[3px] ${
              active === 3 ? "border-[#8BB3D2]" : "text-pot-grey-3"
            } py-5 px-6 -mb-0.5 font-semibold`}
          >
            Platform Presence & Support
          </button>
          <div className="w-full border-b-[3px] -mb-0.5"></div>
        </div>
      </div>
      <div className="px-10 pt-2 pb-10">
        <section ref={marketViewRef}>
          <p className="font-semibold mb-6 text-xl">
            Streaming Video Services Market Overview
          </p>
          <div className="xl:flex gap-6">
            <MarketStateChart stateOfMarket={stateOfMarket} />
            <div className="xl:hidden block h-10"></div>
            <BusinessModelComparisonChart
              data={businessModelComparison}
              label={"# of OTT Video Services"}
            />
          </div>
          <div className="xl:flex gap-6 mt-10">
            <VideoReachChart data={reach} />
            {/* <section className="w-[60%] h-full"></section> */}
          </div>
        </section>
        <section ref={marketLeadersRef} className="">
          <p className="font-semibold my-6 text-xl pt-10">Market Leaders</p>
          <div className="xl:flex gap-6 w-full">
            <Table data={tableData} />
            <div className="xl:hidden block h-10"></div>
            <TrendingChart data={trending} />
          </div>
          <div className="xl:flex gap-6 mt-10">
            <div className="w-full xl:w-1/2">
              <TopsChart
                text="Top 10 SVOD Services"
                data={svod}
                label="Subscribers (M)"
                formatDecimal
              />
            </div>
            <div className="mt-10 xl:mt-0 w-full xl:w-1/2">
              <TopsChart
                text="Top 10 AVOD Services"
                data={avod}
                label="Monthly Active Viewers (M)"
                formatDecimal
              />
            </div>
            {/* <div className="w-full h-full"></div> */}
          </div>
          <div className="xl:flex gap-6 mt-10">
            <div className="w-full xl:w-1/2">
              <TopsChart
                text="Top 10 FAST Platforms"
                data={fastPlatforms}
                label="Monthly Active Viewers (M)"
                formatDecimal
              />
            </div>
            <div className="mt-10 xl:mt-0 w-full xl:w-1/2">
              <TopsChart
                text="Top 10 FAST Channels"
                data={fastChannels}
                label="Monthly Active Viewers (M)"
                formatDecimal
              />
            </div>
            {/* <div className="w-full h-full"></div> */}
          </div>
          <div className="flex justify-center gap-6 mt-10">
            <div className="w-full xl:w-[50%]">
              <TopsChart
                text="Top 10 TVOD Services"
                data={tvod}
                label="Monthly Transactional Customers (M)"
                formatDecimal
              />
            </div>
          </div>
        </section>
        <section ref={platformPresenceRef} className="pt-10 h-[86vh]">
          <p className="font-semibold my-6 text-xl">
            Platform Presence & Support
          </p>
          <div className="xl:flex gap-6">
            <div className="w-full xl:w-1/2">
              <TopsChart
                text="% of All Services Available Through Distribution Platform"
                mainData={distPlats}
                label="% of All Services Available Through Platform"
                distribution
                isPercentVal
                isRoundVal
              />
            </div>
            {/* <TopsChart text="Top 10 SVOD services" data={svod} label="Subscribers" /> */}
            {/* <DevicePlatChart data={devicePlats} /> */}
            <div className="mt-10 xl:mt-0 w-full xl:w-1/2">
              <TopsChart
                text="% of All Services Supporting Device Types"
                mainData={devicePlats}
                label="% of All Services Supporting Device Types"
                devices
                isPercentVal
                isRoundVal
              />
            </div>
            {/* <div className="w-full h-full"></div> */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
