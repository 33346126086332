import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";

const Header = ({ image, title }) => {
    const style = StyleSheet.create({
        header: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingHorizontal: "8px",
            paddingVertical: "5px",
            borderBottom: 1,
            borderBottomColor: "#F0F1F8",
          },
          title: {
            display: "flex",
            flexDirection: "row",
          },
          title_text: {
            color: "#1767A5",
            fontSize: "11px",
            fontWeight: "bold",
            marginLeft: "5px",
          },
          title_img: {
            height: "12px",
          },
    })

  return (
    <View style={style.header}>
      <View wrap={false} style={style.title}>
        <Image src={image} style={style.title_img} />
        <Text style={style.title_text}>{ title }</Text>
      </View>
    </View>
  );
};

export default Header;
