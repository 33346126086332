import React from "react";
import { Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";
import Header from "../../components/Pdf/Header";
import pricing from "../../assets/pdf_png/pricing.png";

const Pricing = ({ detail }) => {
  const styles = StyleSheet.create({
    container: {
      width: "100%",
      marginRight: "10px",
      borderRadius: "8px",
      border: 1,
      borderColor: "#F0F1F8",
      marginTop: "10px",
    },
    text: {
      paddingVertical: "5px",
      paddingHorizontal: "8px",
    },
  });

  const totalLength = String(detail.pricing_and_promotions?.existing).split(`\n`).length

  // const hyphenationCallback = (word) => {
  //   return word
  // }
  
  // Font.registerHyphenationCallback(hyphenationCallback);

  return (
    <View 
    wrap={totalLength > 60} 
    style={styles.container}>
      <Header title="Pricing & Promotions" image={pricing} />
      <View style={styles.text}>
      {/* {String(detail.pricing_and_promotions?.existing).split(`   `).map((each) => (
          <Text>{each.trim()}</Text>
        ))} */}
        <Text>
          {String(detail.pricing_and_promotions?.existing).split("")}
        </Text>
      </View>
    </View>
  );
};

export default Pricing;
