import React from "react";

const World = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10C2.5 11.9891 3.29018 13.8968 4.6967 15.3033C6.10322 16.7098 8.01088 17.5 10 17.5ZM9.25 15.9475C7.80026 15.7667 6.46655 15.0624 5.49971 13.9671C4.53288 12.8718 3.99953 11.461 4 10C4 9.535 4.06 9.0925 4.1575 8.6575L7.75 12.25V13C7.75 13.825 8.425 14.5 9.25 14.5V15.9475ZM14.425 14.0425C14.3298 13.7394 14.1402 13.4747 13.8838 13.2871C13.6274 13.0995 13.3177 12.9989 13 13H12.25V10.75C12.25 10.3375 11.9125 10 11.5 10H7V8.5H8.5C8.9125 8.5 9.25 8.1625 9.25 7.75V6.25H10.75C11.575 6.25 12.25 5.575 12.25 4.75V4.4425C14.4475 5.335 16 7.4875 16 10C16 11.56 15.4 12.9775 14.425 14.0425Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default World;
