import React from "react";
import InputField from "./components/InputField";
import PhoneField from "./components/PhoneField";
import { motion } from "framer-motion";
import { MacScrollbar } from "mac-scrollbar";
import { useRecoilValue } from "recoil";
import { AppInfoAtom, CountryCodesAtom } from "../atom";
import { DotPulse } from "@uiball/loaders";

const OrganizationForm = ({
  isEdit,
  formData,
  setFormData,
  onSubmit,
  error,
  setError,
  loading=false,
}) => {
  const country_codes = useRecoilValue(CountryCodesAtom)

  return (
    <MacScrollbar>
      <section className="pb-8">
        <InputField
          heading="Name"
          className="mt-8"
          formData={formData}
          setFormData={setFormData}
          value="name"
          placeholder="Name of Organization"
          error={error}
          setError={setError}
          required
        />
        <PhoneField
          heading="Phone Number"
          placeholder={"000-000-0000"}
          formData={formData}
          setFormData={setFormData}
          value="phone"
          className="mt-8"
          error={error}
          setError={setError}
          country_codes={country_codes}
        />
        <InputField
          heading="Email ID"
          className="mt-8"
          formData={formData}
          setFormData={setFormData}
          value="email"
          placeholder="jack@example.com"
          error={error}
          setError={setError}
        />
        <div className="w-full mt-[20vh] flex justify-center">
          <motion.button
            onClick={onSubmit}
            whileHover={{ scale: 1.1 }}
            className="bg-pot-blue px-4 py-2 flex gap-x-2 items-center text-white text-sm font semibold rounded-md"
          >
            {loading &&  (
              <DotPulse size={12} speed={0.8} color="white" />
            )}
            {isEdit ? "Save" : "Create"}
          </motion.button>
        </div>
      </section>
    </MacScrollbar>
  );
};

export default OrganizationForm;
