import React from "react";
import BigRetry from "../../assets/BigRetry";
import { AnimatePresence, motion } from "framer-motion";

const Error = ({ goBack }) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.3 }}
        className="z-[90] top-0 left-0 w-full h-[90vh] flex items-center justify-center"
      >
        <div className="flex flex-col items-center justify-center gap-y-[3vh] pr-10">
          <BigRetry />
          <p className="font-semibold mt-5">Data Upload failed</p>
          <p className="text-sm text-pot-text-grey-2 w-[40vw] text-center">
            The subscriber excel couldn't be imported. Please verify contents of
            your file.
          </p>
          <button
            onClick={goBack}
            className="bg-pot-dark-blue rounded-md px-4 py-2 text-white"
          >
            Go Back
          </button>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Error;
