import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AllFilterOptionsAtom } from "../atom";
import { useRecoilValue } from "recoil";

function useFilteredNames() {
  const allFilterOptions = useRecoilValue(AllFilterOptionsAtom); // default options for the dropdown
  const [filteredNames, setFilteredNames] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  // Initially keep the dropdown state as allFilterOptions.names
  useEffect(() => {
    setFilteredNames(allFilterOptions.names);
  }, [allFilterOptions]);

  // Call the customSearch function whenever the searchVal changes/ updates
  useEffect(() => {
    customSearch(searchVal);
  }, [searchVal]);

  // Function to search from the dropdowns
  function customSearch(input) {
    let options = allFilterOptions.names;
    const normalizedInput = input.toLowerCase();
    // If search input is empty, re-set the dropdown options state to the initial options
    if (input === "") {
      setFilteredNames(allFilterOptions.names);
      return;
    }
    // Use filter to find options that start with the input
    const filteredOptions = options.map((option) => {
      const matchingItems = option.options.filter((item) => {
        const label = item.label.toLowerCase();
        if (normalizedInput.startsWith("ama") && label.includes("prime")) {
          //Note: This is hardcoded as per client's request. They wanted amazon to show prime video in suggestion
          return true;
        } else {
          return label.includes(normalizedInput);
        }
      });
      // If there are matching items, return the option with those items
      if (matchingItems.length > 0) {
        return {
          ...option,
          options: matchingItems,
        };
      }

      return null; // No matching items in this option
    });

    setFilteredNames(filteredOptions.filter(Boolean)); // Remove null values
  }

  return { filteredNames, searchVal, setSearchVal };
}

export default useFilteredNames;
