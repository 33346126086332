// import TextField from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Close from "../assets/Close";
import Plus from "../assets/Plus";
import { AllFilterOptionsAtom, SelectedProfileCountryAtom } from "../atom";
import CheckIcon from "@atlaskit/icon/glyph/check";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import company1 from "../assets/company1.png";
import RoundedFilledClose from "../assets/RoundedFilledClose";
import { formatNamesToObj } from "../utils/formatConfig";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  formatChurnValue,
  formatNpsValue,
  padStart,
} from "../utils/helperFunc";

const useCompareViewColDef = (
  comparedHeaders,
  setComparedHeaders,
  setComparedValues
) => {
  const initialFirstColDef = {
    header: () => <p className="text-center text-sm font-semibold">Features</p>,
    accessorKey: "features",
    enablePinning: true,
    size: 10,
    cell: ({ getValue }) => <p className="text-center text-sm">{getValue()}</p>,
  };

  const [colDef, setColDef] = useState([]);
  const [initial, setInitial] = useState(true);
  const allFilterOptions = useRecoilValue(AllFilterOptionsAtom);
  const [allNamesObj, setAllNamesObj] = useState(null);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);
  const keyNames = ["value1", "value2", "value3", "value4", "value5"];
  const width = ["w-[15.5vw]", "w-[19vw]", "w-[25.5vw]", "w-[39vw]"];
  const navigate = useNavigate();
  const [mouseEnteredHeader, setMouseEnteredHeader] = useState(null);
  const [inputVal, setInputVal] = useState("null");

  useEffect(() => {
    if (allFilterOptions.names) {
      const names = formatNamesToObj(allFilterOptions.names);
      setAllNamesObj({ ...names });
    }
  }, [allFilterOptions]);

  const getEachColDef = (value, index, namesNumber) => {
    let originalOptions = allFilterOptions.names;
    function customSearch(input) {
      originalOptions = allFilterOptions.names;
      const normalizedInput = input.toLowerCase();
      // If search input is empty, re-set the dropdown options state to the initial options
      if (input === "") {
        originalOptions = allFilterOptions.names;
        return;
      }
      // Use filter to find options that start with the input
      const filteredOptions = originalOptions.map((option) => {
        const matchingItems = option.options.filter((item) => {
          const label = item.label.toLowerCase();
          if (normalizedInput.startsWith("ama") && label.includes("prime")) {
            //Note: This is hardcoded as per client's request. They wanted amazon to show prime video in suggestion
            return true;
          } else {
            return label.includes(normalizedInput);
          }
        });
        // If there are matching items, return the option with those items
        if (matchingItems.length > 0) {
          return {
            ...option,
            options: matchingItems,
          };
        }

        return null; // No matching items in this option
      });

      originalOptions = filteredOptions.filter(Boolean);
    }

    const columnDef = {
      header: () => {
        return comparedHeaders[value] ? (
          <div
            className={`w-full flex justify-center font-popins text-lg font-semibold`}
          >
            <div className="flex items-center">
              <div className="flex items-center gap-x-3">
                {/* <div className="w-10 h-10 flex-shrink-0 rounded-full bg-white flex justify-center items-center object-contain"> */}
                {/* <img src={company1} className="object-contain h-8 w-8" /> */}
                {/* </div> */}
                {allNamesObj ? (
                  <div
                    onMouseEnter={() => {
                      setMouseEnteredHeader(value);
                    }}
                    onMouseLeave={() => setMouseEnteredHeader(null)}
                    className="w-full h-full relative"
                  >
                    <Link
                      to={`/profile/${allNamesObj[comparedHeaders[value]]}`}
                      className="underline cursor-pointer text-pot-dark-blue mr-3"
                    >
                      {comparedHeaders[value]}
                    </Link>
                    {mouseEnteredHeader === value && (
                      <div
                        onClick={() =>
                          navigate(
                            `/profile/${allNamesObj[comparedHeaders[value]]}`
                          )
                        }
                        className="left-[30%] cursor-pointer absolute bg-pot-dark-green text-xs px-2 py-1 rounded font-normal text-white"
                      >
                        view more
                      </div>
                    )}
                  </div>
                ) : (
                  <p className="text-pot-dark-blue mr-3">
                    {!!allNamesObj ? "true" : JSON.stringify(allNamesObj)}
                    {comparedHeaders[value]}
                  </p>
                )}
              </div>
              <button
                onClick={() => {
                  let settableComparedHeaders;
                  if (Object.keys(comparedHeaders).length > 2) {
                    settableComparedHeaders = Object.keys(
                      comparedHeaders
                    ).reduce((acc, cur, id) => {
                      if (cur === value) {
                        return acc;
                      }
                      const keyIndex = keyNames.indexOf(cur);
                      if (keyIndex > keyNames.indexOf(value)) {
                        acc[keyNames[keyIndex - 1]] = comparedHeaders[cur];
                      } else {
                        acc[cur] = comparedHeaders[cur];
                      }
                      return acc;
                    }, {});
                  } else {
                    settableComparedHeaders = {
                      ...comparedHeaders,
                      [value]: null,
                    };
                  }
                  const vals = keyNames.filter((val) => val >= value);
                  const obj = {};
                  vals.forEach((each) => (obj[each] = null));
                  setComparedValues((prev) => ({ ...prev, ...obj }));
                  setComparedHeaders(settableComparedHeaders);
                }}
              >
                <RoundedFilledClose />
              </button>
            </div>
          </div>
        ) : (
          <div
            className={`w-full flex justify-center items-center font-popins text-sm font-normal`}
          >
            <div
              className={
                5 - namesNumber > 2
                  ? "w-1/2 mr-2"
                  : 5 - namesNumber > 1
                  ? "w-3/4 mr-2"
                  : "w-full mr-2"
              }
            >
              <Select
                onChange={(e) => {
                  setComparedHeaders((prev) => ({
                    ...prev,
                    [keyNames[index]]: e.label,
                  }));
                }}
                onInputChange={(e) => {
                  setInputVal(e);
                  customSearch(e);
                }}
                options={originalOptions}
                placeholder="Choose Something"
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: "white",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#1767A5",
                  }),
                }}
                filterOption={() => true}
              />
            </div>
            {Object.keys(comparedHeaders).length > 2 ? (
              <button
                onClick={() => {
                  if (Object.keys(comparedHeaders).length > 2) {
                    const settableCompareHead = Object.keys(
                      comparedHeaders
                    ).reduce(
                      (acc, cur) =>
                        cur === value
                          ? acc
                          : keyNames.indexOf(cur) > keyNames.indexOf(value)
                          ? {
                              ...acc,
                              [keyNames[keyNames.indexOf(cur) - 1]]:
                                comparedHeaders[cur],
                            }
                          : { ...acc, [cur]: comparedHeaders[cur] },
                      {}
                    );
                    setComparedHeaders({ ...settableCompareHead });
                  }
                }}
              >
                <RoundedFilledClose />
              </button>
            ) : (
              <></>
            )}
          </div>
        );
      },

      accessorKey: keyNames[index],

      cell: ({ getValue, row }) => {
        return row.original.mainVal === "is_free_trial" ? (
          JSON.stringify(getValue()) === "null" ? (
            <div className="w-full flex justify-center">
              <CrossIcon primaryColor="#C34B4B" size="small" />
            </div>
          ) : (
            typeof getValue() === "boolean" && (
              <div className="w-full flex justify-center">
                {getValue() ? (
                  <CheckIcon primaryColor="#028D9B" size="small" />
                ) : (
                  <CrossIcon primaryColor="#C34B4B" size="small" />
                )}
              </div>
            )
          )
        ) : Array.isArray(getValue()) ? (
          <p className="w-full text-center text-sm">
            {getValue().length ? getValue().join("\n") : "-"}
          </p>
        ) : typeof getValue() === "boolean" ? (
          <div className="w-full flex justify-center">
            {getValue() ? (
              <CheckIcon primaryColor="#028D9B" size="small" />
            ) : (
              <CrossIcon primaryColor="#C34B4B" size="small" />
            )}
          </div>
        ) : row.original.mainVal === "pricing" ? (
          <p className="w-full text-center text-sm">
            {getValue() !== null && getValue() !== undefined
              ? typeof getValue() === "string"
                ? getValue()
                : `$ ${getValue()}`
              : row.original.id
              ? "-"
              : ""}
          </p>
        ) : row.original.mainVal === "launch_date" ? (
          <p className="w-full text-center text-sm">
            {getValue()
              ? moment(getValue(), "YYYY-MM-DD").format("MM-DD-YYYY")
              : ""}
          </p>
        ) : row.original.mainVal.includes("subscribers") ? (
          <p className="w-full text-center text-sm">
            {getValue()
              ? getValue() <= 0.009
                ? "< 0.01 M"
                : typeof getValue() === "string"
                ? getValue()
                : `${padStart(getValue()?.toFixed(2))} M`
              : row.original.id
              ? "-"
              : ""}
          </p>
        ) : row.original.mainVal.includes("nps") ? (
          <p className="w-full text-center text-sm">
            {getValue() === null
              ? "NA"
              : typeof getValue() === "string"
              ? getValue()
              : typeof getValue() === "undefined"
              ? ""
              : formatNpsValue(getValue())}
          </p>
        ) : row.original.mainVal.includes("churn") ? (
          <p className="w-full text-center text-sm">
            {getValue() === null
              ? "NA"
              : typeof getValue() === "string"
              ? getValue()
              : typeof getValue() === "undefined"
              ? ""
              : formatChurnValue(getValue())}
          </p>
        ) : (
          <p className="w-full text-center text-sm">
            {Number.isInteger(getValue())
              ? getValue()
              : getValue()
              ? getValue()
              : row.original.id
              ? "-"
              : " "}
          </p>
        );
      },
      // <p className="w-full text-center">{getValue()}</p>
    };
    return columnDef;
  };

  useEffect(() => {
    const colDefLength =
      Object.keys(comparedHeaders).length > 2
        ? Object.keys(comparedHeaders).length
        : 2;
    const colDefTemp = [{ ...initialFirstColDef }];
    Array.from({ length: colDefLength }).forEach((_, id) => {
      const eachColDef = getEachColDef(
        Object.keys(comparedHeaders)[id],
        id,
        Object.keys(comparedHeaders).length
      );
      colDefTemp.push(eachColDef);
    });
    if (colDefLength < 5) {
      colDefTemp.push({
        header: () => (
          <button
            onClick={() => {
              let newKeyName;
              setComparedHeaders((prev) => {
                newKeyName = keyNames[Object.keys(prev).length];
                return { ...prev, [newKeyName]: null };
              });
              setComparedValues((prev) => ({ ...prev, [newKeyName]: null }));
            }}
          >
            <Plus />
          </button>
        ),
        accessorKey: "nothing",
        size: 2,
      });
    }
    setColDef(colDefTemp);
  }, [comparedHeaders, allNamesObj, mouseEnteredHeader]);

  return colDef;
};

export default useCompareViewColDef;
