import React from "react";
import { flexRender } from "@tanstack/react-table";
import { motion } from "framer-motion";
import { useRecoilValue } from "recoil";
import { ResizingAtom, TableLoadingAtom } from "../../atom";
import { ClipLoader } from "react-spinners";
// import { MacScrollbar } from "mac-scrollbar";
import { DotPulse } from "@uiball/loaders";

const TableLayoutOne = ({
  table,
  alternateColor = false,
  moreBorder = false,
  small = false,
}) => {
  const screenWidth = window.innerWidth;
  const fetchLoading = useRecoilValue(TableLoadingAtom);
  const resizingLoading = useRecoilValue(ResizingAtom);
  return (
    <>
      <table
        {...{
          style:
            table.getTotalSize() > screenWidth
              ? {
                  width: table.getTotalSize(),
                }
              : {},
        }}
        className="w-full border-collapse rounded-t-xl overflow-auto"
      >
        <thead className="w-full sticky top-0 z-[40]">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  {...{
                    key: header.id,
                    colSpan: header.colSpan,
                    style: {
                      width: header.getSize(),
                    },
                  }}
                  className={`relative border-x border-pot-grey-2 bg-pot-back-grey ${
                    header.column.getIsPinned()
                      ? "sticky left-0 z-[40]"
                      : "relative"
                  }`}
                >
                  <p
                    className={`w-full h-full ${
                      moreBorder ? "px-2 py-4" : "p-2"
                    }`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </p>
                  {!header.column.getIsPinned() && (
                    <div
                      {...{
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `absolute top-0 right-0 h-full w-[2px] cursor-col-resize  ${
                          header.column.getIsResizing()
                            ? "bg-[#0052CC]"
                            : "bg-white"
                        }`,
                      }}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {fetchLoading || resizingLoading ? (
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                // className="even:bg-white odd:bg-slate-50"
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    {...{
                      key: cell.id,
                      style: {
                        width: cell.column.getSize(),
                      },
                    }}
                    // className="whitespace-pre-wrap"
                    className={`hidden`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        ) : (
          <motion.tbody
            key={table.getRowModel().rows?.[0]}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.8 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={
                  alternateColor
                    ? "odd:bg-white even:bg-pot-background-grey-2"
                    : ""
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    {...{
                      key: cell.id,
                      style: {
                        width: cell.column.getSize(),
                      },
                    }}
                    // className="whitespace-pre-wrap"
                    className={`p-3 bg-inherit whitespace-pre-wrap space-y-4 border-x text-xs border-b border-pot-grey-2 ${
                      cell.column.getIsPinned() ? "left-0 z-[20]" : "relative"
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </motion.tbody>
        )}
      </table>
      {(fetchLoading || resizingLoading) && (
        <div
          className={`w-full ${
            small ? "h-[55vh]" : "h-[69vh]"
          } flex justify-center items-center`}
        >
          {/* <ClipLoader color="#1767A5" /> */}
          <DotPulse size={40} color="#1767A5" speed={0.8} />
        </div>
      )}
    </>
  );
};

export default TableLayoutOne;
