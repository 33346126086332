import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { TelemetryApi } from '../api/telemetry'
import { useRecoilValue } from 'recoil'
import { SelectedProfileCountryAtom } from '../atom'

const useSearchTelemetry = () => {
  const [searchTelemetryId, setSearchTelemetryId] = useState("")
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom)

  const doTelemetry = async () => {
    const payload = { profile_searched: searchTelemetryId }
    const params = { profile_country: selectedProfileCountry }
    const { data: response } = await TelemetryApi.search(payload, { params })
    if(!response.success) {
        console.error(response.errors.error_message.join(", "))
    }
    setSearchTelemetryId("")
  }

  useEffect(() => {
    if(searchTelemetryId) {
        doTelemetry()
    }
  }, [searchTelemetryId])

  return (setSearchTelemetryId)
}

export default useSearchTelemetry