import React, { useEffect, useState } from "react";
import InfoIcon from "@atlaskit/icon/glyph/info";
import { AnimatePresence, motion } from "framer-motion";
import { Radio } from "@atlaskit/radio";
import Select from "@atlaskit/select";
import { toTitleCase } from "../utils/helperFunc";

const NonStandardChartsForm = ({
  clear,
  errors = {},
  setError,
  editData,
  formData,
  setFormData,
}) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const [selectedPage, setSelectedPage] = useState();

  useEffect(() => {
    if (clear) {
      setSelectedPage(null);
    }
  }, [clear]);

  useEffect(() => {
    if (editData) {
      setFormData((prev) => ({
        ...prev,
        name: editData?.name,
        y_axis_label: editData?.y_axis_label,
        footer_text: editData?.footer_text,
        value_type: editData?.value_type,
        page: editData?.page,
        position: editData?.position,
      }));
      editData?.page &&
        setSelectedPage({
          label:
            editData?.page === "CHURN"
              ? toTitleCase(editData?.page)
              : editData?.page,
          value: editData?.page,
        });
    } else {
      setFormData({});
      setSelectedPage(null);
    }
  }, [editData]);

  return (
    <div className="w-full flex flex-col gap-y-3">
      <div className="flex gap-6">
        <div className="w-full">
          <label>
            <span className="font-semibold">Name of the chart</span>
          </label>
          <input
            value={formData?.name || ""}
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                name: e.target.value,
              }));
              if (errors.name) {
                delete errors.name;
                setError({ ...errors });
              }
            }}
            placeholder="Name of the chart"
            className={`w-full mt-1 placeholder:text-pot-t-grey text-xs box-border outline-none focus:bg-white focus:border-pot-light-blue-2 bg-[#EBECF0]/60 hover:bg-[#DFE1E6]/60 border-2 border-[#EBECF0]/60 transition ease-in-out duration-200 flex justify-between p-2 rounded-sm`}
          />
          {errors?.name && (
            <p className="text-xs text-pot-red h-0">
              {errors?.name.join(", ")}
            </p>
          )}
        </div>
        <div className="w-full">
          <label>
            <span className="font-semibold">Y-Axis Label</span>
          </label>
          <input
            value={formData?.y_axis_label || ""}
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                y_axis_label: e.target.value,
              }));
              if (errors.y_axis_label) {
                delete errors.y_axis_label;
                setError({ ...errors });
              }
            }}
            placeholder="Label for Y-Axis"
            className={`w-full mt-1 placeholder:text-pot-t-grey text-xs box-border outline-none focus:bg-white focus:border-pot-light-blue-2 bg-[#EBECF0]/60 hover:bg-[#DFE1E6]/60 border-2 border-[#EBECF0]/60 transition ease-in-out duration-200 flex justify-between p-2 rounded-sm`}
          />
          {errors?.y_axis_label && (
            <p className="text-xs text-pot-red h-0">
              {errors?.y_axis_label.join(", ")}
            </p>
          )}
        </div>
      </div>

      <div>
        <label>
          <span className="font-semibold flex items-center gap-2">
            <span>Footer Text</span>
            <div
              onMouseEnter={() => setShowToolTip(true)}
              onMouseLeave={() => setShowToolTip(false)}
              className="flex items-center cursor-pointer"
            >
              <InfoIcon size="small" primaryColor="grey" />
            </div>
            <div className="relative">
              <AnimatePresence>
                {showToolTip && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="z-50 absolute -top-2 rounded text-xs left-0 w-48"
                  >
                    <p className="bg-pot-dark-blue px-2 py-1 rounded text-white font-normal text-justify">
                      Please make sure the footer text contains the copyright
                      text. Eg: © Parks Associates
                    </p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </span>
        </label>
        <textarea
          value={formData?.footer_text || ""}
          onChange={(e) => {
            setFormData((prev) => ({
              ...prev,
              footer_text: e.target.value,
            }));
            if (errors.footer_text) {
              delete errors.footer_text;
              setError({ ...errors });
            }
          }}
          rows={3}
          placeholder="Footer text of the chart"
          className={`w-full mt-1 placeholder:text-pot-t-grey text-xs box-border outline-none focus:bg-white focus:border-pot-light-blue-2 bg-[#EBECF0]/60 hover:bg-[#DFE1E6]/60 border-2 border-[#EBECF0]/60 transition ease-in-out duration-200 flex justify-between p-2 rounded-sm`}
        />
        {errors?.footer_text && (
          <p className="text-xs text-pot-red h-0">
            {errors?.footer_text.join(", ")}
          </p>
        )}
      </div>
      <div className="flex gap-6 w-full">
        <div className="flex flex-col justify-between">
          <label className="font-semibold">Value Type</label>
          <div className="flex items-center">
            <Radio
              isChecked={formData?.value_type === "NUMBER"}
              onClick={() => {
                let newValue =
                  formData?.value_type === "NUMBER" ? null : "NUMBER";
                setFormData((prev) => ({
                  ...prev,
                  value_type: newValue,
                }));
                if (errors.value_type) {
                  delete errors.value_type;
                  setError({ ...errors });
                }
              }}
              label={
                <span className="cursor-pointer font-semibold text-sm">
                  Number
                </span>
              }
              className="cursor-pointer self-center"
            />
            <Radio
              isChecked={formData?.value_type === "PERCENTAGE"}
              onClick={() => {
                let newValue =
                  formData?.value_type === "PERCENTAGE" ? null : "PERCENTAGE";
                setFormData((prev) => ({
                  ...prev,
                  value_type: newValue,
                }));
                if (errors.value_type) {
                  delete errors.value_type;
                  setError({ ...errors });
                }
              }}
              label={
                <span className="cursor-pointer font-semibold text-sm">
                  Percentage
                </span>
              }
              className="cursor-pointer self-center"
            />
          </div>
          <p className="text-xs text-pot-red h-0">
            {errors?.value_type?.join(", ")}
          </p>
        </div>
        <div className="w-full">
          <label className="font-semibold">Page</label>
          <Select
            classNamePrefix="react-select"
            options={[
              { label: "NPS", value: "NPS" },
              { label: "Churn", value: "CHURN" },
            ]}
            value={selectedPage}
            placeholder="Select Page"
            onChange={(e) => {
              setSelectedPage(e);
              setFormData((prev) => ({
                ...prev,
                page: e.value,
              }));
              if (errors.page) {
                delete errors.page;
                setError({ ...errors });
              }
            }}
            className="w-full z-50 cursor-pointer text-xs mt-1"
          />
          {errors?.page && (
            <p className="text-xs text-pot-red h-0">
              {errors?.page.join(", ")}
            </p>
          )}
        </div>
        <div className="w-full">
          <label>
            <span className="font-semibold">Position</span>
          </label>
          <input
            value={formData?.position || ""}
            type="number"
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                position: e.target.value,
              }));
              if (errors.position) {
                delete errors.position;
                setError({ ...errors });
              }
            }}
            placeholder="Position"
            className={`w-full mt-1 placeholder:text-pot-t-grey text-xs box-border outline-none focus:bg-white focus:border-pot-light-blue-2 bg-[#EBECF0]/60 hover:bg-[#DFE1E6]/60 border-2 border-[#EBECF0]/60 transition ease-in-out duration-200 flex justify-between p-2 rounded-sm`}
          />
          {errors?.position && (
            <p className="text-xs text-pot-red h-0">
              {errors?.position.join(", ")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NonStandardChartsForm;
