import { authenticatedAxiosInstance as axios } from "./axios";
import { normalAxiosInstance } from "./axios";

export const appInfo = () => axios.get(`/core/app-info/`);
export const contact = (payload) => axios.post(`/core/contact/`, payload);
export const faq = () => axios.get(`/core/faq/`);
export const unAuthContact = (payload) =>
  normalAxiosInstance.post(`/core/open-contact/ `, payload);
export const feedback = (payload) =>
  normalAxiosInstance.post(`/core/feedback/`, payload);
