import React from "react";

const NPSChurnIcon = ({ size = "20" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.40871 15.584H16.5829C18.3246 15.584 19.2412 14.6673 19.2412 12.9257V1.83398H2.74121V12.9257C2.75038 14.6673 3.66705 15.584 5.40871 15.584Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.83301 1.83398H20.1663"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33301 20.1673L10.9997 18.334V15.584"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 20.1673L11 18.334"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.875 10.084L9.76249 7.67315C9.99166 7.48065 10.2942 7.53565 10.45 7.79232L11.55 9.62565C11.7058 9.88232 12.0083 9.92816 12.2375 9.74482L15.125 7.33398"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NPSChurnIcon;
