import React, { useEffect, useRef, useState } from "react";
import { authenticatedAxiosInstance as axios } from "../api/axios";
import { getChart } from "../api/dashboard";
import { getFromCache, setToCache } from "../utils/cacheStorage";

const useIndustryBenchmark = (pageFilter, initial) => {
  const [charts, setCharts] = useState([]);
  const [loading, setLoading] = useState(false);
  const chartsRef = useRef(charts);

  useEffect(() => {
    chartsRef.current = charts;
  }, [charts]);

  const formatData = (data) => {
    let newData = Object.keys(data).map((item) => {
      let newObj = {};
      data[item]?.map((each) => {
        return (newObj[each.x_axis] = each.value);
      });
      return {
        name: item,
        ...newObj,
      };
    });
    return newData;
  };

  const formatBarData = (data) => {
    const OTTs = Object.keys(data)
      .map((ott, index) => {
        if (index !== 0) return null;
        return data[ott].map((item) => item.x_axis);
      })
      .filter(Boolean);
    const newData = OTTs[0].map((item, index) => {
      let newObj = {};
      Object.keys(data).forEach((ott) => {
        newObj[ott] = data[ott][index].value;
      });
      return {
        name: item,
        ...newObj,
      };
    });
    return newData;
  };

  const sortDataByQuarter = (data) => {
    const sortedKeys = Object.keys(data).sort((a, b) => {
      const [aQuarter, aYear] = a.split(" ");
      const [bQuarter, bYear] = b.split(" ");

      if (aYear !== bYear) {
        return aYear.localeCompare(bYear);
      } else {
        const quarters = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 };
        return quarters[aQuarter] - quarters[bQuarter];
      }
    });

    const sortedData = {};
    sortedKeys.forEach((key) => {
      sortedData[key] = data[key];
    });

    return sortedData;
  };

  const getSingleChartsData = async ({ params, res }) => {
    const { data: response } = await getChart({ params });
    const barData = formatBarData(sortDataByQuarter(response.data));
    const newData = {
      ...res,
      data: formatData(sortDataByQuarter(response.data)),
      bar: barData,
    };
    const existingChartIndex = chartsRef.current.findIndex(
      (item) => item.id === params.id
    );
    if (existingChartIndex !== -1) {
      setCharts((prevCharts) => {
        const updatedCharts = [...prevCharts];
        updatedCharts[existingChartIndex] = newData;
        return updatedCharts;
      });
    } else {
      setCharts((prevCharts) => [...prevCharts, newData]);
    }
    await setToCache("Chart", params.id, newData);
    setLoading(false);
  };

  // Function to remove extra charts stored in the cache
  const removeExtras = async (data) => {
    setCharts((prevCharts) => {
      const newChartIds = data.map((res) => res.id);

      const chartsToRemove = prevCharts.filter(
        (chart) => !newChartIds.includes(chart.id)
      );

      return prevCharts.filter((chart) => !chartsToRemove.includes(chart));
    });
  };

  const getChartsData = async () => {
    setLoading(true);
    try {
      const { data: response } = await axios.get(
        `/nps-churn/chart/?page-filter=${pageFilter}`
      );
      await setToCache(pageFilter, pageFilter, response.data);
      if (response.success) {
        response.data.forEach((res) => {
          const params = { id: res?.id };
          getSingleChartsData({ params, res });
        });
      }
      removeExtras(response.data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching charts data:", error);
    }
  };
  const fetchSpecificChartFromCache = async (data) => {
    for (const res of data) {
      try {
        const newData = await getFromCache(res.id);
        setCharts((prevCharts) => {
          const updatedCharts = Array.isArray(prevCharts)
            ? [...prevCharts]
            : [];
          updatedCharts.push(newData);
          return updatedCharts;
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    setLoading(false);
  };

  const fetchChartValueFromCache = async () => {
    const data = await getFromCache(pageFilter);
    if (data) {
      await fetchSpecificChartFromCache(data);
    }
  };

  useEffect(() => {
    if (initial) {
      const fetch = async () => {
        fetchChartValueFromCache();
        getChartsData();
      };
      fetch();
    }
  }, []);

  return { charts, loading };
};

export default useIndustryBenchmark;
