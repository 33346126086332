import React from "react";
import { subsLess } from "../../utils/helperFunc";
import { useRecoilValue } from "recoil";
import { SelectedProfileCountryAtom } from "../../atom";
import shortcutnew from "../../assets/shortcutnew.png";
import { useParams } from "react-router-dom";

const UserViwers = ({ detail }) => {
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);
  const { id } = useParams();

  const handleClick = () => {
    const url = `/search/viewers-trend?profile=${id}&region=${selectedProfileCountry}&type=MONTHLY_VIEWERS`;
    window.open(url, "_blank");
  };

  <style>
    {`
          .radial-gradient {
            background-image: radial-gradient(circle, #FBFCFD, #F0F5F9);
          }
          .custom-shadow {
          /* Shadow CSS */
            box-shadow: 0 22px 33px -21px rgba(0, 0, 0, 0.3);
          }
    `}
  </style>;
  return (
    <>
      <section
        className="group w-fit flex flex-col justify-evenly ml-2 border-2 border-8DB1CD52/32 rounded-2xl shadow-md py-4 px-6 radial-gradient custom-shadow relative cursor-pointer"
        onClick={handleClick}
      >
        <span className="absolute -top-10 right-0 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
          Click to see Viewers Trend
        </span>

        <div className="leading-tight text-sm">
          <img
            src={shortcutnew}
            className="absolute top-2 right-2"
            alt="subscriber-icon"
          />
          <p className="font-semibold">Free Monthly</p>
          <p className="font-semibold">Users/Viewers</p> {/* Removed (M) */}
          <div className="h-4 w-4"></div>
        </div>
        <p className="text-pot-dark-blue text-xl mt-1 font-bold">
          {String(detail.monthly_viewers_count?.existing.toFixed(2)) === "0.00"
            ? "0"
            : subsLess(detail.monthly_viewers_count?.existing)}
          {typeof detail.monthly_viewers_count?.existing !== "string" && "M"}

          {/* {subsLess(detail.monthly_viewers_count?.existing)} */}
        </p>
      </section>
    </>
  );
};

export default UserViwers;
