import React from "react";
import { months } from "../../utils/DateHelpers";
import { motion } from "framer-motion";

const MonthView = ({ month, setMonth, setMonthView, sameYear }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.6 }}
      animate={{ opacity: 1, scale: 1 }}
      className="grid grid-cols-4 gap-x-3 gap-y-8 mt-8 my-19 px-5"
    >
      {months.map((each, index) => (
        <button
          onClick={() => {
            setMonth(index);
            setMonthView(false);
          }}
          className={`w-full h-full ${
            month === index && sameYear ? "bg-pot-dark-blue text-white" : ""
          } px-3 py-2 rounded flex justify-center text-xs items-center`}
        >
          {/* <p className=""> */}
          {each}
          {/* </p> */}
        </button>
      ))}
    </motion.div>
  );
};

export default MonthView;
