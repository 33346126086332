import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import { getAdminDashboardData } from "../../api/dashboard";
import { titleAtom } from "../../atom";
import { titles } from "../../utils/Titles";
import ActiveUsersChart from "./ActiveUsersChart";
import ActiveUsersTable from "./ActiveUsersTable";
import DownloadNumberTrack from "./DownloadNumberTrack";
import Header from "./Header";
import ProfileCombinationTable from "./ProfileCombinationTable";
import ProfilesViewed from "./ProfilesViewed";
import TopFiltersTable from "./TopFiltersTable";
import UsersNumberTrack from "./UsersNumberTrack";
import ViewsPage from "./ViewsPage";

const AdminDashboard = () => {
  const currentDate = moment().format("YYYY-MM-DD");
  const lastMonthDate = moment().subtract(30, "days").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(lastMonthDate);
  const [endDate, setEndDate] = useState(currentDate);
  const setTitle = useSetRecoilState(titleAtom);
  const setters = useMemo(() => ({ startDate, endDate }), [startDate, endDate]);

  const fetch = useCallback(
    async (id, region = "") => {
      const params = { id, date_from: startDate, date_to: endDate };
      if (region) {
        params.profile_country = region;
      }
      const { data: response } = await getAdminDashboardData({ params });
      return response.data.results || response.data;
    },
    [startDate, endDate]
  );

  useEffect(() => {
    setTitle("Admin Dashboard");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
  }, []);

  return (
    <div className="px-8">
      <Header
        setters={{ start: startDate, end: endDate }}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <div className="w-full mt-8 flex flex-col gap-8">
        <UsersNumberTrack fetch={fetch} setters={setters} />
        <ActiveUsersChart fetch={fetch} setters={setters} />
      </div>
      <div className="w-full items-stretch h-fit mt-8 flex gap-x-8">
        <TopFiltersTable fetch={fetch} setters={setters} />
        <ActiveUsersTable fetch={fetch} setters={setters} />
      </div>
      <div className="w-full mt-8 flex gap-x-8">
        <ProfileCombinationTable fetch={fetch} setters={setters} />
      </div>
      <div className="w-full my-8 flex gap-x-8">
        <ProfilesViewed fetch={fetch} setters={setters} />
        <ViewsPage fetch={fetch} setters={setters} />
        <DownloadNumberTrack fetch={fetch} setters={setters} />
      </div>
    </div>
  );
};

export default AdminDashboard;
