import React, { useEffect, useState } from "react";
import { LeftArrow, RightArrow } from "../../assets/Arrows";
import { motion } from "framer-motion";
import {
  endOfCount,
  paginationLength,
  startOfCount,
} from "../../utils/tableHelpers";

const TableControl = ({
  page,
  setPage,
  length,
  setLength,
  totalPages,
  totalCount,
  big = false,
}) => {
  const MAX_PAGINATION_COUNT = 6;
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(MAX_PAGINATION_COUNT * Math.floor(page / MAX_PAGINATION_COUNT));
  }, [page]);

  return (
    <div
      className={` w-full sticky ${
        big && "left-0 border-t"
      } -bottom-0.5 z-50 px-10 flex justify-between items-center bg-white text-xs py-3`}
    >
      <div className="flex items-center">
        {!!totalCount && (
          <p className="w-56">
            Showing {startOfCount(length, page)} to{" "}
            {endOfCount(length, page, totalCount)} of {totalCount} Entries
          </p>
        )}
        {!!totalCount && !!totalPages && totalPages !== 1 && big && (
          <select
            value={length}
            onChange={(e) => setLength(e.target.value)}
            className="ml-5 px-2 bg-pot-background-grey-2 border rounded py-1"
          >
            {[10, 20, 25, 50, 100].map((each) => (
              <option key={each}>{each}</option>
            ))}
          </select>
        )}
      </div>
      {!!totalPages && totalPages !== 1 && (
        <div className="flex border-collapse">
          {big && (
            <motion.button
              onClick={() => setPage(1)}
              whileHover={{ scale: 1.1 }}
              className="bg-pot-background-grey-2 px-3 py-1 rounded mr-3 border"
            >
              First
            </motion.button>
          )}
          <motion.button
            onClick={() => page > 1 && setPage((prev) => prev - 1)}
            whileHover={{ scale: 1.1 }}
            className="bg-pot-background-grey-2 px-3 py-1 rounded-l border"
          >
            <LeftArrow />
          </motion.button>
          {Array.from({
            length: paginationLength(totalPages, offset / MAX_PAGINATION_COUNT),
          }).map((_, id) => {
            const number = id + (offset ? offset : 1);
            return (
              <button
                onClick={() => setPage(number)}
                key={id}
                className={`border ${
                  page === number
                    ? "bg-pot-blue text-white border-pot-blue"
                    : "bg-pot-background-grey-2"
                } px-2 py-1`}
              >
                {number}
              </button>
            );
          })}
          <motion.button
            onClick={() => page < totalPages && setPage((prev) => prev + 1)}
            whileHover={{ scale: 1.1 }}
            className="bg-pot-background-grey-2 px-3 py-1 rounded-r border"
          >
            <RightArrow />
          </motion.button>
          {big && (
            <motion.button
              onClick={() => setPage(totalPages)}
              whileHover={{ scale: 1.1 }}
              className="bg-pot-background-grey-2 px-3 py-1 rounded ml-3 border"
            >
              Last
            </motion.button>
          )}
        </div>
      )}
    </div>
  );
};

export default TableControl;
