import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import Header from "../../components/Pdf/Header";
import List from "../../components/Pdf/List";
import phone from "../../assets/pdf_png/phone.png";
import tv from "../../assets/pdf_png/tv.png";
import mobile from "../../assets/pdf_png/mobile.png";
import others from "../../assets/pdf_png/others.png";
import pc from "../../assets/pdf_png/pc.png";
import web from "../../assets/pdf_png/web.png";
import media_player from "../../assets/pdf_png/media_player.png";
import gaming_devices from "../../assets/pdf_png/gaming_devices.png";
import { toNormalText } from "../../utils/helperFunc";

const DeviceSupport = ({ devices }) => {
  const styles = StyleSheet.create({
    container: {
      marginTop: "10px",
      border: 1,
      borderRadius: "8px",
      borderColor: "#F0F1F8",
      width: "100%",
    },
    contents_container: {
      paddingVertical: "5px",
      paddingHorizontal: "8px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    content_header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    header_icon: {
      height: "7px",
      marginRight: "3px"
    },
    header_text: {
      fontWeight: "semibold",
    },
    content_value: {
      marginTop: "5px",
      display: "flex",
      flexDirection: "row",
    },
    ul: {
      paddingRight: "12px",
      paddingBottom: "12px",
    },
  });

  const showableDevices = {
    tv: [
      "Amazon Fire TV",
      "Android",
      "Apple TV",
      "Browser",
      "Chromecast",
      "Google TV",
      "iOS",
    ],

    others: [
      "Nintendo Switch",
      "PlayStation 3 ",
      "PlayStation 4 ",
      "PlayStation Vita ",
      "Roku ",
      "Xbox 360 ",
      "Xbox One ",
    ],

    mobile: [
      "Nintendo Switch",
      "PlayStation 3 ",
      "PlayStation 4 ",
      "PlayStation Vita ",
      "Roku ",
      "Xbox 360 ",
      "Xbox One ",
    ],

    web: [
      "Nintendo Switch",
      "PlayStation 3 ",
      "PlayStation 4 ",
      "PlayStation Vita ",
      "Roku ",
      "Xbox 360 ",
      "Xbox One ",
    ],

    pc: [
      "Nintendo Switch",
      "PlayStation 3 ",
      "PlayStation 4 ",
      "PlayStation Vita ",
      "Roku ",
    ],

    media_player: [
      "Nintendo Switch",
      "PlayStation 3 ",
      "PlayStation 4 ",
      "PlayStation Vita ",
      "Roku ",
      "Xbox 360 ",
      "Xbox One ",
    ],

    gaming_devices: [
      "Nintendo Switch",
      "PlayStation 3 ",
      "PlayStation 4 ",
      "PlayStation Vita ",
      "Roku ",
      "Xbox 360 ",
      "Xbox One ",
    ],
  };

  const getDeviceImage = (deviceType) => {
    if(deviceType.toLowerCase() === "tv") return tv
    else if(deviceType.toLowerCase() === "web") return web
    else if(deviceType.toLowerCase() === "mobile") return mobile
    else if(deviceType.toLowerCase() === "media players") return media_player
    else if(deviceType.toLowerCase().includes("gaming")) return gaming_devices
    else if(deviceType.toLowerCase() === "pc") return pc
    else return others
  }

  return (
    <View wrap={false} style={styles.container}>
      <Header image={phone} title="Device Platforms" />
      <View style={styles.contents_container}>
        {Object.keys(devices)?.map((deviceType) => (
          <View wrap={false}>
            <View wrap={false} style={styles.content_header}>
              <Image src={getDeviceImage(deviceType)} style={styles.header_icon} />
              <Text style={styles.header_text}>{deviceType === "undefined" ? "Others" : toNormalText(deviceType)}</Text>
            </View>
            <View style={styles.content_value}>
              {Array.from({
                length: Math.ceil(devices[deviceType].length / 7),
              }).map((_, id) => (
                <View style={styles.ul}>
                  {Array.from({
                    length:
                      devices[deviceType].length - id * 7 < 7
                        ? devices[deviceType].length - id * 7
                        : 7,
                  }).map((_, idx) => (
                    <List text={devices[deviceType][idx + (id * 7 )]} />
                  ))}
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default DeviceSupport;
