import React, { memo } from "react";
import { strokes } from "../../utils/chartsData";
import { names } from "../../utils/chartsData";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
// import { ClipLoader } from "react-spinners";
import { DotPulse } from "@uiball/loaders";
import { useRecoilValue } from "recoil";
import { ResizingAtom } from "../../atom";

const TrendStackedAreaChart = ({
  selectedBarQuarter,
  chartLoading,
  selectedOtts,
  type,
}) => {
  // const data = names.map((name) => ({
  //   name,
  //   value: Math.ceil(Math.random() * 60),
  // }));
  const containerResizing = useRecoilValue(ResizingAtom);

  if (chartLoading || containerResizing) {
    return (
      <div className="w-full flex justify-center h-[350px] items-center">
        <DotPulse size={40} color="#1767A5" speed={0.8} />
      </div>
    );
  }

  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height={350}>
      <AreaChart
        data={data}
        // data={selectedOtts}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          label={{
            value: "Time",
            position: "insideBottom",
            offset: -20,
          }}
          dataKey="name"
        />
        <YAxis
          label={{
            value:
              type === "PAID_SUBS"
                ? "Subscribers (M)"
                : type === "MONTHLY_VIEWERS"
                ? "Viewers (M)"
                : "Transactional Customers (M)",
            angle: -90,
            offset: 50,
            position: "insideTopRight",
          }}
        />
        <Tooltip
          formatter={(value, name) => [`${value.toFixed(1)} M`, `Q${name}`]}
          cursor={{ fill: "fff", fillOpacity: "0" }}
        />
        <Legend />
        {Object.keys(data[0]) // Assuming all keys except 'name' represent areas
          .filter((key) => key !== "name")
          .map((key, index) => (
            <Area
              type="monotone"
              dataKey={key}
              stackId="1"
              key={`area-${index}`}
              strokeWidth={2} // Adjust the stroke width as needed
              stroke={strokes[index % strokes.length]}
              fill={strokes[index % strokes.length]} // Assigning a color from strokes array
            />
          ))}
        {/* {selectedOtts.map((dataSet, index) => (
          <Area
            type="monotone"
            dataKey={dataSet.name}
            stackId="1"
            key={`area-${index}`}
            stroke={strokes[index % strokes.length]}
            fill={dataSet.color}
          />
        ))} */}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default memo(TrendStackedAreaChart);
