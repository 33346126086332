import React from "react";
import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import poppins_regular from "../../assets/Poppins/Poppins-Regular.ttf";
import poppins_semibold from "../../assets/Poppins/Poppins-SemiBold.ttf";
import poppins_bold from "../../assets/Poppins/Poppins-Bold.ttf";
import poppins_extrabold from "../../assets/Poppins/Poppins-ExtraBold.ttf";
import poppins_black from "../../assets/Poppins/Poppins-Black.ttf";
import FrontPage from "./FrontPage";
import parks_associates from "../../assets/parks-associates-Teallogo.png";
import Footer from "./Footer";
import NameSection from "./NameSection";
import DetailSection from "./DetailSection";
import Resolution from "./Resolution";
import Presence from "./Presence";
import Subscribers from "./Subscribers";
import DistributionType from "./DistributionType";
import Viewers from "./Viewers";
import TransactionalViewers from "./TransactionalViewers";
import Countries from "./Countries";
import DistributionPlatform from "./DistributionPlatform";
import Services from "./Services";
import UniqueAspect from "./UniqueAspect";
import Issues from "./Issues";
import Pricing from "./Pricing";
import DeviceSupport from "./DeviceSupport";

const PDFpage = ({ detail, pdfShowableDevices, pdfShowableCountries, region }) => {
  Font.register({
    family: "Poppins",
    fonts: [
      { src: poppins_regular },
      { src: poppins_semibold, fontWeight: "semibold" },
      { src: poppins_bold, fontWeight: "bold" },
      { src: poppins_extrabold, fontWeight: "extrabold" },
      { src: poppins_black, fontWeight: "black" },
    ],
  });

  const page_style = StyleSheet.create({
    body: {
      width: "100vw",
      height: "100vh",
      paddingBottom: "70px",
      paddingTop: "15px",
      paddingHorizontal: "15px",
      fontFamily: "Poppins",
      fontSize: "7.5px",
    },
    line_display_container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "10px",
    },
  });

  // Font.registerHyphenationCallback(word => {
  //   console.log(word, typeof(word))
  //   return ([word])
  // });

  return (
    <Document>
      <Page size="A4">
        <FrontPage />
      </Page>
      <Page size="A4" style={page_style.body}>
        <NameSection detail={detail} region={region} />
        <DetailSection detail={detail} />
        <View style={page_style.line_display_container}>
          {detail?.resolutions?.existing?.concat(detail.resolutions?.new)
            ?.length && <Resolution detail={detail} />}
          <Presence detail={detail} />
          {detail?.distribution_types?.existing?.concat(
            detail.distribution_types?.new
          )?.length && <DistributionType detail={detail} />}
          {(detail.subscribers_count?.existing ||
            detail.subscribers_count?.existing) && (
            <Subscribers detail={detail} region={region} />
          )}
          {detail?.monthly_viewers_count?.existing && (
            <Viewers detail={detail} last />
          )}
          {detail?.transactional_customers_count?.existing && (
            <TransactionalViewers detail={detail} last />
          )}
        </View>
        {detail?.countries?.existing?.concat(detail?.countries?.new || []).length && (
          <Countries countries={pdfShowableCountries} />
        )}
        {detail?.distribution_platforms?.existing?.concat(
          detail?.distribution_platforms?.new || []
        )?.length && <DistributionPlatform detail={detail} />}
        {detail?.device_support?.existing?.concat(detail?.device_support?.new || [])
          ?.length && <DeviceSupport devices={pdfShowableDevices} />}
        {detail?.pricing_and_promotions?.existing && (
          <Pricing detail={detail} />
        )}
        <View wrap={false} style={page_style.line_display_container}>
          {(detail?.service_features?.existing ||
            detail?.unique_aspects?.existing) && <Services detail={detail} />}
          {/* {detail?.unique_aspects?.existing && <UniqueAspect detail={detail} />} */}
        </View>
        {/* {detail?.issues_considerations?.existing && <Issues detail={detail} />} */}
        <Footer />
      </Page>
    </Document>
  );
};

export default PDFpage;
