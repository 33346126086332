import React from "react";
import { strokes } from "../../utils/chartsData";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  x,
} from "recharts";

const NPSChurnBarChart = ({ filteredData, xAxisLabels, yAxisLabel, page }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={filteredData}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 40,
        }}
        barSize={30}
        barCategoryGap={10} // Gap between groups of bars
        barGap={0} // Gap between bars in a group
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dy={filteredData.length > 8 ? 25 : 5}
          interval={0}
          fontSize={12}
          dataKey="name"
          tick={filteredData.length > 8 ? undefined : CustomizedTick}
          angle={filteredData.length > 8 ? -15 : 0}
        />
        <YAxis
          fontSize={12}
          // label={{
          //   value: yAxisLabel,
          //   angle: -90,
          //   offset: 55,
          //   // position: "insideRight",
          //   fontSize: 12,
          //   lineHeight: 16,
          //   dx: -20,
          // }}
        />
        <Tooltip
          formatter={(value, name) => {
            if (page === "CHURN") return [`${value}%`, `${name}`];
            if (page === "NPS") return [`${value}`, `${name}`];
            return [`${value}`, `${name}`];
          }}
          wrapperStyle={{ fontSize: "12px" }}
        />{" "}
        {xAxisLabels &&
          xAxisLabels.map((each, index) => (
            <Bar
              key={each}
              type="monotone"
              dataKey={each}
              fill={strokes[index]}
              strokeWidth={1.5}
            />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

const CustomizedTick = (props) => {
  const { x, y, payload } = props;
  const words = payload.value.split(" ");
  // const words = payload.value.split(/[ /]/); // Includes both space and "/"

  const mergedWords = [];
  let currentWord = "";
  for (let i = 0; i < words.length; i++) {
    if ((currentWord + " " + words[i]).trim().length < 12) {
      currentWord = (currentWord + " " + words[i]).trim();
    } else {
      if (currentWord !== "") {
        mergedWords.push(currentWord);
        currentWord = "";
      }
      mergedWords.push(words[i]);
    }
  }
  if (currentWord !== "") {
    mergedWords.push(currentWord);
  }
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" fontSize={12}>
        {mergedWords?.map((word, index) => {
          if (word?.length > 12) {
            return word.split("/").map((newWord, id) => {
              return (
                <tspan key={id} x={0} dy="1em">
                  {id === 0 ? newWord : "/" + newWord}
                </tspan>
              );
            });
          }
          return (
            <tspan key={index} x={0} dy="1em">
              {word}
            </tspan>
          );
        })}
      </text>
    </g>
  );
};

export default NPSChurnBarChart;
