import React from 'react'

const DayHeader = ({ bold= false }) => {
  return (
    <div className={`pt-2 grid grid-cols-7 text-xs text-center gap-3 px-4 ${bold && "font-bold"}`}>
        <p>Su</p>
        <p>Mo</p>
        <p>Tu</p>
        <p>We</p>
        <p>Th</p>
        <p>Fr</p>
        <p>Sa</p>
    </div>
  )
}

export default DayHeader