import { authenticatedAxiosInstance as axios } from "./axios";

const create = (payload) => axios.post(`/library/`, payload);
const list = (params) => axios.get(`/library/`, params);
const destroy = (id) => axios.delete(`/library/${id}/`);
const update = (id, payload) => axios.patch(`/library/${id}/`, payload);
const download = (url, arrayBufferType = false) =>
  axios.get(
    url,
    { responseType: arrayBufferType ? "arraybuffer" : "blob" }
  );

export const LibraryApi = {
  create,
  list,
  destroy,
  update,
  download,
};
