import React, { useEffect, useRef, useState } from "react";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import DateContainer from "./DateContainer";
import TopBarControl from "./TopBarControl";
import moment from "moment";
import { motion } from "framer-motion";
import useOnClickOutside from "../../hooks/useClickOutside";
import CheckCircleOutlineIcon from "@atlaskit/icon/glyph/check-circle-outline";

const DatePickerMain = ({ open=false, setOpen, value, setValue }) => {
  const [date, setDate] = useState(new Date().getDate());
  const [month, setMonth] = useState(
    (((new Date().getMonth() - 1) % 12) + 12) % 12
  );
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [inputDate, setInputDate] = useState("");
  const [initial, setInitial] = useState(false);
  const ref = useRef()
  useOnClickOutside(ref, () => setOpen(false))

  useEffect(() => {
    if (initial) {
      setInputDate(moment().format("MM-DD-YY"));
      setInitial(false);
      return;
    }
    const settableDate = selectedDate ? selectedDate : new Date();
    setMonth(settableDate.getMonth());
    setYear(settableDate.getFullYear());
    setInputDate(moment(settableDate).format("MM-DD-YY"));
    if(open){
      setValue(selectedDate)
      setOpen(false)
    }
  }, [selectedDate]);

  console.log(value, "valee")

  return (
    <div onClick={(e) => {
      e.stopPropagation()
      console.log("Hellooo ooo")
      }} ref={ref} className="relative z-[70]">
      <button
        onClick={(e) => {
          e.stopPropagation();
          console.log("Yesssss ooo")
          setOpen(prev => !prev)
        }}
        className="pl-4 pr-3 bg-pot-bg-blue flex items-center gap-x-2 text-sm text-pot-blue py-1 rounded-md"
      >
        Data Updated On
        {value ? (
          <motion.div initial={{scale: 0}} animate={{scale: 1}} className="h-fit -mb-1 py-[3px]">
            <CheckCircleOutlineIcon primaryColor="#1767A5" size="small" />
          </motion.div>
        ) : (
          <HipchatChevronDownIcon primaryColor="#1767A5" />
        )}
      </button>
      {open && (<motion.div initial={{ opacity: 0}} animate={{ opacity: 1 }} onClick={e => e.stopPropagation()} className="absolute z-[70] top-10 min-w-[24vw] shadow-md border rounded-md bg-white pb-4">
        <TopBarControl
          setInputDate={setInputDate}
          inputDate={inputDate}
          date={selectedDate}
          setDate={selectedDate}
          setValue={setValue}
          setOpen={setOpen}
        />
        <DateContainer
          date={{
            value: date,
            month: month,
            year: year,
            set: setDate,
            setMonth: setMonth,
            setYear: setYear,
          }}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </motion.div>)}
    </div>
  );
};

export default DatePickerMain;
