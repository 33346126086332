import React, { useEffect, useState } from "react";
// import { useQuery } from '@tanstack/react-query'

const DownloadNumberTrack = ({ fetch, setters=[] }) => {
  // const { data } = useQuery({
  //   queryKey: ["DownloadTrack"],
  //   queryFn: () => fetch("NO_OF_DOWNLOADS"),
  //   cacheTime: (1000 * 60 * 60 * 24),
  //   initialData: {}
  // })

  const [ data, setData ] = useState([])
  // const [ loading, setLoading ] = useState(true)
  
  const fetchDownloadCount = async () => {
    const data = await fetch("NO_OF_DOWNLOADS")
    setData(data)
    // setLoading(false)
  }
  
  useEffect(() => {
    fetchDownloadCount();
  }, [setters])


  return (
    <div className="w-3/5 self-stretch flex flex-col gap-y-4 text-xs">
      <div className="w-full h-full shadow-md border rounded-md px-3 py-2">
        <p>No Of Downloads From Search Results</p>
        <div className="text-pot-blue text-xl mt-4 font-semibold flex">
          <p className="w-1/3">PDF:</p>
          <p className="w-1/3">{data?.search_result?.PDF || 0}</p>
        </div>
        <div className="text-pot-blue text-xl mt-2 font-semibold flex">
          <p className="w-1/3">Excel:</p>
          <p className="w-1/3">{data?.search_result?.EXCEL || 0}</p>
        </div>
      </div>
      <div className="w-full h-full shadow-md border rounded-md px-3 py-2">
        <p>No Of Downloads From Profiles</p>
        <div className="text-pot-blue text-xl mt-8 font-semibold flex">
          <p className="w-1/3">PDF:</p>
          <p className="w-1/3">{data?.profile?.PDF || 0}</p>
        </div>
      </div>
      <div className="w-full h-full shadow-md border rounded-md px-3 py-2">
        <p>No Of Downloads From Viewers Trend</p>
        <div className="text-pot-blue text-xl mt-8 font-semibold flex">
          <p className="w-1/3">Excel:</p>
          <p className="w-1/3">{data?.viewers_trend?.EXCEL || 0}</p>
        </div>
      </div>
      <div className="w-full h-full shadow-md border rounded-md px-3 py-2">
        <p>No Of Downloads From Service Comparison</p>
        <div className="text-pot-blue text-xl mt-8 font-semibold flex">
          <p className="w-1/3">PDF:</p>
          <p className="w-1/3">{data?.service_comparison?.PDF || 0}</p>
        </div>
      </div>
    </div>
  );
};

export default DownloadNumberTrack;
