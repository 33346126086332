import React from "react";
import location from "../../assets/pdf_png/location_blue_big.png";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const Header = ({ cols, widths, subsType, region }) => {
  const styles = StyleSheet.create({
    header_container: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      marginTop: "20px",
      marginBottom: "10px",
      justifyContent: "space-between",
    },
    heading: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#1767A5",
      marginTop: "20px",
      marginBottom: "10px",
    },
    table_head: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#F4F4F4",
    },
    region: {
      display: "flex",
      flexDirection: "row",
      columnGap: "1px",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      paddingRight: "5px",
      paddingBottom: "5px"
    },
    region_text: {
      fontSize: "7px",
      color: "#1767A5",
      fontWeight: "medium",
    },
    region_img: {
      width: "10px",
      height: "10px",
      paddingBottom: "2px",
      paddingLeft: "2px"
    },
  });

  return (
    <View fixed>
      <View style={styles.header_container}>
        <Text style={styles.heading}>OTT Service Search Results</Text>
        <View style={styles.region}>
          <Image style={styles.region_img} src={location} />
          <Text style={styles.region_text}>{region.toUpperCase()}</Text>
        </View>
      </View>
      <View style={styles.table_head}>
        {cols?.map((col, id) => (
          <View
            style={{
              width: widths[id],
              padding: "5px 4px",
              textAlign: "center",
            }}
          >
            <Text>{col.toLowerCase() === "subscribers" ? subsType : col}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default Header;
