import { MacScrollbar } from "mac-scrollbar";
import { titles } from "../../utils/Titles";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AccountUserAtom, VerificationResultAtom, titleAtom } from "../../atom";
import Download from "./Download";
import Dropzone from "./Dropzone";
import Table from "./Table";
import Success from "./Success";
import Error from "./Error";

function UploadSubscribers() {
  const setTitle = useSetRecoilState(titleAtom);
  const [uploadVerifyId, setUploadVerifyId] = useState(null);
  const [hasDownload, setHasDownload] = useState(true);
  const [hasTable, setHasTable] = useState(true);
  const [hasDropzone, setHasDropzone] = useState(true);
  const account = useRecoilValue(AccountUserAtom);
  const [result, setVerificationResult] = useRecoilState(
    VerificationResultAtom
  );

  const goBack = () => {
    setUploadVerifyId(null);
    setVerificationResult(null);
  };

  useEffect(() => {
    setTitle("Upload Subscribers");
    document.title = `Streaming Video Tracker | ${
      titles[window.location.pathname]
    }`;
    setVerificationResult(null);
  }, []);

  useEffect(() => {
    setHasDropzone(account?.user_permissions.includes("a_create_upload"));
    setHasDownload(account?.user_permissions.includes("a_download_upload"));
    setHasTable(account?.user_permissions.includes("a_view_upload"));
  }, [account]);

  if (result === "FAILED") {
    return <Error goBack={goBack} />;
  }
  if (result === "SUCCESS") {
    return <Success goBack={goBack} />;
  }

  return (
    <MacScrollbar>
      <div className="w-full relative flex flex-col">
        <section className={`w-full transition duration-150 flex flex-col`}>
          {hasDownload && <Download />}
          {hasDropzone && (
            <Dropzone
              uploadVerifyId={uploadVerifyId}
              setUploadVerifyId={setUploadVerifyId}
              hasTable={hasTable}
              hasDownload={hasDownload}
            />
          )}
          <Table
            uploadVerifyId={uploadVerifyId}
            height={
              hasDropzone ? "h-[63vh]" : hasDownload ? "h-[75vh]" : "h-[85vh]"
            }
            hasDropzone={hasDropzone}
          />
        </section>
      </div>
    </MacScrollbar>
  );
}

export default UploadSubscribers;
