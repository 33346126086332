import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useClickOutside";
import DateContainer from "./DateContainer";

const DatePickerInputTwo = ({
  heading = "Publication Date",
  setPickedDate = () => {},
  editData,
  clear,
  placeholder,
}) => {
  const [date, setDate] = useState(new Date().getDate());
  const [month, setMonth] = useState(((new Date().getMonth() % 12) + 12) % 12);
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [initial, setInitial] = useState(true);
  const [inputDate, setInputDate] = useState("");
  const dateRef = useRef(null);
  useOnClickOutside(dateRef, () => setOpen(false));

  useEffect(() => {
    console.log("hello", selectedDate)
    if (initial) {
      setInitial(false);
      return;
    }
    setInputDate(moment(selectedDate).format("MM-DD-YYYY"));
  }, [selectedDate]);

  useEffect(() => {
    if (!open) {
      if (inputDate) setPickedDate(selectedDate);
      else setPickedDate(null);
    }
  }, [open]);

  useEffect(() => {
    if(editData?.publication_date) {
      setSelectedDate(new Date(editData.publication_date))
    } else {
      setInputDate("")
    }
  }, [editData])

  useEffect(() => {
    if(clear) {
      setInputDate("")
    }
  }, [clear])

  return (
    <label className="relative">
      <span className="font-semibold">{heading}</span>
      <input
        placeholder={placeholder}
        value={inputDate}
        onFocus={() => setOpen(true)}
        onChange={(e) => {
          if (e.target.value === "") {
            setInputDate("");
          }
        }}
        onKeyDown={(e) => {
            if(e.key === "Enter") {
                e.target.blur();
                setOpen(false)
            }
        }}
        className={`w-full mt-1 text-xs box-border outline-none ${
          open
            ? "bg-white border-2 border-pot-light-blue-2"
            : " bg-[#EBECF0]/60 hover:bg-[#DFE1E6]/60 border-2 border-[#EBECF0]/60"
        } transition placeholder:text-pot-text-grey-2 ease-in-out duration-200 flex justify-between p-2 rounded-sm`}
      />
      {open && (
        <div
          ref={dateRef}
          className=" w-full absolute z-50 bg-white border rounded-md shadow-sm mt-2 py-2"
        >
          <DateContainer
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            date={{
              value: date,
              month: month,
              year: year,
              set: setDate,
              setMonth: setMonth,
              setYear: setYear,
            }}
          />
        </div>
      )}
    </label>
  );
};

export default DatePickerInputTwo;
