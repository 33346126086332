import Modal, { ModalBody, ModalHeader } from "@atlaskit/modal-dialog";
import React, { useEffect, useState } from "react";
import EditorCloseIcon from "@atlaskit/icon/glyph/editor/close";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { formatDateToMMDDYY } from "../../utils/DateHelpers";
import TableLayoutTwo from "../../components/TableLayout/Two";

const EmailDetailsModal = ({ handleCancel, data }) => {
  const [isLoading, setIsLoading] = useState(true);
  const date = data?.created_at ? formatDateToMMDDYY(data?.created_at) : "-";

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  const colDef = [
    {
      header: () => (
        <div className="w-full flex justify-center cursor-pointer bg-[#fafafa] ">
          No.
        </div>
      ),
      id: "no",
      cell: ({ row }) => {
        const index = parseInt(row.id) + 1;
        return (
          <div className="w-full flex justify-center text-pot-grey-3">
            {index}
          </div>
        );
      },
    },
    {
      header: () => (
        <div className="w-full flex justify-center cursor-pointer bg-[#fafafa] ">
          Email
        </div>
      ),
      accessor: "email",
      id: "email",
      cell: ({ row }) => (
        <div className="w-full flex justify-center text-pot-grey-3">
          {row?.original?.email}
        </div>
      ),
    },
    {
      header: () => (
        <div className="w-full flex justify-center cursor-pointer bg-[#fafafa] ">
          Status
        </div>
      ),
      accessor: "status",
      id: "status",
      cell: ({ row }) => (
        <div className="w-full flex justify-center text-pot-grey-3">
          {row?.original?.status}
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data: data?.recipients,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="rounded-xl bg-pot-grey-3">
      <Modal onClose={handleCancel} width={"600px"}>
        <ModalHeader>
          <div className="flex justify-between w-full">
            <h1 className="text-xl font-bold">Email Info</h1>
            <div onClick={handleCancel} className="cursor-pointer">
              <EditorCloseIcon />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="text-xs flex flex-col font-popins">
            <div className="flex gap-10">
              <div className="flex gap-1 w-[200px]">
                <p className="text-pot-grey-3">Date:</p>
                <span className="text-pot-blue-2 font-semibold">
                  {isLoading ? <Loader /> : date || "-"}
                </span>
              </div>
              <div className="flex gap-1">
                <p className="text-pot-grey-3">Email Template:</p>
                <span className="text-pot-blue-2 font-semibold">
                  {isLoading ? <Loader /> : data?.email_template_name || "-"}
                </span>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="flex gap-1">
                <p className="text-pot-grey-3">Subject:</p>
                <span className="text-pot-blue-2 font-semibold">
                  {isLoading ? <Loader type="long" /> : data?.subject || "-"}
                </span>
              </div>
            </div>
          </div>
          <div className="my-4 rounded-md border-[1px] overflow-hidden">
            {" "}
            {data && data?.recipients && <TableLayoutTwo table={table} small />}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EmailDetailsModal;

const Loader = ({ type }) => {
  return (
    <div
      className={`${
        type === "long" ? "w-[450px]" : "w-[150px]"
      } h-4 bg-pot-light-blue-2/25 animate-pulse`}
    ></div>
  );
};
