import React, { useEffect, useState } from "react";
import useIndustryBenchmark from "../../../hooks/useIndustryBenchmark";
import CommonChart from "../../../components/CommonChart";
import { DotPulse } from "@uiball/loaders";
import { AnimatePresence, motion } from "framer-motion";
import NoData from "../../../assets/no_data.png";

const IndustryBenchmark = () => {
  // STATES
  const [initial, setInitial] = useState(true);
  const { charts, loading } = useIndustryBenchmark("CHURN", initial);
  const sortedCharts =
    charts && charts?.length
      ? charts?.sort((a, b) => a.position - b.position)
      : [];

  // USE EFFECTS
  useEffect(() => {
    if (charts.length > 0) {
      setInitial(false);
    }
  }, [charts]);

  return (
    <div
      className={`m-5 grid ${
        charts?.length > 0 && "grid-cols-1 xl:grid-cols-2"
      } gap-6`}
    >
      {loading && (
        <div className="w-full h-[40vh] flex justify-center items-center pb-[14vh]">
          <DotPulse size={40} color="#1767A5" />
        </div>
      )}
      {!loading && !charts?.length && (
        <AnimatePresence>
          <motion.section
            initial={{ opacity: 0, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
            className="w-full flex flex-col justify-center items-center gap-y-2"
          >
            <img src={NoData} alt="NO DATA" className="w-[30vw]" />
            <p className="text-sm w-[60%] text-center">
              We're currently enhancing your experience! This page is under a
              brief upgrade and will be back shortly. Please revisit us in a
              little while to see what's new. Thank you for your patience and
              understanding!
            </p>
          </motion.section>
        </AnimatePresence>
      )}
      {!loading &&
        charts.length > 0 &&
        sortedCharts.map((chart) => (
          <CommonChart
            key={chart?.id}
            id={chart?.id}
            title={chart?.name}
            footer={chart?.footer_text}
            data={chart?.data}
            bar={chart?.bar}
            yAxisLabel={chart?.y_axis_label}
            page="CHURN"
            quarterRange={chart?.quarter_range}
          />
        ))}
    </div>
  );
};
export default IndustryBenchmark;
