import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { motion } from "framer-motion";
import { Outlet, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  MainAppInitailAtom,
  MainAppSideBarExpandAtom,
  PdfColsAtom,
  PdfDataAtom,
  SelectedProfileCountryAtom,
} from "../atom";
import { getFromLocalStorage } from "../utils/localStorage";
import { registerIntercepts } from "../api/axios";
import { MacScrollbar } from "mac-scrollbar";
import { TelemetryApi } from "../api/telemetry";
import { titles } from "../utils/Titles";

const MainNavigationComponent = ({
  navItems,
  showSearch = false,
  equals = false,
  target,
  startWalkThrough,
  setStartWalkThrough,
}) => {
  const [sidebarExpand, setSidebarExpand] = useRecoilState(
    MainAppSideBarExpandAtom
  );
  const [initial, setInitial] = useRecoilState(MainAppInitailAtom);
  const [loading, setLoading] = useState(true);
  const setPdfCols = useSetRecoilState(PdfColsAtom);
  const setPdfData = useSetRecoilState(PdfDataAtom);
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);
  const location = useLocation();

  const pageTelementry = async (location) => {
    const payload = { url: window.location.href };
    const urlArray = window.location.pathname.split("/");
    urlArray.shift();
    if (urlArray.length < 1) return;
    console.log(urlArray, "list", urlArray?.[1]?.includes("-"), "loccc");
    if (urlArray[0] === "profile" && urlArray?.[1]?.includes("-")) {
      payload.profile_viewed = urlArray[1];
      return;
    }
    const title = titles[window.location.pathname];
    if (!title) {
      return;
    }
    payload.title = title;
    const params = { profile_country: selectedProfileCountry };
    const { data: response } = await TelemetryApi.pageView(payload, { params });
    if (!response.success) {
      console.error(response.errors.error_message.join(", "));
    }
  };

  useEffect(() => {
    if (initial) {
      setTimeout(() => setInitial(false), 900);
    }
    if (!getFromLocalStorage("token")) {
      localStorage.removeItem("token");
      localStorage.removeItem("user_type");
      localStorage.removeItem("noChange");
      localStorage.removeItem("loggedInAlways");
      window.location.href = "/login";
    } else {
      const width = window.screen.width;
      registerIntercepts(setLoading);
      setSidebarExpand(width > 1280 ? true : false);
    }
  }, [initial]);

  useEffect(() => {
    setPdfCols(null);
    setPdfData(null);
    // console.log(window.history.state, window.navigation, "state")
    if (!window.location.pathname.includes("admin")) {
      pageTelementry(location);
    }
  }, [location]);

  if (loading) {
    return <></>;
  }

  return (
    <MacScrollbar>
      <div className="h-screen overflow-y-auto overflow-x-hidden">
        {/* <div className="w-screen flex justify-center">
      <motion.div
        initial={{scale: 4}}
        animate={{scale: 1}}
        whileHover={{scale : 3}}
      >
        {initial ? "hello" : "dolo"}
      </motion.div>
      </div> */}
        <Sidebar
          navItems={navItems}
          sidebarExpand={sidebarExpand}
          setSidebarExpand={setSidebarExpand}
          initial={initial}
          equals={equals}
          target={target}
          startWalkThrough={startWalkThrough}
        />
        <motion.div
          animate={sidebarExpand ? { paddingLeft: 180 } : { paddingLeft: 56 }}
          transition={{ duration: 0.4 }}
        >
          <Topbar
            initial={initial}
            showSearch={showSearch}
            target={target}
            startWalkThrough={startWalkThrough}
            setStartWalkThrough={setStartWalkThrough}
          />
          {/* <LoadingBar
          color="#00838F"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        /> */}
          <motion.div
            key={location.pathname}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
          >
            <Outlet />
          </motion.div>
        </motion.div>
      </div>
    </MacScrollbar>
  );
};

export default MainNavigationComponent;
