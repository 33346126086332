import React from "react";
import { Text, Image, View, StyleSheet, Link } from "@react-pdf/renderer";
import detail_img from "../../assets/pdf_png/detail.png";
import FlexText from "../../components/Pdf/FlexText";
import moment from "moment";
import { fullSentance } from "../../utils/helperFunc";

const DetailSection = ({ detail }) => {
  const style = StyleSheet.create({
    area: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "10px",
    },
    container: {
      border: 1,
      borderRadius: "8px",
      borderColor: "#F0F1F8",
      width: "49%",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingHorizontal: "8px",
      paddingVertical: "5px",
      borderBottom: 1,
      borderBottomColor: "#F0F1F8",
    },
    title: {
      display: "flex",
      flexDirection: "row",
    },
    title_text: {
      color: "#1767A5",
      fontSize: "11px",
      fontWeight: "bold",
      marginLeft: "5px",
    },
    title_img: {
      width: "12px",
      height: "12px",
    },
    title_url: {
      maxWidth: "150px",
      height: "15px",
      color: "#1767A5",
      textDecoration: "underline",
      fontSize: "8px",
      textAlign: "right"
    },
    link: {
      width: "100%",
      textOverflow: "clip",
      backgroundColor: "yellow"
    },
    content_container: {
      paddingHorizontal: "8px",
      paddingVertical: "5px",
    },
    content: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: "3px",
    },
    content_title: {
      width: "45%",
      fontWeight: "semibold",
      marginRight: "5px",
    },
    content_value: {
      width: "100%",
    },
  });
  return (
    <View style={style.area}>
      <View style={style.container}>
        <View style={style.header}>
          <View style={style.title}>
            <Image src={detail_img} style={style.title_img} />
            <Text style={style.title_text}>Detail</Text>
          </View>
          <Text style={style.title_url}>
              {detail.site_urls?.existing?.[0]}
          </Text>
        </View>
        <View style={style.content_container}>
          <FlexText
            title={"Name"}
            value={detail.name?.existing || "-"}
            style={style}
          />
          <FlexText
            title={"Launch Date"}
            value={
              detail.launch_date?.existing
                ? moment(detail?.launch_date?.existing).format("MMMM YYYY")
                : "-"
            }
            style={style}
          />
          <FlexText
            title={"Ownership"}
            value={detail.ownership?.existing || "-"}
            style={style}
          />
          {/* <FlexText
            title={"Licensing Agreements"}
            value={detail.licensing_agreement?.existing || "-"}
            style={style}
          /> */}
          <FlexText
            title="Language"
            value={
              detail.languages?.existing?.length ||
              detail.languages?.new?.length
                ? fullSentance(detail.languages)
                : "-"
            }
            style={style}
          />
        </View>
      </View>
      <View style={style.container}>
        <View style={style.header}>
          <View style={style.title}>
            <Image src={detail_img} style={style.title_img} />
            <Text style={style.title_text}>More detail</Text>
          </View>
        </View>
        <View style={style.content_container}>
          <FlexText
            title="Types of content"
            value={
              detail.type_of_content?.existing?.length ||
              detail.type_of_content?.new?.length
                ? fullSentance(detail.type_of_content)
                : "-"
            }
            style={style}
          />
          <FlexText
            title="Genre"
            value={
              detail.genres?.existing?.length || detail.genres?.new?.length
                ? fullSentance(detail.genres)
                : "-"
            }
            style={style}
          />
          <FlexText
            title="Content Details"
            value={detail.content_details?.existing || "-"}
            style={style}
          />
          <FlexText
            title="Target Market"
            value={
              detail.target_market?.existing?.length ||
              detail.target_market?.new?.length
                ? fullSentance(detail.target_market)
                : "-"
            }
            style={style}
          />
          <FlexText
            title="Business Model"
            value={
              detail.business_model?.existing?.length ||
              detail.business_model?.new?.length
                ? fullSentance(detail.business_model)
                : "-"
            }
            style={style}
          />
          <FlexText
            title="No of streams"
            value={detail.no_of_streams?.existing || "-"}
            style={style}
            whitespace
          />
          <FlexText
            title="Size of Library"
            value={detail.size_of_library?.existing || "-"}
            style={style}
            whitespace
          />
        </View>
      </View>
    </View>
  );
};

export default DetailSection;
