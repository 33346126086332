import React from "react";

const Edit = ({ fill }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.349888 13.0249C0.322296 13.1599 0.328607 13.2996 0.368256 13.4316C0.407906 13.5636 0.479656 13.6836 0.577086 13.7811C0.674515 13.8785 0.794582 13.9502 0.926539 13.9899C1.0585 14.0295 1.19823 14.0358 1.33322 14.0083L4.50822 13.3583L0.999888 9.84992L0.349888 13.0249ZM13.5366 2.58909L11.7699 0.820755C11.615 0.665821 11.431 0.543001 11.2285 0.459343C11.026 0.375686 10.809 0.332837 10.5899 0.333255C10.1632 0.333255 9.73656 0.495755 9.41072 0.820755L1.27989 8.95242L5.40489 13.0774L13.5357 4.94576C13.8482 4.63321 14.0237 4.20936 14.0237 3.76742C14.0237 3.32548 13.8482 2.90163 13.5357 2.58909H13.5366Z"
        fill={fill}
      />
    </svg>
  );
};

export default Edit;
