import { authenticatedAxiosInstance as axios } from "./axios";
import { authenticatedAxiosInstanceV2 as axiosV2 } from "./axios";

const getProgress = (id) => axiosV2.get(`/data-import/upload/${id}/`);
const history = (params) => axiosV2.get(`/data-import/history/`, params);
const download = (id, params = {}) =>
  axiosV2.get(`/data-import/history/download/${id}/`, {
    responseType: "blob",
    params: params,
  });
const downloadExample = () =>
  axiosV2.get(`/data-import/download-sample/example/`, {
    responseType: "blob",
  });
const downloadPrefilled = () =>
  axiosV2.get(`/data-import/download-sample/pre_filled/`, {
    responseType: "blob",
  });
const revert = (id) => axiosV2.post(`/data-import/upload/${id}/revert/`);
const stop = (id) => axios.post(`/data-import/upload/${id}/stop/`);

// const subtProgress = (id) => axios.get(`/data-import/upload/${id}/`);

const subhistory = (params) => axios.get(`/data-import/subs/history/`, params);

const subHistoryDownload = (id) =>
  axios.get(`/data-import/subs/history/download/${id}/`, {
    responseType: "blob",
  });

const subDownloadSample = () =>
  axios.get(`/data-import/subs/download-sample/pre_filled/`, {
    responseType: "blob",
  });

export const DataImportApi = {
  getProgress,
  history,
  download,
  downloadExample,
  downloadPrefilled,
  revert,
  stop,
  // subtProgress,
  subhistory,
  subHistoryDownload,
  subDownloadSample,
};
