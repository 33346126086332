import React from "react";
import "../styles/CustomWalkthrough.css";

const CustomWalkthroughStyling = (route, target) => {
  // let disable = false;
  let step0 = false;
  if (target === ".step_0") {
    step0 = true;
  }
  const baseColor = "rgb(23 103 165)";
  // if (target === ".step_2" && route !== "/search") {
  //   disable = true;
  // }
  // if (target === ".step_3" && route !== "/viewers-trend") {
  //   disable = true;
  // }
  // if (target === ".step_4" && route !== "/library") {
  //   disable = true;
  // }
  const customWalkthroughStyling = {
    options: {
      arrowColor: baseColor,
      zIndex: 10000,
      primaryColor: "#222",
    },
    tooltip: {
      padding: 0,
      background: baseColor,
      borderRadius: 0, // removed default border radius
      // "linear-gradient(to left, rgba(23, 103, 165, 1), rgba(13, 58, 97, 1)",
    },
    tooltipTitle: {
      padding: "20px 10px",
    },
    tooltipContent: {
      // display: "none", // Hide the tooltip content
      padding: "10px",
    },
    tooltipFooter: {
      padding: "10px",
      borderTop: "1px solid rgba(255,255,255,0.3)",
    },
    buttonNext: {
      background: "white",
      borderRadius: 4,
      color: "rgba(13, 58, 97, 1)",
      border: 0,
      outline: "none",
      fontSize: 14,
      fontWeight: 700,
    },
    buttonBack: {
      background: "rgba(48,115,168)",
      borderRadius: 4,
      color: "white",
      border: 0,
      outline: "none",
      fontSize: 14,
    },
    buttonSkip: {
      color: "white",
      border: 0,
      outline: "none",
      fontSize: 14,
    },
    // Add animation only for the remaining steps except 0th step
    spotlight: step0
      ? {
          border: "1px solid #fff",
        }
      : {
          transition: "opacity 1s",
          animation: "fadeInOut 2s infinite",
        },
  };
  return customWalkthroughStyling;
};

export default CustomWalkthroughStyling;
