import React from "react";
import { flexRender } from "@tanstack/react-table";
import { useRecoilValue } from "recoil";
import { ResizingAtom, TableLoadingAtom } from "../../atom";
// import { ClipLoader } from "react-spinners";
import { motion } from "framer-motion";
import { DotPulse } from "@uiball/loaders";

const TableLayoutThree = ({ table, small = false }) => {
  const fetchLoading = useRecoilValue(TableLoadingAtom);
  const resizingLoading = useRecoilValue(ResizingAtom);

  const screenWidth = window.innerWidth;

  return (
    <>
    <table
      {...{
        style:
          table.getTotalSize() > screenWidth
            ? {
                width: table.getTotalSize(),
              }
            : {},
      }}
      className={`w-full border-collapse rounded-t-xl overflow-auto text-sm `}
    >
      <thead className="sticky top-0 z-[20]">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                {...{
                  key: header.id,
                  colSpan: header.colSpan,
                  style: {
                    width: header.getSize(),
                  },
                }}
                className={`relative border-collapse p-0 border-pot-grey-2 bg-white ${
                  header.column.getIsPinned()
                    ? "sticky left-0 z-[20]"
                    : "relative"
                }`}
              >
                <p
                  className={`border border-pot-grey-2 flex h-full p-2 text-xs font-semibold text-pot-blue`}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </p>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {(fetchLoading || resizingLoading) ? (
        <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            // className="even:bg-white odd:bg-slate-50"
          >
            {row.getVisibleCells().map((cell) => (
              <td
                {...{
                  key: cell.id,
                  style: {
                    width: cell.column.getSize(),
                  },
                }}
                // className="whitespace-pre-wrap"
                className={`hidden`}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      ) : (
        <motion.tbody initial={{ opacity: 0}} animate={{ opacity: 1 }} transition={{ duration: 0.6 }}>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              // className="even:bg-white odd:bg-slate-50"
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  {...{
                    key: cell.id,
                    style: {
                      width: cell.column.getSize(),
                    },
                  }}
                  // className="whitespace-pre-wrap"
                  className={`px-3 py-2 bg-white text-xs text-center ${
                    small ? "py-2 border" : "border"
                  } border-pot-grey-2 ${
                    cell.column.getIsPinned()
                      ? "sticky left-0 z-[10]"
                      : "relative"
                  }`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </motion.tbody>
      )}
    </table>
    {(fetchLoading || resizingLoading)  && (
      <div className="w-full h-[69vh] flex justify-center items-center">
        <DotPulse color="#1767A5" speed={0.8} size={40} />
      </div>
    )}
    </>
  );
};

export default TableLayoutThree;
