import React, { useEffect, useState } from "react";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import { DotPulse } from "@uiball/loaders";
import useMouseLocation from "../../hooks/useMouseLocation";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import moment from "moment";
import { LibraryApi } from "../../api/library";
import { btoa } from "../../utils/helperFunc";
import PdfViewer from "./PdfViewer";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AccountUserAtom,
  ArticleFromProfileAtom,
  ProfileCountryAtom,
  SelectedProfileCountryAtom,
} from "../../atom";
import { getFromLocalStorage } from "../../utils/localStorage";

function ArticleCard({ article, highlightingArticle, setHighlightingArticle }) {
  const [hovered, setHovered] = useState(false);
  const [beenHovering, setBeenHovering] = useState(false);
  const [sideIsLeft, setSide] = useState(true);
  const [thumbnail, setThumbnail] = useState(null);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRegion, setselectedRegion] = useState("");
  const selectedProfileCountry = useRecoilValue(SelectedProfileCountryAtom);
  const [articleFromProfile, setArticleFromProfile] = useRecoilState(
    ArticleFromProfileAtom
  );
  const account = useRecoilValue(AccountUserAtom);
  const profileCountries = useRecoilValue(ProfileCountryAtom);
  const isAdmin = getFromLocalStorage("user_type")?.toUpperCase() === "ADMIN";
  const mouseAtEnd = useMouseLocation(true);

  const fetchTumbnail = async () => {
    const res = await LibraryApi.download(article.thumbnail_url, true);
    if (res.status === 404 || res.status === 403) {
      setThumbnail("No data");
      return;
    }
    const base64 = btoa(
      new Uint8Array(res.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    setThumbnail(base64);
  };

  const downloadData = async (e) => {
    const { id, file_url: url, name } = article;
    if (loading) {
      return;
    }
    try {
      setLoading(id);
      const response = await LibraryApi.download(url);
      if (response.status !== 200) {
        const errorString = await response.data.text();
        const errorObj = JSON.parse(errorString);
        toast.error(errorObj.errors.error_message.join(", "));
        setLoading("");
        return;
      }
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.target = "_blank";
      const settableName = name.endsWith(".pdf") ? name : name + ".pdf";
      link.setAttribute("download", settableName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLoading("");
    } catch (e) {
      toast.error(JSON.stringify(e));
      setLoading("");
    }
  };

  useEffect(() => {
    if (hovered) {
      const id = setTimeout(() => {
        setSide(mouseAtEnd);
        setBeenHovering(true);
      }, 600);
      return () => clearTimeout(id);
    } else {
      setBeenHovering(false);
    }
  }, [hovered]);

  useEffect(() => {
    setselectedRegion(selectedProfileCountry);
  }, [selectedProfileCountry]);

  useEffect(() => {
    fetchTumbnail();
  }, []);

  useEffect(() => {
    if (articleFromProfile) {
      setHighlightingArticle(articleFromProfile);
      setArticleFromProfile("");
    }
  }, [articleFromProfile]);

  return (
    <>
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={`relative self-stretch`}
      >
        {beenHovering && (
          <motion.div
            initial={{ x: sideIsLeft ? "-50%" : "0%", scaleX: 0.6 }}
            animate={{ x: sideIsLeft ? "-100%" : "50%", scaleX: 1 }}
            transition={{ duration: 0.2 }}
            className={`z-[32] ${
              sideIsLeft ? "rounded-l-md" : "rounded-r-md"
            } flex flex-col px-3 py-4 w-[200%] h-full shadow-[0_3px_15px_6px_#3A61BA1A] bg-white absolute top-0 left-0`}
          >
            <p className="font-semibold pb-2 border-b">
              Profiles mentioned inside
            </p>
            <div className="flex h-fit border-b relative mb-3">
              {Object.keys(profileCountries).map((region) => {
                const hasTagsInAllCountry = Object.keys(profileCountries).every(
                  (reg) =>
                    article.profile_tags.some(
                      (tag) => tag.profile_country === reg
                    )
                );
                console.log(hasTagsInAllCountry, "hasss");
                const tags = article.profile_tags.filter(
                  (each) => each.profile_country === region
                );
                if (!hasTagsInAllCountry) return null;
                return (
                  <button
                    onClick={() => setselectedRegion(region)}
                    className="w-fit text-sm font-semibold py-2 relative"
                  >
                    <span className="px-3">{profileCountries[region]}</span>
                    {selectedRegion === region && (
                      <motion.div
                        transition={{ duration: 0.4 }}
                        layoutId="selectedRegionInSidebar"
                        className="absolute w-full bottom-0 bg-pot-light-blue-2 h-[1px]"
                      ></motion.div>
                    )}
                  </button>
                );
              })}
            </div>
            <motion.div
              key={selectedRegion}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="flex flex-wrap max-h-full overflow-y-auto gap-x-2 mt-2 text-xs gap-y-1"
            >
              {article.profile_tags
                .filter((each) => each.profile_country === selectedRegion)
                .map((ott) => (
                  <Link to={`/profile/${ott.id}`}>
                    <div className="px-2 py-0.5 rounded-md bg-pot-light-blue-4/40">
                      {ott.name}
                    </div>
                  </Link>
                ))}
            </motion.div>
          </motion.div>
        )}
        <motion.div
          animate={
            highlightingArticle === article.file_url
              ? { boxShadow: "0 0 3px 5px rgba(58, 97, 186, 0.14)" }
              : { boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)" }
          }
          transition={
            highlightingArticle === article.file_url
              ? { duration: 1, repeat: Infinity, repeatType: "reverse" }
              : {}
          }
          className="h-full rounded-md"
        >
          <div
            onClick={() => {
              if (!thumbnail || thumbnail === "No data") return;
              if (
                !account.user_permissions.includes("c_download_detail_pdf") &&
                !isAdmin
              )
                return;
              setOpenPdfViewer(true);
            }}
            className={`${
              beenHovering && "z-[35] bg-white"
            } relative rounded-md flex flex-col justify-between min-h-[20rem] max-h-full h-full ${
              highlightingArticle !== article.file_url && `shadow-md`
            } ${
              (account.user_permissions.includes("c_download_detail_pdf") ||
                isAdmin) &&
              "cursor-pointer"
            } p-4`}
          >
            {thumbnail ? (
              thumbnail === "No data" ? (
                <div className="w-full text-sm font-semibold text-pot-t-grey h-full flex justify-center items-center">
                  No Image
                </div>
              ) : (
                <div className="w-full h-full flex flex-col justify-center rounded-3xl relative ">
                  <div className="flex justify-end mr-0 absolute top-0 right-0">
                    <div className="bg-sky-100 text-center rounded-bl-md rounded-tr-md text-xs py-1 px-4 z-[30] font-semibold text-pot-blue">
                      {moment(article.publication_date).format("MM/DD/YYYY")}
                    </div>
                  </div>
                  <img
                    src={`data:application/pdf;base64,${thumbnail}`}
                    className="h-full object-cover rounded-md shadow-lg"
                  />
                </div>
              )
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <DotPulse size={24} speed={0.8} color="#1767A5" />
              </div>
            )}
            <div className="flex-col text-tiny px-2 text-center justify-center mt-5">
              <p className=" text-gray-900 text-center">{article.name}</p>
              <p className=" mt-2 font-semibold text-gray-900 text-center">
                {moment(article.publication_date).format("MMMM YYYY")}
              </p>
              {thumbnail && thumbnail !== "No data" && (
                <div className="inline-flex" role="group">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      (account.user_permissions.includes(
                        "c_download_detail_pdf"
                      ) ||
                        isAdmin) &&
                        downloadData();
                    }}
                    className={`inline-flex ${
                      account.user_permissions.includes(
                        "c_download_detail_pdf"
                      ) || isAdmin
                        ? "text-pot-blue bg-pot-light-blue-2/10"
                        : "text-pot-text-grey-2 bg-pot-grey-6 cursor-not-allowed"
                    } rounded-md items-center px-4 py-2 mt-3`}
                  >
                    {loading ? (
                      <div className="py-2 min-w-[90px] flex justify-center">
                        <DotPulse size={24} color="#1767A5" />
                      </div>
                    ) : (
                      <>
                        <span className="w-6 h-6 mr-2 fill-current">
                          <DownloadIcon
                            primaryColor={
                              account.user_permissions.includes(
                                "c_download_detail_pdf"
                              ) || isAdmin
                                ? "#1767A5"
                                : ""
                            }
                          />
                        </span>{" "}
                        Download{" "}
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </div>
      {openPdfViewer && (
        <PdfViewer
          article={article}
          handleCancel={() => setOpenPdfViewer(false)}
          pdfFile={pdfFile}
          setPdfFile={setPdfFile}
        />
      )}
    </>
  );
}

export default ArticleCard;
