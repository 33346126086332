import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ColoredDownArrow from "../assets/ColoredDownArrow";
import { useEffect } from "react";
import { useRef } from "react";

const DetailsAccordion = ({ title, children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [iconRotation, setIconRotation] = useState(0);
  const [componentHeight, setComponentHeight] = useState(0);

  // Create a ref to the element whose height you want to measure
  const componentRef = useRef(null);

  // This effect will be triggered when the component mounts or whenever the componentRef changes
  useEffect(() => {
    if (componentRef.current) {
      // Use getBoundingClientRect to get the height of the element
      const height = componentRef.current.getBoundingClientRect().height;

      // Update the state with the height value
      setComponentHeight(height);
    }
  }, [componentRef]);

  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    setIconRotation(isOpen ? 180 : 0);
  }, [isOpen]);

  return (
    <div
      className={` w-[100%] min-h-[50px] flex flex-col items-center m-auto overflow-hidden bg-[#F3F7FA]`}
      ref={componentRef}
    >
      <div
        className="w-full min-h-fit flex justify-between items-center font-gilroy-bold  cursor-pointer pr-4 py-2 bg-[#F3F7FA] z-50"
        onClick={handleDropdown}
      >
        <p className={`p-2 ml-4 text-pot-blue text-sm font-semibold`}>
          {title}
        </p>
        <div
          className="bg-pot-black h-full"
          style={{
            transform: `rotate(${iconRotation}deg)`,
            transition: "transform 0.2s ease",
          }}
        >
          <div className="w-fit text-pot-grey-3">
            <ColoredDownArrow color="rgb(117 117 117)" width={8} />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ marginTop: componentHeight > 50 ? "-10%" : "-100%" }}
            animate={{ marginTop: 0 }}
            exit={{ marginTop: componentHeight > 50 ? "-10%" : "-100%" }}
            transition={{ ease: "linear", duration: 0.4 }}
            className="w-full z-0"
          >
            <div className="flex flex-col justify-around space-y-1 text-sm mx-6 py-4 border-t">
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DetailsAccordion;
