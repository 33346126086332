import React, { useState } from "react";
import Eye from "../../assets/Eye";

const AuthInputField = ({
  heading,
  placeholder,
  value,
  onChange,
  onBlur = () => {},
  onFocus = () => {},
  type = "text",
  newPage,
  errorMessage,
  autoComplete = "new-password", // Strictly kept auto complete off
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`w-full ${!newPage ? "mt-4" : "mt-4 2xl:mt-[2vh]"}`}>
      <p
        className={`text-sm 2xl:text-base font-bold mb-2 ${
          isFocused && "drop-shadow-sm"
        }`}
      >
        {heading}
      </p>
      <div
        className={`w-full transition flex ease-in-out duration-400 bg-white rounded-lg ${
          isFocused ? "shadow-lg" : "shadow"
        } text-xs 2xl:text-sm`}
      >
        <input
          type={showPassword ? "text" : type}
          value={value}
          onChange={onChange}
          onFocus={() => {
            onFocus();
            setIsFocused(true);
          }}
          onBlur={() => {
            onBlur();
            setIsFocused(false);
          }}
          placeholder={showPassword ? "password" : placeholder}
          className="w-full outline-none px-4 py-2 rounded-lg"
          autoComplete={autoComplete}
        />
        {type === "password" && (
          <button
            onClick={() => setShowPassword((prev) => !prev)}
            className={`flex transition ease-in-out duration-400 items-center ${
              showPassword ? "stroke-gray-300" : "stroke-pot-dark-blue"
            } px-4 py-2`}
          >
            <Eye />
          </button>
        )}
      </div>
      <p className="text-xs text-red-400 h-0">{errorMessage}</p>
    </div>
  );
};

export default AuthInputField;
