import { Document, Font, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import poppins_regular from "../../assets/Poppins/Poppins-Regular.ttf";
import poppins_semibold from "../../assets/Poppins/Poppins-SemiBold.ttf";
import poppins_bold from "../../assets/Poppins/Poppins-Bold.ttf";
import poppins_extrabold from "../../assets/Poppins/Poppins-ExtraBold.ttf";
import poppins_black from "../../assets/Poppins/Poppins-Black.ttf";
import FrontPage from "./FrontPage";
import Header from "./Header";
import Row from "./Row";
import Footer from "../PDFGenerator.js/Footer";

const PdfPage = ({ data, cols, widths, colHeads, subsType = "Subscribers", region }) => {
  Font.register({
    family: "Poppins",
    fonts: [
      { src: poppins_regular },
      { src: poppins_semibold, fontWeight: "semibold" },
      { src: poppins_bold, fontWeight: "bold" },
      { src: poppins_extrabold, fontWeight: "extrabold" },
      { src: poppins_black, fontWeight: "black" },
    ],
  });

  Font.registerHyphenationCallback(word => [word]);

  const styles = StyleSheet.create({
    page: {
      width: "100vw",
      height: "100vh",
      paddingHorizontal: "20px",
      fontFamily: "Poppins",
      fontSize: "8px",
      paddingBottom: "70px",
    }
  })

  return (
    <Document>
      <FrontPage />
      <Page size={"A4"} orientation="landscape" style={styles.page}>
        {/* <View > */}
        <Header region={region} cols={colHeads} widths={widths} subsType={subsType} />
        {data?.map(row => (
          <Row row={row} cols={cols} widths={widths} />
        ))}
        {/* </View> */}
        <Footer landscape />
      </Page>
    </Document>
  );
};

export default PdfPage;
