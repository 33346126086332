import CheckIcon from "@atlaskit/icon/glyph/check";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  ColumnKeyValueAtom,
  SortableColumnsListAtom,
  SubscriptionTypeAtom,
} from "../atom";
import Sort from "../assets/Sort";
import { motion } from "framer-motion";
import {
  formatChurnValue,
  formatNpsValue,
  padStart,
} from "../utils/helperFunc";

const useColumnsDef = (
  initialVisibilityObject,
  sortableKey,
  setSortableKey
) => {
  const [mouseOn, setMouseOn] = useState(null);
  const screenSize = window.innerWidth;
  const columnKeyValue = useRecoilValue(ColumnKeyValueAtom);
  const sortableObj = useRecoilValue(SortableColumnsListAtom);
  // const subsType = useRecoilValue(SubscriptionTypeAtom);

  const colDef = [
    {
      accessorKey: "name",
      header: () => (
        <div
          onClick={() =>
            sortableKey === "name"
              ? setSortableKey(`-name`)
              : sortableKey === `-name`
              ? setSortableKey("")
              : setSortableKey("name")
          }
          className={`text-sm font-semibold z-[30] flex justify-center items-center cursor-pointer`}
        >
          <motion.button whileHover={{ scale: 1.2 }} className="min-w-fit mr-2">
            {sortableKey === "name" ? (
              <Sort firstFill="#1767A5" secondFill="#929292" />
            ) : sortableKey === `-name` ? (
              <Sort firstFill="#929292" secondFill="#1767A5" />
            ) : (
              <Sort firstFill="#1767A5" secondFill="#1767A5" />
            )}
          </motion.button>

          <p>Name of Service</p>
        </div>
      ),
      size: Math.ceil((screenSize - 150) / 7),
      cell: ({ getValue, row }) => {
        return (
          <div
            onMouseEnter={() => setMouseOn(row.index)}
            onMouseLeave={() => setMouseOn(null)}
            className="w-full h-full flex flex-col justify-start gap-y-5 items-start text-sm"
          >
            <div className="w-[180px] flex justify-between items-center">
              <p className="py-1 text-xs font-semibold">{getValue()}</p>
              {mouseOn === row.index && (
                <Link to={`/profile/${row.original.id}`}>
                  <button
                    // onClick={() => navigate(`/data/${row.original.id}`)}
                    className="py-1 px-2 relative z-40 text-xs rounded-md bg-pot-blue text-white"
                  >
                    View More
                  </button>
                </Link>
              )}
            </div>
            {/* <img src={row.original.image_url} alt={getValue()} className="w-[180px] object-contain" /> */}
          </div>
        );
      },
    },
  ];
  const remainDef = Object.keys(initialVisibilityObject).map((column) => {
    const header = () => (
      <div
        onClick={() => {
          if (!sortableObj[column]) {
            return;
          }
          sortableKey === column
            ? setSortableKey(`-${column}`)
            : sortableKey === `-${column}`
            ? setSortableKey("")
            : setSortableKey(column);
        }}
        className={`text-sm font-semibold z-[30] ${
          sortableObj[column] &&
          "flex justify-center items-center cursor-pointer"
        }`}
      >
        {sortableObj[column] && (
          <motion.button whileHover={{ scale: 1.2 }} className="min-w-fit mr-2">
            {sortableKey === column ? (
              <Sort firstFill="#1767A5" secondFill="#929292" />
            ) : sortableKey === `-${column}` ? (
              <Sort firstFill="#929292" secondFill="#1767A5" />
            ) : (
              <Sort firstFill="#1767A5" secondFill="#1767A5" />
            )}
          </motion.button>
        )}

        <p>{columnKeyValue[column]}</p>
      </div>
    );
    const def = { accessorKey: column, header };

    if (column === "is_free_trial" || column === "is_original_content") {
      def.size = Math.ceil((screenSize - 150) / 24);
      def.cell = ({ getValue }) => (
        <div className="flex justify-center items-center">
          {getValue() ? (
            <CheckIcon primaryColor="#028D9B" size="small" />
          ) : (
            <CrossIcon primaryColor="#C34B4B" size="small" />
          )}
        </div>
      );
    } else if (column === "pricing") {
      def.size = Math.ceil((screenSize - 150) / 12.2);
      def.cell = ({ getValue, column }) =>
        typeof getValue() === "string" ? (
          <p className="text-center">
            {getValue() + (getValue()[0] === "<" ? "M" : "")}
          </p>
        ) : (
          <p className={"text-center"}>
            {Number.isInteger(getValue()) || getValue()
              ? getValue() * 10 > parseInt(getValue()) * 10
                ? `$ ${(Math.round(getValue() * 100) / 100).toFixed(2)}`
                : `$ ${getValue()}`
              : "-"}
          </p>
        );
    } else if (column === "nps") {
      def.size = Math.ceil((screenSize - 150) / 24);
      def.cell = ({ getValue, column }) => (
        <p className="text-center">
          {getValue() === null
            ? "NA"
            : typeof getValue() === "string"
            ? getValue()
            : formatNpsValue(getValue())}
        </p>
      );
    } else if (column === "churn") {
      def.size = Math.ceil((screenSize - 150) / 24);
      def.cell = ({ getValue, column }) => (
        <p className="text-center">
          {getValue() === null
            ? "NA"
            : typeof getValue() === "string"
            ? getValue()
            : formatChurnValue(getValue())}
        </p>
      );
    } else if (
      ["Subscribers", "Viewers", "Transactional Customers"].includes(
        columnKeyValue[column]
      )
    ) {
      def.size = Math.ceil((screenSize - 150) / 12.2);
      def.cell = ({ getValue }) =>
        typeof getValue() === "string" ? (
          <p className="text-center">
            {getValue() + (getValue()[0] === "<" ? "M" : "")}
          </p>
        ) : (
          <p className={"text-center"}>
            {getValue()
              ? `${padStart((Math.round(getValue() * 100) / 100).toFixed(2))}M`
              : "-"}
          </p>
        );
    } else if (columnKeyValue[column] === "Launch Date") {
      def.size = Math.ceil((screenSize - 150) / 12.2);
      def.cell = ({ getValue, column }) => {
        let y, m, d;
        if (getValue()) {
          [y, m, d] = getValue()?.split("-");
        }
        return (
          <p className={"text-center"}>
            {getValue() ? `${[m, d, y].join("-")}` : "-"}
          </p>
        );
      };
    } else if (column !== "name") {
      def.size = Math.ceil((screenSize - 150) / 12.2);
      def.cell = ({ getValue, column }) =>
        Array.isArray(getValue()) ? (
          !getValue().length ? (
            <p className="text-center">-</p>
          ) : (
            getValue().map((each) => (
              <p
                className={`${
                  column.id.includes("no_of") ? "text-center" : ""
                }`}
              >
                {each}
              </p>
            ))
          )
        ) : (
          <p
            className={`${
              column.id.includes("no_of") || !getValue() ? "text-center" : ""
            }`}
          >
            {getValue() || "-"}
          </p>
        );
    }
    return def;
  });
  colDef.push(...remainDef);
  return colDef;
};

export default useColumnsDef;
