import React from "react";

const Organisation = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M5 3H2.009C0.902 3 0 3.962 0 5.15V15.85C0 17.04 0.9 18 2.009 18H7.496H5V15.855C3.384 15.854 2 15.851 2 15.851C2 15.851 2.005 5.143 2.009 5.143L5 5.144V3Z"
        fill="#1767A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4H11V6H9V4ZM3 7H5V9H3V7ZM3 10H5V12H3V10ZM9 7H11V9H9V7ZM9 10H11V12H9V10ZM11 13H13V16H11V13ZM13 4H15V6H13V4ZM13 7H15V9H13V7ZM13 10H15V12H13V10Z"
        fill="#1767A5"
      />
      <path
        d="M15.991 16C15.998 16 16 1.995 16 1.995C16 2.001 8.009 2 8.009 2C8.002 2 8 16 8 16H15.991ZM6 1.995C6 0.893 6.902 0 8.009 0H15.991C17.101 0 18 0.893 18 1.995V16.005C17.9976 16.5358 17.7847 17.044 17.4081 17.418C17.0315 17.792 16.5218 18.0013 15.991 18H6V1.995Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default Organisation;
