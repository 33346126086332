import React, { useEffect, useState } from "react";
import MainNavigationComponent from "../navigationOutlet/MainNavComponent";
import { navItems } from "../utils/customerNavItems";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DotPulse } from "@uiball/loaders";
import {
  AllConfigAtom,
  AllFilterOptionsAtom,
  AllNamesAtom,
  AppInfoAtom,
  ChurnQuarterRangeAtom,
  ColumnKeyValueAtom,
  CompareViewHeadersAtom,
  CountryObectAtom,
  DeviceSupportAtom,
  FilterValuesAtom,
  InitialColumnVisibilityAtom,
  NPSQuarterRangeAtom,
  ProfileCountryAtom,
  QuarterRangeAtom,
  SelectedProfileCountryAtom,
  ShowAlphabetsAtom,
  SortableColumnsListAtom,
} from "../atom";
import { appInfo } from "../api/core";
import { OttDataApi } from "../api/ottData";
import {
  // formatNamesToIdList,
  formatToKeyValue,
  formatToObj,
  formatToOptions,
  formatToVisibility,
} from "../utils/formatConfig";
import useDownloadTelemetry from "../hooks/useDownloadTelemetry";
import { getFromLocalStorage, setToLocalStorage } from "../utils/localStorage";
import { BASE_URL } from "../api/axios";
import { getFromCache, setToCache } from "../utils/cacheStorage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CustomerNavigation = ({
  target,
  startWalkThrough,
  setStartWalkThrough,
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedProfileCountry, setSelectedProfileCountry] = useRecoilState(
    SelectedProfileCountryAtom
  );
  const [allConfig, setAllConfig] = useRecoilState(AllConfigAtom);
  const [profilesCountries, setProfileCountries] =
    useRecoilState(ProfileCountryAtom);
  const [appInfoData, setAppInfoData] = useRecoilState(AppInfoAtom);
  const setDeviceSupport = useSetRecoilState(DeviceSupportAtom);
  const setCountryObject = useSetRecoilState(CountryObectAtom);
  const setAllFilterOptions = useSetRecoilState(AllFilterOptionsAtom);
  const setQuarterRange = useSetRecoilState(QuarterRangeAtom);
  const setNPSQuarterRange = useSetRecoilState(NPSQuarterRangeAtom);
  const setChurnQuarterRange = useSetRecoilState(ChurnQuarterRangeAtom);
  const setShowAlphabetsInDetailPage = useSetRecoilState(ShowAlphabetsAtom);
  const setInitialColumnVisibility = useSetRecoilState(
    InitialColumnVisibilityAtom
  );
  const [mainFilters, setMainFilters] = useRecoilState(FilterValuesAtom);
  const setColumnKeyValue = useSetRecoilState(ColumnKeyValueAtom);
  const setSortableColumnsList = useSetRecoilState(SortableColumnsListAtom);
  const setComparedHeaders = useSetRecoilState(CompareViewHeadersAtom);
  const [allNames, setAllNames] = useRecoilState(AllNamesAtom);
  const navigate = useNavigate();

  useDownloadTelemetry();

  const checkForProfileChange = (initial, namesList) => {
    let namesArray;
    if (namesList) {
      namesArray = namesList;
    } else {
      namesArray = allNames;
    }
    if (window.location.pathname.includes("/profile/") && namesArray) {
      const id = window.location.pathname.split("profile/")[1];
      const allIds = [];
      const allOtts = [];
      const allCountries = Object.keys(namesArray);
      const correspondingCountryIdx = [];
      Object.values(namesArray).forEach((each, idx) => {
        const length = Object.keys(each).length;
        allIds.push(...Object.values(each));
        allOtts.push(...Object.keys(each));
        correspondingCountryIdx.push(...Array.from({ length }).map(() => idx));
      });
      const index = allIds.indexOf(id); // if index is not found then index will be -1
      if (index !== -1) {
        // if index doesn't exist
        const countryIdx = correspondingCountryIdx[index];
        const name = allOtts[index];
        const country = allCountries[countryIdx];
        if (selectedProfileCountry !== country) {
          if (initial) {
            setSelectedProfileCountry(country);
            return false;
          }
          const allNamesInReg = Object.keys(namesArray[selectedProfileCountry]);
          if (allNamesInReg.includes(name)) {
            navigate(`/profile/${namesArray[selectedProfileCountry][name]}`);
          } else {
            setSelectedProfileCountry(country);
            if (!initial) {
              toast.error(
                `We do not have ${name} for ${profilesCountries[selectedProfileCountry]} Region`
              );
            }
            return false;
          }
        }
      }
      return true;
    }
    if (window.location.pathname.includes("/profile/")) return false;
    else return true;
  };

  const formatAllConfigWithCountry = (
    data,
    initial = false,
    fromApi = false,
    namesList = null
  ) => {
    const runRest = checkForProfileChange(initial, namesList);
    if (!runRest) return;
    const countryRegionObj = formatToKeyValue(data.field_mapping.country);
    const deviceType = formatToKeyValue(data.field_mapping.device_support);
    const allOptions = formatToOptions(data.search_options);
    const {
      visible: allColumns,
      keyVal: allColumnHeads,
      sortable: sortableObj,
    } = formatToVisibility(data.column_fields);
    setInitialColumnVisibility(allColumns);
    setSortableColumnsList(sortableObj);
    setColumnKeyValue(allColumnHeads);
    setCountryObject(countryRegionObj);
    setDeviceSupport(deviceType);
    setAllFilterOptions(allOptions);
    if (!initial) {
      const settableFilters = mainFilters.filter(
        (filter) => Object.keys(filter)[0] !== "names"
      );
      setMainFilters(settableFilters);
    }
    setComparedHeaders({ value1: null, value2: null });
    setShowAlphabetsInDetailPage("null");
    if (fromApi && allConfig !== null) {
      formatAllConfigWithCountry(allConfig[selectedProfileCountry], true);
    }
  };

  const formatNamesOnly = (data) => {
    return Object.keys(data).reduce((acc, cur) => {
      if (!data[cur].search_options) {
        return acc;
      }
      acc[cur] = formatToObj(data[cur].search_options.names);
      return acc;
    }, {});
  };

  const fetchAppConfig = async () => {
    const params = {};
    const storageV = getFromLocalStorage("v");
    const cacheConfig = await getFromCache(`${BASE_URL}/ott-profile/config/`);
    if (storageV && cacheConfig) {
      params.v = storageV;
    }
    const { data: response, status } = await OttDataApi.config({ params });
    if (status === 304) {
      if (!allConfig) {
        fetchAppConfigFromCache();
      }
      return;
    }
    if (storageV === response.v) {
      return;
    }
    const data = response.data.region_config;
    setToLocalStorage(null, null, response.v);
    await setToCache(
      "config",
      `${BASE_URL}/ott-profile/config/`,
      response.data
    );

    const namesList = formatNamesOnly(data);
    setAllNames(namesList);
    setAllConfig(data);
    formatAllConfigWithCountry(
      data[selectedProfileCountry || "US"],
      true,
      true
    );
    setQuarterRange(response.data.subs_quarter_range);
    setNPSQuarterRange(response?.data?.nps_quarter_range);
    setChurnQuarterRange(response?.data?.churn_quarter_range);
    const { keyVal: settableProfilesCountry } = formatToVisibility(
      response.data.profile_country
    );
    setProfileCountries(settableProfilesCountry);
    setLoading(false);
  };

  const fetchAppConfigFromCache = async () => {
    const data = await getFromCache(`${BASE_URL}/ott-profile/config/`);
    if (data) {
      const namesList = formatNamesOnly(data.region_config);
      setAllNames(namesList);
      setAllConfig(data.region_config);
      formatAllConfigWithCountry(
        data.region_config[selectedProfileCountry || "US"],
        true,
        false,
        namesList
      );
      setQuarterRange(data.subs_quarter_range);
      setNPSQuarterRange(data?.nps_quarter_range);
      setChurnQuarterRange(data?.churn_quarter_range);
      const { keyVal: settableProfilesCountry } = formatToVisibility(
        data.profile_country
      );
      setProfileCountries(settableProfilesCountry);
      setLoading(false);
    }
  };

  const fetchAppInfo = async () => {
    const { data: response } = await appInfo();
    const settableAppInfo = { ...response.data };
    settableAppInfo.country_codes = [...new Set(settableAppInfo.country_codes)];
    await setToCache("app-info", `${BASE_URL}/core/app-info/`, settableAppInfo);
    setAppInfoData(settableAppInfo);
  };

  const fetchAppInfoFromCache = async () => {
    const data = await getFromCache(`${BASE_URL}/core/app-info/`);
    if (data) {
      setAppInfoData(data);
    }
  };

  useEffect(() => {
    fetchAppInfo();
    fetchAppConfig();
    fetchAppConfigFromCache();
    fetchAppInfoFromCache();
  }, []);

  useEffect(() => {
    if (allConfig) checkForProfileChange(true);
  }, [allConfig]);

  useEffect(() => {
    // if (loading && selectedProfileCountry === "US") return;
    if (!allConfig) return;
    formatAllConfigWithCountry(allConfig[selectedProfileCountry]);
  }, [selectedProfileCountry]);

  if (loading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <DotPulse size={40} color="#1767A5" />
      </div>
    );
  }

  return (
    <MainNavigationComponent
      navItems={navItems}
      showSearch
      equals
      target={target}
      startWalkThrough={startWalkThrough}
      setStartWalkThrough={setStartWalkThrough}
    />
  );
};

export default CustomerNavigation;
