import React, { useState } from "react";
import ControlSelects from "../screensCustomer/ViewerTrend/ControlSelects";
import NPSChurnLineChart from "../screensCustomer/NPSandChurn/LineChart";
import { getAllQuarterBetween } from "../utils/helperFunc";
import NPSChurnBarChart from "../screensCustomer/NPSandChurn/BarChart";
import { strokes } from "../utils/chartsData";
import { useExportChart } from "../hooks/useExportChart";

const CommonChart = ({
  id,
  title,
  footer,
  data,
  bar,
  page = "NPS",
  yAxisLabel,
  quarterRange,
  allowExport = true,
}) => {
  const [chartType, setChartType] = useState("line");
  const endQuarter =
    quarterRange && Number(quarterRange.max.slice(1).split(" ")[0]) - 1;
  const endQuarterYear =
    quarterRange && Number(quarterRange.max.slice(1).split(" ")[1]);
  const modableVal = endQuarter - 11;
  const startQuarter =
    modableVal % 4 < 0 ? 4 + (modableVal % 4) : modableVal % 4;
  const diff =
    modableVal % 4 !== 0
      ? Math.trunc(Math.abs(modableVal) / 4 + 1)
      : Math.trunc(Math.abs(modableVal) / 4);
  const startQuarterYear = endQuarterYear - diff;
  const [quarterStart, setQuarterStart] = useState([
    startQuarter,
    startQuarterYear,
  ]);
  const [quarterEnd, setQuarterEnd] = useState([endQuarter, endQuarterYear]);
  const { ExportButton, headerRef, titleStyles } = useExportChart();

  let quarters = [];
  getAllQuarterBetween(quarterStart, quarterEnd).forEach((val) => {
    const realVal = [val[0] + 1, val[1]]?.join(" ");
    quarters.push(realVal);
  });
  const filteredData = data?.filter((item) =>
    quarters.includes(item.name.slice(1))
  );
  const filteredBarData =
    bar &&
    bar.map((obj) => {
      const newObj = { name: obj.name };
      quarters.forEach((quarter) => {
        const modifiedQuarter = "Q" + quarter;
        if (obj.hasOwnProperty(modifiedQuarter)) {
          newObj[modifiedQuarter] = obj[modifiedQuarter];
        }
      });
      return newObj;
    });

  let xAxisLabels =
    filteredData &&
    Object.keys(filteredData[0])
      .map((item) => {
        if (item !== "name") {
          return item;
        }
        return null;
      })
      .filter(Boolean);

  let barXAxisBLabels =
    bar &&
    Object.keys(filteredBarData[0])
      .map((item) => {
        if (item !== "name") {
          return item;
        }
        return null;
      })
      .filter(Boolean);

  return (
    <div className="relative">
      {allowExport && <ExportButton id={id} title={title} variant="footer" />}
      <div
        ref={headerRef}
        className={`${titleStyles} flex justify-between items-center py-2 px-6 h-16`}
      >
        <p className="text-base flex items-center font-semibold text-black h-9 w-[calc(100%-270px)]">
          {title}
        </p>
        <ControlSelects
          chartType={chartType}
          setChartType={setChartType}
          quarterEnd={quarterEnd}
          quarterStart={quarterStart}
          setQuarterEnd={setQuarterEnd}
          setQuarterStart={setQuarterStart}
          showChartTypeDropdown={true}
          customQuarterRange={quarterRange}
          showQuarterSelection={data?.length > 4}
          data={data}
          defaultValue={"custom"}
          page="IndustryBenchmark"
        />
      </div>
      <div
        key={id}
        id={`chart-${id}`}
        className={`font-popins flex flex-col border rounded-md w-full divide-y`}
      >
        <div
          style={{ height: headerRef.current?.clientHeight }}
          className="min-w-full w-full flex justify-between items-center py-2 px-6"
        >
          <p className="text-base flex items-center font-semibold text-black h-9">
            {title}
          </p>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingBottom: "350px",
            paddingRight: "12px",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              top: 10,
            }}
          >
            <div className="relative">
              <div
                className="text-[#888] text-center text-xs absolute top-32 -left-[110px] w-[250px]"
                style={{ transform: "rotate(-90deg)" }}
              >
                {yAxisLabel}
              </div>
            </div>
            {chartType === "line" ? (
              <NPSChurnLineChart
                data={data}
                filteredData={filteredData}
                xAxisLabels={xAxisLabels}
                yAxisLabel={yAxisLabel}
                page={page}
              />
            ) : (
              <NPSChurnBarChart
                data={data}
                filteredData={bar ? filteredBarData : filteredData}
                xAxisLabels={bar ? barXAxisBLabels : xAxisLabels}
                yAxisLabel={yAxisLabel}
                page={page}
              />
            )}
          </div>
        </div>

        {/* Legends */}
        <div className="mt-6 border-t-2 p-4 w-full h-20">
          <CustomLegends
            xAxisLabels={chartType === "line" ? xAxisLabels : barXAxisBLabels}
            strokes={strokes}
            chartType={chartType}
          />
        </div>
        {footer && (
          <div className="border-t-2 p-4 w-full text-right text-[rgb(139,139,139)] text-xs">
            {/* <div className="mt-6 border-t-2 p-4 w-full text-right text-[rgb(139,139,139)] text-xs"> */}
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

const CustomLegends = ({ xAxisLabels, strokes, chartType }) => {
  const displayStyle =
    chartType === "line"
      ? `flex-wrap justify-center gap-x-4`
      : xAxisLabels.length % 6 === 0
      ? `grid-cols-6`
      : xAxisLabels.length % 5 === 0
      ? `grid-cols-5`
      : `grid-cols-6`;

  return (
    <div
      className={`w-full ${
        chartType === "line" ? "flex w-fit" : "grid"
      } ${displayStyle} px-10 gap-x-2`}
    >
      {xAxisLabels &&
        xAxisLabels?.map((each, index) => {
          return (
            <div
              key={index}
              className={`flex items-center justify-center gap-1 w-fit py-1 text-xs`}
              color={strokes[index]}
            >
              <span
                className={`!min-w-[8px] !w-2 !min-h-[8px] !h-2`}
                style={{ backgroundColor: strokes[index] }}
              ></span>
              <span style={{ color: strokes[index] }} className="">
                {each}
              </span>
            </div>
          );
        })}
    </div>
  );
};

export default CommonChart;
