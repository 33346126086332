import React, { useEffect, useState } from 'react'

const usePageViewsChart = ({ setters, fetch=async() => {} }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchChart = async (type) => {
    setLoading(true)
    const responseData = await fetch(type)
    setData(responseData)
    setLoading(false)
  }

  useEffect(() => {
    // const type = day ? "COUNT_OF_ACTIVE_USERS_DAILY" : "COUNT_OF_ACTIVE_USERS_WEEKLY"
    const type = "TOP_10_POPULAR_PAGES"
    fetchChart(type)
  }, [setters])

  return ({ data, loading })
}

export default usePageViewsChart