import React from "react";

const Logout = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect width="20" height="20" fill="white" fillOpacity="0.01" />
      <path
        d="M5.83343 12.5V14.1617C5.83343 15.0858 6.58177 15.8333 7.5051 15.8333H15.8284C16.048 15.8334 16.2654 15.7903 16.4683 15.7063C16.6711 15.6223 16.8555 15.4992 17.0107 15.344C17.166 15.1887 17.2891 15.0044 17.3731 14.8015C17.457 14.5986 17.5002 14.3812 17.5001 14.1617V5.83833C17.5002 5.61877 17.457 5.40135 17.3731 5.19848C17.2891 4.99562 17.166 4.81129 17.0107 4.65604C16.8555 4.50079 16.6711 4.37766 16.4683 4.29369C16.2654 4.20972 16.048 4.16655 15.8284 4.16666H7.5051C7.28554 4.16655 7.06812 4.20972 6.86525 4.29369C6.66238 4.37766 6.47806 4.50079 6.32281 4.65604C6.16756 4.81129 6.04443 4.99562 5.96046 5.19848C5.87649 5.40135 5.83332 5.61877 5.83343 5.83833V7.5H7.5026C7.5026 6.52083 7.50427 5.83333 7.5051 5.83333C7.5051 5.83333 15.8334 5.835 15.8334 5.83833C15.8334 5.83833 15.8318 14.1667 15.8284 14.1667C15.8284 14.1667 7.5001 14.165 7.5001 14.1617V12.5H5.83343Z"
        fill="#1767A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66677 5H16.6668V7.5H6.66677V5ZM3.57593 7.74416L1.91427 9.40666C1.83578 9.48418 1.77347 9.5765 1.73094 9.67828C1.6884 9.78006 1.6665 9.88927 1.6665 9.99958C1.6665 10.1099 1.6884 10.2191 1.73094 10.3209C1.77347 10.4227 1.83578 10.515 1.91427 10.5925L3.57593 12.2567C3.73228 12.4124 3.94398 12.4999 4.16468 12.4999C4.38538 12.4999 4.59709 12.4124 4.75343 12.2567C5.07843 11.9308 5.07843 11.4025 4.75343 11.0775L3.67677 10L4.75343 8.9225C4.90159 8.76473 4.98255 8.5555 4.97919 8.3391C4.97583 8.12269 4.88841 7.91609 4.73542 7.76299C4.58244 7.6099 4.37589 7.52233 4.15949 7.51882C3.94309 7.5153 3.73381 7.59612 3.57593 7.74416Z"
        fill="#1767A5"
      />
      <path
        d="M11.6668 9.16666H3.33343C2.87319 9.16666 2.5001 9.53976 2.5001 10C2.5001 10.4602 2.87319 10.8333 3.33343 10.8333H11.6668C12.127 10.8333 12.5001 10.4602 12.5001 10C12.5001 9.53976 12.127 9.16666 11.6668 9.16666Z"
        fill="#1767A5"
      />
    </svg>
  );
};

export default Logout;
