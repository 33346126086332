import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import Check from "../../assets/pdf_png/tick.png";
import Cross from "../../assets/pdf_png/x.png";
import moment from "moment";

const Row = ({ cols, row, widths }) => {
  const typeArrays = [
    "business_model",
    "distribution_types",
    "genres",
    "target_market",
  ];

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        color: "#7B7B7B"
      }}
      wrap={false}
    >
      {cols.filter(col => col !== "nps" && col !== "churn").map((col, id) => {
        if (typeArrays.includes(col)) {
          return (
            <View
              style={{
                width: widths[id],
                display: "flex",
                paddingHorizontal: "2px",
                borderBottom: "1px solid #F4F4F4",
                borderLeft: "1px solid #F4F4F4",
                borderRight:
                  id === cols.length - 1 ? "1px solid #F4F4F4" : "0px",
              }}
            >
              {row[col].map((text) => (
                <Text style={{ paddingVertical: "5px" }}>{text}</Text>
              ))}
            </View>
          );
        } else if (col.includes("pricing")) {
          return (
            <View
              style={{
                width: widths[id],
                display: "flex",
                color: "black",
                alignItems: "center",
                padding: "5px 2px",
                borderBottom: "1px solid #F4F4F4",
                borderLeft: "1px solid #F4F4F4",
                borderRight:
                  id === cols.length - 1 ? "1px solid #F4F4F4" : "0px",
              }}
            >
              {typeof row[col] !== "string" ? (
                <Text>${row[col]}</Text>
              ) : (
                <Text style={{ textAlign: "center" }} >{row[col]}</Text>
              )}
            </View>
          );
        } else if (col.includes("subscribers")) {
          return (
            <View
              style={{
                width: widths[id],
                display: "flex",
                alignItems: "center",
                padding: "5px 2px",
                borderBottom: "1px solid #F4F4F4",
                borderLeft: "1px solid #F4F4F4",
                borderRight:
                  id === cols.length - 1 ? "1px solid #F4F4F4" : "0px",
              }}
            >
              {typeof row[col] !== "string" ? (
                <Text>{row[col] <= 0.009 ? "< 0.01" : row[col].toFixed(2)}M</Text>
              ) : (
                <Text>{row[col]}</Text>
              )}
            </View>
          );
        } else if (col.includes("platform")) {
          return (
            <View
              style={{
                width: widths[id],
                display: "flex",
                alignItems: "center",
                padding: "5px 2px",
                borderBottom: "1px solid #F4F4F4",
                borderLeft: "1px solid #F4F4F4",
                borderRight:
                  id === cols.length - 1 ? "1px solid #F4F4F4" : "0px",
              }}
            >
              <Text>{row[col]}</Text>
            </View>
          );
        } else if (col.includes("is")) {
          return (
            <View
              style={{
                width: widths[id],
                display: "flex",
                alignItems: "center",
                padding: "5px 2px",
                borderBottom: "1px solid #F4F4F4",
                borderLeft: "1px solid #F4F4F4",
                borderRight:
                  id === cols.length - 1 ? "1px solid #F4F4F4" : "0px",
              }}
            >
              {row[col] ? (
                <Image
                  src={Check}
                  style={{
                    width: "6px",
                    objectFit: "contain",
                    paddingTop: "5px",
                  }}
                />
              ) : (
                <Image
                  src={Cross}
                  style={{
                    width: "7px",
                    objectFit: "contain",
                    paddingTop: "5px",
                  }}
                />
              )}
            </View>
          );
        } else {
          return (
            <Text
              style={{
                width: widths[id],
                padding: col === "name" ? "5px 8px" : "5px 4px",
                fontWeight: col === "name" ? "semibold" : "normal",
                color: col === "name" ? "black" : "#7B7B7B",
                borderBottom: "1px solid #F4F4F4",
                borderLeft: "1px solid #F4F4F4",
                borderRight:
                  id === cols.length - 1 ? "1px solid #F4F4F4" : "0px",
              }}
            >
              {col.includes("date") ? moment(row[col], "YYYY-MM-DD").format("MM-DD-YYYY") : row[col]}
            </Text>
          );
        }
        // return <Text style={{ width: widths[id], padding: "2px" }}>{row[col]}</Text>;
      })}
    </View>
  );
};

export default Row;
