import React, { forwardRef, useEffect, useState } from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import TableLayoutTwo from "../../components/TableLayout/Two";
import Edit from "../../assets/Edit";
import TableControl from "../../components/TableLayout/TableControl";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  AccountUserAtom,
  LoadingProgressAtom,
  MainAppSideBarExpandAtom,
  TableLoadingAtom,
} from "../../atom";
import { MacScrollbar } from "mac-scrollbar";
import Sort from "../../assets/Sort";
import { motion } from "framer-motion";
import { EmailAPI } from "../../api/email";
import Trash from "../../assets/Trash";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
  BodyComponentProps,
} from "@atlaskit/modal-dialog";
import CheckIcon from "@atlaskit/icon/glyph/check";
import CrossIcon from "@atlaskit/icon/glyph/cross";

const OrganisationTable = ({ search, editPermission, deletePermission }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const setLoading = useSetRecoilState(LoadingProgressAtom);
  const navigate = useNavigate();
  const [colDef, setColDef] = useState([]);
  const account = useRecoilValue(AccountUserAtom);
  const setTableLoading = useSetRecoilState(TableLoadingAtom);
  const [deleteId, setDeleteId] = useState("");
  const [initial, setInitial] = useState(true);
  const sidebarExpand = useRecoilValue(MainAppSideBarExpandAtom);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    // Combining into MM-DD-YYYY format
    const formattedDate = `${formattedMonth}-${formattedDay}-${year}`;
    return formattedDate;
  };

  const initialColDef = [
    {
      header: () => (
        <div className="w-full flex justify-center cursor-pointer gap-x-3">
          Name
        </div>
      ),
      accessorKey: "name",
      cell: ({ getValue }) => (
        <div className="w-full flex justify-center">
          <p className="w-48 truncate">{getValue()}</p>
        </div>
      ),
    },
    {
      header: () => (
        <div className="w-full flex justify-center cursor-pointer gap-x-3">
          Subject
        </div>
      ),
      accessorKey: "subject",
      cell: ({ getValue }) => (
        <div className="w-full flex justify-center">
          <p className="w-48 truncate">{getValue()}</p>
        </div>
      ),
    },
    {
      header: () => (
        <div className="w-full flex justify-center cursor-pointer gap-x-3">
          Customizable?
        </div>
      ),
      accessorKey: "is_customizable",
      cell: ({ getValue }) => {
        return getValue() ? (
          <CheckIcon primaryColor="#028D9B" size="small" />
        ) : (
          <CrossIcon primaryColor="#C34B4B" size="small" />
        );
      },
    },
    {
      header: () => (
        <div className="w-full flex justify-center cursor-pointer gap-x-3">
          Created On
        </div>
      ),
      accessorKey: "created_at",
      cell: ({ getValue }) => (getValue() ? formatTimestamp(getValue()) : "-"),
    },
  ];

  const actionCol = {
    header: "Action",
    cell: ({ row }) => (
      <div className="flex justify-center gap-1">
        {editPermission && (
          <button
            onClick={() =>
              navigate(`/admin/email-template/edit/${row.original.id}`)
            }
            className="fill-pot-grey-6 p-2"
          >
            <Edit fill={"#1767A5"} />
          </button>
        )}
        {deletePermission && (
          <button
            onClick={() => {
              setDeleteId(row.original.id);
              setOpenDeletePopup(true);
            }}
            className="fill-pot-grey-6 p-2"
          >
            <Trash />
          </button>
        )}
      </div>
    ),
  };

  const handleDelete = async () => {
    setOpenDeletePopup(false);
    if (deleteId !== "") {
      const { data: response } = await EmailAPI.remove(deleteId);
    }
    setDeleteId("");
    fetch();
  };

  useEffect(() => {
    if (editPermission || deletePermission) {
      setColDef([...initialColDef, actionCol]);
    } else {
      setColDef([...initialColDef]);
    }
  }, [account]);

  const fetch = async () => {
    setTableLoading(true);
    setLoading(40);
    const params = { page, length: 10 };
    if (search) {
      params.search = search;
    }
    const { data: response } = await EmailAPI.list({ params });
    setLoading(70);
    if (response.success) {
      setTotalPages(response.data.total_pages);
      setTotalCount(response.data.total_count);
      setData(response.data.results);
    }
    setLoading(100);
    setTableLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (initial) {
      setInitial(false);
      return;
    }
    if (page === 1) {
      fetch();
    } else {
      setPage(1);
    }
  }, [search]);

  const table = useReactTable({
    data,
    columns: colDef,
    getCoreRowModel: getCoreRowModel(),
  });

  //   if (account && !account?.user_permissions.includes("a_view_organization")) {
  //   return (
  //     <div className="w-full h-[75vh] flex justify-center pb-[30vh] items-center font-bold text-pot-dark-green">
  //       You are not allowed to view the email templates list
  //     </div>
  //   );
  // }

  return (
    <MacScrollbar>
      <div className="w-full relative h-[75vh] overflow-y-auto">
        <TableLayoutTwo small table={table} showNoDataText />
        <motion.div
          animate={sidebarExpand ? { paddingLeft: 180 } : { paddingLeft: 56 }}
          className="fixed left-0 bottom-0 w-full"
          transition={{ duration: 0.4 }}
        >
          <TableControl
            page={page}
            setPage={setPage}
            length={10}
            totalCount={totalCount}
            totalPages={totalPages}
          />
        </motion.div>
      </div>
      <ModalTransition>
        {openDeletePopup && (
          <div className="rounded-xl bg-pot-grey-3">
            <Modal onClose={() => setOpenDeletePopup(false)} width={"400px"}>
              <ModalHeader>
                <h1 className="flex justify-center text-xl w-full font-bold">
                  Delete Template?
                </h1>
              </ModalHeader>
              <ModalBody>
                <p className="text-xs">
                  Are you sure you want to delete this template? This action
                  cannot be undone and the template will be permanently removed.
                </p>
              </ModalBody>
              <div className="flex gap-4 p-4">
                <button
                  onClick={() => setOpenDeletePopup(false)}
                  className={`rounded-md px-2 py-1 w-full hover:bg-pot-blue hover:text-white transition-all ease-in-out`}
                >
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  className={`rounded-md px-2 py-1 text-red-500 hover:bg-red-500 hover:text-white w-full transition-all ease-in-out`}
                >
                  Delete
                </button>
              </div>
            </Modal>
          </div>
        )}
      </ModalTransition>
    </MacScrollbar>
  );
};

export default OrganisationTable;
