import { atom } from "recoil";

export const FilterValuesAtom = atom({
  key: "filtervalue",
  default: [],
});

export const MainAppInitailAtom = atom({
  key: "initial",
  default: true,
});

export const MainAppSideBarExpandAtom = atom({
  key: "sidebarExpand",
  default: false,
});

export const titleAtom = atom({
  key: "title",
  default: "",
});

export const CompareViewHeadersAtom = atom({
  key: "compareViewHeaders",
  default: { value1: null, value2: null },
});

export const TopbarSearchShowAtom = atom({
  key: "topbarSearch",
  default: true,
});

export const PlatformDetailIdAtom = atom({
  key: "platformDetailId",
  default: null,
});

export const LoadingProgressAtom = atom({
  key: "loadingProgress",
  default: 0,
});

export const AppInfoAtom = atom({
  key: "appInfo",
  default: null,
});

export const AccountUserAtom = atom({
  key: "accountUser",
  default: null,
});

export const CountryObectAtom = atom({
  key: "countryObject",
  default: {},
});

export const DeviceSupportAtom = atom({
  key: "deviceSupport",
  default: {},
});

export const AllFilterOptionsAtom = atom({
  key: "allFilterOptions",
  default: {},
});

export const InitialColumnVisibilityAtom = atom({
  key: "initialColumnVisiblity",
  default: null,
});

export const ColumnKeyValueAtom = atom({
  key: "columnKeyValue",
  default: null,
});

export const SortableColumnsListAtom = atom({
  key: "sortableColumnList",
  default: null,
});

export const UploadVerifyIdAtom = atom({
  key: "uploadVerifyId",
  default: null,
});

export const VerificationResultAtom = atom({
  key: "verificationResult",
  default: null,
});

export const DescriptionAtom = atom({
  key: "descriptionAtom",
  default: null,
});

export const ScrollToLetterAtom = atom({
  key: "scrollToLEtter",
  default: null,
});

export const ShowAlphabetsAtom = atom({
  key: "showAlphabets",
  default: "",
});

export const StartColumnGenerate = atom({
  key: "startColumnGenerate",
  default: false,
});

export const PdfColsAtom = atom({
  key: "pdfCols",
  default: null,
});

export const PdfDataAtom = atom({
  key: "pdfData",
  default: null,
});

export const ResizingAtom = atom({
  key: "resizing",
  default: true,
});

export const QuarterRangeAtom = atom({
  key: "quarterRange",
  default: null,
});

export const TableLoadingAtom = atom({
  key: "tableLoading",
  default: false,
});

export const ContactEntryAtom = atom({
  key: "contactEntry",
  default: null,
});

export const SubscriptionTypeAtom = atom({
  key: "subscriptionType",
  default: "Subscribers",
});

export const ExcelSearchResultsDownloadAtom = atom({
  key: "excelSearchResultsDownload",
  default: false,
});

export const DownloadTelemetrySetterAtom = atom({
  key: "DownloadTelemetry",
  default: null,
});

export const ArticleFromProfileAtom = atom({
  key: "articleFromProfile",
  default: "",
});

export const NameFilterFromProfileAtom = atom({
  key: "nameFilterFromProfile",
  default: null,
});

export const PlatformCompareViewHeaderAtom = atom({
  key: "platformCompareViewHeader",
  default: { value1: null, value2: null },
});

export const ProfileCountryAtom = atom({
  key: "profileCountry",
  default: null,
});

export const AllConfigAtom = atom({
  key: "allConfig",
  default: null,
});

export const SelectedProfileCountryAtom = atom({
  key: "selectedProfileCountry",
  default: "US",
});

export const AllNamesAtom = atom({
  key: "allNamesObj",
  default: null,
});

export const CanadaOptionsAtom = atom({
  key: "allOptions2",
  default: {},
});

export const CountryCodesAtom = atom({
  key: "countryCodes",
  default: ["+91", "+1"],
});

export const TableInfoAtom = atom({
  key: "tableInfoAtom",
  default: null,
});

export const NPSQuarterRangeAtom = atom({
  key: "npsQuarterRange",
  default: null,
});

export const ChurnQuarterRangeAtom = atom({
  key: "churnQuarterRange",
  default: null,
});
