const titleStyle = "text-left font-bold text-[#fff] px-[10px]";
export const walkthroughSteps = [
  // DASHBOARD
  {
    title: (
      <h1 className={titleStyle}>Streaming market & top competitor KPIs</h1>
    ),
    disableBeacon: true,
    placement: "right-start",
    target: ".step_1",
  },
  // SEARCH
  {
    title: <h1 className={titleStyle}>Search and filter 300+ services</h1>,
    placement: "right-start",
    target: ".step_2",
    disableBeacon: true,
    spotlightClicks: true,
  },
  // VIEWERS TREND PAGE
  {
    title: (
      <h1 className={titleStyle}>
        Trending viewer estimates for 100+ services
      </h1>
    ),
    placement: "right-start",
    target: ".step_3",
    disableBeacon: true,
    spotlightClicks: true,
  },
  // LIBRARY
  {
    title: (
      <h1 className={titleStyle}>
        Monthly market analysis and viewer estimates notes
      </h1>
    ),
    placement: "right-start",
    target: ".step_4",
    disableBeacon: true,
    spotlightClicks: true,
  },
  // DROPDOWN
  {
    title: (
      <h1 className={titleStyle}>Toggle between US and Canada markets!</h1>
    ),
    locale: {
      last: "Finish",
    },
    placement: "right",
    target: ".step_0",
    disableBeacon: true,
    spotlightClicks: true,
  },
];
