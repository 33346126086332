import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  AllFilterOptionsAtom,
  ScrollToLetterAtom,
  ShowAlphabetsAtom,
} from "../../atom";
import { motion } from "framer-motion";
import { MacScrollbar } from "mac-scrollbar";

const OttList = () => {
  const [namesList, setNamesList] = useState([]);
  const [headsList, setHeadsList] = useState([]);
  const [activeHead, setActiveHead] = useState("#");
  const [clickedLetter, setClickedLetter] = useState("");
  const [shouldScrollControl, setShouldScrollControl] = useState(true);
  const [isActive, setIsActive] = useState("active");
  const ottDivRef = useRef([]);
  const navigate = useNavigate();
  const allOptions = useRecoilValue(AllFilterOptionsAtom);
  const [scrollToLetter, setScrollToLetter] = useRecoilState(ScrollToLetterAtom);
  const setShowAlphabets = useSetRecoilState(ShowAlphabetsAtom);

  useEffect(() => {
    if (allOptions) {
      setNamesList(allOptions.tempNames.map((each) => each.options));
      setHeadsList(allOptions.tempNames.map((each) => each.label));
    }
  }, [allOptions]);

  useEffect(() => {
    if (headsList.length && scrollToLetter) {
      scrollToHead(scrollToLetter - 1, headsList[scrollToLetter - 1]);
      setScrollToLetter(null)
    }
  }, [headsList, scrollToLetter]);

  const handleScroll = (e) => {
    if (!shouldScrollControl) {
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight === scrollHeight) {
      setActiveHead(headsList[headsList.length - 1]);
      return;
    }
    ottDivRef.current.forEach((each, id) => {
      if (id === headsList.length - 1) {
        return;
      }
      const realPosition = each.offsetTop - e.target.offsetTop - 20;
      const nextPosition =
        ottDivRef.current[id + 1].offsetTop - e.target.offsetTop;
      if (realPosition <= scrollTop && nextPosition > scrollTop) {
        setActiveHead(headsList[id]);
      }
    });
  };

  const scrollToHead = (id, each) => {
    setShouldScrollControl(false);
    setClickedLetter(each);
    // setActiveHead(each);
    ottDivRef.current[id].scrollIntoView({ behavior: "smooth" });
    setShouldScrollControl(true);
  };

  useEffect(() => {
    if (activeHead === clickedLetter) {
      setClickedLetter("");
    }
  }, [activeHead]);

  return (
    <MacScrollbar>
      <div className="h-[70vh] flex flex-col overflow-y-auto mt-8">
        <div className="h-[6vh] bg-pot-background-grey-2 w-full flex items-center text-sm">
          <p className="w-[14vw] 2xl:w-[20vw] px-4">OTT Names</p>
          <motion.div className="w-full flex gap-x-2">
            {headsList.map((each, idx) => (
              <motion.button
                whileHover={{ scale: activeHead === each ? 1 : 1.1 }}
                onClick={() => scrollToHead(idx, each)}
                className={`px-2 py-1 rounded-md ${
                  (each === activeHead || each === clickedLetter) &&
                  "bg-pot-dark-blue text-white delay-[250ms]"
                }`}
              >
                {each}
              </motion.button>
            ))}
          </motion.div>
        </div>
        <div className="h-[63.5vh] relative">
            <div
              onScroll={handleScroll}
              className="h-full overflow-y-auto pl-8 pr-4"
            >
              {headsList.map((each, id) => (
                <div
                  ref={(ref) => (ottDivRef.current[id] = ref)}
                  className={`py-2 ${
                    id === headsList.length - 1 && "h-[63.5vh]"
                  }`}
                >
                  <p className="w-fit text-2xl font-semibold pb-2 border-b-2 border-pot-light-blue px-4">
                    {each}
                  </p>
                  <div className="w-full grid grid-cols-6 gap-x-4 gap-y-3 my-6">
                    {namesList[id]
                      .filter((obj) => {
                        if (isActive === "active") {
                          return obj.active;
                        } else if (isActive === "inActive") {
                          return !obj.active;
                        } else {
                          return true;
                        }
                      })
                      .map((name) => (
                        <button
                          onClick={() =>
                            setShowAlphabets(isActive ? isActive : "all")
                          }
                          className="text-xs text-pot-text-grey-2 text-left flex justify-start hover:font-semibold hover:text-black transition ease-in-out"
                        >
                          <Link to={`/profile/${name.value}`}>{name.label}</Link>
                        </button>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          <div className="absolute top-0 w-full ">
            <div className="w-full flex justify-end text-xs">
              <div className="w-fit flex mr-4 pl-1 bg-white pt-2 text-pot-text-grey-2">
                <button
                  onClick={() =>
                    isActive === "active"
                      ? setIsActive(null)
                      : setIsActive("active")
                  }
                  className={`px-2 py-1 border rounded-md mr-3 ${
                    isActive === "active" &&
                    "bg-pot-dark-blue border-pot-dark-blue text-white"
                  }`}
                >
                  Active
                </button>
                <button
                  onClick={() =>
                    isActive === "inActive"
                      ? setIsActive(null)
                      : setIsActive("inActive")
                  }
                  className={`px-2 py-1 border rounded-md mr-3 ${
                    isActive === "inActive" &&
                    "bg-pot-dark-blue border-pot-dark-blue text-white"
                  }`}
                >
                  Phased Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MacScrollbar>
  );
};

export default OttList;
