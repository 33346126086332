import { useEffect } from "react";

const useLoggedInControl = () => {
  const handleTabClose = function (e) {
    const newTabCount = localStorage.getItem("tabsOpen");
    const hasChange = localStorage.getItem("noChange");
    if (hasChange) {
      localStorage.setItem("tabsOpen", newTabCount - 1);
      localStorage.removeItem("noChange");
      return;
    }
    if (newTabCount > 1) {
      localStorage.setItem("tabsOpen", newTabCount - 1);
    } else {
      localStorage.removeItem("tabsOpen");
    }
  };

  // const checkAllTabClosed  = () => {
  //     const newTabCount = localStorage.getItem('tabsOpen')
  //     if(!newTabCount) {
  //         localStorage.clear();
  //     }
  // }

  useEffect(() => {
    const tabsOpen = localStorage.getItem("tabsOpen");
    const keepLoggedIn = localStorage.getItem("loggedInAlways")
    if(keepLoggedIn) {
      return
    }
    if (!tabsOpen) {
      if (
        window.performance
          .getEntriesByType("navigation")
          .map((nav) => nav.type)
          .includes("reload")
      ) {
        localStorage.setItem("tabsOpen", 1);
      } else {

      //   localStorage.removeItem("tabsOpen");
      localStorage.removeItem("user_type");
      localStorage.removeItem("token");
      localStorage.setItem("tabsOpen", 1);
      }
    } else {
      if (
        !window.performance
          .getEntriesByType("navigation")
          .map((nav) => nav.type)
          .includes("reload")
      )
      localStorage.setItem("tabsOpen", parseInt(tabsOpen) + parseInt(1));
    }

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      // checkAllTabClosed()
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
};

export default useLoggedInControl;
