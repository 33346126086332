import { authenticatedAxiosInstance as axios } from "./axios";

const search = (payload, params) => axios.post(`/telemetry/gs/`, payload, params)
const pageView = (payload, params) => axios.post(`/telemetry/page-view/`, payload, params)
const compare = (payload, params) => axios.post(`/telemetry/compare/`, payload, params)
const download = (payload, params) => axios.post(`telemetry/download/`, payload, params)

export const TelemetryApi = {
    search,
    pageView,
    compare,
    download
}