import React, { memo, useEffect, useRef, useState } from "react";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import { usePDF } from "@react-pdf/renderer";
import PdfPage from "./PdfPage";
// import { ClipLoader } from "react-spinners";
import { ColumnKeyValueAtom, DownloadTelemetrySetterAtom, ExcelSearchResultsDownloadAtom } from "../../atom";
// import { useRecoilValue } from "recoil";
import { DotPulse } from "@uiball/loaders";
import useOnClickOutside from "../../hooks/useClickOutside";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import { useRecoilValue, useSetRecoilState } from "recoil";
import MediaServicesSpreadsheetIcon from '@atlaskit/icon/glyph/media-services/spreadsheet'
import MediaServicesPdfIcon from '@atlaskit/icon/glyph/media-services/pdf'


export const DownloadButton = ({ title }) => {
  const buttonRef = useRef(null);
  const setDownloadTeleStart = useSetRecoilState(DownloadTelemetrySetterAtom)

  useEffect(() => {
    buttonRef.current.click();
    setDownloadTeleStart(title)
  }, []);

  return (
    <button
      ref={buttonRef}
      onClick={() => setDownloadTeleStart(title)}
      className="w-40 flex justify-center gap-x-3 items-center py-2 rounded text-sm hover:bg-[#66BCFF0D]/60 transition ease-in-out bg-pot-bg-blue text-pot-dark-blue"
    >
      <DownloadIcon primaryColor="#1767A5" size="small" />
      Download
    </button>
  );
};

const ColumnPdf = ({
  region,
  data,
  cols,
  setCols,
  setData,
  pdfCols,
  widths,
  subsType = "Subscribers",
}) => {
  const [openDownloadDropdown, setOpenDownloadDropdown] = useState(false);
  const columnKeyValue = useRecoilValue(ColumnKeyValueAtom);
  const downloadRef = useRef(null);
  const setExcelDownload = useSetRecoilState(ExcelSearchResultsDownloadAtom)
  const setDownloadTelemetryStart = useSetRecoilState(DownloadTelemetrySetterAtom)
  useOnClickOutside(downloadRef, () => setOpenDownloadDropdown(false));
  const [instance, update] = usePDF({
    document: (
      <PdfPage
        region={region}
        // pdfShowableCountries={pdfShowableCountries}
        // pdfShowableDevices={pdfShowableDevices}
        // detail={detail}
        colHeads={pdfCols ? ["Name", ...pdfCols.filter(col => col !== "nps" && col !== "churn").map((each) => columnKeyValue[each])] : []}
        subsType={subsType}
        data={data}
        cols={cols}
        widths={widths}
      />
    ),
  });

  console.log(pdfCols?.map((each, id) =>
  id ? columnKeyValue[each] : "Name"
), "Colsddd", data, cols)

  useEffect(() => {
    if(!instance.url) {
        return;
    }
    const href = instance.url;
    const link = document.createElement("a");
    link.href = href;
    link.target = "_blank";
    link.setAttribute("download", "search_results.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloadTelemetryStart("SEARCH_RESULT")
  }, [instance.url]);

  //   useEffect(() => {
  //     update();
  //   }, [widths]);

  if (!instance.url) {
    return (
      <div className="w-40 cursor-wait flex justify-center items-center gap-x-3 bg-[#66BCFF]/10 hover:bg-[#66BCFF]/20 transition ease-in-out px-2 py-2 text-sm rounded text-pot-dark-blue ">
        {/* <ClipLoader size={20} color="#1767A5" /> */}
        <DotPulse color="#1767A5" size={20} speed={0.8} />
        <p>Generating</p>
      </div>
    );
  }

  return (
    <div ref={downloadRef} className="relative z-[70]">
      <button
        onClick={() => setOpenDownloadDropdown((prev) => !prev)}
        className="w-40 flex justify-between items-center bg-[#66BCFF]/10 hover:bg-[#66BCFF]/20 transition ease-in-out px-2 py-2 text-sm rounded text-pot-dark-blue "
      >
        <div className="flex gap-x-2 items-center ml-2">
          <DownloadIcon primaryColor="#1767A5" size="small" />
          Download
        </div>
        <HipchatChevronDownIcon size="small" primaryColor="#1767A5" />
      </button>
      {openDownloadDropdown && (
        <div className="absolute mt-1">
          <div onClick={() => setOpenDownloadDropdown(false)} className="bg-white h-fit shadow-md rounded-md py-2 w-40">
            <div onClick={() => setExcelDownload(true)} className="px-4 py-2 flex justify-start items-center gap-x-2 hover:bg-pot-bg-blue/60 cursor-pointer">
              <MediaServicesSpreadsheetIcon primaryColor="#1767A5" size="small" />
              Excel
            </div>
            {/* {cols && data && instance.url ? ( */}
            <a
              onClick={() => {
                setDownloadTelemetryStart("SEARCH_RESULT")
              }}
              href={instance.url}
              download="search_results.pdf"
            >
                <p className="w-40 flex justify-start items-center gap-x-2 text-left hover:bg-pot-bg-blue/40 px-4 py-2 cursor-pointer">
                <MediaServicesPdfIcon primaryColor="#1767A5" size="small" />
                  PDF
                </p>
            </a>
            {/* ) : ( */}
            {/* <button
                onClick={() => setStartGenerate(true)}
                className="w-full text-left hover:bg-pot-bg-blue/40 px-4 py-2"
              >
                Pdf
              </button> */}
            {/* )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(ColumnPdf);
