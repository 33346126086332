import React, { useEffect, useState } from 'react'

const useTopActiveUsersChart = ({ fetch= async () => {}, day=true, setters=[] }) => {
    // const dates= ["18/11", "19/11", "20/11", "21/11", "22/11", "23/11", "24/11", "25/11", "26/11", "27/11",]
    // const data = Array.from({ length: 10}).map((_, id) => ({
    //     date: dates[id],
    //     value: Math.floor((Math.random() * 100) + 1)
    // }))
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchChart = async (type) => {
      setLoading(true)
      const responseData = await fetch(type)
      setData(responseData)
      setLoading(false)
    }

    useEffect(() => {
      const type = day ? "COUNT_OF_ACTIVE_USERS_DAILY" : "COUNT_OF_ACTIVE_USERS_WEEKLY"
      fetchChart(type)
    }, [day, setters])

  return ({ data, loading, setLoading })
}

export default useTopActiveUsersChart