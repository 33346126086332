import React, { memo } from "react";
// import { ClipLoader } from "react-spinners";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
// import { strokes } from "../../utils/chartsData";
// import { names } from "../../utils/chartsData";
import { getAllQuarterBetween } from "../../utils/helperFunc";
import { DotPulse } from "@uiball/loaders";
import { ResizingAtom } from "../../atom";
import { useRecoilValue } from "recoil";

const TrendLineChart = ({
  selectedOtts,
  quarterEnd,
  quarterStart,
  chartLoading,
  type,
  page = "viewerstrend",
}) => {
  const containerResizing = useRecoilValue(ResizingAtom);
  const queryParameters = new URLSearchParams(window.location.search);
  var specific_profile = queryParameters.get("profile");

  const data2 = getAllQuarterBetween(quarterStart, quarterEnd)
    .map((val) => {
      const realVal = [val[0] + 1, val[1]].join(" ");
      if (
        page !== "viewerstrend" &&
        (realVal[0] === "2" || realVal[0] === "4")
      ) {
        return null;
      }
      const newObj = { name: realVal };
      selectedOtts.forEach((each) => {
        newObj[each.name] = each[realVal] || null;
      });
      // Only for profile redirected Chart
      if (specific_profile && !selectedOtts[0][realVal]) return null;
      return newObj;
    })
    .filter(Boolean);

  if (chartLoading || containerResizing) {
    return (
      <div className="w-full flex justify-center h-[350px] items-center">
        <DotPulse size={40} color="#1767A5" speed={0.8} />
      </div>
    );
  }

  // FOR YAxis Label depending upon the type and page
  const yAXisLabel =
    page === "nps"
      ? "NPS"
      : page === "churn"
      ? "Churn"
      : type === "PAID_SUBS"
      ? "Subscribers (M)"
      : type === "MONTHLY_VIEWERS"
      ? "Viewers (M)"
      : "Transactional Customers (M)";

  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart
        data={data2}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          label={{
            value: "Time",
            position: "insideBottom",
            offset: -20,
          }}
          dataKey="name"
          tick={
            page === "viewerstrend" ? (
              <CustomizedTick />
            ) : (
              <NPSChurnCustomizedTick />
            )
          }
        />
        <YAxis
          label={{
            value: yAXisLabel,
            angle: -90,
            offset: 50,
            position: "insideRight",
          }}
        />
        <Tooltip
          formatter={(value) => {
            if (page === "viewerstrend") return `${value.toFixed(1)} M`;
            if (page === "nps") return Math.round(value);
            return `${Math.round(value)}%`;
          }}
          labelFormatter={(value) => `Q${value}`}
        />
        {selectedOtts.map((each, index) => (
          <Line
            key={index}
            type="monotone"
            dataKey={each.name}
            stroke={each.color}
            strokeWidth={1.5}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

const CustomizedTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={10}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(0)"
      >
        Q{payload.value}
      </text>
    </g>
  );
};

const NPSChurnCustomizedTick = ({ x, y, payload }) => {
  const quarterNumber = parseInt(payload.value.split(" ")[0]);
  const quartersToHide = [2, 4];
  if (quartersToHide.includes(quarterNumber)) {
    return null;
  }
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={10}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(0)"
      >
        Q{payload.value}
      </text>
    </g>
  );
};

export default memo(TrendLineChart);
