import React from "react";
import { DotPulse } from "@uiball/loaders";
import { data } from "autoprefixer";
import { motion } from "framer-motion";
import {
  Bar,
  BarChart,
  LabelList,
  // ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import usePageViewsChart from "../../hooks/usePageViewsChart";
import { useExportChart } from "../../hooks/useExportChart";

const ViewsPage = ({ setters, fetch }) => {
  // const [day, setDay] = useState(true);
  // const [openDayDropdown, setOpenDayDropdown] = useState(false);
  // const dayRef = useRef();
  // useOnClickOutside(dayRef, () => setOpenDayDropdown(false));
  const { data, loading } = usePageViewsChart({ fetch, setters });
  const { ExportButton, headerRef, titleStyles } = useExportChart();

  return (
    <div className="relative w-full self-stretch">
      <div
        ref={headerRef}
        className={`${titleStyles} flex justify-between items-center h-[10%] px-5`}
      >
        <p className="text-sm text-pot-dark-blue font-semibold">
          Views By Page Title
        </p>
        <ExportButton id="views-by-page-title" title="Views By Page Title" />
      </div>
      <div
        id="chart-views-by-page-title"
        className="h-full border rounded-md shadow-md text-xs"
      >
        <div
          style={{ height: headerRef.current?.clientHeight }}
          className="flex h-[10%] items-center justify-between px-5"
        >
          <p className="text-sm text-pot-dark-blue font-semibold">
            Views By Page Title
          </p>
          {/* <div ref={dayRef} className="relative">
        <button
          onClick={() => setOpenDayDropdown(true)}
          className="flex items-center rounded-md gap-x-1 text-pot-blue bg-pot-bg-blue pl-3 pr-1 py-1"
        >
          {day ? "Day" : "Week"}
          <ChevronDownIcon primaryColor="#1767A5" />
        </button>
        {openDayDropdown && (
          <div className="bg-white absolute right-0 z-50 w-28 rounded-md border shadow-md">
            <button
              onClick={() => {
                setDay(true);
                setOpenDayDropdown(false);
              }}
              className="w-full text-left px-3 py-2 mt-2 hover:bg-pot-bg-blue mb-1"
            >
              Day
            </button>
            <button
              onClick={() => {
                setDay(false);
                setOpenDayDropdown(false);
              }}
              className="w-full text-left px-3 py-2 hover:bg-pot-bg-blue mb-2"
            >
              Week
            </button>
          </div>
        )}
      </div> */}
        </div>
        {loading ? (
          <div className="w-full h-96 flex justify-center items-center">
            <DotPulse size={40} color="#1767A5" />
          </div>
        ) : (
          <div className="w-full h-full relative text-xs pb-5">
            {data.length ? (
              <ResponsiveContainer width={"97%"} height={"90%"}>
                <BarChart
                  margin={{
                    left: 90,
                    // top: 20,
                    bottom: 28,
                    right: 10,
                  }}
                  barGap={2}
                  barCategoryGap={8}
                  stackOffset="sign"
                  data={data}
                  layout="vertical"
                >
                  {/* {devices} */}
                  <Bar shape={CustomizedBar} dataKey={"count"} fill="#1B75BB">
                    <LabelList
                      valueAccessor={(each) => `${each.value}`}
                      position="right"
                    />
                  </Bar>
                  {/* <Tooltip cursor={{ fill: "#fff" }} /> */}
                  <XAxis
                    // dataKey={"count"}
                    padding={{ left: 5, right: 20 }}
                    type="number"
                    label={{
                      value: "No. of Views",
                      position: "insideBottom",
                      offset: -10,
                    }}
                  />
                  {/* <YAxis tickFormatter={(value) => value.split(" ").join("_")} tickMargin={8} interval={0} padding={{ top: 20, bottom: 20 }} type="category" dataKey="x_axis" /> */}
                  <YAxis
                    tick={CustomizedTick}
                    label={{
                      value: "Pages",
                      angle: -90,
                      position: "insideTopRight",
                      // distribution ? "insideTopRight" : "insideLeft",
                      offset: 140,
                      // distribution ? 140 : -90,
                    }}
                    interval={0}
                    padding={{ top: 20, bottom: 20 }}
                    type="category"
                    dataKey="title"
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="w-full h-96 flex justify-center items-center text-base font-semibold text-pot-text-grey-2"
              >
                No Chart Data
              </motion.div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const CustomizedTick = (props) => {
  return (
    <g transform={`translate(0,${props.y})`}>
      <text
        x={136}
        // y={11}
        textAnchor="end"
        dominantBaseline="middle"
        style={{
          fontSize: "12px",
          fill: "#666666",
        }}
      >
        {props.payload.value}
      </text>
    </g>
  );
};

const CustomizedBar = (props) => {
  return (
    <g>
      <rect
        x={props.x}
        y={props.y + props.height / 2 - 5.5}
        width={props.width}
        height={11}
        fill={props.fill}
        rx={5.5}
        ry={5.5}
      />
    </g>
  );
};

export default ViewsPage;
