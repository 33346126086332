import React from "react";

const Resolution = ({ detail }) => {
  return (
    <section className="w-full flex flex-col justify-evenly border border-pot-grey-4/10 rounded-2xl shadow-md p-3">
      <div className="leading-tight text-sm">
        <p className="font-semibold">Resolution</p>
        {/* <p>supported by the ott</p> */}
        <div className="h-4 w-4"></div>
      </div>
      <p className="text-pot-dark-blue text-4xl mt-4 font-bold">
        {/* {detail.resolutions?.existing.concat(detail.resolutions?.new)?.join(", ")} */}
        {detail.resolutions?.existing.concat(detail.resolutions?.new)?.sort((a, b) => {
              if (a === "4K") return -1;   // First, sort "4K"
              if (b === "4K") return 1;
              if (a === "HD") return -1;    // Second, sort "HD"
              if (b === "HD") return 1;
              if (a === "SD") return -1;    // Third, sort "SD"
              if (b === "SD") return 1;
              return 0;                    // Default case: maintain existing order
            })?.join(", ")}
        </p>
    </section>
  );
};

export default Resolution;
